import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { Observable } from 'rxjs';
import { State as GridState } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import * as actions from './store/actions';
import * as selectors from './store/selectors';
import { select } from '@ngrx/store';
import { ByosBackfillSite } from './byos-backfill-site';
import { BackfillRequest } from './backfill-request';

@Component({
  selector: 'app-admin-extended-storage',
  templateUrl: './admin-extended-storage.component.html',
  styleUrls: ['./admin-extended-storage.component.css']
})
export class AdminExtendedStorageComponent {

  public enabledOptions$: Observable<string[]>;
  public selectedEnabledOption$: Observable<string>;
  public textFilter$: Observable<string>;
  public textFilterChange$: Observable<string>;
  public getSites$: Observable<any>;
  public byosGridState$: Observable<GridState>;
  public byosGridView$: Observable<GridDataResult>;
  public showBackfillForm$: Observable<boolean>;
  public backfillSite$: Observable<ByosBackfillSite>;
  
  constructor(
    private store: Store<AppState>, 

  ) { }

  ngOnInit(): void {
    this.enabledOptions$ = this.store.pipe(select(selectors.enabledOptions));
    this.selectedEnabledOption$ = this.store.pipe(select(selectors.selectedEnabledOption));
    this.textFilter$ = this.store.pipe(select(selectors.textFilter));
    this.byosGridState$ = this.store.pipe(select(selectors.byosGridState));
    this.byosGridView$ = this.store.pipe(select(selectors.byosGridView));
    this.showBackfillForm$ = this.store.pipe(select(selectors.showBackfillForm));
    this.backfillSite$ = this.store.pipe(select(selectors.backfillSite));

    this.store.dispatch(actions.getByosSites());
  }

  public onClearFilters() {
    this.store.dispatch(actions.clearFilters());
  }

  public onEnabledOptionChange(option: string) {
    this.store.dispatch(actions.setSelectedEnabledOption({ option: option }));
  }

  onTextFilterChange(filter: string) {
    this.store.dispatch(actions.setTextFilter({ textFilter: filter }));
  }

  onGridStateChange(grid: GridState) {
    this.store.dispatch(actions.setByosGridState({ gridState: grid }));
  }

  onBackfillButtonClick(site: ByosBackfillSite) {
    this.store.dispatch(actions.setBackfillSite({ site: site }));    
  }

  onBackfillFormClose() {
    this.store.dispatch(actions.setShowBackfillForm({ show: false }));
  }

  onBackfillSubmitRequest(request: BackfillRequest) {
    this.store.dispatch(actions.submitBackfillRequest({ request: request }));
    console.log("Backfill Form Submitted: ", request);
  }


}
