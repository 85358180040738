export class EventNote {
    id: number;
    eventID: number;
    author?: number;//user id
    text: string;
    time: Date;
    wts: boolean;
    internal: boolean;//only visible to wts
    fatAuthor?: number;
    fatAuthorName?: string;
    constructor(config: any) {
        Object.assign(this, config);
        if(config.time) {
            this.time = new Date(config.time);
        }
    }
}