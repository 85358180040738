 
import { ByosBackfillSite } from '../byos-backfill-site';
import { State as GridState } from '@progress/kendo-data-query';


export const backFillKey = "backFill";


export interface State {
    enabledOptions: string[],
    selectedEnabledOption: string,
    textFilter: string,
    appBarWidth: number,
    byosSites: ByosBackfillSite[],
    byosGridState: GridState,
    showBackfillForm: boolean,
    backfillSite: ByosBackfillSite,
}

export const initialState: State = {
    enabledOptions: ["All", "Storage Enabled", "Storage Disabled"],
    selectedEnabledOption: "All",
    textFilter: "",
    appBarWidth: 0,
    byosSites: [],
    byosGridState:{ sort: [], skip: 0, take: 50 },
    showBackfillForm: false,
    backfillSite: null,
}

    