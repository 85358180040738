export class CFTagCategory {
    id: number;
    name: string;
    pieColor: string;
    
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config);
        }
    }
}