import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Store, select } from "@ngrx/store";
import { AppState } from "../../app.state";
import * as selectors from "../store/selectors";
import { EventSynopsis } from "./event-synopsis";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-event-synopsis',
  templateUrl: './event-synopsis.component.html',
  styleUrls: ['./event-synopsis.component.css']
})
export class EventSynopsisComponent implements OnInit, OnDestroy {
  @Output()
  generateEventSynopsis = new EventEmitter<{ detailLevel: string, descriptionSelector: string, categoryIDs: number[] }>();

  @Output()
  generateEventSynopsisStream = new EventEmitter<{ detailLevel: string, descriptionSelector: string, categoryIDs: number[] }>();

  @Input("detailLevel")
  detailLevel: string;

  @Input("descriptionSelector")
  descriptionSelector: string;

  public eventSynopsisList$: Observable<EventSynopsis[]>;
  public requestId$: Observable<string | null>;
  public loading$: Observable<boolean>;
  public currentSummary = "";

  private summaryBuffer = "";
  private destroy$ = new Subject<void>();

  constructor(private store: Store<AppState>, private zone: NgZone) {}

  ngOnInit(): void {
    this.eventSynopsisList$ = this.store.pipe(select(selectors.eventSynopsisList));
    this.requestId$ = this.store.pipe(select(selectors.eventSynopsisRequestId));
    this.loading$ = this.store.pipe(select(selectors.eventSynopsisLoading));

    this.requestId$.pipe(takeUntil(this.destroy$)).subscribe((requestId) => {
      if (requestId) {
        this.streamEventSynopsisUpdates(requestId);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onGenerateEventSynopsis(detailLevel: string, descriptionSelector: string, categoryIDs: number[] = []): void {
    this.generateEventSynopsis.emit({ detailLevel, descriptionSelector, categoryIDs });
  }

  onGenerateEventSynopsisStream(detailLevel: string, descriptionSelector: string, categoryIDs: number[] = []): void {
    this.generateEventSynopsis.emit({ detailLevel, descriptionSelector, categoryIDs });
  }

  streamEventSynopsisUpdates(requestId: string): void {
    const eventSource = new EventSource(`/api/ai/summarize/events/stream?requestId=${requestId}`);

    eventSource.onmessage = (event) => {
      this.zone.run(() => {
        this.summaryBuffer += event.data;
        this.updateSummary();
      });
    };

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      eventSource.close();
    };

    eventSource.onopen = (event) => {
      console.log('Connection opened:', event);
    };
  }

  private updateSummary() {
    requestAnimationFrame(() => {
      this.currentSummary = this.summaryBuffer;
    });
  }
}
