import { Component, OnInit } from '@angular/core';
import { TimeObj } from 'app/time-obj';
import { Observable, Subject } from 'rxjs';
import { AppState } from 'app/app.state';
import { Store, select } from '@ngrx/store';
import { selectedStartTimeAsDate, selectedEndTimeAsDate, selectedHours, selectShowTimeModal, selectedTime, selectedCameras, mapsWithCamsSelected, selectedMaps, selectShowTooManyCams, selectValid, selectDescription, selectShowTimeUpdateModal, selectedMap } from './store/selectors';
import * as RawActions from './store/actions';
import { MotionDateTime } from 'app/motion/motion-datetime-modal/motion-datetime';
import { RawRequestCameraMarker } from './raw-request-camera-marker';
import { Map } from 'app/map/map';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-raw-request',
  templateUrl: './raw-request.component.html',
  styleUrls: ['./raw-request.component.css']
})
export class RawRequestComponent implements OnInit {


  public selectedStartDate$: Observable<Date>;
  public selectedEndDate$: Observable<Date>;
  public selectedHours$: Observable<number>;

  public showDateTime$: Observable<boolean>;
  public selectedTime$: Observable<MotionDateTime>;

  public selectedCameras$: Observable<RawRequestCameraMarker[]>;
  public maps$: Observable<Map[]>
  public mapsWithCamIDs$: Observable<number[]>;
  public currentMapID$: Observable<number>;
  public currentMapID: number;

  public maxCameraModalVisible$: Observable<boolean>;
  public additionalCameras$: Observable<number>;

  public description$: Observable<string>;

  public valid$: Observable<boolean>;

  public showTimeUpdateModal$: Observable<boolean>;

  public cameraMarkers: RawRequestCameraMarker[] = [];

  public rawRequestHours$: Observable<number>; 

  public daysContinuous$: Observable<number>;


  public destroy$ = new Subject<void>();
  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.additionalCameras$ = this.store.select(s => s.site).pipe(map(s => s.autoRequestAdditionalCameras), takeUntil(this.destroy$))
    this.selectedStartDate$ = this.store.pipe(select(selectedStartTimeAsDate));
    this.selectedEndDate$ = this.store.pipe(select(selectedEndTimeAsDate));
    this.selectedHours$ = this.store.pipe(select(selectedHours));

    this.showDateTime$ = this.store.pipe(select(selectShowTimeModal));
    this.selectedTime$ = this.store.pipe(select(selectedTime));

    this.selectedCameras$ = this.store.pipe(select(selectedCameras));
    this.mapsWithCamIDs$ = this.store.pipe(select(mapsWithCamsSelected));
    this.maps$ = this.store.pipe(select(selectedMaps));
    this.currentMapID$ = this.store.pipe(select(selectedMap))

    this.maxCameraModalVisible$ = this.store.pipe(select(selectShowTooManyCams));
    this.valid$ = this.store.pipe(select(selectValid));
    this.description$ = this.store.pipe(select(selectDescription));
    this.showTimeUpdateModal$ = this.store.pipe(select(selectShowTimeUpdateModal));
    this.selectedCameras$.pipe(takeUntil(this.destroy$)).subscribe(camMarkers => {
      this.cameraMarkers = camMarkers;
    });
    this.rawRequestHours$ = this.store.select(s => s.site).pipe(map(s => s.rawRequestHours), takeUntil(this.destroy$));
    this.daysContinuous$ = this.store.select(s => s.site).pipe(map(s => s.continuousRecordingDays), takeUntil(this.destroy$));
    this.store.dispatch(RawActions.hideTimeModal())
  }

  ngOnDestroy() {
    this.destroy$.next();
  }


  changeTime() {
    this.store.dispatch(RawActions.clickChangeTimeButton());
  }

  dateTimeSave(time: MotionDateTime) {
    this.store.dispatch(RawActions.submitTimeChange({selectedTime: time}))
  }

  dateTimeCancel() {
    this.store.dispatch(RawActions.hideTimeModal());
  }

  selectMap(map: Map) {
    this.currentMapID = map.id;
  }

  maxCameraModalClosed() {
    this.store.dispatch(RawActions.maxCameraModalClose());
  }

  descriptionChanged(value: string) {
    this.store.dispatch(RawActions.setDescription({description: value}))
  }

  resetCams() {
    this.cameraMarkers.forEach(marker => this.removeCamera(marker));
  }
  removeCamera(marker: RawRequestCameraMarker) {
    this.store.dispatch(RawActions.removeCamera({ marker: marker }));
    if (marker.map.id === this.currentMapID) {
      marker.map.draw();
    }
  }
  cancelTimeUpdateModal() {
    this.store.dispatch(RawActions.hideTimeUpdate());
  }
  updateTime() {
    this.store.dispatch(RawActions.futureTimeUpdate());
    this.store.dispatch(RawActions.submit());
  }
  newDate() {
    return new Date();
  }
  startSubmit() {
    this.store.dispatch(RawActions.startRequestSubmit());
  }
}
