import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import * as LPRActions from './store/actions';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/app.state';
import {
  selectSelectedBottomTab,
  selectSelectedTopTab,
  selectRecentHitsSorted,
  selectRecentHitsSort,
  selectActiveAlerts,
  selectAlertEntries,
  selectAlertDetailsSort,
  selectShowViewHitModal, 
  selectViewHitModalHit,  
  selectTags,
  selectAlertDetailsSorted,
  selectAlertDetailsSelectedEntry,
  selectLPRSiteID,
  selectEditEntry
} from './store/selectors';
import { map, takeWhile, takeUntil, take } from "rxjs/operators";
import { SortData } from './sort-data';
import { PermissionsService } from '../permissions.service';
import { AlertEntry } from './manage-alert-entries/alert-entry';
import { Site } from 'app/site/site';
import { AlertHit } from 'app/lpr/recent-hits/alert-hit';
import { Map } from 'app/map/map';
import { primaryCamera, selectedCameras } from 'app/camera/rewind/add-cams/store/selector';
import { AddCamsService } from 'app/camera/rewind/add-cams/add-cams.service';
import * as AddCamsActions from '../camera/rewind/add-cams/store/actions';
import * as addCamsSelectors from '../camera/rewind/add-cams/store/selector';
import { Camera } from 'app/camera/camera';
import { RewindRequest } from 'app/camera/rewind/rewind-request';
import { Cameras } from 'app/requests/new-request/cameras/cameras';
import { User } from 'app/users/user';
import { TimeObj } from 'app/time-obj';
import { RequestType } from 'app/requests/request-type';
import { FeatureService } from 'app/site-package/services';
import { FeatureType } from 'app/site-package/models';
import { NotificationsService } from 'app/notifications/notifications.service';
import { CameraService } from 'app/camera/camera.service';
import { Router } from '@angular/router';
import { Tag } from './manage-alert-entries/tag';


@Component({
  selector: 'app-lpr',
  templateUrl: './lpr.component.html',
  styleUrls: ['./lpr.component.scss']
})
export class LprComponent implements OnInit, OnDestroy {
  public selectedTopTab$: Observable<string>;
  public selectedBottomTab$: Observable<string>;
  public siteID$: Observable<number>;
  public site$: Observable<Site>;

  public entriesWithRecentHits$: Observable<AlertEntry[]>;
  public recentHitsSort$: Observable<SortData>;
  public alertDetailsSort$: Observable<SortData>;
  public alertDetailsSorted$: Observable<AlertHit[]>;
  private alive: boolean = true;
  public activeAlerts$: Observable<AlertEntry[]>;
  public alertDetailsSelectedEntry$: Observable<AlertEntry>;

  public hasCanManageLPR$: Observable<boolean>;
  public hasCanViewLPR$: Observable<boolean>;
  public hasCanCreateTickets$: Observable<boolean>;

  public tags$: Observable<Tag[]>;
  public editEntry$: Observable<AlertEntry>;
  public addCamerasModalOpened: boolean;
  public addCamsMaps$: Observable<Map[]>;
  public mapsWithSelectedCamerasIDs$: Observable<number[]>;
  public primaryCamMapID$: Observable<number>;
  public cameras$: Observable<Camera[]>;
  public user: User;
  public request: RewindRequest;
  public requestModalOpened: boolean;
  public submitInProgress: boolean = false;
  public isAutoRequest: boolean;
  public maxCameraModalVisible$: Observable<boolean>;
  public imgURL: string;
  public requestPlate: string;

  public detailHits$: Observable<AlertHit[]>;

  public viewHit$: Observable<AlertHit>;
  public showLprImageModal$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    public permissionsService: PermissionsService,
    private addCamsService: AddCamsService,
    private featureService: FeatureService,
    private notificationsService: NotificationsService,
    private cameraService: CameraService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.selectedTopTab$ = this.store.pipe(select(selectSelectedTopTab));
    this.selectedBottomTab$ = this.store.pipe(select(selectSelectedBottomTab));
    this.siteID$ = this.store.select(s => s.site).pipe(map(site => site.siteID));
    this.siteID$.pipe(takeWhile(() =>this.alive)).subscribe(siteID => {
      this.store.pipe(select(selectLPRSiteID), take(1)).subscribe(lprSiteID => {
        if(siteID !== lprSiteID) {
          this.store.dispatch(LPRActions.resetLPR());
          this.store.dispatch(LPRActions.setLPRSiteID({siteID: siteID}));
        }
      })
    })
    this.site$ = this.store.select(s => s.site)
    this.entriesWithRecentHits$ = this.store.pipe(select(selectRecentHitsSorted));
    this.recentHitsSort$ = this.store.pipe(select(selectRecentHitsSort));
    this.tags$ = this.store.pipe(select(selectTags));
    this.editEntry$ = this.store.pipe(select(selectEditEntry));
    this.store.dispatch(LPRActions.loadTags());
    this.alertDetailsSort$ = this.store.pipe(select(selectAlertDetailsSort));
    this.store.select(s => s.site.siteID).pipe(takeWhile(() => this.alive)).subscribe(siteID => {
      this.store.dispatch(LPRActions.recentHitsLoadHits({siteID: siteID}));
    })
    this.activeAlerts$ = this.store.pipe(select(selectAlertEntries));
    this.alertDetailsSorted$ = this.store.pipe(select(selectAlertDetailsSorted));
    this.hasCanManageLPR$ = this.siteID$.pipe(map(siteID => this.permissionsService.site(siteID, "canManageLPR")));
    this.hasCanViewLPR$ = this.siteID$.pipe(map(siteID => this.permissionsService.site(siteID, "canViewLPR")));
    this.hasCanCreateTickets$ = this.siteID$.pipe(map(siteID => this.permissionsService.site(siteID, "canCreateTickets")));

    this.addCamsMaps$ = this.addCamsService.getMaps();

    this.viewHit$ = this.store.pipe(select(selectViewHitModalHit));
    this.showLprImageModal$ = this.store.pipe(select(selectShowViewHitModal));

    this.alertDetailsSelectedEntry$ = this.store.pipe(select(selectAlertDetailsSelectedEntry));

    
    this.primaryCamMapID$ = combineLatest(
      this.store.pipe(select(primaryCamera)),
      this.addCamsMaps$,
    ).pipe(map(([pCam, maps]) => {
      for(let map of maps) {
          let markers = map.markersLocal.map(r => r.id);
          if(markers.indexOf(pCam.id) !== -1) {
              return map.id;
          }
      }

      return 0;
    }));
    this.mapsWithSelectedCamerasIDs$ = combineLatest(
      this.store.pipe(select(selectedCameras)),
      this.addCamsMaps$,
    ).pipe(map(([cams, maps]) => {
      let foundMapIDs = [];
      for (let map of maps) {
          for (let cam of cams) {
              if(map.markersLocal) {
                  let markerIDs = map.markersLocal.map(r => r.id)
                  if(markerIDs.indexOf(cam.id) !== -1) {
                      foundMapIDs.push(map.id);
                  }
              }
          }
      }
      return foundMapIDs;
    }));
    this.cameras$ = combineLatest(
      this.store.pipe(select(addCamsSelectors.selectedCameras)),
      this.store.pipe(select(addCamsSelectors.cameraData)),
    ).pipe(map(([markers, data]) => markers.filter(m => data[m.camera]).map(m => data[m.camera])));
    this.store.select(s => s.user).pipe(takeWhile(() => this.alive)).subscribe(user => {
      this.user = user;
    });
    this.isAutoRequest = !this.featureService.checkFeatureInPackage(FeatureType.FullRequest)
    this.maxCameraModalVisible$ = this.store.pipe(select(addCamsSelectors.maxCameraModalVisible));
    this.detailHits$ = this.store.pipe(select(selectAlertDetailsSorted));
  }

  ngOnDestroy() {
    this.alive = false;
    this.store.dispatch(LPRActions.showViewHitModal({show: false}));
  }

  public selectTopTab(tab: string) {
    this.store.dispatch(LPRActions.setTopTab({tab: tab}));
  }

  public selectBottomTab(tab: string) {
    if(tab === "addAlert") {
      this.store.dispatch(LPRActions.setEditEntry({entry: null}));
    }
    this.store.dispatch(LPRActions.setBottomTab({tab: tab}));
  }
  
  public onRecentHitsColumnClick(field: string) {
    this.store.dispatch(LPRActions.recentHitsClickColumn({field: field}));
  }

  public onAlertDetailsColumnClick(field: string) {
    this.store.dispatch(LPRActions.alertDetailsClickColumn({field: field}));
  }

  public onRecentAlertClick(entry) {
    this.store.dispatch(LPRActions.setTopTab({tab: "alertDetails"}));
    this.store.dispatch(LPRActions.alertDetailsSelectAlert({entry: entry}));

  }

  onRequestModalClose() {
    this.requestModalOpened = false;
    this.imgURL = ""
  }
  public onStartRequest(hit: AlertHit) {
    let siteID;
    this.siteID$.pipe(take(1)).subscribe(s => {
      siteID = s;
    })
    this.requestPlate = hit.plate
    this.imgURL = `/rest/site/${siteID}/lpr/image/${hit.img}`
    let req = new RewindRequest();
    req.when.start = hit.createdDate;
    req.when.end = hit.createdDate;
    req.siteID = hit.siteID;
    let maps = [];
    this.addCamsMaps$.pipe(take(1)).subscribe(m => maps = m);
    this.store.dispatch(AddCamsActions.setPrimaryCamera({ marker: this.addCamsService.getMarker(hit.cameraID, maps) }));
    this.store.dispatch(AddCamsActions.resetCameras());
    this.store.dispatch(AddCamsActions.loadCamera({ cameraID: hit.cameraID }));
    let markers;
    this.store.pipe(select(addCamsSelectors.selectedCameras), take(1)).subscribe(s => {
      markers = s;
    });

    req.cameras = new Cameras(markers);
    req.contactInfo.user = this.user;
    this.request = req;
    this.requestModalOpened = true;
  }

  submit(req) {
    this.submitInProgress = true;
    let request = Object.assign(new RewindRequest(), req);
    request.when = {};
    request.when.start = new TimeObj(req.when.start);
    request.when.end = new TimeObj(req.when.end);
    request.requestType = RequestType.LPR;
    request.isAutoRequest = this.isAutoRequest;
    let markers;
    this.store.pipe(select(addCamsSelectors.selectedCameras), take(1)).subscribe(s => {
      markers = s;
    });
    request.cameras = new Cameras(markers);
    let primaryCameraID: number = -1;
    this.store.pipe(select(addCamsSelectors.primaryCamera), take(1)).subscribe(primaryCamera => {
      if(primaryCamera) {
        primaryCameraID = primaryCamera.camera;
      }
    });
    request.primaryCameraID = primaryCameraID;
    
    let img
    if (request.image) {
      img = request.image;
    }

    delete request.image;
    let siteID;
    this.siteID$.pipe(take(1)).subscribe(id => {
      siteID = id;
    })
    this.cameraService.expressRequest(request).subscribe(resp => {
      if (img) {
        this.cameraService.uploadImage(resp.eventID, img).subscribe(resp => {
          this.notificationsService.success("", "Request Submitted");
          this.submitInProgress = false;
          this.requestModalOpened = false;
          this.router.navigate(['site', siteID, 'events']);
        }, err => {
          this.submitInProgress = false;
          this.notificationsService.error("", "Error uploading image");
          console.error(err);
        })
      } else {
        this.notificationsService.success("", "Request Submitted");
        this.submitInProgress = false;
        this.requestModalOpened = false;
        this.router.navigate(['site', siteID, 'events']);
      }
    }, err => {
      this.submitInProgress = false;
      this.notificationsService.error("", "Error Submitting Request")
    })

  }

  public onAddCamerasCancel() {
    this.addCamerasModalOpened = false;
    this.store.dispatch(AddCamsActions.resetCameras());

  }

  public onAddCamerasSave() {
    this.addCamerasModalOpened = false;
  }

  public onAddCameras() {
    this.addCamerasModalOpened = true;
  }
  maxCameraModalClose() {
    this.store.dispatch(AddCamsActions.maxCameraModalClose());
  }

  public onViewHit(hit: AlertHit) {
    this.store.dispatch(LPRActions.viewHitClicked({hit: hit}));
  }
  public onCloseViewModal() {
    this.store.dispatch(LPRActions.showViewHitModal({show: false}));
  }
  public onAlertDetailsSelectEntry(entry: AlertEntry) {
    this.store.dispatch(LPRActions.alertDetailsSelectAlert({entry: entry}));
  }
}
