import { Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { concatMap, first, startWith, switchMap } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { interval } from "rxjs";
import { TileLayoutReorderEvent, TileLayoutResizeEvent } from '@progress/kendo-angular-layout';
import { TileLayoutGap } from '@progress/kendo-angular-layout';
import { takeUntil, map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Layout } from '../layout';
import { AppState } from 'app/app.state';
import { selectGridSizes, selectSelectedLayout } from '../store/selectors';
import { loadGridSizes, loadLayout, setSelectedLayout } from '../store/actions';
import { CameraView } from '../camera-view';
import { CvsService } from '../cvs.service';


@Component({
  selector: 'cvs-view',
  templateUrl: './cvs-view.component.html',
  styleUrls: ['./cvs-view.component.scss']
})
export class CvsViewComponent implements OnInit, OnDestroy {
  public layoutID: number;
  public siteID: number;
  public layoutName: string;
  public showCameraName: boolean;
  public columns: number;
  public rows: number;
  public cameras: CameraView[] = [];
  private emptyCams: CameraView[] = [];
  private camPositions: number[] = [];
  public loaded: boolean = false;
  public cameraURLMap: {} = {};
  public cameraAuthMap: {} = {};
  public gap: TileLayoutGap = {
    rows: 0,
    columns: 0
  };
  public cvsDisabled: boolean = false;
  
  private siteSub: Subscription;

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private cvsService: CvsService,
  ) { }

  ngOnInit(): void {
    this.route.params
      .pipe(
        switchMap((params) => {
          this.loaded = false;

          this.store.dispatch(setSelectedLayout({layout: null}));

          const layoutID = +params["layoutID"];
          const siteID = +params["siteID"];

          this.layoutID = layoutID;
          this.siteID = siteID;

          this.siteSub = interval(30000).pipe(startWith(0), takeUntil(this.destroy$))
            .subscribe(() => {
              this.cvsService.getSiteCVSStatus(this.siteID).subscribe(status => {
                this.cvsDisabled = status.disabled;
              });
            });

          this.store.dispatch(loadLayout({siteID: siteID, layoutID: layoutID, withCameras: true}));
          this.store.dispatch(loadGridSizes({siteID: siteID}));

          return combineLatest([
            this.store.pipe(select(selectSelectedLayout), first((layout) => !!layout && layout.id === layoutID)),
            this.store.pipe(select(selectGridSizes), first((gridSizes) => !!gridSizes && gridSizes.length > 0)),
          ]);
        }),
        switchMap(([layout, gridSizes]) => {
          this.layoutName = layout.name;
          this.showCameraName = layout.showCameraName;

          gridSizes.forEach(gridSize => {
            if (gridSize.id === layout.gridSizeID) {
              this.columns = gridSize.columns;
              this.rows = gridSize.rows;
            }
          });

          if (!layout.cameraViews || layout.cameraViews.length === 0) {
            return of(layout);
          }

          const cameraIDs = layout.cameraViews.map(c => c.cameraID).filter((value, index, self) => self.indexOf(value) === index);
          return of(layout);
        }),
        takeUntil(this.destroy$),
    ).subscribe((layout) => {
      this.buildCameraLists(layout);
      this.loaded = true;
    });
  }

  ngOnDestroy(): void {
    this.loaded = false;
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onReorder(e: TileLayoutReorderEvent): void {
    this.log(e, 'reorder');
  }

  public onResize(e: TileLayoutResizeEvent): void {
    this.log(e, 'resize');
  }

  public clickCam(cam: CameraView): void {
    if(cam && cam.cameraID) {
      window.open(`/site/${this.siteID}/cameras/${cam.cameraID}/live-view`, '_blank')
    }
  }

  private buildCameraLists(layout: Layout): void {
    this.cameras = [];
    this.emptyCams = [];
    this.camPositions = [];
    this.cameraURLMap = {};
    this.cameraAuthMap = {};
    const numberOfLayoutPositions = this.columns*this.rows;

    if (layout.cameraViews) {
        layout.cameraViews.forEach(c => {
          let cam = Object.assign(new CameraView(), c);
          this.camPositions.push(cam.gridPosition - 1);
          this.cameras.push(cam);
        });
  
        for (let i = 0; i < numberOfLayoutPositions; i++ ) {
          if (!this.camPositions.includes(i)) {
            var empty = new CameraView();
            empty.gridPosition = i+1;
            this.emptyCams.push(empty);
          }
        }
  
        if (this.emptyCams.length > 0) {
          this.cameras = this.cameras.concat(this.emptyCams);
          this.cameras.sort((a, b) => (a.gridPosition > b.gridPosition) ? 1 : -1);
        }
    } else {
      for (let i = 0; i < numberOfLayoutPositions; i++ ) {
        if (!this.camPositions.includes(i)) {
          var empty = new CameraView();
          empty.gridPosition = i+1;
          this.emptyCams.push(empty);
        }
      }

      this.cameras = this.cameras.concat(this.emptyCams);
    }
  }

  private log(event: TileLayoutReorderEvent | TileLayoutResizeEvent, eventName: string): void {
      // Check the developer tools console to inspect the full available event data
      // console.log(eventName, event);
  }
}
