// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .seen {
    background-color: #D3D3D3;
}

.clickable:hover {
    background-color: #f5f5f5;
   
}

.btn {
    background-color: #fff;
    border-color: #ccc;
}

.btn:hover {
    background-color: #f5f5f5ee;
    border: 1px solid #000;
} 
`, "",{"version":3,"sources":["webpack://./src/app/site/site-dashboard/dashboard-recent-events/dashboard-recent-events.component.css"],"names":[],"mappings":"CAAC;IACG,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;;AAE7B;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,sBAAsB;AAC1B","sourcesContent":[" .seen {\r\n    background-color: #D3D3D3;\r\n}\r\n\r\n.clickable:hover {\r\n    background-color: #f5f5f5;\r\n   \r\n}\r\n\r\n.btn {\r\n    background-color: #fff;\r\n    border-color: #ccc;\r\n}\r\n\r\n.btn:hover {\r\n    background-color: #f5f5f5ee;\r\n    border: 1px solid #000;\r\n} \r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
