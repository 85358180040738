import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { User, getName } from '../users/user';
import { UserService } from '../users/user.service';
import { Logger } from '../logger/logger.service';
import { AppState } from '../app.state';
import { SiteService } from '../site/site.service';
import { EventService } from './event.service';
import { Site } from '../site/site';
import { Request } from './request';
import { 
    Filter, 
    SET_EVENTS_SORT_FIELD, 
    SET_ALL_REQUESTS, 
    SET_EVENTS_FILTER_ONLY_MINE, 
    SET_EVENTS_FILTER_ONLY_MINE_OFF, 
    SET_EVENTS_FILTER_STATUS,
    SET_EVENTS_FILTER_STATUS_OFF,
    SET_EVENTS_FILTER_TIME,
    SET_EVENTS_FILTER_TIME_OFF,
    SET_REQUESTS_LOADING,
    SET_REQUESTS_LOADED,
    SET_EVENTS_FILTER_SEARCH,
} from './event.reducer';

import { NotificationsService } from 'app/notifications/notifications.service';
import { Observable } from 'rxjs';
import { selectFilteredSortedRequests, selectRequestsFilter } from './event.selector';
import { FeatureService } from 'app/site-package/services';
import { PermissionsService } from 'app/permissions.service';
import { FeatureType } from 'app/site-package/models';


@Component({
    selector: 'app-event',
    templateUrl: './event.component.html',
    styleUrls: ['./event.component.css'],
})
export class EventComponent implements OnInit, OnDestroy {
    public siteSub;
    public site: Site;
    public requests: Observable<Request[]>;
    public filter: Observable<Filter>;
    public statuses = [];
    public loading: Observable<boolean>;
    public activity: {[eventID: number]: boolean};

    FeatureType = FeatureType;

    constructor(
        public router: Router, 
        public siteService: SiteService, 
        public eventService: EventService, 
        public store: Store<AppState>, 
        public logger: Logger, 
        public userService: UserService,
        public notificationsService: NotificationsService,
        public permissions: PermissionsService,
        public features: FeatureService,
    ) {}

    ngOnInit() {
        this.siteSub = this.siteService.getSite().subscribe(site => {
            this.site = site;
            this.store.dispatch({type: SET_ALL_REQUESTS, payload: []});
            this.store.dispatch({type: SET_REQUESTS_LOADING});
            this.eventService.getRequests(site.siteID).subscribe(requests => {
                this.store.dispatch({type: SET_ALL_REQUESTS, payload: requests}); 
                this.store.dispatch({type: SET_REQUESTS_LOADED});
            }, err => {
                this.store.dispatch({type: SET_REQUESTS_LOADED});
                this.logger.error(err)
            });
            this.eventService.getRequestsActivity(site.siteID).subscribe(act => this.activity = act)            
        });

        this.requests = this.store.pipe(select(selectFilteredSortedRequests));
        this.eventService.getStatuses().subscribe(statuses => this.statuses = statuses.filter(status => status.name !== "Deleted"));
        this.loading = this.store.select(s => s.requestsLoading);
        this.filter = this.store.pipe(select(selectRequestsFilter));
        
        this.logger.trackAction(3, this.site.siteID).subscribe();
    }
    ngOnDestroy() {
        if(this.siteSub) {
            this.siteSub.unsubscribe();
        }
    }

    getName(user: User):string {
        return getName(user);
    }

    searchChange(search: string) {
        this.store.dispatch({type: SET_EVENTS_FILTER_SEARCH, payload: search});
    }
    setOnlyMine(value) {
        if(value) {
            this.store.dispatch({type: SET_EVENTS_FILTER_ONLY_MINE });
        } else {
            this.store.dispatch({type: SET_EVENTS_FILTER_ONLY_MINE_OFF });
        }

    }
    setSort(field) {
        this.store.dispatch({type: SET_EVENTS_SORT_FIELD, payload: field});
    }
    setTime(value) {
        if(value === -1) {
            this.store.dispatch({type: SET_EVENTS_FILTER_TIME_OFF });
            return;
        }
        this.store.dispatch({type: SET_EVENTS_FILTER_TIME, payload: value});
    }
    setStatus(value) {
        if(value === -1) {
            this.store.dispatch({type: SET_EVENTS_FILTER_STATUS_OFF });
            return;
        }
        this.store.dispatch({type: SET_EVENTS_FILTER_STATUS, payload: value});
    }
    gotoEvent(eventID) {
        this.router.navigate(["site", this.site.siteID, "events", eventID]);
    }
    getStatusName(id: number): string {
        let name = "";
        this.statuses.forEach(status => {
            if(status.id === id) {
                name = status.name;
            }
        })
        return name;
    }
    star(request: Request) {

        request.cStarred = true;
        this.eventService.starEvent(request.eventID, this.site.siteID).subscribe(event => {

        }, e => {
            request.cStarred = false;
            this.notificationsService.error("", "Error starring event");
        })
    }
    unstar(request: Request) {
        request.cStarred = false;
        this.eventService.unstarEvent(request.eventID, this.site.siteID).subscribe(event => {

        }, e => {
            request.cStarred = true;
            this.notificationsService.error("", "Error unstarring event");
        })
    }

    requestNewReview() {
        this.router.navigate(["site", this.site.siteID, "request", "new-request", "when"])
    }
}