import { Store } from '@ngrx/store';

import { AppState } from '../app.state';
import { Map } from '../map/map';
import { ADD_MOUSEOVER_EVENT, REMOVE_MOUSEOVER_EVENT } from '../events/event.reducer';
import { Marker, MarkerConfig } from '../markers/marker';
import { MarkerEventService } from '../markers/marker-event.service';


export class CameraMarker extends Marker{
    public id: number;
    public camera: number;
    public on = false;
    public type = 'camera';
    public store: Store<AppState>
    get x() {
        return this.shapeObj.x;
    }
    get y() {
        return this.shapeObj.y;
    }
    constructor(config: CameraMarkerConfig) {
        super(config);
        this.camera = config.camera;
    }
    getFillColor() {
        switch(this.shapeObj.type) {
            case 'poly':
                let color = "rgb(36,163,242)";
                if(this.meta && this.meta[0] && this.meta[0]['colorHex']) {
                    for(let i=0;i <this.meta.length; i++) {
                        if(this.meta[i] && this.meta[i]['colorHex'] && !this.meta[i].hideInPortal) {
                            color = this.meta[i]['colorHex'];
                            break;
                        }
                    }
                }
                return color;
            case 'circle':
                return "black";
            case 'rect':
                return "rgba(36,163,242,.5)";
            default:
                return "red";
        }
    }
    getFill() {
        //TODO: Find some way to move gradient generation into the shapeObj but keep color choice out here
        let on = this.isCurrentMouseOver();
        switch(this.shapeObj.type) {
            case 'poly':
                let shapeObj: any = this.shapeObj;
                let grd = this.shapeObj.map.ctx.createRadialGradient(this.shapeObj.data[0].x, this.shapeObj.data[0].y, 5, this.shapeObj.data[0].x, this.shapeObj.data[0].y, shapeObj.distance + 50)
                if(on) {
                    grd.addColorStop(0,this.getFillColor())
                } else {
                    grd.addColorStop(0,this.getFillColor());
                    grd.addColorStop(1, 'rgba(0,0,0,0)');
                }
                return grd;
            case 'circle':
                return this.getFillColor();
            case 'rect':
                return this.getFillColor();
            default:
                return "red";
        }
    }
    getStroke() {
//        return "rgba(0,0,0,0)"
        return "black"
    }
    clicked() {
        this.eventService.sendEvent({marker: this, payload: this.camera, type: 'camera'})
    }
}
export class CameraMarkerConfig extends MarkerConfig {
    public camera: number;
}