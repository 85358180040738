import { catchError, filter, first } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, Observer } from "rxjs";
import { Store } from "@ngrx/store";

import { AppState } from "../app.state";
import { UserService } from "../users/user.service";
import { SiteService } from "./site.service";
import { ServiceHelpersService } from "../service-helpers.service";
import { SET_SITE, SITE_DONE_LOADING } from "./site.reducer";
import { Site } from "./site";
import { HttpResponse } from "@angular/common/http";
import { PermissionsService } from "../permissions.service";
import { UserHardType } from "app/constants";

@Injectable()
export class SiteLoaderGuard  {
  constructor(
    public userService: UserService,
    public router: Router,
    public siteService: SiteService,
    public store: Store<AppState>,
    public serviceHelpers: ServiceHelpersService,
    public permissionsService: PermissionsService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return Observable.create((observer: Observer<boolean>) => {
      this.userService
        .getUser()
        .pipe(filter((user) => !user.initial), first())
        .subscribe((user) => {
          //could be improved by checking to see if the right site is already loaded
          this.siteService
            .fetchSiteForLoader(+route.params["siteID"])
            .pipe(catchError(this.serviceHelpers.handleError))
            .subscribe((response) => {
              this.handleSiteResponse(response);

              let site = response.body;
              site = new Site(site);

              this.store.dispatch({ type: SET_SITE, payload: site });
              this.store.dispatch({ type: SITE_DONE_LOADING, payload: {} });

              if (!site.allowLEAccess) {
                if (user.typeHard.id === UserHardType.Police) {
                  this.router.navigate(["dashboard"]);
                }
              }

              if (site.isDisabled && !this.permissionsService.admin("isAdmin")) {
                this.router.navigate(['site-disabled']);
              } else if (site.status === 'inactive' && !this.permissionsService.admin("isAdmin")) {
                this.router.navigate(['dashboard']);
              } else {
                observer.next(true);
              }

              observer.complete();
            });
        });
    }).pipe(
      catchError((error: any) => {
        this.router.navigate(["site-not-found"]);

        return new Observable<boolean>((observer) => {
          observer.next(false);
          observer.complete();
        });
      }),
      first(),
    );
  }
  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
  private handleSiteResponse(response: HttpResponse<Site>) {
    if (response.status < 200 || response.status >= 300) {
      this.router.navigate(["site-not-found"]);
      throw new Error("bad response status: " + response.status);
    }
  }
}
