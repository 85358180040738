import { Injectable, NgZone } from "@angular/core";
import { Store } from "@ngrx/store";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable, Subject, throwError } from "rxjs";
import { AppState } from "../app.state";
import { ServiceHelpersService } from "../service-helpers.service";
import { AccessKeyService } from "../users/access-key.service";
import { EventSynopsis } from "./event-synopsis/event-synopsis";

@Injectable()
export class AIService {
    public headers = {};
    constructor(public http: HttpClient, public store: Store<AppState>, public accessKeyService: AccessKeyService, public serviceHelpers: ServiceHelpersService, public zone: NgZone) {
        this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key }));
    }

    generateEventSynopsis(eventIDs: number[], detailLevel: string, descriptionSelector: string, categoryIDs: number[] = []): Observable<EventSynopsis> {
        let headers = new HttpHeaders(this.headers);
        const body = {
            eventIDs: eventIDs,
            detailLevel: detailLevel,
            descriptionSelector: descriptionSelector,
            categoryIDs: categoryIDs
        };
        
        return this.http.post<EventSynopsis>("api/ai/summarize/events", body, { headers: headers }).pipe(catchError(this.serviceHelpers.handleError));
    }

    generateEventSynopsisStream(eventIDs: number[], detailLevel: string, descriptionSelector: string, categoryIDs: number[] = []): Observable<{ requestId: string }> {
        let headers = new HttpHeaders(this.headers);
        const body = {
            eventIDs: eventIDs,
            detailLevel: detailLevel,
            descriptionSelector: descriptionSelector,
            categoryIDs: categoryIDs
        };

        return this.http.post<{ requestId: string }>("api/ai/summarize/events/start", body, { headers: headers }).pipe(
            catchError(this.serviceHelpers.handleError)
        );
    }
}