import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { AppState } from "app/app.state";
import * as RawActions from './actions';
import { concatMap, withLatestFrom, map } from "rxjs/operators";
import { of } from "rxjs";
import { isCameraSelected, numCamsSelected, selectRawRequest, selectedTime, rawSiteID } from "./selectors";
import { RawRequestService } from "../raw-request.service";
import { MotionDateTime } from "app/motion/motion-datetime-modal/motion-datetime";
import { TimeObj } from "app/time-obj";


@Injectable()
export class RawRequestEffects {
    clickCamera$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RawActions.clickCamera),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(isCameraSelected(action.marker.id)))),
                    withLatestFrom(this.store.pipe(select(numCamsSelected))),
                    withLatestFrom(this.store.select(s => s.site).pipe(map(s => s.autoRequestAdditionalCameras))),
                )
            }),
            map(([[[action, isSelected], numCamsSelected], numAddCams]) => {
                if(isSelected) {
                    return RawActions.removeCamera({marker: action.marker});
                }
                if(numCamsSelected < (numAddCams + 1)) {
                    return RawActions.addCamera({marker: action.marker});
                }
                return RawActions.maxCameraModalOpen();
            })
        )

    })
    startSubmit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RawActions.startRequestSubmit),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectedTime)))
                )
            }),
            map(([action, selectedTime]) => {
                if(selectedTime.motionEndDate.toDate().getTime() > new Date().getTime()) {
                    return RawActions.showTimeUpdate();
                } else {
                    return RawActions.submit();
                }
            })
        )
    })
    submit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RawActions.submit),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectRawRequest))),
                    withLatestFrom(this.store.select(s => s.site.siteID)),
                    withLatestFrom(this.store.select(s => s.user)),
                )
            }),
            map(([[[action, raw], siteID], user]) => {
                this.rawRequestService.submit(raw.selectedTime.motionStartDate, raw.selectedTime.motionEndDate, raw.selectedCameras, raw.description, siteID, user);
                return RawActions.requestSubmited();
            })
        )
    })
    futureTimeUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RawActions.futureTimeUpdate),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectedTime))),
                )
            }),
            map(([action, time]) => {
                let date = new Date();
                if(time.motionEndDate.toDate().getTime() > date.getTime() ) {
                    let t = new MotionDateTime(time);
                    t.motionEndDate = new TimeObj(date);
                    return RawActions.submitTimeChange({selectedTime: t});
                }
                return RawActions.noAction();
            })
        )
    })

    loadRawForSite$ = createEffect( () => {
        return this.actions$.pipe(
            ofType(RawActions.loadingRawForSite),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(rawSiteID))),
                )
            }),
            map(([action, siteID]) => {
                console.log(action, siteID)
                if(action.siteID !== siteID) {
                    this.store.dispatch(RawActions.resetRaw());
                }
                return RawActions.setRawSiteID({siteID: action.siteID});
            })
        )
    });    
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private rawRequestService: RawRequestService,
    ) {}
}