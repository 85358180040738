import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "../app.state";
import { MetricsService } from "./metrics.service";
import { PeopleCamera } from "./counting/people-camera";
import { SET_SELECTED_METRIC } from "./metrics.reducer";
import { Observable, Subject } from "rxjs";
import { FeatureType } from "app/site-package/models";
import { takeUntil } from "rxjs/operators";
import { PermissionsService } from "app/permissions.service";
import { FeatureService } from "app/site-package/services";
import { Site } from "app/site/site";
import { SiteService } from "app/site/site.service";


@Component({
  selector: "app-metrics",
  templateUrl: "./metrics.component.html",
  styleUrls: ["./metrics.component.css"],
})
export class MetricsComponent implements OnInit, OnDestroy {
  public siteID: number;
  public peopleCameras: PeopleCamera[] = [];
  public hasPeopleCameras: boolean = false;
  public carCountingCameras = [];
  public hasCarCameras: boolean = false;
  public selectedMetric: Observable<string>;
  public peoplePerm: boolean;
  public carPerm: boolean;
  public eventPerm: boolean;
  public hasFullServiceFeature: boolean;

  private currentSite: Site;
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private metricsService: MetricsService,
    private route: ActivatedRoute,
    private permissionsService: PermissionsService,
    private featureService: FeatureService,
    private siteService: SiteService,
  ) {}

  public ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.siteID = +params["siteID"];
      this.peoplePerm = this.permissionsService.site(this.siteID, "peopleCounting");
      this.carPerm = this.permissionsService.site(this.siteID, "carCounting");
      this.eventPerm = this.permissionsService.site(this.siteID, "canViewTickets");
    });

    this.siteService
      .getSite()
      .pipe(takeUntil(this.destroy$))
      .subscribe((site) => {
        this.currentSite = site;
        this.hasFullServiceFeature = this.featureService.checkFeatureInPackage(
          FeatureType.FullRequest,
          this.currentSite.package,
        );
      });

    this.selectedMetric = this.store.select((s) => {
      return s.selectedMetric;
    });

    this.metricsService
      .getPeopleCountingCamerasBySite(this.siteID)
      .pipe(takeUntil(this.destroy$))
      .subscribe((cams) => {
        this.peopleCameras = cams;
        if (cams.length > 0) {
          this.hasPeopleCameras = true;
        }
      });

    this.metricsService
      .getCarCountCameras(this.siteID)
      .pipe(takeUntil(this.destroy$))
      .subscribe((cams) => {
        if (cams.alprCameras !== null) {
          this.hasCarCameras = true;
        }
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public selectMetric(metric: string) {
    this.store.dispatch({ type: SET_SELECTED_METRIC, payload: metric });
  }
}
