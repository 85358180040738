import { DataBindingDirective, GridComponent } from "@progress/kendo-angular-grid";
import { Directive, OnInit, OnDestroy } from "@angular/core";
import SitePackage from "../../../site-package/models/site-package";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Store, select } from "@ngrx/store";
import * as fromSitePackages from "../../../site-package/reducers";
import { SitePackageViewActions } from "app/site-package/actions";

@Directive({
  selector: "[sitePackagesBinding]",
})
export class SitePackagesBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  private _sitePackages$: Observable<SitePackage[]>;

  constructor(private _sitePackageStore$: Store<fromSitePackages.State>, private _grid: GridComponent) {
    super(_grid);
  }

  public ngOnInit() {
    this._sitePackages$ = this._sitePackageStore$.pipe(select(fromSitePackages.selectAllSitePackages));

    this.rebind();

    this._sitePackages$.pipe(takeUntil(this._destroy$)).subscribe((packages) => {
      this._grid.loading = false;
      this._grid.data = packages;
      this.notifyDataChange();
    });

    super.ngOnInit();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public rebind() {
    this._grid.loading = true;

    this._sitePackageStore$.dispatch(SitePackageViewActions.loadSitePackages());
  }
}
