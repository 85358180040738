import { Component, OnInit, Input } from '@angular/core';
// import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-contains-filter',
  templateUrl: './contains-filter.component.html',
  styleUrls: ['./contains-filter.component.css']
})
export class ContainsFilterComponent extends BaseFilterCellComponent implements OnInit {

  @Input() public valueField: string;
  // @Input() public filter: any;// CompositeFilterDescriptor;
  @Input("filter") public set setfilter(value: any) {
    this.filter = value;
    for(let i = 0; i < value.filters.length; i++) {
      if(value.filters[i].field === this.valueField) {
        this.value = value.filters[i].value;
      }
    }
  };
  public filter: any;
  public value: string;
  constructor(filterService: FilterService) {
    super(filterService);
  }

  ngOnInit() {
  }
  public onChange(value: any): void {
    console.log("OnChange: ", value)
    this.applyFilter(
      value === "" || value === null ?
        this.removeFilter(this.valueField) :
        this.updateFilter({
          field: this.valueField,
          operator: "contains",
          value: value,
        })
    )
  }
}
