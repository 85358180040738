
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs';

import { SiteListService } from '../../sites/site-list.service';
import { UserService } from '../../users/user.service';
import { UserType } from '../../users/user-type';
import { MessageFormComponent } from './message-form/message-form.component';
import { AdminService, SentMessage } from '../admin.service';
import { NotificationsService } from '../../notifications/notifications.service';

@Component({
    selector: 'app-admin-messages',
    templateUrl: './admin-messages.component.html',
    styleUrls: ['./admin-messages.component.css']
})
export class AdminMessagesComponent implements OnInit, OnDestroy {
    sites: Observable<{ text: string, id: number }[]>;
    users: Observable<{ text: string, id: number }[]>;
    softTypes: Observable<UserType[]>;
    hardTypes: Observable<UserType[]>;
    show: boolean;
    messages: SentMessage[]= [];
    message: SentMessage = {text: '', subject: ''};
    showMessage: boolean;
    recipientsShown = {};
    recipients = {};
    messagesSub;
    @ViewChild('messageForm', {static: true}) messageForm: MessageFormComponent;
    constructor(public siteListService:SiteListService, public userService: UserService, public adminService: AdminService, public notificationsService: NotificationsService) {}

    ngOnInit() {
        this.sites = this.siteListService.getSiteList().pipe(map(sites => {
            return sites.map(site =>{
                return {text: site.siteName, id: site.siteID }
            })
        }));
        this.users = this.userService.getUsers().pipe(map(users => {
            return users.map(user => {
                let site = "";
                if(user.sites) {
                    if(user.sites.length === 1) {
                        site = user.sites[0].siteName;
                    } else if(user.sites.length > 1) {
                        site = "multiple";
                    } else {
                        site = "none";
                    }
                }
                return {text: user.userName + " (" + site + ")", id: user.id}
            })
        }))
        this.softTypes = this.userService.getTypes();
        this.hardTypes = this.userService.getHardTypes();
        this.messagesSub = this.adminService.getMessages().subscribe(messages => this.messages = messages, error => this.notificationsService.error("", "Error getting messages"));
    }
    ngOnDestroy() {
        if(this.messagesSub) {
            this.messagesSub.unsubscribe();
        }
    }
    send(message) {
        this.show = false;
        this.messageForm.clear();
        this.adminService.createMessage(message).subscribe(resp => {
            this.notificationsService.success("", "Message Sent")
            if(this.messagesSub) {
                this.messagesSub.unsubscribe();
            }
            this.adminService.getMessages().subscribe(messages => this.messages = messages)
        }, error => this.notificationsService.error("", "Error sending message"));
    }
    showModal() {
        this.show = false;
        this.show = true;
    }
    viewMessage(message) {
        this.message = message;
        this.showMessage = true;
    }
    viewRecipients(message) {
        if(!this.recipients[message.id]) {
            this.adminService.getRecipients(message.id).subscribe(recips => this.recipients[message.id] = recips, error => this.notificationsService.error("","Error getting recipients"))
        } 
            this.recipientsShown[message.id] = !this.recipientsShown[message.id];
    }
    delete(message) {
        this.adminService.deleteMessage(message.id).subscribe(res => {
            this.adminService.getMessages().subscribe(messages => {
                this.messages = messages;
            })
        })
    }
}