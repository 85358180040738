export class EventSearch {
    public outcome: string;
    public managementCompanyID: number;
    public siteID: number;
    public search: string;
    public isStarred: boolean;
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config);
        }
    }
}