import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ImageInfo } from './image-info';
import { AlertHit } from 'app/lpr/recent-hits/alert-hit';
import { AlertEntry } from 'app/lpr/manage-alert-entries/alert-entry';

@Component({
  selector: 'app-lpr-image-modal',
  templateUrl: './lpr-image-modal.component.html',
  styleUrls: ['./lpr-image-modal.component.scss']
})
export class LprImageModalComponent implements OnInit {
  @Input("hit") hit: AlertHit;
  @Input("entry") entry: AlertEntry;
  @Input("opened") opened: boolean = false;
  @Input("admin") admin: boolean = false;

  @Output('hide') hideEmitter = new EventEmitter();
  constructor() { }

  ngOnInit() {
    if (this.hit === undefined) {
      console.log("Tried to open show image modal without an image")
      this.opened = false;
    }
  }

  closeShowImage() {
    this.hideEmitter.emit();
  }

}
