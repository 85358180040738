import { createAction, props } from "@ngrx/store";
import { State } from '@progress/kendo-data-query';
import { SiteGroup, SiteGroupFull } from '../site-group';
import { Lookup } from "app/lookup";


export const noAction = createAction("[SiteGroups] No Action");


export const loadSiteGroups = createAction("[SiteGroups] Load Site Groups");

export const setSiteGroups = createAction("[SiteGroups] Set Site Groups", props<{siteGroups: SiteGroup[]}>());

export const setSiteGroupsLoading = createAction("[SiteGroups] Set Site Groups Loading", props<{loading: boolean}>());

export const setSiteGroupsGridState = createAction("[SiteGroups] Set Site Groups Grid State", props<{gridState: State}>());

export const setShowCreateSiteGroupModal = createAction("[SiteGroups] Set Show Create Site Group Modal", props<{show: boolean}>());

export const createSiteGroup = createAction("[SiteGroups] Create Site Group", props<{siteGroup: SiteGroup}>());

export const appendSiteGroup = createAction("[SiteGroups] Append Site Group To Site Groups List", props<{siteGroup: SiteGroup}>());


// Edit Site Group
export const clickEditSiteGroup = createAction("[SiteGroups] Click Edit Site Group", props<{siteGroupID: number}>());

export const setEditGroup = createAction("[SiteGroups] Set Edit Group", props<{editGroup: SiteGroupFull}>());

export const setEditGroupLoading = createAction("[SiteGroups] Set Edit Group Loading", props<{editGroupLoading: boolean}>());

export const addEditSelectedSite = createAction("[SiteGroups] Add Edit Selected Site", props<{site: Lookup}>());

export const removeEditSelectedSite = createAction("[SiteGroups] Remove Edit Selected Site", props<{site: Lookup}>());

export const setEditSelectedSites = createAction("[SiteGroups] Set Edit Selected Sites", props<{sites: Lookup[]}>());

export const setEditSaving = createAction("[SiteGroups] Set Edit Saving", props<{editSaving: boolean}>());

export const setEditSites = createAction("[SiteGroups] Set Edit Sites", props<{sites: Lookup[]}>());

export const loadEditSites = createAction("[SiteGroups] Load Edit Site Groups");

export const editSave = createAction("[SiteGroups] Edit Save");

export const setEditName = createAction("[SiteGroups] Set Edit Name", props<{name: string}>());

export const editSuccess = createAction("[SiteGroups] Edit Success", props<{siteGroup: SiteGroup}>());

export const editFailed = createAction("[SiteGroups] Edit Failed");

export const setEditSiteFilter = createAction("[SiteGroup] Set Edit Site Filter", props<{filter: string}>());

// Delete Site Group
export const setShowDeleteSiteGroupModal = createAction("[SiteGroups] Set Show Delete Site Group Modal", props<{show: boolean}>());

export const getSiteGroupSelectedForDelete = createAction("[SiteGroups] Get Site Group Selected For Delete", props<{id: number}>());

export const setSiteGroupSelectedForDelete = createAction("[SiteGroups] Set Site Group Selected For Delete", props<{group: SiteGroupFull}>());

export const deleteSiteGroup = createAction("[SiteGroups] Delete Site Group", props<{id: number}>());

export const removeDeletedSiteGroup = createAction("[SiteGroups] Remove Deleted Site Group From Site Groups List", props<{id: number}>());


