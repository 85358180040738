export class Message {
  id: number;
  messageID: number;
  text: string;
  subject: string;
  seen: boolean;
  createdBy: number;
  createdDate: Date;
  expDate?: Date;
  importanceLevel: number;
  site?: { siteID: number; name: string; displayName: string };
  deleted: boolean;

  constructor(data: Message) {
    Object.assign(this, data);
    this.createdDate = new Date(this.createdDate);
    if (this.expDate) {
      this.expDate = new Date(this.expDate);
    }
  }
}
