// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dayGroup {
    text-align: center;
    margin-bottom: 5px;
}

.day {
    display: inline;   
    margin: 4px;
}

.day > button {
    margin-top: 10px;
}
  
.timeGroup {
    text-align: center;
}

.time {
    display: inline;
    margin: 2px;
}

.time > button {
    margin-top: 10px;
    width: 125px;
}

.btn-black {
    background-color: #333;
    color: white;
}

a {    
    color: #fff;
}

.rewindMsg {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
}

.viewContainer {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 900px;
}

.card {
    background-color: #f5f5f5;
}

 /* .timeGroupBtn {
    background-color: #fff;
    border-color: #ccc;
} */

/* .timeGroupBtn:hover {
    background-color: #f5f5f5ee;
    border: 1px solid #000;
}  */

/* .timeGroupBtn.selected,
.timeGroupBtn:active
 {
    background-color: #333;
    color: #fff;
} */ `, "",{"version":3,"sources":["webpack://./src/app/camera/rewind/rewind.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;CAEC;;;GAGE;;AAEH;;;IAGI;;AAEJ;;;;;GAKG","sourcesContent":[".dayGroup {\r\n    text-align: center;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.day {\r\n    display: inline;   \r\n    margin: 4px;\r\n}\r\n\r\n.day > button {\r\n    margin-top: 10px;\r\n}\r\n  \r\n.timeGroup {\r\n    text-align: center;\r\n}\r\n\r\n.time {\r\n    display: inline;\r\n    margin: 2px;\r\n}\r\n\r\n.time > button {\r\n    margin-top: 10px;\r\n    width: 125px;\r\n}\r\n\r\n.btn-black {\r\n    background-color: #333;\r\n    color: white;\r\n}\r\n\r\na {    \r\n    color: #fff;\r\n}\r\n\r\n.rewindMsg {\r\n    padding: 10px;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    max-width: 800px;\r\n}\r\n\r\n.viewContainer {\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    margin-top: 10px;\r\n    margin-bottom: 10px;\r\n    max-width: 900px;\r\n}\r\n\r\n.card {\r\n    background-color: #f5f5f5;\r\n}\r\n\r\n /* .timeGroupBtn {\r\n    background-color: #fff;\r\n    border-color: #ccc;\r\n} */\r\n\r\n/* .timeGroupBtn:hover {\r\n    background-color: #f5f5f5ee;\r\n    border: 1px solid #000;\r\n}  */\r\n\r\n/* .timeGroupBtn.selected,\r\n.timeGroupBtn:active\r\n {\r\n    background-color: #333;\r\n    color: #fff;\r\n} */ "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
