import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from "@angular/core";

import { Request } from "../../requests/request";
import { Camera } from "app/camera/camera";

@Component({
  selector: "express-request-form",
  templateUrl: "./express-request-form.component.html",
  styleUrls: ["./express-request-form.component.scss"],
})
export class ExpressRequestFormComponent implements OnInit {
  @Input() request: Request;
  @Input() cameraDisplay: string;
  @Input("cameras") cameras: Camera[] = []
  @Input() isAutoRequest: boolean;
  @Input() player: ElementRef;
  @Input() image: string;
  @Input() submitInProgress: boolean;
  @Input() extraInfoTitle: string;
  @Input() extraInfoBody: string;

  @Output("submit") submitEmitter = new EventEmitter<Request>();
  @Output("addCameras") addCamerasEmitter = new EventEmitter<Event>();

  @ViewChild("canvas", { static: true }) canvas: ElementRef;

  constructor() {}

  public ngOnInit() {

    if (this.player !== undefined) {
      this.loadPlayer()
    } else if (this.image) {
      this.loadImage()
    }
  }

  public submit(request: Request) {
    this.submitInProgress = true;
    try {
      let canvas = this.canvas.nativeElement;
      let imageDataURL: string = canvas.toDataURL("image/jpeg");
      request.image = imageDataURL;
    } catch (error) {
      console.error(error);
    }
    this.submitEmitter.emit(request);
  }

  public addCameras() {
    this.addCamerasEmitter.emit();
  }
  private loadPlayer() {
    let canvas = this.canvas.nativeElement;
    let context = canvas.getContext("2d");
    let player = this.player.nativeElement;

    canvas.width = player.videoWidth;
    canvas.height = player.videoHeight;

    context.drawImage(player, 0, 0, canvas.width, canvas.height);
  }
  private loadImage() {
    let canvas = this.canvas.nativeElement;
    let ctx = canvas.getContext('2d');
    let img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0, img.width, img.height)
    }
    img.src = this.image
  }
}
