// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeSelect {
  margin-top: 15px;
}

.label {
    display: block;
    font-weight: bold;
    text-indent: 8px;
    color: #656565;
    text-align: center;;
  }

  h3 {
    text-align: center;
  }

  button {
    margin-left: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/app/metrics/events/events.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".timeSelect {\r\n  margin-top: 15px;\r\n}\r\n\r\n.label {\r\n    display: block;\r\n    font-weight: bold;\r\n    text-indent: 8px;\r\n    color: #656565;\r\n    text-align: center;;\r\n  }\r\n\r\n  h3 {\r\n    text-align: center;\r\n  }\r\n\r\n  button {\r\n    margin-left: 5px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
