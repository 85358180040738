import { CameraMarker } from '../../../camera/camera-marker'

export class Cameras {
    private _cameras: CameraMarker[] = []
    get cameras() {
        return [...this._cameras];
    }
    isValid: boolean;
    errors: string[] = [];
    constructor(cameras?: CameraMarker[]) {
        if(cameras) {
            this._cameras = cameras;
        }
        this.isValid = true;
        this.errors = [];
        if(this.cameras.length < 1) {
            this.isValid = false;
            this.errors.push("You need to select at least one camera.");
        }
        if(this.cameras.length > 10) {
            this.isValid = false;
            this.errors.push("You can select at most 10 cameras.");
        }
    }
    valid() {
        let valid = true;
        let errors = {cameras: []};
        if(this.cameras.length < 1) {
            valid = false;
            errors.cameras.push("You need to select at least one camera");
        }
        if(this.cameras.length > 10) {
            valid = false;
            errors.cameras.push("You can select at most 10 cameras.")
        }
        return {valid: valid, errors: errors};
    }
    toJSON() {
        return { cameras: this.cameras }
    }
}
