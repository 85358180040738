import { Component, Input } from '@angular/core';


@Component({
    selector: 'app-event-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.css'],
})
export class StatusComponent {
    @Input('status') status: string;
    labelType(status) {
        switch(status) {
            case 'Closed':
                return 'danger';
            case 'Working':
                return 'warning';
            case 'New':
                return 'primary';
            case 'Preparing Report':
                return 'success';
            case 'Waiting on Client':
                return 'info';
            default:
                return 'secondary';
        }
    }
}