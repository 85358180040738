// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    font-size: .7em;
    height: 500px;
    margin-bottom: 0px;
}
.chart {
    height: 250px;
}
.siteList {
    height: 500px;
    overflow: auto;
    margin-bottom: 20px;
}

th {
    background-color: #333;
    color: white;
}
.label {
    display: block;
    font-weight: bold;
    text-indent: 8px;
    color: #656565;
  }
.error {
    color: red;
}
.sales-button {
    margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-dashboard/admin-dashboard.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB;AACA;IACI,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;EAChB;AACF;IACI,UAAU;AACd;AACA;IACI,gBAAgB;AACpB","sourcesContent":["table {\r\n    font-size: .7em;\r\n    height: 500px;\r\n    margin-bottom: 0px;\r\n}\r\n.chart {\r\n    height: 250px;\r\n}\r\n.siteList {\r\n    height: 500px;\r\n    overflow: auto;\r\n    margin-bottom: 20px;\r\n}\r\n\r\nth {\r\n    background-color: #333;\r\n    color: white;\r\n}\r\n.label {\r\n    display: block;\r\n    font-weight: bold;\r\n    text-indent: 8px;\r\n    color: #656565;\r\n  }\r\n.error {\r\n    color: red;\r\n}\r\n.sales-button {\r\n    margin-left: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
