import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import { UserService } from '../../../users/user.service';
import { User } from '../../../users/user';
import { Site } from '../../../site/site';
import { SiteService } from '../../../site/site.service';
import { ContactInfo } from './contact-info';
import { OtherOfficer } from './other-officer/other-officer';
import { SET_REQUEST_CONTACT_INFO, REQUEST_SET_CONTACT_INFO_USER } from '../../requests.reducer';

@Component({
    selector: 'app-contact-info',
    templateUrl: './contact-info.component.html',
    styleUrls: ['./contact-info.component.css'],
})
export class ContactInfoComponent implements OnInit {
    public user: User;
    public userSub;
    public site: Site;
    public siteSub;
    public usersList: {text: string, id: number}[];
    public usersListSub;
    public contactInfoSub;
    public users: User[];
    public currentUsers: {text: string, id: number}[];
    public currentOfficers: {text: string, id: number}[];
    public contactInfo: ContactInfo;
    public officerList: {text: string, id: number}[];
    constructor(public userService: UserService, public siteService: SiteService, public store: Store<AppState>, public router: Router, public route: ActivatedRoute) {}

    ngOnInit() {
        this.userSub = this.userService.getUser().subscribe(user => {
            this.store.dispatch({type: REQUEST_SET_CONTACT_INFO_USER, payload: user})
            this.user = user
            if(this.siteSub) {
                this.siteSub.unsubscribe();
            }
            this.siteSub = this.siteService.getSite().subscribe(site => {
                this.site = site;
                if(this.usersListSub) {
                    this.usersListSub.unsubscribe();
                }
                this.usersListSub = this.userService.getUsersBySite(this.site.siteID).subscribe(users => {
                    let contacts = [];
                    if (this.site.primaryContact) {
                    contacts = [this.site.primaryContact.id, ...this.site.additionalContacts.map(u => u.id)];
                    } else {
                    contacts = [...this.site.additionalContacts.map(u => u.id)];
                    }                        
                    this.usersList = [];
                    this.officerList = [];
                    users = users.filter(u => contacts.indexOf(u.id) === -1)
                    this.users = users;
                    users.forEach(user => {
                        if(user.id !== this.user.id && user.typeHard.id === 3 && user.active) {
                            this.usersList.push({text: (user.firstName && user.lastName) ? user.firstName + " " + user.lastName : user.userName, id: user.id})
                        } else if(user.id !== this.user.id && user.typeHard.id === 2 && user.active) {
                            this.officerList.push({text: (user.getName()), id: user.id});
                        }
                    })
                })
            })
        })
        this.contactInfoSub = this.store.select(s => s.request.contactInfo).subscribe(contactInfo => {
            this.contactInfo = contactInfo;
            if(contactInfo.otherUsers) {
                this.currentUsers = contactInfo.otherUsers.map(user => {
                    return {text: (user.firstName && user.lastName) ? user.firstName + " " + user.lastName : user.userName, id: user.id}
                });
            }
            if(contactInfo.officers) {
                this.currentOfficers = contactInfo.officers.map(officer => {
                    return {text: (officer.firstName && officer.lastName) ? officer.firstName + " " + officer.lastName : officer.userName, id: officer.id}
                });
            }
            if(!contactInfo.otherOfficers) {
                contactInfo.otherOfficers = [];
            }
        });

    }

    ngOnDestroy() {
        this.userSub.unsubscribe();
        this.siteSub.unsubscribe();
        this.usersListSub.unsubscribe();
        this.contactInfoSub.unsubscribe();
    }

    usersChanged(users) {
        let result: User[] = [];
        if(users) {
            users.forEach(user => {
                let foundUser = this.users.reduce((prev, next) => next.id === user.id ? next : prev, null);
                if(foundUser !== null) {
                    result.push(foundUser);
                }
            })            
        }
        this.contactInfo.otherUsers = result;
        this.update();
    }
    officersChanged(officers) {
        let result: User[] = [];
        if(officers) {
            officers.forEach(officer => {
                let foundUser = this.users.reduce((prev, next) => next.id === officer.id ? next : prev, null);
                if(foundUser !== null) {
                    result.push(foundUser);
                }
            })
        }
        this.contactInfo.officers = result;
        this.update();
    }
    update() {
        this.store.dispatch({type: SET_REQUEST_CONTACT_INFO, payload: this.contactInfo});
    }
    emailsChanged(emailString) {
        //TODO: parse and validate email addresses?
        this.contactInfo.otherEmails = emailString;
        this.update();
    }
    setPoliceInvolved(p) {
        this.contactInfo.policeInvolved = p;
        this.update();
    }
    addOtherOfficer() {
        this.contactInfo.otherOfficers.push(new OtherOfficer());
    }
    updateOtherOfficer(officer, event) {
        officer = event;
        this.update();
    }
    remove(index) {
        if(index > -1) {
            this.contactInfo.otherOfficers.splice(index, 1);
            this.update();
        }
    }
    back() {
        this.router.navigate(['../', 'cameras'], {relativeTo: this.route});
    }
    next() {
        this.router.navigate(['../', 'confirmation'], {relativeTo: this.route});
    }
}