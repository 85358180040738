import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import SitePackage from "../../../site-package/models/site-package";
import Feature from "../../../site-package/models/feature";

@Component({
  selector: "app-site-package-create-modal",
  templateUrl: "./site-package-create-modal.component.html",
  styleUrls: ["./site-package-create-modal.component.css"],
})
export class SitePackageCreateModalComponent {
  @Output()
  public close: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public create: EventEmitter<SitePackage> = new EventEmitter<SitePackage>();

  @Input()
  public allFeatures: Feature[];

  public newSitePackage: SitePackage;

  constructor() {}

  public onClose() {
    this.close.emit();
  }

  public onCreate() {
    this.create.emit(this.newSitePackage);
  }

  public onFormValueChange(sitePackage: SitePackage) {
    this.newSitePackage = sitePackage;
  }
}
