import { Component, OnInit } from '@angular/core';
import { State as GridState } from '@progress/kendo-data-query';
import { AppState } from 'app/app.state';
import { Store } from '@ngrx/store';
import * as actions from './store/actions';
import * as selectors from './store/selectors';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ByosBucketRequest, ByosSite, Storage, ByosBucketCreate } from './byosSite';
import { Lookup } from 'app/lookup';
import { LicenseCount } from './license-count';
import { Router } from '@angular/router';
import { User } from 'app/users/user';
import { UserService } from 'app/users/user.service';

@Component({
  selector: 'app-extended-storage',
  templateUrl: './extended-storage.component.html',
  styleUrls: ['./extended-storage.component.css']
})
export class ExtendedStorageComponent implements OnInit {
  public byosGridState$: Observable<GridState>;
  public byosGridView$: Observable<GridDataResult>;
  public byosUnlicensedGridState$: Observable<GridState>;
  public byosUnlicensedGridView$: Observable<GridDataResult>;
  public showConfigureStorage$: Observable<boolean>;
  public siteStorageBeingConfigured$: Observable<Storage>;
  public enabledOptions$: Observable<string[]>;
  public selectedEnabledOption$: Observable<string>;
  public textFilter$: Observable<string>;
  public textFilterChange$: Observable<string>;
  public showChangeStorage$: Observable<boolean>;
  public accounts$: Observable<Lookup[]>;
  public vendors$: Observable<Lookup[]>;
  public buckets$: Observable<string[]>;
  public unassignStorage$: Observable<any>;
  public showUnassignStorageDialog$: Observable<boolean>;
  public unlicensedSiteSelections$: Observable<number[]>;
  public licenseCount$: Observable<LicenseCount>;
  public showChangeStorageConfirmDialog$: Observable<boolean>;
  public showInquiryConfirmation$: Observable<boolean>;
  public inquirySelectedSites$: Observable<string[]>;

  public changeStorageData: Storage; 

  constructor(
    private store: Store<AppState>,
    private userService: UserService, 
    private router: Router
  ) { }

  ngOnInit(): void {
    this.byosGridState$ = this.store.pipe(select(selectors.byosGridState));
    this.byosGridView$ = this.store.pipe(select(selectors.byosGridView));
    this.byosUnlicensedGridState$ = this.store.pipe(select(selectors.byosUnlicensedGridState));
    this.byosUnlicensedGridView$ = this.store.pipe(select(selectors.byosUnlicensedGridView));
    this.showConfigureStorage$ = this.store.pipe(select(selectors.showConfigureStorage));
    this.siteStorageBeingConfigured$ = this.store.pipe(select(selectors.siteStorageBeingConfigured));
    this.enabledOptions$ = this.store.pipe(select(selectors.enabledOptions));
    this.selectedEnabledOption$ = this.store.pipe(select(selectors.selectedEnabledOption));
    this.textFilter$ = this.store.pipe(select(selectors.textFilter));
    this.showChangeStorage$ = this.store.pipe(select(selectors.showChangeStorage));
    this.accounts$ = this.store.pipe(select(selectors.accounts));
    this.vendors$ = this.store.pipe(select(selectors.vendors))
    this.buckets$ = this.store.pipe(select(selectors.buckets));
    this.unassignStorage$ = this.store.pipe(select(selectors.unassignStorage));
    this.unlicensedSiteSelections$ = this.store.pipe(select(selectors.unlicensedSiteSelections));
    this.licenseCount$ = this.store.pipe(select(selectors.licenseCount));
    this.showUnassignStorageDialog$ = this.store.pipe(select(selectors.showUnassignStorageDialog));
    this.showChangeStorageConfirmDialog$ = this.store.pipe(select(selectors.showChangeStorageConfirmDialog));
    this.showInquiryConfirmation$ = this.store.pipe(select(selectors.showInquiryConfirmation));
    this.inquirySelectedSites$ = this.store.pipe(select(selectors.inquirySelectedSites));

    this.store.dispatch(actions.getBYOSites());
    this.store.dispatch(actions.getVendors());
  }

  dynamicWidth: number | string = 400;

  updateWidth(newWidth: number | string) {
    this.dynamicWidth = newWidth;
  }

  onGridStateChange(grid: GridState) {
    this.store.dispatch(actions.setBYOSGridState({ gridState: grid }));
  }

  onUnlicensedGridStateChange(grid: GridState) {
    this.store.dispatch(actions.setBYOSUnlicensedGridState({ gridState: grid }));
  }

  onConfigureStorage(storage: Storage) {
    this.store.dispatch(actions.configureSiteStorage({ siteStorage: storage }));
  }

  onChangeStorage(dataItem: Storage) {
    this.changeStorageData = dataItem;
    if (dataItem.bucketID) {
    this.store.dispatch(actions.setShowChangeStorageConfirmDialog({ show: true }));
    } else {
      this.confirmChangeStorage();
    }   
  }

  closeChangeStorageConfirmDialog() {
    this.changeStorageData = null;
    this.store.dispatch(actions.setShowChangeStorageConfirmDialog({ show: false }));
  }

  confirmChangeStorage() {  
    this.store.dispatch(actions.changeStorage({siteStorage: this.changeStorageData}));    
  }

  closeStorageMgmtDialog() {
    this.store.dispatch(actions.setShowConfigureStorage({ show: false }));
  }

  closeStorageAccountsDialog() {
    this.store.dispatch(actions.showChangeStorage({ show: false }));
  }

  confirmExistingStorageAccount(account: Lookup) {
    this.store.dispatch(actions.updateBYOSSiteBucket({ account: account }));
  }

  public onUnassignStorage() {
    this.store.dispatch(actions.setShowUnassignStorageDialog({ show: true }));
  }

  public onConfirmUnassignStorage() {
    this.store.dispatch(actions.unassignStorage());
  }

  public closeUnassignStorageDialog() {
    this.store.dispatch(actions.setShowUnassignStorageDialog({ show: false }));
  }

  public onClearFilters() {
    this.store.dispatch(actions.clearFilters());
  }

  public onEnabledOptionChange(option: string) {
    this.store.dispatch(actions.setSelectedEnabledOption({ option: option }));
  }

  onTextFilterChange(filter: string) {
    this.store.dispatch(actions.setTextFilter({ textFilter: filter }));
  }
  
  public chooseStorageBucket( accountInfo: ByosBucketRequest ) {
    this.store.dispatch(actions.getBuckets({accountInfo: accountInfo}));
  }

  confirmAddStorageAccount(account: ByosBucketCreate) {
    this.store.dispatch(actions.addStorageAccount({account: account}));
  }

  onUnlicensedSiteSelectionsChange(sites) {
    this.store.dispatch(actions.setUnlicensedSiteSelections({ siteSelections: sites }));
  }

  onAdminButtonClick() {
    this.router.navigate([ 'admin', 'extended-storage']);
  }

  onShowInquiryConfirmation(show: boolean) {
    this.store.dispatch(actions.showInquiryConfirmation({ show: show }));
  }

  onInquirySubmitted() {
    this.store.dispatch(actions.submitInquiry());
  }


}
