import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SortData } from '../sort-data';
import { AlertEntry } from '../manage-alert-entries/alert-entry';
import { AlertHit } from 'app/lpr/recent-hits/alert-hit';

@Component({
  selector: 'app-alert-details',
  templateUrl: './alert-details.component.html',
  styleUrls: ['./alert-details.component.scss','../lpr-table-styles.scss']
})
export class AlertDetailsComponent implements OnInit, OnDestroy {
  @Input('activeAlerts') set setActiveAlerts(value: AlertEntry[]) {
    this.activeAlerts = value.filter(alerts => alerts.hits !== 0);
  }
  @Input("sort") sort: SortData;
  @Input("canCreateTickets") canCreateTickets: boolean;
  @Input("hits") hits: AlertHit[];
  @Input("selectedEntry") selectedEntry: AlertEntry;

  @Output("columnClick") columnClickEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output("startRequest") startRequestEmitter: EventEmitter<AlertHit> = new EventEmitter<AlertHit>();
  @Output("viewHit") viewHitEmitter: EventEmitter<AlertHit> = new EventEmitter<AlertHit>();
  @Output("selectEntry") selectEntryEmitter: EventEmitter<AlertEntry> = new EventEmitter<AlertEntry>();

  public activeAlerts: AlertEntry[];

  constructor() { }

  ngOnInit() {
    

    if (this.activeAlerts !== undefined && this.activeAlerts.length > 0) {
      if(!this.selectedEntry) {
        this.selectAlert(this.activeAlerts[0]);      
      }
    }

  }

  ngOnDestroy() {
    
  }

  onColumnClick(field: string): void {
    this.columnClickEmitter.emit(field);
  }
  onViewHit(hit: AlertHit) {
    this.viewHitEmitter.emit(hit)
  }

  startRequest(hit: AlertHit) {
    this.startRequestEmitter.emit(hit);
  }
  selectAlert(entry: AlertEntry) {
    this.selectEntryEmitter.emit(entry)
  }
}
