import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ManagementCompany } from '../management-company';
import { LeadSource } from '../lead-source';
import { InternalEmail } from '../internal-email';
import { RelatedSite } from '../related-site';
import { NewLead } from '../new-lead';
import { FileSelectComponent, FileState } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-new-lead-form',
  templateUrl: './new-lead-form.component.html',
  styleUrls: ['./new-lead-form.component.scss']
})
export class NewLeadFormComponent {

  @Input("managementCompanies") set setManagementCompanies(value: ManagementCompany[]) {
    this.managementCompanies = value;
  };

  @Input("leadSources") set setLeadSources(value: LeadSource[]) {
    this.leadSources = value;
  };

  @Input("internalEmails") set setInternalEmails(value: InternalEmail[]) {
    this.internalEmails = value;


  };

  @Input("relatedSites") set setRelatedSites(value: RelatedSite[]) {
    this.relatedSites = value;
  };

  @Input("submitInProgress") set setSubmitInProgress(value: boolean) {
    this.submitInProgress = value;
  };

  @Output("findRelatedSites") findRelatedSitesEmitter: EventEmitter<string> = new EventEmitter<string>();

  @Output("clearRelatedSites") clearRelatedSitesEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output("submit") submitEmitter: EventEmitter<{ lead: NewLead, images: File[] }> = new EventEmitter<{ lead: NewLead, images: File[] }>();

  public form: FormGroup;
  public managementCompanies: ManagementCompany[] = [];
  public leadSources: LeadSource[] = [];
  public internalEmails: InternalEmail[] = [];
  public relatedSites: RelatedSite[] = [];
  public submitInProgress: boolean = false;
  public imageFiles: File[] = [];
  public imagePreviews: { [key: string]: string } = {};
  public mask = "0000000000";

  constructor(
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      managementCompany: [null, [Validators.required, Validators.maxLength(100)]],
      propertyName: [null, Validators.maxLength(100)],
      unitCount: [0],
      leadSource: [null, [Validators.required, Validators.maxLength(100)]],
      firstName: [null, [Validators.required, Validators.maxLength(45)]],
      lastName: [null, [Validators.required, Validators.maxLength(45)]],
      phoneNumber: [""],
      email: [null, Validators.maxLength(45)],
      internalEmails: [[]],
      notes: [null, Validators.maxLength(255)]
    });
  }

  onSubmit(): void {
    const newLead: NewLead = this.form.value;
    this.submitEmitter.emit({ lead: newLead, images: this.imageFiles });
  }

  onImagesChange(imageFiles: File[]): void {
    this.imageFiles = imageFiles;
    this.imagePreviews = {};

    for (let file of imageFiles) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreviews[file.name] = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  getPreviewUrl(file: File): string {
    return this.imagePreviews[file.name];
  }

  showImageRemoveButton(state: FileState): boolean {
    return state === FileState.Selected ? true : false;
  }

  removeImage(fileSelect: FileSelectComponent, uid: string): void {
    fileSelect.removeFileByUid(uid);
  }

  managementCompanyBlur(): void {
    this.findRelatedSitesEmitter.emit(this.form.get('managementCompany').value);
  }

  managementCompanyValueChange(value: any): void {
    this.clearRelatedSitesEmitter.emit();
  }
}
