
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';

import { UserService } from '../../users/user.service';
import { User } from '../../users/user';
import { UserType } from '../../users/user-type';
import { SiteListService } from '../../sites/site-list.service';
import { SiteListItem } from '../../sites/site-list-item';
import { PermissionsTemplate } from '../../permissions-template';
import { SiteGroupsService } from "../admin-site-groups/admin-site-groups.service";
import { SiteGroup, SiteGroupFull } from "../admin-site-groups/site-group";
import { AnalyticsService } from 'app/analytics.service';
import { NotificationsService } from 'app/notifications/notifications.service';

@Component({
    selector: 'app-account-creation',
    templateUrl: './account-creation.component.html',
    styleUrls: ['./account-creation.component.css'],
})
export class AccountCreationComponent implements OnInit, OnDestroy {
    templates: Observable<PermissionsTemplate[]>;
    currentUser: Observable<User>;
    typeList: Observable<UserType[]>;
    hardTypeList: Observable<UserType[]>;
    siteList: Observable<SiteListItem[]>;
    siteGroups: Observable<SiteGroup[]>;
    siteGroupFull: Observable<SiteGroupFull>;

    private destroy$ = new Subject<void>();

    constructor(
        public userService: UserService,
        public siteService: SiteListService,
        public router: Router,
        public siteGroupsService: SiteGroupsService,
        private notificationsService: NotificationsService,
        private analyticsService: AnalyticsService,
    ){}

    ngOnInit() {
        this.templates = this.userService.getPermissionsTemplates();
        this.currentUser = this.userService.getUser();
        this.typeList = this.userService.getTypes();
        this.hardTypeList = this.userService.getHardTypes();
        this.siteList = this.siteService.getSiteList();
        this.siteGroups = this.siteGroupsService.getSiteGroups();
    }

    public ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
      }

    submit(user: User) {
        let currentUserValue: User;

        this.currentUser.pipe(
            tap(currentUser => currentUserValue = currentUser),
            switchMap(() => 
              this.userService
                .createUser(user)
                .pipe(
                  takeUntil(this.destroy$),
                  catchError((err) => {
                      this.notificationsService.error("Failed to create user", err);
                      return throwError(err);
                  }),
                )
            ),
            takeUntil(this.destroy$),
          ).subscribe((updatedUser) => {
            this.analyticsService.track("User Created Admin", {
              userID: updatedUser.id,
              createdBy: currentUserValue.id,
            });
        
            this.router.navigate(['admin', 'temp']);
          });
    }

    getFullSiteGroup(id) {
        this.siteGroupFull = this.siteGroupsService.getSiteGroup(id);
      }
}
