import { Component, OnInit, OnDestroy } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/app.state';
import * as adminLPRActions from '../store/actions';
import {
  selectSelectedAlertEntry,
  selectAlertEntriesGridState,
  selectAlertEntriesLoading,
  selectAlertEntriesGridView,
  selectAlertEntryHitsGridState,
  selectAlertEntryHitsLoading,
  selectAlertEntryHitsGridView,
  selectShowLprImageModal,
} from '../store/selectors';
import { Observable, Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { AlertHit } from '../../../lpr/recent-hits/alert-hit';

@Component({
  selector: 'app-alert-entries',
  templateUrl: './alert-entries.component.html',
  styleUrls: ['./alert-entries.component.css']
})

export class AlertEntriesComponent implements OnInit, OnDestroy {  
  public alertEntriesGridState$: Observable<State>;
  public alertEntriesLoading$: Observable<boolean>;
  public alertEntriesView$: Observable<GridDataResult>;
  public selectedAlertEntry$: Observable<number[]>;
  public selectedAlertEntry: number[];

  public alertEntryHitsGridState$: Observable<State>;
  public alertEntryHitsLoading$: Observable<boolean>;
  public alertEntryHitsView$: Observable<GridDataResult>;

  public showLprImageModal$: Observable<boolean>;
 
  public isAdmin: boolean = true;
  public alertHit: AlertHit; 

  public alive : boolean = true;
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.store.dispatch(adminLPRActions.loadAlertEntries());

    this.alertEntriesGridState$ = this.store.pipe(select(selectAlertEntriesGridState));
    this.alertEntriesLoading$ = this.store.pipe(select(selectAlertEntriesLoading));
    this.alertEntriesView$ = this.store.pipe(select(selectAlertEntriesGridView));
    this.selectedAlertEntry$ = this.store.pipe(select(selectSelectedAlertEntry));

    this.alertEntryHitsGridState$ = this.store.pipe(select(selectAlertEntryHitsGridState));
    this.alertEntryHitsLoading$ = this.store.pipe(select(selectAlertEntryHitsLoading));
    this.alertEntryHitsView$ = this.store.pipe(select(selectAlertEntryHitsGridView));

    this.showLprImageModal$ = this.store.pipe(select(selectShowLprImageModal));

    this.selectedAlertEntry$.pipe(takeWhile(() => this.alive)).subscribe(entry => {
      this.selectedAlertEntry = [...entry];
    });
  }

  ngOnDestroy() {
    this.alive = false;
    this.destroy$.next();
    this.destroy$.complete();
    this.store.dispatch(adminLPRActions.setShowLprImageModal({show: false}));
  }


  onAlertEntriesStateChange(state: State) {
    this.store.dispatch(adminLPRActions.setAlertEntriesGridState({ gridState: state }));
  }

  onAlertEntriesKeyChange(key: number[]) {
    console.log("alertSelect: ", key);
    this.store.dispatch(adminLPRActions.setSelectedAlertEntry({entry: [...key]}));
    this.store.dispatch(adminLPRActions.loadAlertEntryHits());
  }

  onAlertEntryHitsStateChange(state: State) {
    this.store.dispatch(adminLPRActions.setAlertEntryHitsGridState({ gridState: state }));
  }

  showLprImageModal(entry) {
    this.store.dispatch(adminLPRActions.setShowLprImageModal({show: true}));
    this.alertHit = entry;
  }

  hideLprImageModal() {
    this.store.dispatch(adminLPRActions.setShowLprImageModal({show: false}));
  }
}
