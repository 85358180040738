
import {map} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { User } from '../../../users/user';
import { Site } from '../../../site/site';
import { SiteService} from '../../../site/site.service'; 
import { NotificationsService } from '../../../notifications/notifications.service'
import { UserService } from '../../../users/user.service';
import { SiteListService } from '../../../sites/site-list.service';
import { Lookup } from '../../../lookup';
import { AdminService } from '../../admin.service';


@Component({
    selector: 'app-new-site-setup',
    templateUrl: './new-site-setup.component.html',
    styleUrls: ['./new-site-setup.component.css'],
})
export class NewSiteSetupComponent implements OnInit, OnDestroy {
    public users: User[] = [];
    public site: Site = new Site();
    public paramsSub;
    public sales: User[] = []
    public hqAdminList: {text: string, id: number}[];
    public managementCompanies: Lookup[] = [];
    constructor(public siteService: SiteService, public notificationService: NotificationsService, public route: ActivatedRoute, public router: Router, public userService: UserService, public siteListService: SiteListService, public adminService: AdminService) {}
    ngOnInit() {
        this.paramsSub = this.route.params.subscribe( (params: Params) => {
            console.log(params);
            this.site.siteID = +params['id'];
            this.site.siteName = params['name'];
            this.site.managementCompanyName = params['managementCompanyName'];
        })
        this.userService.getWtsEmployees().subscribe(users => {
            this.sales = users;
        })
        this.siteListService.getHqAdminList().pipe(map(list => list.map(item => {
            return { text: item.name, id: item.id};
        }))).subscribe(list => this.hqAdminList = list);
        this.adminService.getManagementCompanies().subscribe(mgmt => {
            this.managementCompanies = mgmt;
        })
    }
    ngOnDestroy() {
        this.paramsSub.unsubscribe();
    }
    updateSite(site) {
        this.site = site;
    }
    save() {
        this.siteService.newSite(this.site).subscribe(result => {
            this.notificationService.success("", "Site Created")
            this.router.navigate(['admin', 'sites']);
        }, result => {
            this.notificationService.error("", "Failed to create site");
        });

    }
}