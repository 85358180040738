import { createAction, props } from "@ngrx/store";
import { State } from '@progress/kendo-data-query';
import { ManageSiteItem } from '../../../sites/manage-site-item';

export const noAction = createAction("[AdminManageSites] No Action");

export const loadSiteList = createAction("[AdminManageSites] Load Site List");

export const setSiteList = createAction("[AdminManageSites] Set Site List", props<{sites: ManageSiteItem[]}>());

export const setSiteListGridState = createAction("[AdminManageSites] Set Site List Grid State", props<{gridState: State}>());