import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import * as fromIotLprState from "./store/state";
import { iotLprReducer } from "./store/reducers";
import { IotLprEffects } from "./store/effects";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromIotLprState.iotLprKey, iotLprReducer),
        EffectsModule.forFeature([IotLprEffects]),
    ]
})
export class IotLprModule { }
