import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map, takeUntil, withLatestFrom } from "rxjs/operators";
import { GridComponent, GridDataResult, PageChangeEvent, SortSettings } from "@progress/kendo-angular-grid";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import * as IotLprActions from "./store/actions";
import * as fromIotLpr from "./store/selectors";
import { AppState } from "../../app.state";
import { IotLprSearchParams } from "./iot-lpr";
import { SiteTimezone } from "../../site/site-timezone";
import { SafeUrl } from "@angular/platform-browser";
import { AdminSiteListItem } from '../adminSiteListItem';
import { minWidthIcon, SVGIcon } from "@progress/kendo-svg-icons";
import { IconSize } from "@progress/kendo-angular-icons";


@Component({
    selector: "app-iot-lpr",
    templateUrl: "./iot-lpr.component.html",
    styleUrls: ["./iot-lpr.component.css"]
})
export class IotLprComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(GridComponent) grid: GridComponent;

    siteTimezones$: Observable<{ [siteID: number]: SiteTimezone }>;

    searchParams: IotLprSearchParams = {
        query: "",
        page: 1,
        size: 25,
        sortBy: "time",
        sortOrder: "desc",
        siteIDs: [],
    };

    gridData: GridDataResult = {
        data: [],
        total: 0,
    };

    gridWidth: number = 0;
    sort: SortSettings = {
        allowUnsort: true,
        mode: "single",
    };

    currentSort: { field: string, dir: "asc" | "desc" }[] = [];

    pageSizes: number[] = [10, 25, 50, 100];

    selectedImageUrl: SafeUrl | null = null;
    showImageDialog: boolean = false;

    loading$: Observable<boolean>;
    error$: Observable<string | null>;

    sites$: Observable<AdminSiteListItem[]>;
    selectedSites$: Observable<AdminSiteListItem[]>;

    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: "contains",
      };

    private destroy$ = new Subject<void>();

    private resizeObserver: ResizeObserver;

    public hiddenColumns: string[] = [];

    public minWidthIcon: SVGIcon = minWidthIcon;    

    constructor(private store: Store<AppState>) { }

    ngOnInit(): void {

        this.store.dispatch(IotLprActions.fetchSites());

        this.siteTimezones$ = this.store.pipe(select(fromIotLpr.selectSiteTimezones));
        this.sites$ = this.store.pipe(select(fromIotLpr.selectSites));
        this.selectedSites$ = this.store.pipe(select(fromIotLpr.selectSelectedSites));

        this.currentSort = [{ field: this.searchParams.sortBy, dir: this.searchParams.sortOrder }];

        this.loading$ = this.store.pipe(select(fromIotLpr.selectLoading));
        this.error$ = this.store.pipe(select(fromIotLpr.selectError));

        this.store.pipe(
            select(fromIotLpr.selectIotLprState),
            withLatestFrom(this.siteTimezones$),
            map(([state, timezones]) => ({
                data: state.results.map(result => ({
                    ...result,
                    timezoneName: timezones[result.siteID]?.timezoneName || "",
                    timezoneOffset: timezones[result.siteID]?.timezone || 0
                })),
                total: state.totalCount,
            })),
            takeUntil(this.destroy$)
        ).subscribe(gridData => {
            this.gridData = gridData;
        });
    }

    ngAfterViewInit(): void {
        this.resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (entry.contentRect) {
                    this.gridWidth = Math.round(entry.contentRect.width);
                }
            }
        });

        if (this.grid && this.grid.wrapper && this.grid.wrapper.nativeElement) {
            this.resizeObserver.observe(this.grid.wrapper.nativeElement);
        }
    }

    ngOnDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }

        this.destroy$.next();
        this.destroy$.complete();
    }

    onSubmit(): void {
        this.store.dispatch(IotLprActions.searchIotLpr({ searchParams: this.searchParams }));
    }

    onPageChange(event: PageChangeEvent): void {
        this.searchParams.page = event.skip / event.take + 1;
        this.searchParams.size = event.take;
        this.onSubmit();
    }

    onSortChange(sort: Array<{ field: string, dir: "asc" | "desc" }>): void {
        this.currentSort = sort;
        if (sort.length > 0) {
            this.searchParams.sortBy = sort[0].field;
            this.searchParams.sortOrder = sort[0].dir;
        } else {
            this.searchParams.sortBy = "";
            this.searchParams.sortOrder = "asc";
        }

        this.searchParams.page = 1;
        this.onSubmit();
    }

    openImageDialog(dataItem: any): void {
        this.selectedImageUrl = dataItem.imageUrl;
        this.showImageDialog = true;
    }

    closeImageDialog(): void {
        this.showImageDialog = false;
        this.selectedImageUrl = null;
    }
    onSiteFilterChange(sites: AdminSiteListItem[]): void {
        this.store.dispatch(IotLprActions.setSelectedSites({ sites }));
    }
    public restoreColumns(): void {
        console.log("Restoring columns", this.hiddenColumns);
        this.hiddenColumns = [];
    }
    
      public hideColumn(field: string): void {
        console.log("Hiding column: ", field);
        if (!this.hiddenColumns.includes(field)) {
            this.hiddenColumns.push(field);
        }
        
    }

}