import { Component, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements AfterViewInit {
    @Input('show') set setShow(show) {
        this._show = show;
        if(this.modal) {
            if(show) {
                this.modal.show();
            } else {
                this.modal.hide();
            }
        }
    }
    @Input('ignoreBackdrop') set setIgnoreBackdrop(ig) {
        this.ignoreBackdrop = ig;
    }
    public _show
    public ignoreBackdrop: boolean = true;
    @Output('hide') hideEmitter = new EventEmitter();
    @ViewChild('modal', {static: true}) modal;
    ngAfterViewInit() {
        if(this._show) {
            this.modal.show();
        }
    }
    onHidden() {
        this.hideEmitter.emit();
    }
    showModal() {
        this.modal.show();
    }
    hideModal() {
        this.modal.hide();
    }
}