// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center {
  text-align: center;
  min-height: 19%;
  max-height: 19%;
  border-bottom: 1px solid black;
  overflow-y: auto;
  box-sizing: border-box;
}

.lower {
  min-height: 79%;
  max-height: 79%;
  overflow-y: scroll;
}

.btn {
  background-color: #fff;
  border-color: #ccc;
}

.btn:hover {
  background-color: rgba(245, 245, 245, 0.9333333333);
  border: 1px solid #000;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/site-management/site-setup/site-setup.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;EACA,eAAA;EACA,8BAAA;EACA,gBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,eAAA;EACA,eAAA;EACA,kBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,mDAAA;EACA,sBAAA;AACJ","sourcesContent":[".center {\r\n    text-align: center;\r\n    min-height: 19%;\r\n    max-height: 19%;\r\n    border-bottom: 1px solid black;\r\n    overflow-y: auto;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.lower {\r\n    min-height: 79%;\r\n    max-height: 79%;\r\n    overflow-y: scroll;\r\n}\r\n\r\n.btn {\r\n    background-color: #fff;\r\n    border-color: #ccc;\r\n}\r\n\r\n.btn:hover {\r\n    background-color: #f5f5f5ee;\r\n    border: 1px solid #000;\r\n} \r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
