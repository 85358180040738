
import {map} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '../../../app.state';
import { Request } from '../../request';
import { RequestStages } from '../request-stages';

@Component({
    selector: 'app-request-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.css'],
})
export class ProgressBarComponent {
    public statuses = [
        {displayName: "Time Frame", name: "when", route: "when"}, 
        {displayName: "Event Description", name: "where", route: "where"}, 
        {displayName:"Cameras", name: "cameras", route: "cameras"}, 
        {displayName: "Contact Info", name: "contactInfo", route: "contact-info"}, 
        {displayName: "Confirmation", name: "confirmation", route: "confirmation"}
    ];
    public request: Observable<Request>;
    public stages: Observable<RequestStages>;
    public url: Observable<UrlSegment[]>;
    constructor(public store: Store<AppState>, public router: Router, public route: ActivatedRoute) {}

    ngOnInit() {
        this.request = this.store.select(s => s.request);
        this.stages = this.store.select(s => s.requestStages);
        this.url = this.route.firstChild.url.pipe(map(url => {
            console.log(url);
            return url;
        }));
    }
    navigate(status) {
        console.log(this.route.snapshot)
        this.router.navigate([status.route], { relativeTo: this.route });
    }
}