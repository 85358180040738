import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { CommonModule } from '@angular/common';


import { SharedModule } from "../shared/shared.module";



import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GridModule, ExcelModule } from "@progress/kendo-angular-grid";
import { DialogModule } from "@progress/kendo-angular-dialog";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { ChartsModule } from "@progress/kendo-angular-charts";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { MenuModule } from "@progress/kendo-angular-menu";
import { TooltipModule } from '@progress/kendo-angular-tooltip';

import { adminRouting } from "./admin.routing";
import { AdminComponent } from "./admin.component";
import { TempUserListComponent } from "./temp-users/temp-user-list.component";
import { AccountCreationComponent } from "./account-creation/account-creation.component";
import { AccountFormComponent } from "./account-form/account-form.component";
import { AccountManagementComponent } from "./account-management/account-management.component";
import { AccountEditComponent } from "./account-edit/account-edit.component";
import { AccountCreationGuard } from "./account-creation/account-creation-guard.service";
import { TempUserGuard } from "./temp-users/temp-user-guard.service";
import { AdminGuard } from "./admin-guard.service";
import { PermissionsSetterComponent } from "./permissions-setter/permissions-setter.component";
import { SiteSetupFormComponent } from "./site-management/site-setup-form/site-setup-form.component";
import { SiteManagementGuard } from "./site-management/site-management-guard.service";
import { SiteManagementComponent } from "./site-management/site-management.component";
import { SiteSetupComponent } from "./site-management/site-setup/site-setup.component";
import { NewSiteSetupComponent } from "./site-management/new-site-setup/new-site-setup.component";
import { SiteTableComponent } from "./admin-dashboard/site-table/site-table.component";
import { AdminDashboardComponent } from "./admin-dashboard/admin-dashboard.component";
import { AdminService } from "./admin.service";
import { SiteMetricsComponent } from "./site-metrics/site-metrics.component";
import { AdminMessagesComponent } from "./admin-messages/admin-messages.component";
import { AdminMessagesGuard } from "./admin-messages/admin-messages-guard.service";
import { MessageFormComponent } from "./admin-messages/message-form/message-form.component";
import { AdminAllSitesComponent } from "./admin-all-sites/admin-all-sites.component";
import { ContainsFilterComponent } from "../contains-filter/contains-filter.component";
import { AdminEventsComponent } from "./admin-events/admin-events.component";
import { SummaryModalComponent } from "./admin-events/summary-modal/summary-modal.component";
import { AdminDocumentsComponent } from "./admin-documents/admin-documents.component";
import { UploadModalComponent } from "./admin-documents/upload-modal/upload-modal.component";
import { DocTypeComponent } from "./admin-documents/doc-type/doc-type.component";
import { DocEditModalComponent } from "./admin-documents/doc-edit-modal/doc-edit-modal.component";
import { AdminSitePackageGuard } from "./admin-site-package/admin-site-package.guard";
import { AdminSitePackageComponent } from "./admin-site-package/admin-site-package.component";
import { SitePackageEditListComponent } from "./admin-site-package/site-package-edit-list/site-package-edit-list.component";
import { SitePackagesBindingDirective } from "./admin-site-package/site-package-edit-list/site-package-edit-list.directive";
import { SitePackageEditModalComponent } from "./admin-site-package/site-package-edit-modal/site-package-edit-modal.component";
import { SitePackageEditFormComponent } from "./admin-site-package/site-package-edit-form/site-package-edit-form.component";
import { SitePackageCreateFormComponent } from "./admin-site-package/site-package-create-form/site-package-create-form.component";
import { SitePackageCreateModalComponent } from "./admin-site-package/site-package-create-modal/site-package-create-modal.component";
import { TempUserEmailService } from "./temp-users/temp-user-email.service";
import { DevelopmentSandboxComponent } from "./development-sandbox/development-sandbox.component";
import { DevelopmentSandboxGuard } from "./development-sandbox/development-sandbox.guard";
import { AdminLprComponent } from './admin-lpr/admin-lpr.component';
import { AlertEntriesComponent } from './admin-lpr/alert-entries/alert-entries.component';
//import { ExpressRequestModule } from "app/express-request/express-request.module";
import { ExpressRequestModule } from "../express-request/express-request.module";

import { AlertHitsComponent } from './admin-lpr/alert-hits/alert-hits.component';
import { AdminSiteGroupsComponent } from './admin-site-groups/admin-site-groups.component';
import { AdminSiteGroupsGuard } from './admin-site-groups/admin-site-groups-guard.service';
import { SiteGroupEditFormComponent } from './admin-site-groups/site-group-edit-form/site-group-edit-form.component'
import { SiteGroupCreateComponent } from './admin-site-groups/site-group-create/site-group-create.component';
import { SiteGroupDeleteComponent } from './admin-site-groups/site-group-delete/site-group-delete.component';
import { AdminUserSelectorComponent } from './admin-user-selector/admin-user-selector.component';
import { AdminPermissionsComponent } from './admin-permissions/admin-permissions.component';
import { AdminPermissionsGuard } from "./admin-permissions/admin-permissions-guard.service";
import { AdminPermModule } from "./admin-permissions/admin-permissions-module";
import { AdminUsersSelectedGridComponent } from './admin-users-selected-grid/admin-users-selected-grid.component';
import { AdminBulkPermFormComponent } from './admin-permissions/admin-bulk-perm-form/admin-bulk-perm-form.component';
import { AdminPermStatusModalComponent } from './admin-permissions/admin-perm-status-modal/admin-perm-status-modal.component';
import { StatusModalService } from "./admin-permissions/admin-perm-status-modal/admin-perm-status-modal.service";
import { AdminPermissionTemplatesComponent } from './admin-permission-templates/admin-permission-templates.component';
import { PermissionTemplateDeleteComponent } from './admin-permission-templates/permission-template-delete/permission-template-delete.component';
import { PermissionTemplateFormComponent } from './admin-permission-templates/permission-template-form/permission-template-form.component';
import { AccountUndeleteComponent } from './account-undelete/account-undelete.component';
import { IotLprComponent } from './iot-lpr/iot-lpr.component';
import { NavigationModule } from "@progress/kendo-angular-navigation";
import { StoreModule } from "@ngrx/store";
import * as fromAdminState from "./store/state";
import { adminReducer } from "./store/reducers";
import { EffectsModule } from "@ngrx/effects";
import { AdminEffects } from "./store/effects";
import { AdminExtendedStorageComponent } from './admin-extended-storage/admin-extended-storage.component';
import { AdminTableComponent } from './admin-extended-storage/admin-table/admin-table.component';
import { AdminFilterComponent } from './admin-extended-storage/admin-filter/admin-filter.component';
import { BackfillFormComponent } from './admin-extended-storage/backfill-form/backfill-form.component';
import { adminExtendedStorageReducer } from './admin-extended-storage/store/reducers';
import * as fromAdminExtendedStorageState from './admin-extended-storage/store/state';
import { AdminExtendedStorageModule } from "./admin-extended-storage/admin-extended-storage.module";
import { IconsModule } from "@progress/kendo-angular-icons";
import { ResizedDirective } from '../angular-resize.directive';


@NgModule({
  imports: [
    adminRouting,
    SharedModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule,
    BrowserAnimationsModule,
    GridModule,
    DialogModule,
    CommonModule,
    ExcelModule,
    DropDownsModule,
    DateInputsModule,
    ChartsModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ExpressRequestModule,
    AdminPermModule,
    NavigationModule,
    IndicatorsModule,
    AdminExtendedStorageModule,
    StoreModule.forFeature(fromAdminState.adminKey, adminReducer),
    EffectsModule.forFeature([AdminEffects]),
    StoreModule.forFeature(fromAdminExtendedStorageState.backFillKey, adminExtendedStorageReducer),
    MenuModule,
    TooltipModule,
    IconsModule,
    ResizedDirective,
  ],
  declarations: [
    AdminComponent,
    TempUserListComponent,
    AccountCreationComponent,
    AccountFormComponent,
    AccountManagementComponent,
    PermissionsSetterComponent,
    AccountEditComponent,
    SiteSetupFormComponent,
    SiteManagementComponent,
    SiteSetupComponent,
    NewSiteSetupComponent,
    SiteTableComponent,
    AdminDashboardComponent,
    SiteMetricsComponent,
    AdminMessagesComponent,
    MessageFormComponent,
    AdminAllSitesComponent,
    ContainsFilterComponent,
    AdminEventsComponent,
    SummaryModalComponent,
    AdminDocumentsComponent,
    UploadModalComponent,
    DocTypeComponent,
    DocEditModalComponent,
    AdminSitePackageComponent,
    SitePackageEditListComponent,
    SitePackagesBindingDirective,
    SitePackageEditModalComponent,
    SitePackageEditFormComponent,
    SitePackageCreateFormComponent,
    SitePackageCreateModalComponent,
    DevelopmentSandboxComponent,
    AdminLprComponent,
    AlertEntriesComponent,
    AlertHitsComponent,
    AdminSiteGroupsComponent,
    SiteGroupEditFormComponent,
    SiteGroupCreateComponent,
    SiteGroupDeleteComponent,
    AdminUserSelectorComponent,
    AdminPermissionsComponent,
    AdminUsersSelectedGridComponent,
    AdminBulkPermFormComponent,
    AdminPermStatusModalComponent,
    AdminPermissionTemplatesComponent,
    PermissionTemplateDeleteComponent,
    PermissionTemplateFormComponent,
    AccountUndeleteComponent,
    IotLprComponent,
    AdminExtendedStorageComponent,
    AdminTableComponent,
    AdminFilterComponent,
    BackfillFormComponent,
  ],
  providers: [
    AccountCreationGuard,
    TempUserGuard,
    TempUserEmailService,
    AdminGuard,
    SiteManagementGuard,
    AdminService,
    AdminMessagesGuard,
    AdminSitePackageGuard,
    DevelopmentSandboxGuard,
    AdminSiteGroupsGuard,
    AdminPermissionsGuard,
    StatusModalService,
  ],
})
export class AdminModule {}
