import { initialState, State } from "./state";
import { createReducer, Action, on } from "@ngrx/store";
import * as AdminPermActions from "./actions"
import { state } from "@angular/animations";
//import { SelectAllCheckboxState } from "./state";
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';



const reducer = createReducer(
    initialState,
    on(AdminPermActions.manCompsLoaded, (state: State, {manComps}) => {
        return {
            ...state,
            manComps: manComps,
            manCompsLoading: false,
        }
    }),
    on(AdminPermActions.loadManCompsFailed, (state: State) => {
        return {
            ...state,
            manCompsLoading: false,
        }
    }),
    on(AdminPermActions.loadManComps, (state: State) => {
        return {
            ...state,
            manCompsLoading: true,
        }
    }),
    on(AdminPermActions.userSearch, (state: State, {search}) => {
        return {
            ...state,
            searching: true,
        }
    }),
    on(AdminPermActions.userSearchSuccess, (state: State, {users}) => {
        let checkbox : SelectAllCheckboxState = "checked";
        return {
            ...state,
            users: users,
            searching: false,
            selectedUsersKeys: users.map(u => u.id),
            selectAllCheckboxState: checkbox,
        }
    }),
    on(AdminPermActions.userSearchFailed, (state: State) => {
        return {
            ...state,
            searching: false,
        }
    }),
    on(AdminPermActions.setUsersGridState, (state: State, {gridState}) => {
        return {
            ...state,
            usersGridState: gridState,
        }
    }),
    on(AdminPermActions.setSelectedUsersKeys, (state: State, {keys}) => {
        return {
            ...state,
            selectedUsersKeys: [...keys],
        }
    }),
    on(AdminPermActions.setSelectAllCheckboxState, (state: State, {checkbox}) => {
        return {
            ...state,
            selectAllCheckboxState: checkbox,
        }
    }),
    on(AdminPermActions.setAction, (state: State, {action}) => {
        return {
            ...state,
            action: action,
        }
    }),
    on(AdminPermActions.setNewPermissions, (state: State, {perms}) => {
        return {
            ...state,
            newPermissions: {...perms},
        }
    }),
    on(AdminPermActions.setSiteClude, (state: State, {siteClude}) => {
        return {
            ...state,
            siteClude: siteClude,
        }
    }),
    on(AdminPermActions.setSites, (state: State, {sites}) => {
        return {
            ...state,
            selectedSites: sites,
        }
    }),
    on(AdminPermActions.submit, (state: State) => {
        return {
            ...state,
            submitting: true,
        }
    }),
    on(AdminPermActions.submitSuccess, (state: State, {transactionID}) => {
        return {
            ...state,
            submitting: false,
            transactionID: transactionID,
            showStatusModal: true,
        }
    }),
    on(AdminPermActions.submitFail, (state: State) => {
        return {
            ...state,
            submitting: false,
        }
    }),
    on(AdminPermActions.setShowStatusModal, (state: State, {value}) => {
        return {
            ...state,
            showStatusModal: value,
        }
    }),
    on(AdminPermActions.reset, (state: State) => {
        return {
            ...initialState,
            newPermissions: {site: {}, document: {}},
        }
    }),
    on(AdminPermActions.setSearchForm, (state: State, {searchForm}) => {
        console.log(searchForm)
        return {
            ...state,
            searchForm: {...searchForm},
        }
    }),

    // Admin Permission Templates

    on(AdminPermActions.permissionTemplatesLoaded, (state: State, {templates}) => {
        return {
            ...state,
            permissionTemplates: templates,
            permissionTemplatesLoading: false,
        }
    }),
    on(AdminPermActions.loadPermissionTemplatesFailed, (state: State) => {
        return {
            ...state,
            permissionTemplatesLoading: false,
        }
    }),
    on(AdminPermActions.loadPermissionTemplates, (state: State) => {
        return {
            ...state,
            permissionTemplatesLoading: true,
        }
    }),
    on(AdminPermActions.setPermissionTemplatesGridState, (state: State, {gridState}) => {
        return {
            ...state,
            permissionTemplatesGridState: gridState,
        }
    }),
    on(AdminPermActions.setTemplateHasUsers, (state: State, {hasUsers}) => {
        return {
            ...state,
            templateHasUsers: hasUsers,
            showDeletePermissionTemplateModal: true,
        }
    }),
    on(AdminPermActions.setShowDeletePermissionTemplateModal, (state: State, {show}) => {
        return {
            ...state,
            showDeletePermissionTemplateModal: show,
            templateHasUsers: false,
        }
    }),
    on(AdminPermActions.setPermissionTemplateSelectedForDelete, (state: State, {template}) => {
        return {
            ...state,
            permissionTemplateSelectedForDelete: template,
        }
    }),
    on(AdminPermActions.cancelDeletePermissionTemplate, (state: State) => {
        return {
            ...state,
            permissionTemplateSelectedForDelete: null,
            showDeletePermissionTemplateModal: false,
            templateHasUsers: false,
        }
    }),
    on(AdminPermActions.removeDeletedPermissionTemplate, (state: State, {id}) => {
        return {
            ...state,
            permissionTemplates: state.permissionTemplates.filter(template => template.id !== id),
        }
    }),
    on(AdminPermActions.setShowPermissionTemplateForm, (state: State, {show}) => {
        return {
            ...state,
            showPermissionTemplateForm: show,
        }
    }),
    on(AdminPermActions.setPermissionTemplateFormMode, (state: State, {mode}) => {
        return {
            ...state,
            permissionTemplateFormMode: mode,
        }
    }),
    on(AdminPermActions.setPermissionTemplateSelectedForEdit, (state: State, {template}) => {
        return {
            ...state,
            permissionTemplateSelectedForEdit: template,
            showPermissionTemplateForm: true,
            permissionTemplateFormMode: 'edit',
        }
    }),
    on(AdminPermActions.setTemplateName, (state: State, {name}) => {
        return {
            ...state,
            templateName: name,
        }
    }),
    on(AdminPermActions.setTemplateDescription, (state: State, {desc}) => {
        return {
            ...state,
            templateDescription: desc,
        }
    }),
    on(AdminPermActions.setTemplatePermissions, (state: State, {permissions}) => {
        return {
            ...state,
            templatePermissions: permissions,
        }
    }),
    on(AdminPermActions.addCreatedPermissionTemplate, (state: State, {template}) => {
        return {
            ...state,
            permissionTemplates: [ ...state.permissionTemplates, template],
            showPermissionTemplateForm: false,
            permissionTemplateFormMode: '',
            permissionTemplateSelectedForEdit: {id: 0, name: "", description: "", internal: false, admin: false, permissions: {}},
            templateName: '',
            templateDescription: '',
            templatePermissions: {},
        }
    }),
    on(AdminPermActions.replaceUpdatedPermissionTemplate, (state: State, {template}) => {
        return {
            ...state,
            permissionTemplates: state.permissionTemplates.map(t => t.id === template.id ? template : t),
            showPermissionTemplateForm: false,
            permissionTemplateFormMode: '',
            permissionTemplateSelectedForEdit: {id: 0, name: "", description: "", internal: false, admin: false, permissions: {}},
            templateName: '',
            templateDescription: '',
            templatePermissions: {},
        }
    }),
    on(AdminPermActions.setTemplateSaving, (state: State, {saving}) => {
        return {
            ...state,
            templateSaving: saving,
        }
    }),
)


export function adminPermReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}