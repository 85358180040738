import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>(
  fromAuth.authFeatureKey
);

export const isAuthenticated = createSelector(
  selectAuthState,
  (state: fromAuth.State) => state.isAuthenticated
);

export const authError = createSelector(
  selectAuthState,
  (state: fromAuth.State) => state.error
);

export const isLoading = createSelector(
  selectAuthState,
  (state: fromAuth.State) => state.isLoading
);

export const sessionCreated = createSelector(
  selectAuthState,
  (state: fromAuth.State) => state.sessionCreated
);