import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { User } from '../users/user';
import { UserService } from '../users/user.service';
import { SiteService } from '../site/site.service';
import { Site } from '../site/site';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeatureFlagService } from 'app/feature-flag/feature-flag.service';
import { PermissionsService } from 'app/permissions.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public user: User

  //limited sites includes siteName, displayName, maps, requestedHours
  public sites: Observable<Site[]>;
  userSub;
  siteData = {}; //stores the detailed site information at [:siteID]
  metricsData = {}; //stores the metrics data for each site at [:siteID]
  showByos$: Observable<boolean> = new BehaviorSubject<boolean>(false);

  private destroy$ = new Subject<void>();

  constructor(
    public userService: UserService,
    public router: Router,
    public siteService: SiteService,
    private featureFlagService: FeatureFlagService,
    private permissions: PermissionsService
  ) {   }

  ngOnInit() {
    this.userService.getUser().pipe(takeUntil(this.destroy$)).subscribe(user => this.user = user);
    this.sites = this.siteService.getMultiSiteData()
    this.showByos$ = this.featureFlagService.isFeatureEnabled$('byos').pipe(takeUntil(this.destroy$));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  gotoSingle(siteID) {
    this.router.navigate(['site', siteID, 'dashboard'])
  }

  gotoExtendedStorage(event) {
    event.stopPropagation();
    this.router.navigate(['portfolio', 'extended-storage']);

  }
}
