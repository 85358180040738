import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccessKeyService } from '../users/access-key.service';
import { ServiceHelpersService } from '../service-helpers.service';
import { map, catchError } from 'rxjs/operators';
import { ManagementCompany } from './management-company';
import { LeadSource } from './lead-source';
import { InternalEmail } from './internal-email';
import { RelatedSite } from './related-site';
import { NewLead } from './new-lead';

@Injectable({
  providedIn: 'root'
})
export class NewLeadService {
  public headers = {};
  constructor(
    private http: HttpClient,
    private helper: ServiceHelpersService,
    private accessKeyService: AccessKeyService,
  ) {
    this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key }));
  }

  getManagementCompanies(): Observable<ManagementCompany[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<ManagementCompany[]>(`/rest/admin/new-lead/managementcompanies`, { headers: headers }).pipe(catchError(this.helper.handleError))
  }

  getLeadSources(): Observable<LeadSource[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<LeadSource[]>(`/rest/admin/new-lead/leadsources`, { headers: headers }).pipe(catchError(this.helper.handleError))
  }

  getInternalEmails(): Observable<InternalEmail[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<InternalEmail[]>(`/rest/admin/new-lead/internalemails`, { headers: headers }).pipe(catchError(this.helper.handleError))
  }

  getRelatedSites(search: string): Observable<RelatedSite[]> {
    let headers = new HttpHeaders(this.headers);
    let params = new HttpParams().set('search', search);
    return this.http.get<RelatedSite[]>(`/rest/admin/new-lead/relatedsites`, { headers: headers, params: params }).pipe(catchError(this.helper.handleError))
  }

  submitNewLead(lead: NewLead): Observable<any> {
    let headers = new HttpHeaders(this.headers);
    return this.http.post<any>(`/rest/admin/new-lead`, lead, { headers: headers }).pipe(catchError(this.helper.handleError))
  }

  uploadNewLeadImages(images: File[]): Observable<string[]> {
    let headers = new HttpHeaders(this.headers);
    let formData = new FormData();
    images.forEach((image) => {
      formData.append("imageFiles", image, image.name);
    });
    
    return this.http.post<string[]>(`/rest/admin/new-lead/images`, formData, { headers: headers }).pipe(catchError(this.helper.handleError))
  }
}
