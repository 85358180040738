import { AddCamsMarker } from "../add-cams-marker";
import { Camera } from "app/camera/camera";

export const AddCamsKey = "rewindAddCams";

export interface State {
    selectedAdditionalCameras: AddCamsMarker[],
    primaryCamera: AddCamsMarker,
    cameras: {[id: number]: Camera},
    maxCameraModalVisible: boolean,
}

export const initialState: State = {
    selectedAdditionalCameras: [],
    primaryCamera: null,
    cameras: {},
    maxCameraModalVisible: false,
}