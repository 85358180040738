// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clickable {
    cursor: pointer;
}

li > a {
    color: #555;
}

.tab-container {
    max-width: 1280px;
    max-height: 220px;
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
    font-size: 14px ;
}

.nav-tabs > li.active > a, 
.nav-tabs > li.active > a:focus, 
.nav-tabs > li.active > a:hover 
{
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
    border-radius: 4px 4px 0 0;
}

.nav-tabs > li > a:hover {
    background-color: #E5E4E2;
}

`, "",{"version":3,"sources":["webpack://./src/app/map/map-container.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;;;;IAII,WAAW;IACX,eAAe;IACf,sBAAsB;IACtB,sBAAsB;IACtB,gCAAgC;IAChC,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".clickable {\r\n    cursor: pointer;\r\n}\r\n\r\nli > a {\r\n    color: #555;\r\n}\r\n\r\n.tab-container {\r\n    max-width: 1280px;\r\n    max-height: 220px;\r\n}\r\n\r\n.nav > li > a {\r\n    position: relative;\r\n    display: block;\r\n    padding: 10px 15px;\r\n    font-size: 14px ;\r\n}\r\n\r\n.nav-tabs > li.active > a, \r\n.nav-tabs > li.active > a:focus, \r\n.nav-tabs > li.active > a:hover \r\n{\r\n    color: #555;\r\n    cursor: default;\r\n    background-color: #fff;\r\n    border: 1px solid #ddd;\r\n    border-bottom-color: transparent;\r\n    border-radius: 4px 4px 0 0;\r\n}\r\n\r\n.nav-tabs > li > a:hover {\r\n    background-color: #E5E4E2;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
