// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
    box-sizing: border-box;
}
label {
    min-height: 26px;
}
.error {
    color: red;
}
td {
    padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-permission-templates/permission-template-form/permission-template-form.component.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;AACA;IACI,gBAAgB;AACpB;AACA;IACI,UAAU;AACd;AACA;IACI,mBAAmB;AACvB","sourcesContent":["input {\r\n    box-sizing: border-box;\r\n}\r\nlabel {\r\n    min-height: 26px;\r\n}\r\n.error {\r\n    color: red;\r\n}\r\ntd {\r\n    padding-right: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
