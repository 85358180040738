import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AdminService } from '../admin.service';
import { SiteService } from '../../site/site.service';

@Component({
    selector: 'app-site-metrics',
    templateUrl: './site-metrics.component.html',
    styleUrls: ['./site-metrics.component.css']
})
export class SiteMetricsComponent {
    pieData = [];
    lineData = [];
    siteID: number;
    metrics;
    userData;
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    constructor(public adminService: AdminService, public route: ActivatedRoute, public siteService: SiteService) {}
    ngOnInit() {
        this.route.params.subscribe(params => {
            this.siteID = +params['siteID'];
            this.adminService.getSiteMetrics(this.siteID).subscribe(metrics => {
                this.metrics = metrics;
                this.userData = metrics.userStatistics;
                if(metrics.logins) {
                    this.lineData = this.formatData(metrics.logins);
                    this.pieData = [];
                    this.userData.forEach(datum =>{
                        this.pieData.push({name: datum.userName, value: datum.logins})
                    })
                }
            })
        })
        
    }
    formatData(data) {
        let formatedData = [];
        let dataMap = {};
        let years = [];
        let months = this.months
        data.forEach(datum => {
            if(dataMap[datum.year]) {
                dataMap[datum.year].series.push({name: months[datum.month -1], value: datum.amount, month: datum.month})
            } else {
                dataMap[datum.year] = {name: datum.year, series: [{name: months[datum.month - 1], value: datum.amount, month: datum.month}]}
                years.push(datum.year)
            }
        })
        years.forEach(year => {
            for(let i = 0; i < 12; i++) {
                if(!dataMap[year].series[i]) {
                    dataMap[year].series.push({name: months[i], value: 0, month: i + 1})
                }
                if(dataMap[year].series[i].month !== i+1) {
                    dataMap[year].series.splice(i, 0, {name: months[i], value: 0, month: i + 1})
                }
            }
        })
        years.forEach(year => {
            formatedData.push({name: year + "", series: dataMap[year].series})
        })
        return formatedData;
    }

}