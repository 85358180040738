import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PermissionTemplate } from '../permission-template';
import { booleanSiteFields, SitePermissions } from 'app/permissions';

@Component({
  selector: 'app-permission-template-form',
  templateUrl: './permission-template-form.component.html',
  styleUrls: ['./permission-template-form.component.css']
})
export class PermissionTemplateFormComponent implements OnInit {
  @Input("active") set activeSetter(value: boolean) {
    this.active = value;
  }
  @Input("mode") set modeSetter(value: string) {
    this.mode = value;
  }
  @Input("template") set templateSetter(value: PermissionTemplate) {
    if(value) {
      this.template = value;
    }
  }
  @Input("perms") set permSetter(value: any) {
    this.newPerms = {};
    for (var key in value) {
      if (value[key] === true) {
        this.newPerms[key] = true;
      }
      }
  }

  @Input('name') name: string;
  @Input('dupName') dupName: boolean;
  @Input('desc') desc: string;
  @Input('saving') saving: boolean;
  @Output('save') saveEmitter = new EventEmitter<string>();
  @Output('nameChange') nameChangeEmitter = new EventEmitter<string>();
  @Output('descChange') descChangeEmitter = new EventEmitter<string>();
  @Output('permsChange') permsChangeEmitter = new EventEmitter<any>();

  public active: boolean = false;
  public mode: string = '';
  public template: PermissionTemplate;
  public siteFields = booleanSiteFields;
  public newPerms: any = {};

  constructor() { }

  ngOnInit() {
  }

  save() {
    this.saveEmitter.emit(this.mode);
  }
  nameChange(name: string) {
    this.nameChangeEmitter.emit(name);
  }

  descChange(desc: string) {
    this.descChangeEmitter.emit(desc);
  }

  permsChange() {
    for (var key in this.newPerms) {
      if(this.newPerms[key] === false) {
        delete this.newPerms[key];        
      }
    }
    this.permsChangeEmitter.emit(this.newPerms)
  }
}
