import { props, createAction } from "@ngrx/store";
import { RawRequestCameraMarker } from "../raw-request-camera-marker";
import { MotionDateTime } from "../../motion/motion-datetime-modal/motion-datetime";
import { Map } from "app/map/map";




export const clickCamera = createAction("[Raw Request] Click Camera", props<{marker: RawRequestCameraMarker}>());

export const addCamera = createAction("[Raw Request] Add Camera", props<{marker: RawRequestCameraMarker}>());

export const removeCamera = createAction("[Raw Request] Remove Camera", props<{marker: RawRequestCameraMarker}>());

export const resetCameras = createAction("[Raw Request] Reset Cameras");

export const clickChangeTimeButton = createAction("[Raw Request] Click Change Time Button");

export const submitTimeChange = createAction("[Raw Request] Submit Time Change", props<{selectedTime: MotionDateTime}>());

export const hideTimeModal = createAction("[Raw Request] Hide Time Modal");

export const setMaps = createAction("[Raw Request] Set Maps", props<{maps: Map[]}>());

export const maxCameraModalOpen = createAction("[Raw Request] Open Max Camera Modal");

export const maxCameraModalClose = createAction("[Raw Request] Close Max Camera Modal");


export const submit = createAction("[Raw Request] Submit");

export const setDescription = createAction("[Raw Request] Set Description", props<{description: string}>());

export const requestSubmited = createAction("[Raw Request] Request submitted");

export const showTimeUpdate = createAction("[Raw Request] Show time update modal");

export const hideTimeUpdate = createAction("[Raw Request] Hide time update modal");

export const startRequestSubmit = createAction("[Raw Request] Start request submit");

export const futureTimeUpdate = createAction("[Raw Request] Update time to remove future times");

export const noAction = createAction("[Raw Request] No Action");

export const resetRaw = createAction("[Raw Request] Reset");

export const loadingRawForSite = createAction("[Raw Request] Loading Raw For Site", props<{siteID: number}>());

export const setRawSiteID = createAction("[Raw Request] Set Raw SiteID", props<{siteID: number}>());