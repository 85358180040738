// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.tab.active {
  background-color: #ffffff;
  color: #43aaa3;
}

button.tab.inactive {
  background-color: #28303d;
  color: #ffffff;
}

.tab-bar {
  width: 100%;
  height: 50px;
  min-width: 325px;
  background-color: #28303d;
  color: #ffffff;
  border-style: solid;
  border-color: #28303d;
  border-width: 1px 0px 0px 1px;
  margin-top: 30px;
}

.tab {
  height: 100%;
  border: 0;
  background: none;
  box-shadow: none;
  border-radius: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.section {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/lpr/lpr.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACI,yBCKO;EDJP,cCGS;ADJb;;AAIA;EACI,yBCMU;EDLV,cCIY;ADLhB;;AAIA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;EACA,yBCFU;EDGV,cCJY;EDKZ,mBAAA;EACA,qBCLU;EDMV,6BAAA;EACA,gBAAA;AADJ;;AAIA;EACI,YAAA;EACA,SAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,UAAA;EACA,iBAAA;EACA,kBAAA;AADJ","sourcesContent":["@import \"../../variables.scss\";\r\n\r\nbutton.tab.active {\r\n    background-color: $bg-color;\r\n    color: $text-color;\r\n}\r\n\r\nbutton.tab.inactive {\r\n    background-color: $selected-bg;\r\n    color: $selected-text;\r\n}\r\n\r\n.tab-bar {\r\n    width: 100%;\r\n    height: 50px;\r\n    min-width: 325px;\r\n    background-color: $selected-bg;\r\n    color: $selected-text;\r\n    border-style: solid;\r\n    border-color: $selected-bg;\r\n    border-width: 1px 0px 0px 1px;\r\n    margin-top: 30px;\r\n}\r\n\r\n.tab {\r\n    height: 100%;\r\n    border: 0;\r\n    background: none;\r\n    box-shadow: none;\r\n    border-radius: 0px;\r\n    padding-left: 10px;\r\n    padding-right: 10px;\r\n}\r\n\r\n.section {\r\n    width: 75%;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n}","$base-theme:Default;\r\n$skin-name:blue;\r\n$accent: #13688c;\r\n$info: #0066cc;\r\n$success: #37b400;\r\n$warning: #ffb400;\r\n$error: #db4240;\r\n$text-color: #43aaa3;\r\n$bg-color: #ffffff;\r\n$base-text: #003f59;\r\n$base-bg: #daecf4;\r\n$hovered-text: #003f59;\r\n$hovered-bg: #7bd2f6;\r\n$selected-text: #ffffff;\r\n$selected-bg: #28303d;\r\n$series-a: #314652;\r\n$series-b: #0098ee;\r\n$series-c: #7bd2f6;\r\n$series-d: #ffb800;\r\n$series-e: #ff8517;\r\n$series-f: #e34a00;\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
