enum Outcome {
    NoResult = 1,
    WorkingNoResultYet = 2,
    EventObserved = 3,
    InsufficientInfo = 4,
    ServiceInterruption = 6,
    ClientInterruption = 7,
    ClientCancellation = 8,
    ExpFootage = 9,
    DupTicket = 10,
    OffCamera = 11,
    Discontinued = 12,
    LPRAlert = 14
}

export default Outcome;
