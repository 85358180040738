// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selector-group {
    border: 1px solid black;
    border-radius: 25px;
    padding: 30px;
    margin-bottom: 20px;
    max-width: 1195px;
    min-width: 250px;
} 

.card {
    background-color: #F5F5F5;
    border: 1px solid #E3E3E3;
    padding-top: 18px;
    margin-bottom: 20px;
    border-radius: 4px;   
} 

.chip {
    display: inline-block;
    background-color: lightgray;
    border-radius: 15px;
    padding: 5px 8px;
    cursor: pointer;
}

/* .selector{ 
    border-width: 0px 0px 1px 0px;
    border-color: black;
    border-style: solid;
} */

/* .block {
    display: block;
} */

/* .hide {
    display: none;
} */

.cam-button {
    margin: 2px;
}

.k-primary {
    float: right;
    margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/raw-request/raw-request.component.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,2BAA2B;IAC3B,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;;;;GAIG;;AAEH;;GAEG;;AAEH;;GAEG;;AAEH;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".selector-group {\r\n    border: 1px solid black;\r\n    border-radius: 25px;\r\n    padding: 30px;\r\n    margin-bottom: 20px;\r\n    max-width: 1195px;\r\n    min-width: 250px;\r\n} \r\n\r\n.card {\r\n    background-color: #F5F5F5;\r\n    border: 1px solid #E3E3E3;\r\n    padding-top: 18px;\r\n    margin-bottom: 20px;\r\n    border-radius: 4px;   \r\n} \r\n\r\n.chip {\r\n    display: inline-block;\r\n    background-color: lightgray;\r\n    border-radius: 15px;\r\n    padding: 5px 8px;\r\n    cursor: pointer;\r\n}\r\n\r\n/* .selector{ \r\n    border-width: 0px 0px 1px 0px;\r\n    border-color: black;\r\n    border-style: solid;\r\n} */\r\n\r\n/* .block {\r\n    display: block;\r\n} */\r\n\r\n/* .hide {\r\n    display: none;\r\n} */\r\n\r\n.cam-button {\r\n    margin: 2px;\r\n}\r\n\r\n.k-primary {\r\n    float: right;\r\n    margin-right: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
