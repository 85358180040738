
import {map, take, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { SiteService } from '../site/site.service';


@Injectable()
export class LightMapGuard  {
    constructor(public siteService: SiteService, public router: Router){}
    canActivate() {
        return this.siteService.getSite().pipe(filter(site => site !== null),take(1),map(site => {
            if(site.lightMaps && site.lightMaps.length > 0) {
                return true;
            }
            this.router.navigate(['site', site.siteID, 'dashboard'])
            return false;
        }),)
    }
}