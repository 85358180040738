import { Action } from '@ngrx/store';


export const SET_MAP = 'SET_MAP';

export const initialPropertyMap = {
    selectedMapID: 0,
}

export interface PropertyMapState {
    selectedMapID: number;
}

export function propertyMapReducer(state: PropertyMapState, action) {
    switch( action.type) {
        case SET_MAP:
            return { selectedMapID: action.payload };
        default:
            return state;
    }
}