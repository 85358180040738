import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { config } from '../app.config';
import { Logger } from '../logger/logger.service';
import { AccessKeyService } from '../users/access-key.service';
import { ServiceHelpersService } from '../service-helpers.service';
import { Request } from 'app/events/request';
import { Request as FormRequest } from './request';
import { catchError, map } from 'rxjs/operators';
import { ServiceRequest } from '../service-request/service-request';

@Injectable()
export class RequestService {
    public headers =  {};
    constructor(public http: HttpClient, public store: Store<AppState>, public logger: Logger, public accessKeyService: AccessKeyService, public serviceHelpersService: ServiceHelpersService) {
        this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey" : key}));
    }
    sendRequest(request: FormRequest): Observable<Request> {
        let headers = new HttpHeaders(Object.assign({}, this.headers, { "Content-Type": 'application/json'}));
        let backendRequest: any = Object.assign({}, request);
        backendRequest.cameras = { cameras: request.cameras.cameras.map(camera => {
            return {id: camera.id}
        })};
        let body = JSON.stringify(backendRequest);
        return this.http.post<Request>(config.apiUrl + "request", body, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError), map(r => new Request(r)));
    }

    serviceRequest(request: ServiceRequest, siteID: number): Observable<ServiceRequest> {
        let headers = new HttpHeaders(Object.assign({}, this.headers, {"Content-Type": 'application/json'}));
        let body = JSON.stringify(request);
        return this.http.post<ServiceRequest>(config.apiUrl+"site/" + siteID + "/service-request", body, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError), map(r => new ServiceRequest(r)));
    }

}