import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { NgModule } from "@angular/core";

import { FeatureEffects, SitePackageEffects } from "./effects";
import * as fromSitePackages from "./reducers";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSitePackages.sitePackageFeatureKey, fromSitePackages.reducers),
    EffectsModule.forFeature([SitePackageEffects, FeatureEffects]),
  ],
})
export class SitePackageModule {}
