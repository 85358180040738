import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import SitePackage from "../models/site-package";
import { SitePackageAPIActions, SitePackageActions } from "../actions";

export const sitePackageFeatureKey = "sitePackages";

export interface State extends EntityState<SitePackage> {
  error: Error;
}

export const sitePackageAdapter: EntityAdapter<SitePackage> = createEntityAdapter<SitePackage>({
  selectId: (sitePackage: SitePackage) => sitePackage.id,
  sortComparer: false,
});

export const initialState: State = sitePackageAdapter.getInitialState({
  error: null,
});

export const reducer = createReducer(
  initialState,
  on(SitePackageActions.loadSitePackage, (state, { sitePackage }) => sitePackageAdapter.addOne(sitePackage, state)),
  on(SitePackageAPIActions.loadSitePackagesSuccess, (state, { sitePackages }) =>
    sitePackageAdapter.addMany(sitePackages, {
      ...state,
      error: null,
    }),
  ),
  on(SitePackageAPIActions.loadSitePackagesFailure, (state, { errorMessage }) => ({
    ...state,
    error: {
      name: "failed to load site packages",
      message: errorMessage,
    },
  })),
  on(SitePackageAPIActions.updateSitePackageSuccess, (state, { sitePackage }) =>
    sitePackageAdapter.updateOne(
      { id: sitePackage.id, changes: sitePackage },
      {
        ...state,
        error: null,
      },
    ),
  ),
  on(SitePackageAPIActions.updateSitePackageFailure, (state, { errorMessage }) => ({
    ...state,
    error: {
      name: "failed to update site package",
      message: errorMessage,
    },
  })),
  on(SitePackageAPIActions.createSitePackageSuccess, (state, { sitePackage }) =>
    sitePackageAdapter.addOne(sitePackage, {
      ...state,
      error: null,
    }),
  ),
  on(SitePackageAPIActions.createSitePackageFailure, (state, { errorMessage }) => ({
    ...state,
    error: {
      name: "failed to create site package",
      message: errorMessage,
    },
  })),
  on(SitePackageAPIActions.deleteSitePackageSuccess, (state, { id }) =>
    sitePackageAdapter.removeOne(id, {
      ...state,
      error: null,
    }),
  ),
  on(SitePackageAPIActions.deleteSitePackageFailure, (state, { errorMessage }) => ({
    ...state,
    error: {
      name: "failed to delete site package",
      message: errorMessage,
    },
  })),
);

export const getError = (state: State) => state.error;
