// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hideplayer {
    display: none;
}

.player {
    width: 100%;
    height: 100%;
    position: relative;
    /* display: block; */
}
.cvsNoVideo {
    width: 100%;
    height: 100%;
    object-fit: none;
    background-color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/camera/live-view/hybrid-player/hybrid-player.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".hideplayer {\r\n    display: none;\r\n}\r\n\r\n.player {\r\n    width: 100%;\r\n    height: 100%;\r\n    position: relative;\r\n    /* display: block; */\r\n}\r\n.cvsNoVideo {\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: none;\r\n    background-color: black;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
