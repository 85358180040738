import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Layout } from '../layout';
import { GridSize } from '../gridsize';
import { Camera } from '../../camera/camera';
import { CameraView } from '../camera-view';
import { Router } from '@angular/router';
import { Site } from 'app/site/site';
import { SiteService } from 'app/site/site.service';

@Component({
  selector: 'app-layout-form',
  templateUrl: './layout-form.component.html',
  styleUrls: ['./layout-form.component.css']
})
export class LayoutFormComponent implements OnInit {
  @Input('layout') set setLayout(value: Layout) {
    if (value) {
      if(value.id !== this.layoutID && this.gridSizesMap[value.gridSizeID]) {
        this.cameraAssign = [];
      }
      this.layoutID = value.id;
      this.layout = Object.assign({}, value);
      this.populateAssign();      
    } else {
      this.layout = { id: null, siteID: null, name: "", gridSizeID: null, cameraViews: [], showCameraName: false };
    }
  }

  @Input('gridSizes') set setGridSizes(value: GridSize[]) {
    if (value) {
      this.gridSizes = value;
      this.gridSizes.forEach(size => {
        this.gridSizesMap[size.id] = size;
      })
      this.populateAssign();
    }
  }

  @Input('siteID') set setSiteID(value: number) {
    if (value) {
      this.siteID = value;
    }
  }

  @Input('cameras') set setCameras(value: Camera[]) {
    if (value) {
      this.cameras = value;
      this.cameras.forEach(cam => {
        this.cameraMap[cam.id] = cam;
        this.cameraImageMap[cam.id] = "/rest/site/" + cam.siteID + "/source/1/camera/" + cam.id + "/file/" + cam.dayReferenceFilename;
      });
    }
  }

  @Input('dupName') set setDupName(value: boolean) {
    this.dupName = value;
  }

  @Input('unsavedChanges') set setUnsavedChanges(value: boolean) {
    this.unsavedChanges = value;
  }

  @Input('cameraLayoutsMap') set setCameraLayoutsMap(value: any) {
    if(value) {
      this.cameraLayoutsMap = value;
    }
  }

  @Output('delete') deleteEmitter = new EventEmitter<Layout>();
  @Output('save') saveEmitter = new EventEmitter<Layout>();
  @Output('changes') changesEmitter = new EventEmitter<Layout>();

  constructor(
    public router: Router,
    public siteService: SiteService,
  ) { }

  public site: Site;
  public layout: Layout = { id: null, siteID: null, name: "", gridSizeID: null, cameraViews: [], showCameraName: false };
  public layoutID: number;
  public gridSizes: GridSize[] = [];
  public gridSizesMap: {[gridSizeID: number]: GridSize} = {};
  public siteID: number;
  public cameras: Camera[] = [];
  public cameraMap: {} = {};
  public cameraAssign: number[][] = [];
  public selectedCell: {row: number, column: number};
  public selectedCamera: number;
  public defaultCamera: { displayName: string, value: number } = { displayName: "none", value: null };
  public dupName: boolean;
  public unsavedChanges: boolean;
  public cameraLayoutsMap: {} = null;
  public cameraImageMap: {} = {};
  
  private lastSizeID: number;

  ngOnInit() {
    this.siteService.getSite().subscribe(site => {
      this.site = site;
    })
    this.createAssign();
  }

  onNameChange() {
    this.updateLayoutChanges();
  }

  onGridSizeChange(event) {
    this.updateLayoutChanges();
    this.lastSizeID = event;
  }

  onShowCameraNameChange() {
    this.updateLayoutChanges();
  }

  onCameraDropDownChange() {
    this.updateLayoutChanges();
  }

  getGridSize(id: number) {
    let rows = this.gridSizesMap[id].rows;
    let columns = this.gridSizesMap[id].columns;
    return rows * columns
  }

  saveLayout() {
    this.layout.siteID = this.siteID;
    this.saveEmitter.emit(this.layout);
  }

  deleteLayout() {
    this.deleteEmitter.emit(this.layout);
  }

  viewLayout() {
    this.router.navigate(['site', this.site.siteID, 'cvs', this.layout.id]);
  }

  rowCounter(i: number) {
    return new Array(i);
  }

  columnCounter(i: number) {
    return new Array(i);
  }

  getGridCellNumber(i: number, j: number) {
    let rows = this.gridSizesMap[this.layout.gridSizeID].rows;
    let cellNumber = i * rows + (j + 1);
    return cellNumber
  }

  resetCameraAssignments() {
    this.selectedCell = null;
    this.cameraAssign = [];
    this.createAssign();
    this.updateLayoutChanges();
  }

  selectCell(row: number, column: number) {
    this.selectedCell = {row, column};
  }

  selectCamera(camera: number) {
    this.selectedCamera = camera;
    if (this.selectedCell != null) {
      this.cameraAssign[this.selectedCell.row][this.selectedCell.column] = this.selectedCamera;
      this.updateLayoutChanges();
    }
  }

  get filterCameras() {
    return this.cameras.filter(cam => cam.id != null);
  }

  drop(ev, row, column) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    this.cameraAssign[row][column] = parseInt(data);
    this.updateLayoutChanges();

  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  drag(ev, camID) {
    ev.dataTransfer.setData("text", camID);
  }

  updateLayoutChanges() {
    let cameraViews: CameraView[] = [];
    let rows = this.gridSizesMap[this.layout.gridSizeID].rows;
    let columns = this.gridSizesMap[this.layout.gridSizeID].columns;
    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < columns; j++) {
        let cameraView = new CameraView();
        if (this.cameraAssign[i][j]) {
          cameraView.cameraID = this.cameraAssign[i][j];
          cameraView.cvsLayoutID = this.layout.id;
          cameraView.gridPosition = i * columns + j + 1;
          cameraViews.push(cameraView);
        }  
      }
    }
    this.layout.cameraViews = cameraViews;
    this.changesEmitter.emit(this.layout);
  }
  populateAssign() {
    if(!this.layout || !this.layout.gridSizeID || (!this.gridSizes || this.gridSizes.length === 0)) {
      return
    }
    if(!this.layout.cameraViews) {
      this.cameraAssign = [];
      this.createAssign();
      return;
    }
    let gridSize = this.gridSizesMap[this.layout.gridSizeID];
    this.createAssign();
    this.layout.cameraViews.forEach((cam, i) => {
      this.cameraAssign[Math.floor((cam.gridPosition - 1) / gridSize.rows)][Math.floor((cam.gridPosition - 1) % gridSize.rows)] = cam.cameraID
    })

  }
  createAssign() {
    for(let i = 0; i < 10; i++) {
      if(!this.cameraAssign[i]) {
        this.cameraAssign[i] = [];
      }
      for(let j = 0; j < 10; j++) {
        if(!this.cameraAssign[i][j]) {
          this.cameraAssign[i][j] = undefined;
        }
      }
    }
  }
}
