import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, EventEmitter, Output } from "@angular/core";
import { CloudVideo } from "../cloud-video";
import { fromEvent } from "rxjs";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "app-cloud-player",
  templateUrl: "./cloud-player.component.html",
  styleUrls: ["./cloud-player.component.scss"],
})
export class CloudPlayerComponent implements OnInit, AfterViewInit {

  @Input("video") set setVideo(value: CloudVideo) {
    this.video = value;
    this.cloudPlayer.nativeElement.load();
  }

  @Input("startTime") set setStartTime(value: number) {
    this.startTime = value;
  }

  @Input("videoCount") set setVideoCount(value: number) {
    this.videoCount = value;
  }

  @Input("selectedVideoIndex") set setSelectedVideoIndex(value: number) {
    this.selectedVideoIndex = value;
  }

  @Input("paused") set setPaused(value: boolean) {
    if (value) {
      this.cloudPlayer.nativeElement.pause();
    }
  }
  @Input() hasRequestFeature: boolean;
  @Input() hasRequestPermission: boolean;
  
  @Output() timeUpdate: EventEmitter<number> = new EventEmitter<number>();
  @Output() done: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() playClicked: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() foundItClicked: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() videoChanged: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild("cloudPlayer", { static: true }) cloudPlayer: ElementRef;

  public currentPlaybackRate: number;
  public video: CloudVideo;
  public startTime: number;
  public videoCount: number;
  public selectedVideoIndex: number;

  public top: string = "0px";
  public right: string = "0px";

  private defaultPlaybackRate: number = 1;
  private maxPlaybackRate: number = 16;

  public alive: boolean = true;

  constructor() { }

  public ngOnInit() {
    this.currentPlaybackRate = this.defaultPlaybackRate;
  }
  
  public ngAfterViewInit() {
    this.cloudPlayer.nativeElement.addEventListener(
      "timeupdate",
      (event: { currentTarget: { currentTime: number } }) => {
        this.timeUpdate.emit(event.currentTarget.currentTime);
      },
    );

    this.cloudPlayer.nativeElement.addEventListener("ended", (event: Event) => {
      this.done.emit();
    });

    this.cloudPlayer.nativeElement.addEventListener("loadedmetadata", (event: Event) => {
      this.cloudPlayer.nativeElement.currentTime = this.startTime;
      this.cloudPlayer.nativeElement.playbackRate = this.currentPlaybackRate;
    });

    this.cloudPlayer.nativeElement.addEventListener("play", (event: Event) => {
        this.playClicked.emit();
     });
     fromEvent(window, "resize").pipe(takeWhile(() => this.alive)).subscribe(() => {this.resize()});
  }
  public ngOnDestroy() {
    this.alive = false;
  }
  public onIncreasePlaybackRate() {
    if (this.cloudPlayer.nativeElement.playbackRate >= this.maxPlaybackRate) {
      this.cloudPlayer.nativeElement.playbackRate = this.defaultPlaybackRate;
      this.currentPlaybackRate = this.cloudPlayer.nativeElement.playbackRate;
      return;
    }

    this.cloudPlayer.nativeElement.playbackRate *= 2;
    this.currentPlaybackRate = this.cloudPlayer.nativeElement.playbackRate;
  }

  public foundIt() {
    this.foundItClicked.emit();
  }

  public onVideoChanged(index: number) {
    this.selectedVideoIndex = index;
    this.videoChanged.emit(this.selectedVideoIndex);
  }
  resize() {
    let videoElement = this.cloudPlayer.nativeElement;
    let vwidth = videoElement.videoWidth;
    let vheight = videoElement.videoHeight;
    let cwidth = videoElement.clientWidth;
    let cheight = videoElement.clientHeight;
    let vidRatio = vwidth / vheight;
    let dispRatio = cwidth / cheight;
    this.top = "0px";
    this.right = "0px";
    if(vidRatio > dispRatio) {
      this.top = (cheight - (cwidth / vwidth * vheight)) / 2 + "px";
    } else {
      this.right = (cwidth - (cheight / vheight * vwidth)) / 2 + "px";
    }
  }  
}
