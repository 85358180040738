export class GraphSeries {
    name: string;
    series: GraphPoint[];
    constructor(config?:any) {
        if(config) {
            Object.assign(this, config);
            if(config.series) {
                this.series = config.series.map(p => new GraphPoint(p));
            } else {
                this.series = [];
            }
        }
    }
}

export class GraphPoint {
    value: number;
    name: string;
    constructor(config?:any) {
        if(config) {
            Object.assign(this, config);
        }
    }
}