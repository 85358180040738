// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description {
    width: 100%;
    resize: none;
    height: 150px;
}
#vehicleInvolved {
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/requests/new-request/what/what.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".description {\r\n    width: 100%;\r\n    resize: none;\r\n    height: 150px;\r\n}\r\n#vehicleInvolved {\r\n    margin-right: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
