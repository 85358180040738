
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from "rxjs";
import { AppState } from 'app/app.state';
import { siteListGridState, siteListGridView } from './store/selectors';
import * as siteManagementActions from './store/actions';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State} from '@progress/kendo-data-query';
import { ManageSiteItem } from "../../sites/manage-site-item";

@Component({
    selector: "app-site-management",
    templateUrl: "./site-management.component.html",
    styleUrls: ["./site-management.component.css"],
})
export class SiteManagementComponent implements OnInit {
 
    public sitesView$: Observable<GridDataResult>;
    public sitesGridState$: Observable<State>;

    constructor(public router: Router, private store: Store<AppState>, ) {}
    ngOnInit() { 
        this.store.dispatch(siteManagementActions.loadSiteList());

        this.sitesView$ = this.store.pipe(select(siteListGridView));
        this.sitesGridState$ = this.store.pipe(select(siteListGridState));
    }

    dataStateChange(state: DataStateChangeEvent): void {
        this.store.dispatch(siteManagementActions.setSiteListGridState({gridState: state}));
    }

    selectSite(site: ManageSiteItem) {
        this.router.navigate(['admin', 'sites', 'setup', site.siteID]);
    }

}