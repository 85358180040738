export class When {
  start: Date;
  end: Date;
  evidenceDiscovered: Date;
  evidenceDiscoveredBy: string;
  valid() {
    const MAX_FOOTAGE_AGE = 1000 * 60 * 60 * 24 * 14;
    const MAX_TIME = 1000 * 60 * 60 * 72;

    let errors = { start: [], end: [] };
    let valid = true;
    if (!this.start) {
      valid = false;
      errors.start.push("Must set a start date/time.");
    }

    if (!this.end) {
      valid = false;
      errors.end.push("Must set an end date/time.");
    }

    if (!valid) {
      return { valid: valid, errors: errors };
    }

    let now = new Date();
    let startBeforeToday = this.start.getTime() < now.getTime();
    let endBeforeToday = this.end.getTime() < now.getTime();

    let beforeFootage = this.start.getTime() < now.getTime() - MAX_FOOTAGE_AGE;
    let duration = this.end.getTime() - this.start.getTime();

    if (beforeFootage) {
      errors.start.push("Start time must be in the last 14 days.");
      valid = false;
    }

    if (!startBeforeToday) {
      errors.start.push("Start time must be before now.");
      valid = false;
    }

    if (!endBeforeToday) {
      errors.end.push("End time must be before now.");
      valid = false;
    }

    if (duration > MAX_TIME) {
      errors.end.push("Selected time frame cannot be greater than 72 hours.");
      valid = false;
    }

    if (duration < 0) {
      errors.start.push("Start time must be before End time.");
      valid = false;
    }
    
    return { valid: valid, errors: errors };
  }
}
