
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import { START_REQUEST_CONFIRMATION, SET_REQUEST_CURRENT_CONFIRMATION} from '../../requests.reducer';

@Injectable()
export class ConfirmationGuard  {
    constructor(public store: Store<AppState>) {}
    canActivate() {
        // this.store.dispatch({type: START_REQUEST_CONFIRMATION });
        this.store.dispatch({type: SET_REQUEST_CURRENT_CONFIRMATION });
        return true;
    }
}