import * as fromSitePackages from "./site-package.reducer";
import * as fromFeatures from "./feature.reducer";
import { Action, combineReducers, createSelector, createFeatureSelector } from "@ngrx/store";

export const sitePackageFeatureKey = "sitePackages";

export interface SitePackageState {
  [fromSitePackages.sitePackageFeatureKey]: fromSitePackages.State;
  [fromFeatures.featureFeatureKey]: fromFeatures.State;
}

export interface State {
  [fromSitePackages.sitePackageFeatureKey]: SitePackageState;
}

export function reducers(state: SitePackageState | undefined, action: Action) {
  return combineReducers({
    [fromSitePackages.sitePackageFeatureKey]: fromSitePackages.reducer,
    [fromFeatures.featureFeatureKey]: fromFeatures.reducer,
  })(state, action);
}

export const selectSitePackageState = createFeatureSelector< SitePackageState>(sitePackageFeatureKey);

export const selectSitePackageEntitiesState = createSelector(selectSitePackageState, (state) => state.sitePackages);

export const selecteSitePackageError = createSelector(
  selectSitePackageEntitiesState,
  fromSitePackages.getError
)

export const {
  selectIds: selectSitePackageIds,
  selectEntities: selectSitePackageEntities,
  selectAll: selectAllSitePackages,
  selectTotal: selectTotalSitePackages,
} = fromSitePackages.sitePackageAdapter.getSelectors(selectSitePackageEntitiesState);

export const selectFeatureState = createSelector(selectSitePackageState, (state) => state.features);

export const selectFeatureError = createSelector(
  selectFeatureState,
  fromFeatures.getError,
)

export const {
  selectIds: selectFeatureIds,
  selectEntities: selectFeatureEntities,
  selectAll: selectAllFeatures,
  selectTotal: selectTotalFeatures,
} = fromFeatures.featureAdapter.getSelectors(selectFeatureState);
