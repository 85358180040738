import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromLPRState from './state';
import { sort } from "../sort-data";
import { AlertEntry } from "../manage-alert-entries/alert-entry";
import { AlertHit } from "../recent-hits/alert-hit";


export const selectLPR = createFeatureSelector<fromLPRState.State>(fromLPRState.LPRKey);

export const selectSelectedTopTab = createSelector(
    selectLPR,
    (lpr => lpr.selectedTopTab),
);

export const selectSelectedBottomTab = createSelector(
    selectLPR,
    (lpr => lpr.selectedBottomTab),
);

export const selectLPRSiteID = createSelector(
    selectLPR,
    (lpr => lpr.lprSiteID),
)

export const selectAlertEntries = createSelector(
    selectLPR,
    (lpr => lpr.alertEntries),
);

export const selectAlertEntriesSort = createSelector(
    selectLPR,
    (lpr => lpr.alertEntriesSort),
)

export const selectAlertEntriesSorted = createSelector(
    selectAlertEntries,
    selectAlertEntriesSort,
    ((entries, s) => {
        console.log(entries, s)
        console.log(sort<AlertEntry>(entries, s))
        return sort<AlertEntry>(entries, s);
    })
)

export const selectShowRemoveAlertEntryModal = createSelector(
    selectLPR,
    (lpr => lpr.showRemoveAlertEntryModal),
);

export const selectAlertEntryToRemove = createSelector(
    selectLPR,
    (lpr => lpr.alertEntryToRemove),
);

// Recent Hits

export const selectRecentHits = createSelector(
    selectLPR,
    (lpr => lpr.entriesWithRecentHitsInfo),
);

export const selectRecentHitsSort = createSelector(
    selectLPR,
    (lpr => lpr.recentHitsSort),
)

export const selectRecentHitsSorted = createSelector(
    selectRecentHits,
    selectRecentHitsSort,
    ((hits, s) => {
        console.log(hits, s)
        console.log(sort<AlertEntry>(hits, s))
        return sort<AlertEntry>(hits, s);
    })
)

// Save/Edit Alert Entry

export const selectStates = createSelector(
    selectLPR,
    (lpr => lpr.states)
)

export const selectTags = createSelector(
    selectLPR,
    (lpr => lpr.tags)
)

export const selectEditEntry = createSelector(
    selectLPR,
    (lpr => lpr.editEntry),
)

export const selectAlertEntrySuccess = createSelector(
    selectLPR,
    (lpr => lpr.success)
)

export const selectAlertEntrySaveDisabled = createSelector(
    selectLPR,
    (lpr => lpr.saveDisabled)
)

// Alert Details

export const selectAlertDetails = createSelector(
    selectLPR,
    (lpr => lpr.alertDetailsInfo),
);

export const selectAlertDetailsSort = createSelector(
    selectLPR,
    (lpr => lpr.alertDetailsSort),
)

export const selectAlertDetailsSorted = createSelector(
    selectAlertDetails,
    selectAlertDetailsSort,
    ((hits, s) => {
        return sort<AlertHit>(hits, s);
    })
)

export const selectAlertDetailsSelectedEntry = createSelector(
    selectLPR,
    (lpr => lpr.alertDetailsSelectedEntry),
)

export const selectActiveAlerts = createSelector(
    selectLPR,
    (lpr => lpr.alertDetailsActiveAlerts),
)


// View hit modal

export const selectShowViewHitModal = createSelector(
    selectLPR,
    (lpr => lpr.showViewHitModal),
)

export const selectViewHitModalHit = createSelector(
    selectLPR,
    (lpr => lpr.viewHitModalHit),
)