// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body {
    max-width: 60vw;
    max-height: 60vh;
    overflow-y: auto;
}

.modal-body {
    max-height: 80vh;
    overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-events/summary-modal/summary-modal.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".body {\r\n    max-width: 60vw;\r\n    max-height: 60vh;\r\n    overflow-y: auto;\r\n}\r\n\r\n.modal-body {\r\n    max-height: 80vh;\r\n    overflow-y: auto;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
