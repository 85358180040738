// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status {
    text-align: center;
    font-size: 1.5em;
    white-space: nowrap;
    min-width: 150px;
    background-color: white;
    width: 20%;
    display:inline-block;
    border: 1px solid  #ddd;
}

.valid {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.invalid {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.selected {
    border-collapse: separate;
    border: 1px solid black;
}

.success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}`, "",{"version":3,"sources":["webpack://./src/app/requests/new-request/progress-bar/progress-bar.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,UAAU;IACV,oBAAoB;IACpB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":[".status {\r\n    text-align: center;\r\n    font-size: 1.5em;\r\n    white-space: nowrap;\r\n    min-width: 150px;\r\n    background-color: white;\r\n    width: 20%;\r\n    display:inline-block;\r\n    border: 1px solid  #ddd;\r\n}\r\n\r\n.valid {\r\n    color: #3c763d;\r\n    background-color: #dff0d8;\r\n    border-color: #d6e9c6;\r\n}\r\n\r\n.invalid {\r\n    color: #a94442;\r\n    background-color: #f2dede;\r\n    border-color: #ebccd1;\r\n}\r\n\r\n.selected {\r\n    border-collapse: separate;\r\n    border: 1px solid black;\r\n}\r\n\r\n.success {\r\n    color: #3c763d;\r\n    background-color: #dff0d8;\r\n    border-color: #d6e9c6;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
