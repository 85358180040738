import { ActionReducer, Action } from '@ngrx/store';
import { SiteListItem } from './site-list-item';

export const SET_SITELIST = "SET_SITELIST";
export const initialSiteList: SiteListItem[] = [];

// export const siteListReducer: ActionReducer<SiteListItem[]> = (state: SiteListItem[] = initialSiteList, action: Action) => {
//     switch (action.type) {
//         case SET_SITELIST:
//             let res = [...action.payload].map(site => new SiteListItem( site.siteName, site.siteID ) )
//             return res;
//         default:
//             return state;
//     }
// }

export function siteListReducer(state: SiteListItem[] = initialSiteList, action): SiteListItem[] {
    switch (action.type) {
        case SET_SITELIST:
            let res = [...action.payload].map(site => new SiteListItem( site.siteName, site.siteID, site.managementCompanyID, "", site.status, site.packageID, site.packageName) )
            return res;
        default:
            return state;
    }    
}