// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-container {
  padding: 15px;
}

select {
  min-width: 100px;
}

.columns {
  padding: 20px;
}

.column50 {
  float: left;
  padding: 20px;
  max-width: 50%;
  min-width: 50%;
}

.column50 > h4:not(:first-child) {
  padding-top: 10px;
}

.column50-vital > h4 {
  font-weight: normal;
}

.byos-reset {
  color: red;
  cursor: pointer;
}

.byos-message {
  max-width: 350px;
  text-align: justify;
}

.btn {
  background-color: #fff;
  border-color: #ccc;
}

.btn:hover {
  background-color: rgba(245, 245, 245, 0.9333333333);
  border: 1px solid #000;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/site-management/site-setup-form/site-setup-form.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,WAAA;EACA,aAAA;EACA,cAAA;EACA,cAAA;AACJ;;AAGA;EACI,iBAAA;AAAJ;;AAGA;EACI,mBAAA;AAAJ;;AAGA;EACI,UAAA;EACA,eAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,sBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,mDAAA;EACA,sBAAA;AAAJ","sourcesContent":[".address-container {\r\n    padding: 15px;\r\n}\r\n\r\nselect {\r\n    min-width: 100px;\r\n}\r\n\r\n.columns {\r\n    padding: 20px;\r\n}\r\n\r\n.column50 {\r\n    float: left;\r\n    padding: 20px;\r\n    max-width: 50%;\r\n    min-width: 50%;\r\n    \r\n}\r\n\r\n.column50 > h4:not(:first-child) {\r\n    padding-top: 10px;\r\n}\r\n\r\n.column50-vital > h4 {\r\n    font-weight: normal;\r\n}\r\n\r\n.byos-reset {\r\n    color: red;\r\n    cursor: pointer;\r\n}\r\n\r\n.byos-message {\r\n    max-width: 350px;\r\n    text-align: justify;\r\n}\r\n\r\n.btn {\r\n    background-color: #fff;\r\n    border-color: #ccc;\r\n}\r\n\r\n.btn:hover {\r\n    background-color: #f5f5f5ee;\r\n    border: 1px solid #000;\r\n} \r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
