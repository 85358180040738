// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .k-grid .k-dropdown {
    width: 85px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/account-management/account-management.component.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B","sourcesContent":["::ng-deep .k-grid .k-dropdown {\r\n    width: 85px !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
