// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panelbar-wrapper {
    max-width: 600px;
    margin: 0 auto;
}

.panel-bar {
    font-size: 16px;
}

.expired {
    margin-bottom: 5px;
}

.dropzone {
    min-height: 300px;
    min-width: 300px;
    display: table;
    background-color: #eee;
    border: dotted 1px #aaa;
    margin: 20px auto;
  }
  
  .text-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  
  .centered {
    font-family: sans-serif;
    font-size: 1.3em;
    font-weight: bold;
    text-align:center;
  }
    
  .nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
    font-size: 14px ;
}

.nav-tabs > li.active > a, 
.nav-tabs > li.active > a:focus, 
.nav-tabs > li.active > a:hover 
{
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
    border-radius: 4px 4px 0 0;
}

a {
    color: #337ab7;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-documents/admin-documents.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,uBAAuB;IACvB,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;IACnB,sBAAsB;EACxB;;EAEA;IACE,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;;;;IAII,WAAW;IACX,eAAe;IACf,sBAAsB;IACtB,sBAAsB;IACtB,gCAAgC;IAChC,0BAA0B;AAC9B;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".panelbar-wrapper {\r\n    max-width: 600px;\r\n    margin: 0 auto;\r\n}\r\n\r\n.panel-bar {\r\n    font-size: 16px;\r\n}\r\n\r\n.expired {\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.dropzone {\r\n    min-height: 300px;\r\n    min-width: 300px;\r\n    display: table;\r\n    background-color: #eee;\r\n    border: dotted 1px #aaa;\r\n    margin: 20px auto;\r\n  }\r\n  \r\n  .text-wrapper {\r\n    display: table-cell;\r\n    vertical-align: middle;\r\n  }\r\n  \r\n  .centered {\r\n    font-family: sans-serif;\r\n    font-size: 1.3em;\r\n    font-weight: bold;\r\n    text-align:center;\r\n  }\r\n    \r\n  .nav > li > a {\r\n    position: relative;\r\n    display: block;\r\n    padding: 10px 15px;\r\n    font-size: 14px ;\r\n}\r\n\r\n.nav-tabs > li.active > a, \r\n.nav-tabs > li.active > a:focus, \r\n.nav-tabs > li.active > a:hover \r\n{\r\n    color: #555;\r\n    cursor: default;\r\n    background-color: #fff;\r\n    border: 1px solid #ddd;\r\n    border-bottom-color: transparent;\r\n    border-radius: 4px 4px 0 0;\r\n}\r\n\r\na {\r\n    color: #337ab7;\r\n    text-decoration: none;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
