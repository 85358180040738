// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
    border: 1px solid #ccc;
}

label {
    font-weight: bold;
}

.mb-3 {
    margin-top: 15px;
}

.astrik {
    padding: 0px 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/first-login/first-login-form/first-login-form.component.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".btn {\r\n    border: 1px solid #ccc;\r\n}\r\n\r\nlabel {\r\n    font-weight: bold;\r\n}\r\n\r\n.mb-3 {\r\n    margin-top: 15px;\r\n}\r\n\r\n.astrik {\r\n    padding: 0px 2px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
