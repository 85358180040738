import { Component, OnInit } from '@angular/core';
import { MessageService } from '../message.service';
import { Message } from '../message';
import { NotificationsService } from '../../notifications/notifications.service';
import { UserService } from 'app/users/user.service';
import { PermissionsService } from '../../permissions.service';
import { take, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import * as messagesActions from "../store/actions";

@Component({
  selector: 'app-new-messages',
  templateUrl: './new-messages.component.html',
  styleUrls: ['./new-messages.component.css']
})
export class NewMessagesComponent implements OnInit {
  showMessage: boolean = false;
  newMessages: Message[] = [];
  currentMessageNumber: number = 0;
  constructor(
    private messageService: MessageService,
    private notificationsService: NotificationsService,
    private userService: UserService,
    private permissions: PermissionsService,
    private store: Store<AppState>,
     ) { }

  ngOnInit() {
    this.userService.getUser().pipe(filter(user => !user.initial), take(1)).subscribe(u => {
      if (this.permissions.user('canMessage')) {
        this.messageService.getMessages().subscribe(mess => {
          this.newMessages = mess.filter(m => m.seen === false && m.importanceLevel > 5);
          if (this.newMessages.length > 0) {
            this.showMessage = true;
          }
        })
      }
    })
  }
  cancel() {
    this.nextMessage();
  }
  markAsRead(message: Message) {
    this.messageService.markAsRead(message.id).subscribe(resp => {
      this.nextMessage();
      this.userService.fetchUser().subscribe();
      message.seen = true;
      this.store.dispatch(messagesActions.updateUserMessage({userMessage: message}))
    }, e => {
      this.notificationsService.error("", "Error marking message read.");
      this.showMessage = false;
    })
  }
  nextMessage() {
    this.showMessage = false;
    if(this.currentMessageNumber < this.newMessages.length - 1) {
      this.currentMessageNumber += 1;
      this.showMessage = true;
    }    
  }
}
