// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-package-edit-list {
  font-size: 14px;
}

.feature-chip {
  border-radius: 16px;
  padding: 7px 12px;
  display: inline-flex;
  height: 1px;
  min-height: 32px;
  align-items: center;
  background-color: #8edae6;
  font-size: 12px;
  margin: 2px;
}

.add-new-site-package {
  float: right;
  margin: 5px;
}

.edit-button {
  margin-right: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-site-package/site-package-edit-list/site-package-edit-list.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;EACpB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".site-package-edit-list {\r\n  font-size: 14px;\r\n}\r\n\r\n.feature-chip {\r\n  border-radius: 16px;\r\n  padding: 7px 12px;\r\n  display: inline-flex;\r\n  height: 1px;\r\n  min-height: 32px;\r\n  align-items: center;\r\n  background-color: #8edae6;\r\n  font-size: 12px;\r\n  margin: 2px;\r\n}\r\n\r\n.add-new-site-package {\r\n  float: right;\r\n  margin: 5px;\r\n}\r\n\r\n.edit-button {\r\n  margin-right: 2px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
