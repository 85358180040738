import { Component, OnInit } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { PermissionTemplate } from './permission-template';
import { SitePermissions } from 'app/permissions';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/app.state';
import * as permissionTemplatesActions from '../admin-permissions/store/actions';

import {
  selectPermissionTemplatesGridState,
  selectPermissionTemplatesGridView,
  selectPermissionTemplatesLoading,
  selectShowDeletePermissionTemplateModal,
  selectTemplateHasUsers,
  selectPermissionTemplateSelectedForDelete,
  selectShowPermissionTemplateForm,
  selectPermissionTemplateFormMode,
  selectPermissionTemplateSelectedForEdit,
  selectTemplateName,
  selectTemplateNameDup,
  selectTemplateDescription,
  selectTemplatePermissions,
  selectTemplateSaving
} from '../admin-permissions/store/selectors';

@Component({
  selector: 'admin-permission-templates',
  templateUrl: './admin-permission-templates.component.html',
  styleUrls: ['./admin-permission-templates.component.css']
})
export class AdminPermissionTemplatesComponent implements OnInit {

  public permissionTemplatesGridState$: Observable<State>;
  public permissionTemplatesGridView$: Observable<GridDataResult>;
  public permissionTemplatesLoading$: Observable<boolean>;
  public showDeletePermissionTemplateModal$: Observable<boolean>;
  public templateHasUsers$: Observable<boolean>;
  public permissionTemplateSelectedForDelete$: Observable<PermissionTemplate>;
  public showPermissionTemplateForm$: Observable<boolean>;
  public permissionTemplateFormMode$: Observable<string>;
  public permissionTemplateSelectedForEdit$: Observable<PermissionTemplate>;
  public templateName$: Observable<string>;
  public templateNameDup$: Observable<boolean>;
  public templateDescription$: Observable<string>;
  public templatePermissions$: Observable<SitePermissions>;
  public templateSaving$: Observable<boolean>;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.store.dispatch(permissionTemplatesActions.loadPermissionTemplates());
    this.permissionTemplatesGridState$ = this.store.pipe(select(selectPermissionTemplatesGridState));
    this.permissionTemplatesGridView$ = this.store.pipe(select(selectPermissionTemplatesGridView));
    this.permissionTemplatesLoading$ = this.store.pipe(select(selectPermissionTemplatesLoading));
    this.showDeletePermissionTemplateModal$ = this.store.pipe(select(selectShowDeletePermissionTemplateModal));
    this.templateHasUsers$ = this.store.pipe(select(selectTemplateHasUsers));
    this.permissionTemplateSelectedForDelete$ = this.store.pipe(select(selectPermissionTemplateSelectedForDelete));
    this.showPermissionTemplateForm$ = this.store.pipe(select(selectShowPermissionTemplateForm));
    this.permissionTemplateFormMode$ = this.store.pipe(select(selectPermissionTemplateFormMode));
    this.permissionTemplateSelectedForEdit$ = this.store.pipe(select(selectPermissionTemplateSelectedForEdit));
    this.templateName$ = this.store.pipe(select(selectTemplateName));
    this.templateNameDup$ = this.store.pipe(select(selectTemplateNameDup));
    this.templateDescription$ = this.store.pipe(select(selectTemplateDescription));
    this.templatePermissions$ = this.store.pipe(select(selectTemplatePermissions));
    this.templateSaving$ = this.store.pipe(select(selectTemplateSaving));
  }

  onPermissionTemplatesStateChange(state: State) {
    this.store.dispatch(permissionTemplatesActions.setPermissionTemplatesGridState({ gridState: state }));
  }

  createTemplate() {
    this.store.dispatch(permissionTemplatesActions.setTemplateName({ name: "" }));
    this.store.dispatch(permissionTemplatesActions.setTemplateDescription({ desc: "" }));
    this.store.dispatch(permissionTemplatesActions.setTemplatePermissions({ permissions: {} }));
    this.store.dispatch(permissionTemplatesActions.setShowPermissionTemplateForm({ show: true }));
    this.store.dispatch(permissionTemplatesActions.setPermissionTemplateFormMode({ mode: 'create' }));
  }

  editPermissionTemplate(template: PermissionTemplate) {
    this.store.dispatch(permissionTemplatesActions.setPermissionTemplateSelectedForEdit({ template: template }));
    this.store.dispatch(permissionTemplatesActions.setTemplateName({ name: template.name }));
    this.store.dispatch(permissionTemplatesActions.setTemplateDescription({ desc: template.description }));
    this.store.dispatch(permissionTemplatesActions.setTemplatePermissions({ permissions: template.permissions }));

  }

  deletePermissionTemplate(template: PermissionTemplate) {
    this.store.dispatch(permissionTemplatesActions.checkTemplateForUsers({ template: template }));
  }

  deleteSelectedPermissionTemplate(template: PermissionTemplate) {
    this.store.dispatch(permissionTemplatesActions.deletePermissionTemplate({ id: template.id }))
  }

  cancelDeletePermissionTemplate() {
    this.store.dispatch(permissionTemplatesActions.cancelDeletePermissionTemplate());
  }

  templateNameChange(name: string) {
    this.store.dispatch(permissionTemplatesActions.setTemplateName({ name: name }));
  }

  templateDescriptionChange(desc: string) {
    this.store.dispatch(permissionTemplatesActions.setTemplateDescription({ desc: desc }));
  }

  templatePermissionsChange(permissions: SitePermissions) {
    this.store.dispatch(permissionTemplatesActions.setTemplatePermissions({ permissions: permissions }));
  }

  saveTemplate(mode: string) {
    if (mode === 'create') {
      this.store.dispatch(permissionTemplatesActions.setTemplateSaving({saving: true}));
      this.store.dispatch(permissionTemplatesActions.createPermissionTemplate());
    } else if (mode === 'edit') {
      this.store.dispatch(permissionTemplatesActions.setTemplateSaving({saving: true}));
      this.store.dispatch(permissionTemplatesActions.updatePermissionTemplate());
    } else {
      console.log("permission template save mode not set")
    }

  }

}
