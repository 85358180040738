import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetricsService } from '../metrics.service';
import { SiteService } from '../../site/site.service';
import { ChartComponent } from '@progress/kendo-angular-charts';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { SET_EVENTS_DATE_RANGE } from '../metrics.reducer';
import { Observable } from 'rxjs';
import { DateRange } from '../date-range';
import { take } from 'rxjs/operators';
import { FeatureType } from "app/site-package/models";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  public bubbleData: any;
  public stackData: any;
  public eventsMajorUnit: number = 1;
  public numberOfEO: any = 0;
  public siteID: number;
  public dateRange: DateRange = {start: null, end: null};
  public eventsDateRange: Observable<DateRange>;
  public features: FeatureType[] = [FeatureType.FullRequest];
  
  constructor(
    public metricsService: MetricsService,
    public route: ActivatedRoute,
    public store: Store<AppState>
  ) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.siteID = +params['siteID'];
    })

    this.eventsDateRange = this.store.select(s => {
      let eventsDateRange = Object.assign(new DateRange(), s.eventsDateRange)
      return eventsDateRange;
    })
    
    this.eventsDateRange.pipe(take(1)).subscribe(res => {
      this.dateRange = res;
    });

    if (this.dateRange.start && this.dateRange.end) {
       this.getTouchesMetrics();
    } else {
    let dateOffset = (24*60*60*1000) * 365;
    let startDate = new Date();
    startDate.setTime(startDate.getTime()- dateOffset); 
    this.dateRange.end = new Date();
    this.dateRange.start = startDate;
    this.store.dispatch({ type: SET_EVENTS_DATE_RANGE, payload: this.dateRange });
    this.getTouchesMetrics();
    }
  }


  getTouchesMetrics() {
 
      this.metricsService.getEventTimesByDayHour(this.siteID, this.dateRange.start, this.dateRange.end)
        .subscribe(res => {
          this.bubbleData = res
          this.bubbleData.push({ size: 0.0000001, x: 26, y: 9 })
          this.bubbleData.push({ size: 0.0000001, x: -2, y: 9 })
        });

      this.metricsService.getEventOutcomes(this.siteID, this.dateRange.end).subscribe(res => {
        this.eventsMajorUnit = 1

        let zero = 0
        zero += res['eo'][0]
        zero += res['expFootage'][0]
        zero += res['offCamera'][0]
        zero += res['other'][0]

        //console.log(zero)

        let one = 0
        one += res['eo'][1]
        one += res['expFootage'][1]
        one += res['offCamera'][1]
        one += res['other'][1]

        //console.log(one)

        let two = 0
        two += res['eo'][2]
        two += res['expFootage'][2]
        two += res['offCamera'][2]
        two += res['other'][2]

        //console.log(two)

        if (zero > 9 || one > 9 || two > 9) {
          this.eventsMajorUnit = null
        }

        this.stackData = res

        this.numberOfEO = res['eo'][1]
      });
  return
    }

  labelY(e) {
    //console.log(e)
    switch (e.value) {
      case 1:
        return 'Mon'
      case 2:
        return 'Tue'
      case 3:
        return 'Wed'
      case 4:
        return 'Thu'
      case 5:
        return 'Fri'
      case 6:
        return 'Sat'
      case 7:
        return 'Sun'
      default:
        return ''
    }
  }

  labelX(e) {
    if (e.value === -1 || e.value === 24) {
      return ''
    } else {
      return e.value + 'h'
    }
  }

  startDateChange(start) {
    this.dateRange.start = start;
    if (this.dateRange.start != null && this.dateRange.end != null) {
      this.store.dispatch({ type: SET_EVENTS_DATE_RANGE, payload: this.dateRange })
    }
  }

  endDateChange(end) {
    this.dateRange.end = end;
    if (this.dateRange.start != null && this.dateRange.end != null) {
      this.store.dispatch({ type: SET_EVENTS_DATE_RANGE, payload: this.dateRange })
    }
  }
}
