import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { Store } from "@ngrx/store";

import { AppState } from "../../../app.state";
import { When } from "./when";
import { SET_REQUEST_WHEN } from "../../requests.reducer";
import { FOOTAGE_TOOLTIP } from "./constants";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BsDatepickerConfig, BsDatepickerDirective  } from "ngx-bootstrap/datepicker";

const FOOTAGE_LIFE = 1000 * 60 * 60 * 24 * 14;
const MAX_DURATION = 1000 * 60 * 60 * 72;

@Component({
  selector: "app-request-when",
  templateUrl: "./when.component.html",
  styleUrls: ["./when.component.css"],
})

export class WhenComponent implements OnInit, OnDestroy {
  public when: When = new When();
  public startDate: Date
  public endDate: Date 
  public startTime: Date = new Date();
  public endTime: Date = new Date();
  public evidenceDiscoveredBy: string = "";
  public minStart: Date = new Date(new Date().getTime() - FOOTAGE_LIFE);
  public maxStart: Date = new Date();
  public minEnd: Date = new Date(new Date().getTime() - FOOTAGE_LIFE);
  public maxEnd: Date = new Date();
  public valid: { valid: boolean; errors: { start: string[]; end: string[] } };
  public startTimeError: { message: string };
  public endTimeError: { message: string };
  public nextDisabled: boolean = true;
  public siteID: number;
  readonly FOOTAGE_TOOLTIP = FOOTAGE_TOOLTIP;
  
  colorTheme = 'theme-default';

  bsConfig?: Partial<BsDatepickerConfig>;
  
  private destroy$ = new Subject<void>();

  constructor(
      public store: Store<AppState>, 
      public router: Router, 
      public route: ActivatedRoute,
    ) {}

  public ngOnInit() {

    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });

    let startT = null;
    let endT = null;
    this.startTime = startT;
    this.endTime = endT;


    this.store
      .select((s) => s.request.when)
      .pipe(takeUntil(this.destroy$))
      .subscribe((when) => {
        this.when = when;
        if (when.start) {
          this.startDate = when.start;
          if (!this.startTime) {
            this.startTime = when.start;
          }
          this.fixMaxMinForStart(when.start);
        }
        if (when.end) {
          this.endDate = when.end;
          if (!this.endTime) {
            this.endTime = when.end;
          }
          this.fixMaxMinForEnd(when.end);
        }
        this.evidenceDiscoveredBy = when.evidenceDiscoveredBy;
      });

    this.route.parent.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.siteID = +params["siteID"];
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public endDateChanged(date: Date) {

    if ( !this.endDate && !date)   {
      return;
    }
    if ( this.endDate && date && this.endDate.getTime() == date.getTime()) {
      return;
    }


    this.fixMaxMinForEnd(date);
    if (this.endTime) {
      date.setHours(
        this.endTime.getHours(),
        this.endTime.getMinutes(),
        this.endTime.getSeconds(),
        this.endTime.getMilliseconds(),
      );

      this.when.end = new Date(date.getTime());
    }

    this.endDate = date;
    this.valid = this.when.valid();
    this.updateRequest();
    this.handleValidation();
  }

  public endTimeChanged(time: Date) {
    if (this.endDate && time) {
      let date = new Date(this.endDate.getTime());
      date.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
      this.when.end = new Date(date.getTime());
    }

    this.endTime = time;
    this.valid = this.when.valid();
    this.updateRequest();
    this.handleValidation();
  }

  public startDateChanged(date: Date) {    

    if ( !this.startDate && !date)  {
      return;
    }

    if ( this.startDate && date && this.startDate.getTime() == date.getTime()) {
      return;
    }

    this.fixMaxMinForStart(date);
  
    if (this.startTime) {
      date.setHours(
        this.startTime.getHours(),
        this.startTime.getMinutes(),
        this.startTime.getSeconds(),
        this.startTime.getMilliseconds(),
      );
        this.when.start = new Date(date.getTime());
    }

    this.startDate = date;
    this.valid = this.when.valid();
    this.updateRequest();
    this.handleValidation();
  }

  public startTimeChanged(time: Date) {

    console.log("time check check", time);
    if (this.startDate && time) {
      let date = new Date(this.startDate.getTime());
      date.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
      this.when.start = new Date(date.getTime());
    }

    this.startTime = time;
    this.valid = this.when.valid();
    this.updateRequest();
    this.handleValidation();
  }

  public endTimeValidated(valid: boolean) {
    if (!valid) {
      this.endTimeError = {
        message: "End time invalid.",
       
      };
      this.handleValidation();
      return;
    }

    this.endTimeError = null;
  }

  public startTimeValidated(valid: boolean) {
    if (!valid) {
      this.startTimeError = {
        message: "Start time invalid.",
      };
      this.handleValidation();
      return;
    }

    this.startTimeError = null;
  }

  public reporterChanged() {
    this.when.evidenceDiscoveredBy = this.evidenceDiscoveredBy;
    this.updateRequest();
  }

  public next() {
    this.router.navigate(["../", "where"], { relativeTo: this.route });
  }

  public handleValidation() {
    if (this.valid.valid && !this.startTimeError && !this.endTimeError) {
      this.nextDisabled = false;
      return;
    }

    this.nextDisabled = true;
  }

  private updateRequest() {
    this.store.dispatch({ type: SET_REQUEST_WHEN, payload: this.when });
  }

  private fixMaxMinForStart(date: Date) {
    let max = date.getTime() + MAX_DURATION;
    if (max > new Date().getTime()) {
      max = new Date().getTime();
    }

    let min = date.getTime();
    if (min < new Date().getTime() - FOOTAGE_LIFE) {
      min = new Date().getTime() - FOOTAGE_LIFE;
    }

    this.maxEnd = new Date(max);
    this.minEnd = new Date(min);
  }

  private fixMaxMinForEnd(date: Date) {
    let max = date.getTime();
    if (max > new Date().getTime()) {
      max = new Date().getTime();
    }

    let min = date.getTime() - MAX_DURATION;
    if (min < new Date().getTime() - FOOTAGE_LIFE) {
      min = new Date().getTime() - FOOTAGE_LIFE;
    }

    this.maxStart = new Date(max);
    this.minStart = new Date(min);
  }


  applyTheme(pop: BsDatepickerDirective) {
    setTimeout(() => {
      pop.show();
    });
  }
}
