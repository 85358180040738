import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//import { TextMaskModule } from 'angular2-text-mask';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { MapModule } from '../map/map.module';
import { AddressModule } from '../address/address.module';
import { SharedModule } from '../shared/shared.module';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogModule } from '@progress/kendo-angular-dialog';

import { NewRequestComponent } from './new-request/new-request.component';
import { WhenComponent } from './new-request/when/when.component';
import { ProgressBarComponent} from './new-request/progress-bar/progress-bar.component';
import { WhatComponent } from './new-request/what/what.component';
import { WhereComponent } from './new-request/where/where.component';
import { CamerasComponent } from './new-request/cameras/cameras.component';
import { ContactInfoComponent } from './new-request/contact-info/contact-info.component';
import { ConfirmationComponent } from './new-request/confirmation/confirmation.component';
import { VehicleComponent} from './new-request/where/vehicle/vehicle.component';
import { OtherOfficerComponent } from './new-request/contact-info/other-officer/other-officer.component';
import { RequestService } from './request.service';

import { WhenGuard } from './new-request/when/when-guard.service';
import { WhatGuard } from './new-request/what/what-guard.service';
import { WhereGuard } from './new-request/where/where-guard.service';
import { CamerasGuard } from './new-request/cameras/cameras-guard.service';
import { ContactInfoGuard } from './new-request/contact-info/contact-info-guard.service';
import { ConfirmationGuard } from './new-request/confirmation/confirmation-guard.service';
import { RequestAuthGuard } from './request-auth-guard.service';

import { requestsRouting } from './requests.router';
import { FileUploadComponent } from './new-request/file-upload/file-upload.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
    imports: [
        CommonModule,
        requestsRouting,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        MapModule,
        AddressModule,
        SharedModule,
        DropDownsModule,
        DialogModule,
        TooltipModule,
    ],
    declarations: [
        NewRequestComponent,
        WhenComponent,
        WhatComponent,
        VehicleComponent,
        ProgressBarComponent,
        WhereComponent,
        CamerasComponent,
        ContactInfoComponent,
        OtherOfficerComponent,
        ConfirmationComponent,
        FileUploadComponent,
    ],
    providers: [
        WhenGuard,
        WhatGuard,
        WhereGuard,
        CamerasGuard,
        ContactInfoGuard,
        ConfirmationGuard,
        RequestService,
        RequestAuthGuard,
    ],

})
export class RequestsModule {}