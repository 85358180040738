
import {interval as observableInterval,  Observable, Subscription, of } from 'rxjs';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { trigger, state, style, transition, animate,} from '@angular/animations';
import { Router } from '@angular/router';
import { PermissionsService } from '../permissions.service';
import { FeatureService } from 'app/site-package/services';
import { FeatureType } from 'app/site-package/models';
import { DocTypeService } from 'app/admin/admin-documents/doc-type/doc-type.service';
import { DocType } from 'app/admin/admin-documents/doc-type/doc-type';
import { selectLayouts, selectShowCVSLayouts, selectStandaloneSessions } from 'app/cvs/store/selectors';
import * as CVSActions from 'app/cvs/store/actions';
import { Layout } from 'app/cvs/layout';
import { take, filter } from 'rxjs/operators';
import { Site } from '../site/site';
import { setShowNewLeadForm } from 'app/new-lead/store/actions';
import { AuthFacade } from '../auth/auth.facade';
import { User } from 'app/users/user';
import { SiteListItem } from 'app/sites/site-list-item';
import { BehaviorSubject, Subject } from 'rxjs';
import { FeatureFlagService } from 'app/feature-flag/feature-flag.service';
import { takeUntil } from 'rxjs/operators';



@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
    animations: [
      trigger('flash', [
          state('active', style({ color: '#43aaa3'})),
          transition('active => *', animate('1000ms ease-in-out')),
          transition('* => active', animate('1000ms ease-in-out')),
          state('inactive', style({ color: '#fff'}))

      ]),
        trigger('stretchIn', [
            state('in', style({height: '*'})),
            transition('* => void', [
                style({height: "*"}),
                animate(250, style({height:0}))
            ]),
            transition('void => *', [
                style({height: 0}),
                animate(250, style({height:"*"}))
            ])
        ])
    ]
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input('user') set setUser(value: User) {
    this.user = value;
    this.checkSites();
  };
  @Input('site') set siteSetter(value: Site) {
    this.site = value;
    if(value && value.siteID) {
      this.docTypes$ = this.docTypeService.getTypes(value.siteID);
      this.store.dispatch(CVSActions.loadLayouts({siteID: value.siteID}));
      this.cvsLayouts$ = this.store.pipe(select(selectLayouts));
      this.showCVSLayouts$ = this.store.pipe(select(selectShowCVSLayouts));
    }
    this.checkSites();
  };
  @Input() userNewMessagesCount;
  showEvents$: Observable<boolean> = new BehaviorSubject<boolean>(false);
  showCameras$: Observable<boolean> = new BehaviorSubject<boolean>(false);
  showByos$: Observable<boolean> = new BehaviorSubject<boolean>(false);

  private destroy$ = new Subject<void>();

  
  public user: User;
  public site: Site;
  public sites: SiteListItem[];
  public docTypes$: Observable<DocType[]> = of([]);
  public docTypesLoading$: Observable<boolean>;
  public cvsLayouts$: Observable<Layout[]>;
  public showCVSLayouts$: Observable<boolean>;
  flashing = "inactive"
  flashingAlternatorSub: Subscription;

  FeatureType = FeatureType;
  public defaultItem = { siteName: "Choose a Property", siteID: 0 }

  public expanded = true;
  public showCollapse = false;
  public showExpand = false;
  public showSite: boolean = true;
  public showAdmin: boolean = true;
  public sidebarHidden$: Observable<boolean>;

  constructor(
    public router: Router, 
    public permissions: PermissionsService,
    public features: FeatureService,
    public docTypeService: DocTypeService,
    private store: Store<AppState>,
    public authFacade: AuthFacade,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() { 

    this.showEvents$ = this.featureFlagService.isFeatureEnabled$('multi-site-events').pipe(takeUntil(this.destroy$));
    this.showCameras$ = this.featureFlagService.isFeatureEnabled$('multi-site-cameras').pipe(takeUntil(this.destroy$));
    this.showByos$ = this.featureFlagService.isFeatureEnabled$('byos').pipe(takeUntil(this.destroy$));


    this.flashingAlternatorSub = observableInterval(1000).subscribe(x => {
      this.flashing = (this.flashing === 'inactive') ? 'active' : 'inactive';
    });
    this.docTypesLoading$ = this.docTypeService.getSiteTypesLoading();
    this.sidebarHidden$ = this.store.select(state => state.sidebarHidden);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if(this.flashingAlternatorSub) {
      this.flashingAlternatorSub.unsubscribe();
    }
  }
  checkSites() {
    if(!this.user || !this.user.sites) {
      this.sites = [];
      return;
    }
    this.sites = [...this.user.sites];
    if(!this.site) {
      return;
    }
    let found = false;
    for(let i = 0; i < this.sites.length; i++) {
      if(this.sites[i].siteID === this.site.siteID) {
        found = true;
        break;
      }
    }
    if(!found) {
      this.sites.push(this.site);
    }
  }

  showHideCVSLayout() {
    this.store.dispatch(CVSActions.toggleShowCVSLayouts());
  }

  openStandalone(layoutID: number) {
    this.store.dispatch(CVSActions.generateStandaloneSession({siteID: this.site.siteID, layoutID: layoutID}))
    this.store.pipe(select(selectStandaloneSessions), filter(session => session.includes(this.site.siteID)), take(1)).subscribe(sessions => {
        let hostname = window.location.hostname
        let subDomain = hostname.split('.')[0]
        var newHost: string
        switch (subDomain) {
          case "dev":
            window.open(`http://${hostname}:18109/site/${this.site.siteID}/cvs/${layoutID}/standalone`, "_blank")
            break;
          case "staging":
            newHost = hostname.replace(/^[^.]*/, 'stagingcvs')
            window.open(`http://${newHost}/site/${this.site.siteID}/cvs/${layoutID}/standalone`, "_blank")
            break;
          default:
            newHost = hostname.replace(/^[^.]*/, 'mycvs')
            window.open(`https://${newHost}/site/${this.site.siteID}/cvs/${layoutID}/standalone`, "_blank")
        }
    })
  }

  showNewLeadForm() {
    this.store.dispatch(setShowNewLeadForm({show: true}));
  }

  siteChange(siteID: number) {
    this.router.navigate(['/site', siteID]);
  }

  logout(): void {
    this.authFacade.logout();
  }

  toggleSidebar() {
    this.expanded = !this.expanded;
  }

  toggleSite() {
    this.showSite = !this.showSite;
  }

  toggleAdmin() {
    this.showAdmin = !this.showAdmin;
  }  

  mouseEnter() {
    if (this.expanded) {
      this.showCollapse = true;
    } else {
      this.showExpand = true;
    }

  }

  mouseLeave() {
    if (this.expanded) {
      this.showCollapse = false;
    } else {
      this.showExpand = false;
    }
  }

  itemDisabled(item: {dataItem: SiteListItem}) {
    return item.dataItem.siteID === 0;
  }

}