// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map {
    cursor: crosshair;
}
.clearButton {
    color: #fff;
    background-color: #57889c;
    border-color: #4e7a8c;
    width: 100%;
    margin-bottom: 10px;
}
.form-group {
    margin-top: 15px;
}
.address {
    width: 100%;
    resize: none;
}

.description {
    width: 100%;
    resize: none;
    height: 150px;
}
#vehicleInvolved {
    margin-right: 5px;
}

.btn {
    border-radius: 4px;
    border: 1px solid #ccc;
}

.buttons {
    margin-bottom: 10px;
    color: black;
}

.btn {
    margin: 2px;
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
    font-size: 14px ;
}

.nav-tabs > li.active > a, 
.nav-tabs > li.active > a:focus, 
.nav-tabs > li.active > a:hover 
{
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
    border-radius: 4px 4px 0 0;
}

.nav-tabs > li > a:hover {
    background-color: #E5E4E2;
}`, "",{"version":3,"sources":["webpack://./src/app/requests/new-request/where/where.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,yBAAyB;IACzB,qBAAqB;IACrB,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;;;;IAII,WAAW;IACX,eAAe;IACf,sBAAsB;IACtB,sBAAsB;IACtB,gCAAgC;IAChC,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".map {\r\n    cursor: crosshair;\r\n}\r\n.clearButton {\r\n    color: #fff;\r\n    background-color: #57889c;\r\n    border-color: #4e7a8c;\r\n    width: 100%;\r\n    margin-bottom: 10px;\r\n}\r\n.form-group {\r\n    margin-top: 15px;\r\n}\r\n.address {\r\n    width: 100%;\r\n    resize: none;\r\n}\r\n\r\n.description {\r\n    width: 100%;\r\n    resize: none;\r\n    height: 150px;\r\n}\r\n#vehicleInvolved {\r\n    margin-right: 5px;\r\n}\r\n\r\n.btn {\r\n    border-radius: 4px;\r\n    border: 1px solid #ccc;\r\n}\r\n\r\n.buttons {\r\n    margin-bottom: 10px;\r\n    color: black;\r\n}\r\n\r\n.btn {\r\n    margin: 2px;\r\n}\r\n\r\n.nav > li > a {\r\n    position: relative;\r\n    display: block;\r\n    padding: 10px 15px;\r\n    font-size: 14px ;\r\n}\r\n\r\n.nav-tabs > li.active > a, \r\n.nav-tabs > li.active > a:focus, \r\n.nav-tabs > li.active > a:hover \r\n{\r\n    color: #555;\r\n    cursor: default;\r\n    background-color: #fff;\r\n    border: 1px solid #ddd;\r\n    border-bottom-color: transparent;\r\n    border-radius: 4px 4px 0 0;\r\n}\r\n\r\n.nav-tabs > li > a:hover {\r\n    background-color: #E5E4E2;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
