import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from "./state";
import * as AIActions from "./actions";

const reducer = createReducer(
    initialState,
    on(AIActions.generateEventSynopsis, (state: State) => {
        return {
            ...state,
            eventSynopsisLoading: true,
            showEventSynopsisModal: true,
        }
    }),
    on(AIActions.generateEventSynopsisSuccess, (state: State, { eventSynopsis }) => {
        return {
            ...state,
            eventSynopsisLoading: false,
            eventSynopsisList: [...state.eventSynopsisList, eventSynopsis],
        };
    }),
    on(AIActions.generateEventSynopsisStream, (state: State) => {
        return {
            ...state,
            eventSynopsisLoading: true,
            showEventSynopsisModal: true,
            eventSynopsisRequestId: null,
        }
    }),
    on(AIActions.generateEventSynopsisStreamSuccess, (state, { requestId }) => {
        return {
            ...state,
            eventSynopsisLoading: false,
            eventSynopsisRequestId: requestId,
        };
    }),
    on(AIActions.generateEventSynopsisStreamFailure, (state: State) => {
        return {
            ...state,
            eventSynopsisLoading: false,
            eventSynopsisRequestId: null,
        };
    }),
    on(AIActions.hideEventSynopsisModal, (state: State) => {
        return {
            ...state,
            showEventSynopsisModal: false,
        };
    }),
    on(AIActions.showEventSynopsisModal, (state: State) => {
        return {
            ...state,
            showEventSynopsisModal: true,
        };
    }),
);

export function aiReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
