import { Store } from '@ngrx/store';

import { AppState } from '../app.state';
import { Map } from '../map/map';
import { ADD_MOUSEOVER_EVENT, REMOVE_MOUSEOVER_EVENT } from '../events/event.reducer';
import { Marker, MarkerConfig } from '../markers/marker';
import { MarkerEventService } from '../markers/marker-event.service';


export class NodeMarker extends Marker{
    public type = 'map';
    public node: number;
    constructor(config: NodeMarkerConfig) {
        super(config);
        this.node = config.node;
    }
    clicked() {
        this.eventService.sendEvent({marker: this, payload: this.node, type: "map"})
    }
    getFill() {
        return "rgba(0,255,0,.5)";
    }
    getStroke() {
        return "rgba(0,0,0,0)";
    }
}

export class NodeMarkerConfig extends MarkerConfig{
    public node: number;
}