// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clickable {
    cursor: pointer;
}

.card {
    margin-bottom: 20px;
}

.card-body {
    padding-bottom: 20px;
}

button {
    display: block;
    border: 0px;
    margin: 5px 5px 5px 0px;
    color: white   ;
    font-size: 10px;
    border-radius: 4px;
}

`, "",{"version":3,"sources":["webpack://./src/app/dashboard/dashboard.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,uBAAuB;IACvB,eAAe;IACf,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".clickable {\r\n    cursor: pointer;\r\n}\r\n\r\n.card {\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.card-body {\r\n    padding-bottom: 20px;\r\n}\r\n\r\nbutton {\r\n    display: block;\r\n    border: 0px;\r\n    margin: 5px 5px 5px 0px;\r\n    color: white   ;\r\n    font-size: 10px;\r\n    border-radius: 4px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
