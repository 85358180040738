import { EventMap } from "./event-map";
import { MapData } from "app/map/map";
import { Marker } from "app/markers/marker";
import { EventMarker } from "app/events/event-marker";


export class FilteredEventMap extends EventMap {
    private eventIDs: number[] = [];
    private _allMarkers: Marker[] = [];
    private _eventMarkers: Marker[] = [];

    constructor(mapData: MapData) {
        super(mapData);
    }

    setEventIDs(ids: number[]) {
        this.eventIDs = ids;
        this._eventMarkers = this.filterEventMarkers(this._allMarkers);
        if(this.ctx) {
            this.draw();
        }
    }

    get markersLocal(): Marker[] {
        return this._eventMarkers;
    }

    set markersLocal(markers: Marker[]) {
        this._allMarkers = markers;
        this._eventMarkers = this.filterEventMarkers(this._allMarkers);
    }

    filterEventMarkers(markers: Marker[]): Marker[] {
        if(markers) {
            // if(!this.eventIDs || this.eventIDs.length === 0) {
            //     return markers;
            // }
            return markers.filter(m => {
                if(m.type !== "heatdot" && m.type !== "event") {
                    return false;
                }
                let eM = m as EventMarker;
                if(this.eventIDs.indexOf(eM.event) === -1) {
                    return false;
                }
                return true;
            });
        } else {
            return [];
        }
    }
}