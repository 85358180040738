import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, combineLatest, forkJoin } from 'rxjs';
import { switchMap, takeUntil, tap, map } from 'rxjs/operators';

import { SiteService } from '../site/site.service';
import { UserService } from '../users/user.service';
import { Site } from '../site/site';
import { User } from '../users/user';
import { NotificationsService } from '../notifications/notifications.service';
import { UserLogSummary, UserUpdateLogEntry } from 'app/users/user-log';
import { UserHardType } from 'app/constants';
import { AnalyticsService } from 'app/analytics.service';

type UserWithLog = User & UserLogSummary;

@Component({
    selector: 'app-client-account-management',
    templateUrl: './client-account-management.component.html',
    styleUrls: ['./client-account-management.component.css'],
})
export class ClientAccountManagementComponent implements OnInit, OnDestroy {
    public site: Site;

    public currentUserID: number;
    public userToRemove: User;
    public leAccessLastUpdatedBy: User;
    public leAccessLastUpdatedDate: Date;

    public showRemoveDialog = false;
    public disableRemoveConfirm = false;
    public disableLEAccessToggle = false;
    public isLoading = false;
    public showUpdateLogDialog = false;

    public removeReason: string;

    public clientUsers$: Observable<User[]>;
    public policeUsers$: Observable<User[]>;

    public userUpdateLog$: Observable<UserUpdateLogEntry[]>;
    
    private destroy$: Subject<void>;

    constructor(
        private userService: UserService,
        private siteService: SiteService,
        private router: Router,
        private notificationsService: NotificationsService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.destroy$ = new Subject<void>();

        this.fetchUsers();

        this.userService.getUser().pipe(
            takeUntil(this.destroy$),
        ).subscribe(user => this.currentUserID = user.id);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    fetchUsers(): void {
        this.siteService.getSite().pipe(
            tap(() => this.isLoading = true),
            tap(site => this.site = site),
            switchMap(site =>
                forkJoin({
                    users: this.userService.getUsersBySite(site.siteID),
                    summaries: this.userService.getUserLogSummariesBySite(site.siteID),
                })
            ),
            map(({users, summaries}) => users
                .filter(user => user.typeHard.id !== UserHardType.WTSEmployee)
                .map(user => {
                    const summary = summaries.find(log => log.userID === user.id);
                    const lastActive = summary?.lastActive ? new Date(summary.lastActive) : null;
                    const lastSiteAccess = summary?.lastSiteAccess ? new Date(summary.lastSiteAccess) : null;
                    const lastUpdated = summary?.lastUpdated ? new Date(summary.lastUpdated) : new Date(user.createdDate);
                    return {
                        ...user,
                        lastActive: lastActive,
                        lastSiteAccess: lastSiteAccess,
                        lastUpdated: lastUpdated,
                    } as UserWithLog;
                })
                .sort((a, b) => a.userName.localeCompare(b.userName))),
            tap(() => this.isLoading = false),
            takeUntil(this.destroy$),
        ).subscribe(users => {
            this.clientUsers$ = new BehaviorSubject(users.filter(user => user.typeHard.id === UserHardType.Client));
            this.policeUsers$ = new BehaviorSubject(users.filter(user => user.typeHard.id === UserHardType.Police));
            this.getLastLEAccessAuditLog();
        });
    }

    getLastLEAccessAuditLog(): void {
        this.siteService.getLEAccessAuditLog(this.site.siteID).pipe(
          map(logs => logs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]),
          switchMap(lastLog => {
            return combineLatest([
              this.clientUsers$,
              this.policeUsers$
            ]).pipe(
              map(([clientUsers, policeUsers]) => {
                const allUsers = [...clientUsers, ...policeUsers];
                const lastUpdatedBy = allUsers.find(user => user.id === lastLog.updatedBy);
                return { lastLog, lastUpdatedBy };
              })
            );
          }),
          takeUntil(this.destroy$)
        ).subscribe(({ lastLog, lastUpdatedBy }) => {
          this.leAccessLastUpdatedBy = lastUpdatedBy;
          this.leAccessLastUpdatedDate = new Date(lastLog.date);
        });
      }

    toggleLEAccess(value: boolean): void {
        this.disableLEAccessToggle = true;
        this.isLoading = true;
        this.site.allowLEAccess = value;
        this.siteService.updateSiteLEAccess(this.site.siteID).pipe(
            takeUntil(this.destroy$),
        ).subscribe(site => {
            this.site = site;
            this.disableLEAccessToggle = false;
            this.isLoading = false;
            this.notificationsService.success("", "Law Enforcement access updated successfully");
            this.getLastLEAccessAuditLog();

            this.analyticsService.track("Law Enforcement Access Toggled", {
                siteID: this.site.siteID,
                allowAccess: this.site.allowLEAccess,
                updatedBy: this.currentUserID,
            });
        }, e => {
            this.disableLEAccessToggle = false;
            this.isLoading = false;
            this.notificationsService.error("", "Error updating Law Enforcement access");
        });
    }

    newUser(): void {
        this.router.navigate(['site', this.site.siteID, 'user-management','new']);
    }

    removeUser(user: User): void {
        this.userToRemove = user;
        this.showRemoveDialog = true;
    }

    cancelRemove(): void {
        this.showRemoveDialog = false;        
        this.userToRemove = null;
        this.removeReason = "";
    }

    confirmRemove(): void {
        this.disableRemoveConfirm = true;
        this.userService.clientRemoveUser(
            this.userToRemove.id, 
            this.site.siteID,
            this.removeReason,
        ).pipe(
            takeUntil(this.destroy$),
        ).subscribe(res => {
            this.showRemoveDialog = false;
            this.removeReason = "";
            this.disableRemoveConfirm = false;
            this.notificationsService.success("", "User removed successfully");
            this.fetchUsers();

            this.analyticsService.track("User Removed", {
                userID: this.userToRemove.id,
                siteID: this.site.siteID,
                removedBy: this.currentUserID,
            });

            this.userToRemove = null;
        }, e => {
            this.disableRemoveConfirm = false;
            this.notificationsService.error("", "Error removing user");
        });
    }

    showUpdateLog(userID: number): void {
        this.userUpdateLog$ = this.userService.getUserUpdateLog(this.site.siteID, userID).pipe(
            map(log => {
                return log.map(entry => {
                    return {
                        ...entry,
                        createdDate: new Date(entry.createdDate),
                    };
                });
            }),
            takeUntil(this.destroy$),
        );

        this.showUpdateLogDialog = true;
    }

    closeUpdateLog(): void {
        this.showUpdateLogDialog = false;
    }
}
