import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, interval, merge, Observable, Subscription } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private inactiveSeconds: number = 0;
  private intervalSubscription: Subscription;
  private idleSeconds$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private events$: Observable<Event>;
  private alive: boolean = true;
  constructor() { 
    this.intervalSubscription = interval(1000).subscribe(() => {
      this.inactiveSeconds++;
      this.idleSeconds$.next(this.inactiveSeconds);
    });
    const moveEvent = fromEvent(document, 'mousemove');
    const keydownEvent = fromEvent(document, 'keydown');
    const clickEvent = fromEvent(document, 'click');
    const scrollEvent = fromEvent(document, 'scroll');
    const resizeEvent = fromEvent(window, 'resize');
    const focusEvent = fromEvent(window, 'focus');

    this.events$ = merge(moveEvent, keydownEvent, clickEvent, scrollEvent, resizeEvent, focusEvent);
    this.events$.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.inactiveSeconds = 0;
      this.idleSeconds$.next(0);
      if (this.intervalSubscription) {
        this.intervalSubscription.unsubscribe();
        this.intervalSubscription = interval(1000).subscribe(() => {
          this.inactiveSeconds++;
          this.idleSeconds$.next(this.inactiveSeconds);
        });
      }
    });
  }
  ngOnDestroy(): void {
    this.intervalSubscription.unsubscribe();
    this.alive = false;
  }

  getInactive(seconds: number): Observable<boolean> {
    return this.idleSeconds$.pipe(map(i => i >= seconds));
  }
}
