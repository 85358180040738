import { createFeature, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export const authFeatureKey = 'auth';

export interface State {
  isAuthenticated: boolean;
  isLoading: boolean;
  sessionCreated: boolean;
  error: any | null;
}

export const initialState: State = {
  isAuthenticated: false,
  isLoading: false,
  sessionCreated: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.login, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AuthActions.loginSuccess, (state, { authResult }) => ({
    ...state,
    isAuthenticated: true,
    isLoading: false,
    error: null,
  })),
  on(AuthActions.loginFailure, (state, { error }) => ({
    ...state,
    isAuthenticated: false,
    isLoading: false,
    sessionCreated: false,
    error,
  })),
  on(AuthActions.sessionCreated, (state) => ({
    ...state,
    sessionCreated: true,
    error: null,
  })),
  on(AuthActions.logout, (state) => ({
    ...initialState,
  })),
  on(AuthActions.updateAuthCookieFailure, (state, { error }) => ({
    ...state,
    isAuthenticated: false,
    isLoading: false,
    sessionCreated: false,
    error,
  }))

);

export const authFeature = createFeature({
  name: authFeatureKey,
  reducer,
});