import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from './state';
import * as CVSActions from './actions';

const reducer = createReducer(
    initialState,
    on(CVSActions.setLayouts, (state: State, {layouts}) => {
        return {
            ...state,
            layouts: layouts,
            showAutoGenerateDialog: false,
        }
    }),

    on(CVSActions.setCVSSiteID, (state: State, {siteID}) => {
        return {
            ...state,
            cvsSiteID: siteID,
        }
    }),

    on(CVSActions.resetCVS, (state: State) => {
        return {
            ...state,
            ...initialState,
        }
    }),

    on(CVSActions.setSelectedLayout, (state: State, {layout}) => {
        if (state.layoutHasChanges) {
            return {
                ...state,
                layoutForAfterConfirm: layout,
                showUnsavedChangesDialog: true,
            }
        }
        return {
            ...state,
            selectedLayout: layout,
        }
    }),

    on(CVSActions.setGridSizes, (state: State, {gridSizes}) => {
        return {
            ...state,
            gridSizes: gridSizes,
        }
    }),

    on(CVSActions.appendAddedLayout, (state: State, {layout}) => {
        return {
            ...state,
            layouts: [ ...state.layouts, layout],
            selectedLayout: layout,
            layoutHasChanges: false,
        }
    }),

    on(CVSActions.replaceLayout, (state: State, {layout}) => {
        return {
            ...state,
            layouts: state.layouts.map(l => l.id === layout.id ? layout : l),
            selectedLayout: layout,
            layoutHasChanges: false,
        }
    }),

    on(CVSActions.removeLayout, (state: State, {id}) => {
        return {
            ...state,
            layouts: state.layouts.filter(layout => layout.id != id),
            selectedLayout: null,
            layoutHasChanges: false,
            showDeleteLayoutDialog: false,
        }
    }),

    on(CVSActions.setCameras, (state: State, {cameras}) => {
        return {
            ...state,
            cameras: cameras,
        }
    }),

    on(CVSActions.storeLayoutChanges, (state: State, {layout}) => {
        return {
            ...state,
            selectedLayout: layout,
        }
    }),

    on(CVSActions.setLayoutHasChanges, (state: State, {changes}) => {
        return {
            ...state,
            layoutHasChanges: changes,
        }
    }),

    on(CVSActions.setShowUnsavedChangesDialog, (state: State, {show}) => {
        return {
            ...state,
            showUnsavedChangesDialog: show,
        }
    }),

    on(CVSActions.confirmUnsavedChanges, (state: State) => {
        return {
            ...state,
            selectedLayout: state.layoutForAfterConfirm,
            showUnsavedChangesDialog: false,
            layoutHasChanges: false,
            layoutForAfterConfirm: null,
        }
    }),


    on(CVSActions.toggleShowCVSLayouts, (state: State) => {
        return {
            ...state,
            showCVSLayouts: !state.showCVSLayouts,
        }
    }),

    on(CVSActions.setShowDeleteLayoutDialog, (state: State, {show}) => {
        return {
            ...state,
            showDeleteLayoutDialog: show,
        }
    }),

    on(CVSActions.setShowAutoGenerateDialog, (state: State, {show}) => {
        return {
            ...state,
            showAutoGenerateDialog: show,
        }
    }),

    on(CVSActions.addStandaloneSession, (state: State, {siteID}) => {
        return {
            ...state,
            standaloneSessions: [ ...state.standaloneSessions, siteID]
        }
    })

)


export function cvsReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}