import { createAction, props } from "@ngrx/store";
import { EventSynopsis } from "../event-synopsis/event-synopsis";

export const noAction = createAction("[AI] No Action");

export const generateEventSynopsis = createAction("[AI] Generate Event Synopsis", props<{ detailLevel: string, descriptionSelector: string, categoryIDs: number[] }>());

export const generateEventSynopsisSuccess = createAction("[AI] Generate Event Synopsis Success", props<{ eventSynopsis: EventSynopsis }>());

export const generateEventSynopsisFailure = createAction("[AI] Generate Event Synopsis Failure");

export const generateEventSynopsisStream = createAction("[AI] Generate Event Synopsis Stream", props<{ detailLevel: string, descriptionSelector: string, categoryIDs: number[] }>());

export const generateEventSynopsisStreamSuccess = createAction("[AI] Generate Event Synopsis Stream Success", props<{ requestId: string }>());

export const generateEventSynopsisStreamFailure = createAction("[AI] Generate Event Synopsis Stream Failure");

export const showEventSynopsisModal = createAction("[AI] Show Event Synopsis Modal");

export const hideEventSynopsisModal = createAction("[AI] Hide Event Synopsis Modal");
