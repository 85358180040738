// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.truncate-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

.bucketEnabled {
  color: #fff; 
  padding: 5px 22px; 
  border-radius: 4px;
}

.bucketEnabled-1 {
  color: #fff; 
  padding: 5px; 
  border-radius: 4px;
}

.responsive-image,
  picture {
  width: 100%;
  height: auto;
  display: block;
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/extended-storage/portfolio-extended-storage/portfolio-extended-storage.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;EACzB;;AAEF;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,eAAe;AACjB","sourcesContent":[".truncate-text {\r\n    overflow: hidden;\r\n    white-space: nowrap;\r\n    text-overflow: ellipsis;\r\n  }\r\n\r\n.bucketEnabled {\r\n  color: #fff; \r\n  padding: 5px 22px; \r\n  border-radius: 4px;\r\n}\r\n\r\n.bucketEnabled-1 {\r\n  color: #fff; \r\n  padding: 5px; \r\n  border-radius: 4px;\r\n}\r\n\r\n.responsive-image,\r\n  picture {\r\n  width: 100%;\r\n  height: auto;\r\n  display: block;\r\n  max-width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
