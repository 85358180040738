import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromAdminSiteManagementState from './state';
import { process } from '@progress/kendo-data-query';

export const siteManagement = createFeatureSelector<fromAdminSiteManagementState.State>(fromAdminSiteManagementState.adminSiteManagementKey);

export const siteListGridData = createSelector(
    siteManagement,
    (siteManagement => siteManagement.siteList),
);

export const siteListGridState = createSelector(
    siteManagement,
    (siteManagement => siteManagement.siteListGridState),
);

export const siteListGridView = createSelector(
    siteListGridData,
    siteListGridState,
    ((data, state) => process(data, state)),
);