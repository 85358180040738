import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { UserService } from '../users/user.service';
import { SiteService } from '../site/site.service';
import { PermissionsService } from '../permissions.service';

export const ExtendedStorageGuard: CanActivateFn = (): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> => {
    const userService = inject(UserService);
    const siteService = inject(SiteService);
    const permissions = inject(PermissionsService);
    const router = inject(Router);

    return userService.getUser().pipe(map(user => {
        if (user.typeHard.id === 2) {
            router.navigate(['dashboard']);
            return false;
        }
        return true;
    }), first());
}