import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { Lookup } from '../../lookup';
import { menuIcon, filterIcon, SVGIcon, minHeightIcon, searchIcon } from "@progress/kendo-svg-icons";
import { CameraGridSize } from '../camera-grid-size';
import { ResizedEvent } from '../../angular-resize.directive';


@Component({
  selector: 'app-camera-filter',
  templateUrl: './camera-filter.component.html',
  styleUrls: ['./camera-filter.component.css']
})

export class CameraFilterComponent implements OnInit {

  @Input() sites: Lookup[];
  @Input() cameraIntents: Lookup[];
  @Input() sortOptions: string[];
  @Input() groupByOptions: string[];
  @Input() hasLiveFeature: boolean = false;
  @Input() hasSiteWalkFeature: boolean = false;
  @Input() isLive: boolean = false;
  @Input('siteFilter') set setSiteFilter(value: number[]) {
    if (value) {
      this.siteFilter = value;
    }
  }

  @Input('intentFilter') set setIntentFilter(value: number[]) {
    if (value) {
      this.intentFilter = value;
    }
  }

  @Input('nameFilter') set setNameFilter(value: string) {
    this.nameFilter = value;
  }

  @Input('selectedSortOption') set setSelectedSortOption(value: string) {
    if (value) {
      this.selectedSortOption = value;
    }
  }

  @Input('selectedGroupByOption') set setSelectedGroupByOption(value: string) {
    if (value) {
      this.selectedGroupByOption = value;
    }
  }

  @Input('cameraGridSize') set setCameraGridSize(value: CameraGridSize) {
    this.cameraGridSize = value;
  }

  @Input('appBarWidth') set setAppBarWidth(value: number) {
    this.appBarWidth = value;
  }

  @Output() siteFilterChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() intentFilterChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() nameFilterChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() clearFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortOptionChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() groupByOptionChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() camFooterToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() cameraGridSizeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() appBarWidthChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() live: EventEmitter<any> = new EventEmitter<any>();
  @Output() walk: EventEmitter<any> = new EventEmitter<any>();


  public siteFilter: number[] = [];
  public intentFilter: number[] = [];
  public nameFilter: string = "";
  public selectedSortOption: string = "";
  public selectedGroupByOption: string = "";
  public cameraGridSize: CameraGridSize = { small: false, medium: false, large: false};

  public menuSVG: SVGIcon = menuIcon;
  public filterSVG: SVGIcon = filterIcon;
  public minHeightSVG: SVGIcon = minHeightIcon;
  public searchSVG: SVGIcon = searchIcon;


  public appBarWidth: number = 0;
  public collapseWidth: number = 1530;
  

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  
  constructor() { }

  ngOnInit(): void {
  }

  onSiteFilterChange() {
    this.siteFilterChange.emit(this.siteFilter);
  }

  onIntentFilterChange() {
    this.intentFilterChange.emit(this.intentFilter);
  }

  onNameFilterChange() {
    this.nameFilterChange.emit(this.nameFilter);
  }

  onClearFilters() {
    this.clearFilters.emit();
  }

  onSortOptionChange() {
    this.sortOptionChange.emit(this.selectedSortOption);
  }

  onCamFooterToggle() {
    this.camFooterToggle.emit();
  }
  
  onClose(e) {
    if (e.item.contentTemplate) {
      e.preventDefault();
    }
  }

  onFiltersResized(event: ResizedEvent) {
    this.appBarWidthChange.emit(Math.round(event.newRect.width));
  }
  
  onLive() {
    this.live.emit();
  }

  onWalk() {
    this.walk.emit();
  }

  onCameraSizeButtonChange(event: string) {
    this.cameraGridSizeChange.emit(event);
  }

}
