import { Pipe, PipeTransform } from '@angular/core';

import { Video } from '../video';

@Pipe({name: 'dayFilter'})
export class DayFilterPipe implements PipeTransform {
    transform(value: Video[], day: Date): Video[] {
        let testDate = new Date(day);
        testDate.setUTCHours(0,0,0,0);
        let newVids = value.filter(video => {
            let d2 = new Date(video.startTime);
            d2.setUTCHours(0,0,0,0);
            return d2.getTime() === testDate.getTime()
        })
        return newVids
    }
}