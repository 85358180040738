import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MetricsService } from '../metrics.service';
import { ShortRequest } from './short-request';
import { SiteService } from 'app/site/site.service';
import { takeWhile, filter } from 'rxjs/operators';
import { FilteredEventMap } from 'app/event-map/filtered-event-map';
import { HistoricalData } from './historical-data';
import { MarkerService } from 'app/markers/marker.service';
import { HistoricalService } from './historical.service';
import { Subscription, Observable } from 'rxjs';
import { MarkerEventService } from 'app/markers/marker-event.service';
import { Site } from 'app/site/site';
import { Router } from '@angular/router';
import { PermissionsService } from 'app/permissions.service';

@Component({
  selector: 'app-historical',
  templateUrl: './historical.component.html',
  styleUrls: ['./historical.component.css']
})
export class HistoricalComponent implements OnInit, OnDestroy {

  alive: boolean = true;
  historical: HistoricalData = new HistoricalData();
  saved: HistoricalData[] = [];
  clicksSub: Subscription;
  siteSub: Subscription;
  site: Site;
  loading: Observable<boolean>;
  constructor(
    private historicalService: HistoricalService,
    private cdRef: ChangeDetectorRef,
    private markerEventService: MarkerEventService,
    private siteService: SiteService,
    private router: Router,
    private permissionsService: PermissionsService,
  ) { }

  ngOnInit() {
    this.historical = this.historicalService.getHistorical();
    this.saved = this.historicalService.saved;
    this.clicksSub = this.markerEventService.getEvents().pipe(filter(event => event.type === 'event')).subscribe(event => {
      if(this.permissionsService.site(this.site.siteID, 'canViewTickets')) {
          this.router.navigate(['site', this.site.siteID, "events", event.payload]);
      }
    });

    this.siteSub = this.siteService.getSite().subscribe(site => {
      this.site = site;
    });

    this.loading = this.historicalService.getLoading();
  }

  ngOnDestroy() {
    if(this.siteSub) {
      this.siteSub.unsubscribe();
    }

    if(this.clicksSub) {
      this.clicksSub.unsubscribe();
    }
  }

  startDateChange(start: Date) {
    this.historical.setStart(start);
  }

  endDateChange(end: Date) {
    this.historical.setEnd(end);
  }

  save() {
    this.historicalService.save();
    this.saved = this.historicalService.saved;    
  }

  clear() {
    this.historicalService.clear();
    this.saved = this.historicalService.saved;    
  }

  sort() {
    this.historicalService.sort();
    this.saved = this.historicalService.saved;    
  }

  remove(hist: HistoricalData) {
    this.historicalService.remove(hist);
    this.saved = this.historicalService.saved;
  }

  generateYears() {
    this.historicalService.generateYears();
    this.saved = this.historicalService.saved;
    this.cdRef.detectChanges();
    this.saved.forEach(s => s.maps.forEach(m => m.draw()));
  }

  generateQuarters() {
    this.historicalService.generateQuarters();
    this.saved = this.historicalService.saved;
    this.cdRef.detectChanges();
    this.saved.forEach(s => s.maps.forEach(m => m.draw()));
  }
}
