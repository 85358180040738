import { createAction, props } from "@ngrx/store";
import { AddCamsMarker } from "../add-cams-marker";
import { Camera } from "app/camera/camera";



export const clickCamera = createAction("[Rewind-Add-Cams] Click Camera", props<{marker: AddCamsMarker}>());

export const addCamera = createAction("[Rewind-Add-Cams] Add Camera", props<{marker: AddCamsMarker}>());

export const removeCamera = createAction("[Rewind-Add-Cams] Remove Camera", props<{marker: AddCamsMarker}>());

export const resetCameras = createAction("[Rewind-Add-Cams] Reset Cameras");

export const tooManyCamsClicked = createAction("[Rewind-Add-Cams] Too Many Cam Clicked");

export const setPrimaryCamera = createAction("[Rewind-Add-Cams] Set Primary Camera", props<{marker: AddCamsMarker}>());

export const resetPrimaryCamera = createAction("[Rewind-Add-Cams] Reset Primary Camera");

export const loadCamera = createAction("[Rewind-Add-Cams] Load Camera", props<{cameraID: number}>());

export const addCameraData = createAction("[Rewind-Add-Cams] Add Camera Data", props<{camera: Camera}>());

export const cameraAlreadyLoaded = createAction("[Rewind-Add-Cams] Camera Already Loaded");

export const maxCameraModalOpen = createAction("[Rewind-Add-Cams] Max Camera Modal Open");

export const maxCameraModalClose = createAction("[Rewind-Add-Cams] Max Camera Modal Close");