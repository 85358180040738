export class DocumentFile {
    id: number;
    filePath: string;
    name: string;
    displayName: string;
    typeID: number;
    type: string;
    uuid: string;
    size: number;
    siteID: number;
    messageID: number;
    startDisplayDate: Date;
    endDisplayDate: Date;
    createdByAppID: number;
    createdByID: number;
    createdDate: Date;
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config);
            if(config.createdDate) {
                this.createdDate = new Date(config.createdDate);
            }
            if(config.startDisplayDate) {
                this.startDisplayDate = new Date(config.startDisplayDate);
            }
            if(config.endDisplayDate) {
                this.endDisplayDate = new Date(config.endDisplayDate);
            }
        }
    }
}
