import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MotionFile } from "../motion-data";
import { MotionDateTime } from '../motion-datetime-modal/motion-datetime';
import { dateFromTimeObj } from "../../time-obj";
@Component({
  selector: 'app-motion-files',
  templateUrl: './motion-files.component.html',
  styleUrls: ['./motion-files.component.css']
})
export class MotionFilesComponent implements OnInit {

  @Input() set files(val: MotionFile[]) {
    this.motionFiles = val;
    if (typeof this.motionFiles !== 'undefined' &&
      typeof this.camIDToNames !== 'undefined' &&
      typeof this.camIDToDisplayNames !== 'undefined' &&
      typeof this.selectedHours !== 'undefined' &&
      typeof this.selectedTime !== 'undefined' &&
      typeof this.selectedCameras !== 'undefined' &&
      typeof this.motionLoaded !== 'undefined') {
      this.processFiles();
    }
  };
  @Input() camIDToNames: { [id: number]: string };
  @Input() camIDToDisplayNames: { [id: number]: string };
  @Input() selectedHours: number;
  @Input() selectedTime: MotionDateTime;
  @Input() selectedCameras: number[];
  @Input() motionLoaded: boolean;
  @Input() motionViewed: {[imgUrl: string]: boolean};

  @Output() fileClicked: EventEmitter<MotionFile> = new EventEmitter();

  public motionFiles: MotionFile[] = [];
  public motionCameras: number[] = [];
  public cameraNames: string[] = [];
  public cameraDisplayNames: string[] = [];
  public cameraFileMap = {};
  public timeFileMap = {};
  public selectedTab: string = "cameras";
  public timePeriods: { from: Date, to: Date }[] = [];
  public haveFilesToDisplay: boolean = false;

  constructor() { }

  ngOnInit() {
    if (typeof this.motionFiles !== 'undefined' &&
      typeof this.camIDToNames !== 'undefined' &&
      typeof this.selectedHours !== 'undefined' &&
      typeof this.selectedTime !== 'undefined' &&
      typeof this.selectedCameras !== 'undefined' &&
      typeof this.motionLoaded !== 'undefined' &&
      typeof this.camIDToDisplayNames !== 'undefined') {
      this.processFiles();
    }
  }

  onTabSelect(tab: string) {
    this.selectedTab = tab;
    if (this.motionFiles.length > 0 && typeof this.selectedHours !== 'undefined' && typeof this.selectedHours !== 'undefined') {
      this.processFiles();
    }
  }

  processFiles() {
    if (this.motionFiles.length > 1) {
      this.haveFilesToDisplay = true;
    } else {
      this.haveFilesToDisplay = false;
    }
    this.getSelectedCameraNames();
    this.getSelectedCameraDisplayNames();
    this.makeFileMapByCameraName();
    this.getTimePeriods();
    this.makeFileMapByTimePeriod();
  }

  getSelectedCameraNames() {
    this.cameraNames = [];
    for (let i = 0; i < this.selectedCameras.length; i++) {
      this.cameraNames[i] = this.camIDToNames[this.selectedCameras[i]];
    }
  }

  getSelectedCameraDisplayNames() {
    this.cameraDisplayNames = [];
    for (let i = 0; i < this.selectedCameras.length; i++) {
      this.cameraDisplayNames[i] = this.camIDToDisplayNames[this.selectedCameras[i]];
    }
  }

  makeFileMapByCameraName() {
    this.cameraFileMap = {};
    for (let i = 0; i < this.cameraNames.length; i++) {
      let categoryItems = this.getFilesByCameraName(this.cameraNames[i]);
      if (categoryItems.length > 0) {
        this.cameraFileMap[this.cameraNames[i]] = categoryItems;
      } else {
        this.cameraFileMap[this.cameraNames[i]] = [];
      }
    }
  }

  getFilesByCameraName(cameraName: string) {
    let filteredFiles = this.motionFiles.filter(item => {
      return this.camIDToNames[item.cameraID] == cameraName;
    });
    return filteredFiles;
  }

  makeFileMapByTimePeriod() {
    this.timeFileMap = {};
    for (let i = 0; i < this.timePeriods.length; i++) {
      let categoryItems = this.getFilesByTimePeriod(i);
      categoryItems.sort((a,b)=>dateFromTimeObj(a.time).getTime() - dateFromTimeObj(b.time).getTime() )
      if (categoryItems.length > 0) {
        this.timeFileMap[i] = categoryItems;
      } else {
        this.timeFileMap[i] = [];
      }
    }
  }

  getFilesByTimePeriod(index: number) {
    let filteredFiles = this.motionFiles.filter(item => {
      let fileTime = new Date(dateFromTimeObj(item.time))
      return (fileTime > this.timePeriods[index].from && fileTime < this.timePeriods[index].to);
    });
    return filteredFiles;
  }

  getTimePeriods() {
    this.timePeriods = [];
    for (let i = 0; i < this.selectedHours; i++) {
      let from = new Date(dateFromTimeObj(this.selectedTime.motionStartDate));
      from.setHours(from.getHours() + i);
      let to = new Date(dateFromTimeObj(this.selectedTime.motionStartDate));
      to.setHours(to.getHours() + (i + 1));
      let timePeriod = { from: from, to: to };
      this.timePeriods.push(timePeriod);
    }


    return;
  }

  clickMotion(file: MotionFile) {
    this.fileClicked.emit(file);
  }

}
