import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { DomSanitizer } from '@angular/platform-browser';
import { AppState } from '../app.state';
import { config } from '../app.config';
import { AccessKeyService } from '../users/access-key.service';
import { Site } from '../site/site';
import { ServiceHelpersService } from '../service-helpers.service';
import { Camera } from './camera';
import { Video } from './video';
import { Request as FormRequest } from '../requests/request';
import { Request } from 'app/events/request';
import { catchError, map } from 'rxjs/operators';
import { CameraAuth } from './camera-auth';
import { CameraLite } from './camera-lite';
import { Lookup } from 'app/lookup';

@Injectable()
export class CameraService {
    public headers = {};

    constructor(
        public http: HttpClient, 
        public store: Store<AppState>, 
        public accessKeyService: AccessKeyService, 
        public serviceHelpersService: ServiceHelpersService, 
        public sanitizer: DomSanitizer,
    ) {
        this.accessKeyService.getKey().subscribe(key => {
            this.headers = Object.assign(this.headers, {"accessKey": key});
        });
    }

    getCamerasForSite(id: number): Observable<Camera[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Camera[]>(config.apiUrl + "site/" + id + "/cameras", {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
    }
    getCamerasLite(id: number): Observable<CameraLite[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<CameraLite[]>(config.apiUrl + "site/" + id + "/cameraslite", {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
    }

    getCamera(cameraID: number, siteID: number): Observable<Camera> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Camera>(config.apiUrl + "site/" + siteID + "/camera/" + cameraID, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
    }

    getVideos(cameraID, siteID, daysRewind): Observable<Video[]> {
       let headers = new HttpHeaders(this.headers);
       return this.http.get<Video[]>(config.apiUrl + "site/" + siteID + "/rewind/" + cameraID + "/daysrewind/" + daysRewind, {headers: headers}).pipe(map(videos => {
             videos.forEach(video => {
                video.startTime = new Date(video.startTime);
                video.fileLocation = this.sanitizer.bypassSecurityTrustUrl(video.fileLocation) as string
            });
            
            return videos
        }), catchError(this.serviceHelpersService.handleError));
    }

    expressRequest(request: FormRequest): Observable<Request> {
        let headers = new HttpHeaders(Object.assign({}, this.headers, { "Content-Type": 'application/json'}));
        let backendRequest: any = Object.assign({}, request);
        backendRequest.cameras = { cameras: request.cameras.cameras.map(camera => {
            return {id: camera.id}
        })};
        let body = JSON.stringify(backendRequest);
        return this.http.post<Request>(config.apiUrl + "request", body, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError), map(r => new Request(r)));
    }

    uploadImage( eventID: number, img: string) {
        let fd = new FormData();
        fd.append("picture", this.dataURItoBlob(img), 'rewindImage.jpg')
        let headers = new HttpHeaders(this.headers);
        return this.http.post(config.apiUrl + "event/" + eventID + "/images", fd, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
    }

    dataURItoBlob(dataURI: string): Blob {
        let byteString: string;
        let mimestring;
    
        if(dataURI.split(',')[0].indexOf('base64') !== -1 ) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = decodeURI(dataURI.split(',')[1]);
        }
    
        mimestring = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        var content = new Array();
        for (var i = 0; i < byteString.length; i++) {
            content[i] = byteString.charCodeAt(i);
        }
    
        return new Blob([new Uint8Array(content)], {type: mimestring});
    }
    getCameraStreamAuth(cameraID: number, siteID: number): Observable<CameraAuth> {
        let headers = new HttpHeaders(this.headers);
        //return this.http.get<CameraAuth>(`${config.apiUrl}site/${siteID}/camera/${cameraID}/auth`, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
        return this.http.get<CameraAuth>(`/api/cameraauth/${cameraID}`, {headers}).pipe(catchError(this.serviceHelpersService.handleError));
    }

    listCameraStreamAuth(cameraIDs: number[], siteID: number): Observable<CameraAuth[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.post<CameraAuth[]>(`${config.apiUrl}site/${siteID}/cameras/auth`, cameraIDs, { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError), map(authList => {
            if(authList && authList.map) {
                return authList.map(l => new CameraAuth(l));
            } else {
                return authList;
            }
        }));
    }

    getPortfolioCameras(): Observable<CameraLite[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<CameraLite[]>(config.apiUrl  + "portfolio/cameras", {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
    }
    getPortfolioCameraStreams(): Observable<Camera[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Camera[]>(config.apiUrl + "portfolio/camera/streams", {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
    }

    getCameraIntents(): Observable<Lookup[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Lookup[]>(config.apiUrl + "portfolio/camera/intents", {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
    }
}
