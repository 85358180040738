import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IssueCollectorService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadIssueCollector() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = environment.issueCollectorServiceUrl;
    script.async = true;

    this.renderer.appendChild(document.body, script);
  }
}