// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browser-warning {
    color: #cc1818;
    padding-bottom: 5px;
    text-align: center;
}

.scrollUp {
    position: absolute;
    top: 85vh;
    right: 30px;
}

.material-icons-round.scroll-up {
    cursor: pointer;
    color: #43aaa3;
    font-size: 48px;
}

`, "",{"version":3,"sources":["webpack://./src/app/camera/camera.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,eAAe;IACf,cAAc;IACd,eAAe;AACnB","sourcesContent":[".browser-warning {\r\n    color: #cc1818;\r\n    padding-bottom: 5px;\r\n    text-align: center;\r\n}\r\n\r\n.scrollUp {\r\n    position: absolute;\r\n    top: 85vh;\r\n    right: 30px;\r\n}\r\n\r\n.material-icons-round.scroll-up {\r\n    cursor: pointer;\r\n    color: #43aaa3;\r\n    font-size: 48px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
