
import {map} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import { Map, MapData } from '../../../map/map';
import { Marker } from '../../../markers/marker';
import { REQUEST_ADD_WHERE_POINT, REQUEST_CLEAR_MAP } from '../../requests.reducer';
import { Point } from './point';

export class WhereMap extends Map {
    public dots = [];
    public drawing = false;
    public store: Store<AppState>;
    public canDraw = true;
    constructor(mapData: MapData, store: Store<AppState>) {
        super(mapData);
        this.store = store;
    }
    drawMarker(marker: Marker) {
        if(marker.type === 'camera') {
            marker.draw();
        }
    }
    draw() {
        super.draw();
        this.store.select(s => s.request.where.mapData).pipe(map(data => {
            if(data) {
                return data.filter(datum => datum.mapID === this.id);
            }
            return data;
        })).subscribe(data => {
            if(data) {
                if(data.length > 0) {
                    data.forEach(datum => {
                        let points = [];
                        datum.points.forEach((point, index) => {
                            if(!point.start) {
                                points.push(point)
                                // this.drawDot(point.x, point.y, datum.points[index -1].x, datum.points[index -1].y)
                            } else {
                                this.drawLine(points);
                                points = [point];
                                this.drawDot(point.x, point.y);
                            }
                            
                        });
                        this.drawLine(points);
                    });
                }
            }
        }).unsubscribe();
    }
    drawDot(x,y, oldx?, oldy?) {
        if(oldx && oldy) {
            this.ctx.lineWidth = 3;
            let oldStroke = this.ctx.strokeStyle;            
            this.ctx.strokeStyle = "red";
            this.ctx.beginPath();            
            this.ctx.moveTo(oldx, oldy);
            this.ctx.lineTo(x, y);
            this.ctx.stroke();
            this.ctx.strokeStyle = oldStroke;
            this.ctx.lineWidth = 1;
            return;
        }
        else {
            let oldfill = this.ctx.fillStyle;
            let oldStroke = this.ctx.strokeStyle;
            this.ctx.strokeStyle = "red";            
            this.ctx.lineWidth = 3;
            this.ctx.fillStyle = "red";
            this.ctx.beginPath();
            this.ctx.arc(x, y, 5, 0, 2 * Math.PI)
            this.ctx.fill();
            this.ctx.strokeStyle = oldStroke;
            this.ctx.fillStyle = oldfill;
            this.ctx.lineWidth = 1;
        }
    }
    drawLine(points: Point[]) {
        if(points.length < 1) {
            return;
        }
        if(points.length === 1) {
            this.drawDot(points[0].x, points[0].y);
            return;
        }
        this.ctx.lineWidth = 10;
        let oldStroke = this.ctx.strokeStyle;
        this.ctx.strokeStyle = "red";
        this.ctx.beginPath();
        this.ctx.moveTo(points[0].x, points[0].y);
        points.forEach(point => {
            this.ctx.lineTo(point.x, point.y);
        });
        this.ctx.stroke();
        this.ctx.strokeStyle = oldStroke;
        this.ctx.lineWidth = 1;
    }
    mouseMove(event) {
        let xy = this.getXY(event);
        if(this.drawing) {
            let x = xy.x;
            let y = xy.y;
            let xmod =  ( this.canvas.width / this.canvas.clientWidth);
            let ymod = ( this.canvas.height / this.canvas.clientHeight);
            x = x * xmod;
            y = y * ymod;
            this.store.dispatch({type: REQUEST_ADD_WHERE_POINT, payload: {mapID: this.id, point: new Point(x, y)}})
            this.draw();
        }
    }
    mouseDown(event) {
        let xy = this.getXY(event);
        if(this.canDraw) {
            let x = xy.x;
            let y = xy.y;
            let xmod =  ( this.canvas.width / this.canvas.clientWidth);
            let ymod = ( this.canvas.height / this.canvas.clientHeight);
            x = x * xmod;
            y = y * ymod;
            this.store.dispatch({type: REQUEST_ADD_WHERE_POINT, payload: {mapID: this.id, point: new Point(x, y, true)}})
            this.drawing = true;
        }
        this.draw();
    }
    mouseUp(event) {
        this.drawing = false;
    }
    mouseLeave(event) {
        this.drawing = false;
    }
    clear() {
        this.store.dispatch({type: REQUEST_CLEAR_MAP, payload: this.id});
    }
    mouseClick(event) {

    }
}