import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from "./state";
import * as ExtendedStorageActions from './actions';
import { ExtendedStorageComponent } from "../extended-storage.component";
import { ByosSort } from "../byos-sort";
import { showConfigureStorage, sites } from "./selectors";
import { config } from "rxjs";

const reducer = createReducer(
    initialState,


on(ExtendedStorageActions.getBYOSitesSuccess, (state: State, {sites}) => {
    return {
        ...state,
        sites: sites,
        filteredSites: sites,
    }
}),

on(ExtendedStorageActions.setTextFilter, (state: State, {textFilter}) => {
    return {
        ...state,
        textFilter: textFilter,
    }
}),

on(ExtendedStorageActions.setBYOSGridState, (state: State, {gridState}) => {
    let byosSort: ByosSort = {field: "", dir: ""};
    if (gridState.sort.length > 0){
        byosSort = { dir: gridState.sort[0].dir, field: gridState.sort[0].field };
    }

    return {
        ...state,
        byosGridState: gridState,
        byosSort: byosSort,
    }
}),

on(ExtendedStorageActions.setBYOSUnlicensedGridState, (state: State, {gridState}) => {
    return {
        ...state,
        byosUnlicensedGridState: gridState,
    }
}),

on(ExtendedStorageActions.searchByos, (state: State) => {
    let gridState = {...state.byosGridState};
    gridState.skip = 0;
    return {
        ...state,
        byosGridState: gridState,
    }
}),


on(ExtendedStorageActions.configureSiteStorage, (state: State, {siteStorage}) => {
    return {
        ...state,
        showConfigureStorage: true,
        siteIDBeingConfigured: siteStorage.siteID,
        
    }
}),

on(ExtendedStorageActions.setShowConfigureStorage, (state: State, {show}) => {
    return {
        ...state,
        showConfigureStorage: show,
    }
}),


on(ExtendedStorageActions.changeStorage, (state: State, {siteStorage}) => {
   
    return {
        ...state,
        siteIDBeingConfigured: siteStorage.siteID,
        showConfigureStorage: false,
        showChangeStorageConfirmDialog: false,
        showChangeStorage: true,

    }
}),

on(ExtendedStorageActions.showChangeStorage, (state: State, {show}) => {

    let configModal =  true;
    const selectedSite = state.sites.find( site => site.id === state.siteIDBeingConfigured);

    if( selectedSite.bucketID === null || selectedSite.bucketID === undefined || selectedSite.bucketID === 0) {
      configModal = false;
    } 
   
    return {
        ...state,
        showChangeStorage: show,
        showConfigureStorage: configModal,
    }
}),


on(ExtendedStorageActions.unassignStorageSuccess, (state: State) => {
    return {
        ...state,
        showUnassignStorageDialog: false,
        showConfigureStorage: false,
        siteIDBeingConfigured: null,
    }
}),

on(ExtendedStorageActions.setSelectedEnabledOption, (state: State, {option}) => {
    return {
        ...state,
        selectedEnabledOption: option,
    }
}),

on(ExtendedStorageActions.clearFilters, (state: State) => {
    return {
        ...state,        
        selectedEnabledOption: "All",
        textFilter: "",
    }
}),


on(ExtendedStorageActions.getVendorsSuccess, (state: State, {vendors}) => {
    return {
        ...state,
        vendors: vendors,
        }
}),

on(ExtendedStorageActions.getBucketsSuccess, (state: State, {buckets}) => {
    return {
        ...state,
        buckets: buckets,
        }
}),


on(ExtendedStorageActions.addStorageAccountSuccess, (state: State, {account} ) => {
    return {
        ...state,
        showChangeStorage: false,
        }
}),

on (ExtendedStorageActions.updateBYOSSiteBucketSuccess, (state: State, {bucket}) => {
    let siteID = state.siteIDBeingConfigured;

    let newSites = [...state.sites]
    let siteIndex = newSites.findIndex(s => s.id === siteID);
    let site = {...newSites[siteIndex]};
    site.bucketID = bucket.id; 
    site.byosBucket = {...bucket};
    newSites[siteIndex] = site;

    return {
        ...state,
        showConfigureStorage: true,
        showChangeStorage: false,
        sites: newSites,
    }
}),

on(ExtendedStorageActions.getExtendedStorageForm, (state: State, {siteID}) => {

    let newSites = [...state.sites]
    let siteIndex = newSites.findIndex(s => s.id === siteID);
    let site = newSites[siteIndex];
  
    let showConfigureStorage = false;   
    let showChangeStorage = false;
    

    if(site === undefined || site.bucketID === 0 || site.bucketID === null || site.bucketID === undefined) {
        
        showChangeStorage = true;
        showConfigureStorage = false;
    }
    
    else {
        
        showChangeStorage = false;
        showConfigureStorage = true;
    }

    return {
        ...state,
        siteIDBeingConfigured: siteID,  
        showConfigureStorage: showConfigureStorage,
        showChangeStorage: showChangeStorage,     
    }
}),


on(ExtendedStorageActions.setUnlicensedSiteSelections, (state: State, {siteSelections}) => {
    return {
        ...state,
        unlicensedSiteSelections: siteSelections,
    }
}),

on(ExtendedStorageActions.setShowUnassignStorageDialog, (state: State, {show}) => {
    return {
        ...state,
        showUnassignStorageDialog: show,
    }
}),

on(ExtendedStorageActions.setShowChangeStorageConfirmDialog, (state: State, {show}) => {
    return {
        ...state,
        showChangeStorageConfirmDialog: show,
    }
}),

on(ExtendedStorageActions.showInquiryConfirmation, (state: State, {show} ) => {
    return {
        ...state,
        showInquiryConfirmation: show,
    }
}),

on(ExtendedStorageActions.submitInquirySuccess, (state: State, {sites}) => {
    return {
        ...state,
        showInquiryConfirmation: false,
        unlicensedSiteSelections: [],
    }
}),

);

export function extendedStorageReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}