import { SafeHtml } from "@angular/platform-browser";
import { TimeObj,dateFromTimeObj } from "app/time-obj";

export class Summary {
    createdBy: {id: number, name: string};
    id: number;
    reviewedDate: Date;
    text: string | SafeHtml;
    uploadedFiles: SummaryFile[];
    autoRequestFiles: AutoRequestFile[];
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config);
            if(config.reviewedDate) {
                this.reviewedDate = new Date(config.reviewedDate);
            }
            if (config.autoRequestFiles) {
                 this.autoRequestFiles = config.autoRequestFiles.map(file => new AutoRequestFile(file));
            }
         }
    }
}

export class SummaryFile {
    fileName: string;
    id: number;
    summaryID: number;
    type: number;
    url: string;
}

export class AutoRequestFile {
    id: number;
    eventID: number;
	siteID: number;
    name: string;
    path: string;
    uuid: string;
	cameraID: number;
	cameraNumber: number;
	cameraName: string;
	startDate: Date;
	endDate: Date;
	lengthMins: number;
	size: number;
	dataSourceID: number;
    timelineItemIDs: number[];
    isPurged: boolean;
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config);
            if (config.startDate) {
             let tStart = new Date(dateFromTimeObj(config.startDate));
            this.startDate = tStart;
            }
            if (config.endDate) {
            let tEnd = new Date(dateFromTimeObj(config.endDate));
            this.endDate = tEnd;
            }
        }
    }
}