// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h4, h5{
    text-align: center;
    font-weight: semi-bold;
}

h4 {
    margin-top: 0px;
}

.centered { 
    display: flex;
    justify-content: center;
    align-items: center;
}

.bucketEnabled{
    border-radius: 10px;
    text-align: center;
    padding: 2px 14px;
    margin-bottom: 8px;
    color: white;
}

.form-group {
    display: flex;
}
  
.form-group .k-form {
    margin-right: 10px;
}
  
k-textbox {
    float: right;
}

.form-group-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
  
.mgmt-data {
    border: 1px solid #0000009f; 
    border-radius: 4px; 
    padding: 10px;

}`, "",{"version":3,"sources":["webpack://./src/app/extended-storage/extended-storage-mgmt/extended-storage-mgmt.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,aAAa;;AAEjB","sourcesContent":["h4, h5{\r\n    text-align: center;\r\n    font-weight: semi-bold;\r\n}\r\n\r\nh4 {\r\n    margin-top: 0px;\r\n}\r\n\r\n.centered { \r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.bucketEnabled{\r\n    border-radius: 10px;\r\n    text-align: center;\r\n    padding: 2px 14px;\r\n    margin-bottom: 8px;\r\n    color: white;\r\n}\r\n\r\n.form-group {\r\n    display: flex;\r\n}\r\n  \r\n.form-group .k-form {\r\n    margin-right: 10px;\r\n}\r\n  \r\nk-textbox {\r\n    float: right;\r\n}\r\n\r\n.form-group-button {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    margin-top: 10px;\r\n}\r\n  \r\n.mgmt-data {\r\n    border: 1px solid #0000009f; \r\n    border-radius: 4px; \r\n    padding: 10px;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
