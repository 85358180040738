import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { RawKey } from './store/state';
import { rawRequestReducer } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { RawRequestEffects } from './store/effects';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(RawKey, rawRequestReducer),
    EffectsModule.forFeature([RawRequestEffects]),
  ],
  exports: [
  ]
})
export class RawRequestModule { }
