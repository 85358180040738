import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import SitePackage, { PackageFeature } from "../../../site-package/models/site-package";
import Feature from "../../../site-package/models/feature";

@Component({
  selector: "app-site-package-edit-form",
  templateUrl: "./site-package-edit-form.component.html",
  styleUrls: ["./site-package-edit-form.component.css"],
})
export class SitePackageEditFormComponent implements OnInit, OnDestroy {
  @Output() public formValueChange: EventEmitter<SitePackage> = new EventEmitter<SitePackage>();

  @Input() public sitePackage: SitePackage;

  @Input() public allFeatures: Feature[] = [];

  public sitePackageEditForm: UntypedFormGroup;
  public availableFeatures: Feature[] = new Array<Feature>();
  public assignedFeatures: Feature[] = new Array<Feature>();

  private destroy$ = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder) {}

  public ngOnInit() {
    this.loadForm();
    this.sitePackageEditForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((sitePackage: SitePackage) => {
      if (!sitePackage.features) {
        sitePackage.features = new Array();
      }

      this.formValueChange.emit({
        ...this.sitePackage,
        name: sitePackage.name,
        features: [...sitePackage.features],
      });
    });

    this.updateFeatureAssignment();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public unassignFeature(featureToUnassign: Feature) {
    const indexOfFeatureToRemove = this.assignedFeatures.findIndex((feature) => {
      return featureToUnassign.id == feature.id;
    });

    this.assignedFeatures.splice(indexOfFeatureToRemove, 1);
    this.availableFeatures.push(featureToUnassign);
    this.patchFormFeatures();
  }

  public assignFeature(featureToAssign: Feature) {
    const indexOfFeatureToRemove = this.availableFeatures.findIndex((feature) => {
      return featureToAssign.id == feature.id;
    });

    this.availableFeatures.splice(indexOfFeatureToRemove, 1);
    this.assignedFeatures.push(featureToAssign);
    this.patchFormFeatures();
  }

  private loadForm() {
    if (!this.sitePackage) {
      this.sitePackage = {name: '', id: 0, features: []};
    }
    if (!this.sitePackage.features) {
      this.sitePackage.features = new Array();
    }

    this.sitePackageEditForm = this.formBuilder.group({
      ...this.sitePackage,
      name: [this.sitePackage.name, Validators.required],
      features: [...this.mapFeatures(this.assignedFeatures)],
    });
  }

  private updateFeatureAssignment() {
    const assignedFeatureIDs = this.sitePackage.features.map((feature) => feature.featureID);

    this.availableFeatures = this.filterFeatures(this.allFeatures, assignedFeatureIDs);
    this.assignedFeatures = [
      ...this.sitePackage.features.map((feature) => {
        return {
          id: feature.featureID,
          name: feature.name,
        };
      }),
    ];

    this.patchFormFeatures();
  }

  private filterFeatures(featuresToFilter: Feature[], filterWithIDs: number[]): Feature[] {
    return featuresToFilter.filter((feature) => filterWithIDs.indexOf(feature.id) === -1);
  }

  private patchFormFeatures() {
    this.sitePackageEditForm.patchValue({
      features: [...this.mapFeatures(this.assignedFeatures)],
    });
  }

  private mapFeatures(features: Feature[]): PackageFeature[] {
    return features.map((feature) => {
      return <PackageFeature>{
        name: feature.name,
        featureID: feature.id,
        packageID: this.sitePackage.id,
      };
    });
  }
}
