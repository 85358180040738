import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { State } from './store/auth.reducer';
import * as authActions from './store/auth.actions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Auth0Error, Auth0Result } from 'auth0-js';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Auth0Adapter } from './auth0-adapter.service';
import { ServiceHelpersService } from '../service-helpers.service';
import { config } from '../app.config';
import { User } from '../users/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private jwtHelper = new JwtHelperService(); 

  constructor(private auth0Adapter: Auth0Adapter, public http: HttpClient, public serviceHelpersService: ServiceHelpersService, private store: Store<State>) {}

  showLogin(): void {
    this.auth0Adapter.showLock();
  }

  hideLogin(): void {
    this.auth0Adapter.hideLock();
  }

  refreshAuth(): void {
    this.auth0Adapter.checkSession();
  }

  logout(): void {
    localStorage.removeItem('access_token');
    this.auth0Adapter.logout({
      returnTo: window.location.origin + '/logout',
    });
  }

  authenticated$(): Observable<Auth0Result | null> {
    return this.auth0Adapter.authenticated$.pipe(
      tap((authResult: Auth0Result | null) => {
        if (authResult?.accessToken) {
          localStorage.setItem('access_token', authResult.accessToken);
          this.store.dispatch(authActions.updateAuthCookie({ accessToken: authResult.accessToken})); 
        }
      })
    );
  }

  authorizationError$(): Observable<Auth0Error | null> {
    return this.auth0Adapter.authorizationError$;
  }

  isTokenExpired(): boolean {
    const token = localStorage.getItem('access_token');
    return token ? this.jwtHelper.isTokenExpired(token) : true;
  }

  createSession(token: string): Observable<User> {
    let body = JSON.stringify({ accessToken: token});
    let headers = new HttpHeaders(Object.assign({ 'Content-Type': 'application/json'}, {} ));
    return this.http.post<User>(config.apiUrl + 'user/login', body, { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError))
  }

  updateAuthCookie(token: string): Observable<User> {
    let body = JSON.stringify({ accessToken: token});
    let headers = new HttpHeaders(Object.assign({ 'Content-Type': 'application/json'}, {} ));
    return this.http.put<User>(config.apiUrl + 'user/cookie', body, { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError))
  }


}