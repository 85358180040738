// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selector {
    vertical-align: top;
    margin: 10px;
    flex-grow: 1;
    text-align: center;
}

label {
    display: inline-block;
}

.form {
    display: flex;
    flex-wrap: wrap;
}

.form-multi {
    min-width: 200px;
}

.search-button {
    font-size: 1.5em;
    background-color: #43aaa3;
    margin-right: 50px;
}
.button-cont {
    width: 100%;
    text-align: right;
}

label span {
    display: inline-block;
    vertical-align: top;
    width: 150px;
    text-align: right;
    margin-right: 5px;
    font-weight: bold;
}

.wrapper {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    width: calc(100% - 20px);
    box-sizing: border-box;
    min-width: 300px;
    
}

h3 {
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-user-selector/admin-user-selector.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,wBAAwB;IACxB,sBAAsB;IACtB,gBAAgB;;AAEpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".selector {\r\n    vertical-align: top;\r\n    margin: 10px;\r\n    flex-grow: 1;\r\n    text-align: center;\r\n}\r\n\r\nlabel {\r\n    display: inline-block;\r\n}\r\n\r\n.form {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.form-multi {\r\n    min-width: 200px;\r\n}\r\n\r\n.search-button {\r\n    font-size: 1.5em;\r\n    background-color: #43aaa3;\r\n    margin-right: 50px;\r\n}\r\n.button-cont {\r\n    width: 100%;\r\n    text-align: right;\r\n}\r\n\r\nlabel span {\r\n    display: inline-block;\r\n    vertical-align: top;\r\n    width: 150px;\r\n    text-align: right;\r\n    margin-right: 5px;\r\n    font-weight: bold;\r\n}\r\n\r\n.wrapper {\r\n    border-color: black;\r\n    border-style: solid;\r\n    border-width: 1px;\r\n    border-radius: 5px;\r\n    margin: 10px;\r\n    padding: 10px;\r\n    width: calc(100% - 20px);\r\n    box-sizing: border-box;\r\n    min-width: 300px;\r\n    \r\n}\r\n\r\nh3 {\r\n    margin-top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
