export enum UserSoftType {
    PropertyManager = 1,
    AssistantManager = 4,
    RegionalManager = 5,
    SecurityGuard = 6,
    Other = 7,
    Police = 8,
}

export enum UserHardType {
    WTSEmployee = 1,
    Police = 2,
    Client = 3,
}

export const PRODUCT_RETENTION_LENGTH = 365 //days

export const REVIEW_PHONE_NUMBER =  "(314) 427-4586"