import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, withLatestFrom, concatMap, catchError } from 'rxjs/operators';
import { Store, select } from "@ngrx/store";
import { AppState } from "app/app.state";
import { MessageService } from '../message.service';
import { of } from "rxjs";
import { selectUserMessages } from "./selectors";
import * as messagesActions from './actions';
import { NotificationsService } from '../../notifications/notifications.service';

@Injectable()
export class messagesEffects {

    loadUserMessages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(messagesActions.loadUserMessages),
            switchMap(() => {
                return this.messagesService.getMessages().pipe(
                    map(data => {
                        return messagesActions.setUserMessages({userMessages: data});
                    }),
                    catchError(() => {
                        console.error("Error loading user messages");
                        return of(messagesActions.noAction());
                    }),
                )
            })
        )
    })

    updateUserMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(messagesActions.updateUserMessage),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectUserMessages))),
                )
            }),
            map(([action, userMessages]) => {
                let index = userMessages.findIndex(m => m.id === action.userMessage.id) 
                userMessages.splice(index,1,action.userMessage);
                return messagesActions.setUserMessages({userMessages: userMessages});
             })
        )
    })

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private messagesService: MessageService,         
        private notificationsService: NotificationsService,
    ) {}
}