import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {
  @Input() messages: string[];
  @Input() show: boolean;
  @Input() title: string;

  @Output("confirm") confirmEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("cancel") cancelEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onClose() {
    this.onCancel();
  }
  onCancel() {
    this.cancelEmitter.emit();
  }
  onOK() {
    this.confirmEmitter.emit();
  }
}
