
import {map, takeUntil} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { process, State, AggregateResult, aggregateBy } from '@progress/kendo-data-query';
import { GridComponent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { AppState } from '../../app.state';
import { User } from '../../users/user';
import { SiteListService } from '../../sites/site-list.service';
import { UserService } from '../../users/user.service';
import { 
    SET_ACC_MGMT_USERS,
    SET_ACC_MGMT_DELETED_USERS,
    UPDATE_ACC_MGMT_USER,
    ACC_MGMT_FILTERS,
    ACC_MGMT_FILTER_ALL,
    ACC_MGMT_FILTER_SITE,
    ACC_MGMT_FILTER_WATCHTOWER,
    SET_ACC_MGMT_SITE,
    RESEND_ACC_MGMT_USERS,
} from './account-management.reducer';
import { SiteListItem } from '../../sites/site-list-item';
import { NotificationsService } from '../../notifications/notifications.service';
import { AdminService } from '../admin.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { SelectEvent } from "@progress/kendo-angular-layout";
import { AnalyticsService } from 'app/analytics.service';

@Component({
    selector: 'app-account-management',
    templateUrl: './account-management.component.html',
    styleUrls: ['./account-management.component.css'],
})
export class AccountManagementComponent implements OnInit, OnDestroy{
    public sites = [];
    public users :User[] = [];
    public deletedUsers: User[] = [];
    public selectedSite: SiteListItem;
    public filters: string[] = ['All', 'Site', 'Watchtower'];
    public filter: string;
    public filterSub;
    public siteSub;
    public sitesSub;
    public disabledFilter: string = "enabled";
    public globalFilter: string = "";
    public state: State = {
        skip: 0,
        take: 50,
    }
    private usersSub: any;
    private deletedUsersSub: any;
    public showDelete: boolean = false;
    public deletingUser: User;
    public userToUndelete: User;
    public disableUndelete: boolean = false;
    public showUndeleteConfirmDialog: boolean = false;
    public gridData: GridDataResult = process(this.users, this.state)
    public showPassword: boolean = false;
    public userPassword: string = "";
    public newPassword: string = "";
    public passwordUser: User;
    public passwordValid: boolean = false;
    public saveInProgress: boolean = false;
    public currentUser: User;

    private destroy$ = new Subject<void>();

    constructor(public siteListService: SiteListService, public userService: UserService, public router: Router, public route: ActivatedRoute, public store: Store<AppState>, public notificationsService: NotificationsService, private adminService: AdminService, private analyticsService: AnalyticsService) {}

    ngOnInit() {
        this.sitesSub = this.siteListService.getSiteList().pipe(map(sites => sites.map(site => this.selectedSite && this.selectedSite.siteID === site.siteID ? this.selectedSite : site))).subscribe(sites => this.sites = sites);
        this.userService.getUsers().subscribe(users => {
            this.store.dispatch({type: SET_ACC_MGMT_USERS, payload: users});
        })
        this.userService.getDeletedUsers().subscribe(users => {
            this.store.dispatch({type: SET_ACC_MGMT_DELETED_USERS, payload: users});
        })
        this.usersSub = this.store.select(s => s.accMgmtUsers).pipe(map(users => {
            switch(this.filter) {
                case ACC_MGMT_FILTERS.ALL:
                    return users;
                case ACC_MGMT_FILTERS.SITE:
                    return users.filter(user => {
                        if(!this.selectedSite || !user.sites) {
                            return false;
                        }
                        return user.sites.reduce((prev, next) => {
                            return next.siteID === this.selectedSite.siteID || prev;
                        }, false);
                    })
                case ACC_MGMT_FILTERS.WATCHTOWER:
                    return users.filter(user => user.typeHard.id === 1);
                default:
                    return users;
            }
        }),map(users => {
            return users.filter(user => {
                return this.applyGlobalFilter(user);
            })
        }),).subscribe(users => {
            this.users = users
            this.disabledFilterChange(this.disabledFilter);
            this.updateGrid();
        });
        this.filterSub = this.store.select(s => s.accMgmtFilter).subscribe(filter => {
            console.log("FILTER: " + filter);
            this.filter = filter;
            this.store.dispatch({type: RESEND_ACC_MGMT_USERS});
        });

        this.deletedUsersSub = this.store.select(s => s.accMgmtDeletedUsers).subscribe(users =>{
            this.deletedUsers = users;
        })

        this.siteSub = this.store.select(s => s.accMgmtSite).subscribe(site =>{
            console.log("SITE: " + site.siteName);
            this.sites.forEach(otherSite => {
                if(otherSite.siteID === site.siteID) {
                    this.selectedSite = otherSite;
                    return;
                }
            })
            this.store.dispatch({type: RESEND_ACC_MGMT_USERS});
        })

        this.userService.getUser().pipe(
            takeUntil(this.destroy$),
        ).subscribe(user => this.currentUser = user);

        this.updateGrid();
    }
    updateGrid() {
        this.gridData = process(this.users, this.state)
    }
    ngOnDestroy() {
        this.filterSub.unsubscribe();
        this.siteSub.unsubscribe();
        this.sitesSub.unsubscribe();
        this.usersSub.unsubscribe();
        this.deletedUsersSub.unsubscribe();

        this.destroy$.next();
        this.destroy$.complete();
    }
    selectFilter(filter) {
        switch(filter) {
            case 'Watchtower':
                this.store.dispatch({type: ACC_MGMT_FILTER_WATCHTOWER});
                break;
            case 'All':
                this.store.dispatch({type: ACC_MGMT_FILTER_ALL});
                break;
            case 'Site':
                this.store.dispatch({type: ACC_MGMT_FILTER_SITE});
                break;
        }

    }
    globalFilterChange() {
        this.state.skip = 0;
        this.store.dispatch({type: RESEND_ACC_MGMT_USERS});
    }
    applyGlobalFilter(user: User): boolean {
        if(this.globalFilter === "") {
            return true;
        }
        let filter = this.globalFilter.toLowerCase();
        if(user.userName && user.userName.toLowerCase().indexOf(filter) !== -1) {
            return true;
        }
        if(user.firstName && user.firstName.toLowerCase().indexOf(filter) !== -1) {
            return true;
        }
        if(user.lastName && user.lastName.toLowerCase().indexOf(filter) !== -1) {
            return true;
        }
        if(user.email && user.email.toLowerCase().indexOf(filter) !== -1) {
            return true;
        }
        if(user.typeHard && user.typeHard.name && user.typeHard.name.toLowerCase().indexOf(filter) !== -1) {
            return true;
        }
        if(user.typeFriendly && user.typeFriendly.name && user.typeFriendly.name.toLowerCase().indexOf(filter) !== -1) {
            return true;
        }
        if(user.phoneNumber && (user.phoneNumber + "").indexOf(filter) !== -1) {
            return true;
        }
        if(user.cellNumber && (user.cellNumber + "").indexOf(filter) !== -1) {
            return true;
        }
        let siteString = "";
        if(user.sites && user.sites.length > 0) {
            user.sites.forEach(site => siteString += site.siteName);
            if(siteString.toLowerCase().indexOf(filter) !== -1) {
                return true;
            }
        }
        return false;
    }
    disabledFilterChange(newFilter: string): void {
        const root = this.state.filter || { logic: "and", filters: []};
        const [filter] = flatten(root).filter(x => x.field === "active");
        if(!filter) {
            root.filters.push({
                field: "active",
                operator: (x, v) => {
                    switch (v) {
                        case "all":
                            return true;
                        case "enabled":
                            return (x ? true : false);
                        case "disabled":
                            return (x ? false : true);
                        default:
                            return true;
                    }
                },
                value: newFilter,
            });
        } else {
            filter.value = newFilter;
        }

        this.state.filter = root;
        this.updateGrid()
        return;
    }
    dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.updateGrid();
    }
    select(selectedSite) {
        this.store.dispatch({type: SET_ACC_MGMT_SITE, payload: selectedSite})
    }
    edit(user) {
        this.router.navigate(['admin', 'users', 'edit', user.id]);
    }
    updateUser(user) {
        this.userService.updateUser(user).subscribe(user => this.store.dispatch({ type: UPDATE_ACC_MGMT_USER, payload: user }));
    }
    toggleUser(user) {
        this.userService.updateUser({id: user.id, active: !user.active}).subscribe(user => this.store.dispatch({ type: UPDATE_ACC_MGMT_USER, payload: user}), error => {
            this.notificationsService.error("", "Error updating user");
        } );
    }
    showDeleteUser(user: User) {
        this.deletingUser = user;
        this.showDelete = true;
    }
    cancelDelete() {
        this.showDelete = false;
        this.deletingUser = null;
    }
    confirmDelete() {
        this.adminService.deleteUser(this.deletingUser.id).subscribe(r => {
            let us = this.users.filter(u => u.id !== this.deletingUser.id);
            this.store.dispatch({type: SET_ACC_MGMT_USERS, payload: us});
            this.store.dispatch({type: SET_ACC_MGMT_DELETED_USERS, payload: [...this.deletedUsers, this.deletingUser]});
            this.showDelete = false;

            this.analyticsService.track("User Deleted Admin", {
                userID: this.deletingUser.id,
                deletedBy: this.currentUser.id,
            });

            this.deletingUser = null;
        }, e => {
            this.notificationsService.error("", "Error deleting User: " + this.deletingUser.getName());
            this.showDelete = false;
            this.deletingUser = null;
        })
    }
    showPasswordForm(user: User) { 
        this.passwordUser = user;
        this.showPassword = true;
    }
    cancelPassword() {
        this.newPassword = '';
        this.showPassword = false;
        this.passwordUser = null;
    }

    onNewPasswordChange(pwd: string) { 
        this.newPassword = pwd;
    }

    onValidationChange(valid: boolean) {
        this.passwordValid = valid;
    }

    savePassword() {
      this.saveInProgress = true; 
      let payload = {password: this.newPassword};
        this.adminService.updateUserPassword(this.passwordUser.id, payload).subscribe(r => {
            this.newPassword = '';

            this.analyticsService.track("User Password Updated Admin", {
                userID: this.passwordUser.id,
                updatedBy: this.currentUser.id,
            });

            this.passwordUser = null;
            this.showPassword = false;
            this.saveInProgress = false;
        }, e => {
            this.notificationsService.error("", "Error changing password: " + this.passwordUser.getName());
            this.newPassword = '';
            this.passwordUser = null;
            this.showPassword = false;
            this.saveInProgress = false;
        })
    }

    onTabSelect(e: SelectEvent) {
        console.log("Select tab event: ", e);
    }

    confirmUndeleteUser(user: User) {
        this.userToUndelete = user;
        this.showUndeleteConfirmDialog = true;    
    }

    cancelUndeleteUser() {
        this.showUndeleteConfirmDialog = false;
        this.userToUndelete = null;
      }

    undeleteUser() {
        this.disableUndelete = true;
        this.adminService.undeleteUser(this.userToUndelete.id).subscribe(user => {
            let deletedUsers = this.deletedUsers.filter(u => u.id !== user.id);
            this.store.dispatch({type: SET_ACC_MGMT_DELETED_USERS, payload: deletedUsers});
            this.store.dispatch({type: SET_ACC_MGMT_USERS, payload: [...this.users, user]});
            this.notificationsService.success("", "successfully undeleted user");
            this.showUndeleteConfirmDialog = false;

            this.analyticsService.track("User Undeleted Admin", {
                userID: this.userToUndelete.id,
                undeletedBy: this.currentUser.id,
            });

            this.userToUndelete = null;
            this.disableUndelete = false;
        }, e => {
            this.notificationsService.error("", "Error undeleting user");
            this.showUndeleteConfirmDialog = false;
            this.userToUndelete = null;
            this.disableUndelete = false;
        });
    }

public  allData = (): ExcelExportData => {
    let users = this.users;
    const result: ExcelExportData = {
      data: process(users, {group: this.state.group, sort: this.state.sort, filter: this.state.filter}).data,
      group: this.state.group,
    }
    return result;
  }

}

const flatten = filter => {
    const filters = filter.filters;
    if (filters) {
        return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
    }
    return [];
};
