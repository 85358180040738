// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description {
    width: 100%;
    resize: none;
    height: 150px;
}

.vehicle {
    margin-bottom: 10px;
}

.clickable {
    cursor: pointer;
}

.otherOfficer {
    margin-bottom: 10px;
}

.address {
    margin-top: 15px;
}

.buttons {
    margin-bottom: 10px;  
    background-color: #fff;
    color: black;
}

.btn {
    border-radius: 4px;
    border: 1px solid #ccc;
}

.disabled {
    cursor: not-allowed;
    text-decoration: none;
    color: black;
}
  
.btn:disabled {
    cursor: not-allowed;
    pointer-events: all;
}

h4 {
    font-weight: normal;
    margin: 10px 0;
}

label {
    font-weight: bold;
  }`, "",{"version":3,"sources":["webpack://./src/app/requests/new-request/confirmation/confirmation.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,iBAAiB;EACnB","sourcesContent":[".description {\r\n    width: 100%;\r\n    resize: none;\r\n    height: 150px;\r\n}\r\n\r\n.vehicle {\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.clickable {\r\n    cursor: pointer;\r\n}\r\n\r\n.otherOfficer {\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.address {\r\n    margin-top: 15px;\r\n}\r\n\r\n.buttons {\r\n    margin-bottom: 10px;  \r\n    background-color: #fff;\r\n    color: black;\r\n}\r\n\r\n.btn {\r\n    border-radius: 4px;\r\n    border: 1px solid #ccc;\r\n}\r\n\r\n.disabled {\r\n    cursor: not-allowed;\r\n    text-decoration: none;\r\n    color: black;\r\n}\r\n  \r\n.btn:disabled {\r\n    cursor: not-allowed;\r\n    pointer-events: all;\r\n}\r\n\r\nh4 {\r\n    font-weight: normal;\r\n    margin: 10px 0;\r\n}\r\n\r\nlabel {\r\n    font-weight: bold;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
