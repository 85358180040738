import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { UserUpdateLogEntry } from 'app/users/user-log';

@Component({
    selector: 'app-user-update-log',
    templateUrl: './user-update-log.component.html',
    styleUrls: ['./user-update-log.component.css'],
})
export class UserUpdateLogComponent implements OnInit, OnDestroy {
    @Input()
    set userUpdateLog(log : UserUpdateLogEntry[]) {
        this.isLoading = true;
        this.gridData = process(this.transformLogEntries(log), this.state);
        this.isLoading = false;
    }

    public isLoading = true;
    public state: State = {};
    public gridData: GridDataResult;

    private fieldDisplayNameMap = new Map([
        ["email", "Email Address"],
        ["firstName", "First Name"],
        ["lastName", "Last Name"],
        ["phoneNumber", "Phone Number"],
        ["phoneNumberEXT", "Phone Number EXT"],
        ["cellNumber", "Cell Number"],
    ])

    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.gridData = process(this.userUpdateLog, this.state);
    }

    private transformLogEntries(log: UserUpdateLogEntry[]): UserUpdateLogEntry[] {
        return log
            .filter(entry => this.fieldDisplayNameMap.has(entry.field))
            .map(entry => ({
                ...entry,
                fieldDisplayName: this.fieldDisplayNameMap.get(entry.field),
            }));
    }
}
