import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromEventState from "./store/state";
import { eventReducer } from './store/reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EventEffects } from './store/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromEventState.eventKey, eventReducer),
    EffectsModule.forFeature([EventEffects]),
  ]
})
export class EventsModule { }
