export class AlertHit {
    id: number;
    plate: string;
    createdDate: Date;
    siteCreatedDate: Date;
    cameraID: number;
    siteID: number; // site its seen at maybe we need a more descriptive name
    siteName: string;
    confidence: number;
    make: string;
    model: string;
    color: string;
    state: string;
    img: string;
    croppedImg: string;
    hits: number;

    constructor(config?: any) {
        if (config) {
            Object.assign(this, config);
            if (config.createdDate) {
                this.createdDate = new Date(this.createdDate);
                this.siteCreatedDate = new Date(this.createdDate);
            }
        }
    }
}