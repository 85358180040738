import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NotificationsService } from "../../../notifications/notifications.service";
import { Site } from "../../../site/site";
import { User } from "../../../users/user";
import { STATUSES } from "../../../site/statuses";
import { Lookup } from "../../../lookup";
import SitePackage from "../../../site-package/models/site-package";
import { Observable } from "rxjs";
import { PermissionsService } from "app/permissions.service";

@Component({
  selector: "app-site-setup-form",
  templateUrl: "./site-setup-form.component.html",
  styleUrls: ["./site-setup-form.component.scss"],
})
export class SiteSetupFormComponent {
  @Input()
  set site(value: Site) {
    this._site = new Site(value);    
    if (!this._site.status) {
      this._site.status = STATUSES[0];
    }

    if (!this._site.displayName || this._site.displayName === "") {
      this._site.displayName = this._site.siteName;
      this.updatedSite.emit(this._site);
    }

    this.startingHqAdmin = value.hqAdminID;
    if (this.hqAdminList && value) {
      this.selectedHQ = this.hqAdminList.filter((item) => item.id === value.hqAdminID);
    }

    if (this._site.additionalContacts) {
      this.additionalContacts = this._site.additionalContacts.map((user) => {
        return { id: user.id, text: user.userName };
      });
    }
    if (this._site.additionalSalesmen) {
      this.additionalSalesmen = this._site.additionalSalesmen.map((user) => {
        return { id: user.id, text: user.userName };
      });
    }
  }

  @Input("users")
  set setUsers(value: User[]) {        
    this.users = value;
    if (value) {
      this.primaryContactUsers = [...value];
      this.userlist = value.map((user) => {
        return { id: user.id, text: user.userName };
      });
      this.users.unshift(this.undefinedUser);      
    } else {
      this.users = [this.undefinedUser];
     }
  }

  @Input("sales")
  set setSalesList(value: User[]) {
    this.sales = value;
    if (value) {
      this.saleslist = value.map((user) => {
        return { id: user.id, text: user.userName };
      });
    }
  }

  @Input("hqAdminList")
  set setHqAdminList(value) {
    this.hqAdminList = value;
    if (this._site && value) {
      this.selectedHQ = value.filter((item) => item.id === this._site.hqAdminID);
    }
  }

  @Input("managementCompanies")
  set setManagmentCompanies(value) {
    this.managementCompanies = value;
  }

  @Input("sitePackages")
  public sitePackages$: Observable<SitePackage[]>;

  @Input("hasManageVitals") set SetManageVitals(value: boolean) {
    this.hasVitalPerm = value;
  }

  @Output()
  public updatedSite = new EventEmitter();

  public users: User[];
  public primaryContactUsers: User[] = [];
  public sales: User[];
  public managementCompanies: Lookup;
  public statuses = STATUSES;
  public _site: Site = new Site();
  public userlist: { id: number; text: string }[] = [];
  public saleslist: { id: number; text: string }[] = [];
  public startingHqAdmin: number;
  public selectedHQ: { id: number; text: string }[] = [];
  public hqAdminList;
  public additionalContacts: any[] = [];
  public additionalSalesmen: any[] = [];
  public showVitalModal: boolean = false;
  public hasVitalPerm: boolean;
  public undefinedUser: User = new User({id:0, userName: "Need Info"});
  public showDisableByosAccountDialog: boolean = false;
  public showEnableByosAccountDialog: boolean = false;

  public storageOptionSelected: boolean = false;
  public savedBucketID: number = null;

  constructor(
    private _notificationsService: NotificationsService) {}

  onChange() {
    if (this._site.daysRewind > this._site.cloudStorage) {
      this._notificationsService.error("", "Rewind Days cannot be greater than cloud storage: " + this._site.cloudStorage.toString());
    }

    this.updatedSite.emit(this._site);
  }

  onByosChange() {

    if (this._site.isByos === true && this._site.bucketID !== null) {
      this._site.isByos = !this._site.isByos;
      this.onChange();
      this.showDisableByosAccountDialog = true;
      return;
    }

    if (this._site.isByos === false && this._site.bucketID !== null) {
      this._site.isByos = !this._site.isByos;
      this.onChange();
      this.showEnableByosAccountDialog = true;
      return;
    }

    this._site.isByos = !this._site.isByos;
    this.onChange();

  }

  closeDisableByosAccountDialog() { 
    this._site.isByos = !this._site.isByos;    
    this.showDisableByosAccountDialog = false;    
    this.onChange();
  }

  removeByosAccountOnDisable() {
    this.storageOptionSelected = true;
    this.savedBucketID = this._site.bucketID;
    this._site.bucketID = null;
    this.showDisableByosAccountDialog = false;
    this.onChange();
  }

  keepByosAccountOnDisable() {
    this.savedBucketID = this._site.bucketID;
    this.storageOptionSelected = true;
    this.showDisableByosAccountDialog = false;   
  }

  closeEnableByosAccountDialog() {  
    this._site.isByos = !this._site.isByos;
    this.showEnableByosAccountDialog = false;
    this.onChange();    
  }

  keepByosAccountOnEnable() {
    this.savedBucketID = this._site.bucketID;
    this.storageOptionSelected = true;
    this.showEnableByosAccountDialog = false; 
  }
  
  removeByosAccountOnEnable() {    
    this.storageOptionSelected = true;
    this.showEnableByosAccountDialog = false;
    this.savedBucketID = this._site.bucketID;
    this._site.bucketID = null;
    this.onChange();
  }

  resetByos() {
    this._site.bucketID = this.savedBucketID;
    this._site.isByos = !this._site.isByos;
    this.storageOptionSelected = false;
    this.onChange();
  }

  clearUpgrade() {
    this._site.upgradeDate = null;
    this.onChange();
  }

  showEditVital() {
    this.showVitalModal = true;
  }

  hideEditVital() {
    this.showVitalModal = false;
  }
}