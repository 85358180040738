import { Component, Output, EventEmitter, OnInit } from "@angular/core";

@Component({
  selector: "express-request-modal",
  templateUrl: "./express-request-modal.component.html",
  styleUrls: ["./express-request-modal.component.scss"],
})
export class ExpressRequestModalComponent implements OnInit {
  @Output() close = new EventEmitter<Event>();

  constructor() {}

  public ngOnInit() {}

  public onClose() {
    this.close.emit();
  }
}
