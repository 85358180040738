import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { config } from "app/app.config";
import { catchError } from "rxjs/operators";
import { ServiceHelpersService } from "app/service-helpers.service";

@Injectable()
export class StreamService {
    private headers = {};

    constructor(public http: HttpClient, public serviceHelpersService: ServiceHelpersService) {}
    
    createHLSStream(cameraID: number): Observable<any> {
        return this.http.post<any>(`${config.apiUrl}stream/hls/${cameraID}`, {})
            .pipe(catchError(this.serviceHelpersService.handleError));
    }

    createRTSPStream(cameraID: number): Observable<any> {
        return this.http.post<any>(`${config.apiUrl}stream/rtsp/${cameraID}`, {})
            .pipe(catchError(this.serviceHelpersService.handleError));
    }

    sendError(cameraID: number, error: any, type: string): Observable<any> {
        let headers = new HttpHeaders(Object.assign({}, this.headers, {"Content-Type": 'application/json'}));
        let body = JSON.stringify({
            cameraID: cameraID,
            error: error,
            type: type,
        });

        return this.http.post<any>(`${config.apiUrl}stream/error`, body, { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError));
    }
}

@Injectable()
export class MockStreamService {
    createHLSStream(cameraID: number): Observable<any> {
        return of("");
    }
    createRTSPStream(cameraID: number): Observable<any> {
        return of("");
    }
    sendError(cameraID: number, error: any, type: string): Observable<any> {
        return of("");
    }
}