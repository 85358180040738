import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MotionFile } from "app/motion/motion-data";

@Component({
  selector: "app-video-image-carousel",
  templateUrl: "./video-image-carousel.component.html",
  styleUrls: ["./video-image-carousel.component.scss"],
})
export class VideoImageCarouselComponent implements OnInit {
  @Input("files") set setFiles(value: MotionFile[]) {
    this.tabCount = Math.ceil(value.length / this.maxActiveFiles);
    this.pageMin = 1;
    this.files = value;
    this.resetActiveFiles();
  }

  @Input() camIDToDisplayName: { [id: number]: string };
  @Input() motionViewed: { [imgURL: string]: boolean};
  @Input("selectedMotionFile") set setSelectedMotionFile(value: MotionFile) {
    this.selectedMotionFile = value;
    this.jumpToFile(value);
  };

  @Output() fileClick = new EventEmitter<MotionFile>();

  public activeFiles: MotionFile[] = [];
  public files: MotionFile[] = [];
  public tabCount: number;
  public selectedMotionFile: MotionFile;
  
  public currentPage: number = 1;
  public pageMin: number = 1;
  
  private readonly maxActiveFiles = 5;
  private slideIndex = 0;  

  constructor() {}

  public ngOnInit() {}

  public onRightNavClick() {
    if (this.slideIndex >= this.tabCount - 1) {
      this.slideIndex = 0;
      this.currentPage = 1;
      this.resetActiveFiles();
      return;
    }

    this.slideIndex++;
    this.currentPage = this.slideIndex+1;
    this.resetActiveFiles();
  }

  public onLeftNavClick() {
    if (this.slideIndex <= 0) {
      this.slideIndex = this.tabCount - 1;
      this.currentPage = this.slideIndex+1
      this.resetActiveFiles();
      return;
    }

    this.slideIndex--;
    this.currentPage=this.slideIndex+1;
    this.resetActiveFiles();
  }

  public onBottomNavClick(index: number) {
    this.slideIndex = index;
    this.currentPage = index+1
    this.resetActiveFiles();
  }

  public onFileClick(file: MotionFile) {
    this.fileClick.emit(file);
  }

  private resetActiveFiles() {
    const startIndex = this.maxActiveFiles * this.slideIndex;
    this.activeFiles = [];
    for (let i = startIndex; i < this.files.length; i++) {
      if (this.activeFiles.length >= this.maxActiveFiles) {
        break;
      }

      this.activeFiles.push(this.files[i]);
    }
  }
  public jumpToFile(file: MotionFile) {
    let fileIndex = 0;
    for(let i = 0; i < this.files.length; i++) {
      if(this.files[i].imageURL === file.imageURL) {
        fileIndex = i
        this.currentPage = i+1;
        break;
      }
    }
    this.slideIndex = Math.floor(fileIndex / this.maxActiveFiles);
    this.currentPage = this.slideIndex+1;
    this.resetActiveFiles();
  }

  public onPageChange(page: number) {
    if (page !== null) {
   this.onBottomNavClick(page-1);
  }  
}
}
