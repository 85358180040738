import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../app.state';

import { Site } from '../site/site';
import { SiteService } from '../site/site.service';
import { Map } from '../map/map';

@Component({
    selector: 'app-light-map',
    templateUrl: './light-map.component.html',
    styleUrls: ['./light-map.component.css'],
})
export class LightMapComponent implements OnInit{
    public siteSub: any;
    public map: Map;
    public maps: Map[];
    public site: Site;
    public siteLoading;
    public routeSubscription;
    constructor(public siteService: SiteService) {}
    ngOnInit() {
        this.siteSub = this.siteService.getSite().subscribe(site => {
            if(site) {
                this.maps = [];
                if(site.lightMaps) {
                    site.lightMaps.forEach( map => {
                        let newMap = new Map(map);
                        newMap.markersLocal = [];
                        this.maps.push(newMap);
                    })
                }
            }
        });
    }
}