import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, catchError, takeUntil } from 'rxjs/operators';
import { AppState } from "app/app.state";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import * as NewLeadActions from './actions';
import { NotificationsService } from "../../notifications/notifications.service";
import { NewLeadService } from "../new-lead.service";

@Injectable()
export class NewLeadEffects {

    getManagementCompanies$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(NewLeadActions.setShowNewLeadForm),
            switchMap((action) => {
                if (action.show === false) {
                    return of(NewLeadActions.noAction());
                }
                return this.newLeadService.getManagementCompanies().pipe(
                    map(data => NewLeadActions.setManagementCompanies({companies: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading management companies")
                        return of(NewLeadActions.noAction());
                    }),
                )
            })
        )
    })

    getLeadSources$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(NewLeadActions.setShowNewLeadForm),
            switchMap((action) => {
                if (action.show === false) {
                    return of(NewLeadActions.noAction());
                }
                return this.newLeadService.getLeadSources().pipe(
                    map(data => NewLeadActions.setLeadSources({sources: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading lead sources")
                        return of(NewLeadActions.noAction());
                    }),
                )
            })
        )
    })

    getInternalEmails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(NewLeadActions.setShowNewLeadForm),
            switchMap((action) => {
                if (action.show === false) {
                    return of(NewLeadActions.noAction());
                }
                return this.newLeadService.getInternalEmails().pipe(
                    map(data => NewLeadActions.setInternalEmails({emails: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading internal emails")
                        return of(NewLeadActions.noAction());
                    }),
                )
            })
        )
    })

    getRelatedSites$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(NewLeadActions.getRelatedSites),
            switchMap((action) => {
                if (action.search.length < 2) {
                     return of(NewLeadActions.noAction());
                }
                return this.newLeadService.getRelatedSites(action.search).pipe(
                    map(data => NewLeadActions.setRelatedSites({sites: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading related sites")
                        return of(NewLeadActions.noAction());
                    }),
                )
            })
        )
    })

    submitNewLead$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(NewLeadActions.submitNewLead),
            switchMap((action) => {
                const cancel$ = this.actions$.pipe(ofType(NewLeadActions.cancelSubmitNewLead));
                if (action.lead.imageFiles.length > 0) {
                    return this.newLeadService.uploadNewLeadImages(action.lead.imageFiles).pipe(
                        switchMap((imgResponse) => {
                            const leadWithImages = {
                                ...action.lead,
                                imageFileNames: imgResponse,
                            };
                            return this.newLeadService.submitNewLead(leadWithImages).pipe(
                                map(() => {
                                    this.notificationsService.success("", "Lead submitted successfully");
                                    return NewLeadActions.submitNewLeadSuccess();
                                }),
                                catchError((error) => {
                                    this.notificationsService.error("", "Error submitting lead");
                                    return of(NewLeadActions.submitNewLeadFailure());
                                }),
                                takeUntil(cancel$)
                            );
                        }),
                        catchError((error) => {
                            this.notificationsService.error("", "Error uploading images");
                            return of(NewLeadActions.uploadNewLeadImagesFailure());
                        }),
                        takeUntil(cancel$)
                    );
                } else {
                    return this.newLeadService.submitNewLead(action.lead).pipe(
                        map(() => {
                            this.notificationsService.success("", "Lead submitted successfully");
                            return NewLeadActions.submitNewLeadSuccess();
                        }),
                        catchError((error) => {
                            this.notificationsService.error("", "Error submitting lead");
                            return of(NewLeadActions.submitNewLeadFailure());
                        }),
                        takeUntil(cancel$)
                    );
                }
            })
        );
    });
      

    constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private store: Store<AppState>,
    private newLeadService: NewLeadService, 
) {}

}