import { Directive, HostListener, HostBinding, EventEmitter, Output, Input } from '@angular/core';

@Directive({
  selector: '[uploadDrop]'
})
export class UploadDirective {
  @Input() extensions = []
  @Output() filesChange = new EventEmitter()
  @Output() filesInvalid = new EventEmitter()
  @HostBinding('style.background') background = '#eee'

  constructor() { }

  @HostListener('dragover', ['$event']) dragOver(event){
    event.preventDefault()
    event.stopPropagation()
    this.background = '#999'
  }

  @HostListener('dragleave', ['$event']) dragLeave(event){
    event.preventDefault()
    event.stopPropagation()
    this.background = '#eee'
  }

  @HostListener('drop', ['$event']) drop(event){
    event.preventDefault()
    event.stopPropagation()
    this.background = '#eee'

    let files = event.dataTransfer.files
    let validFiles = []
    let invalidFiles = []

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let ext = files[i].name.split('.')[files[i].name.split('.').length - 1]
        if (this.extensions.lastIndexOf(ext) != -1) {
          validFiles.push(files[i])
        } else {
          invalidFiles.push(files[i])
        }
      }

      this.filesChange.emit(validFiles);
      this.filesInvalid.emit(invalidFiles);
    }
  }
}