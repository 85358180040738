import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromExtendedStorageState from './store/state';
import { extendedStorageReducer } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { ExtendedStorageEffects } from './store/effects';


@NgModule({
    declarations:[ 
  
    
  ],
    imports:[
        CommonModule,
        StoreModule.forFeature(fromExtendedStorageState.byosKey, extendedStorageReducer),
        EffectsModule.forFeature([ExtendedStorageEffects]),
    ]
})
export class ExtendedStorageModule{}