

export class Point {
    x: number;
    y: number;
    start: boolean;
    constructor(x: number, y: number, start?: boolean) {
        this.x = x;
        this.y = y;
        this.start = start;
    }
}