import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';

declare global {
  interface Window {
    freshpaint: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private freshpaint: any;

  constructor() {
    this.waitForFreshpaint().subscribe(freshpaint => {
      this.freshpaint = freshpaint;
    });
  }

  private waitForFreshpaint(): Observable<any> {
    return fromEvent(window, "load").pipe(
      map(() => window.freshpaint),
      filter(freshpaint => !!freshpaint),
      take(1)
    );
  }

  track(event: string, properties: any): void {
    if(this.freshpaint) {
      this.freshpaint.track(event, properties);
    } else {
      this.waitForFreshpaint().subscribe(() => {
        this.freshpaint.track(event, properties);
      });  
      console.log("Freshpaint not loaded");
    }
  }
  
  identify(userId: string, traits: any): void {
    if (this.freshpaint) {
      this.freshpaint.identify(userId, traits);
    } else {
      this.waitForFreshpaint().subscribe(() => {
        this.freshpaint.identify(userId, traits);
      });
      console.log("Freshpaint not loaded");
    }
  }
}
