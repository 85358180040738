// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-button {
    font-size: 16px;
    margin: 5px;
    
}



.layout-button-active {
    font-size: 16px;
    margin: 5px;
    background-color: #666;
    color: white;
}

.layout-content {
    display: flex;
    align-items: center;
}
.layout-content .material-icons-round {
    margin-right: 8px; /* Adjust spacing between icon and name */
}

`, "",{"version":3,"sources":["webpack://./src/app/cvs/cvs.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;;AAEf;;;;AAIA;IACI,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,iBAAiB,EAAE,yCAAyC;AAChE","sourcesContent":[".layout-button {\r\n    font-size: 16px;\r\n    margin: 5px;\r\n    \r\n}\r\n\r\n\r\n\r\n.layout-button-active {\r\n    font-size: 16px;\r\n    margin: 5px;\r\n    background-color: #666;\r\n    color: white;\r\n}\r\n\r\n.layout-content {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n.layout-content .material-icons-round {\r\n    margin-right: 8px; /* Adjust spacing between icon and name */\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
