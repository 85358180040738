import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { AppState } from "app/app.state";
import { Store } from "@ngrx/store";
import { Router } from '@angular/router';
import { ResizedEvent } from '../../angular-resize.directive';

@Component({
  selector: 'app-portfolio-event-grid',
  templateUrl: './portfolio-event-grid.component.html',
  styleUrls: ['./portfolio-event-grid.component.css']
})
export class PortfolioEventGridComponent implements OnInit {

  @Input('eventGridView') set setEvents(value: GridDataResult) {
    if (value) {
      this.eventGridView = value;
    }
  }

  @Input('gridState') set setGridState(value: State) {
    if (value) {
      this.gridState = value;
    }
  }

  @Input('eventGridLoading') set setEventGridLoading(value: boolean) {   
      this.eventGridLoading = value;
  }

  @Output() gridStateChange: EventEmitter<State> = new EventEmitter<State>();

  public eventGridView: GridDataResult;
  public gridState: State;
  public eventGridLoading: boolean = false;
  
  gridWidth: number = 0;

  constructor(
    public router: Router,
    private store: Store<AppState>,
  ) { }

  ngOnInit(): void { }

  onEventGridStateChange(state: DataStateChangeEvent) {
    this.gridStateChange.emit(state);
  }

  public onEventSelect(data: any) {
    let event = data.selectedRows[0].dataItem;
    this.router.navigate(["site", event.siteID, "events", event.id]);
  }

  onGridResized(event: ResizedEvent) {
    this.gridWidth = Math.round(event.newRect.width);
  }
}
