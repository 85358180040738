
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import { START_REQUEST_WHAT, SET_REQUEST_CURRENT_WHAT } from '../../requests.reducer';

@Injectable()
export class WhatGuard  {
    constructor(public store: Store<AppState>) {}
    canActivate() {
        this.store.dispatch({ type: START_REQUEST_WHAT });
        this.store.dispatch({type: SET_REQUEST_CURRENT_WHAT});
        return true;
    }
}