import { createAction, props } from "@ngrx/store";

import { Message } from '../message';

export const noAction = createAction("[Messages] No Action");

export const loadUserMessages = createAction("[Messages] Load User Messages");

export const setUserMessages = createAction("[Messages] Set User Messages", props<{userMessages: Message[]}>());

export const updateUserMessage = createAction("[Messages] Update User Message", props<{userMessage: Message}>());