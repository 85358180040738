import { PortfolioEvent, Event } from "../portfolio-event";
import { State as GridState } from '@progress/kendo-data-query';
import { EventSort } from "../event-sort";
import { Lookup } from "../../lookup";
import { EventTypeMetric } from "../event-type-metric";
import { EventDayHourMetric } from "../event-day-hour-metric";

export const eventKey = "event";

export interface State {
    portfolioEvents: PortfolioEvent,
    eventGridState: GridState,
    eventGridLoading: boolean,
    eventItems: Event[],
    eventSort: EventSort,
    siteList: Lookup[],
    cfTagCategories: Lookup[],
    statusCodes: Lookup[],
    siteFilter: Lookup[],
    cfTagCategoryFilter: Lookup[],
    startDateFilter: Date,
    endDateFilter: Date,
    statusFilter: Lookup[],
    textFilter: string,
    eventTypeMetrics: EventTypeMetric,
    eventTypeMetricsLoading: boolean,
    showEventMetrics: boolean,
    eventTimeDayHour: EventDayHourMetric[],
    eventTimeDayHourLoading: boolean,
    selectedBubble: EventDayHourMetric 
}

export const initialState: State = {
    portfolioEvents: null,
    eventSort: undefined,
    eventGridState: { sort: [], skip: 0, take: 50 },
    eventGridLoading: false,
    eventItems: [],
    siteList: [],    
    cfTagCategories: [],
    statusCodes: [],
    siteFilter: [],
    cfTagCategoryFilter: [],
    startDateFilter: null,
    endDateFilter: null,
    statusFilter: [],
    textFilter: '',
    eventTypeMetrics: null,
    eventTypeMetricsLoading: false,
    showEventMetrics: true,
    eventTimeDayHour: [],
    eventTimeDayHourLoading: false,
    selectedBubble: null
}