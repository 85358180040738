import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AppState } from '../app.state';
import { config } from '../app.config';
import { AccessKeyService } from '../users/access-key.service';
import { SET_SITE, SITE_LOADING, SITE_DONE_LOADING } from './site.reducer';
import { Site } from './site';
import { SiteListService } from '../sites/site-list.service';
import { ServiceHelpersService } from '../service-helpers.service';
import { SiteMetrics } from './site-metrics';
import { SiteCloudStorage } from './site-cloud-storage';
import { SiteLEAccessAuditLogEntry } from './site-le-access-audit-log';
import { ByosBackfillJob } from './byos-backfill-job';

@Injectable()
export class SiteService {
    public headers = {};
    constructor(
        public http:HttpClient,
        public store: Store<AppState>,
        public accessKeyService: AccessKeyService,
        public siteListService: SiteListService,
        public serviceHelpersService: ServiceHelpersService,
    ) {
        this.accessKeyService.getKey().subscribe(key => {
            this.headers = Object.assign(this.headers, { "accessKey": key})
        });
    }

    getSite(): Observable<Site> {
        return this.store.select(s => s.site);
    }

    getSiteLoading(): Observable<boolean> {
        return this.store.select(s => s.siteLoading);
    }

    fetchSite(id: number) {
        this.store.dispatch({type: SITE_LOADING, payload: {}});
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Site>(config.apiUrl + "site/" + id, {headers: headers}).pipe(
            catchError(this.serviceHelpersService.handleError), 
            map((site) => {
                site = new Site(site);
                this.store.dispatch({type: SET_SITE, payload: site});
                this.store.dispatch({type: SITE_DONE_LOADING, payload: {}});

                return site;
            }),
        );
    }

    fetchSiteForLoader(id: number): Observable<HttpResponse<Site>> {
        this.store.dispatch({type: SITE_LOADING, payload: {}});
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Site>(config.apiUrl + "site/" + id, {headers: headers, observe: "response"});
    }

    getOtherSite(id: number): Observable<Site> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Site>(config.apiUrl + "site/" + id, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError), map(site => new Site(site)));
    }

    updateSite(site: Site): Observable<Site> {
        let headers = new HttpHeaders(Object.assign(this.headers, { 'Content-Type': 'application/json'}));
        let body = JSON.stringify(site);
        return this.http.put<Site>(config.apiUrl + "site/" + site.siteID, body, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError), map(site => new Site(site)));
    }

    updateSiteLEAccess(siteID: number): Observable<Site> {
        let headers = new HttpHeaders(this.headers);
        return this.http.put<Site>(config.apiUrl + "site/" + siteID + "/leaccess", null, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError), map(site => new Site(site)));
    }

    newSite(site: Site): Observable<Site> {
        let headers = new HttpHeaders(Object.assign(this.headers, { 'Content-Type': 'application/json'}));
        let body = JSON.stringify(site);
        return this.http.post<Site>(config.apiUrl + "sites", body, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError), map(site => {
            this.siteListService.fetchSiteList();
            return site;
        }));
    }
    
    getMetrics(id: number): Observable<SiteMetrics> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<SiteMetrics>(config.apiUrl + "site/" + id + "/metrics", {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
    }

    getMultiSiteData(): Observable<Site[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Site[]>(config.apiUrl + "user/current/sites", {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError), map(sites => {
            if(sites && sites.map) {
                return sites.map(s => new Site(s));
            }
            return sites;
        }));
    }

    getSiteCloudStorage(id: number): Observable<SiteCloudStorage> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<SiteCloudStorage>(config.apiUrl + "site/" + id + "/cloudstorage", {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError), map(storage => new SiteCloudStorage(storage)));
    }

    getLEAccessAuditLog(siteID: number): Observable<SiteLEAccessAuditLogEntry[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<SiteLEAccessAuditLogEntry[]>(config.apiUrl + "site/" + siteID + "/leaccessauditlog", {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
    }

    byosDnsBackfill(siteID: number): Observable<ByosBackfillJob> {
        let headers = new HttpHeaders(this.headers);

        return this.http.post<ByosBackfillJob>("/api/byos/backfill/site/" + siteID + "/dns", headers, {}).pipe(catchError(this.serviceHelpersService.handleError), map(job => new ByosBackfillJob(job)));
    }
}
