// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-player {
    /* max-width: 100%; */
    display: block;
    pointer-events: auto;
    background-color: black;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    aspect-ratio: 16/9;
    max-height: calc(90vh - 100px);
}
.video-player.stretch {
    width: 100%;
    height: 100%;
    object-fit: fill;
    max-height: 100%;
}
.video-container:-webkit-full-screen>.video-player {
    object-fit: fill;
    max-height: 100%;
    height: 100%;
    width: 100%;
}
.video-container:fullscreen>.video-player {
    object-fit: fill;
    max-height: 100%;
    height: 100%;
    width: 100%;
}
.video-container:-webkit-full-screen>.logo {
    top: 0 !important;
    right: 0 !important;
}
.video-container:fullscreen>.logo {
    top: 0 !important;
    right: 0 !important;
}

.video-player::after {
    padding-top: 56.25%;
    display: block;
    content: "";
}

.hide {
    display: none;
}

video::-webkit-media-controls {
    display: none !important;
}

.logo {
    display: inline-block;
    z-index: 1;
    position: absolute;
    width: 3vw;
    right: 0;
}

.video-container {
    position: relative;
    width: 100%;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/camera/live-view/hls2-player/hls2-player.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,cAAc;IACd,oBAAoB;IACpB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,8BAA8B;AAClC;AACA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,WAAW;AACf;AALA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,WAAW;AACf;AACA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;AAHA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,kBAAkB;IAClB,UAAU;IACV,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".video-player {\r\n    /* max-width: 100%; */\r\n    display: block;\r\n    pointer-events: auto;\r\n    background-color: black;\r\n    width: 100%;\r\n    height: auto;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    aspect-ratio: 16/9;\r\n    max-height: calc(90vh - 100px);\r\n}\r\n.video-player.stretch {\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: fill;\r\n    max-height: 100%;\r\n}\r\n.video-container:fullscreen>.video-player {\r\n    object-fit: fill;\r\n    max-height: 100%;\r\n    height: 100%;\r\n    width: 100%;\r\n}\r\n.video-container:fullscreen>.logo {\r\n    top: 0 !important;\r\n    right: 0 !important;\r\n}\r\n\r\n.video-player::after {\r\n    padding-top: 56.25%;\r\n    display: block;\r\n    content: \"\";\r\n}\r\n\r\n.hide {\r\n    display: none;\r\n}\r\n\r\nvideo::-webkit-media-controls {\r\n    display: none !important;\r\n}\r\n\r\n.logo {\r\n    display: inline-block;\r\n    z-index: 1;\r\n    position: absolute;\r\n    width: 3vw;\r\n    right: 0;\r\n}\r\n\r\n.video-container {\r\n    position: relative;\r\n    width: 100%;\r\n    height: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
