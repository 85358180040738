import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router';

import { MetricsService } from '../metrics.service';
import { PeopleCamera } from './people-camera';
import { SiteService } from '../../site/site.service';
import { Camera } from '../../camera/camera';
import { CameraService } from '../../camera/camera.service';
import { PermissionsService } from '../../permissions.service';

@Component({
  selector: 'app-counting',
  templateUrl: './counting.component.html',
  styleUrls: ['./counting.component.css']
})
export class CountingComponent implements OnInit, OnDestroy {
  data = [];
  siteID: number;
  graphType: string = "Month"
  changes: any;
  peopleCameras: PeopleCamera[] = [];
  selectedPeople: PeopleCamera;
  alive: boolean = true;
  camNames: { [id: number]: string} = {};
  peopleGraphType: string = "Week"
  peopleData = [];
  peopleChanges: any;
  carCountingCameras = [];

  constructor(
    public metricsService: MetricsService, 
    public route: ActivatedRoute, 
    private cameraService: CameraService, 
    public permissionsService: PermissionsService
  ) { }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.siteID = +params['siteID'];
      this.metricsService.getCarCountByMonth(this.siteID).subscribe(data => this.data = data);       
      this.metricsService.getCarCountChanges(this.siteID).subscribe(data => this.changes = data);
      this.cameraService.getCamerasLite(this.siteID).subscribe(cameras => {
          this.camNames = {};
          cameras.forEach(cam => {
              this.camNames[cam.id] = cam.displayName;
          })
      })
      this.metricsService.getPeopleCountingCamerasBySite(this.siteID).subscribe(cams => {
          this.peopleCameras = cams
          if(cams.length > 0) {
              this.selectPeople(cams[0]);
          }
      });
      this.metricsService.getCarCountCameras(this.siteID).subscribe(cams => {
          this.carCountingCameras = cams.alprCameras;
      })
  })

  }

ngOnDestroy() {
    this.alive = false;
}

setMonth() {
  this.graphType = "Month";
  this.metricsService.getCarCountByMonth(this.siteID).subscribe(data => {
      this.data = data;
  });
}
setWeekday() {
  this.graphType = "Weekday";
  this.metricsService.getCarCountByWeekday(this.siteID).subscribe(data => {
      this.data = data;
  });
}
setWeek() {
  this.graphType = "Week";
  this.metricsService.getCarCountByWeek(this.siteID).subscribe(data => this.data = data);
}
calcPercent(last, prev) {
  if(prev === 0 ) {
      return 0;
  }
  let diff = last - prev;
  return (diff / prev) * 100
}
selectPeople(cam: PeopleCamera) {
  this.selectedPeople = cam;
  // this.metricsService.getPeopleCountingSchedule(cam.cameraID).subscribe(data => {
  //     console.log(data);
  // })
  this.setPeopleType(this.peopleGraphType);
  this.metricsService.getPeopleChanges(cam.cameraID).subscribe(data => {
      this.peopleChanges = data;
  })
}
setPeopleType(type: string) {
  this.peopleGraphType = type;
  switch (type) {
      case "Week":
          this.metricsService.getPeopleByWeek(this.selectedPeople.cameraID).subscribe(data => {
              this.peopleData = data;
          });
          break;
      case "Weekday":
          this.metricsService.getPeopleByWeekday(this.selectedPeople.cameraID).subscribe(data => this.peopleData = data);
          break;
      case "Hour":
          this.metricsService.getPeopleByHour(this.selectedPeople.cameraID).subscribe(data => this.peopleData = data);
          break;
      default:
          break;
  }
}

}
