import { Component, Input, OnInit } from '@angular/core';

import { Site } from '../../site/site';
import { SiteMetrics } from '../../site/site-metrics';
import { Map } from '../../map/map';
import { SiteDisplayMap } from './site-display-map';
import { MarkerService } from '../../markers/marker.service';

@Component({
    selector: 'app-site-display',
    templateUrl: './site-display.component.html',
    styleUrls: ['./site-display.component.css'],
})
export class SiteDisplayComponent {
    @Input("site") set siteSetter(value: Site) {
        if(value){
            this.site = new Site(value);
            if(this.site.maps && this.site.maps[0]) {

                let newMap = new SiteDisplayMap(this.site.maps[0])
                newMap.markersLocal = [];
                this.maps.push(newMap);                
                // this.site.maps.forEach(map => {
                //     let newMap = new SiteDisplayMap(map);
                //     if(map.markers) {
                //         map.markersLocal = this.markerService.createMarkers(map.markers, newMap);
                //     }
                //     else {
                //         map.markersLocal = [];
                //     }
                //     newMap.markersLocal = map.markersLocal;
                //     this.maps.push(newMap);
                // })
            }
        }
    };
    site: Site;
    maps: Map[] = [];
    verifiedTime: Date;
    done = false;
    constructor(public markerService: MarkerService) {}
    ngOnInit() {
        this.verifiedTime = new Date();
        this.verifiedTime.setMinutes( Math.floor(this.verifiedTime.getMinutes() / 15 ) * 15)
    }
    ngAfterViewInit() {
        setTimeout( () => {
        if(this.site.maps[0].markers) {
            this.site.maps[0].markersLocal = this.markerService.createMarkers(this.site.maps[0].markers.filter(marker => marker.type ==="heatdot" || marker.type =="map"), this.maps[0])
        } else {
            this.site.maps[0].markersLocal = [];
        }
        this.maps[0].markersLocal = this.site.maps[0].markersLocal;
        this.maps[0].draw();
        this.done = true;
        }, 1
        )
    }
}