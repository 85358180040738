import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, combineLatest, fromEvent, interval } from "rxjs";
import {  map, take, takeWhile } from 'rxjs/operators';
import { Store, select } from "@ngrx/store";
import { AppState } from "app/app.state";
import * as actions from '../store/actions';
import * as selectors from '../store/selectors';
import { CameraLite } from '../camera-lite';
import { UserAgentService } from 'app/user-agent.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkVirtualScrollViewport, ViewportRuler } from '@angular/cdk/scrolling';
import { Camera } from '../camera';


import { User } from '../../users/user';
import { UserService } from '../../users/user.service';
import { Site } from '../../site/site';
import { SiteListService } from '../../sites/site-list.service';
import { Lookup } from 'app/lookup';
import { FeatureFlagService } from 'app/feature-flag/feature-flag.service';
import { CameraGridSize } from '../camera-grid-size';
import { Filters } from './filters';
import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { PermissionsService } from 'app/permissions.service';


@Component({
  selector: 'app-portfolio-camera',
  templateUrl: './portfolio-camera.component.html',
  styleUrls: ['./portfolio-camera.component.css']
})
export class PortfolioCameraComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

  public portfolioCameras$: Observable<CameraLite[]>;
  public cameraIntents$: Observable<Lookup[]>;  
  public siteFilter$: Observable<number[]>;
  public intentFilter$: Observable<number[]>;
  public nameFilter$: Observable<string>;
  public sortOptions$: Observable<string[]>;
  public groupByOptions$: Observable<string[]>;
  public selectedSortOption$: Observable<string>;
  public selectedGroupByOption$: Observable<string>;
  public cameraFooterCollapse$: Observable<boolean>;
  public cameraGridSize$: Observable<CameraGridSize>;
  public cameraHeight: Observable<number>;
  public appBarWidth$: Observable<number>;
  public scrollUpThreshold$: Observable<number>;
  public loadingCameras$: Observable<boolean>;
  
  public isIE: boolean;
  public cameraStreams$: Observable<{ [cameraID: number]: Camera }>;

  public isLive$: Observable<boolean>;
  public user: User;
  public site: Site;
  public sites: Lookup[] = [];  
  public alive: boolean = true;
  private resize$ = this.viewportRuler.change(200);
  public navHeight: string = "height:calc(100vh - 60px)";
  public hasLiveFeature: boolean = false;
  public hasSiteWalkFeature: boolean = false;
  public hasLivePermission: boolean = true; // TODO: Need to figure out how live permission works with portfoilios

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private userService: UserService,
    private siteListService: SiteListService,
    public userAgentService: UserAgentService,
    private readonly viewportRuler: ViewportRuler,
    private featureFlagService: FeatureFlagService,
    private location: Location,
    private permissionService: PermissionsService,
  ) { }

  ngOnInit() {
    this.store.dispatch(actions.portfolioLiveOff());
    this.isIE = this.userAgentService.internetExplorer();

    this.userService.getUser().pipe(takeWhile(() => this.alive)).subscribe(user => {
      this.user = user;
      if (this.user.permissions.admin.allSites) {
        this.siteListService.getSiteList().subscribe(sites => {
          this.sites = sites.map(site => ({ id: site.siteID, name: site.siteName }));
        });
      } else {
        if (this.user.sites) {
          this.sites = this.user.sites.map(site => ({ id: site.siteID, name: site.siteName }));
        } else {
          this.sites = [];
        }
      }
    }); 
 

    this.store.dispatch(actions.getPortfolioCameras());
    this.cameraStreams$ = this.store.pipe(select(selectors.portfolioCameraStreams));
    this.isLive$ = this.store.pipe(select(selectors.portfolioIsLive));
    this.store.dispatch(actions.getCameraIntents());

    this.portfolioCameras$ = this.store.pipe(select(selectors.formattedPortfolioCameras));
    this.cameraIntents$ = this.store.pipe(select(selectors.cameraIntents));  
    this.siteFilter$ = this.store.pipe(select(selectors.siteFilter));
    this.intentFilter$ = this.store.pipe(select(selectors.intentFilter));
    this.nameFilter$ = this.store.pipe(select(selectors.nameFilter));
    this.sortOptions$ = this.store.pipe(select(selectors.sortOptions));
    this.selectedSortOption$ = this.store.pipe(select(selectors.selectedSortOption));
    this.groupByOptions$ = this.store.pipe(select(selectors.groupByOptions));
    this.selectedGroupByOption$ = this.store.pipe(select(selectors.selectedGroupByOption));
    this.cameraFooterCollapse$ = this.store.pipe(select(selectors.cameraFooterCollapse));
    this.cameraGridSize$ = this.store.pipe(select(selectors.cameraGridSize));
    this.cameraHeight = this.store.pipe(select(selectors.cameraHeight));
    this.appBarWidth$ = this.store.pipe(select(selectors.appBarWidth));
    this.scrollUpThreshold$ = this.store.pipe(select(selectors.scrollUpThreshold));
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      this.store.dispatch(actions.setFilters({ filters: {
        siteFilter: params.siteFilter ? typeof params.siteFilter === "string" ? [+params.siteFilter] :params.siteFilter.map(s => +s) : [],
        intentFilter: params.intentFilter ? typeof params.intentFilter === "string" ? [+params.intentFilter] : params.intentFilter.map(i => +i) : [],
        nameFilter: params.nameFilter ? params.nameFilter : '',
        selectedSortOption: params.sortOption ? params.sortOption : '',
        selectedGroupByOption: params.groupByOption ? params.groupByOption : '',
        cameraGridSize: params.cameraGridSize ? params.cameraGridSize : 'small',
      }}))
    });
    this.store.pipe(select(selectors.cameraFilters), takeWhile(() => this.alive)).subscribe((filters) =>{
      let params = new HttpParams().appendAll({
        siteFilter: filters.siteFilter,
        intentFilter: filters.intentFilter,
        nameFilter: filters.nameFilter,
        sortOption: filters.selectedSortOption,
        cameraGridSize: filters.cameraGridSize.small ? 'small' : filters.cameraGridSize.medium ? 'medium' : 'large',
        // groupByOption: filters.selectedGroupByOption,
      });
      this.location.replaceState(location.pathname, params.toString());
    });

    this.loadingCameras$ = this.store.pipe(select(selectors.loadingCameras));
   
    this.resize$.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.updateViewportWidth();
    });
    this.updateViewportWidth();
    this.featureFlagService.isFeatureEnabled$('multi-site-camera-live').pipe(takeWhile(() => this.alive)).subscribe((enabled) => {
      this.hasLiveFeature = enabled;
    });
    this.featureFlagService.isFeatureEnabled$('site-walk').pipe(takeWhile(() => this.alive)).subscribe((enabled) => {
      this.hasSiteWalkFeature = enabled;
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  public top() {
    this.virtualScroll.scrollToIndex(0, "smooth");
  }

  public motionSelected(camera: CameraLite) {
    this.router.navigate(["site", camera.siteID, "motion"], { queryParams: { cameraID: camera.id } });
  }

  public rewindSelected(camera: CameraLite) {
    this.router.navigate(['site', camera.siteID, 'cameras', camera.id, 'rewind']);
  }

  public cameraSelect(camera: CameraLite) {
    if (camera.permissions.hasLive) {
      this.router.navigate(['site', camera.siteID, 'cameras', camera.id, 'live-view']);
    } else {
      this.router.navigate(['site', camera.siteID, 'cameras', camera.id, 'rewind']);
    }
  }
  public onLive() {
    this.store.dispatch(actions.clickPortfolioLive());
  }

  public onWalk() {
    this.store.dispatch(actions.clickWalk());
  }

  public onSiteFilterChange(filter: number[]) {
    this.store.dispatch(actions.setSiteFilter({ filter: filter }));
  }

  public onIntentFilterChange(filter: number[]) {
    this.store.dispatch(actions.setIntentFilter({ filter: filter }));
  }

  public onNameFilterChange(filter: string) {
    this.store.dispatch(actions.setNameFilter({ filter: filter }));
  }

  public onClearFilters() {
    this.store.dispatch(actions.clearFilters());
  }

  public onSortOptionChange(option: string) {  
    this.store.dispatch(actions.setSelectedSortOption({ option: option }));
  }

  public onGroupByOptionChange(option: string) {
    this.store.dispatch(actions.setSelectedGroupByOption({ option: option }));
  }
  
  public onCameraFooterToggle(filter: boolean) {
    this.store.dispatch(actions.toggleCameraFooterToggle());
  }

  public onCameraGridSizeChange(gridSize: string) {
    this.store.dispatch(actions.setCameraGridSize({ gridSize: gridSize }));
  }

  public onCameraHeightChange(height: number) {
    this.store.dispatch(actions.setCameraHeight({ height: height }));
  }
  
  private updateViewportWidth() {
    const { width } = this.viewportRuler.getViewportSize();
    this.store.dispatch(actions.setViewportWidth({ width }));
  }

  public onAppBarWidthChange(width: number) {
    this.store.dispatch(actions.setAppBarWidth({ width }));
  }
}
