import { createAction, props } from "@ngrx/store";
import { AlertEntry } from '../manage-alert-entries/alert-entry'
import { Tag } from "../manage-alert-entries/tag";
import { AlertHit } from "../recent-hits/alert-hit";

export const noAction = createAction("[LPR] No Action");

export const setTopTab = createAction("[LPR] Set Top Tab", props<{tab: string}>());

export const setBottomTab = createAction("[LPR] Set Bottom Tab", props<{tab: string}>());

export const resetLPR = createAction("[LPR] Reset LPR");

export const setLPRSiteID = createAction("[LPR] Set LPR siteID", props<{siteID: number}>());

// Manage Alert Entries

export const loadAlertEntries = createAction("[LPR] Load Alert Entries", props<{siteID: number}>());

export const setAlertEntries = createAction("[LPR] Set Alert Entries", props<{alertEntries: AlertEntry[]}>());

export const appendAlertEntry = createAction("[LPR] Append alert entry to alert entries list", props<{entry: AlertEntry}>());

export const replaceAlertEntry = createAction("[LPR] Replace Alert Entry in alert entries list", props<{entry: AlertEntry}>());

export const setAlertEntriesSiteID = createAction("[LPR] Set Alert Entries Site ID", props<{siteID: number}>());

export const setShowRemoveAlertEntryModal = createAction("[LPR] Set Show Delete Alert Entry Modal", props<{show: boolean}>());

export const removeAlertEntry = createAction("[LPR] Delete Alert Entry");

export const setAlertEntryToRemove = createAction("[LPR] Set Alert Entry To Delete", props<{entry: AlertEntry}>());

export const manageAlertEntriesClickColumn = createAction("[LPR] Manage Alert Entries click column", props<{field: string}>());

// Add/Edit Alert Entry

export const saveAlertEntry = createAction("[LPR] Save Alert Entry", props<{alertEntry: AlertEntry}>());

export const updateAlertEntry = createAction("[LPR] Update Alert Entry", props<{entry: AlertEntry}>());

export const loadTags = createAction("[LPR] Load Tags");

export const setTags = createAction("[LPR] Set Tags", props<{tags: Tag[]}>());

export const setEditEntry = createAction("[LPR] Set Edit Alert Entry", props<{entry: AlertEntry}>());

export const setSuccess = createAction("[LPR] Set Alert Entry Success", props<{success: boolean}>());

export const setSaveDisabled = createAction("[LPR] Set Alert Entry Save Disabled", props<{saveDisabled: boolean}>());

// Recent Hits

export const recentHitsClickColumn = createAction("[LPR] Recent hits click column", props<{field: string}>());

export const recentHitsLoadHits = createAction("[LPR] Recent hits load hits", props<{siteID: number}>());

export const recentHitsSetHits = createAction("[LPR] Recent hits set hits", props<{hits: AlertEntry[]}>());

export const recentHitsReset = createAction("[LPR] Recent hits reset");

// Alert Details

export const alertDetailsClickColumn = createAction("[LPR] Alert details click column", props<{field: string}>());

export const alertDetailsLoadHits = createAction("[LPR] Alert details load hits", props<{alertID: number, siteID: number}>());

export const alertDetailsSetHits = createAction("[LPR] Alert details set hits", props<{hits: AlertHit[]}>());

export const alertDetailsReset = createAction("[LPR] Alert details reset");

export const alertDetailsSelectAlert = createAction("[LPR] Alert details select alert", props<{entry: AlertEntry}>())

export const alertDetailsSetSelectedAlert = createAction("[LPR] Alert details set selected alert", props<{entry: AlertEntry}>())


// Hit Modal

export const viewHitClicked = createAction("[LPR] View Alert Hit", props<{hit: AlertHit}>());

export const setViewHit = createAction("[LPR] Set hit to view", props<{hit: AlertHit}>());

export const showViewHitModal = createAction("[LPR] Show View Hit Modal", props<{show: boolean}>());