import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import 'rxjs';



@Injectable()
export class KeyboardService {
    public keysObservable: Subject<any>
    constructor() {
        this.keysObservable = new Subject<any>();
        let that = this;
        document.addEventListener('keyup', that, false);
    }
    handleEvent(event) {
        this.addkey(event);
    }
    public addkey(event) {
        this.keysObservable.next(event);
    }
    getKeys():Observable<any> {
        return this.keysObservable;
    }
}