import { Injectable } from '@angular/core';
import { HistoricalData } from './historical-data';
import { ShortRequest } from './short-request';
import { FilteredEventMap } from 'app/event-map/filtered-event-map';
import { MetricsService } from '../metrics.service';
import { SiteService } from 'app/site/site.service';
import { MarkerService } from 'app/markers/marker.service';
import { MapData } from 'app/map/map';
import { DateRange } from '../date-range';
import { BehaviorSubject, Observable } from 'rxjs';
import { CFTagCategory } from '../cf-tag-category';
import { HISTORICAL_START } from './historical-constants';

@Injectable({
  providedIn: 'root'
})
export class HistoricalService {
  private historical: HistoricalData;
  saved: HistoricalData[] = [];
  allRequests: ShortRequest[] = [];
  maps: FilteredEventMap[] = [];
  loadedRequests: boolean;
  loadingRequests: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadedMaps: boolean;
  mapData: MapData[] = [];
  cfTagCategories: CFTagCategory[] = [];
  private siteID: number;
  constructor(
    private metricsService: MetricsService, 
    private siteService: SiteService, 
    private markerService: MarkerService,
  ) { }

  getHistorical(): HistoricalData {
    if(!this.historical) {
      this.historical = new HistoricalData();
      this.siteService.getSite().subscribe(site => {
        this.siteID = site.siteID;
        if(this.historical && site.siteID === this.historical.siteID) {
          return;
        }

        this.historical = new HistoricalData();
        this.saved = [];
        this.historical.siteID = site.siteID
        this.loadedRequests = false;
        this.loadedMaps = false;
        this.metricsService.getMaps(site.siteID).subscribe(maps => {
          this.loadedMaps = true;
          this.mapData = maps;
          this.maps = maps.map(m => {
            let nMap = new FilteredEventMap(m);
            nMap.markersLocal = this.markerService.createMarkers(m.markers, nMap);

            return nMap;
          });

          this.historical.maps = this.maps;
        });

        this.loadingRequests.next(true);
        this.metricsService.getRequests(site.siteID, new Date(HISTORICAL_START)).subscribe(requests => {
          this.loadedRequests = true;
          this.loadingRequests.next(false);
          this.allRequests = requests;
          this.historical.setRequests(requests);
        }, e => {
          this.loadingRequests.next(false);
        });
        this.metricsService.getCFTagCategories().subscribe(cats => {
          this.cfTagCategories = cats;
          this.historical.setCategoryColors(cats);
        })
      });      
    }

    return this.historical;
  }

  getLoading(): Observable<boolean> {
    return this.loadingRequests;
  }
  
  save() {
    let hist =  this.historical.clone();
    hist.maps = this.mapData.map(m => {
      let nMap = new FilteredEventMap(m);
      nMap.markersLocal = this.markerService.createMarkers(m.markers, nMap);
      nMap.setEventIDs(hist.filteredRequests.map(r => r.eventID));

      return nMap;
    });

    this.saved.push(hist);
  }

  clear() {
    this.saved = [];
  }

  sort() {

  }

  remove(hist: HistoricalData) {
    this.saved = this.saved.filter(h => h !== hist);
  }

  generateYears() {
    if(this.allRequests.length === 0) {
      return;
    }

    let range = this.getStartEndFromRequests(this.allRequests);
    let dates = this.yearDatesForRange(range);
    this.saved = this.createHistoricalsForDates(dates);
  }

  generateQuarters() {
    if(this.allRequests.length === 0) {
      return;
    }

    let range = this.getStartEndFromRequests(this.allRequests);
    let dates = this.quarterDatesForRange(range);
    this.saved = this.createHistoricalsForDates(dates);
  }

  getStartEndFromRequests(requests: ShortRequest[]): DateRange {
    let start = null;
    let end = null;
    for(let i = 0; i < requests.length; i++) {
      if(!start || requests[i].requested.getTime() < start.getTime()) {
        start = requests[i].requested;
      }
      if(!end || requests[i].requested.getTime() > end.getTime()) {
        end = requests[i].requested;
      }
    }

    return { start: start, end: end };
  }

  createHistoricalsForDates(dates: DateRange[]): HistoricalData[] {
    let res = [];
    for(let i = 0; i < dates.length; i++) {
      let hist = this.createHistoricalForDates(dates[i]);
      res.push(hist);
    }

    return res;
  }

  createHistoricalForDates(dates: DateRange): HistoricalData {
    let hist = new HistoricalData();
    hist.setRequests(this.allRequests);
    hist.siteID = this.siteID;
    hist.title = dates.title;
    hist.maps = this.mapData.map(m => {
      let nMap = new FilteredEventMap(m);
      nMap.markersLocal = this.markerService.createMarkers(m.markers, nMap);
      nMap.setEventIDs(hist.requests.map(r => r.eventID));

      return nMap;
    });

    hist.setStart(dates.start);
    hist.setEnd(dates.end);
    hist.setCategoryColors(this.cfTagCategories);
    return hist;
  }

  yearDatesForRange(dates: DateRange): DateRange[] {
    let res = [];
    let start = new Date(dates.end);
    start.setMonth(0, 1);
    start.setHours(0,0,0,0);
    let end = new Date(dates.end);
    end.setMonth(11, 31)
    end.setHours(23,59,59,999);
    res.push({start: new Date(start), end: new Date(end), title: start.getFullYear() + ""});

    while(start.getTime() > dates.start.getTime()) {
      start.setFullYear(start.getFullYear() - 1);
      end.setFullYear(end.getFullYear() - 1);
      res.push({start: new Date(start), end: new Date(end), title: start.getFullYear() + ""});
    }

    return res;
  }

  quarterDatesForRange(dates: DateRange): DateRange[] {
    let res = [];
    let start = new Date(dates.end);
    let quarter = Math.floor(start.getMonth() / 3);
    start.setMonth(quarter * 3, 1);
    start.setHours(0,0,0,0);
    let end = new Date(dates.end);
    end.setMonth((quarter + 1) * 3, 1);
    end.setHours(0,0,0,-1);
    res.push({start: new Date(start), end: new Date(end), title: "Quarter: " + (quarter + 1) + ", " + start.getFullYear()});

    while (start.getTime() > dates.start.getTime()) {
      if (quarter > 0) {
        quarter--;
      } else {
        quarter = 3;
        start.setFullYear(start.getFullYear() - 1);
        end.setFullYear(end.getFullYear() - 1);
      }

      start.setMonth(quarter * 3, 1);
      start.setHours(0,0,0,0);
      end.setMonth((quarter + 1) * 3, 1);
      end.setHours(0,0,0,-1);
      res.push({start: new Date(start), end: new Date(end), title: "Quarter: " + (quarter + 1) + ", " + start.getFullYear()});
    }

    return res;
  }
}
