
import {map, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../app.state';


@Injectable()
export class AccessKeyService {
    constructor(public store: Store<AppState>) {}
    getKey() {
        return this.store.select(s => s.user).pipe(filter(user => !user.initial),map(user => user.accessKey),);
    }
}