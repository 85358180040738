export class MessageRequest {
    id: number;
    text: string;
    subject: string;
    target: string;
    createdBy: number;
    createdByUsername: string;
    createdDate: Date;
    expDate: Date;
    values: number[] = [];
    importanceLevel: number;
    hardTypeWitlist: number[] = [];
    softTypeWitlist: number[] = [];
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config);
            if(config.createdDate) {
                this.createdDate = new Date(config.createdDate)
            }
            if(config.expDate) {
                this.expDate = new Date(config.expDate);
            }
        }
    }
}