// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cvsView {
  height: 100%;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

kendo-tilelayout {
  height: 100%;
  width: 100%;
}

kendo-tilelayout-item-body {
  width: 100%;
  height: 100%;
}

camera-view {
  width: 100%;
  height: 100%;
}

app-hybrid-player {
  width: 100%;
  height: 100%;
}

.k-card-body {
  padding: 0;
}

.cvs-disabled-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.message {
  margin: auto;
  width: 400px;
  text-align: left;
  background: #FFCCCC;
  border: 1px solid red;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/cvs/cvs-view/cvs-view.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AACJ;;AAGA;EACI,YAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,qBAAA;EACA,aAAA;AAAJ","sourcesContent":[".cvsView {\r\n    height: 100%;\r\n    width: 100%;\r\n}\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n\r\nkendo-tilelayout { \r\n    height: 100%;\r\n    width: 100%;\r\n}\r\n\r\nkendo-tilelayout-item-body {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\ncamera-view {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\napp-hybrid-player {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.k-card-body {\r\n    padding: 0;\r\n}\r\n\r\n.cvs-disabled-container {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100%;\r\n}\r\n\r\n\r\n.message {\r\n    margin: auto;\r\n    width: 400px;\r\n    text-align: left;\r\n    background: #FFCCCC;\r\n    border: 1px solid red;\r\n    padding: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
