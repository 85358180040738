import { State as GridState } from '@progress/kendo-data-query';
import { ManageSiteItem } from '../../../sites/manage-site-item'

export const adminSiteManagementKey = "adminSiteManagement"

export interface State {
    siteList: ManageSiteItem[],
    siteListGridState: GridState,
}

export const initialState: State = {
    siteList: [],
    siteListGridState: {skip: 0, take: 50},
}