import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromMessagesState from './store/state';
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { messagesReducer } from "./store/reducers";
import { messagesEffects } from "./store/effects"

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromMessagesState.messagesKey, messagesReducer),
    EffectsModule.forFeature([messagesEffects]),
  ],
  providers: [
    messagesEffects,
],
})
export class MessagesModule { }
