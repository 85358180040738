// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-form-sites-multiselect-item {
    display: flex;
    vertical-align: middle;
    align-items: center;
    gap: 0.25rem;
}

.account-form-sites-multiselect-item span {
    font-size: 16px;
}

.account-form-sites-multiselect-item span.allow-le-access {
    color: #4CAF50;
}

.account-form-sites-multiselect-item span.disallow-le-access {
    color: #F44336;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/account-form/account-form.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".account-form-sites-multiselect-item {\r\n    display: flex;\r\n    vertical-align: middle;\r\n    align-items: center;\r\n    gap: 0.25rem;\r\n}\r\n\r\n.account-form-sites-multiselect-item span {\r\n    font-size: 16px;\r\n}\r\n\r\n.account-form-sites-multiselect-item span.allow-le-access {\r\n    color: #4CAF50;\r\n}\r\n\r\n.account-form-sites-multiselect-item span.disallow-le-access {\r\n    color: #F44336;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
