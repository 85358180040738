import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceHelpersService } from '../../service-helpers.service';
import { Observable } from 'rxjs';
import { AccessKeyService } from '../../users/access-key.service';
import { map, catchError } from 'rxjs/operators';
import { PermissionTemplate } from './permission-template'
import { config } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class AdminPermissionTemplatesService {
  public headers = {};

  constructor(
    private http: HttpClient,
    private serviceHelpersService: ServiceHelpersService,
    private accessKeyService: AccessKeyService,   
  ) {
    this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key}));
  }

  getPermissionTemplates(): Observable<PermissionTemplate[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<PermissionTemplate[]>(config.apiUrl + "admin/permissiontemplates", {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
  }

  checkPermissionTemplateForUsers(id: number): Observable<any> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<any>(config.apiUrl + `admin/permissiontemplate/${id}/checkforusers`, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
  }

  deletePermissionTemplate(id: number) {
    let headers = new HttpHeaders(this.headers);
    return this.http.delete(config.apiUrl + `admin/permissiontemplate/${id}`, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
  }

  createPermissionTemplate(template: PermissionTemplate): Observable<PermissionTemplate> {
    let headers = new HttpHeaders(this.headers);
    return this.http.post<PermissionTemplate>(config.apiUrl + `admin/permissiontemplate`, template, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
  }

  updatePermissionTemplate(template: PermissionTemplate): Observable<PermissionTemplate> {
    let headers = new HttpHeaders(this.headers);
    return this.http.put<PermissionTemplate>(config.apiUrl + `admin/permissiontemplate`, template, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError));
  }

}
