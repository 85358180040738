import * as fromExtendedStorageState from './state';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { GridDataResult } from "@progress/kendo-angular-grid";
import { PortfolioExtendedStorageComponent } from '../portfolio-extended-storage/portfolio-extended-storage.component';
import { ByosSite } from '../byosSite';
import { process } from '@progress/kendo-data-query';
import { Lookup } from 'app/lookup';
import { LicenseCount } from '../license-count';

export const selectByos = createFeatureSelector<fromExtendedStorageState.State>(fromExtendedStorageState.byosKey);

export const rawSites = createSelector(
    selectByos,
    (byos => byos.sites),
);

export const sites = createSelector(
    rawSites,
    (sites => sites.filter(site => site.isByos).map(site => ({

        property: site?.name,
        accountName: site?.byosBucket?.accountName,
        provider: site?.byosBucket?.vendor?.name,
        providerID: site?.byosBucket?.vendorID,
        description: site?.byosBucket?.description,
        bucketID: site?.bucketID,
        bucketName: site?.byosBucket?.bucketName,
        siteID: site?.id,
        isByos: site?.isByos,
    })).sort((a, b) => a.property.localeCompare(b.property)))
);

export const sitesUnlicensed = createSelector(
    rawSites,
    (sites => sites.filter(site => !site.isByos).map(site => ({
        property: site?.name,
        accountName: site?.byosBucket?.accountName,
        provider: site?.byosBucket?.vendor?.name,
        providerID: site?.byosBucket?.vendorID,
        description: site?.byosBucket?.description,
        bucketID: site?.bucketID,
        bucketName: site?.byosBucket?.bucketName,
        siteID: site?.id,
    })).sort((a, b) => a.property.localeCompare(b.property)))
);

export const byosGridState = createSelector(
    selectByos,
    (byos => byos.byosGridState),
);

export const byosUnlicensedGridState = createSelector(
    selectByos,
    (byos => byos.byosUnlicensedGridState),
);

export const textFilter = createSelector(
    selectByos,
    (byos => byos.textFilter),
);

export const selectedEnabledOption = createSelector(
    selectByos,
    (byos => byos.selectedEnabledOption),
);

export const filteredSites = createSelector(
    sites,
    selectedEnabledOption,
    (sites, option) => {

        if (option === "All") {
            return sites;
        }
        else if (option === "Storage Enabled") {
            return sites.filter(site => site.bucketID);
        }
        else (option === "Storage Disabled")
        {
            return sites.filter(site => !site.bucketID);
        }
});


export const byosGridView = createSelector(
    filteredSites,
    byosGridState,
    textFilter,
    (sites, byosGridState, textFilter) => {
        if (textFilter) {
            sites = sites.filter(site => site.property.toLowerCase().includes(textFilter.toLowerCase()));
        }
        return process(sites, byosGridState);
    }
);

export const byosUnlicensedGridView = createSelector(
    sitesUnlicensed,
    byosUnlicensedGridState,
    (sites, byosUnlicensedGridState) => { 
        return process(sites, byosUnlicensedGridState);
    }
);

export const siteIDBeingConfigured = createSelector(
    selectByos,
    (byos => byos.siteIDBeingConfigured),
);

export const siteStorageBeingConfigured = createSelector(
    sites,
    siteIDBeingConfigured,
    ((sites, siteIDBeingConfigured) => {
        return sites.find(site => site.siteID === siteIDBeingConfigured);
    }),
);

export const showConfigureStorage = createSelector(
    siteStorageBeingConfigured,
    selectByos,
    (siteStorageBeingConfigure, byos )=> 
        {
            if (!siteStorageBeingConfigure) {
                return false;
            }
            return byos.showConfigureStorage;
        }
);

export const enabledOptions = createSelector(
    selectByos,
    (byos => byos.enabledOptions),
);

export const byosSort = createSelector(
    selectByos,
    (byos => byos.byosSort),
);

export const showChangeStorage = createSelector(
    selectByos,
    (byos => byos.showChangeStorage),
);

export const changeStorage = createSelector(
    selectByos,
    (byos => byos.showChangeStorage),
);



export const accounts = createSelector(
    sites,
    (sites => {
        let accounts: Lookup[] = [];
        sites.forEach(site => {
            if (site.accountName && site.bucketID !== 0) {
                let account = accounts.find(acc => acc.id === site.bucketID);
                if (account === undefined) {
                    accounts.push({ id: site.bucketID, name: site.accountName });
                }
            }
        })
        return accounts;
    }),
);

export const vendors = createSelector(
    selectByos,
    (byos => byos.vendors),
);

export const buckets = createSelector(
    selectByos,
    (byos => byos.buckets),
);

export const account = createSelector(
    selectByos,
    (byos => byos.accounts),
);

export const unassignStorage = createSelector(
    selectByos,
    (byos => byos.unassignStorage),
);

export const showUnassignStorageDialog = createSelector(
    selectByos,
    (byos => byos.showUnassignStorageDialog),
);

export const unlicensedSiteSelections = createSelector(
    selectByos,
    (byos => byos.unlicensedSiteSelections),
);

export const licenseCount = createSelector(
    sites,
    sitesUnlicensed,
    (sitesWithLicense, sitesWithoutLicense) => {
        let licenseCount: LicenseCount = {
            sitesWithLicense: 0,
            sitesWithoutLicense: 0
        };
        licenseCount.sitesWithLicense = sitesWithLicense.length;
        licenseCount.sitesWithoutLicense = sitesWithoutLicense.length;
        return licenseCount;
    }
);

export const showChangeStorageConfirmDialog = createSelector(
    selectByos,
    (byos => byos.showChangeStorageConfirmDialog),
);

export const showInquiryConfirmation = createSelector(
    selectByos,
    (byos => byos.showInquiryConfirmation),
);

export const inquirySelectedSites = createSelector(
    sitesUnlicensed,
    unlicensedSiteSelections,
    ((sites, selections) => {
        let selectedSites: string[] = [];
        selections.forEach(selection => {
            let site = sites.find(site => site.siteID === selection);
            selectedSites.push(site.property);
        });
        return selectedSites;
    }),
);
