// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-message-dialog {
    min-width: 100vw;
}

.new-message-dialog .new-message-dialog-title {
    white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/app/messages/new-messages/new-messages.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".new-message-dialog {\r\n    min-width: 100vw;\r\n}\r\n\r\n.new-message-dialog .new-message-dialog-title {\r\n    white-space: pre-wrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
