import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Site } from 'app/site/site';
import { Map } from 'app/map/map';

@Component({
  selector: 'app-add-cams-modal',
  templateUrl: './add-cams-modal.component.html',
  styleUrls: ['./add-cams-modal.component.css']
})
export class AddCamsModalComponent implements OnInit {

  @Input() numCams: number;
  @Input() show: boolean = false;
  @Input() maps: Map[] = [];
  @Input() mapsWithCamIDs: number[] = [];
  @Input() selectedMapID: number;
  @Input() maxCameraModalVisible: boolean;
  @Output("cancel") cancelEmitter = new EventEmitter<any>();
  @Output("save") saveEmitter = new EventEmitter<any>();
  @Output("maxCameraModalClose") closeMaxCameraModalEmitter = new EventEmitter<any>();

  

  constructor() { }

  ngOnInit() { }

  save() {
    this.saveEmitter.emit();
  }
  cancel() {
    this.cancelEmitter.emit();
  }

  maxCameraModalClose() {
    this.closeMaxCameraModalEmitter.emit();
   }


}
