import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/app.state';
import * as LPRActions from '../store/actions';
import {
  selectShowRemoveAlertEntryModal,
  selectAlertEntriesSorted,
  selectAlertEntriesSort
} from '../store/selectors';
import { AlertEntry } from './alert-entry'
import { SortData } from '../sort-data';

@Component({
  selector: 'app-manage-alert-entries',
  templateUrl: './manage-alert-entries.component.html',
  styleUrls: ['./manage-alert-entries.component.scss','../lpr-table-styles.scss']
})
export class ManageAlertEntriesComponent implements OnInit, OnDestroy {
  @Input('siteID') set setSiteID(value: number) {
    this.store.dispatch(LPRActions.loadAlertEntries({siteID: value}));
  }
  @Input('canManageLPR') canManageLPR: boolean;

  public alertEntries$: Observable<AlertEntry[]>;
  public alertEntriesSort$: Observable<SortData>;
  public showRemoveAlertEntryModal$: Observable<boolean>;
  public alertEntryToDelete$: Observable<AlertEntry>;

  public sort: SortData;
  public alive : boolean = true;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.alertEntries$ = this.store.pipe(select(selectAlertEntriesSorted));
    this.alertEntriesSort$ = this.store.pipe(select(selectAlertEntriesSort));

    this.showRemoveAlertEntryModal$ = this.store.pipe(select(selectShowRemoveAlertEntryModal));
  
    this.alertEntriesSort$.pipe(takeWhile(() => this.alive)).subscribe(sort => {
      this.sort = sort;
    });
  
  }

  ngOnDestroy() {
    this.alive = false;
  }

  editAlert(entry: AlertEntry) {
    this.store.dispatch(LPRActions.setEditEntry({entry: entry}));
    this.store.dispatch(LPRActions.setBottomTab({tab: "addAlert"}));
  }

  removeAlert(entry: AlertEntry) {
    this.store.dispatch(LPRActions.setAlertEntryToRemove({entry: entry}));
    this.store.dispatch(LPRActions.setShowRemoveAlertEntryModal({show: true}));
  }

  confirmRemoveAlertEntry() {
    this.store.dispatch(LPRActions.removeAlertEntry());
  }

  closeRemoveAlertEntryModal() {
    this.store.dispatch(LPRActions.setAlertEntryToRemove({entry: null}));
    this.store.dispatch(LPRActions.setShowRemoveAlertEntryModal({show: false}));
  }

  public onColumnClick(field: string) {
    console.log("Column click:", field);
    this.store.dispatch(LPRActions.manageAlertEntriesClickColumn({field: field}));
  }

}
