import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccessKeyService } from '../../users/access-key.service';
import { ServiceHelpersService } from "app/service-helpers.service";
import { ByosBackfillSite } from "./byos-backfill-site";
import { BackfillRequest } from "./backfill-request";
import { ByosBackfillJob } from "../../site/byos-backfill-job";



@Injectable({
    providedIn: 'root'
})

export class AdminExtendedStorageService {
    public headers = {};

    constructor(public http: HttpClient, public accessKeyService: AccessKeyService, public serviceHelpers: ServiceHelpersService) {
        this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key }));
    }
    
    getByosSites(): Observable<ByosBackfillSite[]> {
        let headers = new HttpHeaders(this.headers);        
        return this.http.get<ByosBackfillSite[]>("/api/byos/backfill/sites", { headers: headers }).pipe(catchError(this.serviceHelpers.handleError));
    }

    backfillSite(backfillRequest: BackfillRequest): Observable<ByosBackfillJob> {
        let headers = new HttpHeaders(this.headers);
        return this.http.post<ByosBackfillJob>(`/api/byos/backfill`, backfillRequest, { headers: headers }).pipe(catchError(this.serviceHelpers.handleError));
    }

}