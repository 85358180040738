import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CameraService } from './camera.service';
import { SiteService } from '../site/site.service';
import { Site } from '../site/site';
import { UserService } from '../users/user.service';
import { User } from '../users/user';
import { PermissionsService } from '../permissions.service';
import { UserAgentService } from 'app/user-agent.service';
import { CameraLite } from './camera-lite';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.css'],
})
export class CameraComponent implements OnInit, OnDestroy {

    @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

    public site: Site;
    public siteSub;
    public cameras: CameraLite[];
    public userSub;
    public user: User;
    public isIE: boolean;

    constructor(
        public router: Router, 
        public cameraService: CameraService, 
        public siteService: SiteService, 
        public userService: UserService, 
        public permissions: PermissionsService,
        public userAgentService: UserAgentService,
    ) {}
    
    public cameraSelect(camera: CameraLite) {
        if(this.permissions.site(this.site.siteID, 'live')) {
            this.router.navigate(['site', this.site.siteID, 'cameras', camera.id, 'live-view']);
        } else {
            this.router.navigate(['site', this.site.siteID, 'cameras', camera.id, 'rewind']);
        }
    }

    public rewindSelected(camera: CameraLite) {
        this.router.navigate(['site', this.site.siteID, 'cameras', camera.id, 'rewind']);         
    }
 
    public motionSelected(camera: CameraLite) {     
        this.router.navigate(["site", this.site.siteID, "motion"], {queryParams: {cameraID: camera.id}});
    }

    public ngOnInit() {    
        this.isIE = this.userAgentService.internetExplorer();

        this.siteSub = this.siteService.getSite().subscribe(site => {
            this.site = site
            this.cameraService.getCamerasLite(this.site.siteID).subscribe(cameras => this.cameras = cameras);
        });

        this.userSub = this.userService.getUser().subscribe( user => {
            this.user = user;
        });
    }

    public ngOnDestroy() {
        if(this.siteSub) {
            this.siteSub.unsubscribe();
        }

        if(this.userSub) {
            this.userSub.unsubscribe();
        }
    }

    public top() {
        this.virtualScroll.scrollToIndex(0, "smooth");
    }
    
}