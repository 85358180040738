import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromAdminLPRState from './state';
import { process } from '@progress/kendo-data-query';

export const selectAdminLPR = createFeatureSelector<fromAdminLPRState.State>(fromAdminLPRState.adminLPRKey);

// Alert Entries Tab
export const selectAlertEntriesGridState = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.alertEntriesGridState),
);

export const selectAlertEntriesLoading = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.alertEntriesLoading),
);

export const selectAlertEntriesGridData = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.alertEntries),
);

export const selectAlertEntriesGridView = createSelector(
    selectAlertEntriesGridData,
    selectAlertEntriesGridState,
    ((data, state) => process(data, state)),
);

export const selectSelectedAlertEntry = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.selectedAlertEntry),
)

export const selectAlertEntryHitsGridState = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.alertEntryHitsGridState),
);

export const selectAlertEntryHitsLoading = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.alertEntryHitsLoading),
);

export const selectAlertEntryHitsGridData = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.alertEntryHits),
);

export const selectAlertEntryHitsGridView = createSelector(
    selectAlertEntryHitsGridData,
    selectAlertEntryHitsGridState,
    ((data, state) => process(data, state)),
);

export const selectShowLprImageModal = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.showLprImageModal),
);

export const selectShowRequestModal = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.showRequestModal),
);

export const selectShowAddCamsModal = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.showAddCamsModal),
);

// Alert Hits Tab
export const selectSelectedEntriesHitsTab = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.selectedEntriesHitsTab),
);

export const selectAlertHitsLoading = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.alertHitsLoading),
);

export const selectAlertHitsGridState = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.alertHitsGridState),
);

export const selectAlertHitsGridData = createSelector(
    selectAdminLPR,
    (adminLPR => adminLPR.alertHits),
);

export const selectAlertHitsGridView = createSelector(
    selectAlertHitsGridData,
    selectAlertHitsGridState,
    ((data, state) => process(data, state)),
);