import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceHelpersService } from 'app/service-helpers.service';
import { AccessKeyService } from 'app/users/access-key.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertEntry } from '../manage-alert-entries/alert-entry';

@Injectable({
  providedIn: 'root'
})
export class RecentHitsService {
  public headers = {};
  constructor(
    private http: HttpClient,
    private helper: ServiceHelpersService,
    private accessKeyService: AccessKeyService,
  ) { 
    this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key }));
  }
  getEntriesWithRecentHits(siteID: number): Observable<AlertEntry[]> {
    return this.http.get<AlertEntry[]>(`/rest/site/${siteID}/lpr/alertentrieswithrecenthits`)
      .pipe(map(hits => {
        if(hits && hits.map) {
          return hits.map(h => new AlertEntry(h));
        }
        return hits;
      }));
  }
}
