import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import { CameraService } from '../camera.service';
import { Camera } from '../camera';
import { SiteService } from '../../site/site.service';
import { Site } from '../../site/site';
import { UserAgentService } from '../../user-agent.service';
import { UserService } from '../../users/user.service';
import { User } from '../../users/user';
import { PermissionsService } from '../../permissions.service';
import { Logger } from '../../logger/logger.service';

@Component({
    selector: 'app-live-view',
    templateUrl: './live-view.component.html',
    styleUrls: ['./live-view.component.css'],
})
export class LiveViewComponent implements OnInit, OnDestroy {
    public cameraID: number;
    public camera: Camera;
    public routeSub;
    public siteSub;
    public site: Site;
    public siteID;
    public mobile: boolean;
    public user: User;
    public userSub;
    public isIE: boolean = false;
    public isSafari: boolean = false;
    public mediaSourceExtensions: boolean = false;

    constructor(
        public route: ActivatedRoute, 
        public cameraService: CameraService, 
        public siteService: SiteService, 
        public userAgentService: UserAgentService, 
        public userService: UserService, 
        public permissions: PermissionsService,
        public logger: Logger
    ) {}

    ngOnInit() {
        this.siteSub = this.siteService.getSite().subscribe(site => {
            this.siteID = site.siteID;
        });

        this.routeSub = this.route.params.subscribe(params => {
            this.cameraID = +params['cameraID'];
            this.cameraService.getCamera(this.cameraID, this.siteID).subscribe(camera => this.camera = camera);
        });

        this.userSub = this.userService.getUser().subscribe(user => this.user = user);
        this.mobile = this.userAgentService.mobile();
        this.isIE = this.userAgentService.internetExplorer();
        this.isSafari = this.userAgentService.safari();
        this.mediaSourceExtensions = !!window["MediaSource"];

        this.logger.trackAction(6, this.siteID).subscribe();
    }

    ngOnDestroy() {
        if(this.routeSub) {
            this.routeSub.unsubscribe();
        }
        if(this.siteSub) {
            this.siteSub.unsubscribe();
        }
        if(this.userSub) {
            this.userSub.unsubscribe();
        }
    }
}