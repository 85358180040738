// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewContainer {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    margin-top: 10px;
}

.browser-warning {
    color: #cc1818;
    padding-bottom: 5px;
    text-align: center;
}

.btn {
    color: #fff;
    margin-top: 10px;
}

h3 {
    font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/camera/live-view/live-view.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".viewContainer {\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    max-width: 1280px;\r\n    margin-top: 10px;\r\n}\r\n\r\n.browser-warning {\r\n    color: #cc1818;\r\n    padding-bottom: 5px;\r\n    text-align: center;\r\n}\r\n\r\n.btn {\r\n    color: #fff;\r\n    margin-top: 10px;\r\n}\r\n\r\nh3 {\r\n    font-size: 1.5rem;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
