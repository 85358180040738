// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player-container {
  text-align: center;
}

.cloud-player {
  display: inline-block;
  margin: 0 auto;
  clear: both;
}

.image-carousel {
  display: inline-block;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.divider {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: black;
  margin-top: 10px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/cloud/cloud-player/cloud-player-modal/cloud-player-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,qBAAA;EACA,cAAA;EACA,WAAA;AACF;;AAEA;EACE,qBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,6BAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;AACF","sourcesContent":[".player-container {\r\n  text-align: center;\r\n}\r\n\r\n.cloud-player {\r\n  display: inline-block;\r\n  margin:0 auto;\r\n  clear: both;\r\n}\r\n\r\n.image-carousel {\r\n  display: inline-block;\r\n  margin:0 auto;\r\n  margin-top: 10px;\r\n  margin-bottom:10px;\r\n}\r\n\r\n.divider {\r\n  border-width: 1px 0px 0px 0px;\r\n  border-style: solid;\r\n  border-color: black;\r\n  margin-top: 10px;\r\n  width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
