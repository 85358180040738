import { EventSynopsis } from "../event-synopsis/event-synopsis";

export const aiKey = "ai";

export interface State {
    eventSynopsis: EventSynopsis,
    eventSynopsisList: EventSynopsis[],
    eventSynopsisLoading: boolean,
    eventSynopsisRequestId: string | null,
    showEventSynopsisModal: boolean,
}

export const initialState: State = {
    eventSynopsis: null,
    eventSynopsisList: [],
    eventSynopsisLoading: false,
    eventSynopsisRequestId: null,
    showEventSynopsisModal: false,
}
