import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserInfo } from '../user-info';
import { Lookup } from 'app/lookup';
import { booleanSiteFields } from 'app/permissions';
import { DocType } from 'app/admin/admin-documents/doc-type/doc-type';

@Component({
  selector: 'admin-bulk-perm-form',
  templateUrl: './admin-bulk-perm-form.component.html',
  styleUrls: ['./admin-bulk-perm-form.component.css']
})
export class AdminBulkPermFormComponent implements OnInit {
  @Input() users: UserInfo[];
  @Input() sites: Lookup[];
  @Input() docTypes: DocType[];
  @Input() action: string;
  @Input() newPermissions: {site: any, document: any} = { site: {}, document: {} };
  @Input() siteClude: "exclude" | "include";
  @Input() selectedSites: number[];

  @Output('onActionChange') onActionChangeEmitter = new EventEmitter<string>();
  @Output('onSitesChange') onSitesChangeEmitter = new EventEmitter<number[]>();
  @Output('onSiteCludeChange') onSiteCludeChangeEmitter = new EventEmitter<string>();
  @Output('onNewPermissionsChange') onNewPermissionsChangeEmitter = new EventEmitter<{site: any, document: any}>();
  
  public siteFields = booleanSiteFields;
  public actions = [
    { name:"set", desc: "Replace any permissions the user has with those selected" },
    { name:"add", desc: "Add the seleted permissions to any the user already had" },
    { name:"remove", desc: "Remove the selected permissions from any the user has" },
  ];



  constructor() { }

  ngOnInit() {
  }
  onSiteCludeChange(selected: string) {
    this.onSiteCludeChangeEmitter.emit(selected);
  }
  onSitesChange(sites: number[]) {
    this.onSitesChangeEmitter.emit(sites);
  }
  onActionChange(action: string) {
    this.onActionChangeEmitter.emit(action);
  }
  onNewPermissionsChange() {
    this.onNewPermissionsChangeEmitter.emit(this.newPermissions);
  }
}
