// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k-appbar-section > * {
    margin: 0px 6px 0px 6px;
}

/* .button-container {
    margin-right: 15px;
} */`, "",{"version":3,"sources":["webpack://./src/app/extended-storage/extended-storage-filter/extended-storage-filter.component.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;;GAEG","sourcesContent":[".k-appbar-section > * {\r\n    margin: 0px 6px 0px 6px;\r\n}\r\n\r\n/* .button-container {\r\n    margin-right: 15px;\r\n} */"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
