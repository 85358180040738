import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
} from "@angular/forms";
import { DocumentFile } from "app/document/document-file";

@Component({
  selector: "app-doc-edit-modal",
  templateUrl: "./doc-edit-modal.component.html",
  styleUrls: ["./doc-edit-modal.component.css"]
})
export class DocEditModalComponent {
  @Input() set model(fileInfo: DocumentFile) {
    this.active = fileInfo !== undefined;
    this.file = fileInfo;

    if (this.active) {
      this.loadForm();
    }
  }
  
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  @Output() save: EventEmitter<any> = new EventEmitter();

  public docEditForm: UntypedFormGroup;
  public active: boolean = false;
  public file: DocumentFile;

  constructor(private fb: UntypedFormBuilder) {}

  onSave(event: Event): void {
    event.preventDefault();
    this.save.emit(this.docEditForm.value);
    this.active = false;
  }

  onCancel(event: Event): void {
    event.preventDefault();
    this.closeForm();
  }

  closeForm(): void {
    this.docEditForm.reset();
    this.active = false;
    this.cancel.emit();    
  }

  loadForm() {
    this.docEditForm = this.fb.group({
      displayName: [this.file.displayName, [Validators.required]],
      start: [new Date(this.file.startDisplayDate), [Validators.required]],
      end: [new Date(this.file.endDisplayDate), [Validators.required]]
    });
  }
}
