import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { AuthFacade } from '../auth.facade';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  public fullYear: string;

  constructor(
    public authFacade: AuthFacade,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.fullYear = new Date().getFullYear().toString();
    this.authFacade.isAuthenticated$
      .pipe(take(1))
      .subscribe((isAuthenticated) => {
        if (!isAuthenticated) {
          this.authService.showLogin();
        }
      });
  }
}