// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
    resize: none;
    display: block;
    width: 100%;
    border-radius: 4px;
}

label {
    width: 100%;
    font-weight: bold;
}

.required {
    display: inline;
    margin-left: 10px;
    color: red;
}

/* .form-check-input::after {
    background-color: blue;
} */

.cancel-btn {
    background-color: #fff;
    border-color: #ccc;
    border: 1px solid #000;
    margin: 0 0 0 10px;
}

.cancel-btn:hover {
    background-color: #ccc;
    border: 1px solid #000;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-messages/message-form/message-form.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,UAAU;AACd;;AAEA;;GAEG;;AAEH;IACI,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,sBAAsB;AAC1B","sourcesContent":["textarea {\r\n    resize: none;\r\n    display: block;\r\n    width: 100%;\r\n    border-radius: 4px;\r\n}\r\n\r\nlabel {\r\n    width: 100%;\r\n    font-weight: bold;\r\n}\r\n\r\n.required {\r\n    display: inline;\r\n    margin-left: 10px;\r\n    color: red;\r\n}\r\n\r\n/* .form-check-input::after {\r\n    background-color: blue;\r\n} */\r\n\r\n.cancel-btn {\r\n    background-color: #fff;\r\n    border-color: #ccc;\r\n    border: 1px solid #000;\r\n    margin: 0 0 0 10px;\r\n}\r\n\r\n.cancel-btn:hover {\r\n    background-color: #ccc;\r\n    border: 1px solid #000;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
