import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from './state';
import * as messagesActions from './actions';

const reducer = createReducer(
    initialState,
    on(messagesActions.setUserMessages, (state: State, {userMessages}) => {
        return {
            ...state,
            userMessages: userMessages,
        }
    })

)

export function messagesReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}

