
import { Where } from './new-request/where/where';
import { When } from './new-request/when/when';
import { What } from './new-request/what/what';
import { Cameras } from './new-request/cameras/cameras';
import { ContactInfo } from './new-request/contact-info/contact-info';
import { Confirmation } from './new-request/confirmation/confirmation';

export class Request {
    constructor() {
        this.when = new When();
        this.where = new Where();
        this.cameras = new Cameras();
        this.contactInfo = new ContactInfo();
        this.confirmation = new Confirmation();
    }

    public isAutoRequest = false;
    public requestType: number = 1;
    public siteID: number;
    public confidential = false;
    public when: When;
    public where: Where;
    public cameras: Cameras;
    public contactInfo: ContactInfo;
    public confirmation: Confirmation;
    public image: string;
    public primaryCameraID: number;
    public submitted: boolean = false;
    public valid() {
        return this.when.valid().valid && this.where.valid().valid && this.cameras.isValid && this.contactInfo.valid().valid && this.confirmation.valid().valid;
    }
}




