import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Summary, SummaryFile, AutoRequestFile } from 'app/events/summary';
import { EventService } from 'app/events/event.service';
import { PRODUCT_RETENTION_LENGTH, REVIEW_PHONE_NUMBER } from '../../../constants';
import { Router } from '@angular/router';


@Component({
   selector: 'app-summary',
   templateUrl: './summary.component.html',
   styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
   @ViewChild('share2') share;
   @ViewChild("arVideoPlayer") arVideoPlayer;
   @ViewChild('regVideoPlayer') regVideoPlayer;
   @ViewChild("downloadAll") downloadAll;
   @Input('summary') set setSummary(value: Summary) {
      this.summary = value;
      if (value.uploadedFiles && value.uploadedFiles.length > 0) {
         let expiredDate = new Date();
         expiredDate.setDate(expiredDate.getDate() - PRODUCT_RETENTION_LENGTH);
         this.isExpired = this.summary.reviewedDate.getTime() < expiredDate.getTime();

         if (!this.isExpired) {
         this.selectedFile = value.uploadedFiles[0];
         this.uploadedFiles = value.uploadedFiles;
         this.checkVideo();
         } else {
            this.uploadedFiles = value.uploadedFiles.filter(f => f.type === 1)
            if (this.uploadedFiles.length > 0) {
               this.selectedFile = this.uploadedFiles[0];
               this.checkVideo();
            }
         }
      }
     
      if (value.autoRequestFiles.length < 1) {
         this.noAutoRequestFiles = true;
      } else {
         this.noAutoRequestFiles = false;
      }


      let autoRequestFiles = value.autoRequestFiles.filter(function (file) {
         return file.isPurged !== true;
      });

      if (autoRequestFiles.length > 0) {
         this.eventID = autoRequestFiles[0].eventID;
         this.translateAutoRequestFiles(value.autoRequestFiles);
      }
      
      if (!this.noAutoRequestFiles && autoRequestFiles.length < 1) {
         this.autoRequestFilesExpired = true;
      }

   };

   @Input('isAutoRequest') set setIsAutoRequest(value: boolean) {
      this.isAutoRequest = value;
   }
   @Input('vidTime') set  setVidTime(v: number) {
      this.applyVidTime(v);
   };

   @Output('share') shareEmitter = new EventEmitter<any>();

   @Output('playVideo') playVideoEmitter = new EventEmitter<string>();
   @Output('stopVideo') stopVideoEmitter = new EventEmitter<any>();
   @Output('imageLoaded') imageLoadedEmitter = new EventEmitter<string>();
   @Output('linkCopy') linkCopyEmitter = new EventEmitter<string>();
   @Output('download') downloadEmitter = new EventEmitter<string>();

   public summary: Summary;
   public link: string;
   public autoRequestLink: string;
   public selectedFile: SummaryFile;
   public selectedAutoRequestFile: AutoRequestFile;
   public autoRequestDownloadLinks: {};
   public isExpired: boolean = false;
   public isAutoRequest: boolean = false;
   public expiredMessage: string = `The storage term for this file has expired. Please contact the Watchtower Security office at ${REVIEW_PHONE_NUMBER} for assistance and delivery of the requested file.`;
   public autoRequestFiles: AutoRequestFile[];
   public autoRequestTable: any;
   public headersArray: string[];
   public siteID: number;
   public downloadAllLink: string;
   public downloadAllFilename: string;
   public eventID: number;
   public creatingZip: boolean = false;
   public noAutoRequestFiles: boolean = false;
   public autoRequestFilesExpired: boolean = false;
   public uploadedFiles: SummaryFile[] = [];
   public failedImageLoad: boolean = false;

   public speeds = [.5,1,1.25,1.5, 2, 4, 8, 16];
   public regCurrentSpeedIndex = 1;
   public arCurrentSpeedIndex = 1;
   public vidTime: number | null = null;

   constructor(
      public eventService: EventService,
      public router:Router,
   ) { }

   ngOnInit() { }


   selectFile(file: SummaryFile) {
      this.stopVideoEmitter.emit();
      this.selectedFile = file;
      this.failedImageLoad = false;
      if(file.type === 2) {
         this.regLoad();
         this.regPlay();
      }
      this.checkVideo();
   }
   selectAutoRequestFile(file: AutoRequestFile) {
      this.stopVideoEmitter.emit();
      this.selectedAutoRequestFile = file;
      this.checkAutoRequestVideo();
   }

   checkVideo() {
      this.link = '/api/events/summary/' + this.summary.id + '/file/' + this.selectedFile.url + '/' + this.selectedFile.fileName;
   }

   checkAutoRequestVideo() {
      this.autoRequestLink = 'api/events/' + this.eventID + '/autorequest/file/' + this.selectedAutoRequestFile.uuid + '/' + this.selectedAutoRequestFile.name;
      this.load();
      this.play();
   }

   regLoad() {
      if(this.regVideoPlayer) {
         this.regVideoPlayer.nativeElement.load();
      }
   }
   regPlay() {
      if(this.regVideoPlayer) {
         this.regVideoPlayer.nativeElement.play();
         this.regVideoPlayer.nativeElement.playbackRate = this.speeds[this.regCurrentSpeedIndex];
      }
   }


   load() {
      if (this.arVideoPlayer) {
         this.arVideoPlayer.nativeElement.load();
      }
   }
   play() {
      if (this.arVideoPlayer) {
         this.arVideoPlayer.nativeElement.play();
         this.arVideoPlayer.nativeElement.playbackRate = this.speeds[this.arCurrentSpeedIndex];
      }
   }

   regIncreaseSpeed() {
      if (this.regCurrentSpeedIndex < this.speeds.length - 1) {
         this.regCurrentSpeedIndex++;
      }
      console.log(this.speeds[this.regCurrentSpeedIndex])
      this.regVideoPlayer.nativeElement.playbackRate = this.speeds[this.regCurrentSpeedIndex];
   }
   regDecreaseSpeed() {
      if (this.regCurrentSpeedIndex > 0) {
         this.regCurrentSpeedIndex--;
      }
      console.log(this.speeds[this.regCurrentSpeedIndex])
      this.regVideoPlayer.nativeElement.playbackRate = this.speeds[this.regCurrentSpeedIndex];
   }
   arIncreaseSpeed() {
      if (this.arCurrentSpeedIndex < this.speeds.length - 1) {
         this.arCurrentSpeedIndex++;
      }
      console.log(this.speeds[this.arCurrentSpeedIndex])
      this.arVideoPlayer.nativeElement.playbackRate = this.speeds[this.arCurrentSpeedIndex];
   }
   arDecreaseSpeed() {
      if (this.arCurrentSpeedIndex > 0) {
         this.arCurrentSpeedIndex--;
      }
      console.log(this.speeds[this.arCurrentSpeedIndex])
      this.arVideoPlayer.nativeElement.playbackRate = this.speeds[this.arCurrentSpeedIndex];
   }


   copy() {
      this.share.nativeElement.select();
      document.execCommand("copy");
      this.linkCopyEmitter.emit(this.share.nativeElement.value);
   }
   shareModal() {
      this.shareEmitter.emit();
   }
   downloadAutoRequestFile(file) {
      console.log("downloadFile:", file)
      this.downloadEmitter.emit(file.name);
   }

   translateAutoRequestFiles(files: AutoRequestFile[]) {
      let fileCountMap = {};
      let fileCountArray = [];
      let tableMap = {};
      let camNumberArray: number[] = [];
      let headersMap = {};
      let headersArray: string[] = [];
      let downloadLinks = {};

      this.downloadAllFilename = files[0].eventID + '-all-files.zip';
      this.downloadAllLink = '/rest/autorequest/download/site/' + files[0].siteID  + '/event/' + files[0].eventID + '/file/' + this.downloadAllFilename;


      for (let i = 0; i < files.length; i++) {
         if (files[i].cameraNumber in fileCountMap) {
            fileCountMap[files[i].cameraNumber]++;
         } else {
            fileCountMap[files[i].cameraNumber] = 1;
         }

         if (files[i].cameraNumber in tableMap) {
            tableMap[files[i].cameraNumber].push(files[i]);
         } else {
            tableMap[files[i].cameraNumber] = new Array<AutoRequestFile>();
            tableMap[files[i].cameraNumber].push(files[i]);
         }

         if (!(files[i].cameraNumber in headersMap)) {
            headersMap[files[i].cameraNumber] = files[i].cameraName;
         }
         downloadLinks[files[i].id] = this.createDownloadLink(files[i]);
      }

      this.autoRequestDownloadLinks = downloadLinks;

      Object.keys(tableMap)
         .forEach(function eachKey(key) {
            tableMap[key].sort(function (a, b) {
               let dateA = a.startDate;
               let dateB = b.startDate;
               return dateB - dateA 
            })
         });

      Object.keys(fileCountMap)
         .forEach(function eachKey(key) {
            fileCountArray.push(fileCountMap[key])
            camNumberArray.push(parseInt(key))
         });

      camNumberArray.sort()

      for (let i = 0; i < camNumberArray.length; i++) {
         let name = headersMap[camNumberArray[i]];
         headersArray.push(name);
      }

      this.headersArray = headersArray;
      let autoRequestTableRows = Math.max(...fileCountArray);
      let autoRequestTableColumns = camNumberArray.length

      let matrix = this.createMatrix(autoRequestTableRows, autoRequestTableColumns);
      let loadedMatrix = this.loadMatrix(matrix, tableMap, camNumberArray);

      this.autoRequestTable = loadedMatrix;
      this.loadFirstFile();
   }

   createMatrix(rows: number, cols: number) {
      let matrix = new Array(rows);
      for (let i = 0, length = matrix.length; i < length; ++i) {
         matrix[i] = new Array(cols).fill(0);
      }
      return matrix;
   }

   loadMatrix(matrix: any, data: any, camNbrs: number[]) {
      for (let i = 0; i < camNbrs.length; i++) {
         let camNbr = camNbrs[i]
         for (let j = 0; j < data[camNbr].length; j++) {
            matrix[j][i] = data[camNbr][j];
         }
      }
      return matrix
   }

   createDownloadLink(file: AutoRequestFile) {
      let url = '/rest/autorequest/download/' + file.uuid + '/' + file.name;
      return url;
   }

   loadFirstFile() {
      this.selectedAutoRequestFile = this.autoRequestTable[0][0];
      this.checkAutoRequestVideo();
   }

   createZipFile() {
      this.creatingZip = true;
      this.eventService.createAutoZipFile(this.eventID).subscribe((res) => {
         this.downloadEmitter.emit("all")
         this.downloadAll.nativeElement.click();
         setTimeout(() => 
         {
            this.creatingZip = false; 
         },
         5000);
      }, () => {
         this.creatingZip = false;
      });
   }
   vidLoaded() {
      if((typeof this.vidTime === "number") && this.regVideoPlayer && this.regVideoPlayer.nativeElement) {
         this.regVideoPlayer.nativeElement.currentTime = this.vidTime;
         this.regVideoPlayer.nativeElement.scrollIntoView({block: "center", inline: "nearest"});
      }
   }
   clickSummary(event: MouseEvent) {
      let target = event.target as HTMLAnchorElement
      let href = target.href
      if(href) {
         let matches = href.match(/video=(\d*)/)
         if(matches && matches[1]) {
            event.preventDefault();
            this.applyVidTime(+matches[1]);
         }
      }
   }
   applyVidTime(seconds: number){
      this.vidTime = seconds;
      if((typeof this.vidTime === "number") && this.regVideoPlayer && this.regVideoPlayer.nativeElement) {
         this.regVideoPlayer.nativeElement.currentTime = this.vidTime;
      }
   }

   
   regPlaying() {
      this.playVideoEmitter.emit(this.link);
   }
   regPause() {
      this.stopVideoEmitter.emit(this.link);
   }
   regEnded() {
      this.stopVideoEmitter.emit(this.link);
   }

   arPlaying() {
      this.playVideoEmitter.emit(this.autoRequestLink);
   }
   arPause() {
      this.stopVideoEmitter.emit(this.autoRequestLink);
   }
   arEnded() {
      this.stopVideoEmitter.emit(this.autoRequestLink);
   }

   
   imageLoaded(img: string) {
      this.imageLoadedEmitter.emit(img);
   }

   imageLoadError() {
      this.failedImageLoad = false;
   }
}
