import { createAction, props } from "@ngrx/store";
import { Layout } from '../layout';
import { GridSize } from '../gridsize';
import { Camera } from '../../camera/camera';
import { Server } from "../server";
import { CameraAuth } from "app/camera/camera-auth";

export const noAction = createAction("[CVS] No Action");

export const loadLayouts = createAction("[CVS] Load Layouts", props<{siteID: number}>());

export const loadLayout = createAction("[CVS] Load Layout", props<{siteID: number, layoutID: number, withCameras: boolean}>());

export const setLayouts = createAction("[CVS] Set Layouts", props<{layouts: Layout[]}>());

export const setCVSSiteID = createAction("[CVS] Set CVS siteID", props<{siteID: number}>());

export const resetCVS = createAction("[CVS] Reset CVS");

export const setSelectedLayout = createAction("[CVS] Set Selected Layout ID", props<{layout: Layout}>());

export const loadGridSizes = createAction("[CVS] Load Grid Sizes", props<{siteID: number}>());

export const setGridSizes = createAction("[CVS] Set Grid Sizes", props<{gridSizes: GridSize[]}>());

export const addLayout = createAction("[CVS] Add Layout", props<{layout: Layout}>());

export const appendAddedLayout = createAction("[CVS] Append Added LAyout", props<{layout: Layout}>());

export const updateLayout = createAction("[CVS] Update Layout", props<{layout: Layout}>());

export const replaceLayout = createAction("[CVS] Replace Layout", props<{layout: Layout}>());

export const deleteLayout = createAction("[CVS] Delete Layout");

export const removeLayout = createAction("[CVS] Remove Layout", props<{id: number}>());

export const loadCameras = createAction("[CVS] Load Cameras", props<{siteID: number}>());

export const setCameras = createAction("[CVS] Set Cameras", props<{cameras: Camera[]}>());

export const storeLayoutChanges = createAction("[CVS] Store Layout Changes", props<{layout: Layout}>());

export const setLayoutHasChanges = createAction("[CVS] Set Layout Has Changes", props<{changes: boolean}>());

export const setShowUnsavedChangesDialog = createAction("[CVS] Set Show Unsaved Changes Dialog", props<{show: boolean}>());

export const confirmUnsavedChanges = createAction("[CVS] Confirm Unsaved Changes");

export const setShowDeleteLayoutDialog = createAction("[CVS] Set Show Delete Layout Dialog", props<{show: boolean}>());

export const setShowAutoGenerateDialog = createAction("[CVS] Set show Auto Generate CVS Dialog", props<{show: boolean}>());

export const generateLayouts = createAction("[CVS] generate layouts", props<{siteID: number, gridSizeID: number}>());

export const toggleShowCVSLayouts = createAction("[CVS] Toggle Show CVS Layouts");

export const generateStandaloneSession = createAction("[CVS] Generate Standalone Session", props<{siteID: number, layoutID: number}>());

export const addStandaloneSession = createAction("[CVS] Add Standalone Session", props<{siteID: number}>());