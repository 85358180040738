

export class EventListItem {
    id: number;
    displayName: string;    
    siteID: number;
    siteName: string;
    outcome: string;
    outcomeID: number;
    submitted: Date;
    categoryID: number;    
    category: string;
    isStarred: boolean;
    summaries: string[] = [];
    isInactive: boolean;
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config);
            if(config.submitted) {
                this.submitted = new Date(config.submitted);
            }
        }
    }
}