
import {map, take, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {  UserService } from '../../users/user.service';
import { User } from '../../users/user';
import { PermissionsService } from '../../permissions.service';


@Injectable()
export class LiveViewGuard  {
    constructor(public router: Router, public userService: UserService, public permissions: PermissionsService) {}
    canActivate(        
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
        ) {
        return this.userService.getUser().pipe(filter(user => !user.initial),take(1),map(user => {
            let siteID = +route.params['siteID'];
            if(this.permissions.site(siteID, 'live')) {
                return true;
            }
            this.router.navigate(['site', siteID, 'dashboard']);
            return false;
        }),)
    }
}