import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NotificationsService } from "../../notifications/notifications.service";
import SitePackage from "../../site-package/models/site-package";
import Feature from "../../site-package/models/feature";
import * as fromSitePackages from "../../site-package/reducers";
import { SitePackageViewActions, FeatureViewActions } from "../../site-package/actions";

@Component({
  selector: "app-admin-site--package",
  templateUrl: "./admin-site-package.component.html",
  styleUrls: ["./admin-site-package.component.css"],
})
export class AdminSitePackageComponent implements OnInit, OnDestroy {
  public createSitePackageOpened: boolean;
  public editSitePackageOpened: boolean;
  public sitePackageToEdit: SitePackage;
  
  public editSitePackageError$: Observable<Error>;
  public error$: Observable<Error>;
  public allFeatures$: Observable<Feature[]>

  private destroy$ = new Subject<void>();

  constructor(
    private sitePackageStore$: Store<fromSitePackages.State>,
    private notificationsService: NotificationsService,
  ) {}

  public ngOnInit() {
    this.allFeatures$ = this.sitePackageStore$.pipe(select(fromSitePackages.selectAllFeatures));

    this.error$ = this.sitePackageStore$.pipe(select(fromSitePackages.selecteSitePackageError));

    this.error$.pipe(takeUntil(this.destroy$)).subscribe((error) => {
      if (error) {
        this.notificationsService.error(error.name, error.message);
      }
    });

    this.sitePackageStore$.dispatch(FeatureViewActions.loadFeatures());
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onOpenEditSitePackage(sitePackage: SitePackage) {
    this.sitePackageToEdit = sitePackage;
    this.editSitePackageOpened = true;
  }

  public onCloseEditSitePackage() {
    this.editSitePackageOpened = false;
    this.sitePackageToEdit = null;
  }

  public onSaveEditSitePackage(sitePackage: SitePackage) {
    if (sitePackage) {
      this.sitePackageStore$.dispatch(SitePackageViewActions.updateSitePackage({ sitePackage }));
    }

    this.onCloseEditSitePackage();
  }

  public onOpenCreateSitePackage() {
    this.createSitePackageOpened = true;
  }

  public onCloseCreateSitePackage() {
    this.createSitePackageOpened = false;
  }

  public onCreateSitePackage(sitePackage: SitePackage) {
    if (sitePackage) {
      this.sitePackageStore$.dispatch(SitePackageViewActions.createSitePackage({ sitePackage }));
    }

    this.onCloseCreateSitePackage();
  }

  public onDeleteSitePackage(id: number) {
    if (id > 0) {
      this.sitePackageStore$.dispatch(SitePackageViewActions.deleteSitePackage({ id }));
    }
  }
}
