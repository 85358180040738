import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from './state';
import * as siteManagementActions from './actions';

const reducer = createReducer(
    initialState,
    
    on(siteManagementActions.setSiteList, (state: State, {sites}) => {
        return {
            ...state,
            siteList: sites,
        }
    }),
    on(siteManagementActions.setSiteListGridState, (state: State, {gridState}) => {
        return {
            ...state,
            siteListGridState: gridState,
        }
    }),

)

export function siteManagementReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}