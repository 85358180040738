import { User } from '../users/user';
import { OtherOfficer } from '../requests/new-request/contact-info/other-officer/other-officer';
import { Site } from '../site/site';
import { Vehicle, isBlankVehicle } from '../requests/new-request/where/vehicle/vehicle';
import { Summary } from './summary';
import { TimeObj, dateFromTimeObj } from 'app/time-obj';

export class Request {
    id: number;
    eventID: number;
    requester: User;
    involvedUsers: User[];
    involvedNotUsers: {email: string};
    involvedOfficers: User[];
    cameras: {id: number}[];
    extraOfficers: OtherOfficer[];
    description: string;
    site: Site;
    whenStart: Date;
    whenStartObj: TimeObj;
    whenEnd: Date;
    whenEndObj: TimeObj;
    evidenceDiscovered: Date;
    evidenceDiscoveredBy: string;
    vehicleInvolved: boolean;
    vehicles: Vehicle[];
    whereAddress: string;
    policeInvolved: boolean;
    reportNumber: string;
    statusCode: number;
    requested: Date;
    mapPoints: any;
    starred: boolean;
    cStarred: boolean;
    isAutoRequest: boolean;
    summaries: Summary[];
    constructor(request?) {
        if(request) {
            Object.assign(this, request);
            this.requested = new Date(this.requested);
            this.whenStart = new Date(this.whenStart);
            this.whenEnd = new Date(this.whenEnd);
            if( request.whenStartObj ) {
                this.whenStart = dateFromTimeObj(request.whenStartObj)
            }
            if( request.whenEndObj ) {
                this.whenEnd = dateFromTimeObj(request.whenEndObj)
            }
            this.evidenceDiscovered = new Date(this.evidenceDiscovered);
            this.requester = new User(request.requester);
            if(request.involvedUsers) {
                this.involvedUsers = request.involvedUsers.map(u => new User(u));
            }
            if(request.involvedOfficers) {
                this.involvedOfficers = request.involvedOfficers.map(o => new User(o));
            }
            if(!request.invovledNotUsers && request.nonUsers) {
                this.involvedNotUsers = request.nonUsers;
            }
            if(request.vehicles && request.vehicles.length > 0) {
                this.vehicles = request.vehicles.filter(v => !isBlankVehicle(v));
            }
        }
    }
}
