import { CameraMarker } from "app/camera/camera-marker";
import { clickCamera } from "../store/actions";
import { select } from "@ngrx/store";
import { isCameraSelected } from "../store/selectors";
import { take } from "rxjs/operators";


export class MotionCameraMarker extends CameraMarker {
    clicked() {
        this.store.dispatch(clickCamera({marker: this}));
        this.map.draw();    
    }
    draw() {
        this.store.pipe(select(isCameraSelected(this.id)), take(1)).subscribe(selected => {
            if(!selected) {
                super.draw();
                return;
            } else {
                this.shapeObj.draw("purple", "green");
            }    
        }).unsubscribe();
    }
}