import { ActionReducer, Action } from '@ngrx/store';
import { AllSitesSearch } from './all-sites-search'

export const SET_ALL_SITES_SEARCH = "SET_ALL_SITES_SEARCH";

export const initialAllSitesSearch = {siteText: "", packageText: ""}


export function allSitesSearchReducer(state: AllSitesSearch = initialAllSitesSearch, action): AllSitesSearch {
    switch(action.type) {
        case SET_ALL_SITES_SEARCH:
            return new AllSitesSearch(action.payload.siteText, action.payload.packageText);
        default:
            return state;
    }    
}