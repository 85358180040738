import { Marker } from '../markers/marker';
declare var h337: any;

import { MapOverlay } from './map-overlay';

export class Map {
    public id;
    public siteID: number;
    public mapType: string;
    public filePath: string;
    public width: number;
    public height: number;
    public overlays: MapOverlay[] = [];
    public device: string;
    public displayName: string;
    public displayOrder: number;
    public markers: any = [];
    public _markersLocal: Marker[] = [];
    public _canvas
    public _heatContainer;
    public _hmap;
    public _ctx;
    public _tooltip;
    public _renderer;
    constructor(data: MapData) {
        Object.assign(this, data);
    }
    set canvas(value) {
        this._canvas = value;
        this._ctx = this._canvas.getContext("2d");
    }
    get canvas() {
        return this._canvas;
    }
    set heatContainer(value) {
        this._heatContainer = value;
    }
    get heatContainer() {
        return this._heatContainer;
    }
    get tooltip() {
        return this._tooltip;
    }
    set tooltip(value) {
        this._tooltip = value;
    }
    get hmap() {
        return this._hmap;
    }
    set hmap(value) {
        this._hmap = value;
    }
    get ctx() {
        return this._ctx;
    }
    get renderer() {
        return this._renderer;
    }
    set renderer(value) {
        this._renderer = value;
    }
    get markersLocal(): Marker[] {
        return this._markersLocal;
    }
    set markersLocal(markers: Marker[]) {
        this._markersLocal = markers;
    }

    draw() {
        this.clearCanvas();
        if(this.markersLocal && this.markersLocal.forEach) {
            this.markersLocal.forEach( marker => {
                this.drawMarker(marker);
            });    
        }
    }
    drawMarker(marker: Marker) {
        marker.draw();
    }
    mouseMove(event) {
        
        let xy = this.getXY(event);
        if(this.markersLocal) {
            this.markersLocal.forEach(marker => {               
                marker.mouseOver(xy.x, xy.y);
            })
        }
    }
    mouseClick(event) {
        let xy = this.getXY(event);
        if(this.markersLocal) {
            this.markersLocal.forEach(marker => {
                marker.click(xy.x, xy.y);
            })
        }
    }
    mouseDown(event) {

    }
    mouseUp(event) {

    }
    mouseLeave(event) {

    }
    clearCanvas() {
        if(this.ctx) {
            this.ctx.clearRect(0,0,this.ctx.canvas.width, this.ctx.canvas.height)
            if(this.hmap) {
                this.hmap.setData({min: 0, max: 10, data: []})                
            }
            if(this.markersLocal) {
                this.markersLocal.forEach(marker => marker.reset());                
            }
        }
    }
    getXY(event): {x:number, y:number} {
        if(event.offsetX && event.offsetY) {
            return {x: event.offsetX, y: event.offsetY}
        }
        return {x: event.layerX, y: event.layerY};
    }
}
export interface MapData {
    id?: number;
    siteID?: number;
    mapType?: string;
    filePath?: string;
    width?: number;
    height?: number;
    overlays?: MapOverlay[];
    device?: string;
    displayName?: string;
    displayOrder?: number;
    markers?: any;
    markersLocal?: Marker[];
    tooltip?: any;
    hmap?: any;
    canvas?: any;
}