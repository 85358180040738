import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { SET_ADMIN_EVENTS_MANAGEMENT_COMPANY, SET_ADMIN_EVENTS_SITE, SET_ADMIN_EVENTS_SEARCH, SET_ADMIN_EVENTS_STARRED, SET_ADMIN_EVENTS_OUTCOME, SET_ADMIN_EVENTS_LOADING, SET_ADMIN_EVENTS_RESULTS, CLEAR_ADMIN_EVENTS, TOGGLE_ADMIN_EVENTS_SORT } from './admin-events.reducer';
import { EventSearch } from './event-search';
import { Observable } from 'rxjs';
import { map, take, combineLatest } from 'rxjs/operators';
import { config } from '../../app.config';
import { NotificationsService } from 'app/notifications/notifications.service';
import { EventListItem } from './event-list-item';


@Injectable({
  providedIn: 'root'
})
export class AdminEventsService {

  constructor(
    private store: Store<AppState>, 
    public http: HttpClient, 
    private notificationService: NotificationsService,
  ) { }
  getCurrentSearch(): Observable<EventSearch> {
    return this.store.select(s => s.adminEvents.currentSearch);
  }
  getCurrentManagementCompany(): Observable<number> {
    return this.store.select(s => s.adminEvents.currentSearch).pipe(map(current => {
      if(current) {
        return current.managementCompanyID;
      } else {
        return 0;
      }
    }))
  }
  getEvents(): Observable<EventListItem[]> {
    return this.store.select(s => s.adminEvents.events);
  }
  getLoading(): Observable<boolean> {
    return this.store.select(s => s.adminEvents.eventsLoading);
  }
  managementChanged(id: number) {
    this.store.dispatch({type:SET_ADMIN_EVENTS_MANAGEMENT_COMPANY, payload: id});
  }
  siteChanged(siteID: number) {
    this.store.dispatch({type:SET_ADMIN_EVENTS_SITE, payload: siteID});
  }
  searchChanged(search: string) {
    this.store.dispatch({type:SET_ADMIN_EVENTS_SEARCH, payload: search});
  }
  starChanged(isStarred: boolean) {
    this.store.dispatch({type: SET_ADMIN_EVENTS_STARRED, payload: isStarred});
  }
  outcomeChanged(outcome: string) {
    this.store.dispatch({type: SET_ADMIN_EVENTS_OUTCOME, payload: outcome});
  }
  search() {
    let currentSearch: EventSearch;
    this.store.select(s => s.adminEvents.currentSearch).pipe(take(1)).subscribe(c => currentSearch = c);
    if(this.isEmptySearch(currentSearch)) {
      this.notificationService.error("", "Please Select a filter before searching.");
      return;
    }
    this.store.dispatch({type: SET_ADMIN_EVENTS_LOADING, payload: true});
    this.store.dispatch({type: CLEAR_ADMIN_EVENTS });
    this.http.post<EventListItem[]>(`${config.apiUrl}admin/events`, currentSearch).subscribe(events => {
      events = events.map(e => new EventListItem(e));
      this.store.dispatch({type: SET_ADMIN_EVENTS_RESULTS, payload: events});
    }, e => {
      this.store.dispatch({type: SET_ADMIN_EVENTS_LOADING, payload: false});
      this.notificationService.error("","Failed to load events.");
    })
  }
  getIsEmptySearch(): Observable<boolean> {
    return this.store.select(s => s.adminEvents.currentSearch).pipe(map(current => this.isEmptySearch(current)));
  }
  isEmptySearch(search: EventSearch) {
    if(!search.isStarred && search.managementCompanyID === 0 && search.outcome === "All" && search.search === "" && search.siteID === 0) {
      return true;
    }
    return false;
  }
  getIsSameSearch(): Observable<boolean> {
    return this.store.select(s => s.adminEvents.currentSearch).pipe(combineLatest(this.store.select(s => s.adminEvents.previousSearch), (current, prev) => {
      return this.isSameSearch(prev, current);
    }))
  }
  isSameSearch(old: EventSearch, current: EventSearch) {
    if(old.isStarred !== current.isStarred) {
      return false;
    }
    if(old.managementCompanyID !== current.managementCompanyID) {
      return false;
    }
    if(old.outcome !== current.outcome) {
      return false;
    }
    if(old.search !== current.search) {
      return false;
    }
    if(old.siteID !== current.siteID) {
      return false;
    }
    return true;
  }
  sortByColumn(column: string) {
    this.store.dispatch({type:TOGGLE_ADMIN_EVENTS_SORT, payload: column});
  }
}
