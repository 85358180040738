import { Address } from '../address/address';
import { User } from '../users/user';
import { Map } from '../map/map';
import SitePackage from '../site-package/models/site-package';

export class Site {
    public siteID: number;
    public siteName: string;
    public displayName: string;
    public hqAdminID: number;
    public mailingAddress: Address;
    public shippingAddress: Address;
    public serviceHours: { start: string, end: string };
    public officeHours: { start: string, end: string };

    public managementCompanyID: number;
    public managementCompanyName: string;
    public continuousRecordingDays: number;
    public cloudStorage: number;
    public daysRewind: number;
    public unitCount: number;
    public isPriority: boolean;

    public upgradeDate: Date;

    public propertyManager: User;
    public regionalManager: User;

    public primaryContact: User;
    public additionalContacts: User[];

    public salesContact: User;
    public additionalSalesmen: User[];

    public maps: Map[] = [];
    public lightMaps: Map[] = [];
    public status: string;

    public packageID: number;
    public package: SitePackage;

    public lng: number;
    public lat: number;
    public restrictedUse: boolean;
    public siteNotesURL: string;
    public cameras: any[] = []; //TODO: fix to cameras
    public autoRequestAdditionalCameras: number;
    public autoRequestFileRetentionDays: number;
    public lprCams: number;
    public isWTSSite: boolean;
    public rawRequestHours: number;
    public isDisabled: boolean;
    public cvsDisabled: boolean;
    public notes: string;
    public motionOnCamera: boolean;
    public allowLEAccess: boolean;

    public bucketID: number;
    public bucketName: string;
    public bucketAccount: string;
    public isByos: boolean;


    constructor(options?: any) {
        if(options) {
            Object.assign(this, options);
            if(options.propertyManager) {
                this.propertyManager = new User(options.propertyManager);
            }
            if(options.regionalManager) {
                this.regionalManager = new User(options.regionalManager);
            }
            if(options.primaryContact) {
                this.primaryContact = new User(options.primaryContact);
            }
            if(options.additionalContacts) {
                this.additionalContacts = options.additionalContacts.map(c => new User(c));
            }
            if(options.salesContact) {
                this.salesContact = new User(options.salesContact);
            }
            if(options.additionalSalesmen) {
                this.additionalSalesmen = options.additionalSalesmen.map(s => new User(s));
            }
            if(options.upgradeDate) {
                this.upgradeDate = new Date(options.upgradeDate);
            }
            if(options.maps) {
                this.maps = options.maps.map(m => new Map(m));
            }
        }
    }
    name(): string {
        return this.displayName ? this.displayName : this.siteName;
    }
}
