
import {first, map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../users/user.service';
import { SiteService } from '../site/site.service';
import { PermissionsService } from '../permissions.service';

@Injectable()
export class RequestAuthGuard  {
    constructor(public router: Router, public userService: UserService, public siteService: SiteService, public permissions: PermissionsService) {}
    canActivateChild() {
        return this.checkPermissions();
    }
    canActivate() {
        return this.checkPermissions();
    }
    checkPermissions() {
        let siteID;
        this.siteService.getSite().pipe(take(1)).subscribe(site => {
            if(site) {
                siteID = site.siteID;
            }
        })
        return this.userService.getUser().pipe(map(user => {
            if(siteID && this.permissions.site(siteID, 'canCreateTickets', user.permissions)) {
                return true;
            }
            this.router.navigate(['dashboard']);
            return false;
        }),first(),)

    }
}