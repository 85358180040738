import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccessKeyService } from '../users/access-key.service';
import { ServiceHelpersService } from '../service-helpers.service';
import { config } from '../app.config';
import { MotionRequest } from './motion-request';
import { MotionData } from './motion-data';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MotionService {
  public headers = {};
  constructor(
    private http: HttpClient,
    private helper: ServiceHelpersService,
    private accessKeyService: AccessKeyService,
  ) { 
    this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key}));
  }

  getMotion(request: MotionRequest): Observable<MotionData> {
    let headers = new HttpHeaders(this.headers);
    return this.http.post<MotionData>(`/rest/video/motion`,request, {headers: headers}).pipe(catchError(this.helper.handleError), map(md => new MotionData(md)));
  }
}
