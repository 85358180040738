import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Camera } from 'app/camera/camera';
import Hls from 'hls.js';


@Component({
  selector: 'app-hls2-player',
  templateUrl: './hls2-player.component.html',
  styleUrls: ['./hls2-player.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Hls2PlayerComponent {
  @Output() timeupdate = new EventEmitter<number>();
  @Output() durationchange = new EventEmitter<number>();
  @Output('ready') readyEmitter = new EventEmitter<boolean>();
  @Output('error') errorEmitter = new EventEmitter<boolean>();
  @ViewChild('video') video: ElementRef<HTMLVideoElement> | null = null;
  @ViewChild('videoContainer') videoContainer: ElementRef<HTMLDivElement>;
  @Input('camera') set setCamera(value: Camera | null) {
    this.camera = value;
    }
  @Input('streamAuth') set setStreamAuth(value: { username: string, password: string } | null ) {
    //this.streamAuth = value;
    if(value) {
      this.updateAuth(value);
    }
  };
  @Input('showLogo') showLogo: boolean = true;
  @Input('speed') set setSpeed(value: number) {
    if(!this.videoElement) {
      return;
    }
    this.videoElement.playbackRate = value;
  };
  @Input('stretch') stretch: boolean = false;
  
  // private url: string | null = '';
  public camera: Camera | null = null;
  public videoElement: HTMLVideoElement | null = null;
  //private streamAuth: { username: string, password: string } | null = { username: '', password: '' };
  private hls: Hls | null = null;
  public ready: boolean = false;
  private resizeObserver: ResizeObserver;
  public top: string = "0px";
  public right: string = "0px";
  constructor() {   }

  ngAfterViewInit() {
    this.hlsSetup();
    this.resizeObserver = new ResizeObserver(() => { 
      this.resize();
    });
    this.resizeObserver.observe(this.videoContainer.nativeElement);
  }
  ngOnDestroy() {
    if(this.hls) {
      this.hls.destroy();
    }
  };

  hlsSetup() {
    if(this.video && this.video.nativeElement) {
      this.videoElement = this.video.nativeElement;
    }
    if(!this.camera || !this.camera.stream || !this.camera.stream.hls || !this.camera.stream.hls.url || !this.videoElement) {
      return;
    }
    let url = this.camera.stream.hls.url + "/index.m3u8";
    if(!this.videoElement) {
      return;
    }
    this.videoElement.muted = true;
    if(this.hls) {
      this.hls.destroy();
      this.hls = null;
    }
    if (Hls.isSupported()) {
      this.hls = new Hls({
        maxLiveSyncPlaybackRate: 1.5,
        xhrSetup: xhr => {
          xhr.setRequestHeader('Authorization', 'Basic ' + btoa(this.camera.stream.username + ':' + this.camera.stream.password));
        }
      });
      this.hls.on(Hls.Events.FRAG_BUFFERED, () => {
        this.onBuffer();
      });
      this.hls.on(Hls.Events.ERROR, (evt, data) => {
        if (data.fatal) {
          if(this.hls) {
            this.hls.destroy();
          }
          setTimeout(this.hlsSetup, 2000);
          console.log("hls error", data)
          this.errorEmitter.emit(true);
          this.ready = false;
        }
      });
      this.hls.loadSource(url);
      this.hls.attachMedia(this.videoElement);
    } else if (this.videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      fetch(url).then(() =>{
        if(this.videoElement) {
          if(url) {
            this.videoElement.src = url; 
          }
        }
      })
    }
  }
  updateAuth(auth: { username: string, password: string }) {
    if(this.hls) {
      this.hls.config.xhrSetup = xhr => {
        xhr.setRequestHeader('Authorization', 'Basic ' + btoa(auth.username + ':' + auth.password));
      }
    }
  }
  currenttime(time: number) {
    if(!this.videoElement) {
      return;
    }
    this.videoElement.currentTime = time;
  }
  gotoTime(time: number) {
    if(!this.videoElement) {
      return;
    }
    //console.log(`duration: ${this.videoElement.duration} time diff: ${(new Date().getTime() - time)} time: ${time} currentTime:${this.videoElement.currentTime}`);
    this.videoElement.currentTime = this.videoElement.duration - (new Date().getTime() - time) / 1000;
    //console.log(this.videoElement.currentTime);
  }
  onTimeUpdate() {
    if(!this.videoElement) {
      return;
    }
    let time = new Date().getTime() - ((this.videoElement.duration - this.videoElement.currentTime) * 1000);
    //console.log(new Date(time))
    this.timeupdate.emit(time);
  };
  onDurationChange() {
    if(!this.videoElement) {
      return;
    }

    this.durationchange.emit(this.videoElement.duration);

  };
  live() {
    if(!this.videoElement) {
      return;
    }
    this.videoElement.currentTime = this.videoElement.duration;
    // this.videoElement.play();
  }
  pause() {
    if(!this.videoElement) {
      return;
    }

    this.videoElement.pause();
  }
  play() {
    if(!this.videoElement) {
      return;
    }
    if(this.videoElement.paused) {
      this.videoElement.play();
    }
  }
  onBuffer() {
    this.readyEmitter.emit(true);
    this.ready = true;
  }
  onVideoPlay() {
    this.live();
  }
  resize() {
    this.top = "0px";
    this.right = "0px";
    if (this.stretch) {
      return;
    }
    let vwidth = this.videoElement.videoWidth;
    let vheight = this.videoElement.videoHeight;
    let cwidth = this.videoElement.clientWidth;
    let cheight = this.videoElement.clientHeight;
    let vidRatio = this.videoElement.videoWidth / this.videoElement.videoHeight;
    let dispRatio = this.videoElement.clientWidth / this.videoElement.clientHeight;
    if(vidRatio > dispRatio) {
      this.top = (cheight - (cwidth / vwidth * vheight)) / 2 + "px";
    } else {
      this.right = (cwidth - (cheight / vheight * vwidth)) / 2 + "px";
    }
  }
  goFullScreen() {
    this.videoContainer.nativeElement.requestFullscreen();
  }
  reset() {
    if(this.hls) {
      this.hls.destroy();
      this.hlsSetup();
    }
  }
  clear() {
    if(this.hls) {
      this.hls.destroy();
    }
  }
  setup() {
    this.hlsSetup();
  }
}
