// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showSet {
    display: block;
    height: 100%;
}

.hideSet {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/camera/camera-rotater/camera-rotater.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".showSet {\r\n    display: block;\r\n    height: 100%;\r\n}\r\n\r\n.hideSet {\r\n  display: none;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
