import { Component, OnInit, Input } from '@angular/core';
import { MotionFile } from '../motion-data';

@Component({
  selector: 'app-motion-image',
  templateUrl: './motion-image.component.html',
  styleUrls: ['./motion-image.component.scss']
})
export class MotionImageComponent implements OnInit {
  @Input() file: MotionFile;
  @Input('name') set setName(value: string) {
    this.name = value.substring(0,14);
  }
  @Input() viewed: boolean;

  public name: string;
  public loaded: boolean = false;
  constructor() { }

  ngOnInit() {
  }
  imageLoaded() {
    this.loaded = true;
  }
}
