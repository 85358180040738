// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.motion-card {
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  box-shadow: 3px 3px grey;
  padding: 3px;
  margin: 5px;
}

.not-loaded {
  display: none;
}

.info-row {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  font-size: 10px;
  font-weight: normal;
}

.cam-name {
  margin-right: 3px;
  flex: 0 1 auto;
}

.motion-time {
  flex: 0 1 auto;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 3px;
  border-style: solid;
  max-height: 120px;
}

.viewed {
  border-color: red;
}

img {
  max-height: 115px;
}

.not-viewed {
  border-color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/motion/motion-image/motion-image.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;EACA,mBAAA;EACA,wBAAA;EACA,YAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,cAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAGA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,iBAAA;AAAJ;;AAIA;EACI,iBAAA;AADJ;;AAIA;EACI,iBAAA;AADJ;;AAIA;EACI,mBAAA;AADJ","sourcesContent":[".motion-card {\r\n    display: flex;\r\n    flex-direction: column;\r\n    border-width: 1px;\r\n    border-style: solid;\r\n    border-color: black;\r\n    box-shadow: 3px 3px grey;\r\n    padding: 3px;\r\n    margin: 5px;\r\n}\r\n\r\n.not-loaded {\r\n    display: none;\r\n}\r\n\r\n.info-row {\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-bottom: 8px;\r\n    font-size: 10px;\r\n    font-weight: normal;\r\n}\r\n\r\n.cam-name {\r\n    margin-right: 3px;\r\n    flex:0 1 auto;\r\n}\r\n\r\n.motion-time {\r\n    flex: 0 1 auto;\r\n   \r\n}\r\n \r\n.image-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border-width: 3px;\r\n    border-style: solid;\r\n    max-height: 120px;\r\n    \r\n}\r\n\r\n.viewed {\r\n    border-color: red;\r\n}\r\n\r\nimg {\r\n    max-height: 115px;\r\n}\r\n\r\n.not-viewed {\r\n    border-color: white;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
