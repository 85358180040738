import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState } from "app/app.state";
import * as fromAddCamsState from "./state";
import { AddCamsMarker } from "../add-cams-marker";

export const selectAddCams = createFeatureSelector<fromAddCamsState.State>(fromAddCamsState.AddCamsKey);

export const selectedAdditionalCameras = createSelector(
    selectAddCams,
    (state: fromAddCamsState.State) => state.selectedAdditionalCameras,
)

export const selectedCameras = createSelector(
    selectAddCams,
    (addCams => {
        if(addCams.primaryCamera){
            return [addCams.primaryCamera, ...addCams.selectedAdditionalCameras]
        } else {
            return [...addCams.selectedAdditionalCameras]
        }
    }),
)

export const isCameraSelected = (markerID: number) => {
    return createSelector(
        selectedAdditionalCameras,
        (selectedCams: AddCamsMarker[]) => {
            return selectedCams.map(marker => marker.id).indexOf(markerID) !== -1
        },    
    )
};

export const numCamsSelected = createSelector(
    selectedAdditionalCameras,
    (selectedCams: AddCamsMarker[]) => selectedCams.length,
)

export const primaryCamera = createSelector(
    selectAddCams,
    (state: fromAddCamsState.State) => state.primaryCamera,
)

export const cameraData = createSelector(
    selectAddCams,
    (state: fromAddCamsState.State) => state.cameras,
)

export const  maxCameraModalVisible = createSelector(
    selectAddCams,
    (addCams => {
        return addCams.maxCameraModalVisible;
    }),
)
export const selectCamerasWithData = createSelector(
    selectedCameras,
    cameraData,
    (markers, data) => {
        console.log(markers, data)
        return markers.filter(m => data[m.camera]).map(m => data[m.camera])
    }
)