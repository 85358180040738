import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ByosSite, Storage, ByosBucketRequest, ByosBucketCreate } from '../byosSite';
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import * as selectors from '../store/selectors';
import { Observable } from 'rxjs';
import { AppState } from 'app/app.state';
import * as actions from '../store/actions';
import Outcome from 'app/events/outcome';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Key } from 'protractor';
import { Lookup } from 'app/lookup';

@Component({
  selector: 'app-extended-storage-accounts',
  templateUrl: './extended-storage-accounts.component.html',
  styleUrls: ['./extended-storage-accounts.component.css']
})
export class ExtendedStorageAccountsComponent implements OnInit {


  @Input('siteStorage') set setSiteStorage(value: Storage) {
    if (value) {
      this.siteStorage = value;
      this.accountSelection = value.bucketID !== 0 ? 'existing' : '';

      if (this.accountSelection === 'existing') {
        this.selectedAccount = { id: this.siteStorage.bucketID, name: this.siteStorage.accountName }
      }
    }
  }

  @Input('accounts') set setAccounts(value: Lookup[]) {
    if (value) {
      this.accounts = value;
    }
  }

  @Input('vendors') set setVendors(value: Lookup[]) {
    if (value) {
      this.vendors = value;
    }
  }

  @Input('buckets') set setBuckets(value: string[]) {
    console.log("buckets", value);
    if (value) {
      this.buckets = value;
    }
  }

  @Output() closeStorageAccountsDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirmAddStorageAccount: EventEmitter<ByosBucketCreate> = new EventEmitter<ByosBucketCreate>();
  @Output() confirmExistingStorageAccount: EventEmitter<Lookup> = new EventEmitter<Lookup>();
  @Output() chooseStorageBucket: EventEmitter<ByosBucketRequest> = new EventEmitter<ByosBucketRequest>();


  public siteStorage: Storage;
  public showForm: boolean = false;
  public accounts: Lookup[] = [];
  public existingAccount: boolean = false;
  public newAccountForm: boolean = false;
  public selectedAccount: Lookup;
  public accountSelection: string;
  public disableConfirm: boolean = true;
  public existingChangeInProgress: boolean = false;
  public form: FormGroup;
  public vendors: Lookup[] = [];
  public buckets: string[] = [];
  showStorageBucketList: boolean = false;
  selectedButton: string | null = null;


  constructor(private fb: FormBuilder) 
  { 
    this.createForm(); 
  }


  public createForm(): void {
    this.form = this.fb.group({
      accountName: [null, [Validators.required]],
      vendorID: [1, [Validators.required]],
      description: [null, [Validators.required]],
      accessKey: [null, [Validators.required]],
      secretKey: [null, [Validators.required]],      
    });
  }

  ngOnInit() {
  }


  onExistingAccountChange(ev: string) {
    this.accountSelection = ev;
  }

  public onChooseStorageBucket() {
    if(this.form.valid){
      let accountInfo: ByosBucketRequest =  {
        vendorID: this.form.value.vendorID,
        accKey: this.form.value.accessKey,
        secret: this.form.value.secretKey
      }
      this.chooseStorageBucket.emit(accountInfo);

      this.showStorageBucketList = true;
    }
  }

  onButtonSelect(buttonName: string): void {
    this.selectedButton = buttonName;
    this.disableConfirm = false;

  }

  accountSelectionChange(event: Lookup) {
    if (this.siteStorage.bucketID !== event.id) {
      this.disableConfirm = false;
      this.existingChangeInProgress = true;
    }
    else {
      this.disableConfirm = true;
      this.existingChangeInProgress = false;
    }
  }

  onCloseStorageAccountsDialog() {
    this.closeStorageAccountsDialog.emit();
  }

  onConfirmStorageAccountsDialog() {
    if (this.accountSelection === 'existing') {
      this.confirmExistingStorageAccount.emit(this.selectedAccount);
    }


    if (this.accountSelection === 'add') {
      let accountInfo: ByosBucketCreate =  {

        bucketName: this.selectedButton,
        accountName: this.form.value.accountName,
        description: this.form.value.description,
        vendorID: this.form.value.vendorID,
        accKey: this.form.value.accessKey,
        secret: this.form.value.secretKey,
        key: ""
      }

      this.confirmAddStorageAccount.emit(accountInfo);
    }
  }     
}
