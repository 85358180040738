import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'app/app.state';
import * as adminLPRActions from './store/actions';
import { selectSelectedEntriesHitsTab } from './store/selectors'

@Component({
  selector: 'app-admin-lpr',
  templateUrl: './admin-lpr.component.html',
  styleUrls: ['./admin-lpr.component.css']
})
export class AdminLprComponent implements OnInit {

  public selectedEntriesHitsTab$: Observable<string>;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.selectedEntriesHitsTab$ = this.store.pipe(select(selectSelectedEntriesHitsTab));

  }

  setSelectedTab(tab: string) {
    this.store.dispatch(adminLPRActions.setSelectedEntriesHitsTab({ tab: tab }))
  }


}
