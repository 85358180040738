import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { interval } from "rxjs";
import { startWith, takeWhile } from 'rxjs/operators';
import { TransactionStatus } from './transaction-status';
import { StatusModalService } from './admin-perm-status-modal.service';

@Component({
  selector: 'admin-perm-status-modal',
  templateUrl: './admin-perm-status-modal.component.html',
  styleUrls: ['./admin-perm-status-modal.component.css']
})
export class AdminPermStatusModalComponent implements OnInit {
  @Input("transactionID") transactionID: number;
  @Input("opened") opened: boolean = false;

  @Output("hide") hideEmitter = new EventEmitter();

  private transactionSub: Subscription;
  private alive: boolean = true;
  public transactionStatus: TransactionStatus;

  constructor(
    private statusModalService: StatusModalService
  ) { }

  ngOnInit() { 
    if (this.transactionID === undefined || this.transactionID === 0) {
      console.log("Tried to open show transaction status modal without a transaction ID")
      this.opened = false;
    }

    if (this.transactionSub) {
      this.transactionSub.unsubscribe();
    }

    this.transactionSub = interval(3000).pipe(startWith(0), takeWhile(() => this.alive))
      .subscribe(() => {
        if (this.transactionID !== undefined && this.transactionID !== 0) {
          this.statusModalService.getTransactionStatus(this.transactionID).subscribe(
            t => this.transactionStatus = t)
        }
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  closeStatusModal() {
    this.transactionID = 0;
    this.hideEmitter.emit();
  }
}
