// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  text-align: center;
  margin: 20px;
}

h4 {
  font-weight: normal;
}

.buttons {
  display: inline-block;
}

.titleBox {
  padding: 7px 0px 7px 7px;
  width: 100%;
}

.date-picker-label, .time-picker-label {
  font-weight: bold;
} 

.btn {
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
} 

.disabled {
  text-decoration: none;
  color: black;
}

/* .bs-datepicker-navigation-view > button {
  cursor: help;
  visibility: visible;
}

.date-picker-head > .next, .previous{
  cursor: help;
} */

.datePicker > .next, .previous{
  cursor: pointer;
  

}`, "",{"version":3,"sources":["webpack://./src/app/requests/new-request/when/when.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;EACxB,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;;;;;;;GAOG;;AAEH;EACE,eAAe;;;AAGjB","sourcesContent":[".title {\r\n  text-align: center;\r\n  margin: 20px;\r\n}\r\n\r\nh4 {\r\n  font-weight: normal;\r\n}\r\n\r\n.buttons {\r\n  display: inline-block;\r\n}\r\n\r\n.titleBox {\r\n  padding: 7px 0px 7px 7px;\r\n  width: 100%;\r\n}\r\n\r\n.date-picker-label, .time-picker-label {\r\n  font-weight: bold;\r\n} \r\n\r\n.btn {\r\n  border-radius: 4px;\r\n  border: 1px solid #ccc;\r\n  margin-bottom: 20px;\r\n} \r\n\r\n.disabled {\r\n  text-decoration: none;\r\n  color: black;\r\n}\r\n\r\n/* .bs-datepicker-navigation-view > button {\r\n  cursor: help;\r\n  visibility: visible;\r\n}\r\n\r\n.date-picker-head > .next, .previous{\r\n  cursor: help;\r\n} */\r\n\r\n.datePicker > .next, .previous{\r\n  cursor: pointer;\r\n  \r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
