// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-sidenav {
    width: 200px;
    height: 100%;
    /* background-color: rgb(27, 30, 36); */
    background-color:#28303d;
}

.main {
    background-color:#f2f5f2;
    height: 100%;
    padding: 5px;
    overflow-y: auto;
}

app-sidebar {
    height: 100%;
}

mat-sidenav-container {
    height: 100%;
}

app-navbar {
    height: 6%;
}`, "",{"version":3,"sources":["webpack://./src/app/main/main.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,uCAAuC;IACvC,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;IACxB,YAAY;IACZ,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;AACd","sourcesContent":["mat-sidenav {\r\n    width: 200px;\r\n    height: 100%;\r\n    /* background-color: rgb(27, 30, 36); */\r\n    background-color:#28303d;\r\n}\r\n\r\n.main {\r\n    background-color:#f2f5f2;\r\n    height: 100%;\r\n    padding: 5px;\r\n    overflow-y: auto;\r\n}\r\n\r\napp-sidebar {\r\n    height: 100%;\r\n}\r\n\r\nmat-sidenav-container {\r\n    height: 100%;\r\n}\r\n\r\napp-navbar {\r\n    height: 6%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
