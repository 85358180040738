import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { SiteDashboardComponent } from "./site/site-dashboard/site-dashboard.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { PropertyMapComponent } from "./property-map/property-map.component";
import { UserCreationComponent } from "./client-account-management/user-creation/user-creation.component";
import { ClientAccountManagementComponent } from "./client-account-management/client-account-management.component";
import { EventComponent } from "./events/event.component";
import { EventDetailComponent } from "./events/event-detail/event-detail.component";

import { CameraComponent } from "./camera/camera.component";
import { LiveViewComponent } from "./camera/live-view/live-view.component";
import { RewindComponent } from "./camera/rewind/rewind.component";
import { MotionComponent } from "./motion/motion.component";
import { LightMapComponent } from "./light-map/light-map.component";
import { MessagesComponent } from "./messages/messages.component";
import { EditUserComponent } from "./edit-user/edit-user.component";

import { EventGuard } from "./events/event-guard.service";
import { SiteLoaderGuard } from "./site/site-loader-guard.service";
import { ClientAccountManagementGuard } from "./client-account-management/client-account-management-guard.service";
import { UserLoaderGuard } from "./users/user-loader-guard.service";
import { CameraGuard } from "./camera/camera-guard.service";
import { AppGuard } from "./app-guard.service";
import { DashboardGuard } from "./dashboard/dashboard-guard.service";
import { LightMapGuard } from "./light-map/light-map-guard.service";
import { RewindGuard } from "./camera/rewind/rewind-guard.service";
import { LiveViewGuard } from "./camera/live-view/live-view-guard.service";
import { MessageGuard } from "./messages/message-guard.service";
import { MetricsComponent } from "./metrics/metrics.component";
import { MetricsGuard } from "./metrics/metrics-guard.service";
import { MessageSiteGuard } from "./messages/message-site-guard.service";

import { DocumentComponent } from "./document/document.component";
import { DocumentGuard } from "./document/document-guard.service";

import { FeatureGuard } from "./site-package/feature.guard";
import { FeatureType } from "./site-package/models";
import { MotionLoaderGuard } from "./motion/motion-loader.guard";
import { RawRequestComponent } from "./raw-request/raw-request.component";
import { RawLoaderGuard } from "./raw-request/raw-loader.guard";
import { RawRequestGuard } from "./raw-request/raw-request.guard";
import { LprComponent } from "./lpr/lpr.component";
import { LPRGuard } from "./lpr/lpr.guard";
import { SiteDisabledComponent } from "./site-disabled/site-disabled.component";
import { CvsViewComponent } from "./cvs/cvs-view/cvs-view.component";
import { ServiceRequestComponent } from "./service-request/service-request.component";


import { CvsComponent } from "./cvs/cvs.component";
import { CVSChangesGuard } from "./cvs/cvs-changes-guard";
import { CVSGuard } from "./cvs/cvs.guard";
import { CVSDisabledGuard } from "./cvs/cvs-disabled.guard";
import { CVSViewGuard } from "./cvs/cvs-view.guard";
import { RequestAuthGuard } from './requests/request-auth-guard.service';
import { FeatureFlagGuard } from "./feature-flag/feature-flag-guard.service";

import { PortfolioCameraComponent } from "./camera/portfolio-camera/portfolio-camera.component";
import { PortfolioEventComponent } from "./events/portfolio-event/portfolio-event.component";
import { ExtendedStorageComponent } from "./extended-storage/extended-storage.component";
import { PortfolioSiteWalkComponent } from "./camera/portfolio-site-walk/portfolio-site-walk.component";
import { ExtendedStorageGuard } from "./extended-storage/extended-storage-guard.service";


//TODO: Make guards for site dashboard/property-map and possibly other site specific locations that checks if user has the site

const config: ExtraOptions = {
  canceledNavigationResolution: 'replace',
  paramsInheritanceStrategy: 'always',
  urlUpdateStrategy: 'eager',
};




const appRoutes: Routes = [
  {
    path: "",
    canActivateChild: [AppGuard, UserLoaderGuard],
    children: [
      { path: "dashboard", component: DashboardComponent, canActivate: [UserLoaderGuard, DashboardGuard] },
      { path: "portfolio/cameras", component: PortfolioCameraComponent, canActivate: [UserLoaderGuard, FeatureFlagGuard], data: { featureName: "multi-site-cameras" } },
      { path: "portfolio/events", component: PortfolioEventComponent, canActivate: [UserLoaderGuard, FeatureFlagGuard], data: { featureName: "multi-site-events" } },
      { path: "portfolio/extended-storage", component: ExtendedStorageComponent, canActivate: [UserLoaderGuard, FeatureFlagGuard], data: { featureName: "byos" } },
      { path: "portfolio/site-walk", component: PortfolioSiteWalkComponent, canActivate: [UserLoaderGuard, FeatureFlagGuard], data: { featureName: "site-walk" } },
      { path: "portfolio/extended-storage", component: ExtendedStorageComponent, canActivate: [UserLoaderGuard, ExtendedStorageGuard, FeatureFlagGuard], data: { featureName: "byos" } },
      { path: "site/:siteID", redirectTo: "site/:siteID/dashboard", pathMatch: "full" },     
      { path: "site/:siteID", canActivateChild: [SiteLoaderGuard], children: [
        { path: "dashboard", component: SiteDashboardComponent },
        { path: "property-map", component: PropertyMapComponent,},
        { path: "events", component: EventComponent, canActivate: [EventGuard] },
        { path: "events/:eventID", component: EventDetailComponent, canActivate: [EventGuard] },
        { path: "light-map", component: LightMapComponent, canActivate: [LightMapGuard] },
        { path: "service-request", component: ServiceRequestComponent, canActivate: [RequestAuthGuard] },
        { path: "user-management/new", component: UserCreationComponent, canActivate: [ClientAccountManagementGuard] },
        { path: "user-management", component: ClientAccountManagementComponent, canActivate: [ClientAccountManagementGuard] },
        { path: "cameras", component: CameraComponent, canActivate: [CameraGuard] },
        { path: "cameras/:cameraID/live-view", component: LiveViewComponent, canActivate: [LiveViewGuard] },
        { path: "cameras/:cameraID/rewind", component: RewindComponent, canActivate: [RewindGuard] },
        { path: "metrics", component: MetricsComponent, canActivate: [MetricsGuard] },
        { path: "documents", component: DocumentComponent, canActivate: [DocumentGuard] },  
        // { path: "motion", component: MotionComponent, canActivate: [FeatureGuard, MotionLoaderGuard], data: { feature: FeatureType.Motion } },
        { path: "motion", component: MotionComponent, canActivate: [FeatureGuard, MotionLoaderGuard], data: { feature: FeatureType.Motion } },
        // { path: "raw-request", component: RawRequestComponent, canActivate: [FeatureGuard, RawLoaderGuard, RawRequestGuard], data: { feature: FeatureType.RawRequest } },
        { path: "raw-request", component: RawRequestComponent, canActivate: [FeatureGuard, RawLoaderGuard, RawRequestGuard], data: { feature: FeatureType.RawRequest } },
        { path: "lpr", component: LprComponent, canActivate: [LPRGuard, FeatureGuard], data: { feature: FeatureType.LPR } },
        { path: "cvs/manage", component: CvsComponent,  canDeactivate: [CVSChangesGuard], canActivate: [CVSGuard, CVSDisabledGuard, FeatureGuard], data: { feature: FeatureType.CVS } },
        { path: "cvs/:layoutID", component: CvsViewComponent, canActivate: [CVSViewGuard, CVSDisabledGuard, FeatureGuard], data: { feature: FeatureType.CVS } },
      ]},
      { path: "user/messages", component: MessagesComponent, canActivate: [MessageGuard, MessageSiteGuard] },
      { path: "user/profile", component: EditUserComponent },
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
      { path: "not-found", component: NotFoundComponent },
      { path: "site-disabled", component: SiteDisabledComponent },
      { path: "**", redirectTo: "/not-found", pathMatch: "full" },
    ],
  },
];

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, config);
