// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzone {
    min-height: 300px;
    min-width: 300px;
    display: table;
    background-color: #eee;
    border: dotted 1px #aaa;
    margin: 20px auto;
  }
  
  .text-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  
  .centered {
    font-family: sans-serif;
    font-size: 1.3em;
    font-weight: bold;
    text-align:center;
  }

  .image-container {
    display: inline-block;
    height: 75px;
    width: 75px;
}`, "",{"version":3,"sources":["webpack://./src/app/requests/new-request/file-upload/file-upload.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,uBAAuB;IACvB,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;IACnB,sBAAsB;EACxB;;EAEA;IACE,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,WAAW;AACf","sourcesContent":[".dropzone {\r\n    min-height: 300px;\r\n    min-width: 300px;\r\n    display: table;\r\n    background-color: #eee;\r\n    border: dotted 1px #aaa;\r\n    margin: 20px auto;\r\n  }\r\n  \r\n  .text-wrapper {\r\n    display: table-cell;\r\n    vertical-align: middle;\r\n  }\r\n  \r\n  .centered {\r\n    font-family: sans-serif;\r\n    font-size: 1.3em;\r\n    font-weight: bold;\r\n    text-align:center;\r\n  }\r\n\r\n  .image-container {\r\n    display: inline-block;\r\n    height: 75px;\r\n    width: 75px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
