// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsive-video {
    width: 100%;
    height: auto;
    max-width: 1000px;
}

.playerContainer {
    margin-top: 10px;
    text-align: center;
    padding: 0px;
}

h4 {
    font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/metrics/historical/historical-data/historical-data.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".responsive-video {\r\n    width: 100%;\r\n    height: auto;\r\n    max-width: 1000px;\r\n}\r\n\r\n.playerContainer {\r\n    margin-top: 10px;\r\n    text-align: center;\r\n    padding: 0px;\r\n}\r\n\r\nh4 {\r\n    font-weight: normal;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
