

import { Permissions } from '../permissions';

import { SiteListItem } from '../sites/site-list-item';
import { UserType } from './user-type';
import { Lookup } from 'app/lookup';

export class User {
    public initial: boolean;
    public isTempUser: boolean;
    public accessKey: string;
    public email: string;
    public userName: string;
    public permissions: Permissions;
    public sites: SiteListItem[];
    public siteGroups: Lookup[];
    public singleSites: SiteListItem[];
    public title: string;
    public phoneNumber: string;
    public ext: string;
    public cellNumber: string;
    public firstName: string;
    public lastName: string;
    public contactPreference: string;
    public isWtsEmployee: boolean;
    public id: number;
    public active: boolean;
    public typeFriendly: UserType;
    public typeHard: UserType;
    public permissionsTemplateID: number;
    public oldUsername: string;
    public createdDate?: Date;
    constructor(options?: any) {
        if (options) {
            Object.assign(this, options);
            //may have issues with Object.assign and shallow copy
        }
    }
    getName() {
        return getName(this);
    }
    get siteName():string {
        return (this.sites && this.sites.length > 1) ? "Multiple" : this.sites ? this.sites[0].siteName : ""
    }
}
export function getName(user) {
    if(!user) {
        return "";
    }
        if(user.firstName && user.lastName) {
            return user.firstName + " " + user.lastName;
        }
        return user.userName ? user.userName : "";
}
