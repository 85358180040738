import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ServiceHelpersService } from '../../service-helpers.service';
import { Observable } from 'rxjs';
import { AccessKeyService } from '../../users/access-key.service';
import { map, catchError } from 'rxjs/operators';
import { AlertEntry } from '../../lpr/manage-alert-entries/alert-entry';
import { AlertHit } from '../../lpr/recent-hits/alert-hit';

@Injectable({
  providedIn: 'root'
})
export class AdminLprService {
  public headers = {};

  constructor(
    private http: HttpClient,
    private helper: ServiceHelpersService,
    private accessKeyService: AccessKeyService,    
  ) {
    this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key}));
   }

   getAdminAlertEntries(): Observable<AlertEntry[]> {   
    let headers = new HttpHeaders(this.headers);
    return this.http.get<AlertEntry[]>(`/rest/admin/lpr/alertentries`, { headers: headers }).pipe(catchError(this.helper.handleError), map(alertEntries => {
      if (alertEntries && alertEntries.map) {
        return alertEntries.map(entry => new AlertEntry(entry))
      }
      return alertEntries;
    }));
  }

  getAdminAlertEntryHits(alertEntryID: number): Observable<AlertHit[]> {   
    let headers = new HttpHeaders(this.headers);
    return this.http.get<AlertHit[]>(`/rest/admin/lpr/alertentry/${alertEntryID}/alertentryhits`, { headers: headers }).pipe(catchError(this.helper.handleError), map(hits => {
      if (hits && hits.map) {
        return hits.map(hit => new AlertHit(hit))
      }
      return hits;
    }));
  }

  getAdminAlertHits(): Observable<AlertHit[]> {   
    let headers = new HttpHeaders(this.headers);
    return this.http.get<AlertHit[]>(`/rest/admin/lpr/alerthits`, { headers: headers }).pipe(catchError(this.helper.handleError), map(hits => {
      if (hits && hits.map) {
        return hits.map(hit => new AlertHit(hit))
      }
      return hits;
    }));
  }

  }
