// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map {
    max-width: 750px;
}
.descriptionBox {
    width: 100%;
    resize: none;
}

.clickable {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/service-request/service-request.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".map {\r\n    max-width: 750px;\r\n}\r\n.descriptionBox {\r\n    width: 100%;\r\n    resize: none;\r\n}\r\n\r\n.clickable {\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
