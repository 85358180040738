import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Subject, interval } from "rxjs";
import { WhepService } from "../whep.service";
import { CameraView } from "../camera-view";
import { CameraService } from "../../camera/camera.service";
import { WHEPClient } from "app/camera/live-view/webrtc-player/whepclient";

@Component({
  selector: "camera-view",
  templateUrl: "./camera-view.component.html",
  styleUrls: ["./camera-view.component.scss"],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CameraViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input("showCameraName") set setShowCameraName(value: boolean) {
    if (value) {
      this.showCameraName = value;
    }
  }

  @Input("camera") set setCamera(value: CameraView) {
    this.camera = value;
  }

  @Input("siteID") set setSiteID(value: number) {
    this.siteID = value;
  }

  @ViewChild('videoContainer') videoContainer: ElementRef<HTMLDivElement>;
  @ViewChild("videoElement") videoElement!: ElementRef<HTMLVideoElement>;

  public camera: CameraView;
  public siteID: number;
  public videoLoaded: boolean = true;
  public showCameraName: boolean = false;
  public whepClient: WHEPClient;

  private destroy$ = new Subject<void>();

  constructor(private cameraService: CameraService) {}

  ngOnInit(): void {}


  ngAfterViewInit(): void {
    if (!this.camera || !this.camera.cameraID) {
      return;
    }

    interval(600000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cameraService.getCameraStreamAuth(this.camera.cameraID, this.siteID).subscribe((auth) => {

          this.whepClient.updateUsernamePassword(auth.username, auth.password);
        });
      });

    this.videoElement.nativeElement.onloadedmetadata = (evt) => this.onLoad(evt);
    this.videoElement.nativeElement.muted = true;

    this.whepClient = new WHEPClient(this.camera.camera?.stream.webRTC.url, this.videoElement.nativeElement, this.camera.camera.stream.username, this.camera.camera.stream.password, (err) => this.onError(err));
    
    
    // this.whepService.start(this.camera.url, false);
    // this.whepService.pcSubject.pipe(takeUntil(this.destroy$)).subscribe((pc) => {
    //   pc.ontrack = (evt) => this.onTrack(evt);
    // });

    // this.whepService.errorSubject.pipe(takeUntil(this.destroy$)).subscribe((error) => this.onError(error));
  }

  ngOnDestroy(): void {
    // this.whepService.close();
    this.videoElement.nativeElement.srcObject = null;
    this.whepClient.close();
    this.whepClient = null;

    this.videoLoaded = false;

    this.destroy$.next();
    this.destroy$.complete();
  }

  private onTrack(evt: RTCTrackEvent): void {
    if (evt.track.kind === "video") {
      this.videoElement.nativeElement.srcObject = evt.streams[0];
      this.videoElement.nativeElement.muted = true;
    }
  }

  private onLoad(event: Event): void {
    this.videoLoaded = true;
  }

  private onError(error: Error): void {
    console.error(error);
    this.videoLoaded = false;
  }

  goFullScreen() {
    this.videoContainer.nativeElement.requestFullscreen();
  }
}
