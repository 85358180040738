import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";

@Component({
  selector: "app-time-picker",
  templateUrl: "./time-picker.component.html",
  styleUrls: ["./time-picker.component.scss"],
})
export class TimePickerComponent implements OnInit, OnDestroy {
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formValueChange: EventEmitter<Date> = new EventEmitter<Date>();

  @Input('time') set setTime(value: Date) {
    if((value && this.time && value.getTime() === this.time.getTime())) {
      return;
    }
    this.time = value;
    this.setTimeInForm(value);
  };
  private time: Date;
  public timeForm: UntypedFormGroup;
  public meridianList: Array<string> = ["AM", "PM"];

  private destroy$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder) {}

  public ngOnInit() {
    this.timeForm = this.createGroup();
    if(this.time) {
      this.setTimeInForm(this.time);
    }
    this.timeForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((values: { hour: string; minute: string; meridian: string }) => {
        const date = this.parseTime(values.hour, values.minute, values.meridian);

        const errors = this.getFormValidationErrors();
        const isValid = this.isValidDate(date) && errors.length === 0;

        this.isValid.emit(isValid);
        if(isValid) {
          this.formValueChange.emit(date);
        }
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private createGroup(): UntypedFormGroup {
    const numberExp = /^-?[0-9][^\.]*$/;

    const group = this.fb.group({
      hour: ["", [Validators.required, Validators.min(1), Validators.max(12), Validators.pattern(numberExp)]],
      minute: ["", [Validators.required, Validators.min(0), Validators.max(59), Validators.pattern(numberExp)]],
      meridian: ["", [Validators.required]],
    });

    return group;
  }

  private parseTime(hours: string, minutes: string, meridian: string): Date {
    let hour = this.parseHours(hours, meridian);
    const minute = this.parseMinutes(minutes);
    const format = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
    const date = moment(`${hour}:${minute}:00 ${meridian}`, "h:mm:ss A").format(format);
    return moment(date, format).toDate();
  }

  private parseHours(hours: string, meridian: string): number {
    const hour = parseInt(hours);
    const isPM = this.isPM(meridian);
    if (hour <= 0 || hour > 12) { //(isPM ? 12 : 24)) {
      return NaN;
    }

    return hour;
  }

  private parseMinutes(minutes: string): number {
    const minute = parseInt(minutes);

    if (minute < 0 || minute >= 60) {
      return NaN;
    }

    return minute;
  }

  private isValidDate(value?: Date): boolean {
    if (!value) {
      return false;
    }

    if (value instanceof Date && isNaN(value.getHours())) {
      return false;
    }

    if (value instanceof Date && isNaN(value.getMinutes())) {
      return false;
    }

    if (typeof value === "string") {
      return this.isValidDate(new Date(value));
    }

    return true;
  }

  private setTimeInForm(time: Date) {
    if(!time) {
      return;
    }
    if(!this.timeForm) {
      return;
    }
    let hours = time.getHours();
    let meridian = "AM"
    if(hours === 12) {
      meridian = "PM"
    }
    if(hours > 12) {
      meridian = "PM"
      hours = hours - 12;
    }
    if(hours === 0) {
      hours = 12;
      meridian = "AM"
    }

    this.timeForm.patchValue({hour: hours, minute: time.getMinutes(), meridian: meridian})
    const errors = this.getFormValidationErrors();
    const isValid = this.isValidDate(time) && errors.length === 0;

    this.isValid.emit(isValid);

  }

  private isPM(meridian: string): boolean {
    return meridian === "PM";
  }

  private getFormValidationErrors(): ValidationErrors[] {
    let validationErrors = [];
    Object.keys(this.timeForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.timeForm.get(key).errors;
      if (controlErrors !== null) {
        validationErrors.push(controlErrors);
      }
    });

    return validationErrors;
  }
}
