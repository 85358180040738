import { Injectable } from '@angular/core';

import { UserService } from 'app/users/user.service';
import { PermissionsService } from 'app/permissions.service';
import { filter, map, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminDocumentsGuard  {

  constructor(
    private userService: UserService, 
    private permissions: PermissionsService,
  ) {}

  canActivate() {
    let canActivate = this.userService.getUser().pipe(filter(user => !user.initial), map(user => this.permissions.adminAnyDocuments(user.permissions)), first());
    return canActivate;
  }
}
