import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { CloudVideo } from "../cloud-video";
import { ServiceHelpersService } from "app/service-helpers.service";
import { config } from "app/app.config";
import { TimeObj } from "app/time-obj";

@Injectable()
export class CloudService {
  constructor(private http: HttpClient, private serviceHelpersService: ServiceHelpersService) {}

  public fetch(cameraID: number, time: TimeObj): Observable<CloudVideo[]> {
    const url = `${config.apiUrl}video/cloud`;
    return this.http
      .post<CloudVideo[]>(url, {
        cameraID: cameraID,
        time: time,
      })
      .pipe(catchError(this.serviceHelpersService.handleError));
  }
}
