import { Map, MapData } from '../../map/map';
import { Marker } from '../../markers/marker';

export class SiteDisplayMap extends Map {
    constructor(mapData: MapData) {
        super(mapData);
    }
    drawMarker(marker: Marker) {
        if(marker.type === "event" || marker.type === "map") {
            marker.draw();
        }
    }
    mouseMove(event) {
        return;
    }
    mouseClick(event) {
        return;
    }
}