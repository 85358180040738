import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import * as adminPermActions from './actions'
import { switchMap, map, catchError, concatMap, withLatestFrom } from "rxjs/operators";
import { AdminService } from "app/admin/admin.service";
import { UserService } from "app/users/user.service";
import { AdminPermissionTemplatesService } from "app/admin/admin-permission-templates/admin-permission-templates.service";
import { NotificationsService } from "app/notifications/notifications.service";
import { of } from "rxjs";
import { 
selectUsersGridData,
selectSelectedUsers,
selectAction,
selectNewPermissions,
selectSiteClude,
selectSites,
selectTemplateName,
selectTemplateDescription,
selectTemplatePermissions,
selectPermissionTemplateSelectedForEdit
} from "./selectors";
import { select, Store } from "@ngrx/store";
import { AppState } from "app/app.state";
import { Updates, Update } from "../updates";
import { PermissionTemplate } from "../../admin-permission-templates/permission-template";

@Injectable()
export class AdminPermEffects {

    loadManagementCompanies$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adminPermActions.loadManComps),
            switchMap((action) => {
                return this.adminService.getManagementCompanies().pipe(
                    map(manComps => {
                        return adminPermActions.manCompsLoaded({manComps: manComps});
                    }),
                    catchError(() => {
                        this.notificationService.error("", "Error loading management companies");
                        return of(adminPermActions.loadManCompsFailed);
                    })
                )
            })
        )
    });

    userSearch$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adminPermActions.userSearch),
            switchMap((action) => {
                return this.adminService.userSearch(action.search).pipe(
                    map(users => {
                        return adminPermActions.userSearchSuccess({users: users});
                    }),
                    catchError(() => {
                        this.notificationService.error("", "Error searching for users");
                        return of(adminPermActions.userSearchFailed())
                    })
                )
            })
        )
    });

    submit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adminPermActions.submit),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(
                        this.store.pipe(select(selectSelectedUsers)),
                        this.store.pipe(select(selectAction)),
                        this.store.pipe(select(selectNewPermissions)),
                        this.store.pipe(select(selectSiteClude)),
                        this.store.pipe(select(selectSites)),
                    )
                    
                )
            }),
            switchMap(([submitAction, users, action, newPermissions, siteClude, sites]) => {
                let updates = new Updates();
                updates.updates = [];
                console.log(sites, siteClude)
                for(let user of users) {
                    let update = new Update();
                    update.userID = user.id;
                    update.permission = newPermissions;
                    update.action = action;
                    if(siteClude === "exclude") {
                        update.sitesExcluded = sites;
                    }
                    if(siteClude === "include") {
                        update.sitesIncluded = sites;
                    }
                    update.userName = user.username;
                    updates.updates = [...updates.updates, update];
                }
                return this.adminService.bulkPermissionSubmit(updates).pipe(
                    map(id => {
                        return adminPermActions.submitSuccess({transactionID: id});
                    }),
                    catchError(() => {
                        this.notificationService.error("", "Error Submitting Bulk Permissions");
                        return of(adminPermActions.submitFail());
                    })
                )
            })
        )
    });

    // Admin Permission Templates

    loadPermissionTemplates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adminPermActions.loadPermissionTemplates),
            switchMap((action) => {
                return this.permissionTemplatesService.getPermissionTemplates().pipe(
                    map(templates => {
                        return adminPermActions.permissionTemplatesLoaded({templates: templates});
                    }),
                    catchError(() => {
                        this.notificationService.error("", "Error loading permission templates");
                        return of(adminPermActions.loadPermissionTemplatesFailed);
                    })
                )
            })
        )
    });

    checkTemplateForUsers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adminPermActions.checkTemplateForUsers),

            switchMap((action) => {
                return this.permissionTemplatesService.checkPermissionTemplateForUsers(action.template.id).pipe(
                    map(response => {
                        this.store.dispatch(adminPermActions.setPermissionTemplateSelectedForDelete({template: action.template}));
                        return adminPermActions.setTemplateHasUsers({hasUsers: response.hasUsers});
                    }),
                    catchError(() => {
                        this.notificationService.error("", "Error checking template for users");
                        return of(adminPermActions.cancelDeletePermissionTemplate());
                    })
                )
            })
        )
    });

    deletePermissionTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adminPermActions.deletePermissionTemplate),
            switchMap((action) => {
                 return this.permissionTemplatesService.deletePermissionTemplate(action.id).pipe(
                    map(() => {
                        this.store.dispatch(adminPermActions.cancelDeletePermissionTemplate());
                        this.notificationService.success("", "Success deleting permission template");
                        this.userService.fetchPermissionsTemplates().subscribe(() => {});
                        return adminPermActions.removeDeletedPermissionTemplate({id: action.id});
                    }),
                    catchError(() => {
                        this.notificationService.error("", "Error deleting permission template")
                        return of(adminPermActions.cancelDeletePermissionTemplate());
                    }),
                )
            })
        )
    })

    createPermissionTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adminPermActions.createPermissionTemplate),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(
                        this.store.pipe(select(selectTemplateName)),
                        this.store.pipe(select(selectTemplateDescription)),
                        this.store.pipe(select(selectTemplatePermissions)),
                    )
                )
            }),
            switchMap(([createAction, name, desc, perm]) => {
                let newTemplate: PermissionTemplate = {
                    id: 0,
                    name: name,
                    description: desc,
                    permissions: perm,
                    admin: false,
                    internal: false
                }
                return this.permissionTemplatesService.createPermissionTemplate(newTemplate).pipe(
                    map(template => {
                        this.notificationService.success("", "Success creating permission template");
                        this.store.dispatch(adminPermActions.setTemplateSaving({saving:false}));
                        this.userService.fetchPermissionsTemplates().subscribe(() => {});
                        return adminPermActions.addCreatedPermissionTemplate({ template: template });
                    }),
                    catchError(() => {
                        this.notificationService.error("", "Error creating permission template");
                        this.store.dispatch(adminPermActions.setTemplateSaving({saving:false}));
                        return of(adminPermActions.noAction());
                    }),
                )
            })
        )
    })

    updatePermissionTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adminPermActions.updatePermissionTemplate),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(
                        this.store.pipe(select(selectPermissionTemplateSelectedForEdit)),
                        this.store.pipe(select(selectTemplateName)),
                        this.store.pipe(select(selectTemplateDescription)),
                        this.store.pipe(select(selectTemplatePermissions)),
                    )
                )
            }),
            switchMap(([createAction, template, name, desc, perm]) => {
                let newTemplate: PermissionTemplate = {
                    id: template.id,
                    name: name,
                    description: desc,
                    permissions: perm,
                    admin: false,
                    internal: false
                }
                return this.permissionTemplatesService.updatePermissionTemplate(newTemplate).pipe(
                    map(template => {
                        this.notificationService.success("", "Success updating permission template");
                        this.store.dispatch(adminPermActions.setTemplateSaving({saving:false}));
                        this.userService.fetchPermissionsTemplates().subscribe(() => {});
                        return adminPermActions.replaceUpdatedPermissionTemplate({ template: template });
                    }),
                    catchError(() => {
                        this.notificationService.error("", "Error  permission template");
                        this.store.dispatch(adminPermActions.setTemplateSaving({saving:false}));
                        return of(adminPermActions.noAction());
                    }),
                )
            })
        )
    })

    constructor(
        private actions$: Actions,
        private adminService: AdminService,
        private notificationService: NotificationsService,
        private permissionTemplatesService: AdminPermissionTemplatesService,
        private userService: UserService,
        private store: Store<AppState>,
    ) {}


}