// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td {
    padding: 10px;
    text-align: center;
}

.user, .password, .email, .sites {
    width: 20%;
}

.active, .sendEmail {
    width: 10%;
}

.emailButton,  .deleteButton{
    border: 1px solid #000;
}

.table>tbody>tr>td {
    vertical-align: middle;
}

.wide, .emailButton {
    width: 100%;
}

span {
    display: inline-block;
}

label {
    font-weight: bold;
}

.edit-btn {
    background-color: #fff;
    border: 1px solid #000;
}

.edit-btn:hover {
    background-color: #f5f5f5ee;
    border: 1px solid #000;
} 

`, "",{"version":3,"sources":["webpack://./src/app/admin/temp-users/temp-user-list.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;IAC3B,sBAAsB;AAC1B","sourcesContent":["td {\r\n    padding: 10px;\r\n    text-align: center;\r\n}\r\n\r\n.user, .password, .email, .sites {\r\n    width: 20%;\r\n}\r\n\r\n.active, .sendEmail {\r\n    width: 10%;\r\n}\r\n\r\n.emailButton,  .deleteButton{\r\n    border: 1px solid #000;\r\n}\r\n\r\n.table>tbody>tr>td {\r\n    vertical-align: middle;\r\n}\r\n\r\n.wide, .emailButton {\r\n    width: 100%;\r\n}\r\n\r\nspan {\r\n    display: inline-block;\r\n}\r\n\r\nlabel {\r\n    font-weight: bold;\r\n}\r\n\r\n.edit-btn {\r\n    background-color: #fff;\r\n    border: 1px solid #000;\r\n}\r\n\r\n.edit-btn:hover {\r\n    background-color: #f5f5f5ee;\r\n    border: 1px solid #000;\r\n} \r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
