export class GridSize {
    id: number;
    name: string;
    rows: number;
    columns: number;

    constructor(config?: any) {
        if (config) {
            Object.assign(this, config);
        }
    }
}