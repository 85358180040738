import { Injectable } from '@angular/core';
import statsig from 'statsig-js';
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';
import { BehaviorSubject, interval, Observable, Subject, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../users/user'
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})

export class FeatureFlagService {
  private initializedSubject = new BehaviorSubject<boolean>(false);
  initialized$ = this.initializedSubject.asObservable();
  private experiment: ExperimentClient | null = null;
  private features: BehaviorSubject<ExperimentClient | null> = new BehaviorSubject<ExperimentClient | null>(null);
  private intervalSub: Subscription | null = null;
  constructor() { }

  async initialize(u: User): Promise<void> {
    if (environment.featureFlagProvider === "statsig") {
      let userID = u.id;
      let email = u.email;
      await statsig.initialize(environment.statsig.sdkKey, {
        userID,
        email,
      });
    } else if (environment.featureFlagProvider === "amplitude") {
      this.experiment = Experiment.initialize(environment.amplitude.deploymentKey, {
        exposureTrackingProvider: {
          track: (exposure) => {
            // TODO: Implement exposure tracking
            // analytics.track('$exposure', exposure)
          },
        },
        serverUrl: "/api/feature-flag/proxy",
        flagsServerUrl: "/api/feature-flag/proxy",
      });

      const user = {
        user_id: u.email,
        user_properties: {
          user_id: u.id,
          email: u.email,
          first_name: u.firstName,
          last_name: u.lastName,
          hard_type_id: u.typeHard.id,
          hard_type: u.typeHard.name,
          friendly_type_id: u.typeFriendly.id,
          friendly_type: u.typeFriendly.name,
        },
      };

     
        await this.experiment.start(user);

      this.features.next(this.experiment);
      if (this.intervalSub) {
        this.intervalSub.unsubscribe();
      }
      this.intervalSub = interval(60000).subscribe(() => this.experiment?.fetch().then(() => this.features.next(this.experiment)));
    }

    this.initializedSubject.next(true);
  }

  isFeatureEnabled(featureName: string): boolean {
    if (environment.featureFlagProvider === "statsig") {
      return statsig.checkGate(featureName);
    } else if (environment.featureFlagProvider === "amplitude") {
      const variant = this.experiment?.variant(featureName);
      return variant?.value === "on";
    }

    return false;
  }
  isFeatureEnabled$(featureName): Observable<boolean> {
    return this.features.pipe(map((experiment) => {
      if (!experiment) {
        return false;
      }
      const variant = experiment.variant(featureName);
      return variant?.value === "on";
    }));
  }

  updateUser(u: User): void {
    if (environment.featureFlagProvider === "statsig") {
      let userID = u.id;
      let email = u.email;
      statsig.updateUser({
        userID,
        email,
      });
    } else if (environment.featureFlagProvider === "amplitude") {
      const user = {
        user_id: u.email,
        user_properties: {
          user_id: u.id,
          email: u.email,
          first_name: u.firstName,
          last_name: u.lastName,
          hard_type_id: u.typeHard.id,
          hard_type: u.typeHard.name,
          friendly_type_id: u.typeFriendly.id,
          friendly_type: u.typeFriendly.name,
        },
      };

      this.experiment?.setUser(user);
    }
  }
}
