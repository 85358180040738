import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { AccessKeyService } from "../users/access-key.service";
import { ServiceHelpersService } from "../service-helpers.service";
import { config } from "../app.config";
import { Message } from "./message";

@Injectable()
export class MessageService {
  public headers = {};
  constructor(
    private http: HttpClient,
    private accessKey: AccessKeyService,
    private serviceHelpers: ServiceHelpersService,
  ) {
    this.accessKey.getKey().subscribe((key) => (this.headers = Object.assign(this.headers, { accessKey: key })));
  }

  public getMessages(): Observable<Message[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http
      .get<Message[]>(config.apiUrl + "user/current/messages", { headers: headers })
      .pipe(
        map((messages) => messages.map((message) => new Message(message))),
        catchError(this.serviceHelpers.handleError),
      );
  }

  public markAsRead(id: number) {
    let headers = new HttpHeaders(this.headers);
    return this.http
      .put(config.apiUrl + "user/current/messages/" + id + "/opened", {}, { headers: headers })
      .pipe(catchError(this.serviceHelpers.handleError));
  }
}
