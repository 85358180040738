import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromNewLeadState from "./store/state";
import { newLeadReducer } from './store/reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NewLeadEffects } from './store/effects';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { LabelModule } from "@progress/kendo-angular-label";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { IconsModule } from "@progress/kendo-angular-icons";

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromNewLeadState.NewLeadKey, newLeadReducer),
    EffectsModule.forFeature([NewLeadEffects]),
    BrowserModule,
    BrowserAnimationsModule,
    DropDownsModule,
    LabelModule,
    InputsModule,
    ButtonsModule,
    LayoutModule,
    IconsModule,
    FormsModule,
  ]
})
export class NewLeadModule { }
