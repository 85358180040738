import { SafeUrl } from "@angular/platform-browser";
import { createAction, props } from "@ngrx/store";
import { IotLprResult, IotLprSearchParams } from "../iot-lpr";
import { SiteTimezone } from "../../../site/site-timezone";
import { AdminSiteListItem } from '../../adminSiteListItem';

export const noAction = createAction("[IOT LPR] No Action");

export const searchIotLpr = createAction(
    "[IOT LPR] Search",
    props<{ searchParams: IotLprSearchParams }>()
);

export const searchIotLprSuccess = createAction(
    "[IOT LPR] Search Success",
    props<{ results: IotLprResult[], totalCount: number }>()
);

export const searchIotLprFailure = createAction(
    "[IOT LPR] Search Failure",
    props<{ error: string }>(),
);

export const loadImage = createAction(
    "[IOT LPR] Load Image",
    props<{ id: string; cropped: boolean }>()
);

export const loadImageSuccess = createAction(
    "[IOT LPR] Load Image Success",
    props<{ id: string; imageUrl: SafeUrl }>()
);

export const loadImageFailure = createAction(
    "[IOT LPR] Load Image Failure",
    props<{ id: string; error: string }>()
);

export const fetchSiteTimezones = createAction(
    "[IOT LPR] Fetch Site Timezones",
    props<{ siteIDs: number[] }>()
);

export const fetchSiteTimezonesSuccess = createAction(
    "[IOT LPR] Fetch Site Timezones Success",
    props<{ timezones: SiteTimezone[] }>()
);

export const fetchSiteTimezonesFailure = createAction(
    "[IOT LPR] Fetch Site Timezones Failure",
    props<{ error: string }>()
);

export const fetchSites = createAction(
    "[IOT LPR] Fetch Sites"
);

export const fetchSitesSuccess = createAction(
    "[IOT LPR] Fetch Sites Success",
    props<{ sites: AdminSiteListItem[] }>()
);

export const fetchSitesFailure = createAction(
    "[IOT LPR] Fetch Sites Failure",
    props<{ error: string }>()
);

export const setSelectedSites = createAction(
    "[IOT LPR] Set Selected Sites",
    props<{ sites: AdminSiteListItem[] }>()
);
