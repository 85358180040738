// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-alert-button {
  background-color: #ff8517;
  color: white;
  width: 75px;
  padding: 3px;
}

.remove-alert-button {
  background-color: #db4240;
  color: white;
  width: 75px;
  padding: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/lpr/manage-alert-entries/manage-alert-entries.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACI,yBCgBO;EDfP,YAAA;EACA,WAAA;EACA,YAAA;AADJ;;AAIA;EACI,yBCJI;EDKJ,YAAA;EACA,WAAA;EACA,YAAA;AADJ","sourcesContent":["@import \"../../../variables.scss\";\r\n\r\n.edit-alert-button {\r\n    background-color:  $series-e;\r\n    color: white;\r\n    width: 75px;\r\n    padding: 3px;\r\n}\r\n\r\n.remove-alert-button {\r\n    background-color: $error;\r\n    color: white; \r\n    width: 75px; \r\n    padding: 3px;\r\n}","$base-theme:Default;\r\n$skin-name:blue;\r\n$accent: #13688c;\r\n$info: #0066cc;\r\n$success: #37b400;\r\n$warning: #ffb400;\r\n$error: #db4240;\r\n$text-color: #43aaa3;\r\n$bg-color: #ffffff;\r\n$base-text: #003f59;\r\n$base-bg: #daecf4;\r\n$hovered-text: #003f59;\r\n$hovered-bg: #7bd2f6;\r\n$selected-text: #ffffff;\r\n$selected-bg: #28303d;\r\n$series-a: #314652;\r\n$series-b: #0098ee;\r\n$series-c: #7bd2f6;\r\n$series-d: #ffb800;\r\n$series-e: #ff8517;\r\n$series-f: #e34a00;\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
