import { Component, Input } from '@angular/core';


import { User, getName } from '../../../users/user';
import { EventNote } from '../../event-note';

@Component({
    selector: 'app-note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.css'],
})
export class NoteComponent {
    @Input('note') set setNote(value: EventNote) {
        this.note = value;
    };
    @Input('author') author: User;
    note: any;
    constructor() {}
    getName(user) {
        return getName(user);
    }
}
//maybe add wts logo for wts notes