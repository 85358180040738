import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { menuIcon, filterIcon, SVGIcon, minHeightIcon, chartBubbleIcon, searchIcon } from "@progress/kendo-svg-icons";
import { AdaptiveMode } from "@progress/kendo-angular-dateinputs";
import { Lookup } from '../../lookup';
import { FeatureFlagService } from '../../feature-flag/feature-flag.service';
import { ResizedEvent } from '../../angular-resize.directive';


@Component({
  selector: 'app-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.css']
})
export class EventFilterComponent implements OnInit {

  @Input() sites: Lookup[];
  @Input() cfTagCategories: Lookup[];
  @Input() statusCodes: Lookup[];


  @Input('siteFilter') set setSiteFilter(value: Lookup[]) {
    if (value) {
      this.siteFilter = value;
    }
  }

  @Input('cfTagCategoryFilter') set setCfTagCategoryFilter(value: Lookup[]) {
    if (value) {
      this.cfTagCategoryFilter = value;
    }
  }

  @Input('startDateFilter') set setStartDateFilter(value: Date) {
    this.startDateFilter = value;
  }

  @Input('endDateFilter') set setEndDateFilter(value: Date) {
    this.endDateFilter = value;
  }

  @Input('statusFilter') set setStatusFilter(value: Lookup[]) {
    this.statusFilter = value;
  }

  @Input('textFilter') set setTextFilter(value: string) {
    this.textFilter = value;
  }

  @Output() siteFilterChange: EventEmitter<Lookup[]> = new EventEmitter<Lookup[]>();
  @Output() cfTagCategoryFilterChange: EventEmitter<Lookup[]> = new EventEmitter<Lookup[]>();
  @Output() startDateFilterChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() endDateFilterChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() statusFilterChange: EventEmitter<Lookup[]> = new EventEmitter<Lookup[]>();
  @Output() textFilterChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() clearFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() generateEventSynopsis: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() generateEventSynopsisStream: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() toggleEventMetrics: EventEmitter<any> = new EventEmitter<any>();

  public siteFilter: Lookup[] = [];
  public cfTagCategoryFilter: Lookup[] = [];
  public startDateFilter: Date;
  public endDateFilter: Date;
  public statusFilter: Lookup[] = [];
  public textFilter: string = '';

  public menuSVG: SVGIcon = menuIcon;
  public filterSVG: SVGIcon = filterIcon;
  public minHeightSVG: SVGIcon = minHeightIcon;
  public chartBubbleSVG: SVGIcon = chartBubbleIcon;
  public searchIcon: SVGIcon = searchIcon;


  public appBarWidth: number = 0; 

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public adaptiveMode: AdaptiveMode = "auto";

  public showAISynopsis$: Observable<boolean> = new BehaviorSubject<boolean>(false);
  public collapseWidth: number = 1590;
  
  private destroy$ = new Subject<void>();

  constructor(private featureFlagService: FeatureFlagService) { }

  ngOnInit(): void {
    this.showAISynopsis$ = this.featureFlagService.isFeatureEnabled$('event-ai-synopsis').pipe(takeUntil(this.destroy$));
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSiteFilterChange() {
    this.siteFilterChange.emit(this.siteFilter);
  }

  onCfTagCategoryFilterChange() {
    this.cfTagCategoryFilterChange.emit(this.cfTagCategoryFilter);
  }

  onStartDateChange() {
    this.startDateFilterChange.emit(this.startDateFilter);
  }

  onEndDateChange() {
    this.endDateFilterChange.emit(this.endDateFilter);
  }

  onStatusFilterChange() {
    console.log(this.statusFilter)
    this.statusFilterChange.emit(this.statusFilter);
  }

  onClearFilters() {
    this.clearFilters.emit();
  }

  onTextFilterChange() {
    this.textFilterChange.emit(this.textFilter.trim());
  }


  onClose(e) {
    console.log(e);
    if (e.item.contentTemplate) {
      e.preventDefault();
    }
  }

  onFiltersResized(event: ResizedEvent) {
    this.appBarWidth = Math.round(event.newRect.width);
  }

  onClearDates() {
    this.startDateFilter = null;
    this.endDateFilter = null;
    this.startDateFilterChange.emit(this.startDateFilter);
    this.endDateFilterChange.emit(this.endDateFilter);
  }

  onGenerateEventSynopsis(): void {
    this.generateEventSynopsis.emit();
  }

  onGenerateEventSynopsisStream(): void {
    this.generateEventSynopsisStream.emit();
  }

  onToggleMetrics() {
    this.toggleEventMetrics.emit();  
  }

}
