// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player-outer-container {
  width: 640px;
  text-align: center;
}

.player-inner-container {
  clear: both;
  position: relative;
}

.video-player {
  width: 640px;
  height: auto;
}

.playback-rate-container {
  float: left;
}

.playback-rate-button {
  width: 150px;
  background-color: #43aaa3;
  color: white;
  font-size: 14px;
}

.found-it-container {
  float: right;
}

.found-it-button {
  width: 150px;
  color: white;
  background-color: #43aaa3;
  font-size: 14px;
}

.slider-container {
  display: inline-block;
  margin: 0 auto;
}

.logo {
  display: inline-block;
  z-index: 1;
  position: absolute;
  width: 50px;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/cloud/cloud-player/cloud-player.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,YAAA;EACA,yBAAA;EACA,YAAA;EACA,eAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;AACJ;;AAEA;EACI,qBAAA;EACA,cAAA;AACJ;;AAEA;EACI,qBAAA;EACA,UAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;AACJ","sourcesContent":[".player-outer-container {\r\n    width:640px;\r\n    text-align:center;\r\n}\r\n\r\n.player-inner-container {\r\n    clear: both;\r\n    position: relative;\r\n}\r\n\r\n.video-player {\r\n    width: 640px;\r\n    height: auto;\r\n}\r\n\r\n.playback-rate-container {\r\n    float:left;\r\n}\r\n\r\n.playback-rate-button {\r\n    width: 150px;\r\n    background-color: #43aaa3;\r\n    color: white;\r\n    font-size: 14px;\r\n}\r\n\r\n.found-it-container {\r\n    float:right;\r\n}\r\n\r\n.found-it-button {\r\n    width: 150px;\r\n    color: white;\r\n    background-color: #43aaa3;\r\n    font-size:14px;\r\n}\r\n\r\n.slider-container {\r\n    display: inline-block;\r\n    margin:0 auto;\r\n\r\n}\r\n.logo {\r\n    display: inline-block;\r\n    z-index: 1;\r\n    position: absolute;\r\n    width: 50px;\r\n    right: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
