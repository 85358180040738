import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, interval, Subscription } from 'rxjs';
import { map, startWith, take, takeWhile } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../app.state';
import { TOGGLE_SIDEBAR, CLOSE_SIDEBAR } from '../sidebar/sidebar.reducer';
import { UserService } from '../users/user.service';
import { SiteService } from '../site/site.service';
import { UserAgentService } from '../user-agent.service';
import * as messagesActions from '../messages/store/actions';
import { selectUserNewMessagesCount } from '../messages/store/selectors';
import { PermissionsService } from '../permissions.service';
import { User } from '../users/user';
import { Site } from '../site/site';
import { AnalyticsService } from '../analytics.service';
import { SurvicateService } from '../survicate.service';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnDestroy {
  
  public user: Observable<User>;
  private surveyUser: User;
  public tempUser: Observable<boolean>;
  public site: Observable<Site>;
  public subscription;
  public sideOpen: Observable<boolean>;
  public mobile: boolean;
  public userNewMessagesCount$: Observable<number>;
  public alive: boolean = true;
  private messageSub: Subscription;  
  public error: string;
  public saveInProgress: boolean = false;
  public appendFooter: boolean = false;  
  
  constructor(
    public userService: UserService,
    public siteService: SiteService,
    public permissionsService: PermissionsService,
    public store: Store<AppState>,
    public userAgentService: UserAgentService,
    private analyticsService: AnalyticsService, 
    private survicateService: SurvicateService,
    private featureFlagService: FeatureFlagService,
    ) { }

  ngOnInit() {
    this.mobile = this.userAgentService.mobile();
    this.user = this.userService.getUser();
    this.tempUser = this.userService.getUser().pipe(map(user => user.isTempUser));
    this.sideOpen = this.store.select(s => s.sidebarOpen);
    this.site = this.siteService.getSite();
    if(this.mobile) {
      this.store.dispatch({type: CLOSE_SIDEBAR });
    }
    this.userService.getUser().subscribe(user => {
      if (user && user.id) {
        this.featureFlagService.initialize(user);

        this.analyticsService.identify(user.email, {
          user_id: user.id,
          email: user.email,
          first_name: user.firstName,
          last_name: user.lastName,
          hard_type_id: user.typeHard.id,
          hard_type: user.typeHard.name,
          friendly_type_id: user.typeFriendly.id,
          friendly_type: user.typeFriendly.name,
        });

        this.surveyUser = user;
      }

      if(this.messageSub) {
        this.messageSub.unsubscribe();
      }

      if(user && user.permissions && this.permissionsService.user('canMessage', user.permissions)) {
        this.messageSub = interval(60000).pipe(startWith(0), takeWhile(() => this.alive))
        .subscribe(() => {
          this.store.dispatch(messagesActions.loadUserMessages());
        });
      }
    });

    this.userService.getUserSurveyData().subscribe(data => {
      if (this.surveyUser && data && data.isSurvicate) {
        this.survicateService.loadSurvicate(this.surveyUser, data);
      }
    });

    this.userNewMessagesCount$ = this.store.pipe(select(selectUserNewMessagesCount));

  }

  ngOnDestroy() {
    this.alive = false;
  }

  onUpdateTemp(user) {
    this.saveInProgress = true;
    this.userService.updateCurrentUser(user).subscribe(u => {
      this.error = ""
      this.saveInProgress = false;
    }, e => {
      this.error = e;
      this.saveInProgress = false;
    });
  }

  onLogout() {
    window.location.href = "/logout";
  }

  toggleSide() {
    this.store.dispatch({type: TOGGLE_SIDEBAR});
  }

}
