import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';


import { UserService } from '../../users/user.service';
import { SiteListService } from '../../sites/site-list.service';
import { AppState } from '../../app.state';
import { UPDATE_TEMP_USER } from '../../users/user.reducer';
import { TempUserEmailService } from './temp-user-email.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { SiteListItem } from '../../sites/site-list-item';

import { User } from '../../users/user';

import { process, State } from '@progress/kendo-data-query';
import { GridComponent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { AdminService } from '../admin.service';
import { Lookup } from '../../lookup';

@Component({
    selector: 'app-temp-user-list',
    templateUrl: './temp-user-list.component.html',
    styleUrls: ['./temp-user-list.component.css'],
})
export class TempUserListComponent implements OnInit{

    public showTempUsers: boolean = false;

    public state: State = {
    }
    public users: any[] = [];
    private usersSub;
    public filter: string = "";
    public deleting: boolean = false;
    public deletingUser: User;
    public showDelete: boolean = false;
    public gridData: GridDataResult = process(this.users, this.state)
    public salesmen: Observable<Lookup[]>;
    public selectedSalesmanID: number = null;
    
    constructor(public userService: UserService, public siteListService: SiteListService, public store: Store<AppState>, public emailService: TempUserEmailService, public notificationsService: NotificationsService, public router:Router, private adminService: AdminService) {}

    ngOnInit() {
        this.usersSub = this.userService.getTempUsers().subscribe( users => {
            [...users].sort((a,b) => {
                if(a.id > b.id) {
                    return -1;
                }
                if(a.id < b.id) {
                    return 1;
                }
                return 0;
            })
            this.users = users;
            this.updateTempUsers();
        });
        this.salesmen = this.adminService.getSalesmen();
        this.loadData();
    }
    ngOnDestroy() {
        this.usersSub.unsubscribe();
    }
    selectSalesman(id: number) {
        this.selectedSalesmanID = id;
        this.loadData();
    }
    loadData() {
        this.userService.fetchTempUsers(this.selectedSalesmanID).subscribe();
    }
    dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.updateTempUsers();
    }
    updateTempUsers() {
        let users = this.users;
        users = this.users.filter(user => this.filterUser(user));
        if(this.showTempUsers) {
            this.gridData = process(users, this.state);
        } else {
            this.gridData = process(users.filter(user => user.needsTraining), this.state)
        }        
    }
    filterUser(user: User): boolean {
        if(this.filter === "") {
            return true;
        }
        let filter = this.filter;
        let fields = ["userName", "firstName", "lastName", "email"];
        for(let i = 0; i < fields.length; i++) {
            if(this.checkField(user, fields[i])) {
                return true
            }
        }
        let siteString = "";
        if(user.sites && user.sites.length > 0) {
            user.sites.forEach(site => siteString += (site.siteName + " "));
            if(siteString.toLowerCase().indexOf(filter) !== -1) {
                return true;
            }
        }        
        return false;
    }
    filterChange() {
        this.updateTempUsers();
    }
    checkField(user: User, field: string): boolean {
        if(!this.filter) {
            return true;
        }
        let filter = this.filter.toLowerCase();
        return user[field] && (user[field] + "").toLowerCase().indexOf(filter) !== -1
    }
    toggleUser(user) {
        this.userService.updateUser({id: user.id, active: !user.active}).subscribe(user => this.store.dispatch({ type: UPDATE_TEMP_USER, payload: Object.assign(user, {active: user.active})}), error => {
            this.notificationsService.error("", "Error updating user");
        } );
    }    
    edit(user) {
        this.router.navigate(['admin', 'users', 'edit', user.id]);
    }    
    sites(user) {
        if(user.sites) {
            if(user.sites.length === 1) {
                return user.sites[0].siteName;
            }
            if(user.sites.length === 0) {
                return "";
            }
            return "multiple";
        }
        return "";
    }
    sendEmail(user) {
        user.sendingEmail = true;
        this.emailService.sendEmail(user.id).subscribe(()=> {
            user.sendingEmail = false;
            this.notificationsService.success("", "Email Sent");
        }, () => {
            user.sendingEmail = false
            this.notificationsService.error("", "Error Sending Email");
        });
    }
    deleteUser(user) {
        this.deletingUser = user;
        this.showDelete = true;
    }
    cancelDelete() {
        this.showDelete = false;
        this.deletingUser = null;
    }
    confirmDelete(user) {
        this.deleting = true;
        this.adminService.deleteUser(this.deletingUser.id).subscribe( resp => {
            this.users = this.users.filter(u => u.id !== this.deletingUser.id);
            this.updateTempUsers();
            this.deleting = false;
            this.showDelete = false;
            this.deletingUser = null;
        }, e => {
            this.notificationsService.error("", "Error deleting " + this.deletingUser.userName);
            this.deleting = false;
            this.showDelete = false;
            this.deletingUser = null;
        })
    }
}