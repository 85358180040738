import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";


import * as fromAdminPermissionsState from './store/state';
import { adminPermReducer } from "./store/reducers";
import { EffectsModule } from "@ngrx/effects";
import { AdminPermEffects } from "./store/effects";


@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature(fromAdminPermissionsState.adminPermissionsKey, adminPermReducer),
        EffectsModule.forFeature([AdminPermEffects]),
    ],
    providers: [
        AdminPermEffects,
    ]
})
export class AdminPermModule { }