// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.list-container {
    border-width: 1px;
    border-color: black;
    border-style: solid;
    border-radius: 3px;
    min-height: 100px;
    margin: 10px;
    overflow-y: auto;
    height: 100%;
    padding: 3px;
    max-height: calc(100vh - 300px);
}

input {
    box-sizing: border-box;
}

.filter {
    margin-left: 10px;
}

label {
    min-height: 26px;
    font-weight: bold;
}
.error {
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-site-groups/site-group-edit-form/site-group-edit-form.component.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,+BAA+B;AACnC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,UAAU;AACd","sourcesContent":["\r\n.list-container {\r\n    border-width: 1px;\r\n    border-color: black;\r\n    border-style: solid;\r\n    border-radius: 3px;\r\n    min-height: 100px;\r\n    margin: 10px;\r\n    overflow-y: auto;\r\n    height: 100%;\r\n    padding: 3px;\r\n    max-height: calc(100vh - 300px);\r\n}\r\n\r\ninput {\r\n    box-sizing: border-box;\r\n}\r\n\r\n.filter {\r\n    margin-left: 10px;\r\n}\r\n\r\nlabel {\r\n    min-height: 26px;\r\n    font-weight: bold;\r\n}\r\n.error {\r\n    color: red;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
