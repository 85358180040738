import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, withLatestFrom, concatMap, catchError, tap, flatMap, filter } from 'rxjs/operators';
import * as CVSActions from './actions';
import { Store, select, createAction } from "@ngrx/store";
import { AppState } from "app/app.state";
import { CvsService } from '../cvs.service';
import { of } from "rxjs";
import { NotificationsService } from '../../notifications/notifications.service';
import { CameraService } from '../../camera/camera.service';
import { selectCVSSiteID, selectLayouts, selectSelectedLayout } from "./selectors";
import { Layout } from "../layout";
import { PermissionsService } from "app/permissions.service";

@Injectable()
export class CVSEffects {

    loadLayouts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CVSActions.loadLayouts),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.select(s => s.user).pipe(filter(u => !u.initial)), this.store.select(s => s.site)),
                )
            }),
            switchMap(([action, user, site]) => {
                if(!this.permissions.site(site.siteID, "canViewCVS") && !this.permissions.site(site.siteID, "canManageCVS")) {
                    return of(CVSActions.noAction());
                }
                return this.cvsService.getLayouts(action.siteID).pipe(
                    map(data => CVSActions.setLayouts({layouts: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading layouts")
                        return of(CVSActions.noAction());
                    }),
                )
            })
        )
    })

    loadLayout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CVSActions.loadLayout),
            switchMap((action) => {
                return this.cvsService.getLayout(action.siteID, action.layoutID, action.withCameras).pipe(
                    map(data => CVSActions.setSelectedLayout({layout: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading layout")
                        return of(CVSActions.noAction());
                    })
                )
            })
        )
    })

    loadGridSizes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CVSActions.loadGridSizes),
            switchMap((action) => {
                return this.cvsService.getGridSizes(action.siteID).pipe(
                    map(data => CVSActions.setGridSizes({gridSizes: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading grid sizes")
                        return of(CVSActions.noAction());
                    }),
                )
            })
        )
    })

    addLayout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CVSActions.addLayout),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectCVSSiteID))),
                    withLatestFrom(this.store.pipe(select(selectLayouts))),                    
                )
            }),
            switchMap(([[action, siteID], layouts]) => {
                let layout = Object.assign(new Layout(), action.layout)
                let cvsName = this.genLayoutName(layouts);
                layout.name = cvsName;
                layout.siteID = siteID;
                return this.cvsService.addLayout(layout).pipe(
                    switchMap(layout => [
                        CVSActions.appendAddedLayout({layout: layout}),
                    ]),
                    catchError(() => {
                        this.notificationsService.error("", "Error adding new cvs layout")
                        return of(CVSActions.noAction());
                    }),
                )
            })
        )
    })

    updateLayout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CVSActions.updateLayout),
            switchMap((action) => {
                return this.cvsService.updateLayout(action.layout).pipe(
                    switchMap(layout => [                        
                        CVSActions.replaceLayout({layout: layout}),
                    ]),
                    catchError(() => {
                        this.notificationsService.error("", "Error updating cvs layout")
                        return of(CVSActions.noAction());
                    }),
                )
            })
        )
    })

    deleteLayout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CVSActions.deleteLayout),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectSelectedLayout))),
                )
            }),
            switchMap(([action, layout]) => {
                return this.cvsService.deleteLayout(layout).pipe(
                    switchMap(() => [                        
                        CVSActions.removeLayout({id: layout.id}),
                    ]),
                    catchError(() => {
                        this.notificationsService.error("", "Error deleting cvs layout")
                        return of(CVSActions.noAction());
                    }),
                )
            })
        )
    })

    generateLayouts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CVSActions.generateLayouts),
            switchMap((action) => {
                return this.cvsService.generateCVS(action.siteID, action.gridSizeID).pipe(
                    map(layouts => {
                        return CVSActions.setLayouts({layouts});
                    }),
                    catchError(() => {
                        this.notificationsService.error("", "Error generating cvs");
                        return of(CVSActions.noAction());
                    })
                )
            })
        )
    })

    loadCameras$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CVSActions.loadCameras),
            switchMap((action) => {
                return this.cameraService.getCamerasForSite(action.siteID).pipe(
                    map(data => CVSActions.setCameras({cameras: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading cameras")
                        return of(CVSActions.noAction());
                    }),
                )
            })
        )
    })

    generateStandaloneSession$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CVSActions.generateStandaloneSession),
            switchMap((action) => {
                return this.cvsService.generateStandaloneSession(action.siteID, action.layoutID).pipe(
                    map(resp => {
                        if(resp.status < 200 || resp.status >= 300) {
                            throw new Error('Bad response status: ' + resp.status);
                        }
                        return CVSActions.addStandaloneSession({siteID: action.siteID});
                    }),
                    catchError(() =>{
                        return of(CVSActions.noAction());
                    })
                )
            })
        )
    })

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private cvsService: CvsService,
        private cameraService: CameraService,         
        private notificationsService: NotificationsService,
        private permissions: PermissionsService,
    ) {}

    genLayoutName(layouts: Layout[]) {
        let cvsBaseName = "New Layout";
        let cvsNumber = 1;
        let cvsName = cvsBaseName + " " + cvsNumber;
        for (; ;) {
            if (layouts.some(l => l.name.toLowerCase().trim() === cvsName.toLowerCase().trim())) {
                cvsNumber++;
                cvsName = cvsBaseName + " " + cvsNumber.toString();
                continue;
            } else {
                return cvsName;
            }
        }
    }
    
}

