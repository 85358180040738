import { AppState } from "app/app.state";
import { createSelector } from "@ngrx/store";
import { Request } from "./request";
import { Filter, Sort } from "./event.reducer";
import { User, getName } from "app/users/user";


export const selectRequests = (state: AppState) => state.requests;
export const selectRequestsFilter = (state: AppState) => state.eventsFilter;
export const selectRequestsSort = (state: AppState) => state.eventsSort;
export const selectUser = (state: AppState) => state.user;

export const selectFilteredSortedRequests = createSelector(selectRequests, selectRequestsFilter, selectRequestsSort, selectUser, (requests: Request[], filter: Filter, sort: Sort, user: User) => {
    return sortRequests(filterRequests(requests, filter, user), sort);
})

function filterRequests(requests: Request[], filter: Filter, user: User): Request[] {
    let currentRequests = requests;
    if (filter.byStatus) {
        currentRequests = currentRequests.filter(request => request.statusCode === filter.status);
    }
    if (filter.byTime) {
        let firstTime = new Date();
        firstTime.setHours(0, 0, 0, 0);
        firstTime.setDate(firstTime.getDate() - filter.days);
        currentRequests = currentRequests.filter(request => {
            return request.requested.getTime() > firstTime.getTime();
        })
    }
    if (filter.onlyMine) {
        currentRequests = currentRequests.filter(request => request.requester.id === user.id);
    }
    if (filter.search) {
        currentRequests = currentRequests.filter(request => {
            return searchRequest(request, filter.search);
        })
    }
    return currentRequests;
}

function searchRequest(request: Request, search: string): boolean {
    if (!search) {
        return true;
    }
    search = search.toLowerCase();
    if (request.description && request.description.toLowerCase().indexOf(search) !== -1) {
        return true;
    }
    if (request.summaries && request.summaries.length > 0) {
        for (let i = 0; i < request.summaries.length; i++) {
            let text = request.summaries[i].text as string;
            if (text.toLowerCase) {
                if (text.toLowerCase().indexOf(search) !== -1) {
                    return true;
                }
            }
        }
    }
    return false;
}

function sortRequests(requests: Request[], sort: Sort): Request[] {
    if (sort.field === "requester") {
        return [...requests].sort((a, b) => {
            return (getName(a.requester) <= getName(b.requester) ? -1 : 1) * (sort.reverse ? -1 : 1);
        })
    }
    if (sort.field === "submitted") {
        return [...requests].sort((a, b) => {
            return (a.requested.getTime() - b.requested.getTime()) * (sort.reverse ? -1 : 1);
        })
    }
    return [...requests].sort((a, b) => {
        return (a[sort.field] <= b[sort.field] ? -1 : 1) * (sort.reverse ? -1 : 1);
    })
}