// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    padding-bottom: 20px;
    padding-top: 20px
}
h3 {
    display: inline;
    font-size: 1.5rem;
}
.material-icons-round {
    cursor: pointer;
}

label {
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/requests/new-request/contact-info/other-officer/other-officer.component.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB;AACJ;AACA;IACI,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".card {\r\n    padding-bottom: 20px;\r\n    padding-top: 20px\r\n}\r\nh3 {\r\n    display: inline;\r\n    font-size: 1.5rem;\r\n}\r\n.material-icons-round {\r\n    cursor: pointer;\r\n}\r\n\r\nlabel {\r\n    font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
