export class WHEPClient {
    baseURL: string;
    pc: RTCPeerConnection;
    restartTimeout: any;
    eTag: string;
    queuedCandidates: any[];
    offerData: any;
    videoElement: HTMLVideoElement;
    username: string;
    password: string;
    onError: (err: any) => void;
    retry: boolean = true;
    sessionURL: string = '';
    startedClose: boolean = false;

    constructor(baseURL: string, videoElement: HTMLVideoElement, username: string, password: string, onError: (err: any) => void) {
        this.baseURL = baseURL;
        this.pc = null;
        this.restartTimeout = null;
        this.eTag = '';
        this.queuedCandidates = [];
        this.videoElement = videoElement;
        this.username = username;
        this.password = password;
        this.onError = onError;
        this.start();
    }
    updateUsernamePassword(username: string, password: string) {
        this.username = username;
        this.password = password;
    }
    start() {
        if (this.startedClose) {
            this.close();
            return;
        }
        fetch(`${this.baseURL}/whep`, {
        method: 'OPTIONS',
        headers: {
            "Authorization": "Basic " + btoa(this.username + ":" + this.password),
        }
        })
        .then((res) => this.onIceServers(res))
        .catch((err) => {
            console.log('error: ' + err);
            this.scheduleRestart();
        });
    }
    cancelRestart() {
        if (this.restartTimeout !== null) {
            clearTimeout(this.restartTimeout);
            this.restartTimeout = null;
        }
        this.retry = false;
    }
    close() {
        this.startedClose = true;
        this.cancelRestart();

        if (this.pc !== null) {
            this.pc.ontrack = null;
            this.pc.onicecandidate = null;
            this.pc.oniceconnectionstatechange = null;
            this.pc.onsignalingstatechange = null;
            this.pc.onicegatheringstatechange = null;
            this.pc.onnegotiationneeded = null;
            if (this.videoElement) {
                let obj: any = this.videoElement.srcObject;
                if(obj && obj.getTracks) {
                    obj.getTracks().forEach((track) => track.stop());
                }
            }
            this.pc.close();
            this.pc = null;
            this.videoElement.removeAttribute("src");
            this.videoElement.removeAttribute("srcObject");
        }

        this.eTag = "";
        this.queuedCandidates = [];
    }    
    onIceServers(res) {
        if (this.startedClose) {
            this.close();
            return;
        }
        this.pc = new RTCPeerConnection({
            iceServers: linkToIceServers(res.headers.get('Link')),
        });

        const direction = "sendrecv";
        this.pc.addTransceiver("video", { direction });
        this.pc.addTransceiver("audio", { direction });

        this.pc.onicecandidate = (evt) => this.onLocalCandidate(evt);
        this.pc.oniceconnectionstatechange = () => this.onConnectionState();

        this.pc.ontrack = (evt) => {
            this.videoElement.srcObject = evt.streams[0];
        };
        if (this.startedClose) {
            this.close();
            return;
        }
        this.pc.createOffer()
            .then((desc) => {
                this.offerData = parseOffer(desc.sdp);
                this.pc.setLocalDescription(desc);
                fetch(`${this.baseURL}/whep`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/sdp',
                        'Authorization': 'Basic ' + btoa(this.username + ':' + this.password)
                    },
                    body: desc.sdp,
                })
                    .then((res) => {
                        if (res.status !== 201) {
                            throw new Error('bad status code');
                        }
                        this.sessionURL = `${this.baseURL}/${res.headers.get('Location') || ''}`;
                        this.eTag = res.headers.get('ETag');
                        return res.text();
                    })
                    .then((sdp) => {
                        if (this.startedClose) {
                            this.close();
                            return;
                        }
                        this.onRemoteDescription(new RTCSessionDescription({
                        type: 'answer',
                        sdp,
                    }))})
                    .catch((err) => {
                        console.log('error: ' + err);
                        this.scheduleRestart();
                    });
            });
    }

    onConnectionState() {
        if (this.startedClose) {
            this.close();
            return;
        }
        if (this.restartTimeout !== null) {
            return;
        }

        switch (this.pc.iceConnectionState) {
            case "disconnected":
                this.scheduleRestart();
        }
    }

    onRemoteDescription(answer) {
        if (this.startedClose) {
            this.close();
            return;
        }
        if (this.restartTimeout !== null) {
            return;
        }

        this.pc.setRemoteDescription(new RTCSessionDescription(answer));

        if (this.queuedCandidates.length !== 0) {
            this.sendLocalCandidates(this.queuedCandidates);
            this.queuedCandidates = [];
        }
    }

    onLocalCandidate(evt) {
        if (this.startedClose) {
            this.close();
            return;
        }
        if (this.restartTimeout !== null) {
            return;
        }

        if (evt.candidate !== null) {
            if (this.sessionURL === '') {
                this.queuedCandidates.push(evt.candidate);
            } else {
                this.sendLocalCandidates([evt.candidate])
            }
        }
    }

    sendLocalCandidates(candidates) {
        if (this.startedClose) {
            this.close();
            return;
        }
        fetch(this.sessionURL, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/trickle-ice-sdpfrag',
                'If-Match': this.eTag,
                'Authorization': 'Basic ' + btoa(this.username + ':' + this.password)
            },
            body: generateSdpFragment(this.offerData, candidates),
        })
            .then((res) => {
                if (this.startedClose) {
                    this.close();
                    return;
                }
                if (res.status !== 204) {
                    throw new Error('bad status code');
                }
            })
            .catch((err) => {
                console.log('error: ' + err);
                this.scheduleRestart();
            });
    }

    scheduleRestart() {
        if (this.startedClose) {
            this.close();
            return;
        }
        if (this.restartTimeout !== null) {
            return;
        }
        if(!this.retry) {
            return;
        }

        if (this.pc !== null) {
            this.pc.close();
            this.pc = null;
        }

        this.restartTimeout = window.setTimeout(() => {
            this.restartTimeout = null;
            this.start();
        }, restartPause);

        this.eTag = '';
        this.queuedCandidates = [];
        this.onError("disconnected");
    }
}
const linkToIceServers = (links) => (
    (links !== null) ? links.split(', ').map((link) => {
        const m = link.match(/^<(.+?)>; rel="ice-server"(; username="(.*?)"; credential="(.*?)"; credential-type="password")?/i);
        const ret: any = {
            urls: [m[1]],
        };

        if (m[3] !== undefined) {
            ret.username = m[3];
            ret.credential = m[4];
            ret.credentialType = "password";
        }

        return ret;
    }) : []
);

const parseOffer = (offer) => {
    const ret = {
        iceUfrag: '',
        icePwd: '',
        medias: [],
    };

    for (const line of offer.split('\r\n')) {
        if (line.startsWith('m=')) {
            ret.medias.push(line.slice('m='.length));
        } else if (ret.iceUfrag === '' && line.startsWith('a=ice-ufrag:')) {
            ret.iceUfrag = line.slice('a=ice-ufrag:'.length);
        } else if (ret.icePwd === '' && line.startsWith('a=ice-pwd:')) {
            ret.icePwd = line.slice('a=ice-pwd:'.length);
        }
    }

    return ret;
};

const generateSdpFragment = (offerData, candidates) => {
    const candidatesByMedia = {};
    for (const candidate of candidates) {
        const mid = candidate.sdpMLineIndex;
        if (candidatesByMedia[mid] === undefined) {
            candidatesByMedia[mid] = [];
        }
        candidatesByMedia[mid].push(candidate);
    }

    let frag = 'a=ice-ufrag:' + offerData.iceUfrag + '\r\n'
        + 'a=ice-pwd:' + offerData.icePwd + '\r\n';

    let mid = 0;

    for (const media of offerData.medias) {
        if (candidatesByMedia[mid] !== undefined) {
            frag += 'm=' + media + '\r\n'
                + 'a=mid:' + mid + '\r\n';

            for (const candidate of candidatesByMedia[mid]) {
                frag += 'a=' + candidate.candidate + '\r\n';
            }
        }
        mid++;
    }

    return frag;
}
const restartPause = 20000;

