import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy
} from "@angular/core";
import { SiteListItem } from "../../sites/site-list-item";
import {
  Permissions,
  SitePermissions,
  booleanSiteFields,
  booleanUserFields
} from "../../permissions";
import { DocTypeService } from "../admin-documents/doc-type/doc-type.service";
import { take, map, takeUntil } from "rxjs/operators";
import { DocType } from "../admin-documents/doc-type/doc-type";
import { Observable, Subject } from "rxjs";
import { PermissionsService } from "app/permissions.service";
import { Site } from "app/site/site";

@Component({
  selector: "app-permissions-setter",
  templateUrl: "./permissions-setter.component.html",
  styleUrls: ["./permissions-setter.component.css"]
})
export class PermissionsSetterComponent implements OnInit, OnDestroy {
  @Input() set template(value: SitePermissions) {
    this._template = value;
  }

  @Input() set currentUserPermissions(value: Permissions) {
    this._currentUserPermissions = value;
  }

  @Input() set initialPermissions(value: Permissions) {
    if (value) {            
      this.newPermissions = new Permissions(Object.assign({document: this.newPermissions.document}, value)) 
      if (!this.newPermissions.document) {
        this.newPermissions.document = {};
      }
      this.save();
    }
  }

  @Input() set sites(value: SiteListItem[]) {
    if (!value) {
      return;
    }

    let oldPermissions = this.newPermissions;
    this.newPermissions = new Permissions({
      admin: oldPermissions.admin,
      user: oldPermissions.user ? oldPermissions.user : new Permissions().user,
      document: oldPermissions.document ? oldPermissions.document : new Permissions().document,
    });

    if (!this.newPermissions.document) {
      this.newPermissions.document = {};
    }
    this._sites = value;
 
    value.forEach((site: Site) => {
      if (!this.newPermissions.document[site.siteID]) {
        this.newPermissions.document[site.siteID] = {};
      }

      if (!oldPermissions[site.siteID]) {
        if (this._template) {
          this.newPermissions[site.siteID] = Object.assign({}, this._template);
        } else {
          this.newPermissions[site.siteID] = {};
        }
      } else {
        this.newPermissions[site.siteID] = oldPermissions[site.siteID];
      }
    });
    this.save();

  }

  @Input() set canAdmin(value: boolean) {
    if (value) {
      Object.assign(this.newPermissions.admin, {isAdmin: true, document: this.newPermissions.admin.document ? this.newPermissions.admin.document : {}})
    } else {
      this.newPermissions.admin = {
        isAdmin: false,
      };
    }

    this._canAdmin = value;
  }

  @Output() permissionsUpdate = new EventEmitter();

  public newPermissions = new Permissions({ admin: {}, document: {} });
  public siteFields = booleanSiteFields;
  public userFields = booleanUserFields;
  public docTypes: Observable<{ [typeID: string]: string }>;
  public docTypeAdminPermissions: { [typeID: string]: boolean } = {};
  public adminPermissions: { [field: string]: boolean } = {};
  public hasAdminAnyDocuments: boolean;

  public _template: SitePermissions;
  public _sites: SiteListItem[];
  public _canAdmin: boolean;
  public _currentUserPermissions: Permissions;
  public sitesAreMerged: boolean = false;
  private _destroyed$ = new Subject();

  constructor(
    private docTypeService: DocTypeService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.docTypes = this.docTypeService.getTypesAdmin()
    .pipe(
      map((docTypes: DocType[]) => {
        return Object.assign(
          {},
          ...docTypes.map((docType: DocType) => {
            return { [docType.id]: docType.name };
          })
        );
      })
    );

    this.docTypes
      .pipe(takeUntil(this._destroyed$))
      .subscribe((docTypes: { [id: string]: string }) => {
        Object.keys(docTypes).forEach(key => {
          let typeID = parseInt(key);
          let hasPermission = this.permissionsService.documentAdmin(
            typeID,
            this._currentUserPermissions
          );

          this.docTypeAdminPermissions[key] = hasPermission;
        });
      });

    Object.keys(this._currentUserPermissions.admin).forEach(key => {
      let hasPermission = this.permissionsService.admin(key, this._currentUserPermissions);
      this.adminPermissions[key] = hasPermission;
    });

    this.hasAdminAnyDocuments = this.permissionsService.adminAnyDocuments(this._currentUserPermissions);
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  save() {
    this.permissionsUpdate.emit(this.newPermissions);
  }
}
