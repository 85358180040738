import { Point } from './point'
import { MapDrawing } from './map-drawing';
import { Vehicle } from './vehicle/vehicle';
export class Where {
    mapData: MapDrawing[] = [];
    address: string;
    description: string;
    vehicleInvolved: boolean = true;
    vehicles: Vehicle[] = [new Vehicle()];    
    valid() {
        let valid = true;
        let errors = {map: [], address: [], description: []}
        if(this.mapData.length < 1) {
            valid = false;
            errors.map.push("Please Indicate on the map where the event happened.")
        }
        if(!this.address) {
            valid = false;
            errors.address.push("Please enter the address where the event took place.");
        }
        if(!this.description || this.description.length < 1) {
            valid = false;
            errors.description.push("Please enter a description.")
        }
        return {valid: valid, errors: errors};
    }
}
