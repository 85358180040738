import { Component, Output, EventEmitter, Input } from '@angular/core'
import { OtherOfficer } from './other-officer';


@Component({
    selector: 'app-other-officer',
    templateUrl: './other-officer.component.html',
    styleUrls: ['./other-officer.component.css'],
})
export class OtherOfficerComponent {
    @Output('change') change = new EventEmitter<OtherOfficer>();
    @Output('remove') remove = new EventEmitter();
    @Input('number') number: number;
    @Input('officer') officer: OtherOfficer;
   // public mask: (string | RegExp)[] = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public mask = "0000000000";

    removeFunc() {
        this.remove.emit();
    }

    update() {
        this.change.emit(this.officer);
    }
}