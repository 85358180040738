import { ShortRequest } from "./short-request";
import Outcome from "../../events/outcome";
import { FilteredEventMap } from "../../event-map/filtered-event-map";
import { CFTagCategory } from "../cf-tag-category";
import { CategoryColors } from "./category-colors";

export class HistoricalData {
    title: string;
    siteID: number;
    requests: ShortRequest[] = [];
    categoryColors: CategoryColors = new CategoryColors();
    selectedCategory: string;
    filteredRequests: ShortRequest[] = [];
    timeFilteredRequests: ShortRequest[] = [];
    maps: FilteredEventMap[] = [];
    startDate: Date;
    endDate: Date;
    categories: {category: string, value: number, selected: boolean, color: string}[] = [];

    constructor(config?: any) {
        if(config) {
            if(config.requests) {
                this.requests = config.requests.map(r => new ShortRequest(r));
            }

            this.selectedCategory = config.selectedCategory;
            if(config.maps) {
                this.maps = config.maps.map(m => new FilteredEventMap(m));
            }

            if(config.startDate) {
                this.startDate = new Date(config.startDate);
            }

            if(config.endDate) {
                this.endDate = new Date(config.endDate);
            }
        }

        this.updateRequests();
    }

    setStart(start: Date) {
        this.startDate = start;
        this.updateRequests()
    }

    setEnd(end: Date) {
        this.endDate = end;
        this.updateRequests();
    }

    setRequests(requests: ShortRequest[]) {
        this.requests = requests.map(r => new ShortRequest(r));
        this.selectedCategory = "";
        this.updateRequests();
    }

    setCategoryColors(categories: CFTagCategory[]) {
        this.categoryColors = new CategoryColors();
        for (let cat of categories) {
            this.categoryColors[cat.name] = cat.pieColor;
        }
        this.updateRequests();
    }
    calcCategories(): {category: string, value: number, selected: boolean, color: string}[] {
        let m = {};
        for(let i = 0; i < this.timeFilteredRequests.length; i++) {
            let cat = this.timeFilteredRequests[i].category;
            if(!m[cat]) {
                m[cat] = 0;
            }
            m[cat]++;
        }
        
        let result = [];
        let cats = Object.keys(m);
        cats.forEach(cat => result.push({category: cat, value: m[cat], selected: false, color: this.categoryColors[cat] ? this.categoryColors[cat] : ""}));

        return result;
    }

    selectCategory(category: string) {
        if(this.selectedCategory !== category) {
            this.selectedCategory = category;
            this.updateRequests();
            this.categories = this.categories.map(cat => Object.assign({}, cat, {selected: cat.category === category}));    
        } else {
            this.selectedCategory = "";
            this.updateRequests();
        }
    }

    filterRequests() {
        let requests = [...this.requests]
        if(this.startDate) {
            requests = requests.filter(request => request.requested.getTime() > this.startDate.getTime());
        }

        if(this.endDate) {
            requests = requests.filter(request => request.requested.getTime() < this.endDate.getTime());
        }

        this.timeFilteredRequests = [...requests];
        if(this.selectedCategory) {
            requests = requests.filter(request => request.category === this.selectedCategory);
        }

        requests = requests.filter(request => request.outcome !== Outcome.DupTicket);

        this.filteredRequests = requests;
    }

    updateRequests() {
        this.filterRequests();
        let ids = this.filteredRequests.map(req => req.eventID);
        this.maps.forEach(m => m.setEventIDs(ids));
        this.categories = this.calcCategories()
    }

    clone(): HistoricalData {
        let res = new HistoricalData();
        res.siteID = this.siteID;
        res.selectedCategory = this.selectedCategory;
        if(this.startDate) {
            res.startDate = new Date(this.startDate);
        }

        if(this.endDate) {
            res.endDate = new Date(this.endDate);
        }

        if(this.requests) {
            res.requests = this.requests.map(r => new ShortRequest(r));
        }

        if(this.filteredRequests) {
            res.filteredRequests = this.filteredRequests.map(r => new ShortRequest(r));
        }

        if(this.timeFilteredRequests) {
            res.timeFilteredRequests = this.timeFilteredRequests.map(r => new ShortRequest(r));
        }

        if(this.categories) {
            res.categories = this.categories.map(cat => {return {category: cat.category, value: cat.value, selected: cat.selected, color: cat.color}})
        }

        if(this.categoryColors) {
            res.categoryColors = Object.assign({}, this.categoryColors);
        }

        res.selectCategory("");

        return res;
    }
}