import { ActionReducer, Action } from '@ngrx/store';
import { DateRange } from './date-range';

export const SET_SELECTED_METRIC = 'SET_SELECTED_METRIC';
export const SET_EVENTS_DATE_RANGE = 'SET_EVENTS_DATE_RANGE';
export const SET_LOGINS_DATE_RANGE = 'SET_LOGINS_DATE_RANGE'

export const initialSelectedMetric = "events";
export const initialEventsDateRange: DateRange = { start: null, end: null };
export const initialLoginsDateRange: DateRange = { start: null, end: null };

export function selectedMetricReducer(state: string = initialSelectedMetric, action): string {
    switch (action.type) {
        case SET_SELECTED_METRIC:
            return action.payload;
        default:
            return state;
    }
}

export function eventsDateRangeReducer(state: DateRange = initialEventsDateRange, action): DateRange {
    switch (action.type) {
        case SET_EVENTS_DATE_RANGE:
            return Object.assign(new DateRange(), action.payload);
        default:
            return state;
    }
}

export function loginsDateRangeReducer(state: DateRange = initialLoginsDateRange, action): DateRange {
    switch (action.type) {
        case SET_LOGINS_DATE_RANGE:
            return Object.assign(new DateRange(), action.payload);
        default:
            return state;
    }
}