import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { Camera } from 'app/camera/camera';
import { Observable } from 'rxjs';
import * as actions from '../store/actions';
import * as selectors from '../store/selectors';
import { HIDE_SIDEBAR } from 'app/sidebar/sidebar.reducer';
import { map, take, takeWhile } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CameraRotaterComponent } from '../camera-rotater/camera-rotater.component';
import { CameraGridSize } from '../camera-grid-size';
import { KeyboardService } from 'app/keyboard.service';
import { IdleService } from 'app/idle.service';


@Component({
  selector: 'app-portfolio-site-walk',
  templateUrl: './portfolio-site-walk.component.html',
  styleUrls: ['./portfolio-site-walk.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioSiteWalkComponent {
  @ViewChild('cameraRotater') cameraRotater: CameraRotaterComponent;

  public cvsStreams$: Observable<Camera[]>;
  public portfolioSiteWalkStatus$: Observable<"play" | "pause" | "fast">;
  public rowsAndColumns$: Observable<{rows: number, columns: number}>;
  public theme$: Observable<"light" | "dark">;
  public startCameraIndex: number = 0;
  public endCameraIndex: number = 0;
  public progress: number = 0;
  public cameraGridSize$: Observable<CameraGridSize>;
  private alive = true;
  public isIdle$: Observable<boolean>;
  public loading: boolean = false;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private keyboardService: KeyboardService,
    private idleService: IdleService,
  ) { }

  ngOnInit() {
    this.cvsStreams$ = this.store.pipe(select(selectors.cvsStreams));
    this.portfolioSiteWalkStatus$ = this.store.pipe(select(selectors.portfolioSiteWalkStatus));
    this.onHide();
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      this.store.dispatch(actions.setFilters({ filters: {
        siteFilter: params.siteFilter ? typeof params.siteFilter === "string" ? [+params.siteFilter] :params.siteFilter.map(s => +s) : [],
        intentFilter: params.intentFilter ? typeof params.intentFilter === "string" ? [+params.intentFilter] : params.intentFilter.map(i => +i) : [],
        nameFilter: params.nameFilter ? params.nameFilter : '',
        selectedSortOption: params.sortOption ? params.sortOption : '',
        selectedGroupByOption: params.groupByOption ? params.groupByOption : '',
        cameraGridSize: params.cameraGridSize ? params.cameraGridSize : 'small',
      }}))
      this.store.dispatch(actions.getPortfolioCameras());
      this.store.dispatch(actions.getPortfolioCameraStreams());  
    });
    this.store.dispatch(actions.getPortfolioCameras());
    this.store.dispatch(actions.getPortfolioCameraStreams());
    this.rowsAndColumns$ =  this.store.pipe(select(selectors.allCameraSizes), map(size => {
      if (size.small) {
        return {rows: 4, columns: 4};
      } else if (size.medium) {
        return {rows: 3, columns: 3};
      } else {
        return {rows: 1, columns: 1};
      }
    }));
    this.cameraGridSize$ = this.store.pipe(select(selectors.allCameraSizes));
    this.keyboardService.getKeys().pipe(takeWhile(() => this.alive)).subscribe(event => {
      console.log("Event", event);
      this.handleKey(event);
    });
    this.theme$ = this.store.pipe(select(selectors.theme));
    this.isIdle$ = this.idleService.getInactive(10);
  }
  ngOnDestroy() {
    this.alive = false;
  }

  onFullscreenchanged() {
    
  }
  onHide() {
    this.store.dispatch({type: HIDE_SIDEBAR});
  }
  onStartCameraIndex(index: number) {
    this.startCameraIndex = index;
  }
  onEndCameraIndex(index: number) {
    this.endCameraIndex = index;
  }
  onProgress(progress: number) {
    this.progress = progress;
  };
  onPlay() {
    this.store.dispatch(actions.clickSiteWalkPlay());
  }
  onPause() {
    this.store.dispatch(actions.clickSiteWalkPause());
  }
  onFast() {
    this.store.dispatch(actions.clickSiteWalkFast());
  }
  onNext() {
    this.cameraRotater.next();
  }
  onPrevious() {
    this.cameraRotater.back();
  }
  onTogglePause() {
    this.store.dispatch(actions.siteWalkTogglePause());
  }
  onThemeLight() {
    this.store.dispatch(actions.themeLight());
  }
  onThemeDark() {
    this.store.dispatch(actions.themeDark());
  }
  onLoading(loading: boolean) {
    this.loading = loading;
  }
  onCloseNext() {
    this.cameraRotater.closeNext();
  }
  onCloseCurrent() {
    this.cameraRotater.closeCurrent();
  }
  public onCameraGridSizeChange(gridSize: string) {
    this.store.dispatch(actions.setCameraGridSize({ gridSize: gridSize }));
  }
  handleKey(event: KeyboardEvent) {
    if (event.ctrlKey && event.altKey) {
      if(event.key === "p") {
        this.onPlay();
        return;
      }
      if(event.key === "f") {
        this.onFast();
        return;
      }
      if(event.key === "1") {
        this.onCameraGridSizeChange("SET_LARGE");
        return;
      }
      if(event.key === "2") {
        this.onCameraGridSizeChange("SET_MEDIUM");
        return;
      }
      if(event.key === "3") {
        this.onCameraGridSizeChange("SET_SMALL");
        return;
      }
      if(event.key === "l") {
        this.onThemeLight();
        return;
      }
      if(event.key === "d") {
        this.onThemeDark();
        return;
      }
    }
    if (event.key === "ArrowRight") {
      this.cameraRotater.next();
      return;
    }
    if (event.key === "ArrowLeft") {
      this.cameraRotater.back();
      return;
    }
    if (event.key === " ") {
      this.onTogglePause();
      return;
    }
  }
}
