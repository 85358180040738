import { Component, OnInit, Input } from '@angular/core';
import { AlertEntry } from '../manage-alert-entries/alert-entry';
import { Tag } from '../manage-alert-entries/tag';
import { AppState } from 'app/app.state';
import { Store, select } from '@ngrx/store';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { selectStates, selectAlertEntriesSorted, selectAlertEntrySuccess } from '../store/selectors';
import { Observable } from 'rxjs';
import * as LPRActions from '../store/actions';
import { takeWhile } from 'rxjs/operators';
import { NotificationsService } from 'app/notifications/notifications.service';

@Component({
  selector: 'app-alert-entry',
  templateUrl: './alert-entry.component.html',
  styleUrls: ['./alert-entry.component.scss']
})
export class AlertEntryComponent implements OnInit {
  @Input('alertEntryData') set setAlertEntryData(value: AlertEntry) {
    // If we have data already (editing an existing entry) we load the component with that data
    // and also set exists to true so that we don't allow editing of the plate number
    if (value) {
      this.alertEntry = new AlertEntry(value);
      this.exists = true;
    } 
  }
  @Input('tags') set setTags(value: Tag[]) {
    this.tags = value;
  }
  @Input('siteID') set setSiteID(value: number) {
    this.siteID = value;
    // Load previous alert entries to prevent duplicate plates
    this.store.dispatch(LPRActions.loadAlertEntries({siteID: value}));
    this.alertEntries$ = this.store.pipe(select(selectAlertEntriesSorted));
    this.alertEntries$.pipe(takeWhile(() => this.alive)).subscribe(entries => {
      this.entries = entries;
    });
  }
  @Input('userID') set setUserID(value: number) {
    this.userID = value;
  }
  @Input('userEmail') set setUserEmail(value: string) {
    this.userEmail = value;
  }
  @Input('isAdmin') set setIsAdmin(value: boolean) {
    this.isAdmin = value;
  }
  
  public alertEntry: AlertEntry = new AlertEntry;
  public exists: boolean = false;
  public alertEntryForm: UntypedFormGroup;
  public states$: Observable<string[]>;
  public invalidEmail: boolean = false;
  public tags: Tag[];
  private tempTags: Tag[] = [];
  private siteID: number;
  private userID: number;
  public userEmail: string;
  public isAdmin: boolean;
  public min: Date = new Date();
  public alertEntries$: Observable<AlertEntry[]>;
  public entries: AlertEntry[];
  public alive: boolean = true;
  private success$: Observable<boolean>;
  public saveDisabled: boolean;

  constructor(
    private store: Store<AppState>,
    private notificationsService: NotificationsService  
  ) { }

  ngOnInit() {
    this.min.setDate(this.min.getDate() + 1);
    this.min.setHours(0,0,0);
    this.states$ = this.store.pipe(select(selectStates));
    this.success$ = this.store.pipe(select(selectAlertEntrySuccess));

    // If we don't have an array of emails yet, make a new one
    if (this.alertEntry.notificationEmails === undefined) {
      this.alertEntry.notificationEmails = new Array()
      // If this is a new Alert Entry, automatically add the user's email
      if (!this.exists) {
        this.alertEntry.notificationEmails.push(this.userEmail)
      }
    }

    // Initialize the form with the information in AlertEntry data. Due to how 
    // tags are stored, need to first check if the tag in the data exists then set.
    this.alertEntryForm = new UntypedFormGroup({
      plate: new UntypedFormControl(this.alertEntry.plate, [Validators.required, Validators.maxLength(12), this.nonDupePlateValidator]),

      state: new UntypedFormControl(this.alertEntry.state !== undefined ?
                                this.alertEntry.state
                                : undefined),
      description: new UntypedFormControl(this.alertEntry.description !== undefined ?
                                this.alertEntry.description
                                : undefined, Validators.required),
      expirationDate: new UntypedFormControl(this.alertEntry.expirationDate !== undefined ?
                                this.alertEntry.expirationDate
                                : undefined),
      events: new UntypedFormControl(this.alertEntry.events ?
                                this.alertEntry.events.join(",")
                                : undefined,
                                Validators.pattern(/^[0-9]+(,[0-9]+)*$/)),
    })

    // Add all the tags retrieved from the backend to the FormGroup
    if (this.tags !== undefined) {
      this.tags.forEach(tag => {
        this.alertEntryForm.addControl(tag.name, new UntypedFormControl(
          // Yeah, it's a nested ternary. I'm sorry. If it the tag exists set true, otherwise false
          this.alertEntry.tags !== undefined ? (this.alertEntry.tags.map(t => t.id).indexOf(tag.id) !== -1 ? true : false) : false))
      })
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  get plate() { return this.alertEntryForm.get('plate'); }

  private nonDupePlateValidator = (control: AbstractControl) => {
    return this.nonDupePlate(control.value, this.entries)
  }

  public nonDupePlate(plate: string, entries: AlertEntry[]) {  
    let dupe = false;
    if (plate !== null) {
      entries.forEach(entry => {
        if (plate.toUpperCase() === entry.plate) {
          dupe = true;
        }
      })
    }

    return dupe && !this.exists ? {
      nonDupePlate: {
        valid: false
      }
    } : null
  }

  public saveAlertEntry() {
    if (this.alertEntryForm.valid) {
      this.saveDisabled = true;
      // Save/Convert Data to appropriate format before reading the form
      let notificationsEmails = this.alertEntry.notificationEmails;
      this.tags.forEach(tag => {
        if (this.alertEntryForm.get(tag.name).value === true) {
          this.tempTags.push(tag)
        }
        this.alertEntryForm.get(tag.name).disable();
      })
      let events: number[];
      if (this.alertEntryForm.get("events").value !== null) {
        let temp = this.alertEntryForm.get("events").value;
        events = temp.replace(/\s/g, "").split(',').map(Number);
        
        this.alertEntryForm.get("events").disable;
      }
      let id = this.alertEntry.id;

      // Set the alert entry to the form data, then adjust/add values
      this.alertEntry = new AlertEntry(this.alertEntryForm.value);  
      this.alertEntry.notificationEmails = notificationsEmails;
      this.alertEntry.tags = this.tempTags;
      this.alertEntry.siteID = this.siteID;
      if (!this.exists) {
        this.alertEntry.createdByID = this.userID;
      }
      this.alertEntry.lastUpdatedByID = this.userID;
      this.alertEntry.events = events;
      this.alertEntry.id = id;
      this.alertEntry.plate = this.alertEntry.plate.trim()
      this.alertEntry.plate = this.alertEntry.plate.replace(/\s/g, "")

      // Submit the alert entry to the backend
      console.log(this.alertEntry)
      if(this.exists) {
        this.store.dispatch(LPRActions.updateAlertEntry({entry: this.alertEntry}));
      } else {
        this.store.dispatch(LPRActions.saveAlertEntry({alertEntry: this.alertEntry}));
      }

      // If the alert entry successfully saved, clear and reset the form
      this.success$.pipe(takeWhile(() => this.alive)).subscribe(success => {
        if (success) {
          this.notificationsService.success("", "Alert Entry Added")
          this.alertEntryForm.reset();
          this.alertEntryForm.enable();
          this.alertEntry.notificationEmails = [];
          this.alertEntry.notificationEmails.push(this.userEmail);
          this.saveDisabled = false;
        } else {
          this.delay(5000);
        }
      });
    }
  }

  async delay(ms: number) {
    // await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => this.saveDisabled = false);
    await new Promise(resolve => setTimeout(resolve, ms)).then(() => this.saveDisabled = false);
  }

  public addNotificationEmail(email: string) {
    // Little awkward because of DRY for clearing the input box. If an email is valid, isValid returns an empty string,
    // otherwise isValid returns what was passed into it. So if we get email back, it isn't valid.
    if (this.isValid(email) !== email) {
      // If we already added the email, don't add it again
      if (!(this.alertEntry.notificationEmails.indexOf(email) !== -1)) {
        this.alertEntry.notificationEmails.push(email)
      }
      this.invalidEmail = false;
    }
    else {
      this.invalidEmail = true;
    }
  }

  // Verify email address is valid-ish (validating emails is hard)
  // Returns the input string if not valid, empty string if valid
  public isValid(email: string) {
    var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  
    if (regexp.test(email)) {
      return ''
    }
    return email
  }

  public removeNotificationEmail(email: string) {
    console.log(email)
    var index: number = this.alertEntry.notificationEmails.indexOf(email);
    console.log(index)
    if (index !== -1) {
      this.alertEntry.notificationEmails.splice(index, 1);
    }
    console.log(this.alertEntry.notificationEmails)
  }
}
