import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AdminComponent } from "./admin.component";
import { AccountCreationComponent } from "./account-creation/account-creation.component";
import { AccountManagementComponent } from "./account-management/account-management.component";
import { AccountEditComponent } from "./account-edit/account-edit.component";
import { AccountCreationGuard } from "./account-creation/account-creation-guard.service";
import { TempUserListComponent } from "./temp-users/temp-user-list.component";
import { TempUserGuard } from "./temp-users/temp-user-guard.service";
import { AppGuard } from "../app-guard.service";
import { AdminGuard } from "./admin-guard.service";
import { SiteManagementComponent } from "./site-management/site-management.component";
import { SiteManagementGuard } from "./site-management/site-management-guard.service";
import { SiteSetupComponent } from "./site-management/site-setup/site-setup.component";
import { NewSiteSetupComponent } from "./site-management/new-site-setup/new-site-setup.component";
import { AdminDashboardComponent } from "./admin-dashboard/admin-dashboard.component";
import { SiteMetricsComponent } from "./site-metrics/site-metrics.component";
import { SiteLoaderGuard } from "../site/site-loader-guard.service";
import { AdminMessagesComponent } from "./admin-messages/admin-messages.component";
import { AdminMessagesGuard } from "./admin-messages/admin-messages-guard.service";
import { AdminAllSitesComponent } from "./admin-all-sites/admin-all-sites.component";
import { AdminEventsComponent } from "./admin-events/admin-events.component";
import { AdminDocumentsComponent } from "./admin-documents/admin-documents.component";
import { AdminDocumentsGuard } from "./admin-documents/admin-documents.guard";
import { AdminSitePackageComponent } from "./admin-site-package/admin-site-package.component";
import { AdminSitePackageGuard } from "./admin-site-package/admin-site-package.guard";
import { DevelopmentSandboxComponent } from "./development-sandbox/development-sandbox.component";
import { DevelopmentSandboxGuard } from "./development-sandbox/development-sandbox.guard";
import { AdminLprComponent } from "./admin-lpr/admin-lpr.component";
import { AdminSiteGroupsComponent } from './admin-site-groups/admin-site-groups.component';
import { AdminSiteGroupsGuard } from './admin-site-groups/admin-site-groups-guard.service';
import { AdminPermissionsComponent } from "./admin-permissions/admin-permissions.component";
import { AdminPermissionsGuard } from "./admin-permissions/admin-permissions-guard.service";
import { AdminPermissionTemplatesComponent } from "./admin-permission-templates/admin-permission-templates.component";
import { IotLprComponent } from "./iot-lpr/iot-lpr.component";
import { AdminExtendedStorageComponent } from "./admin-extended-storage/admin-extended-storage.component";

const adminRoutes: Routes = [
  {
    path: "admin",
    component: AdminComponent,
    canActivateChild: [AppGuard, AdminGuard],
    children: [
      { path: "create", component: AccountCreationComponent, canActivate: [AccountCreationGuard] },
      { path: "temp", component: TempUserListComponent, canActivate: [TempUserGuard] },
      { path: "users", component: AccountManagementComponent },
      { path: "events", component: AdminEventsComponent },
      { path: "users/edit/:id", component: AccountEditComponent },
      { path: "sites/setup/new", component: NewSiteSetupComponent, canActivate: [SiteManagementGuard] },
      { path: "sites/setup/:id", component: SiteSetupComponent, canActivate: [SiteManagementGuard] },
      { path: "sites", component: SiteManagementComponent, canActivate: [SiteManagementGuard] },
      { path: "dashboard", component: AdminDashboardComponent },
      { path: "site/:siteID/metrics", component: SiteMetricsComponent, canActivate: [SiteLoaderGuard] },
      { path: "messages", component: AdminMessagesComponent, canActivate: [AdminMessagesGuard] },
      { path: "all-sites", component: AdminAllSitesComponent },
      { path: "documents", component: AdminDocumentsComponent, canActivate: [AdminDocumentsGuard] },
      { path: "packages", component: AdminSitePackageComponent, canActivate: [AdminSitePackageGuard] },
      { path: "lpr", component: AdminLprComponent },
      { path: "site-groups", component: AdminSiteGroupsComponent, canActivate: [AdminSiteGroupsGuard] },
      { path: "development-sandbox", component: DevelopmentSandboxComponent, canActivate: [DevelopmentSandboxGuard] },
      { path: "permissions", component: AdminPermissionsComponent, canActivate: [AdminPermissionsGuard]},
      { path: "permission-templates", component: AdminPermissionTemplatesComponent, canActivate: [AdminPermissionsGuard]},
      { path: "iot-lpr", component: IotLprComponent, canActivate: [SiteManagementGuard]},
      {path: "extended-storage", component: AdminExtendedStorageComponent, canActivate: [SiteManagementGuard]}
    ],
  },
];

export const adminRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(adminRoutes);
