import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';

import { User } from '../../users/user';


@Component({
  selector: 'app-first-login-form',
  templateUrl: './first-login-form.component.html',
  styleUrls: ['./first-login-form.component.css']
})
export class FirstLoginFormComponent implements OnInit {
  @Input('user') set user(user: User) {
    if (user && this.formUser) {
      this.fields.forEach((field) => {
        this.formUser[field] = user[field];
      })
      this._user = user;
    }
  };
  @Input('error') error;
  @Input('saveInProgress') saveInProgress;
  
  @ViewChild('phoneNumber', { static: true }) phoneNumberEl;

  @Output() update = new EventEmitter();
  public _user: User;  
  //public mask: (string | RegExp)[] = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public formUser: any = {};
  public fields: string[] = ['title', 'phoneNumber', 'ext', 'cellNumber', 'firstName', 'lastName', 'contactPreference'];
  public titles: string[] = ['', 'Mr.', 'Ms.', 'Mrs.', 'Sgt.', 'Det.', 'Ofc.', 'Capt.', 'Insp.']; //TODO: add more titles later?
  public contactPreferences: string[] = ['Email', 'Phone', 'Cell Phone'];
  public passwordValid: boolean = false;
  public mask = "";
 
  constructor() { }

  ngOnInit() {

  }
  ngAfterViewInit() {

  }
  submit() {
    this.update.emit(this.formUser);
  }
  validValues(obj) {

    if (!this.passwordValid && this._user.isTempUser) {
      return false;
    }

    if (obj.phoneNumber === '' || !obj.phoneNumber || !this.validPhoneNumber(obj.phoneNumber)) {
      return false;
    }
    if (obj.firstName === '' || !obj.firstName) {
      return false;
    }
    if (obj.lastName === '' || !obj.lastName) {
      return false;
    }
    if ((obj.cellNumber !== '' && obj.cellNumber) && (obj.cellNumber.length < 14 || !this.validPhoneNumber(obj.cellNumber))) {
      return false;
    }
    if (obj.firstName && obj.firstName.length > 45) {
      return false;
    }
    if (obj.lastName && obj.lastName.length > 45) {
      return false;
    }
    return true;
  }
  validPhoneNumber(num) {
    return num && !num.match(/_/);
  }

  onNewPasswordChange(pwd: string) {
    this.formUser.password = pwd;
  }

  onValidationChange(valid: boolean) {
    this.passwordValid = valid;
  }

}
