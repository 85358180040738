// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-new-site-group {
    clear: both;
    margin: 5px;
  }

  .edit-button {
    margin-right: 2px;
  }`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-site-groups/admin-site-groups.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;EACb;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".add-new-site-group {\r\n    clear: both;\r\n    margin: 5px;\r\n  }\r\n\r\n  .edit-button {\r\n    margin-right: 2px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
