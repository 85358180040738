import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ServiceHelpersService } from "app/service-helpers.service";
import { AccessKeyService } from "app/users/access-key.service";
import { TransactionStatus } from "./transaction-status";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

@Injectable()
export class StatusModalService {
    public headers = {};
  
    constructor(
        private http: HttpClient,
        private helper: ServiceHelpersService,
        private accessKeyService: AccessKeyService,    
    ) {
        this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key}));
    }

    getTransactionStatus(id: number): Observable<TransactionStatus> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<TransactionStatus>(`/rest/admin/sitegroups/transactionstatus/${id}`, 
            { headers: headers }).pipe(catchError(this.helper.handleError), map(status => {
            
            return new TransactionStatus(status);
        }));
    }
}