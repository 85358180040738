import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { takeWhile } from 'rxjs/operators';
import { AllSitesSearch } from '../../admin-all-sites/all-sites-search';
import { SET_ALL_SITES_SEARCH } from '../../admin-all-sites/all-sites.reducer';

@Component({
    selector: 'app-site-table',
    templateUrl: './site-table.component.html',
    styleUrls: ['./site-table.component.css'],
})
export class SiteTableComponent implements OnInit, OnDestroy {
    @Input('sites') set sitesInput(value) {
        this.baseSites = value;
        this.sitesFilterChange();
    }
    @Output('clickSite') siteClicker = new EventEmitter();
    @ViewChild("siteSearch",{static: true}) siteSearch: ElementRef;

    sites;
    siteFilter: string = "";
    packageFilter: string = "";
    baseSites;
    allSitesSearch: AllSitesSearch;
    test: AllSitesSearch;
    initialSiteLoad: boolean = true;
    alive : boolean = true;

    constructor(public store: Store<AppState>) {}

    ngOnInit() {
        this.store.select(s => s.allSitesSearch).pipe(takeWhile(() => this.alive)).subscribe(search => {
            this.allSitesSearch = new AllSitesSearch(search.siteText, search.packageText);
            this.siteFilter = this.allSitesSearch.siteText;
            this.packageFilter = this.allSitesSearch.packageText;
            if (this.initialSiteLoad) {
                this.sitesFilterChange();
            }
            this.initialSiteLoad = false;
        });
    }

    public ngOnDestroy() {
        this.alive = false;
    }
    
    ngAfterViewInit() {
        this.siteSearch.nativeElement.focus();
    }

    clickSite(id) {
        this.siteClicker.emit(id);
    }

    sitesFilterChange() {
        this.sites = this.baseSites;
        if (this.siteFilter !== "") {
            this.sites = this.sites.filter(site => site.siteName.toLowerCase().indexOf(this.siteFilter.toLowerCase()) !== -1);
        }

        if (this.packageFilter !== "") {
            this.sites = this.sites.filter(site => site.packageName.toLowerCase().indexOf(this.packageFilter.toLowerCase()) !== -1);
        }

        if (!this.initialSiteLoad) {
         this.store.dispatch({type: SET_ALL_SITES_SEARCH, payload: {siteText: this.siteFilter, packageText: this.packageFilter}});
        }
    }

}