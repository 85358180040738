
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { AppState } from "../../../app.state";
import { START_REQUEST_WHEN, SET_REQUEST_CURRENT_WHEN } from "../../requests.reducer";

@Injectable()
export class WhenGuard  {
  constructor(public store: Store<AppState>) {}
  canActivate() {
    this.store.dispatch({ type: START_REQUEST_WHEN });
    this.store.dispatch({ type: SET_REQUEST_CURRENT_WHEN });
    return true; //need to add check if request is in progress later;
  }
}
