import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from './state';
import * as adminLPRActions from './actions';

const reducer = createReducer(
    initialState,

    // Alert Entries Tab
    on(adminLPRActions.setAlertEntries, (state: State, {alertEntries}) => {
        return {
            ...state,
            alertEntries: alertEntries,
            alertEntriesLoading: false,
        }
    }),
    on(adminLPRActions.setAlertEntriesLoading, (state: State, {loading}) => {
        return {
            ...state,
            alertEntriesLoading: loading,
        }
    }),
    on(adminLPRActions.setAlertEntriesGridState, (state: State, {gridState}) => {
        return {
            ...state,
            alertEntriesGridState: gridState,
        }
    }),
    on(adminLPRActions.setSelectedAlertEntry, (state: State, {entry}) => {
        return {
            ...state,
            selectedAlertEntry: [...entry],
        }
    }),
    on(adminLPRActions.setAlertEntryHits, (state: State, {alertEntryHits}) => {
        return {
            ...state,
            alertEntryHits: alertEntryHits,
            alertEntryHitsLoading: false,
        }
    }),
    on(adminLPRActions.setAlertEntryHitsLoading, (state: State, {loading}) => {
        return {
            ...state,
            alertEntryHitsLoading: loading,
        }
    }),
    on(adminLPRActions.setAlertEntryHitsGridState, (state: State, {gridState}) => {
        return {
            ...state,
            alertEntryHitsGridState: gridState,
        }
    }),
    on(adminLPRActions.setShowLprImageModal, (state: State, {show}) => {
        return {
            ...state,
            showLprImageModal: show,
        }
    }),
    on(adminLPRActions.setShowRequestModal, (state: State, {show}) => {
        return {
            ...state,
            showRequestModal: show,
        }
    }),
    on(adminLPRActions.setShowAddCamsModal, (state: State, {show}) => {
        return {
            ...state,
            showAddCamsModal: show,
        }
    }),   

    // Alert Hits Tab
    on(adminLPRActions.setSelectedEntriesHitsTab, (state: State, {tab}) => {
        return {
            ...state,
            selectedEntriesHitsTab: tab,
        }
    }),
    on(adminLPRActions.setAlertHits, (state: State, {alertHits}) => {
        return {
            ...state,
            alertHits: alertHits,
            alertHitsLoading: false,
        }
    }),
    on(adminLPRActions.setAlertHitsLoading, (state: State, {loading}) => {
        return {
            ...state,
            alertHitsLoading: loading,
        }
    }),
    on(adminLPRActions.setAlertHitsGridState, (state: State, {gridState}) => {
        return {
            ...state,
            alertHitsGridState: gridState,
        }
    }),

)

export function adminLPRReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}

