import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ByosSite, Storage } from '../byosSite';
import { ResizedEvent } from '../../angular-resize.directive';
import { State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { AppState } from "app/app.state";
import { Store } from "@ngrx/store";
import { Router } from '@angular/router';



@Component({
  selector: 'app-extended-storage-mgmt',
  templateUrl: './extended-storage-mgmt.component.html',
  styleUrls: ['./extended-storage-mgmt.component.css']
})
export class ExtendedStorageMgmtComponent {

  @Input('siteStorage') set setSiteStorage(value: Storage) {
    if (value) {
      this.siteStorage = value;
    }
  }

  @Output() changeStorage: EventEmitter<any> = new EventEmitter<any>();
  @Output() unassignStorage: EventEmitter<any> = new EventEmitter<any>();

  public siteStorage: Storage;
  textBoxWidth: number = 400;

  constructor() {}

  ngOnInit() {}

   public onChangeStorage() {
    this.changeStorage.emit(this.siteStorage);   
  }

  public onUnassignStorage() {
    this.unassignStorage.emit();
  }
}
