import { props, createAction } from "@ngrx/store";
import { CameraLite } from "../camera-lite";
import { Camera } from "../camera";
import { Lookup } from "../../lookup";
import { Filters } from "../portfolio-camera/filters";

export const noAction = createAction("[Camera] No Action");

export const getPortfolioCameras = createAction("[Camera] Get Portfolio Cameras");

export const getPortfolioCamerasSuccess = createAction("[Camera] Get Portfolio Cameras Success", props<{cameras: CameraLite[]}>());

export const getPortfolioCamerasFailure = createAction("[Camera] Get Portfolio Cameras Failure");

export const portfolioLiveOn = createAction("[Camera] Portfolio Live On");

export const portfolioLiveOff = createAction("[Camera] Portfolio Live Off");

export const setPortfolioCameraStreams = createAction("[Camera] Set Portfolio Camera Streams", props<{cameraStreams: Camera[]}>());

export const getPortfolioCameraStreamsError = createAction("[Camera] Get Portfolio Camera Streams Error");

export const clickPortfolioLive = createAction("[Camera] Portfolio Click Live");

export const getCameraIntents = createAction("[Camera] Get Camera Intents");

export const getCameraIntentsSuccess = createAction("[Camera] Get Camera Intents Success", props<{intents: Lookup[]}>());

export const getCameraIntentsFailure = createAction("[Camera] Get Camera Intents Failure");

export const setSiteFilter = createAction("[Camera] Set Site Filter", props<{filter: number[]}>());

export const setIntentFilter = createAction("[Camera] Set Intent Filter", props<{filter: number[]}>());

export const setNameFilter = createAction("[Camera] Set Name Filter", props<{filter: string}>());

export const clearFilters = createAction("[Camera] Clear Filters");

export const setSelectedSortOption = createAction("[Camera] Set Selected Sort Option", props<{option: string}>());

export const setSelectedGroupByOption = createAction("[Camera] Set Selected Group By Option", props<{option: string}>());

export const toggleCameraFooterToggle = createAction("[Camera] Toggle Camera Footer Toggle");

export const setCameraGridSize = createAction("[Camera] Set Camera Grid Size", props<{gridSize: string}>());

export const setViewportWidth = createAction("[Camera] Set Viewport Width", props<{width: number}>());

export const setCameraHeight = createAction("[Camera] Set Camera Height", props<{height: number}>());

export const setAppBarWidth = createAction("[Camera] Set AppBar Width", props<{width: number}>());

export const clickWalk = createAction("[Camera] Click Walk");

export const setFilters = createAction("[Camera] Set Filters", props<{filters: Filters}>());

export const getPortfolioCameraStreams = createAction("[Camera] Get Portfolio Camera Streams");

export const clickSiteWalkPlay = createAction("[Camera] Click Site Walk Play");

export const clickSiteWalkPause = createAction("[Camera] Click Site Walk Pause");

export const clickSiteWalkFast = createAction("[Camera] Click Site Walk Fast");

export const siteWalkTogglePause = createAction("[Camera] Site Walk Toggle Pause");

export const themeLight = createAction("[Camera] Theme Light");

export const themeDark = createAction("[Camera] Theme Dark");