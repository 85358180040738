import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { AppState } from "app/app.state";
import { MotionFile } from "./motion-data";
import { motionFiles, 
         cloudFiles, 
         cloudCamera, 
         cloudTime, 
         cloudModalVisible, 
         selectedCameras, 
         motionLoading, 
         motionLoaded, 
         motionFailed, 
         selectCameraIDToName,
         selectCameraIDToDisplayName, 
         selectedHours, 
         selectedTime, 
         motionSiteID, 
         motionViewed, 
         selectedMotionFile, 
         maxCameraModalVisible } from "./store/selectors";
import { Observable, zip, Subject, combineLatest } from "rxjs";
import * as addCamsSelectors from '../camera/rewind/add-cams/store/selector';
import * as AddCamsActions from '../camera/rewind/add-cams/store/actions';
import { CloudVideo } from "app/cloud/cloud-video";
import { cloudModalClose, motionClick, maxCameraModalClose } from "./store/actions";
import { map, takeUntil, take, tap, withLatestFrom, filter } from "rxjs/operators";
import { Camera } from "app/camera/camera";
import { CloudPlayerModalData, RequestData } from "app/cloud/cloud-player/cloud-player-modal/cloud-player-modal.component";
import { Map } from "app/map/map";
import { Router } from "@angular/router";
import { MotionDateTime } from './motion-datetime-modal/motion-datetime';
import { RewindRequest } from "app/camera/rewind/rewind-request";
import { TimeObj } from "app/time-obj";
import { Cameras } from "app/requests/new-request/cameras/cameras";
import { AddCamsMarker } from "app/camera/rewind/add-cams/add-cams-marker";
import { PropertyMap } from "app/property-map/property-map";
import { NotificationsService } from "app/notifications/notifications.service";
import { CameraService } from "app/camera/camera.service";
import { AddCamsService } from "app/camera/rewind/add-cams/add-cams.service";
import { User } from "app/users/user";
import { setPrimaryCamera } from "app/camera/rewind/add-cams/store/actions";
import { FeatureService } from "app/site-package/services";
import { FeatureType } from "app/site-package/models";
import { RequestType } from "app/requests/request-type";
import { Site } from "app/site/site";
import { PermissionsService } from "app/permissions.service";


@Component({
  selector: "app-motion",
  templateUrl: "./motion.component.html",
  styleUrls: ["./motion.component.css"],
})
export class MotionComponent implements OnInit, OnDestroy {
  @ViewChild("topOfPage", { static: true }) topOfPage: ElementRef;

  showCloud: boolean = false;

  motionFiles$: Observable<MotionFile[]>;

  public cloudModalVisible$: Observable<boolean>;
  public cloudPlayerModalData$: Observable<CloudPlayerModalData>;

  public selectedCameras$: Observable<number[]>;
  private cloudFiles$: Observable<CloudVideo[]>;
  private cloudCamera$: Observable<number>;
  private cloudTime$: Observable<Date>;

  public motionLoading$: Observable<boolean>;
  public motionLoaded$: Observable<boolean>;
  public motionFailed$: Observable<boolean>;

  public cameraIDToName$: Observable<{ [id: number]: string }>;
  public cameraIDToDisplayName$: Observable<{ [id: number]: string }>;

  public selectedHours$: Observable<number>;
  public selectedTime$: Observable<MotionDateTime>;

  private destroy$ = new Subject<void>();

  public request: RewindRequest;
  public requestModalOpened: boolean = false;
  public submitInProgress: boolean = false;

  public addCamsMaps$: Observable<Map[]>;

  public siteID: number;
  public additionalCameras: number;
  public site: Site;
  public user: User;
  public hasRequestFeature: boolean = false;

  public requestCameras$

  public isAutoRequest = true;
  public addCamerasModalOpened = false;

  public player: ElementRef;
  public cameras$: Observable<Camera[]>;

  public motionViewed$: Observable<{[imgURL: string]: boolean}>;
  public selectedMotionFile$: Observable<MotionFile>;

  public maxCameraModalVisible$: Observable<boolean>;

  public hasRawRequestFeature: boolean;
  public hasRequestPermission: boolean;
  public hasCanCreateRawRequestsPermission: boolean;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private notificationsService: NotificationsService,
    private cameraService: CameraService,
    private addCamsService: AddCamsService,
    private featureService: FeatureService,
    private permissionsService: PermissionsService,
  ) { }

  ngOnInit() {
    this.store.select(s => s.site).pipe(takeUntil(this.destroy$)).subscribe(site => {
      this.siteID = site.siteID;
      this.additionalCameras = site.autoRequestAdditionalCameras;
      this.site = site;
      this.hasRequestPermission = this.permissionsService.site(site.siteID, "canCreateTickets");
      this.hasCanCreateRawRequestsPermission = this.permissionsService.site(site.siteID, "canCreateRawRequests");
    });


    this.store.select(s => s.user).pipe(takeUntil(this.destroy$)).subscribe(user => {
      this.user = user;
    });
    this.hasRawRequestFeature = this.featureService.checkFeatureInPackage(FeatureType.RawRequest);
    this.motionFiles$ = this.store.pipe(select(motionFiles));

    this.cloudFiles$ = this.store.pipe(select(cloudFiles));
    this.cloudCamera$ = this.store.pipe(select(cloudCamera));
    this.cloudTime$ = this.store.pipe(select(cloudTime), map(t => t.toDate()));
    this.cloudModalVisible$ = this.store.pipe(select(cloudModalVisible));
    this.selectedCameras$ = this.store.pipe(select(selectedCameras), map(markers => markers.map(m => m.camera)));

    this.motionLoading$ = this.store.pipe(select(motionLoading));
    this.motionLoaded$ = this.store.pipe(select(motionLoaded));
    this.motionFailed$ = this.store.pipe(select(motionFailed));

    this.cameraIDToName$ = this.store.pipe(select(selectCameraIDToName));
    this.cameraIDToDisplayName$ = this.store.pipe(select(selectCameraIDToDisplayName));

    this.motionViewed$ = this.store.pipe(select(motionViewed));
    this.selectedMotionFile$ = this.store.pipe(select(selectedMotionFile));

    
    this.selectedHours$ = this.store.pipe(select(selectedHours));
    this.selectedTime$ = this.store.pipe(select(selectedTime));
    this.cloudPlayerModalData$ = zip(this.cloudFiles$, this.cloudTime$).pipe(
      withLatestFrom(this.cloudCamera$),
      map((data): CloudPlayerModalData => {
        let files = data[0][0];
        let time = data[0][1];
        let cameraID = data[1];

        return {
          videos: files,
          cameraID: cameraID,
          time: time,
        }
      }),
    );

    this.addCamsMaps$ = this.addCamsService.getMaps();
    this.hasRequestFeature = this.featureService.hasRequestFeature();
    this.cameras$ = combineLatest(
      this.store.pipe(select(addCamsSelectors.selectedCameras)),
      this.store.pipe(select(addCamsSelectors.cameraData)),
    ).pipe(map(([markers, data]) => markers.filter(m => data[m.camera]).map(m => data[m.camera])));
  

  this.maxCameraModalVisible$ = this.store.pipe(select(maxCameraModalVisible));

  }

  public ngOnDestroy() {
    this.store.dispatch(cloudModalClose());
    this.destroy$.next();
  }

  public onCloudPlayerModalClose() {
    this.store.dispatch(cloudModalClose());
  }
  public onRequestModalClose() {
    this.requestModalOpened = false;
  }

  public onMotionFileClick(file: MotionFile) {
    this.clickMotion(file);
  }

  onAddCameras() {
    this.addCamerasModalOpened = true;
  }
  onAddCamerasCancel() {
    this.addCamerasModalOpened = false;
    this.store.dispatch(AddCamsActions.resetCameras());
  }
  onAddCamerasSave() {
    this.addCamerasModalOpened = false;
  }

  clickMotion(file: MotionFile) {
    this.store.dispatch(motionClick({ file: file }));
    this.store.dispatch(setPrimaryCamera({ marker: this.getMarker(file.cameraID) }));
    this.store.dispatch(AddCamsActions.resetCameras());
    this.store.dispatch(AddCamsActions.loadCamera({ cameraID: file.cameraID }));
  }

  startRequest(data: RequestData) {
    this.player = data.player;
    let newRequest = new RewindRequest();

    // let utc = new Date(this.selectedVideo.startTime.getTime());
    // let local = new Date(this.selectedVideo.startTime.getTime());
    // local.setHours(utc.getUTCHours(), utc.getUTCMinutes(), utc.getUTCSeconds(), utc.getUTCMilliseconds());
    // local.setFullYear(utc.getUTCFullYear(), utc.getUTCMonth(), utc.getUTCDate() );
    // let newTime = local;
    // newTime.setSeconds(newTime.getSeconds() + time);
    newRequest.when.start = data.time;
    newRequest.when.end = data.time;
    let siteID;
    this.store.select(s => s.site.siteID).subscribe(id => siteID = id);
    newRequest.siteID = siteID;
    let markers;
    this.store.pipe(select(addCamsSelectors.selectedCameras), take(1)).subscribe(s => {
      markers = s;
    });

    newRequest.cameras = new Cameras(markers);
    newRequest.contactInfo.user = this.user;
    this.request = newRequest

    this.requestModalOpened = true;
  }

  submit(req) {
    this.submitInProgress = true;
    let request = Object.assign(new RewindRequest(), req);
    request.when = {};
    request.when.start = new TimeObj(req.when.start);
    request.when.end = new TimeObj(req.when.end);
    request.requestType = RequestType.Motion;
    if (this.featureService.checkFeatureInPackage(FeatureType.AutoRequest)) {
      request.isAutoRequest = true;
    }

    let markers;
    this.store.pipe(select(addCamsSelectors.selectedCameras), take(1)).subscribe(s => {
      markers = s;
    });

    request.cameras = new Cameras(markers);

    let primaryCameraID: number;
    this.store.pipe(select(addCamsSelectors.primaryCamera), take(1)).subscribe(primaryCamera => {
      primaryCameraID = primaryCamera.camera;
    });

    request.primaryCameraID = primaryCameraID;
    
    let img
    if (request.image) {
      img = request.image;
    }

    delete request.image;


    
    this.cameraService.expressRequest(request).pipe(takeUntil(this.destroy$)).subscribe(resp => {
      if (img) {
        this.cameraService.uploadImage(resp.eventID, img).pipe(takeUntil(this.destroy$)).subscribe(resp => {
          this.notificationsService.success("", "Request Submitted");
          this.submitInProgress = false;
          this.requestModalOpened = false;
          this.router.navigate(['site', this.siteID, 'events']);
        }, err => {
          this.submitInProgress = false;
          this.notificationsService.error("", "Error uploading image");
          console.error(err);
        })
      } else {
        this.notificationsService.success("", "Request Submitted");
        this.submitInProgress = false;
        this.requestModalOpened = false;
        this.router.navigate(['site', this.siteID, 'events']);
      }
    }, err => {
      this.submitInProgress = false;
      this.notificationsService.error("", "Error Submitting Request")
    })
  }
  getMarker(cameraID: number): AddCamsMarker {
    let result: AddCamsMarker;
    let maps: PropertyMap[];
    this.addCamsMaps$.pipe(take(1)).subscribe(m => maps = m);

    maps.forEach(map => {
      if (map.markersLocal) {
        map.markersLocal.forEach(m => {
          let marker = m as AddCamsMarker;
          if (marker.type === "camera" && +marker.camera === cameraID) {
            result = marker;
          }
        })
      }
    });

    return result;
  }

  scrollToTop() {
    this.topOfPage.nativeElement.scrollIntoView();
  }

  maxCameraModalClose() {
  this.store.dispatch(maxCameraModalClose())
  }
  rawRequest() {
    this.router.navigate(["site", this.siteID, "raw-request"]);
  }
  rawRequestFromPlayer() {
    this.store.dispatch(cloudModalClose());
    this.router.navigate(["site", this.siteID, "raw-request"]);
  }
}
