import { Component, Output, EventEmitter, Input } from '@angular/core';

import { SentMessage } from '../../admin.service';
import { UserType } from '../../../users/user-type';

@Component({
    selector: 'app-message-form',
    templateUrl: './message-form.component.html',
    styleUrls: ['./message-form.component.css'],
})
export class MessageFormComponent {
    @Input('hardTypes') set setHardTypes(value: UserType[]) {
        this.hardTypes = value;
        this.resetHardTypes();
    };
    @Input('softTypes') set setSoftTypes(value: UserType[]) {
        this.softTypes = value;
        this.resetSoftTypes();
    };
    @Input('sites') sites;
    @Input('users') users;
    @Output('message') submit = new EventEmitter<SentMessage>();
    @Output('cancel') cancelEmitter = new EventEmitter();
    hardTypes: UserType[];
    softTypes: UserType[];
    targets = ['all', 'site', 'user'];
    selectedHardTypes = {};
    selectedSoftTypes = {};
    selectedSites;
    selectedUsers;
    importanceLevel: number = 1;
    text: string = "";
    subject: string = "";
    target: string = 'site';
    expDate: Date;
    softTypeIsSelected: boolean = false;
    hardTypeIsSelected: boolean = false;

    send() {
        let message: any = {};
        message.importanceLevel = this.importanceLevel;
        message.text = this.text;
        message.subject = this.subject;
        message.hardTypesWitlist = [];
        message.softTypesWitlist = [];
        message.expDate = this.expDate;
        switch (this.target) {
            case 'all':
                message.target = 'all';
                break;
            case 'site':
                message.target = 'site';
                message.values = [];
                message.selectedUsers = [];
                this.selectedSites.forEach(site => {
                    message.values.push(site.id);
                })
                break;
            case 'user':
                message.target = 'user';
                message.values = [];
                this.resetHardTypes();
                this.resetSoftTypes();
                this.selectedUsers.forEach(user => {
                    message.values.push(user.id);
                })
                break;
            default:
                console.log("invalid target in admin message form")
        }
        if (this.hardTypes) {
            this.hardTypes.forEach(type => {
                if (this.selectedHardTypes[type.id]) {
                    message.hardTypesWitlist.push(type.id);
                }
            })
        } else {
            console.log("No hard types passed to admin message form");
        }
        if (this.softTypes) {
            this.softTypes.forEach(type => {
                if (this.selectedSoftTypes[type.id]) {
                    message.softTypesWitlist.push(type.id);
                }
            })
        } else {
            console.log("No soft types passed to admin message form");
        }
        this.submit.emit(message);
    }
    setSites(sites) {
        this.selectedSites = sites;
    }
    setUsers(users) {
        this.selectedUsers = users;
    }
    cancel() {
        this.cancelEmitter.emit();
    }
    clear() {
        this.target = 'all';
        this.selectedSites = []
        this.selectedUsers = [];
        this.subject = "";
        this.text = '';
        this.importanceLevel = 1;
        this.resetHardTypes();
        this.resetSoftTypes();
    }
    resetHardTypes() {
        this.selectedHardTypes = {};
        // this.hardTypes.forEach(type => {
        //     this.selectedHardTypes[type.id] = true;
        // })

    }
    resetSoftTypes() {
        this.selectedSoftTypes = {};
        // this.softTypes.forEach(type => {
        //     this.selectedSoftTypes[type.id] = true;
        // })

    }
    softTypesChange() {
        let hasTrue: number = 0;

        if (Object.keys(this.selectedSoftTypes).length < 1) {
            this.softTypeIsSelected = false;
        } else {
            for (var key in this.selectedSoftTypes) {
                if (this.selectedSoftTypes[key] === true) {
                    hasTrue++
                }
            }
            if (hasTrue > 0) {
                this.softTypeIsSelected = true;
            } else {
                this.softTypeIsSelected = false;
            }
        }
    }

    hardTypesChange() {
        let hasTrue: number = 0;
        if (Object.keys(this.selectedHardTypes).length < 1) {
            this.hardTypeIsSelected = false;
        } else {
            for (var key in this.selectedHardTypes) {
                if (this.selectedHardTypes[key] === true) {
                    hasTrue++
                }
            }
            if (hasTrue > 0) {
                this.hardTypeIsSelected = true;
            } else {
                this.hardTypeIsSelected = false;
            }
        }
    }

    checkExpDate() {
        if (!this.expDate) {
            return false;
        }
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let selectedExpireDate = new Date(this.expDate);
        selectedExpireDate.setHours(0, 0, 0, 0);
        if (selectedExpireDate > today) {
            return false;
        } else {
            return true;
        }
    }

}