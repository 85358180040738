// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeSelect {
  margin-top: 15px;
}

.label {
  display: block;
  font-weight: bold;
  text-indent: 8px;
  color: #656565;

}

h3 {
  text-align: center;
}

button {
  margin-left: 5px;
  border: 1px solid #ccc;
}

.cont {
  display: flex;
}

.thing {
  flex: 1;
  text-align: center;
}

.main-historical {
  box-shadow: 3px 3px 3px gray;
}

.historical {
  box-shadow: 2px 2px 2px gray;
}

.main-historical, .historical {
  border: 1px solid black;
  padding: 3px;
  margin: 4px;
}

.thing-1{
  z-index: 1000;
  flex: 1;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/metrics/historical/historical.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;;AAEhB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,OAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,OAAO;EACP,kBAAkB;AACpB","sourcesContent":[".timeSelect {\r\n  margin-top: 15px;\r\n}\r\n\r\n.label {\r\n  display: block;\r\n  font-weight: bold;\r\n  text-indent: 8px;\r\n  color: #656565;\r\n\r\n}\r\n\r\nh3 {\r\n  text-align: center;\r\n}\r\n\r\nbutton {\r\n  margin-left: 5px;\r\n  border: 1px solid #ccc;\r\n}\r\n\r\n.cont {\r\n  display: flex;\r\n}\r\n\r\n.thing {\r\n  flex: 1;\r\n  text-align: center;\r\n}\r\n\r\n.main-historical {\r\n  box-shadow: 3px 3px 3px gray;\r\n}\r\n\r\n.historical {\r\n  box-shadow: 2px 2px 2px gray;\r\n}\r\n\r\n.main-historical, .historical {\r\n  border: 1px solid black;\r\n  padding: 3px;\r\n  margin: 4px;\r\n}\r\n\r\n.thing-1{\r\n  z-index: 1000;\r\n  flex: 1;\r\n  text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
