export class UserSiteTypeLogin {
    siteID: number;
    siteName: string;
    userID: number;
    userName: string;
    typeID: number;
    typeName: string;
    logins: number;
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config);
        }
    }
}