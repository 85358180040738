// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-container {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    font-family: Arial, sans-serif; 
}

.data-item {
    margin-bottom: 10px;
}

.data-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}

.data-name {
    font-size: 1.5em;
    font-weight: bold;
    margin-right: 5px;
    flex-grow: 1;
    text-align: left;
}

.data-value {
    font-size: 1.5em;
    text-align: right;
    white-space: nowrap;
    flex-grow: 0;
}

.percent-display {
    color: gray;
    font-size: .75em;
 
}

.divider {
    border: 0;
    height: 1px;
    background-color: #ccc;
    margin: 5px 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/events/portfolio-event-type-metrics/portfolio-event-type-metrics.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,cAAc;IACd,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,gBAAgB;;AAEpB;;AAEA;IACI,SAAS;IACT,WAAW;IACX,sBAAsB;IACtB,aAAa;AACjB","sourcesContent":[".data-container {\r\n    height: 100%;\r\n    width: 100%;\r\n    margin: 0 auto;\r\n    padding: 10px;\r\n    font-family: Arial, sans-serif; \r\n}\r\n\r\n.data-item {\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.data-row {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 5px 0;\r\n}\r\n\r\n.data-name {\r\n    font-size: 1.5em;\r\n    font-weight: bold;\r\n    margin-right: 5px;\r\n    flex-grow: 1;\r\n    text-align: left;\r\n}\r\n\r\n.data-value {\r\n    font-size: 1.5em;\r\n    text-align: right;\r\n    white-space: nowrap;\r\n    flex-grow: 0;\r\n}\r\n\r\n.percent-display {\r\n    color: gray;\r\n    font-size: .75em;\r\n \r\n}\r\n\r\n.divider {\r\n    border: 0;\r\n    height: 1px;\r\n    background-color: #ccc;\r\n    margin: 5px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
