import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { siteGroupsReducer } from "./store/reducers";
import { siteGroupsEffects } from "./store/effects"
import { GridModule } from "@progress/kendo-angular-grid";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { FormsModule } from '@angular/forms';

import * as fromSiteGroupsState from './store/state';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSiteGroupsState.siteGroupsKey, siteGroupsReducer),
    EffectsModule.forFeature([siteGroupsEffects]),
    GridModule,
    LayoutModule,
    ButtonsModule,
    DialogsModule,
    FormsModule,  
  ],
  providers: [
    siteGroupsEffects,
  ],
})
export class AdminSiteGroupsModule { }
