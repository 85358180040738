import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SiteService } from '../site/site.service';
import { Site } from "app/site/site";
@Injectable({
    providedIn: 'root'
})
export class CVSDisabledGuard  {
    constructor(
        private siteService: SiteService,
        private router: Router,
    ) { }
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.siteService.getSite().pipe(
            map((site: Site) => {
                if (site.cvsDisabled) {
                    this.router.navigate(["dashboard"]);
                }
                return true;
            }),
        );
    }
}