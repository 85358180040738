import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WtsEvent } from 'app/events/event';

@Component({
  selector: 'app-summary-modal',
  templateUrl: './summary-modal.component.html',
  styleUrls: ['./summary-modal.component.css']
})
export class SummaryModalComponent implements OnInit {
  @Input('event') event: WtsEvent;
  @Output('close') closeEmitter = new EventEmitter<any>()
  constructor() { }

  ngOnInit() {
  }
  close() {
    this.closeEmitter.emit();
  }
}
