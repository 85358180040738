import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject, throwError } from "rxjs";
import { takeUntil, catchError, map, switchMap, shareReplay, tap } from "rxjs/operators";
import { UserService } from "../../users/user.service";
import { User } from "../../users/user";
import { UserType } from "../../users/user-type";
import { SiteListService } from "../../sites/site-list.service";
import { SiteListItem } from "../../sites/site-list-item";
import { PermissionsTemplate } from "../../permissions-template";
import { NotificationsService } from "app/notifications/notifications.service";
import { SiteGroupsService } from "../admin-site-groups/admin-site-groups.service";
import { SiteGroup, SiteGroupFull } from "../admin-site-groups/site-group";
import { AdminService } from "../admin.service";
import { SiteAccessPolicy } from "app/site/site-access-policy";
import { AnalyticsService } from "app/analytics.service";

@Component({
  templateUrl: "./account-edit.component.html",
  styleUrls: ["./account-edit.component.css"],
})
export class AccountEditComponent implements OnInit, OnDestroy {
  public user: Observable<User>;
  public currentUser: Observable<User>;
  public typeList: Observable<UserType[]>;
  public hardTypeList: Observable<UserType[]>;
  public siteList: Observable<SiteListItem[]>;
  public siteGroups: Observable<SiteGroup[]>;
  public siteGroupFull: Observable<SiteGroupFull>;
  public templates: Observable<PermissionsTemplate[]>;
  public siteAccessPolicyMap: Observable<Map<number, SiteAccessPolicy>>;
  public userSiteGroupsFull: SiteGroupFull[];

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private siteListService: SiteListService,
    private notificationsService: NotificationsService,
    private siteGroupsService: SiteGroupsService,   
    private analyticsService: AnalyticsService,
    private adminService: AdminService,
  ) {}

  public ngOnInit() {
    this.siteAccessPolicyMap = this.adminService.getSiteAccessPolicies().pipe(
      map(policies => {
          const policiesMap = policies.reduce((map, policy) => {
              map[policy.siteID] = policy;
              return map;
          }, new Map<number, SiteAccessPolicy>());

          return policiesMap;
      }),
      shareReplay(1),
    );

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
        this.user = this.userService
            .getOtherUser(+params["id"])
            .pipe(
                switchMap(user => this.siteAccessPolicyMap.pipe(
                    map(policiesMap => {
                        if(!user.singleSites) {
                            return user;
                        }
                        user.singleSites = user.singleSites.map(site => {
                            const policy = policiesMap[site.siteID];
                            return policy ? { ...site, allowLEAccess: policy.allowLEAccess } : site;
                        });
                        return user;
                    })
                )),
                takeUntil(this.destroy$),
            );
      });
    });

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.siteGroupsService
      .getSiteGroupsForUser(+params["id"])
      .pipe(takeUntil(this.destroy$))
      .subscribe((userSiteGroups) => {
        this.userSiteGroupsFull = userSiteGroups;
      });
    });  

    this.templates = this.userService.getPermissionsTemplates();
    this.currentUser = this.userService.getUser();
    this.typeList = this.userService.getTypes();
    this.hardTypeList = this.userService.getHardTypes();
    this.siteList = this.siteListService.getSiteList().pipe(
      switchMap(sites => this.siteAccessPolicyMap.pipe(
          map(policiesMap => {
              return sites.map(site => {
                  const policy = policiesMap[site.siteID];
                  return policy ? { ...site, allowLEAccess: policy.allowLEAccess } : site;
              });
          })
      ))
    );
    this.siteGroups = this.siteGroupsService.getSiteGroups();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public save(user: User) {
    let currentUserValue: User;
  
    this.currentUser.pipe(
      tap(currentUser => currentUserValue = currentUser),
      switchMap(() => 
        this.userService
          .updateUser(user)
          .pipe(
            takeUntil(this.destroy$),
            catchError((err) => {
                this.notificationsService.error("Failed to update user", err);
                return throwError(err);
            }),
          )
      ),
      takeUntil(this.destroy$),
    ).subscribe((updatedUser) => {
      this.analyticsService.track("User Updated Admin", {
        userID: updatedUser.id,
        updatedBy: currentUserValue.id,
      });
  
      this.router.navigate(["admin", "users"]);
    });
  }

  getFullSiteGroup(id) {
    this.siteGroupFull = this.siteGroupsService.getSiteGroup(id);
  }
}
