import { Component, Input } from '@angular/core';
import { EventTypeMetric } from '../event-type-metric';
import { Lookup } from 'app/lookup';

@Component({
  selector: 'app-portfolio-event-type-metrics',
  templateUrl: './portfolio-event-type-metrics.component.html',
  styleUrls: ['./portfolio-event-type-metrics.component.css']
})
export class PortfolioEventTypeMetricsComponent {

  @Input('eventTypeMetrics') set setEventTypeMetrics(value: EventTypeMetric) {
    this.eventTypeMetricsMap = {};
    if (value != null) {
      value.metrics.forEach(element => {
        this.eventTypeMetricsMap[element.cfTagCategoryID] = element;
      });
      this.totalEvents = value.totalEvents;
    }
  }

  @Input('eventTypeMetricsLoading') set setEventTypeMetricsLoading(value: boolean) {
    this.eventTypeMetricsLoading = value;
  }

  @Input('cfTagCategories') set setCfTagCategories(value: Lookup[]) {
    this.cfTagCategories = value;
  }

  public eventTypeMetrics: EventTypeMetric = null;
  public totalEvents: number = 0;
  public eventTypeMetricsMap: {[id: number]:any} = {};
  public eventTypeMetricsLoading: boolean = false;
  public cfTagCategories: Lookup[] = [];

}
