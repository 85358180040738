import { ActionReducer, Action } from '@ngrx/store';
import { User } from '../../users/user';
import { SiteListItem } from '../../sites/site-list-item';


export const SET_ACC_MGMT_USERS = "SET_ACC_MGMT_USERS";
export const RESEND_ACC_MGMT_USERS = "RESEND_ACC_MGMT_USERS";
export const UPDATE_ACC_MGMT_USER = "UPDATE_ACC_MGMT_USER";

export const SET_ACC_MGMT_DELETED_USERS = "SET_ACC_MGMT_DELETED_USERS";

export const SET_ACC_MGMT_SITE = "SET_ACC_MGMT_SITE";

export const ACC_MGMT_FILTERS = { ALL: 'All', SITE: 'Site', WATCHTOWER: 'Watchtower' };

export const ACC_MGMT_FILTER_ALL = "ACC_MGMT_FILTER_ALL";
export const ACC_MGMT_FILTER_SITE = "ACC_MGMT_FILTER_SITE";
export const ACC_MGMT_FILTER_WATCHTOWER = "ACC_MGMT_FILTER_WATCHTOWER";

export const initialAccMgmtUsers = [];
export const initialAccMgmtDeletedUsers = [];
export const initialAccMgmtSite = {siteName: '', siteID: -1};
export const initialAccMgmtFilter = ACC_MGMT_FILTERS.ALL;

// export const accMgmtUsersReducer = (state: User[] = initialAccMgmtUsers, action: Action) => {
//     switch(action.type) {
//         case SET_ACC_MGMT_USERS:
//             return [...action.payload];
//         case UPDATE_ACC_MGMT_USER:
//             return state.map(user => (user.id === action.payload.id)? new User(action.payload) : user);
//         case RESEND_ACC_MGMT_USERS:
//             return [...state];
//         default:
//             return state;
//     }
// }

export function accMgmtUsersReducer(state: User[] = initialAccMgmtUsers, action): User[] {
    switch(action.type) {
        case SET_ACC_MGMT_USERS:
            return [...action.payload];
        case UPDATE_ACC_MGMT_USER:
            return state.map(user => (user.id === action.payload.id)? new User(action.payload) : user);
        case RESEND_ACC_MGMT_USERS:
            return [...state];
        default:
            return state;
    }    
}

export function accMgmtDeletedUsersReducer(state: User[] = initialAccMgmtDeletedUsers, action): User[] {
    switch(action.type) {
        case SET_ACC_MGMT_DELETED_USERS:
            return [...action.payload];
        default:
            return state;
    }    
}

// export const accMgmtSiteReducer = (state: SiteListItem = initialAccMgmtSite, action: Action) => {

//     switch(action.type) {
//         case SET_ACC_MGMT_SITE:
//             return new SiteListItem(action.payload.siteName, action.payload.siteID);
//         default:
//             return state;
//     }
// }

export function accMgmtSiteReducer(state: SiteListItem = initialAccMgmtSite, action): SiteListItem {
    switch(action.type) {
        case SET_ACC_MGMT_SITE:
            return new SiteListItem(action.payload.siteName, action.payload.siteID);
        default:
            return state;
    }    
}

// export const accMgmtFilterReducer = (state = initialAccMgmtFilter, action: Action) => {
//     switch(action.type) {
//         case ACC_MGMT_FILTER_ALL:
//             return ACC_MGMT_FILTERS.ALL;
//         case ACC_MGMT_FILTER_SITE: 
//             return ACC_MGMT_FILTERS.SITE;
//         case ACC_MGMT_FILTER_WATCHTOWER:
//             return ACC_MGMT_FILTERS.WATCHTOWER
//         default:
//             return state;
//     }
// }

export function accMgmtFilterReducer(state = initialAccMgmtFilter, action: Action) {
    switch(action.type) {
        case ACC_MGMT_FILTER_ALL:
            return ACC_MGMT_FILTERS.ALL;
        case ACC_MGMT_FILTER_SITE: 
            return ACC_MGMT_FILTERS.SITE;
        case ACC_MGMT_FILTER_WATCHTOWER:
            return ACC_MGMT_FILTERS.WATCHTOWER
        default:
            return state;
    }    
}