import { TimeObj } from "app/time-obj";

export class MotionRequest {
    start: TimeObj;
    end: TimeObj;
    cameraIDs: number[] = [];
    constructor(start: TimeObj, end: TimeObj, cameraIDs: number[]) {
        this.start = start;
        this.end = end;
        this.cameraIDs = cameraIDs
    }
}