import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { map, switchMap, catchError } from 'rxjs/operators';
import { AppState } from "app/app.state";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import * as EventActions from './actions';
import * as eventSelectors from './selectors';
import { NotificationsService } from "app/notifications/notifications.service";
import { EventService } from "../event.service";
import { UserService } from '../../users/user.service';
import { AnalyticsService } from 'app/analytics.service';

@Injectable()
export class EventEffects {

    getPortfolioEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                EventActions.getPortfolioEvents, 
                EventActions.searchEvents, 
                EventActions.setEventGridState, 
                EventActions.clearFilters,
                EventActions.setSiteFilter,
                EventActions.setCfTagCategoryFilter,
                EventActions.setStartDateFilter,
                EventActions.setEndDateFilter,
                EventActions.setStatusFilter,
                EventActions.setTextFilter,
                EventActions.setSelectedBubble,
            ),
            concatLatestFrom(() => [
                this.store.select(eventSelectors.eventGridState),
                this.store.select(eventSelectors.eventSort),
                this.store.select(eventSelectors.siteFilterArrayInt),
                this.store.select(eventSelectors.cfTagCategoryFilterArrayInt),
                this.store.select(eventSelectors.startDateFilter),
                this.store.select(eventSelectors.endDateFilter),
                this.store.select(eventSelectors.statusFilterArrayInt),
                this.store.select(eventSelectors.textFilter),
                this.store.select(eventSelectors.selectedBubble),                             
            ]),
            switchMap(([action, gridState, eventSort, siteFilter, cfTagCategoryFilter, startDateFilter, endDateFilter, statusFilter, textFilter, selectedBubble]) => {                
                let page = (gridState.skip + gridState.take) / gridState.take;
                let pageSize = gridState.take;
                return this.eventService.getPortfolioEvents(
                    page,
                    pageSize,
                    eventSort,
                    siteFilter,
                    startDateFilter,
                    endDateFilter,
                    cfTagCategoryFilter,
                    statusFilter,
                    textFilter,
                    selectedBubble).pipe(
                    map(data => EventActions.getPortfolioEventsSuccess({ events: data })),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading portfolio events")
                        return of(EventActions.getPortfolioEventsFailure());
                    }),
                )
            })
        )
    })

    getSiteList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.getSiteList),
            switchMap((action) => { 
                return this.eventService.getSitesForFilter().pipe(
                    map(data => EventActions.getSiteListSuccess({sites: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading site list")
                        return of(EventActions.getSiteListFailure());
                    }),
                )
            })
        )
    })

    getCfTagCategories$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.getCfTagCategories),
            switchMap((action) => { 
                return this.eventService.getCfTagCategories().pipe(
                    map(data => EventActions.getCfTagCategoriesSuccess({categories: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading CF Tag Categories")
                        return of(EventActions.getCfTagCategoriesFailure());
                    }),
                )
            })
        )
    })

    getStatusCodes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.getStatusCodes),
            switchMap((action) => { 
                return this.eventService.getEventStatusCodes().pipe(
                    map(data => EventActions.getStatusCodesSuccess({statusCodes: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading Status Codes")
                        return of(EventActions.getStatusCodesFailure());
                    }),
                )
            })
        )
    })

    freshPaintSortLogging$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.setEventGridState),
            concatLatestFrom(() => [
                this.store.select(eventSelectors.eventSort),
            ]),
            switchMap(([action, eventSort]) => {
                this.analyticsService.track("User Sorted Portfolio Events", {
                    sortField: eventSort.field,
                    sortDir: eventSort.dir
                });
                return of(EventActions.noAction()).pipe(
                    catchError(() => {
                        return of(EventActions.noAction());
                    }),
                )
            })
        )
    })

    freshPaintSearchLogging$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.searchEvents),
            concatLatestFrom(() => [
                this.store.select(eventSelectors.cfTagCategoryFilter),
                this.store.select(eventSelectors.statusFilter),
                this.store.select(eventSelectors.textFilter),
            ]),
            switchMap(([action, cfTagCategories, statusFilter, textFilter ]) => {
                this.analyticsService.track("User Events Search", {
                    eventType: cfTagCategories,
                    status: statusFilter,
                    searchText: textFilter
                });
                return of(EventActions.noAction()).pipe(
                    catchError(() => {
                        return of(EventActions.noAction());
                    }),
                )
            })
        )
    })

    getEventTypeMetrics$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                EventActions.getEventTypeMetrics, 
                EventActions.searchEvents,
                EventActions.clearFilters,
                EventActions.setSiteFilter,
                EventActions.setCfTagCategoryFilter,
                EventActions.setStartDateFilter,
                EventActions.setEndDateFilter,
                EventActions.setStatusFilter,
                EventActions.setTextFilter,
            ),
            concatLatestFrom(() => [
                this.store.select(eventSelectors.siteFilterArrayInt),
                this.store.select(eventSelectors.cfTagCategoryFilterArrayInt),
                this.store.select(eventSelectors.startDateFilter),
                this.store.select(eventSelectors.endDateFilter),
                this.store.select(eventSelectors.statusFilterArrayInt),
                this.store.select(eventSelectors.textFilter),                
            ]),
            switchMap(([action, siteFilter, cfTagCategoryFilter, startDateFilter, endDateFilter, statusFilter, textFilter]) => {
                return this.eventService.getEventTypeMetrics( 
                    siteFilter,
                    startDateFilter,
                    endDateFilter,
                    cfTagCategoryFilter,
                    statusFilter,
                    textFilter).pipe(
                    map(data => EventActions.getEventTypeMetricsSuccess({ metrics: data })),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading portfolio event type metrics")
                        return of(EventActions.getEventTypeMetricsFailure());
                    }),
                )
            })
        )
    })

    getEventTimeDayHour$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                EventActions.getEventTimeDayHourMetrics,
                EventActions.searchEvents,
                EventActions.clearFilters,
                EventActions.setSiteFilter,
                EventActions.setCfTagCategoryFilter,
                EventActions.setStartDateFilter,
                EventActions.setEndDateFilter,
                EventActions.setStatusFilter,
                EventActions.setTextFilter,
            ),
            concatLatestFrom(() => [
                this.store.select(eventSelectors.siteFilterArrayInt),
                this.store.select(eventSelectors.cfTagCategoryFilterArrayInt),
                this.store.select(eventSelectors.startDateFilter),
                this.store.select(eventSelectors.endDateFilter),
                this.store.select(eventSelectors.statusFilterArrayInt),
                this.store.select(eventSelectors.textFilter),                
            ]),
            switchMap(([action, siteFilter, cfTagCategoryFilter, startDateFilter, endDateFilter, statusFilter, textFilter]) => {
                return this.eventService.getEventDayHourMetrics( 
                    siteFilter,
                    startDateFilter,
                    endDateFilter,
                    cfTagCategoryFilter,
                    statusFilter,
                    textFilter).pipe(
                    map(data => EventActions.getEventTimeDayHourSuccess({ metrics: data })),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading portfolio event time day hour metrics")
                        return of(EventActions.getEventTypeMetricsFailure());
                    }),
                )
            })
        )
    })

    constructor(
        private actions$: Actions,
        private notificationsService: NotificationsService,
        private store: Store<AppState>,
        private eventService: EventService,
        private userService: UserService,
        private analyticsService: AnalyticsService
    ) { }
}