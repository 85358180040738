import { ActionReducer, Action } from '@ngrx/store';
import { Site } from './site';

export const SET_SITE = 'SET_SITE';
export const SITE_LOADING = 'SITE_LOADING';
export const SITE_DONE_LOADING = 'SITE_DONE_LOADING';

export const initialSite: Site = null;
export const initialSiteLoading: boolean = false;

// export const siteReducer: ActionReducer<Site> = (state: Site = initialSite, action: Action) => {
//     console.log(action)
//     switch (action.type) {
//         case SET_SITE:
//             console.log(action.payload)
//             return new Site(action.payload);
//         default:
//             return state;
//     }
// }

export function siteReducer(state: Site = initialSite, action): Site {
    switch (action.type) {
        case SET_SITE:
            return new Site(action.payload);
        default:
            return state;
    }    
} 

// export const siteLoadingReducer: ActionReducer<boolean> = (state: boolean = initialSiteLoading, action: Action) => {
//     switch (action.type) {
//         case SITE_LOADING:
//             return true;
//         case SITE_DONE_LOADING:
//             return false;
//         default:
//             return state;
//     }
// }

export function siteLoadingReducer(state: boolean = initialSiteLoading, action: Action): boolean {
    switch (action.type) {
        case SITE_LOADING:
            return true;
        case SITE_DONE_LOADING:
            return false;
        default:
            return state;
    }    
}