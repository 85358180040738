import { props, createAction } from "@ngrx/store";
import Feature from "../models/feature";

export const loadFeaturesSuccess = createAction(
  "[Feature/Collection/API] Load Features Success",
  props<{ features: Feature[] }>(),
);

export const loadFeaturesFailure = createAction(
  "[Feature/Collection/API] Load Features Failure",
  props<{ errorMessage: string }>(),
);
