import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { catchError, map, switchMap, debounceTime } from "rxjs/operators";
import { EMPTY as empty, of, asyncScheduler } from "rxjs";
import { SitePackageViewActions, SitePackageAPIActions } from "../actions";
import { SitePackageService } from "../services";
import SitePackage from "../models/site-package";

@Injectable()
export class SitePackageEffects {
  public loadSitePackages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitePackageViewActions.loadSitePackages),
      switchMap(() =>
        this.sitePackageService.fetch().pipe(
          map((sitePackages: SitePackage[]) => SitePackageAPIActions.loadSitePackagesSuccess({ sitePackages })),
          catchError((errorMessage: string) => of(SitePackageAPIActions.loadSitePackagesFailure({ errorMessage }))),
        ),
      ),
    ),
  );

  public updateSitePackage$ = createEffect(() => ({ debounce = 300, scheduler = asyncScheduler } = {}) =>
    this.actions$.pipe(
      ofType(SitePackageViewActions.updateSitePackage),
      debounceTime(debounce, scheduler),
      switchMap(({ sitePackage }) => {
        if (!sitePackage) {
          return empty;
        }

        return this.sitePackageService.update(sitePackage).pipe(
          map((sitePackage: SitePackage) => SitePackageAPIActions.updateSitePackageSuccess({ sitePackage })),
          catchError((errorMessage: string) => of(SitePackageAPIActions.updateSitePackageFailure({ errorMessage }))),
        );
      }),
    ),
  );

  public createSitePackage$ = createEffect(() => ({ debounce = 300, scheduler = asyncScheduler } = {}) =>
    this.actions$.pipe(
      ofType(SitePackageViewActions.createSitePackage),
      debounceTime(debounce, scheduler),
      switchMap(({ sitePackage }) => {
        if (!sitePackage) {
          return empty;
        }

        return this.sitePackageService.create(sitePackage).pipe(
          map((sitePackage: SitePackage) => SitePackageAPIActions.createSitePackageSuccess({ sitePackage })),
          catchError((errorMessage: string) => of(SitePackageAPIActions.createSitePackageFailure({ errorMessage }))),
        );
      }),
    ),
  );

  public deleteSitePackage$ = createEffect(() => ({ debounce = 300, scheduler = asyncScheduler } = {}) =>
    this.actions$.pipe(
      ofType(SitePackageViewActions.deleteSitePackage),
      debounceTime(debounce, scheduler),
      switchMap(({ id }) => {
        if (id <= 0) {
          return empty;
        }

        return this.sitePackageService.delete(id).pipe(
          map((id: number) => SitePackageAPIActions.deleteSitePackageSuccess({ id })),
          catchError((errorMessage: string) => of(SitePackageAPIActions.createSitePackageFailure({ errorMessage }))),
        );
      }),
    ),
  );

  constructor(private actions$: Actions, private sitePackageService: SitePackageService) {}
}
