import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Address } from './address';

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.css'],
})
export class AddressComponent {
    @Input() set initialAddress(value: Address) {
        this.address = new Address(value);
    }
    @Output('address') changeAddress = new EventEmitter();
    public address: Address = new Address();
    onChange() {
        this.changeAddress.emit(this.address);
    }
    public states: string[] = ['AL', 'AK', 'AZ', 'AR', 'CA','CO', 'CT', 'DE', 'FL', 'GA', 'HI',
     'ID', 'IL', 'IN', 'IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV', 'NH',
     'NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY']
}