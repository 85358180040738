import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from "./state";
import * as NewLeadActions from './actions';

const reducer = createReducer(
    initialState,
    on(NewLeadActions.setShowNewLeadForm, (state: State, {show}) => {
        return {
            ...state,
            showNewLeadForm: show,
        };
    }),
    on(NewLeadActions.setManagementCompanies, (state: State, {companies}) => {
        return {
            ...state,
            managementCompanies: companies,
        };
    }),
    on(NewLeadActions.setLeadSources, (state: State, {sources}) => {
        return {
            ...state,
            leadSources: sources,
        };
    }),
    on(NewLeadActions.setInternalEmails, (state: State, {emails}) => {
        return {
            ...state,
            internalEmails: emails,
        };
    }),
    on(NewLeadActions.setRelatedSites, (state: State, {sites}) => {
        return {
            ...state,
            relatedSites: sites,
        };
    }),
    on(NewLeadActions.submitNewLead, (state: State) => {
        return {
            ...state,
            submitInProgress: true,            
        };
    }),
    on(NewLeadActions.submitNewLeadSuccess, (state: State) => {
        return {
            ...state,
            relatedSites: [],
            showNewLeadForm: false,
            submitInProgress: false,
        };
    }),
    on(NewLeadActions.submitNewLeadFailure, (state: State) => {
        return {
            ...state,
            submitInProgress: false,
        };
    }),
    on(NewLeadActions.cancelSubmitNewLead, (state: State) => {
        return {
            ...state,
            submitInProgress: false,
        };
    }),
);

export function newLeadReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
