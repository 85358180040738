import { SummaryFile } from "../summary";


export class FileShare {
    message: string;
    userName: string;
    eventID: number;
    siteName: string;
    files: SummaryFile[];
    includeEmails: string[];
    includeSummary: boolean;
    summaryText: string;
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config)
            if(!config.files) {
                this.files = [];
            }
        }
    }
}