import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import Auth0Lock from 'auth0-lock';
import { Auth0Result, Auth0Error, LogoutOptions } from 'auth0-js';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Auth0Adapter {

  private lockOptions: Auth0LockConstructorOptions = {
    container: 'auth0-lock',
    avatar: null,
    allowSignUp: false,
    auth: {
      redirectUrl: window.location.origin,  
      audience: environment.auth.audience,
      responseType: 'token id_token',
      params: {
        scope: 'openid profile email',
      },
    },
    theme: {
      hideMainScreenTitle: true,
      primaryColor: '#43aaa3',
      logo: '',
    },
    languageDictionary: {
      emailInputPlaceholder: 'Email',
      passwordInputPlaceholder: 'Password',
      title: 'Watchtower Security',
    }
  };

  private lock: Auth0LockStatic;

  private authenticatedSubject: Subject<Auth0Result | null> =
    new Subject<Auth0Result | null>();
  private authorizationErrorSubject: Subject<Auth0Error | null> =
    new Subject<Auth0Error | null>();

  constructor() {
    this.lock = new Auth0Lock(
      environment.auth.clientId,
      environment.auth.domain,
      this.lockOptions
    );

    this.checkSession();
    this.subscribeToAuth0Events();
  }

  showLock(): void {
    this.lock.show();
  }

  hideLock(): void {
    this.lock.hide();
  }

  logout(options: LogoutOptions): void {
    this.lock.logout(options);
  }

  checkSession(): void {
    this.lock.checkSession(
      {},
      (error: Auth0Error, authResult: Auth0Result | undefined) => {
        this.authenticatedSubject.next(authResult ?? null);
        if (error) {
          this.authorizationErrorSubject.next(error);
        }
      }
    );
  }
 
  get authenticated$(): Observable<Auth0Result | null> {
    return this.authenticatedSubject.asObservable();
  }

  get authorizationError$(): Observable<Auth0Error | null> {
    return this.authorizationErrorSubject.asObservable();
  }

  private subscribeToAuth0Events(): void {
    this.lock.on('authenticated', (authResult: Auth0Result) => {
      this.authenticatedSubject.next(authResult);
    });

    this.lock.on('hash_parsed', (authResult: Auth0Result) => {
      if (authResult && authResult.accessToken) {
        this.authenticatedSubject.next(authResult);
      }
    });

    this.lock.on('signin ready', () => { 
      localStorage.setItem('wts_auth0_signin_route', window.location.pathname)
    });

    this.lock.on('authorization_error', (error: Auth0Error) => {
      this.authorizationErrorSubject.next(error);
    });
  }

}
