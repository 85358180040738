import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { AppState } from "app/app.state";
import { ManagementCompany } from './management-company';
import { LeadSource } from './lead-source';
import { InternalEmail } from './internal-email';
import { RelatedSite } from './related-site';
import { NewLead } from './new-lead';
import * as selectors from './store/selectors';
import * as actions from './store/actions';

@Component({
  selector: 'app-new-lead',
  templateUrl: './new-lead.component.html',
  styleUrls: ['./new-lead.component.css']
})
export class NewLeadComponent implements OnInit {

  public managementCompanies$: Observable<ManagementCompany[]>;
  public leadSources$: Observable<LeadSource[]>;
  public internalEmails$: Observable<InternalEmail[]>;
  public relatedSites$: Observable<RelatedSite[]>;
  public showNewLeadForm$: Observable<boolean>;
  public submitInProgress$: Observable<boolean>;

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {  
    this.managementCompanies$ = this.store.pipe(select(selectors.managementCompanies));
    this.leadSources$ = this.store.pipe(select(selectors.leadSources));
    this.internalEmails$ = this.store.pipe(select(selectors.internalEmails));
    this.relatedSites$ = this.store.pipe(select(selectors.relatedSites));
    this.showNewLeadForm$ = this.store.pipe(select(selectors.showNewLeadForm));
    this.submitInProgress$ = this.store.pipe(select(selectors.submitInProgress));
  }

  closeForm(): void {
    this.store.dispatch(actions.cancelSubmitNewLead());
    this.store.dispatch(actions.setShowNewLeadForm({show: false}));
    this.clearRelatedSites();
  }

  onSubmit(event: { lead: NewLead, images: File[] }): void {
    const leadWithImages = {
      ...event.lead,
      imageFiles: event.images
    };

    this.store.dispatch(actions.submitNewLead({ lead: leadWithImages }));
  }

  findRelatedSites(search: string): void {
    this.store.dispatch(actions.getRelatedSites({search: search}));
  }

  clearRelatedSites(): void {
    this.store.dispatch(actions.setRelatedSites({sites: []}));
  }
}
