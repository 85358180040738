import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AccessKeyService } from '../users/access-key.service';
import { ServiceHelpersService } from '../service-helpers.service';
import { config } from '../app.config';
import { PeopleCamera } from './counting/people-camera';
import { GraphSeries } from './counting/graph-series';
import { catchError, map } from 'rxjs/operators';
import { CountChanges } from './counting/count-changes';
import { FilteredEventMap } from 'app/event-map/filtered-event-map';
import { ShortRequest } from './historical/short-request';
import { MapData } from 'app/map/map';
import { CFTagCategory } from './cf-tag-category';


@Injectable()
export class MetricsService {
    public headers = {};
    public params = {};
    constructor(public http:HttpClient, public keyService: AccessKeyService, public helperService: ServiceHelpersService) {
        this.keyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, {"accessKey": key}));
    }

    // Car Counting
    getCarCountCameras(siteID: number): Observable<{alprCameras: number[]}> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<{alprCameras: number[]}>(config.apiUrl + "site/" + siteID + "/car-count/cameras", {headers: headers}).pipe(catchError(this.helperService.handleError));
    }
    getCarCountByMonth(siteID: number): Observable<GraphSeries[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<GraphSeries[]>(config.apiUrl + "site/" + siteID + "/car-count/month", {headers: headers}).pipe(catchError(this.helperService.handleError), map(series => {
            if(series && series.map) {
                return series.map(s => new GraphSeries(s));
            }
            return series;
        }));
    }
    getCarCountByWeekday(siteID: number): Observable<GraphSeries[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<GraphSeries[]>(config.apiUrl + "site/" + siteID + "/car-count/weekday", {headers: headers}).pipe(catchError(this.helperService.handleError), map(series => {
            if(series && series.map) {
                return series.map(s => new GraphSeries(s));
            }
            return series;
        }));
    }
    getCarCountByWeek(siteID: number): Observable<GraphSeries[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<GraphSeries[]>(config.apiUrl + "site/" + siteID + "/car-count/week", {headers: headers}).pipe(catchError(this.helperService.handleError), map(series => {
            if(series && series.map) {
                return series.map(s => new GraphSeries(s));
            }
            return series;
        }));
    }
    getCarCountChanges(siteID: number): Observable<CountChanges> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<CountChanges>(config.apiUrl + "site/" + siteID + "/car-count/changes", {headers: headers}).pipe(catchError(this.helperService.handleError), map(c => new CountChanges(c)));
    }

    // People Counting
    getPeopleCountingCamerasBySite(siteID: number): Observable<PeopleCamera[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<PeopleCamera[]>(config.apiUrl + "site/" + siteID + "/peoplecounter/cameras", {headers: headers}).pipe(catchError(this.helperService.handleError), map(cams => {
            if(cams && cams.map) {
                return cams.map(c => new PeopleCamera(c));
            }
            return cams;
        }));
    }
    getPeopleCountingSchedule(camID: number): Observable<any> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<any>(config.apiUrl + "site/peoplecounter/camera/" + camID + "/schedule", {headers: headers}).pipe(catchError(this.helperService.handleError));
    }
    getPeopleByWeek(camID: number): Observable<GraphSeries[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<GraphSeries[]>(config.apiUrl + "site/peoplecounter/camera/" + camID + "/countsbyweek", {headers: headers}).pipe(catchError(this.helperService.handleError), map(series => {
            if(series && series.map) {
                return series.map(s => new GraphSeries(s));
            }
            return series;
        }));
    }
    getPeopleByWeekday(camID: number): Observable<GraphSeries[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<GraphSeries[]>(config.apiUrl + "site/peoplecounter/camera/" + camID + "/countsbyweekday", {headers: headers}).pipe(catchError(this.helperService.handleError), map(series => {
            if(series && series.map) {
                return series.map(s => new GraphSeries(s));
            }
            return series;
        }));
    }
    getPeopleByHour(camID: number): Observable<GraphSeries[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<GraphSeries[]>(config.apiUrl + "site/peoplecounter/camera/" + camID + "/countsbyhour", {headers: headers}).pipe(catchError(this.helperService.handleError), map(series => {
            if(series && series.map) {
                return series.map(s => new GraphSeries(s));
            }
            return series;
        }));
    }
    getPeopleChanges(camID: number): Observable<CountChanges> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<CountChanges>(config.apiUrl + "site/peoplecounter/camera/" + camID + "/changes", {headers: headers}).pipe(catchError(this.helperService.handleError), map(c => new CountChanges(c)));
    }
    getEventTimesByDayHour(siteID: number, startDate: Date, endDate: Date): Observable<any> {
        let headers = new HttpHeaders(this.headers);
        let params = new HttpParams()
        .set('startdate', startDate.toLocaleDateString())
        .set('enddate', endDate.toLocaleDateString());
        return this.http.get<any>(config.apiUrl + "v0/eventTimesByDayHour/site/" + siteID , {headers: headers, params: params}).pipe(catchError(this.helperService.handleError));
    }  
    getEventOutcomes(siteID: number, endDate: Date): Observable<any> {
        let headers = new HttpHeaders(this.headers);
        let params = new HttpParams()
        .set('date', endDate.toLocaleDateString());
        return this.http.get<any>(config.apiUrl + "v0/eventOutcomes/site/" + siteID , {headers: headers, params: params}).pipe(catchError(this.helperService.handleError));
    }
    getLoginsOverTime(siteID: number): Observable<any> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<any>(config.apiUrl + "v0/loginsOverTime/site/" + siteID , {headers: headers}).pipe(catchError(this.helperService.handleError));
    }
    getLoginInfo(siteID: number, startDate: Date, endDate: Date): Observable<any> {
        let headers = new HttpHeaders(this.headers);
        let params = new HttpParams()
        .set('startdate', startDate.toLocaleDateString())
        .set('enddate', endDate.toLocaleDateString());       
        return this.http.get<any>(config.apiUrl + "v0/loginInfo/site/" + siteID , {headers: headers, params: params}).pipe(catchError(this.helperService.handleError));
    }

    getMaps(siteID: number): Observable<MapData[]> {
        let headers = new HttpHeaders(this.headers);
        let params = new HttpParams().set('allEvents', 'true');
        return this.http.get<MapData[]>(`${config.apiUrl}site/${siteID}/maps`, {headers: headers, params: params}).pipe(catchError(this.helperService.handleError))
    }
    getRequests(siteID: number, startDate?: Date): Observable<ShortRequest[]> {
        let headers = new HttpHeaders(this.headers);
        let options = {headers: headers}
        if(startDate && startDate.toLocaleDateString) {
            let str = startDate.getFullYear() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getDate();
            let params = new HttpParams().set('startDate', str)
            options["params"] = params;
        }
        return this.http.get<ShortRequest[]>(`${config.apiUrl}site/${siteID}/requests`, options).pipe(catchError(this.helperService.handleError), map(requests => {
            if(requests && requests.map) {
                return requests.map(r => new ShortRequest(r));
            }
            return requests;
        }));
    }

    getCFTagCategories(): Observable<CFTagCategory[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<CFTagCategory[]>(`${config.apiUrl}cftags/cftagCategories`, {headers: headers}).pipe(catchError(this.helperService.handleError), map(cats => {
            if(cats && cats.map) {
                return cats.map(c => new CFTagCategory(c));
            }
            return cats;
        }));
    }
}
