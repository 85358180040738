// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 600px) {
    .col-1-3,
    .col-2-3 {
        flex: 1 1 100%;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.col-1-3 {
    flex: 1 1 25%;
    padding: 0;
    margin: 0;
}

.col-2-3 {
    flex: 1 1 75%;
    padding: 0;
    margin: 0;    
}

.box {
    height: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/events/portfolio-event/portfolio-event.component.css"],"names":[],"mappings":"AAAA;IACI;;QAEI,cAAc;IAClB;AACJ;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,UAAU;AACd;;AAEA;IACI,aAAa;IACb,UAAU;IACV,SAAS;AACb;;AAEA;IACI,aAAa;IACb,UAAU;IACV,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb","sourcesContent":["@media (max-width: 600px) {\r\n    .col-1-3,\r\n    .col-2-3 {\r\n        flex: 1 1 100%;\r\n    }\r\n}\r\n\r\n.row {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    margin: 0;\r\n    padding: 0;\r\n}\r\n\r\n.col-1-3 {\r\n    flex: 1 1 25%;\r\n    padding: 0;\r\n    margin: 0;\r\n}\r\n\r\n.col-2-3 {\r\n    flex: 1 1 75%;\r\n    padding: 0;\r\n    margin: 0;    \r\n}\r\n\r\n.box {\r\n    height: 100%;\r\n    padding: 10px;\r\n    border: 1px solid #ddd;\r\n    margin: 0;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
