import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { config } from '../app.config';
import { Logger } from '../logger/logger.service';
import { SET_SITELIST} from './site-list.reducer';
import { AccessKeyService } from '../users/access-key.service';
import { ServiceHelpersService } from '../service-helpers.service';
import { SiteListItem } from './site-list-item';
import { ManageSiteItem } from './manage-site-item';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SiteListService {
    public headers = {};
    public sitesLoaded = false;
    constructor(public http:HttpClient, public store:Store<AppState>,public accessKeyService: AccessKeyService, public serviceHelpersService: ServiceHelpersService) {
        this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key }));
    }

    getSiteList(): Observable<SiteListItem[]> {
        if(!this.sitesLoaded) {
            this.fetchSiteList();
        }
        return this.store.select(s => s.siteList);
    }

    fetchSiteList() {
        let that = this;
        let headers = new HttpHeaders(this.headers);
        this.http.get<SiteListItem[]>(config.apiUrl + "sites", { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError)).subscribe(sites => {
            that.store.dispatch({type: SET_SITELIST, payload: sites})
            this.sitesLoaded = true;
        })
    }

    getNewSiteList(): Observable<SiteListItem[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<SiteListItem[]>(config.apiUrl + "sites/new", { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError));
    }
    getManageSitesList(): Observable<ManageSiteItem[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<ManageSiteItem[]>(config.apiUrl + "sites/manage", { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError));
    }
    getHqAdminList(): Observable<{id: number, name: string}[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<{id: number, name: string}[]>(config.apiUrl + "sites/hqAdmin", { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError));
    }
}