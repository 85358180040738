// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-picker .time-picker-field {
  font-size: 14px;
  padding: 6px 12px;
  height: 34px;
  text-align: center;
}
.time-picker .time-picker-meridian {
  height: 34px;
}`, "",{"version":3,"sources":["webpack://./src/app/time-picker/time-picker.component.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;AAAR;AAGI;EACI,YAAA;AADR","sourcesContent":[".time-picker {\r\n    .time-picker-field {\r\n        font-size: 14px;\r\n        padding: 6px 12px;\r\n        height: 34px;\r\n        text-align: center;\r\n    }\r\n\r\n    .time-picker-meridian {\r\n        height: 34px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
