import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventSearch } from './event-search';
import { SiteListService } from 'app/sites/site-list.service';
import { SiteListItem } from 'app/sites/site-list-item';
import { Observable, Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { AdminService } from '../admin.service';
import { Lookup } from 'app/lookup';
import { map, takeWhile } from 'rxjs/operators';
import { AdminEventsService } from './admin-events.service';
import { EventListItem } from './event-list-item';
import { Router } from '@angular/router';
import { WtsEvent } from 'app/events/event';
import { EventService } from 'app/events/event.service';
import { NotificationsService } from 'app/notifications/notifications.service';
import { AppState } from 'app/app.state';
import { select, Store } from '@ngrx/store';
import * as selectors from '../store/selectors';
import * as actions from '../store/actions';
import { AdminSiteListItem } from '../adminSiteListItem';

@Component({
  selector: 'app-admin-events',
  templateUrl: './admin-events.component.html',
  styleUrls: ['./admin-events.component.css']
})
export class AdminEventsComponent implements OnInit, OnDestroy {

  public lastSearch: EventSearch = null;
  public currentSearch: EventSearch = new EventSearch();

  public sites: Observable<AdminSiteListItem[]>;
  public managementCompanies: Observable<Lookup[]>;
  public managementFilter: BehaviorSubject<number> = new BehaviorSubject(0);
  public alive: boolean = true;
  public events: Observable<EventListItem[]>;
  public loading: Observable<boolean>;
  public sameSearch: Observable<boolean>;
  public emptySearch: Observable<boolean>;
  public selectedEvent: WtsEvent;
  public sitesLoading$: Observable<boolean>;

  constructor(
    private adminService: AdminService, 
    private eventsService: AdminEventsService, 
    private router: Router,
    private eventService: EventService,
    private notificationsService: NotificationsService,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.managementCompanies = this.adminService.getManagementCompanies();
    this.sitesLoading$ = this.store.pipe(select(selectors.sitesLoading));
    this.sites = combineLatest([this.adminService.getSites(), this.eventsService.getCurrentManagementCompany()]).pipe(map(([sites, mgmt]) => {
      let filteredSites = sites;
      if(mgmt) {
        filteredSites = filteredSites.filter(site => site.manCompanyID === mgmt)
      }
      return filteredSites
    }));
    this.eventsService.getCurrentSearch().pipe(takeWhile(()=>this.alive)).subscribe(current => {
      if(current != null) {
        this.currentSearch = current;
      }
    })
    this.events = this.eventsService.getEvents();
    this.loading = this.eventsService.getLoading();
    this.sameSearch = this.eventsService.getIsSameSearch();
    this.emptySearch = this.eventsService.getIsEmptySearch();
  }
  ngOnDestroy() {
    this.alive = false;
  }
  managementChanged(id: number) {
    this.eventsService.managementChanged(id);
  }
  siteChanged(id: number) {
    this.eventsService.siteChanged(id);
  }
  searchChanged(search: string) {
    this.eventsService.searchChanged(search);
  }
  starChanged(isStarred: boolean) {
    this.eventsService.starChanged(isStarred);
  }
  outcomeChanged(outcome: string) {
    this.eventsService.outcomeChanged(outcome);
  }
  search() {
    this.eventsService.search();
  }
  view(event: EventListItem) {
    this.router.navigate(['site', event.siteID, 'events', event.id]);
  }
  sortByColumn(column: string) {
    this.eventsService.sortByColumn(column);
  }
  closeModal() {
    this.selectedEvent = null;
  }
  openEvent(eventID: number) {
    this.eventService.getEvent(eventID).subscribe(event => {
      this.selectedEvent = event;
    }, e => {
      this.notificationsService.error("", "Error loading summaries for event")
    })
  }
  onInputKeyup(event) {
    if(event.key === "Enter") {
      this.search();
    }
  }
}
