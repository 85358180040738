import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventDayHourMetric } from '../event-day-hour-metric';

@Component({
  selector: 'app-portfolio-event-graph',
  templateUrl: './portfolio-event-graph.component.html',
  styleUrls: ['./portfolio-event-graph.component.css']
})
export class PortfolioEventGraphComponent {  

  @Input('eventTimeDayHour') set setEventTimeDayHour(value: any[]) {
    if (value != null) {
      this.eventTimeDayHour = value;
      this.eventTimeDayHour.push({ size: 0.0000001, x: 26, y: 9 });
      this.eventTimeDayHour.push({ size: 0.0000001, x: -2, y: 9 });
    }  
  }

  @Input('eventTimeDayHourLoading') set setEventDayHourMetricsLoading(value: boolean) {
    this.eventTimeDayHourLoading = value;
  }

  @Input('selectedBubble') set setSelectedBubble(value: EventDayHourMetric) {    
      this.selectedBubble = value;
  }

  @Output() bubbleSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  public eventTimeDayHour: any[] = [];
  public eventTimeDayHourLoading: boolean = false;
  public selectedBubble: any = null;
  public paddingValue: string = ".5px";  

  labelY(e) { 
    switch (e.value) {
      case 1:
        return 'Mon'
      case 2:
        return 'Tue'
      case 3:
        return 'Wed'
      case 4:
        return 'Thu'
      case 5:
        return 'Fri'
      case 6:
        return 'Sat'
      case 7:
        return 'Sun'
      default:
        return ''
    }
  }

  labelX(e) {
    if (e.value === -1 || e.value === 24) {
      return ''
    } else {
      return e.value + 'h'
    }
  }  

  onSeriesClick(e: any) {
    if (this.selectedBubble !== null && e.dataItem.id === this.selectedBubble.id) {
      this.changeBubbleColor(this.selectedBubble.id, '#42AAA3');
      this.bubbleSelected.emit(null);
      return;
    }    
    this.bubbleSelected.emit(e.dataItem);
    this.changeBubbleColor(e.dataItem.id, '#93BF56');
  }

  changeBubbleColor(id: number, color: string) {  
    for (let i = 0; i < this.eventTimeDayHour.length; i++) {      
      if (this.eventTimeDayHour[i].id === id) {
        this.eventTimeDayHour[i].color = color;
      } else {
        this.eventTimeDayHour[i].color = '#42AAA3';
      }
    }

    if (this.paddingValue === ".5px") {
      this.paddingValue="0px";
    } else {
      this.paddingValue=".5px";
    }
  }
 
}
