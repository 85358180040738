import { createAction, props } from '@ngrx/store';
import { Auth0Result } from '../auth0-result';

export const noAction = createAction('[Auth] No Action');

export const login = createAction('[Auth] Login');

export const loginSuccess = createAction(
  '[Auth] Login Success', 
  props<{ authResult: Auth0Result }>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: any }>()
);

export const createSession = createAction(
  '[Auth] Create Session',
  props<{ authResult: Auth0Result }>()
);

export const createSessionFailure = createAction(
  '[Auth] Create Session Failure',
  props<{ error: any }>()
);

export const sessionCreated = createAction('[Auth] Session Created');

export const logout = createAction('[Auth] Logout');

export const checkTokenExpiration = createAction(
  '[Auth] Check Token Expiration'
);

export const refreshToken = createAction('[Auth] Refresh Token');

export const updateAuthCookie = createAction(
  '[Auth] Update Auth Cookie',
  props<{ accessToken: string }>()
);

export const updateAuthCookieFailure = createAction(
  '[Auth] Update Auth Cookie Failure',
  props<{ error: any }>()
);