import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";

import * as MotionActions from "./actions";
import { NotificationsService } from "app/notifications/notifications.service";
import { AppState } from "app/app.state";
import { Store, select } from "@ngrx/store";
import { concatMap, withLatestFrom, map, switchMap, catchError, tap } from "rxjs/operators";
import { of } from "rxjs";
import { isCameraSelected, numCamsSelected, selectedCameras, selectedTime, motionSiteID, markerForCameraID } from "./selectors";
import { MotionService } from "../motion.service";
import { MotionRequest } from "../motion-request";
import { CloudService } from "app/cloud/services/cloud.service";
import { setPrimaryCamera } from "app/camera/rewind/add-cams/store/actions";

@Injectable()
export class MotionEffects {
    clickCamera$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MotionActions.clickCamera),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(isCameraSelected(action.marker.id)))),
                    withLatestFrom(this.store.pipe(select(numCamsSelected))),
                    withLatestFrom(this.store.select(s => s.site).pipe(map(s => s.autoRequestAdditionalCameras))),
                )
            }),
            map(([[[action, isSelected], numCamsSelected], numAddCams]) => {
                if(isSelected) {
                    return MotionActions.removeCamera({marker: action.marker});
                }
                if(numCamsSelected < (numAddCams + 1)) {
                    return MotionActions.addCamera({marker: action.marker});
                }
                return MotionActions.maxCameraModalOpen();
                //this.notificationsService.error("", `You have already selected the max of ${numAddCams + 1} cameras.`);
                //return MotionActions.tooManyCamsClicked();
            })
        )
    });
    submitRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MotionActions.submitRequest),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectedCameras))),
                    withLatestFrom(this.store.pipe(select(selectedTime))),
                )
            }),
            map(([[action, selectedCameras], selectedTime]) => {
                let req = new MotionRequest(selectedTime.motionStartDate, selectedTime.motionEndDate, selectedCameras.map(c => c.camera))
                return MotionActions.loadMotion({request: req});
            })
        )
    })

    loadMotion$ = createEffect(() => 
        this.actions$.pipe(
            ofType(MotionActions.loadMotion),
            tap( () => {
                this.store.dispatch(MotionActions.motionLoading());            
            }),
            switchMap((action) => 
                this.motionService.getMotion(action.request).pipe(
                    map(data => MotionActions.motionLoaded({motionData: data, request: action.request})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading motion")
                        return of(MotionActions.motionFailed())
                    }),
                ),
            ),
        ),
    );

    clickMotion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MotionActions.motionClick),
            switchMap((action) => {
                return this.cloudService.fetch(action.file.cameraID, action.file.time).pipe(
                    map(cloudFiles => MotionActions.cloudLoaded({cloudFiles: cloudFiles, cloudTime: action.file.time, cloudCamera: action.file.cameraID})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading video for motion");
                        return of(MotionActions.cloudFailed());
                    })
                )
            }),
        )
    });

    loadMotionForSite$ = createEffect( () => {
        return this.actions$.pipe(
            ofType(MotionActions.loadingMotionForSite),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(motionSiteID))),
                )
            }),
            map(([action, siteID]) => {
                console.log(action, siteID)
                if(action.siteID !== siteID) {
                    this.store.dispatch(MotionActions.resetMotion());
                }
                return MotionActions.setMotionSiteID({siteID: action.siteID});
            })
        )
    });
    
    loadMotionForCameraID$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MotionActions.loadMotionForCameraID),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(markerForCameraID(action.cameraID)))),
                )
            }),
            map(([action, marker]) => {
                console.log(marker);
                if(marker !== null) {
                    this.store.dispatch(MotionActions.resetCameras());
                    this.store.dispatch(MotionActions.addCamera({marker: marker}));    
                }
                return MotionActions.submitRequest();
            }),
        )
    })


    constructor(
        private actions$: Actions,
        private notificationsService: NotificationsService,
        private store: Store<AppState>,
        private motionService: MotionService,
        private cloudService: CloudService,
    ) {}
}