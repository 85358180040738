
import {first, map, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../users/user.service';
import { SiteService } from '../site/site.service';

@Injectable()
export class MessageSiteGuard  {
    constructor(public userService: UserService, public siteService: SiteService){}
    canActivate() {
        return this.userService.getUser().pipe(filter(user => !user.initial),map(user => {
            if(user.sites.length !== 1) {
                return true;
            }
            this.siteService.fetchSite(user.sites[0].siteID).subscribe();
            return true;
            
        }),first(),)
    }
}
