// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cvsView {
    height: 100%;
    width: 100%;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

kendo-tilelayout { 
    height: 100%;
    width: 100%;
}

kendo-tilelayout-item-body {
    width: 100%;
    height: 100%;
}

camera-view {
    width: 100%;
    height: 100%;
}

app-hybrid-player {
    width: 100%;
    height: 100%;
}

.k-card-body {
    padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/camera/camera-grid/camera-grid.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".cvsView {\r\n    height: 100%;\r\n    width: 100%;\r\n}\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n\r\nkendo-tilelayout { \r\n    height: 100%;\r\n    width: 100%;\r\n}\r\n\r\nkendo-tilelayout-item-body {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\ncamera-view {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\napp-hybrid-player {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.k-card-body {\r\n    padding: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
