import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AdminService } from "../../admin.service";
import * as IotLprActions from "./actions";
import { NotificationsService } from "../../../notifications/notifications.service";
import { AppState } from "../../../app.state";
import * as fromIotLpr from "./selectors";

@Injectable()
export class IotLprEffects {
    searchIotLpr$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IotLprActions.searchIotLpr),
            concatLatestFrom(() => [
                this.store.select(fromIotLpr.selectSelectedSites),
            ]),
            switchMap(([action, selectedSites ]) => {

                // code for using lpr-search-api to perform query

                // let searchParams = {...action.searchParams};
                // let ids: number[] = [];
                // if (selectedSites.length > 0) {
                //     ids = selectedSites.map(site => site.id);
                //     // siteQuery =`siteID:(${ids})`;
                // }

                // if (ids.length > 0) {
                //     searchParams.siteIDs = [...ids];
                // } else {
                //     searchParams.siteIDs = [];
                // }


                // code for appending siteID to the query string

                let siteQuery = "";

                if (selectedSites.length > 0) {
                    let ids = selectedSites.map(site => site.id).join(' OR ');
                    siteQuery =`siteID:(${ids})`;
                }

                let searchParams = {...action.searchParams};

                if (searchParams.query.trim().length === 0 && selectedSites.length === 0) {
                    searchParams.query = "siteID:*";
                } else if (searchParams.query.trim().length !== 0 && selectedSites.length !== 0) {
                    let newQuery = searchParams.query.concat(" AND ", siteQuery);
                    searchParams.query = newQuery;
                } else if (searchParams.query.trim().length === 0 && selectedSites.length !== 0) {
                    searchParams.query = siteQuery;
                } else {
                    searchParams.query = searchParams.query;
                }               
        
                return this.adminService.searchIotLpr(searchParams).pipe(
                    map(response => IotLprActions.searchIotLprSuccess({
                        results: response.results,
                        totalCount: response.totalCount
                    })),
                    catchError(error => {
                        this.notificationsService.error("", "Search Error")
                        return of(IotLprActions.searchIotLprFailure({ error: error.message }))
                    })
                )
            })
        )
    );

    loadImages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IotLprActions.searchIotLprSuccess),
            mergeMap(({ results }) =>
                results.map(result => IotLprActions.loadImage({ id: result.id, cropped: false }))
            )
        )
    );

    loadImage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IotLprActions.loadImage),
            mergeMap(({ id, cropped }) =>
                this.adminService.getIotLprImage(id, cropped).pipe(
                    map(blob => {
                        const unsafeImageUrl = URL.createObjectURL(blob);
                        const safeImageUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
                        return IotLprActions.loadImageSuccess({ id, imageUrl: safeImageUrl });
                    }),
                    catchError(error => of(IotLprActions.loadImageFailure({ id, error: error.message })))
                )
            )
        )
    );

    fetchSiteTimezones$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IotLprActions.searchIotLprSuccess),
            map(({ results }) => [...new Set(results.map(result => result.siteID))]),
            withLatestFrom(this.store.select(fromIotLpr.selectSiteTimezones)),
            map(([siteIDs, existingTimezones]) =>
                siteIDs.filter(id => !existingTimezones[id])
            ),
            filter(newSiteIDs => newSiteIDs.length > 0),
            switchMap((siteIDs) =>
                this.adminService.fetchSiteTimezones(siteIDs).pipe(
                    map(timezones => IotLprActions.fetchSiteTimezonesSuccess({ timezones })),
                    catchError(error => of(IotLprActions.fetchSiteTimezonesFailure({ error: error.message })))
                )
            )
        )
    );

    fetchSites$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IotLprActions.fetchSites),
            switchMap((action) => { 
                return this.adminService.fetchSites().pipe(
                    map(data => IotLprActions.fetchSitesSuccess({sites: data})),
                    catchError((e) => {
                        this.notificationsService.error("", "Error loading site list")
                        return of(IotLprActions.fetchSitesFailure({error: e.Message}));
                    }),
                )
            })
        )
    })

    constructor(
        private store: Store<AppState>,
        private actions$: Actions,
        private notificationsService: NotificationsService,
        private adminService: AdminService,
        private sanitizer: DomSanitizer,
    ) { }
}
