import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, take } from "rxjs/operators";
import { config } from "../../app.config";
import { ServiceHelpersService } from "app/service-helpers.service";
import Feature from "../models/feature";
import SitePackage from "../models/site-package";
import { SiteService } from "app/site/site.service";
import { FeatureType } from "../models";
import { PermissionsService } from '../../permissions.service';


@Injectable()
export class FeatureService {
  constructor(
    private http: HttpClient,
    private serviceHelpersService: ServiceHelpersService,
    private siteService: SiteService,
    private permissions: PermissionsService,
  ) {}

  public fetch(): Observable<Feature[]> {
    const url = `${config.apiUrl}/site/package/features`;
    return this.http.get<Feature[]>(url).pipe(catchError(this.serviceHelpersService.handleError));
  }

  public checkFeatureInPackage(featureID: number, sitePackage?: SitePackage): boolean {
    if (!sitePackage) {
      this.siteService
        .getSite()
        .pipe(take(1))
        .subscribe((site) => {
          sitePackage = site.package;
        });
    }

    if (this.permissions.admin('isAdmin') && featureID === FeatureType.Motion) {
      return true;
    }

    if (!sitePackage.features) {
      return false;
    }

    const featureIDs = sitePackage.features.map((feature) => feature.featureID);
    return featureIDs.indexOf(featureID) > -1;
  }
  public hasRequestFeature(sitePackage?: SitePackage): boolean {
    if (!sitePackage) {
      this.siteService.getSite().pipe(take(1)).subscribe((site) => sitePackage = site.package);
    }
    return this.checkFeatureInPackage(FeatureType.AutoRequest, sitePackage) || this.checkFeatureInPackage(FeatureType.FullRequest);
  }
}
