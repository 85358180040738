import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { SiteService } from "app/site/site.service";
import { Site } from "app/site/site";
import { map } from "rxjs/operators";
import { FeatureService } from "./services";

@Injectable()
export class FeatureGuard  {
  constructor(private siteService: SiteService, private featureService: FeatureService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.siteService.getSite().pipe(
      map((site: Site) => {
        const hasFeature = this.featureService.checkFeatureInPackage(route.data.feature, site.package);
        if (!hasFeature) {
          this.router.navigate(["dashboard"]);
        }

        return hasFeature;
      }),
    );
  }
}
