import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectableSettings, SelectableMode} from "@progress/kendo-angular-grid";
import { GridDataResult, DataStateChangeEvent } from "@progress/kendo-angular-grid";
import { State as GridState } from '@progress/kendo-data-query';
import { ByosBackfillSite } from '../byos-backfill-site';

@Component({
  selector: 'app-admin-table',
  templateUrl: './admin-table.component.html',
  styleUrls: ['./admin-table.component.css']
})
export class AdminTableComponent {

  @Input('byosGridView') set setByosGridView(value: GridDataResult) {
    if (value) {
      this.gridView = value;
    }
  }

  @Input('gridState') set setGridState(value: GridState) {
    if (value) {
      this.gridState = value;
    }
  }

  @Output() gridStateChange: EventEmitter<GridState> = new EventEmitter<GridState>();
  @Output() backfillButtonClick: EventEmitter<ByosBackfillSite> = new EventEmitter<ByosBackfillSite>();
  
  public gridView: GridDataResult;
  public gridState: GridState;  
  
  constructor() {}
  
  ngOnInit(): void {}

  onGridStateChange(state: DataStateChangeEvent) {
    this.gridStateChange.emit(state);
  }

  onBackfillButtonClick(site: ByosBackfillSite) {
    this.backfillButtonClick.emit(site);
  }

  onCancelButtonClick(site: ByosBackfillSite) {
    console.log('Backfill canceled for site: ', site);
  }

}
