import { Component, Input, ViewChild, OnInit, OnDestroy, Output, EventEmitter, AfterViewInit } from '@angular/core';

import { Video } from '../../video';
import { KeyboardService } from '../../../keyboard.service';
import { FeatureType } from 'app/site-package/models';
import { fromEvent } from 'rxjs';
import { takeWhile } from 'rxjs/operators';


@Component({
    selector: "app-rewind-player",
    templateUrl: "./rewind-player.component.html",
    styleUrls: ["rewind-player.component.css"],
})
export class RewindPlayerComponent implements OnInit, OnDestroy, AfterViewInit{
    @Input("video") video: Video
    @Input("requestPermission") requestPermission: boolean;
    @Input("rawFeature") rawFeature: boolean;
    @Input("rawRequestPermission") rawRequestPermission: boolean;
    @Input("cameraName") cameraName: string;
    @ViewChild("videoPlayer") videoPlayer;
    @ViewChild("container", {static: true}) container;
    @ViewChild("canvas", {static: true} ) canvas;
    @ViewChild("SSButton") ssButton;
    @ViewChild("playerContainer", {static: false}) set setPlayerContainer(value) {
        if(value) {
            this.resizeObserver = new ResizeObserver(() => { 
                this.resize();
            });
            this.resizeObserver.observe(value.nativeElement);        
        }
    };
    @Output("request") request = new EventEmitter<number>();
    @Output("done") done = new EventEmitter();
    @Output("otherRequest") otherRequest = new EventEmitter<any>();
    @Output("rawRequest") rawRequestEmitter = new EventEmitter<any>();
    public keySub;
    public speeds = [.5,1,1.25,1.5, 2, 4, 8, 16];
    public currentSpeedIndex = 1;
    public downloadFileName = "";
    public top = "0px";
    public right = "0px";
    public alive = true;
    public fullRequest: FeatureType[] = [
        FeatureType.FullRequest,
    ]
    public autoRequest: FeatureType[] = [
        FeatureType.AutoRequest,
    ]
    private resizeObserver: ResizeObserver;
    constructor(public keyboardService: KeyboardService) {}

    ngOnInit() {
        this.keySub = this.keyboardService.getKeys().subscribe(event => {
            if(event.shiftKey) {
                if(event.keyCode === 37) {
                    this.decreaseSpeed()
                }
                if(event.keyCode === 39) {
                    this.increaseSpeed()
                }
            }
        })
    }
    ngOnDestroy() {
        if(this.keySub) {
            this.keySub.unsubscribe();
        }
        this.alive = false;
        if(this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }
    ngAfterViewInit() {
        this.container.nativeElement.addEventListener('ended', (e) => {
            this.done.emit();
        }, true)
    }
    done2() {
        console.log("InDone")
    }
    load() {
        if(this.videoPlayer) {
            this.videoPlayer.nativeElement.load();
        }
    }
    play() {
        if(this.videoPlayer) {
            this.videoPlayer.nativeElement.play();
            this.videoPlayer.nativeElement.playbackRate = this.speeds[this.currentSpeedIndex];
        }
    }
    increaseSpeed() {
        if(this.currentSpeedIndex < this.speeds.length -1) {
            this.currentSpeedIndex++;
        }
        console.log(this.speeds[this.currentSpeedIndex])
        this.videoPlayer.nativeElement.playbackRate = this.speeds[this.currentSpeedIndex];
    }
    decreaseSpeed() {
        if(this.currentSpeedIndex > 0) {
            this.currentSpeedIndex--;
        }
        console.log(this.speeds[this.currentSpeedIndex])
        this.videoPlayer.nativeElement.playbackRate = this.speeds[this.currentSpeedIndex];
    }
    doRequest() {
        this.videoPlayer.nativeElement.pause();
        this.request.emit(this.videoPlayer.nativeElement.currentTime);
    }
    doOtherRequest() {
        this.otherRequest.emit();
    }
    rawRequest() {
        this.rawRequestEmitter.emit();
    }
    snapShot() {
        this.videoPlayer.nativeElement.pause();
        let canvas = this.canvas.nativeElement
        let ctx = canvas.getContext('2d');
        let p = this.videoPlayer.nativeElement;
        canvas.width = p.videoWidth;
        canvas.height = p.videoHeight;
        ctx.drawImage(p, 0, 0, canvas.width, canvas.height);
        let btn = this.ssButton.nativeElement;
        let image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        btn.setAttribute("href", image);

        let snapDate = new Date(this.video.startTime)
        snapDate.setSeconds(snapDate.getSeconds() + Math.round(p.currentTime));

        if (this.cameraName === "") {
            this.cameraName = "image";
        }
            
        let snapDateStr = snapDate.getUTCFullYear() + "-" + this.zeroFill((snapDate.getUTCMonth()+1)) + "-" + this.zeroFill(snapDate.getUTCDate());
        let snapTimeStr = this.zeroFill(snapDate.getUTCHours()) + "-" + this.zeroFill(snapDate.getUTCMinutes()) + "-" + this.zeroFill(snapDate.getUTCSeconds());
        this.downloadFileName = this.cameraName + "_" + snapDateStr + "_" + snapTimeStr + ".png";
    }

    zeroFill(i) {
        return (i < 10 ? '0' : '') + i
      }
    resize() {
        let videoElement = this.videoPlayer.nativeElement;
        let vwidth = videoElement.videoWidth;
        let vheight = videoElement.videoHeight;
        let cwidth = videoElement.clientWidth;
        let cheight = videoElement.clientHeight;
        let vidRatio = vwidth / vheight;
        let dispRatio = cwidth / cheight;
        this.top = "0px";
        this.right = "0px";
        if(vidRatio > dispRatio) {
            this.top = Math.ceil((cheight - (cwidth / vwidth * vheight)) / 2) + 2 + "px";
        } else {
            this.right = Math.ceil((cwidth - (cheight / vheight * vwidth)) / 2) + 2 + "px";
        }
    }
}
