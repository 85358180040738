import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Observable, of, Subscription } from 'rxjs';
import { UserActivity } from './user-activity';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {

  constructor(public http: HttpClient) { }
  startTime: Date;
  intervalSub: Subscription;
  currentActivity: UserActivity;

  logActivity(activity: UserActivity) {
    console.log(activity);
    this.createActivity(activity).subscribe(()=>{})
  }


  startVideoTracking(activity: UserActivity) {
    this.endVideoTracking();
    this.startTime = new Date();

    this.createActivity(activity).subscribe(act => {
      this.currentActivity = act;
      this.intervalSub = interval(5000).subscribe(() => this.reportVideoTracking())
    }, err => {
      this.startTime = null;
    })


  }
  endVideoTracking() {
    this.reportVideoTracking();
    if(this.intervalSub) {
      this.intervalSub.unsubscribe();
      this.intervalSub = null;
    }
    this.startTime = null;
    this.currentActivity = null;
  }
  reportVideoTracking() {
    if(!this.startTime || !this.currentActivity) {
      return;
    }
    let watched = (new Date().getTime() - this.startTime.getTime()) / 1000;
    if(!this.currentActivity.meta) {
      this.currentActivity.meta = {};
    }
    this.currentActivity.meta["watched"] = watched;
    this.updateActivity(this.currentActivity).subscribe(() =>{});

  }

  createActivity(activity: UserActivity): Observable<UserActivity> {
    return this.http.post<UserActivity>(`/rest/site/${activity.siteID}/useractivity`, activity)
  }
  updateActivity(activity: UserActivity): Observable<UserActivity> {
    return this.http.put<UserActivity>(`/rest/site/${activity.siteID}/useractivity/${activity.id}`, activity)
  }
}
