import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from "./state";
import * as AddCamsActions from './actions';



const reducer = createReducer(
    initialState,
    on(AddCamsActions.addCamera, (state: State, { marker }) => {
        return { ...state, selectedAdditionalCameras: [...state.selectedAdditionalCameras, marker] };
    }),
    on(AddCamsActions.removeCamera, (state: State, { marker }) => {
        return { ...state, selectedAdditionalCameras: [...state.selectedAdditionalCameras.filter(m => m.id !== marker.id)]};
    }),
    on(AddCamsActions.resetCameras, (state: State) => {
        return { ...state, selectedAdditionalCameras: [] };
    }),
    on(AddCamsActions.setPrimaryCamera, (state: State, { marker }) => {
        return { ...state, primaryCamera: marker };
    }),
    on(AddCamsActions.resetPrimaryCamera, (state: State) => {
        return { ...state, primaryCamera: null };
    }),
    on(AddCamsActions.addCameraData, (state: State, { camera }) => {
        let obj = {};
        obj[camera.id] = camera;
        return { ...state, cameras: {...state.cameras, ...obj}};
    }),
    on(AddCamsActions.maxCameraModalOpen, (state: State) => {
        return { ...state, maxCameraModalVisible: true};
    }),
    on(AddCamsActions.maxCameraModalClose, (state: State) => {
         return { ...state, maxCameraModalVisible: false};
    })
)



export function AddCamsReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}