// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background: #28303d;
    min-height: 100vh;
    height: 100%;
}

.container {
    padding-top: 100px;
    padding-bottom: 20px;
}

.center {
    text-align: center; 
}

.image {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.button {
    margin: 3px;
}

.download-app {
    height: 50px;
    display: inline-block;
    margin: 30px 5px 5px;
}

.copyright {
    color: #fff;
    text-align: center;

}

.footer-links {
    margin-left: 15px;
}

a{
    color: #306085;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/login/login.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,kBAAkB;;AAEtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":["body {\r\n    background: #28303d;\r\n    min-height: 100vh;\r\n    height: 100%;\r\n}\r\n\r\n.container {\r\n    padding-top: 100px;\r\n    padding-bottom: 20px;\r\n}\r\n\r\n.center {\r\n    text-align: center; \r\n}\r\n\r\n.image {\r\n    display: block;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n}\r\n\r\n.button {\r\n    margin: 3px;\r\n}\r\n\r\n.download-app {\r\n    height: 50px;\r\n    display: inline-block;\r\n    margin: 30px 5px 5px;\r\n}\r\n\r\n.copyright {\r\n    color: #fff;\r\n    text-align: center;\r\n\r\n}\r\n\r\n.footer-links {\r\n    margin-left: 15px;\r\n}\r\n\r\na{\r\n    color: #306085;\r\n    text-decoration: none;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
