import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

import { User } from 'app/users/user';
import { UserSoftType } from 'app/constants';

@Component({
    selector: 'app-user-management-grid',
    templateUrl: './user-management-grid.component.html',
    styleUrls: ['./user-management-grid.component.css']
})
export class UserManagementGridComponent implements OnInit, OnDestroy {
    @Input() currentUserID: number;
    @Input() isLoading: boolean;
    @Input() set users(users: User[]) {
        this._users = users;
        this.loadGridData();
    }

    @Output() userRemove = new EventEmitter<User>();
    @Output() updateLogShow = new EventEmitter<User>();

    public state: State = {};
    public gridData: GridDataResult;

    public UserSoftType = UserSoftType;

    private _users: User[] = [];

    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    public removeUser(user: User): void {
        this.userRemove.emit(user);
    }

    public showUpdateLog(user: User): void {
        this.updateLogShow.emit(user);
    }

    public loadGridData(): void {
        this.gridData = process(this._users, this.state);
    }

    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.gridData = process(this._users, this.state);
    }
}
