import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromIotLprState from "./state";

export const selectIotLprState = createFeatureSelector<fromIotLprState.State>(fromIotLprState.iotLprKey);

export const selectResults = createSelector(
    selectIotLprState,
    (state) => state.results,
);

export const selectTotalCount = createSelector(
    selectIotLprState,
    (state) => state.totalCount,
);

export const selectLoading = createSelector(
    selectIotLprState,
    (state) => state.loading,
);

export const selectError = createSelector(
    selectIotLprState,
    (state) => state.error,
);

export const selectSiteTimezones = createSelector(
    selectIotLprState,
    (state) => state.siteTimezones,
);

export const selectSites = createSelector(
    selectIotLprState,
    (state) => state.sites,
);

export const selectSelectedSites = createSelector(
    selectIotLprState,
    (state) => state.selectedSites,
);
