import { Site } from '../site/site';
import { User } from '../users/user';
import { EventNote } from './event-note';
import { EventTime } from './event-time';
import { Request } from './request';
import { Summary } from './summary';

export class WtsEvent {
    id: number;
    site: Site;
    emails: string;
    starred: boolean;
    description: string;
    displayName: string;
    reviewers: User[];
    notes: EventNote[];
    users: User[];
    status: EventTime[];
    requests: Request[];
    summaries: Summary[];
    images: string[];
    constructor(event) {
        Object.assign(this, event);
        if(this.requests) {
            this.requests = this.requests.map(request => new Request(request));
        }
        if(event.summaries) {
            this.summaries = event.summaries.map(s => new Summary(s));
        }
    }
}

