import { Component, OnInit, EventEmitter, OnDestroy, Input, Output } from '@angular/core';
import { MotionSelectorService } from './motion-selector.service';
import { Map } from 'app/map/map';
import { Observable } from 'rxjs';
import { MotionCameraMarker } from './motion-camera-marker';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { removeCamera, submitRequest, setSelectedTime, toggleMotionSelectorCollapse, setCameraIDToName, selectSelectionMap, setSelectionMaps } from '../store/actions';
import { selectedCameras, mapsWithCamsSelected, selectedStartTimeAsDate, selectedHours, selectedEndTimeAsDate, selectedTime, motionLoading, sameRequest, selectorCollapsed, selectionMaps, currentSelectionMapID } from '../store/selectors';
import { MotionDateTime } from '../motion-datetime-modal/motion-datetime';
import { state, style, trigger, transition, animate, sequence } from '@angular/animations';
import { CameraMarker } from 'app/camera/camera-marker';
import { take, takeWhile } from 'rxjs/operators';



@Component({
  selector: 'app-motion-selector',
  templateUrl: './motion-selector.component.html',
  styleUrls: ['./motion-selector.component.css'],
  // animations: [
  //   trigger('expandCollapse', [
  //     state('expanded', style({
  //       maxWidth: '640px',
  //       display: 'block',
  //     })),
  //     state('collapsed', style({
  //       maxWidth: '0px',
  //       display: 'none',
  //     })),
  //     transition('expanded => collapsed', [
  //       sequence([
  //         animate('.3s', style({
  //           maxWidth: '0px',
  //         })),
  //         style({
  //           display: 'none',
  //         }),
  //       ])
  //     ]),
  //     transition('collapsed => expanded', [
  //       sequence([
  //         style({
  //           display: 'block',
  //         }),
  //         animate('.3s', style({
  //           maxWidth: "640px",
  //         })),
  //       ])
  //     ]),
  //   ]),
  // ]
})
export class MotionSelectorComponent implements OnInit, OnDestroy {
  @Input() hasRequestPermission: boolean;
  @Input() hasRawRequestFeature: boolean;
  @Input() hasRawRequestPermission: boolean;
  @Input() rawRequestHours: number;

  @Output() mapToggle: EventEmitter<boolean> = new EventEmitter();
  @Output("rawRequest") rawRequestEmitter: EventEmitter<any> = new EventEmitter();

  selectedCameras$: Observable<MotionCameraMarker[]>;
  mapsWithCamIDs$: Observable<number[]>;
  selectedStartDate$: Observable<Date>;
  selectedEndDate$: Observable<Date>;
  selectedHours$: Observable<number>;
  selectedTime$: Observable<MotionDateTime>;
  motionLoading$: Observable<boolean>;
  sameRequest$: Observable<boolean>;
  selectorCollapsed$: Observable<boolean>;
  maps$: Observable<Map[]>;
  currentMapID$: Observable<number>;  

  currentMapID: number;
  showDateTime: boolean = false;
  alive: boolean = true;
  cameraMarkers: MotionCameraMarker[];
  toggle: boolean = false;

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {

    this.selectedCameras$ = this.store.pipe(select(selectedCameras));

    this.mapsWithCamIDs$ = this.store.pipe(select(mapsWithCamsSelected));

    this.selectedStartDate$ = this.store.pipe(select(selectedStartTimeAsDate));

    this.selectedEndDate$ = this.store.pipe(select(selectedEndTimeAsDate));

    this.selectedHours$ = this.store.pipe(select(selectedHours));

    this.selectedTime$ = this.store.pipe(select(selectedTime));

    this.motionLoading$ = this.store.pipe(select(motionLoading));

    this.sameRequest$ = this.store.pipe(select(sameRequest));

    this.selectorCollapsed$ = this.store.pipe(select(selectorCollapsed));

    this.maps$ = this.store.pipe(select(selectionMaps));

    this.currentMapID$ = this.store.pipe(select(currentSelectionMapID));

    this.selectedCameras$.pipe(takeWhile(() => this.alive)).subscribe(camMarkers => {
      this.cameraMarkers = camMarkers;
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  removeCamera(marker: MotionCameraMarker) {
    this.store.dispatch(removeCamera({ marker: marker }));
    if (marker.map.id === this.currentMapID) {
      marker.map.draw();
    }
  }
  selectMap(map: Map) {
    this.currentMapID = map.id;
  }
  changeTime() {
    this.showDateTime = true;
  }
  submit() {
    this.store.dispatch(submitRequest());
  }
  dateTimeCancel() {
    this.showDateTime = false;
  }
  dateTimeSave(time: MotionDateTime) {
    this.store.dispatch(setSelectedTime({ time: time }));
    this.showDateTime = false;
  }
  // onClickCollapse() {
  //     this.store.dispatch(toggleMotionSelectorCollapse());
  // this.mapToggle.emit(!this.toggle)
  // }
  // onClickNotCollapse(event: Event) {
  //   event.stopPropagation();
  //   event.preventDefault();
  // }
  resetCameras() {
    this.cameraMarkers.forEach(marker => this.removeCamera(marker));
  }
  rawRequest() {
    this.rawRequestEmitter.emit();
  }
}
