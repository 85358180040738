import * as fromAdminState from './state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectAdminState = createFeatureSelector<fromAdminState.State>(fromAdminState.adminKey);

export const sites = createSelector(
    selectAdminState,
    (admin => admin.sites),
);

export const sitesLoading = createSelector(
    selectAdminState,
    (admin => admin.sitesLoading),
);

export const sitesLoaded = createSelector(
    selectAdminState,
    (admin => admin.sitesLoaded),
);