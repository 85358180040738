import { RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { RawRequestService } from "./raw-request.service";
import { tap, map } from "rxjs/operators";
import { setMaps, clickCamera, loadingRawForSite, resetRaw } from "./store/actions";
import { CameraMarker } from "app/camera/camera-marker";
import { Camera } from "app/camera/camera";
import { CameraService } from "app/camera/camera.service";
import { Store } from "@ngrx/store";
import { AppState } from "app/app.state";
import { RawRequestCameraMarker } from "./raw-request-camera-marker";

@Injectable({
    providedIn: 'root'
  })
  export class RawLoaderGuard  {
    constructor(private rawRequestService: RawRequestService, private cameraService: CameraService, private store: Store<AppState>) {}
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.rawRequestService.getMaps().pipe(tap(maps => { 
        let params = route.params;
        let siteID = +params["siteID"];
        this.store.dispatch(loadingRawForSite({siteID: siteID}));
  
        this.store.dispatch(setMaps({maps: maps}))
        let queryParams = route.queryParams
        outer_block: {
          if(queryParams["cameraID"]) {
            this.store.dispatch(resetRaw());
            let cameraID = +queryParams["cameraID"];
            for(let map of maps) {
              for(let marker of map.markersLocal) {
                if(marker.type === "camera") {
                  let m = marker as CameraMarker
                  if(m.camera === cameraID) {
                    let reqM = m as RawRequestCameraMarker
                    this.store.dispatch(clickCamera({marker: reqM}))
                    break outer_block;
                  }
                }
              }
            }
          }  
        }
      }), map(maps => true));
  
    }
  }
  