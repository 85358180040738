import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Logger } from '../logger/logger.service';
import { AccessKeyService } from '../users/access-key.service';
import { ServiceHelpersService } from '../service-helpers.service';
import { config } from '../app.config';
import { Lookup } from '../lookup';
import { UserSiteTypeLogin } from './admin-dashboard/user-site-type-login';
import { EOMetrics, RecentUser, RequestMetrics, ActivityMetrics, ChartData, SitesNoLogins } from './admin-dashboard/admin-dashboard.component';
import { catchError, map, take } from 'rxjs/operators';
import { MessageRequest } from './admin-messages/message-request';
import { Message } from 'app/messages/message';
import { UserInfo } from './admin-permissions/user-info';
import { UserSearch } from './admin-user-selector/user-search';
import { Updates } from './admin-permissions/updates';
import { User } from '../users/user';
import { SiteAccessPolicy } from 'app/site/site-access-policy';
import { IotLpr, IotLprSearchParams } from './iot-lpr/iot-lpr';
import { AdminSiteListItem } from './adminSiteListItem';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { select } from '@ngrx/store';
import * as selectors from './store/selectors';
import * as actions from './store/actions';
import { SiteTimezone } from '../site/site-timezone';

@Injectable()
export class AdminService {
    public headers = {};
    constructor(public http: HttpClient, public logger: Logger, public accessKeyService: AccessKeyService, public serviceHelpers: ServiceHelpersService, private store: Store<AppState>) {
        this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key}));
    }

    // Dashboard
    getEOMetrics(q?: MetricsQuery): Observable<EOMetrics[]> {
        let params = this.getMetricsParams(q);
        let headers = new HttpHeaders(this.headers);
        return this.http.get<EOMetrics[]>(config.apiUrl + "admin/metrics/eo", {headers: headers, params: params}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getNewestUsersMetrics(q?: MetricsQuery): Observable<RecentUser[]> {
        let params = this.getMetricsParams(q);
        let headers = new HttpHeaders(this.headers);
        return this.http.get<RecentUser[]>(config.apiUrl + "admin/metrics/users/newest", {headers: headers, params: params}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getSitesRequestsMetrics(q?: MetricsQuery): Observable<RequestMetrics[]> {
        let params = this.getMetricsParams(q);
        let headers = new HttpHeaders(this.headers);
        return this.http.get<RequestMetrics[]>(config.apiUrl + "admin/metrics/sites/byrequest", {headers: headers, params: params}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getSitesLoginMetrics(q?: MetricsQuery): Observable<ActivityMetrics[]> {
        let params = this.getMetricsParams(q);
        let headers = new HttpHeaders(this.headers);
        return this.http.get<ActivityMetrics[]>(config.apiUrl + "admin/metrics/sites/byloginamount", {headers: headers, params: params}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getUserLoginMetrics(q?: MetricsQuery): Observable<ChartData[]> {
        let params = this.getMetricsParams(q);
        let headers = new HttpHeaders(this.headers);
        return this.http.get<ChartData[]>(config.apiUrl + "admin/metrics/users/activity", {headers: headers, params: params}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getSitesNoLoginMetrics(q?: MetricsQuery): Observable<SitesNoLogins[]> {
        let params = this.getMetricsParams(q);
        let headers = new HttpHeaders(this.headers);
        return this.http.get<SitesNoLogins[]>(config.apiUrl + "admin/metrics/sites/noactivity", {headers:headers, params: params}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getUserSiteLoginsByType(q?: MetricsQuery): Observable<UserSiteTypeLogin[]> {
        let params = this.getMetricsParams(q);
        let headers = new HttpHeaders(this.headers);
        return this.http.get<UserSiteTypeLogin[]>(config.apiUrl + "admin/metrics/user/bytype", {headers: headers, params: params}).pipe(catchError(this.serviceHelpers.handleError), map(logins => {
            if(logins && logins.map) {
                return logins.map(l => new UserSiteTypeLogin(l));
            }
            return logins;
        }));
    }

    getSiteMetrics(siteID: number): Observable<any> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<any>(config.apiUrl + "admin/site/" + siteID + "/metrics", {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }
    createMessage(message: SentMessage): Observable<MessageRequest> {
        let headers = new HttpHeaders(Object.assign({}, this.headers, {"Content-Type": 'application/json'}));
        let body = JSON.stringify(message);
        return this.http.post<MessageRequest>(config.apiUrl + "admin/messages", body, {headers: headers}).pipe(catchError(this.serviceHelpers.handleError), map(m => new MessageRequest(m)));
    }
    getMessages(): Observable<MessageRequest[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<MessageRequest[]>(config.apiUrl + "admin/sent-messages", {headers: headers}).pipe(catchError(this.serviceHelpers.handleError), map(messages => {
            if(messages && messages.map) {
                return messages.map(m => new MessageRequest(m))
            }
            return messages;
        }));
    }
    deleteMessage(id: number) {
        let headers = new HttpHeaders(this.headers);
        return this.http.delete(config.apiUrl + "admin/messages/" + id, {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getRecipients(id: number): Observable<Message[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Message[]>(config.apiUrl + "admin/messages/" + id, {headers: headers}).pipe(catchError(this.serviceHelpers.handleError), map(messages => {
            if(messages && messages.map) {
                return messages.map(m => new Message(m));
            }
            return messages;
        }));
    }
    deleteUser(id: number) {
        let headers = new HttpHeaders(this.headers);
        return this.http.delete(config.apiUrl + "admin/user/" + id, {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }
    undeleteUser(id: number): Observable<User> {
        let headers = new HttpHeaders(this.headers);
        return this.http.put(config.apiUrl + "admin/user/" + id + "/undelete", {headers: headers}).pipe(catchError(this.serviceHelpers.handleError), map(user => new User(user)));
    }
    updateUserPassword(id: number, password) {
        let headers = new HttpHeaders(this.headers);
        let body = JSON.stringify(password);
        return this.http.put(config.apiUrl + "admin/user/" + id + "/password", body, {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getManagementCompanies(): Observable<Lookup[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Lookup[]>(config.apiUrl + "admin/managementcompanies", {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getMetricsParams(q?: MetricsQuery): HttpParams {
        let params = new HttpParams();
        if(q) {
            if(q.whenStart) {
                params = params.set('whenStart', formatDateFormAdminMetrics(q.whenStart));
            }
            if(q.whenEnd) {
                params = params.set('whenEnd', formatDateFormAdminMetrics(q.whenEnd));
            }
            if(q.salesRep) {
                params = params.set('salesRep', q.salesRep + '');
            }
        }
        return params;
    }
    getSalesmen(): Observable<Lookup[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Lookup[]>(config.apiUrl + "admin/sites/salescontacts", {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }

    userSearch(search: UserSearch): Observable<UserInfo[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.post<UserInfo[]>(config.apiUrl + "admin/sitegroups/searchusers", search, {headers: headers } ).pipe(catchError(this.serviceHelpers.handleError), map(users => {
            if (users && users.map) {
                return users.map(user => new UserInfo(user))
            }
            return users;
        }));
    }
    bulkPermissionSubmit(req: Updates): Observable<number> {
        let headers = new HttpHeaders(this.headers);
        return this.http.put<{id: number}>(`${config.apiUrl}admin/bulkpermissions`, req, {headers: headers}).pipe(catchError(this.serviceHelpers.handleError), map(obj => obj.id));
    }

    getSiteAccessPolicies(): Observable<SiteAccessPolicy[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<SiteAccessPolicy[]>(config.apiUrl + "admin/sites/accesspolicies", { headers: headers }).pipe(catchError(this.serviceHelpers.handleError));
    }

    searchIotLpr(searchParams: IotLprSearchParams): Observable<IotLpr> {
        const headers = new HttpHeaders(this.headers);
        return this.http.post<IotLpr>(
            "api/lpr/search",
            searchParams,
            { headers },
        ).pipe(catchError(this.serviceHelpers.handleError));
    }

    getIotLprImage(id: string, cropped: boolean): Observable<Blob> {
        const headers = new HttpHeaders(this.headers);
        return this.http.get(
            "api/lpr/image/" + id + "?cropped=" + cropped,
            { headers, responseType: "blob" },
        ).pipe(catchError(this.serviceHelpers.handleError));
    }

    fetchSites(): Observable<AdminSiteListItem[]> {
        const headers = new HttpHeaders(this.headers);
        return this.http.get<AdminSiteListItem[]>("api/sites/admin", { headers }).pipe(catchError(this.serviceHelpers.handleError));
    }

    getSites(): Observable<AdminSiteListItem[]> {
        this.store.pipe(select(selectors.sitesLoaded), take(1)).subscribe(loaded => {
            if(!loaded) {
                this.store.dispatch(actions.fetchSites());
            }
        });
        return this.store.pipe(select(selectors.sites));
    }

    fetchSiteTimezones(siteIDs: number[]): Observable<SiteTimezone[]> {
        const headers = new HttpHeaders(this.headers);
        return this.http.post<SiteTimezone[]>(
            "api/sites/timezones",
            siteIDs,
            { headers },
        ).pipe(catchError(this.serviceHelpers.handleError));
    }
}

export interface SentMessage {
    subject: string;
    text: string;
    target?: string;
    values?: number[];
    importanceLevel?: number;
    hardTypesWitlst?: number[];
    softTypesWitlst?: number[];
}
export interface MetricsQuery {
    whenStart?: Date;
    whenEnd?: Date;
    salesRep?: number;
}
function formatDateFormAdminMetrics(date: Date): string { //sigh
    if(!date || !date.getFullYear) {
        return ""
    }
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
}
