import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from "./state";
import * as AdminExtendedStorageActions from './actions';
import { sites } from "app/extended-storage/store/selectors";

const reducer = createReducer(
    initialState,

on(AdminExtendedStorageActions.setSelectedEnabledOption, (state: State, {option}) => {
    return {
        ...state,
        selectedEnabledOption: option,
    }
}),

on(AdminExtendedStorageActions.clearFilters, (state: State) => {
    return {
        ...state,        
        selectedEnabledOption: "All",
        textFilter: "",
    }
}),

on(AdminExtendedStorageActions.setTextFilter, (state: State, {textFilter}) => {
    return {
        ...state,
        textFilter: textFilter,
    }
}),

on(AdminExtendedStorageActions.getByosSitesSuccess, (state: State, {sites}) => {
    return {
        ...state,
        byosSites: sites,
    }
}),

on(AdminExtendedStorageActions.setByosGridState, (state: State, {gridState}) => {
    return {
        ...state,
        byosGridState: gridState,
    }
}),

on(AdminExtendedStorageActions.setShowBackfillForm, (state: State, {show}) => {
    return {
        ...state,
        showBackfillForm: show,
    }
}),

on(AdminExtendedStorageActions.setBackfillSite, (state: State, {site}) => {
    return {
        ...state,
        backfillSite: site,
        showBackfillForm: true,
    }
}),

on(AdminExtendedStorageActions.submitBackfillRequestSuccess, (state: State) => {
    let site = state.backfillSite;;
    site.isComplete = false;
    let sites = [...state.byosSites];
    let index = sites.findIndex(s => s.id === site.id);
    sites[index] = site;

    return {
        ...state,
        backfillSite: null,
        byosSites: sites,
        showBackfillForm: false,
    }
}),

on(AdminExtendedStorageActions.submitBackfillRequestFailure, (state: State) => {
    return {
        ...state,
        backfillSite: null,
        showBackfillForm: false,
    }
}),

);

export function adminExtendedStorageReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}



