import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, withLatestFrom, concatMap, catchError, tap, flatMap } from 'rxjs/operators';
import * as LPRActions from './actions';
import { Store, select, createAction } from "@ngrx/store";
import { AppState } from "app/app.state";
import { LprService } from '../lpr.service';
import { of } from "rxjs";
import { selectLPR, selectAlertEntries, selectAlertEntryToRemove } from "./selectors";
import { NotificationsService } from '../../notifications/notifications.service';
import { RecentHitsService } from "../recent-hits/recent-hits.service";

@Injectable()
export class LPREffects {

    loadAlertEntries$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LPRActions.loadAlertEntries),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectLPR))),
                )
            }),
            switchMap(([action, lpr]) => {
                if(action.siteID === lpr.alertEntriesSiteID) {
                    return of(LPRActions.noAction());
                }
                this.store.dispatch(LPRActions.setAlertEntriesSiteID({siteID: action.siteID}));
                return this.lprService.getAlertEntries(action.siteID).pipe(
                    map(data => {
                        return LPRActions.setAlertEntries({alertEntries: data});
                    }),
                    catchError(() => {
                        this.notificationsService.error("","Error loading alert entries");
                        return of(LPRActions.noAction());
                    }),
                )
            })
        )
    })

    removeAlertEntry$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LPRActions.removeAlertEntry),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectAlertEntries))),
                    withLatestFrom(this.store.pipe(select(selectAlertEntryToRemove))),
                )
            }),
            switchMap(([[action, alertEntries], alertEntryToRemove]) => {
                this.store.dispatch(LPRActions.setShowRemoveAlertEntryModal({ show: false }));

                return this.lprService.removeAlertEntry(alertEntryToRemove.id, alertEntryToRemove.siteID).pipe(
                    map(() => {
                        this.store.dispatch(LPRActions.setAlertEntryToRemove({ entry: null }));
                        let newEntries = alertEntries.filter((entry) => entry.id != alertEntryToRemove.id);
                        return LPRActions.setAlertEntries({ alertEntries: newEntries });
                    }),
                    catchError(() => {
                        this.store.dispatch(LPRActions.setAlertEntryToRemove({ entry: null }));
                        this.notificationsService.error("", "Error removing alert entry");
                        return of(LPRActions.noAction());
                    }),
                )
            })
        )
    })            
    
    // Recent Hits
    recentHitsLoadHits$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LPRActions.recentHitsLoadHits),
            // concatMap(action => {
            //     return of(action).pipe(
            //         withLatestFrom(this.store.select(s =>s.site))
            //     )
            // }),
            switchMap((action) => {
                return this.recentHitsService.getEntriesWithRecentHits(action.siteID).pipe(
                    map(data => LPRActions.recentHitsSetHits({hits: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading recent hits")
                        return of(LPRActions.noAction());
                    }),
                )
            })
        )
    })

    // Alert Details
    alertDetailsLoadHits$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LPRActions.alertDetailsLoadHits),
            switchMap((action) => {
                return this.lprService.loadRecentHitsForAlert(action.alertID, action.siteID).pipe(
                    map(data => LPRActions.alertDetailsSetHits({hits: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading alert details")
                        return of(LPRActions.noAction());
                    })
                )
            })
        )
    })

    alertDetailsSelectAlert$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LPRActions.alertDetailsSelectAlert),
            
            switchMap((action) => [
                LPRActions.alertDetailsSetSelectedAlert({entry: action.entry}),
                LPRActions.alertDetailsLoadHits({alertID: action.entry.id, siteID: action.entry.siteID})
            ])
        )
    })

    // Add/Edit Alert Entry
    saveAlertEntry$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LPRActions.saveAlertEntry),
            switchMap((action) => {
                LPRActions.setSaveDisabled({saveDisabled: true});
                return this.lprService.saveAlertEntry(action.alertEntry).pipe(
                    switchMap(entry => [
                        LPRActions.appendAlertEntry({entry: entry}),
                        LPRActions.setSuccess({success: true}),
                    ]),
                    catchError(() => {
                        this.notificationsService.error("", "Error saving alert entry")
                        LPRActions.setSuccess({success: false})
                        return of(LPRActions.noAction());
                    }),
                )
            })
        )
    })

    updateAlertEntry$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LPRActions.updateAlertEntry),
            switchMap((action) => {
                LPRActions.setSaveDisabled({saveDisabled: true});
                return this.lprService.updateAlertEntry(action.entry).pipe(
                    switchMap(entry => [
                        // The internet seems to maybe think we should make a new action with multiple effects instead - updateAlertEntrySuccess?
                        LPRActions.replaceAlertEntry({entry: entry}),
                        LPRActions.setBottomTab({tab:"manageAlerts"}),
                        LPRActions.setEditEntry({entry: null}),
                        LPRActions.setSaveDisabled({saveDisabled: false}),
                    ]),
                    catchError(() => {
                        this.notificationsService.error("", "Error updating alert entry");
                        LPRActions.setSuccess({success: false})
                        LPRActions.setSaveDisabled({saveDisabled: false});
                        return of(LPRActions.noAction());
                    }),
                )
            }),
        )
    })

    loadTags$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LPRActions.loadTags),
            switchMap(() => {
                return this.lprService.getTags().pipe(
                    map(data => LPRActions.setTags({tags: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading tags")
                        return of(LPRActions.noAction());
                    }),
                )
            })
        )
    })
    
    // Button clicked to view modal
    viewHitClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LPRActions.viewHitClicked),
            map((action) => {
                this.store.dispatch(LPRActions.setViewHit({hit: action.hit}));
                return LPRActions.showViewHitModal({show: true});
            })
        )
    })

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private recentHitsService: RecentHitsService,
        private lprService: LprService,         
        private notificationsService: NotificationsService,
    ) {}
}