import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';

import { UserService } from '../users/user.service';

@Component({
  selector: 'app-first-login',
  templateUrl: './first-login.component.html',
  styleUrls: ['./first-login.component.css']
})
export class FirstLoginComponent implements AfterViewInit {
  @Input() user;
  @Input() set show(show){
    if(this.modal) {
      if(show) {
        this.modal.show();
      } else {
        this.modal.hide();
      }
    }
  };
  @Input() error:string;
  @Input('saveInProgress') saveInProgress;
  @ViewChild('modal', {static: true}) modal;
  @Output() update = new EventEmitter();
  constructor(/*public userService: UserService*/) { }

  ngAfterViewInit() {
    if(this.show) {
      this.modal.show();
    }
  }
  onUpdate(event) {
    this.update.emit(event);
    // this.userService.updateCurrentUser(event).subscribe(user => {
    //   this.modal.hide()
    // }, error => this.error = error)
  }
}
