import * as fromNewLeadState from './state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectNewLead = createFeatureSelector<fromNewLeadState.State>(fromNewLeadState.NewLeadKey);

export const showNewLeadForm = createSelector(
    selectNewLead,
    (newLead => newLead.showNewLeadForm),
)

export const managementCompanies = createSelector(
    selectNewLead,
    (newLead => newLead.managementCompanies),
)

export const leadSources = createSelector(
    selectNewLead,
    (newLead => newLead.leadSources),
)

export const internalEmails = createSelector(
    selectNewLead,
    (newLead => newLead.internalEmails),
)

export const relatedSites = createSelector(
    selectNewLead,
    (newLead => newLead.relatedSites),
)

export const submitInProgress = createSelector(
    selectNewLead,
    (newLead => newLead.submitInProgress),
)
