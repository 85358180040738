import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import 'rxjs';

import { MarkerEvent } from './marker-event';


//service for managing events emitted by markers (ex change current map, navigate to different page)
//getEvents gets an observable of all the events that can be filtered for the events needed
//could also add more functions here to do the filtering for common types
@Injectable()
export class MarkerEventService {
    public eventsObservable: Subject<MarkerEvent>;
    constructor() {
        this.eventsObservable = new Subject<MarkerEvent>()
    }
    getEvents(): Observable<MarkerEvent> {
        return this.eventsObservable;
    }
    sendEvent(event: MarkerEvent) {
        this.eventsObservable.next(event);
    }
}