import { props, createAction} from "@ngrx/store";
import {State} from '@progress/kendo-data-query';
import {ByosBackfillSite} from '../byos-backfill-site';
import {BackfillRequest} from '../backfill-request';



export const setSelectedEnabledOption = createAction("[Admin Extended Storage] Set Selected Enabled Option", props<{option: string}>());

export const setTextFilter = createAction("[Admin Extended Storage] Set Text Filter", props<{textFilter: string}>());

export const clearFilters = createAction("[Admin Extended Storage] Clear Filters");

export const getByosSites =   createAction("[Admin Extended Storage] Get Byos Sites");

export const getByosSitesFailure = createAction("[Admin Extended Storage] Get Byos Sites Failure");

export const getByosSitesSuccess = createAction("[Admin Extended Storage] Get Byos Sites Success", props<{sites: ByosBackfillSite[]}>());

export const setByosGridState = createAction("[Admin Extended Storage] Set Byos Grid State", props<{gridState: State}>());

export const setShowBackfillForm = createAction("[Admin Extended Storage] Show Backfill Form", props<{show: boolean}>());

export const setBackfillSite = createAction("[Admin Extended Storage] Set Backfill Site", props<{site: ByosBackfillSite}>());

export const submitBackfillRequest = createAction("[Admin Extended Storage] Submit Backfill Request", props<{request: BackfillRequest}>());

export const submitBackfillRequestSuccess = createAction("[Admin Extended Storage] Submit Backfill Request Success");

export const submitBackfillRequestFailure = createAction("[Admin Extended Storage] Submit Backfill Request Failure");
