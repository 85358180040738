import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { TimeObj, dateFromTimeObj } from "../../time-obj";
import { MotionDateTime } from './motion-datetime';

@Component({
  selector: 'app-motion-datetime-modal',
  templateUrl: './motion-datetime-modal.component.html',
  styleUrls: ['./motion-datetime-modal.component.css']
})

export class MotionDatetimeModalComponent implements OnInit {
  @Input() set model(motionDateTimeData: MotionDateTime) {
    this.initial = motionDateTimeData;
  }

  @Input("active") set activeSetter(value: boolean) {
    this.active = value;
    if (this.initial && value) {
      this.initializeMotionDateTime(this.initial);
    }
  }

  @Input("rawRequestHours") set setRawRequestHours(value: number) {
    if (value) {
      this.rawRequestHours = value;
    }
  }

  @Input("daysContinuous") set setDaysContinuous(value: number) {
    if (value && value > 0) {
      this.daysContinuous = value;
      this.maxDate = new Date();
      let min = new Date();
      this.minDate = new Date(min.setDate(min.getDate()-value));
      this.minDate.setHours(0,0,0,0)
    }
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();

  @Output() save: EventEmitter<MotionDateTime> = new EventEmitter();


  public initial: MotionDateTime;
  public active: boolean = false;
  public selectedYear: number;
  public selectedMonth: number;
  public selectedDay: number;
  public selectedTime: number;
  public selectedAMPM: string;
  public selectedHoursToView: number;
  public dateArray: Date[] = [];
  public timeArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public ampmArray: string[] = ["AM", "PM"];
  public hoursToViewArray: number[] = [];
  public numberOfDays = 14;
  public selectedDateIndex: number;
  public selectedTimeIndex: number;
  public selectedAMPMIndex: number;
  public selectedHoursToViewIndex: number;
  public selectedDateState: boolean[] = [];
  public selectedTimeState: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false];
  public selectedAMPMState: boolean[] = [false, false];
  public selectedHoursToViewState: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false];
  public motionEndDate: Date;
  public motionStartDate: Date;
  public invalidDateTime: boolean = false;
  public dateTimeOutput: MotionDateTime;
  public rawRequestHours: number;
  public isRawRequest: boolean;
  public hoursToView: number;
  public maxHoursToViewButtons: number = 12;
  public daysContinuous: number = 0;
  public datePickerDate: Date;
  public minDate: Date;
  public maxDate: Date;
  public dateRangeError: string = "";

  constructor() { }

  ngOnInit() {

    for (let i = 0; i < this.numberOfDays; i++) {
      this.selectedDateState.push(false);
    }  

    if (typeof this.rawRequestHours !== 'undefined') {
      this.isRawRequest = true;
      this.hoursToView = this.rawRequestHours;
    } else {
      this.hoursToView = 4;
    }  

  }

  onCancel(event: Event): void {
    this.closeModal();
  }

  closeModal(): void {
    this.active = false;
    this.dateArray = [];
    this.hoursToViewArray = [];
    this.cancel.emit();
  }

  initializeMotionDateTime(dateTimeData: MotionDateTime) {
    this.initializeDateButtons(dateTimeData);
    this.initializeHoursToViewButtons(dateTimeData);
    this.initializeTimeButtons(dateTimeData);
    this.initializeDatePicker(dateTimeData);
    
  }

  initializeDateButtons(dateTimeData: MotionDateTime) {
    this.dateArray = [];
    let start = new Date();
    for (let i = 0; i < this.numberOfDays; i++) {
      let startDay = new Date(start);
      let day = startDay.setDate(startDay.getDate() - i);
      this.dateArray.push(new Date(day));
    }
    this.dateArray.reverse();
    let index = this.findSelectedDateIndex(dateTimeData.motionStartDate.day);
    this.dateButtonChange(index);
    return;
  }

  findSelectedDateIndex(selectedDate: number) {
    let dateFromArray = this.dateArray.filter(item => {
      return selectedDate === item.getDate();
    });
    let index = this.dateArray.indexOf(dateFromArray[0]);
    return index;
  }

  initializeHoursToViewButtons(dateTimeData: MotionDateTime) {

    let loopCount: number;
    if (this.isRawRequest && this.rawRequestHours < 13) {
      loopCount = this.rawRequestHours;
    } else {
      loopCount = 4;
    }

    for (let i = 0; i < loopCount; i++) {
      this.hoursToViewArray.push(i+1);
    }

    if (this.isRawRequest && this.rawRequestHours > 12) {
      let hrsPerButton = (this.rawRequestHours - 4) / 7;
      let remainder = (this.rawRequestHours - 4) % 7;
      let hrsPerButtonWhole = Math.trunc(hrsPerButton);
  
      let hrsToAdd: number;
      if (remainder >= 0.5) {
        hrsToAdd = hrsPerButtonWhole + 1;
      } else {
        hrsToAdd = hrsPerButtonWhole;
      }
  
      let lastIndex = 0;
      let hrsSum = 4;
      for (let i = 4; i < 11; i++) {
        if (hrsSum + hrsToAdd >= this.rawRequestHours) {
          break;
        }
        this.hoursToViewArray.push(hrsSum + hrsToAdd);
        hrsSum = hrsSum + hrsToAdd;
        lastIndex = i;
      }

      this.hoursToViewArray.push(this.rawRequestHours);

    }

    let startDate = dateFromTimeObj(dateTimeData.motionStartDate);
    let endDate = dateFromTimeObj(dateTimeData.motionEndDate);
    let hrsDiff = (Math.abs(endDate.valueOf() - startDate.valueOf()) / 36e5);
    this.hoursToViewButtonChange(this.hoursToViewArray.indexOf(hrsDiff));

    return;
  
  }

  initializeTimeButtons(dateTimeData: MotionDateTime) {
    let hour = dateTimeData.motionStartDate.hours;
    let index: number;
    switch (true) {
      case hour === 0:
        index = 11;
        this.timeButtonChange(index);
        index = 0
        this.ampmButtonChange(index);
        break;

      case hour === 12:
        index = 11
        this.timeButtonChange(index);
        index = 1;
        this.ampmButtonChange(index);
        break;

      case hour > 12:
        index = (hour - 12) - 1;
        this.timeButtonChange(index);
        index = 1;
        this.ampmButtonChange(index);
        break;

      case hour < 12:
        index = hour - 1;
        this.timeButtonChange(index);
        index = 0;
        this.ampmButtonChange(index);
    }
    return;
  }
  initializeDatePicker(dateTimeData: MotionDateTime) {
    this.datePickerDate = dateTimeData.motionStartDate.toDate();
    this.onDatePickerChange(this.datePickerDate);
  }
  onSubmit() {
    this.motionStartDate = this.getMotionStartDate();
    this.motionEndDate = this.getMotionEndDate();
    this.dateArray = [];
    this.hoursToViewArray = [];

    this.dateTimeOutput = new MotionDateTime({ motionStartDate: new TimeObj(this.motionStartDate), motionEndDate: new TimeObj(this.motionEndDate) });
    this.save.emit(this.dateTimeOutput);
    this.active = false;
  }

  getMotionStartDate() {
    let time = this.convertTime(this.selectedTime, this.selectedAMPM);
    let startDate = new Date(this.selectedYear, this.selectedMonth, this.selectedDay, time);
    return startDate;
  }

  getMotionEndDate() {
    let time = this.convertTime(this.selectedTime, this.selectedAMPM);
    let endDate = new Date(this.selectedYear, this.selectedMonth, this.selectedDay, time);
    endDate.setHours(endDate.getHours() + this.selectedHoursToView);
    return endDate;
  }

  dateButtonChange(index: number) {
    this.selectedDateIndex = index;
    this.selectedYear = this.dateArray[index].getFullYear();
    this.selectedMonth = this.dateArray[index].getMonth();
    this.selectedDay = this.dateArray[index].getDate();

    this.checkForValidDateTime();

    if (this.selectedDateState[index] === true) {
      return;
    }
    for (let i = 0; i < this.selectedDateState.length; i++) {
      if (i === index) {
        this.selectedDateState[i] = true;
      } else {
        this.selectedDateState[i] = false;
      }
    }
  }

  timeButtonChange(index: number) {
    this.selectedTimeIndex = index;
    this.selectedTime = this.timeArray[index];
    this.checkForValidDateTime();
    if (this.selectedTimeState[index] === true) {
      return;
    }
    for (let i = 0; i < this.selectedTimeState.length; i++) {
      if (i === index) {
        this.selectedTimeState[i] = true;
      } else {
        this.selectedTimeState[i] = false;
      }
    }
  }

  ampmButtonChange(index: number) {
    this.selectedAMPMIndex = index;
    this.selectedAMPM = this.ampmArray[index];
    this.checkForValidDateTime();
    if (this.selectedAMPMState[index] === true) {
      return;
    }
    for (let i = 0; i < this.selectedAMPMState.length; i++) {
      if (i === index) {
        this.selectedAMPMState[i] = true;
      } else {
        this.selectedAMPMState[i] = false;
      }
    }
  }

  hoursToViewButtonChange(index: number) {
    this.selectedHoursToViewIndex = index;
    this.selectedHoursToView = this.hoursToViewArray[index];
    this.checkForValidDateTime();
    if (this.selectedHoursToViewState[index] === true) {
      return;
    }
    for (let i = 0; i < this.selectedHoursToViewState.length; i++) {
      if (i === index) {
        this.selectedHoursToViewState[i] = true;
      } else {
        this.selectedHoursToViewState[i] = false;
      }
    }
  }

  checkForSelections() {
    if (typeof this.selectedDateIndex === 'undefined' ||
      typeof this.selectedTimeIndex === 'undefined' ||
      typeof this.selectedAMPMIndex === 'undefined' ||
      typeof this.selectedHoursToViewIndex === 'undefined' ||
      this.invalidDateTime === true) {
      return true;
    }
    return false;
  }

  convertTime(hour: number, ampm: string) {
    let newHour: number;
    switch (ampm) {
      case "AM":
        if (hour === 12) {
          newHour = 0;
        } else {
          newHour = hour;
        }
        break;
      case "PM":
        if (hour < 12) {
          newHour = hour + 12;
        } else {
          newHour = hour;
        }
    }
    return newHour;
  }

  checkForValidDateTime() {
    let check = (typeof this.selectedDateIndex === 'undefined' ||
      typeof this.selectedTimeIndex === 'undefined' ||
      typeof this.selectedAMPMIndex === 'undefined' ||
      typeof this.selectedHoursToViewIndex === 'undefined')
    if (check === false) {
      if (this.getMotionStartDate() > new Date()) {
        this.invalidDateTime = true;
      } else {
        this.invalidDateTime = false;
      }
    }
  }

  onDatePickerChange(datePicked: Date) {
    this.dateRangeError = "";
    if(datePicked.getTime() > this.maxDate.getTime() || datePicked.getTime() < this.minDate.getTime()) {
      this.dateRangeError = "Please select a date in the available range."
      return;
    }
    this.selectedYear = datePicked.getFullYear();
    this.selectedMonth = datePicked.getMonth();
    this.selectedDay = datePicked.getDate();
    this.checkForValidDateTime();
  }

}
