import { Injectable } from '@angular/core';
import { SiteService } from 'app/site/site.service';
import { MarkerService } from 'app/markers/marker.service';
import { Observable } from 'rxjs';
import { Map } from 'app/map/map';
import { take, map } from 'rxjs/operators';
import { PropertyMap } from 'app/property-map/property-map';
import { TimeObj } from 'app/time-obj';
import { RawRequestCameraMarker } from './raw-request-camera-marker';
import { RewindRequest } from 'app/camera/rewind/rewind-request';
import { Cameras } from 'app/requests/new-request/cameras/cameras';
import { NotificationsService } from 'app/notifications/notifications.service';
import { When } from 'app/requests/new-request/when/when';
import { RequestType } from 'app/requests/request-type';
import { CameraService } from 'app/camera/camera.service';
import { Router } from '@angular/router';
import { User } from 'app/users/user';
import { Site } from 'app/site/site';

@Injectable({
  providedIn: 'root'
})
export class RawRequestService {

  constructor(
    private siteService: SiteService,
    private markerService: MarkerService,
    private notificationsService: NotificationsService,
    private cameraService: CameraService,
    private router: Router,
  ) { }

  getMaps(): Observable<Map[]> {
    return this.siteService.getSite().pipe(
      take(1),
      map(site => {
        let maps: Map[] = [];
        if(site && site.maps) {
          site.maps.forEach(map => {
            let newMap = new PropertyMap(map);
            let markers = map.markers.filter(m => m.type === "camera");
            newMap.markersLocal = [];
            newMap.markersLocal = this.markerService.createRawRequestCameraMarkers(markers, newMap)
            let otherMarkerData = map.markers.filter(m => m.type !== "camera");
            let otherMarkers = this.markerService.createMarkers(otherMarkerData, newMap);
            newMap.markersLocal = [...newMap.markersLocal, ...otherMarkers];
            maps.push(newMap);
          })
        }
        console.log(maps)
        return maps;
      })
    );
  }
  submit(start: TimeObj, end: TimeObj, markers: RawRequestCameraMarker[], description: string, siteID: number, user: User) {
    let request: any = {};
    request.when = {};
    request.when.start = start;
    request.when.end = end;
    request.requestType = RequestType.Normal;
    request.isAutoRequest = true;
    request.where = {};
    request.where.description = description;
    request.contactInfo = {};
    request.contactInfo.user = user;
    request.siteID = siteID
    request.cameras = new Cameras(markers);  
    this.cameraService.expressRequest(request).subscribe(resp => {
        this.notificationsService.success("", "Request Submitted");
        this.router.navigate(['site', siteID, 'events']);
    }, err => this.notificationsService.error("", "Error Submitting Request"))
  }
}

