
import {throwError as observableThrowError } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { config } from '../app.config';
import { AccessKeyService } from '../users/access-key.service';
import { AuthFacade } from '../auth/auth.facade';



@Injectable()
export class Logger {
    public headers = {};

    constructor(public http: HttpClient, public keyService: AccessKeyService, public authFacade: AuthFacade) {
        this.keyService.getKey().subscribe(key => {
            this.headers = Object.assign(this.headers, { "accessKey": key});
        })

    }

    log(message: string) {
        console.log(message);
    }
    error(message: string) {
        console.error(message);
    }
    saveUrl(url: string) {
        let body = JSON.stringify({url: url});
        let headers = new HttpHeaders(Object.assign({}, this.headers, { 'Content-Type': 'application/json'}));
        this.http.post<any>(config.apiUrl + 'userdata/url', body, { headers: headers }).pipe(catchError(this.handleError),).subscribe();
    }
    sendRewindInfo(rewind: {cameraID: number, startTime: Date}, siteID: number) {
        let body = JSON.stringify(rewind);
        let headers = new HttpHeaders(Object.assign({}, this.headers, { 'Content-Type': 'application/json'}));
        return this.http.post<any>(config.apiUrl + "userdata/site/" + siteID + "/rewind", body,{headers: headers}).pipe(catchError(this.handleError),);
    }
    trackAction(typeID: number, siteID: number) {
        let body = {};
        let headers = new HttpHeaders(Object.assign({}, this.headers, { 'Content-Type': 'application/json'}));
        return this.http.post<any>(config.apiUrl + "userdata/site/" + siteID + "/action/" + typeID, body, { headers: headers }).pipe(catchError(this.handleError),);
    }

    public handleError = (error: any) => {
        let errMsg = error.message || 'Server error';
        this.error(errMsg);
        // if (error.status === 401) {
        //     this.authFacade.logout();
        // }
        return observableThrowError(errMsg);
    }
    public extractData(res: HttpResponse<any>) {
        if (res.status < 200 || res.status >= 300) {
            throw new Error('Bad response status: ' + res.status);
        }
        let body = res.body();
        return body || {};
    }
}
