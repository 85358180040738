// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wide {
    width: 100%;
}

.line {
    border-bottom-style: solid;
    border-bottom-color: #bfbfbf;
    border-bottom-width: 1px;
}

.note {
    margin-top: 30px;
    margin-bottom: 30px;
}

.text {
    white-space: pre-wrap;
}

.card {
    background-color: #f5f5f5;
}
`, "",{"version":3,"sources":["webpack://./src/app/events/event-detail/note/note.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".wide {\r\n    width: 100%;\r\n}\r\n\r\n.line {\r\n    border-bottom-style: solid;\r\n    border-bottom-color: #bfbfbf;\r\n    border-bottom-width: 1px;\r\n}\r\n\r\n.note {\r\n    margin-top: 30px;\r\n    margin-bottom: 30px;\r\n}\r\n\r\n.text {\r\n    white-space: pre-wrap;\r\n}\r\n\r\n.card {\r\n    background-color: #f5f5f5;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
