import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, concatMap, withLatestFrom, catchError } from 'rxjs/operators';
import { Store, select } from "@ngrx/store";
import { AppState } from "app/app.state";
import { of } from 'rxjs';
import { AdminLprService } from '../admin-lpr.service';
import * as adminLPRActions from './actions';
import { selectAdminLPR, selectSelectedAlertEntry } from './selectors';
import { NotificationsService } from '../../../notifications/notifications.service';

@Injectable()
export class adminLPREffects {

    loadAlertEntries$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adminLPRActions.loadAlertEntries),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectSelectedAlertEntry))),
                )
            }),
            switchMap(([action, selectedAlertEntry]) => {
                if(selectedAlertEntry.length > 0) {
                    return of(adminLPRActions.noAction());
                }
                this.store.dispatch(adminLPRActions.setAlertEntriesLoading({ loading: true }));
                return this.adminLPRService.getAdminAlertEntries().pipe(
                    map(data => {
                        if(data.length > 0) {
                        let selected: number[] =[];
                        selected.push(data[0].id);
                        this.store.dispatch(adminLPRActions.setSelectedAlertEntry({entry: selected}));
                        this.store.dispatch(adminLPRActions.loadAlertEntryHits());
                    }
                        return adminLPRActions.setAlertEntries({ alertEntries: data });
                    }),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading admin alert entries");
                        return of(adminLPRActions.setAlertEntriesLoading({ loading: false }));
                    }),
                )
            })
        )
    })

    loadAlertEntryHits$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adminLPRActions.loadAlertEntryHits),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectAdminLPR))),
                )
            }),            
            switchMap(([action, adminLPR]) => {
                if(adminLPR.selectedAlertEntry.length < 1)
                this.store.dispatch(adminLPRActions.setAlertEntryHitsLoading({ loading: true }));
                return this.adminLPRService.getAdminAlertEntryHits(adminLPR.selectedAlertEntry[0]).pipe(
                    map(data => {
                        return adminLPRActions.setAlertEntryHits({ alertEntryHits: data });
                    }),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading admin alert entry hits");
                        return of(adminLPRActions.setAlertEntryHitsLoading({ loading: false }));
                    }),
                )
            })
        )
    })

    loadAlertHits$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adminLPRActions.loadAlertHits),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectAdminLPR))),
                )
            }),            
            switchMap(([action, adminLPR]) => {
                this.store.dispatch(adminLPRActions.setAlertHitsLoading({ loading: true }));
                return this.adminLPRService.getAdminAlertHits().pipe(
                    map(data => {
                        return adminLPRActions.setAlertHits({ alertHits: data });
                    }),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading admin alert hits");
                        return of(adminLPRActions.setAlertHitsLoading({ loading: false }));
                    }),
                )
            })
        )
    })

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private adminLPRService: AdminLprService,
        private notificationsService: NotificationsService,
    ) { }
}