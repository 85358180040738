import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import * as fromAdminExtendedStorageState from './store/state';
import { adminExtendedStorageReducer } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { AdminExtendedStorageEffects } from './store/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAdminExtendedStorageState.backFillKey, adminExtendedStorageReducer),
    EffectsModule.forFeature([AdminExtendedStorageEffects]),
  ]
})
export class AdminExtendedStorageModule { }
