import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MotionSelectorService } from './motion-selector/motion-selector.service';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { setSelectionMaps, setCameraIDToName, setCameraIDToDisplayName, loadingMotionForSite, loadMotionForCameraID } from './store/actions';
import { CameraMarker } from 'app/camera/camera-marker';
import { tap, map, take } from 'rxjs/operators';
import { CameraService } from 'app/camera/camera.service';
import { Camera } from 'app/camera/camera';
import { CameraLite } from 'app/camera/camera-lite';

@Injectable({
  providedIn: 'root'
})
export class MotionLoaderGuard  {
  constructor(private motionSelectorService: MotionSelectorService, private cameraService: CameraService, private store: Store<AppState>) {}
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.motionSelectorService.getMaps().pipe(tap(maps => {
      console.log(maps)
      let params = route.params;
      let siteID = +params["siteID"];
      this.store.dispatch(loadingMotionForSite({siteID: siteID}));

      this.store.dispatch(setSelectionMaps({maps: maps}))
      let cameraIDToName = {};
      for(let map of maps) {
        for(let marker of map.markersLocal) {
          if(marker.type === "camera") {
            let m = marker as CameraMarker
            cameraIDToName[m.camera] = m.displayName
          }
        }
      }
      this.store.dispatch(setCameraIDToName({ map: cameraIDToName}));

      this.cameraService.getCamerasLite(siteID).subscribe(cameras => {
        let cameraList: CameraLite[] = cameras;
        let cameraIDToDisplayName = {};
       for (let camera of cameraList) {
         cameraIDToDisplayName[camera.id] = camera.displayName;
       }
       this.store.dispatch(setCameraIDToDisplayName({ map: cameraIDToDisplayName}));
      });

      let queryParams = route.queryParams
      if(queryParams["cameraID"]) {
        let cameraID = +queryParams["cameraID"];
        this.store.dispatch(loadMotionForCameraID({cameraID: cameraID}));
      }
    }), map(maps => true));

  }
}
