import { Component, OnInit, Input } from '@angular/core';
import { Map } from 'app/map/map';

@Component({
  selector: 'app-add-cams-form',
  templateUrl: './add-cams-form.component.html',
  styleUrls: ['./add-cams-form.component.css']
})
export class AddCamsFormComponent implements OnInit {
  @Input() maps: Map[] = [];
  @Input() numCams: number;
  @Input() mapsWithCamIDs: number[] = [];
  @Input() selectedMapID: number;

  constructor() { }

  ngOnInit() {
  }

}
