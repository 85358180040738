import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '../../app.config';
import { AccessKeyService } from '../../users/access-key.service'
import { ServiceHelpersService } from '../../service-helpers.service';
import { FileShare } from './file-share';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShareFilesService {
  private headers: HttpHeaders = new HttpHeaders();
  constructor(
    private http: HttpClient, 
    private helperService: ServiceHelpersService, 
    private accessKeyService: AccessKeyService,
  ) { 
    this.accessKeyService.getKey().subscribe(key => this.headers = this.headers.set("accessKey", key));    
  }
  sendShareFileEmail(fileShare: FileShare, siteID) {
    return this.http.post<any>(`${config.apiUrl}site/${siteID}/event/${fileShare.eventID}/files/email`, fileShare, {headers: this.headers}).pipe(catchError(this.helperService.handleError))
  }
}
