// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-synopsis-settings {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-inline: 1rem;
}

.event-synopsis-generate > kendo-label {
    display: flex;
    flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/app/ai/event-synopsis-modal/event-synopsis-modal.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,SAAS;IACT,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".event-synopsis-settings {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    gap: 10px;\r\n    padding-inline: 1rem;\r\n}\r\n\r\n.event-synopsis-generate > kendo-label {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
