import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ByosBackfillSite } from '../byos-backfill-site';
import { BackfillRequest } from '../backfill-request';
import { BsDatepickerConfig, BsDatepickerDirective  } from "ngx-bootstrap/datepicker";

@Component({
  selector: 'app-backfill-form',
  templateUrl: './backfill-form.component.html',
  styleUrls: ['./backfill-form.component.css']
})
export class BackfillFormComponent {

  @Input('showBackfillForm') set setBackfillForm(value: boolean) {
    this.showBackfillForm = value;
    this.errorMsg = '';
    this.startDate = null;
    this.endDate = null;
    this.validateDateRange();
  }
  @Input() backfillSite: ByosBackfillSite;
  @Output() backfillFormClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() backfillFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() backfillSubmitRequest: EventEmitter<BackfillRequest> = new EventEmitter<BackfillRequest>();

  public showBackfillForm: boolean = false;
  public startDate: Date;
  public maxStart: Date = new Date();
  public endDate: Date;
  public maxEnd: Date = new Date();

  public errorMsg: string = '';
  public showConfirmationDialog: boolean = false;

  colorTheme = 'theme-default';
  bsConfig?: Partial<BsDatepickerConfig>;

  constructor() { }

  ngOnInit() {
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.validateDateRange();
  }


  closeBackfillForm() {
    this.startDate = null;
    this.endDate = null;
    this.backfillFormClose.emit();
  }

  submitBackfillRequest() {
    let backfillRequest: BackfillRequest = {
      siteID: this.backfillSite.id,
      startDate: this.startDate,
      endDate: this.endDate
    }
    this.backfillSubmitRequest.emit(backfillRequest); 
  }

  startDateChanged(start: Date) {
    this.validateDateRange();
  }

  endDateChanged(end: Date) {
    this.validateDateRange();
  }

  validateDateRange() {

    if (!this.startDate && !this.endDate) {
      this.errorMsg = 'Start and end date are required';
      return
    }

    if (!this.startDate) {
      this.errorMsg = 'Start date is required';
      return
    }

    if (!this.endDate) {
      this.errorMsg = 'End date is required';
      return
    }

    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.errorMsg = 'End date must be greater than or equal to start date';
      return
    }
    this.errorMsg = '';
  }

  openConfirmationDialog() {
    this.showConfirmationDialog = true;
  }

  closeConfirmationDialog() {
    this.showConfirmationDialog = false;
  }

  confirmSubmit() {
    this.showConfirmationDialog = false;
    this.submitBackfillRequest();
  }

  applyTheme(pop: BsDatepickerDirective) {
    setTimeout(() => {
      pop.show();
    });
  }

}
