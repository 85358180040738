export class UserInfo {
    id: number;
    userName: string;
    firstName: string;
    lastName: string;
    typeName: string;
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config);
        }
    }
}