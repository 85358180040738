
import { TimeObj } from "app/time-obj";

export class MotionData  {
    start: TimeObj;
    end: TimeObj;
    cameraIDs: number[] = [];
    files: MotionFile[] = [];
    constructor(config?: any) {
        if(config) {
            if(config.start) {
                this.start = Object.assign(new TimeObj(), config.start);
            }
            if(config.end) {
                this.end = Object.assign(new TimeObj(), config.end);
            }
            if(config.cameraIDs) {
                this.cameraIDs = [...config.cameraIDs];
            }
            if(config.files) {
                this.files = config.files.map(f => new MotionFile(f));
            }
        }
    }
}

export class MotionFile {
    cameraID: number;
    time: TimeObj;
    end?: TimeObj;
    imageURL: string;

    constructor(config?: any) {
        if(config) {
            Object.assign(this, config)
            if(config.time) {
                this.time = Object.assign(new TimeObj(), config.time);
            }
            if(config.end) {
                this.end = Object.assign(new TimeObj(), config.end);
            }
        }
    }
}