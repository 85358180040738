// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-image-button {
  background-color: white;
  color: black;
  width: 70px;
  padding: 3px;
}

.request-review-button {
  background-color: #43aaa3;
  color: #ffffff;
  width: 70px;
  padding: 3px;
}

.horizontal > * {
  margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/lpr/alert-details/alert-details.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACI,uBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AADJ;;AAIA;EACI,yBCHS;EDIT,cCEY;EDDZ,WAAA;EACA,YAAA;AADJ;;AAIA;EAEI,YAAA;AAFJ","sourcesContent":["@import \"../../../variables.scss\";\r\n\r\n.view-image-button {\r\n    background-color:  white;\r\n    color: black;\r\n    width: 70px;\r\n    padding: 3px;\r\n}\r\n\r\n.request-review-button {\r\n    background-color: $text-color;\r\n    color: $selected-text;\r\n    width: 70px; \r\n    padding: 3px;\r\n}\r\n\r\n.horizontal > *{\r\n    // display: inline-block; \r\n    margin: 10px;\r\n}","$base-theme:Default;\r\n$skin-name:blue;\r\n$accent: #13688c;\r\n$info: #0066cc;\r\n$success: #37b400;\r\n$warning: #ffb400;\r\n$error: #db4240;\r\n$text-color: #43aaa3;\r\n$bg-color: #ffffff;\r\n$base-text: #003f59;\r\n$base-bg: #daecf4;\r\n$hovered-text: #003f59;\r\n$hovered-bg: #7bd2f6;\r\n$selected-text: #ffffff;\r\n$selected-bg: #28303d;\r\n$series-a: #314652;\r\n$series-b: #0098ee;\r\n$series-c: #7bd2f6;\r\n$series-d: #ffb800;\r\n$series-e: #ff8517;\r\n$series-f: #e34a00;\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
