import { props, createAction } from "@ngrx/store";
import { ManagementCompany } from "../management-company";
import { LeadSource } from "../lead-source";
import { InternalEmail } from "../internal-email";
import { RelatedSite } from "../related-site";
import { NewLead } from "../new-lead";

export const noAction = createAction("[NewLead] No Action");

export const setShowNewLeadForm = createAction("[NewLead] Set Show New Lead Form", props<{show: boolean}>());

export const setManagementCompanies = createAction("[NewLead] Set Management Companies", props<{companies: ManagementCompany[]}>());

export const setLeadSources = createAction("[NewLead] Set Lead Sources", props<{sources: LeadSource[]}>());

export const setInternalEmails = createAction("[NewLead] Set Internal Emails", props<{emails: InternalEmail[]}>());

export const getRelatedSites = createAction("[NewLead] Get Related Sites", props<{search: string}>());

export const setRelatedSites = createAction("[NewLead] Set Related sites", props<{sites: RelatedSite[]}>());

export const submitNewLead = createAction("[NewLead] Submit New Lead", props<{lead: NewLead}>());

export const submitNewLeadSuccess = createAction("[NewLead] Submit New Lead Success");

export const submitNewLeadFailure = createAction("[NewLead] Submit New Lead Failure");

export const cancelSubmitNewLead = createAction("[NewLead] Cancel Submit New Lead");

export const uploadNewLeadImages = createAction("[NewLead] Upload New Lead Images", props<{imageFiles: File[]}>());

export const uploadNewLeadImagesSuccess = createAction("[NewLead] Upload New Lead Images Success");

export const uploadNewLeadImagesFailure = createAction("[NewLead] Upload New Lead Images Failure");
