import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "app/users/user.service";
import { PermissionsService } from "app/permissions.service";
import { first, map, filter } from "rxjs/operators";



@Injectable()
export class AdminPermissionsGuard  {
    constructor(public userService: UserService, public router: Router, public permissions: PermissionsService) {}
    canActivate() {
        return this.userService.getUser().pipe(filter(user => !user.initial),map(user => this.permissions.admin('bulkPermissions')),first(),);
    }
}