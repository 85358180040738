
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import { START_REQUEST_WHERE, SET_REQUEST_CURRENT_WHERE } from '../../requests.reducer';

@Injectable()
export class WhereGuard  {
    constructor(public store: Store<AppState>) {}
    canActivate() {
        this.store.dispatch({ type: START_REQUEST_WHERE });
        this.store.dispatch({ type: SET_REQUEST_CURRENT_WHERE} );
        return true;
    }
}