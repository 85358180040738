import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocType } from './doc-type';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from 'app/app.config';
import { AccessKeyService } from 'app/users/access-key.service';
import { ServiceHelpersService } from 'app/service-helpers.service';
import { catchError } from 'rxjs/operators';
import { NotificationsService } from 'app/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class DocTypeService {
  public headers = {};

  private types: BehaviorSubject<DocType[]> = new BehaviorSubject<DocType[]>([]);
  private data: DocType[];
  private siteID: number;
  private siteTypesLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient, 
    private accessKeyService: AccessKeyService, 
    private serviceHelpersService: ServiceHelpersService,
    private notificationsService: NotificationsService,
  ) { 
    this.accessKeyService.getKey().subscribe(key => {
      this.headers = Object.assign(this.headers, { "accessKey": key });
    });
  }

  getTypes(siteID: number): Observable<DocType[]> {
    if (!this.data || this.data.length === 0 || siteID !== this.siteID) {
      this.loadTypes(siteID);
    }

    return this.types;
  }

  getSiteTypesLoading(): Observable<boolean> {
    return this.siteTypesLoading$;
  }

  getTypesAdmin(): Observable<DocType[]> {
    if (!this.data || this.data.length === 0) {
      this.loadTypesAdmin();
    }

    return this.types;
  }

  loadTypes(siteID: number) {
    let headers = new HttpHeaders(this.headers);
    this.siteTypesLoading$.next(true);
    this.http.get<DocType[]>(`${config.apiUrl}site/${siteID}/document/types`, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError)).subscribe(types => {
      this.data = types;
      this.siteID = siteID;
      this.types.next(types);
      this.siteTypesLoading$.next(false)
    }, err => {
      this.siteTypesLoading$.next(false)
    });
  }

  loadTypesAdmin() {
    let headers = new HttpHeaders(this.headers);
    this.http.get<DocType[]>(`${config.apiUrl}admin/document/types`, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError)).subscribe(types => {
      this.data = types;
      this.types.next(types);
    });
  }

  remove(item: DocType) {
    let headers = new HttpHeaders(this.headers);
    this.http.delete(`${config.apiUrl}admin/document/type/${item.id}`, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError)).subscribe(() => {
      this.data = this.data.filter(type => type.id !== item.id);
      this.types.next(this.data);
    }, e => {
      console.log(e)
      this.notificationsService.error("", e)
    });
  }

  add(item: DocType) {
    let headers = new HttpHeaders(this.headers);
    this.http.post<DocType>(`${config.apiUrl}admin/document/types`, item, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError)).subscribe(type => {
      this.data.push(type);
      this.types.next(this.data);
    });
  }

  update(item: DocType) {
    let headers = new HttpHeaders(this.headers);
    this.http.put<DocType>(`${config.apiUrl}admin/document/type/${item.id}`, item, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError)).subscribe(type => {
      this.data = this.data.map(old => old.id === type.id ? type : old);
      this.types.next(this.data);
    });
  }
}
