// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-nav {
    background-color: #28303d;
    margin-bottom: 0px;
}
.navbar-brand {
    padding: 7px;
}
.navbar-form {
    display: inline;
}
.sidebarControl {
    height: 100%;
}
.navbar-toggle .icon-bar {
    background-color: white;
}

.logo-center {
    margin-left: -20px;
    margin-right: 20px;
}

.teal {
    color: #43aaa3;
}`, "",{"version":3,"sources":["webpack://./src/app/navbar/navbar.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,YAAY;AAChB;AACA;IACI,eAAe;AACnB;AACA;IACI,YAAY;AAChB;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".my-nav {\r\n    background-color: #28303d;\r\n    margin-bottom: 0px;\r\n}\r\n.navbar-brand {\r\n    padding: 7px;\r\n}\r\n.navbar-form {\r\n    display: inline;\r\n}\r\n.sidebarControl {\r\n    height: 100%;\r\n}\r\n.navbar-toggle .icon-bar {\r\n    background-color: white;\r\n}\r\n\r\n.logo-center {\r\n    margin-left: -20px;\r\n    margin-right: 20px;\r\n}\r\n\r\n.teal {\r\n    color: #43aaa3;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
