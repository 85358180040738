import * as fromEventState from './state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GridDataResult } from "@progress/kendo-angular-grid";

export const selectEvents = createFeatureSelector<fromEventState.State>(fromEventState.eventKey);

export const portfolioEvents = createSelector(
    selectEvents,
    (event => event.portfolioEvents),
)

export const eventItems = createSelector(
    selectEvents,
    (event => event.eventItems),
)

export const eventGridState = createSelector(
    selectEvents,
    (event => event.eventGridState),
)

export const eventGridView = createSelector(
    portfolioEvents,
    eventItems,
    eventGridState,
    ((pEvents, data, state) => {

        let gridData: GridDataResult = {data: [], total: 0};
        if ( pEvents === null){
            return gridData;
        }

        gridData.data = [...data];
        gridData.total = pEvents.totalCount;

        return gridData;
    }
    )
)

export const eventGridLoading = createSelector(
    selectEvents,
    (event => event.eventGridLoading),
)

export const eventSort = createSelector(
    selectEvents,
    (event => event.eventSort),
)

export const siteList = createSelector(
    selectEvents,
    (event => event.siteList),
)

export const cfTagCategories = createSelector(
    selectEvents,
    (event => event.cfTagCategories),
)

export const siteFilter = createSelector(
    selectEvents,
    (event => event.siteFilter),
)

export const siteFilterArrayInt = createSelector(
    selectEvents,
    (event => event.siteFilter.map(s => s.id)),
)

export const cfTagCategoryFilter = createSelector(
    selectEvents,
    (event => event.cfTagCategoryFilter),
)

export const cfTagCategoryFilterArrayInt = createSelector(
    selectEvents,
    (event => event.cfTagCategoryFilter.map(s => s.id)),
)

export const startDateFilter = createSelector(
    selectEvents,
    (event => event.startDateFilter),
)

export const endDateFilter = createSelector(
    selectEvents,
    (event => event.endDateFilter),
)

export const statusCodes = createSelector(
    selectEvents,
    (event => event.statusCodes),
)

export const statusFilter = createSelector(
    selectEvents,
    (event => event.statusFilter),
)

export const statusFilterArrayInt = createSelector(
    statusFilter,
    (statusFilter => statusFilter.map(s => s.id)),
)

export const textFilter = createSelector(
    selectEvents,
    (event => event.textFilter),
)

export const eventTypeMetrics = createSelector(
    selectEvents,
    (event => event.eventTypeMetrics),
)

export const eventTypeMetricsLoading = createSelector(
    selectEvents,
    (event => event.eventTypeMetricsLoading),
)

export const showEventMetrics = createSelector(
    selectEvents,
    (event => event.showEventMetrics),
)

export const selectedBubble = createSelector(
    selectEvents,
    (event => event.selectedBubble),
)

export const eventTimeDayHour = createSelector(
    selectEvents,
    (event => event.eventTimeDayHour),
)

export const eventTimeDayHourLoading = createSelector(
    selectEvents,
    (event => event.eventTimeDayHourLoading),
)