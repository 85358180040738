import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromAdminExtendedStorageState from './state';
import { process } from '@progress/kendo-data-query';

export const selectByosBackfill = createFeatureSelector<fromAdminExtendedStorageState.State>(fromAdminExtendedStorageState.backFillKey);

export const textFilter = createSelector(
    selectByosBackfill,
    (backFill => backFill.textFilter),
);

export const enabledOptions = createSelector(
    selectByosBackfill,
    (backFill => backFill.enabledOptions),
);
export const selectedEnabledOption = createSelector(
    selectByosBackfill,
    (backFill => backFill.selectedEnabledOption),
);

export const byosSites = createSelector(
    selectByosBackfill,
    (backfill => backfill.byosSites),
);

export const filteredByosSites = createSelector(
    byosSites,
    selectedEnabledOption,
    (sites, option) => {

        if (option === "All") {
            return sites;
        }
        else if (option === "Storage Enabled") {
            return sites.filter(site => site.bucketID);
        }
        else (option === "Storage Disabled")
        {
            return sites.filter(site => !site.bucketID);
        }
});   

export const byosGridState = createSelector(
    selectByosBackfill,
    (backfill => backfill.byosGridState),
);

export const byosGridView = createSelector(
    filteredByosSites,
    byosGridState,
    textFilter,
    (sites, gridState, textFilter) => {
        if (textFilter) {
            sites = sites.filter(site => site.name.toLowerCase().includes(textFilter.toLowerCase()));
        }
       return process(sites, gridState);
    }
);

export const showBackfillForm = createSelector(
    selectByosBackfill,
    (backfill => backfill.showBackfillForm),
);

export const backfillSite = createSelector(
    selectByosBackfill,
    (backfill => backfill.backfillSite),
);