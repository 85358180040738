
import * as fromRawRequestState from './state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RawRequestCameraMarker } from '../raw-request-camera-marker';

export const selectRawRequest = createFeatureSelector<fromRawRequestState.State>(fromRawRequestState.RawKey);

export const selectedCameras = createSelector(
    selectRawRequest,
    (raw => [...raw.selectedCameras]),
)

export const isCameraSelected = (markerID: number) => {
    return createSelector(
        selectedCameras,
        (cams: RawRequestCameraMarker[]) => {
            return cams.map(marker => marker.id).indexOf(markerID) !== -1
        },
    )
}

export const numCamsSelected = createSelector(
    selectedCameras,
    (cams: RawRequestCameraMarker[]) => {
        return cams.length
    },
)

export const mapsWithCamsSelected = createSelector(
    selectedCameras,
    (cams: RawRequestCameraMarker[]) => cams.map(c => c.map.id),
)

export const selectedMaps = createSelector(
    selectRawRequest,
    (raw => {
        return raw.selectionMaps;
    }),
)

export const selectedMap = createSelector(
    selectRawRequest,
    (raw => {
        return raw.currentSelectionMapID;
    }),
)

export const selectedTime = createSelector(
    selectRawRequest,
    (motion => motion.selectedTime),
)

export const selectedHours = createSelector(
    selectedTime,
    (time => {
        if(!time.motionStartDate || !time.motionEndDate) {
            return 0;
        }
        return time.motionStartDate.hoursDifference(time.motionEndDate);
    }),
)

export const selectedStartTimeAsDate = createSelector(
    selectedTime,
    (time => {
        return time.motionStartDate.toDate();
    }),
)

export const selectedEndTimeAsDate = createSelector(
    selectedTime,
    (time => {
        return time.motionEndDate.toDate();
    }),
)

export const selectShowTimeModal = createSelector(
    selectRawRequest,
    (raw => {
        return raw.showTimeModal;
    }),
)

export const selectShowTooManyCams = createSelector(
    selectRawRequest,
    (raw => {
        return raw.showTooManyCams;
    }),
)

export const selectValid = createSelector(
    selectRawRequest,
    (raw => {
        return raw.description && raw.selectedCameras.length > 0;
    })
)

export const selectDescription = createSelector(
    selectRawRequest,
    (raw => raw.description),
)

export const selectShowTimeUpdateModal = createSelector(
    selectRawRequest,
    (raw => raw.showTimeUpdateModal),
)

export const rawSiteID = createSelector(
    selectRawRequest,
    (raw => raw.rawSiteID),
)