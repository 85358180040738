import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromAdminLPRState from './store/state';
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { adminLPRReducer } from "./store/reducers";
import { adminLPREffects } from "./store/effects"
import { GridModule } from "@progress/kendo-angular-grid";
import { LayoutModule } from "@progress/kendo-angular-layout";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAdminLPRState.adminLPRKey, adminLPRReducer),
    EffectsModule.forFeature([adminLPREffects]),
    GridModule,
    LayoutModule,
  ],
  providers: [
    adminLPREffects,
],
})
export class AdminLPRModule { }
