
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import { START_REQUEST_CONTACT_INFO, SET_REQUEST_CURRENT_CONTACT_INFO} from '../../requests.reducer';

@Injectable()
export class ContactInfoGuard  {
    constructor(public store: Store<AppState>) {

    }
    canActivate() {
        this.store.dispatch({ type: START_REQUEST_CONTACT_INFO });
        this.store.dispatch({ type: SET_REQUEST_CURRENT_CONTACT_INFO});
        return true;
    }
}