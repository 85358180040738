import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { switchMap, map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { FeatureViewActions, FeatureAPIActions } from "../actions";
import { FeatureService } from "../services";
import Feature from "../models/feature";

@Injectable()
export class FeatureEffects {
  public loadFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureViewActions.loadFeatures),
      switchMap(() =>
        this.featureService.fetch().pipe(
          map((features: Feature[]) => FeatureAPIActions.loadFeaturesSuccess({ features })),
          catchError((errorMessage: string) => of(FeatureAPIActions.loadFeaturesFailure({ errorMessage }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private featureService: FeatureService) {}
}
