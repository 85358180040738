import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ResizedEvent } from '../../angular-resize.directive';
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { menuIcon, filterIcon, SVGIcon, minHeightIcon, searchIcon } from "@progress/kendo-svg-icons";
import { PermissionsService } from "../../permissions.service";

@Component({
  selector: 'app-extended-storage-filter',
  templateUrl: './extended-storage-filter.component.html',
  styleUrls: ['./extended-storage-filter.component.css']
})
export class ExtendedStorageFilterComponent {

  @Input() enabledOptions: string[];

  @Input('selectedEnabledOption') set setSelectedEnabledOptions(value: string) {
    if (value) {
      this.selectedEnabledOption = value;
    }
  }

  @Input('textFilter') set setTextFilter(value: string) {
    this.textFilter = value;
  }

  @Input('appBarWidth') set setAppBarWidth(value: number) {
    this.appBarWidth = value;
  }

  @Output() clearFilters: EventEmitter<any> = new EventEmitter<any>();

  @Output() appBarWidthChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() textFilterChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() enabledOptionChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() adminButtonClick: EventEmitter<any> = new EventEmitter<any>();



  public appBarWidth: number = 0;
  public collapseWidth: number = 700;
  public textFilter: string = '';
  public selectedEnabledOption: string = "";

  public menuSVG: SVGIcon = menuIcon;
  public filterSVG: SVGIcon = filterIcon;
  public minHeightSVG: SVGIcon = minHeightIcon;
  public searchSVG: SVGIcon = searchIcon;



  constructor(private permissionsService: PermissionsService) { }

  ngOnInit(): void { }

  onTextFilterChange() {
    this.textFilterChange.emit(this.textFilter.trim());
  }

  onFiltersResized(event: ResizedEvent) {
    this.appBarWidth = Math.round(event.newRect.width);
  }

  onClearFilters() {
    this.clearFilters.emit();
  }

  onEnabledOptionChange() {
    this.enabledOptionChange.emit(this.selectedEnabledOption);
  }

  onAdminButtonClick() {
    this.adminButtonClick.emit();
  }

  checkIfAdmin() {
    return this.permissionsService.admin("isAdmin");
  }

}
