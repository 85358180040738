import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SiteGroup } from '../site-group';

@Component({
  selector: 'app-site-group-create',
  templateUrl: './site-group-create.component.html',
  styleUrls: ['./site-group-create.component.css']
})
export class SiteGroupCreateComponent implements OnInit {
  @Input("active") set activeSetter(value: boolean) {
    this.reset();    
    this.active = value;
    this.groupName = "";
  }

  @Input("currentSiteGroups") set currentSiteGroupsSetter(value: SiteGroup[]) {    
    this.currentSiteGroups = [];
    for(let group of value) {
      this.currentSiteGroups.push(group.name.toLowerCase());
    }
  }


  @Output() cancel: EventEmitter<any> = new EventEmitter();

  @Output() save: EventEmitter<SiteGroup> = new EventEmitter();

  public active: boolean = false;
  public siteGroup: SiteGroup;
  public groupName: string;
  public currentSiteGroups: string[] = [];
  public disableSubmitButton: boolean = true;
  public nameError: boolean;
  public nameErrorMsg: string;

  constructor() { }

  ngOnInit() {
  }

  onCancel(event: Event): void {
    this.closeModal();
  }

  closeModal(): void {
    this.cancel.emit();
  }

  reset() {
    this.disableSubmitButton = true;
    this.nameError = false;
    this.nameErrorMsg = "";
  }

  onNameChange() {

    this.groupName = this.groupName.trim();

    if (this.groupName.length > 45) {
      this.nameError = true;
      this.nameErrorMsg = "group name is limited to 45 chars"
      this.disableSubmitButton = true;
      return;
    }

    if (this.currentSiteGroups.indexOf(this.groupName.toLowerCase()) !== -1) {
      this.nameError = true;
      this.nameErrorMsg = "group name exists";
      this.disableSubmitButton = true;
      return;
    } 

    if (this.groupName === "") {
      this.disableSubmitButton = true;
      return;
    }

    this.nameError = false;
    this.nameErrorMsg = "";
    this.disableSubmitButton = false;
  }

  onSubmit() {
  this.siteGroup = new SiteGroup();
  this.siteGroup.name = this.groupName;
  this.save.emit(this.siteGroup);
  this.reset();
  }

}
