import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromAdminPermState from './state';
import { process } from '@progress/kendo-data-query';


export const selectAdminPerm = createFeatureSelector<fromAdminPermState.State>(fromAdminPermState.adminPermissionsKey);

export const selectManComps = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.manComps.sort((a,b) => a.name.trim().localeCompare(b.name.trim()));
    }),
)
export const selectManCompsLoading = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.manCompsLoading;
    }),
)
export const selectSearching = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.searching;
    })
)
export const selectSearchResults = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.users;
    })
)
export const selectUsersGridData = createSelector(
    selectAdminPerm,
    (adminPerm => adminPerm.users),
);
export const selectUsersGridState = createSelector(
    selectAdminPerm,
    (adminPerm => adminPerm.usersGridState),
);
export const selectUsersGridView = createSelector(
    selectUsersGridData,
    selectUsersGridState,
    ((data, state) => process(data, state)),
);
export const selectSelectedUsersKeys = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.selectedUsersKeys;
    }),
);
export const selectSelectAllCheckboxState = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.selectAllCheckboxState;
    }),
);

export const selectSelectedUsers = createSelector(
    selectUsersGridData,
    selectSelectedUsersKeys,
    ((users, keys) => {
        return users.filter(user => keys.indexOf(user.id) !== -1);
    }),
);

export const selectSelectedUserSites = createSelector(
    selectSelectedUsers,
    (users => {
        let siteObj = users.reduce((sites, user) => {
            user.sites.forEach(site => {
                sites[site.id] = site;
            });
            return sites;
        }, {});
        let sites = [];
        for(let k in siteObj) {
            sites = [...sites, siteObj[k]];
        }
        sites.sort((a,b) => {
            return a.name.trim().localeCompare(b.name.trim());
        })
        return sites;
    }),
);

export const selectAction = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.action;
    })
);

export const selectNewPermissions = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.newPermissions;
    })
);

export const selectSiteClude = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.siteClude;
    })
);

export const selectSites = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.selectedSites;
    })
);

export const selectSubmitting = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.submitting;
    })
);

export const selectTransactionID = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.transactionID;
    })
);

export const selectShowStatusModal = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.showStatusModal;
    })
);

export const selectSearchForm = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.searchForm;
    })
);

// Admin Permission Templates

export const selectPermissionTemplatesGridData = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.permissionTemplates;
    })
);

export const selectPermissionTemplatesLoading = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.permissionTemplatesLoading;
    }),
)

export const selectPermissionTemplatesGridState = createSelector(
    selectAdminPerm,
    (adminPerm => adminPerm.permissionTemplatesGridState),
);

export const selectPermissionTemplatesGridView = createSelector(
    selectPermissionTemplatesGridData,
    selectPermissionTemplatesGridState,
    ((data, state) => {
        [...data].sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) ? 1 : -1);
        return process(data, state)
    }),
);

export const selectShowDeletePermissionTemplateModal = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.showDeletePermissionTemplateModal
    })
);

export const selectTemplateHasUsers = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.templateHasUsers
    })
);

export const selectPermissionTemplateSelectedForDelete = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.permissionTemplateSelectedForDelete
    })
);

export const selectShowPermissionTemplateForm = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.showPermissionTemplateForm
    })
);

export const selectPermissionTemplateFormMode = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.permissionTemplateFormMode
    })
);

export const selectPermissionTemplateSelectedForEdit = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.permissionTemplateSelectedForEdit
    })
);

export const selectTemplateName = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.templateName
    })
);

export const selectTemplateNameDup = createSelector(
    selectAdminPerm,
    (adminPerm => {
        if (!adminPerm.permissionTemplateSelectedForEdit) {
            return false;
        }
        for (let template of adminPerm.permissionTemplates) {
            if (template.id !== adminPerm.permissionTemplateSelectedForEdit.id && adminPerm.templateName.toLowerCase().trim() === template.name.toLowerCase().trim()) {
                return true;
            }
        }
        return false;
    })
);

export const selectTemplateDescription = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.templateDescription
    })
);

export const selectTemplatePermissions = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.templatePermissions
    })
);

export const selectTemplateSaving = createSelector(
    selectAdminPerm,
    (adminPerm => {
        return adminPerm.templateSaving
    })
);