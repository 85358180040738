import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromAIState from "./state";

export const selectAI = createFeatureSelector<fromAIState.State>(fromAIState.aiKey);

export const eventSynopsis = createSelector(
    selectAI,
    (ai => ai.eventSynopsis),
);

export const eventSynopsisList = createSelector(
    selectAI,
    (ai => ai.eventSynopsisList),
);

export const eventSynopsisLoading = createSelector(
    selectAI,
    (ai => ai.eventSynopsisLoading),
);

export const eventSynopsisRequestId = createSelector(
    selectAI,
    (ai => ai.eventSynopsisRequestId),
);

export const showEventSynopsisModal = createSelector(
    selectAI,
    (ai => ai.showEventSynopsisModal),
);
