// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3 {
  padding: 20px 0 10px 0;
}
.label {
    display: block;
    font-weight: bold;
    text-indent: 8px;
    color: #656565;
  }

  .tabs {
    padding: 0px;  
  }

  th, td {
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center !important;
  }

  button {
    border: 1px solid #ccc;
    margin: 20px 0 20px 20px;
    text-align: center;
    justify-content: center;
  }
`, "",{"version":3,"sources":["webpack://./src/app/metrics/counting/counting.component.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;AACA;IACI,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,kCAAkC;EACpC;;EAEA;IACE,sBAAsB;IACtB,wBAAwB;IACxB,kBAAkB;IAClB,uBAAuB;EACzB","sourcesContent":["h3 {\r\n  padding: 20px 0 10px 0;\r\n}\r\n.label {\r\n    display: block;\r\n    font-weight: bold;\r\n    text-indent: 8px;\r\n    color: #656565;\r\n  }\r\n\r\n  .tabs {\r\n    padding: 0px;  \r\n  }\r\n\r\n  th, td {\r\n    text-align: center;\r\n  }\r\n\r\n  .container {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: center !important;\r\n  }\r\n\r\n  button {\r\n    border: 1px solid #ccc;\r\n    margin: 20px 0 20px 20px;\r\n    text-align: center;\r\n    justify-content: center;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
