
import {take} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { SiteService } from '../site/site.service';
import { AppState } from '../app.state';
import { PropertyMap } from '../property-map/property-map';
import { MarkerService } from '../markers/marker.service';
import { CLEAR_MOUSEOVER_EVENT, ADD_MOUSEOVER_EVENT, REMOVE_MOUSEOVER_EVENT } from '../events/event.reducer';
import { Site } from '../site/site';
import { ServiceRequestCameraMarker } from './service-request-camera-marker';
import { SERVICE_REQUEST_SET_DESCRIPTION, SERVICE_REQUEST_CLEAR, SERVICE_REQUEST_SET_SITEID } from '../requests/requests.reducer';
import { SERVICE_REQUEST_TOGGLE_CAMERA } from '../requests/request-toggle-camera-action';
import { RequestService } from '../requests/request.service'
import { UserService } from '../users/user.service';
import { ServiceRequest } from './service-request';
import { NotificationsService } from '../notifications/notifications.service';

@Component({
    selector: "app-service-request",
    templateUrl: "./service-request.component.html",
    styleUrls: ["./service-request.component.css"]
})
export class ServiceRequestComponent implements OnInit{
    siteLoading: Observable<boolean>;
    public siteSub;
    maps: PropertyMap[];
    map: PropertyMap;
    site: Site;
    public camerasSub;
    public selected;
    description: string;
    serviceRequest: Observable<ServiceRequest>;
    cameras: ServiceRequestCameraMarker[];
    constructor(public siteService: SiteService, public store: Store<AppState>, public markerService: MarkerService, public requestService: RequestService, public userService: UserService, public notificationsService: NotificationsService, public router: Router) {}
    ngOnInit() {
        this.siteLoading = this.siteService.getSiteLoading();
        this.store.dispatch({type: CLEAR_MOUSEOVER_EVENT});
        this.serviceRequest = this.store.select(s => s.serviceRequest)
        this.siteSub = this.siteService.getSite().subscribe(site => {
            this.serviceRequest.pipe(take(1)).subscribe(request => {
                if(request.siteID !== site.siteID) {
                    this.store.dispatch({type: SERVICE_REQUEST_CLEAR})
                }
            })
            this.store.dispatch({type: SERVICE_REQUEST_SET_SITEID, payload: site.siteID});
            this.store.dispatch({type: CLEAR_MOUSEOVER_EVENT});
            if(site) {
                this.maps = [];
                if(site.maps) {
                    site.maps.forEach(map => {
                        let newMap = new PropertyMap(map);
                        if(map.markers) {
                            newMap.markersLocal = this.markerService.createServiceRequestCameraMarkers(map.markers, newMap);
                        }
                        this.maps.push(newMap);
                    })
                    this.map = this.maps[0];
                } else {
                    this.map = new PropertyMap({})
                }
                this.site = site;
            }
        })

        this.camerasSub = this.store.select(s => s.serviceRequest.cameras).subscribe(cameras => {
            this.cameras = cameras;
            this.selected = {};
            if(this.cameras) {
                this.cameras.forEach(camera => this.selected[camera.id] = true);
            }
        });
    }
    select(camera) {
        this.store.dispatch({type: SERVICE_REQUEST_TOGGLE_CAMERA, payload: camera});
        this.map.draw();
    }
    remove(camera) {
        this.store.dispatch({type: SERVICE_REQUEST_TOGGLE_CAMERA, payload: camera});
        this.store.dispatch({type: REMOVE_MOUSEOVER_EVENT, payload: camera});
        this.map.draw();
    }
    enter(camera) {
        if(camera.map.id === this.map.id) {
            this.store.dispatch({type: ADD_MOUSEOVER_EVENT, payload: camera });
            this.map.draw();        
        }
    }
    leave(camera) {
        if(camera.map.id === this.map.id) {
            this.store.dispatch({type: REMOVE_MOUSEOVER_EVENT, payload: camera });
            this.map.draw();
        }
    }
    descriptionChange(description) {
        this.store.dispatch({type: SERVICE_REQUEST_SET_DESCRIPTION, payload: description})
    }
    submit() {
        let request;
        let siteID;
        this.store.select(s => s.serviceRequest).pipe(take(1)).subscribe(serviceRequest => request = serviceRequest);
        this.siteService.getSite().pipe(take(1)).subscribe(site => siteID = site.siteID);
        request.cameras = request.cameras.map(camera => {
            return {id: camera.camera, displayName: camera.displayName};
        })
        this.userService.getUser().pipe(take(1)).subscribe(user => request.userID = user.id);
        this.requestService.serviceRequest(request, siteID).subscribe(resp => {
            this.notificationsService.success("", "Request Sent")
            this.store.dispatch({type: SERVICE_REQUEST_CLEAR})
            this.router.navigate(["site", siteID, "dashboard"])
        }, error => this.notificationsService.error("", "Error Sending Request"));
    }
    ngOnDestroy() {
        if(this.siteSub) {
            this.siteSub.unsubscribe();
        }
        if(this.camerasSub) {
            this.camerasSub.unsubscribe();
        }
    }
}