import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapComponent } from './map.component';
import { MapContainerComponent } from './map-container.component';
import { MapTooltipComponent } from './map-tooltip.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        MapComponent,
        MapContainerComponent,
        MapTooltipComponent,
    ],
    exports: [
        MapComponent,
        MapContainerComponent,
        MapTooltipComponent,
    ],
})
export class MapModule {}