import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserService } from '../../users/user.service';
import { SiteService } from '../../site/site.service';
import { Site } from '../../site/site';
import { NotificationsService } from '../../notifications/notifications.service';
import { User } from 'app/users/user';
import { AnalyticsService } from 'app/analytics.service';

@Component({
    selector: 'app-user-creation',
    templateUrl: './user-creation.component.html',
    styleUrls: ['./user-creation.component.css'],
})
export class UserCreationComponent implements OnInit, OnDestroy {
    public site: Site;

    public currentUserID: number;

    private destroy$: Subject<void>;

    constructor(
        private userService: UserService,
        private notifications: NotificationsService,
        private siteService: SiteService, 
        private analyticsService: AnalyticsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.destroy$ = new Subject<void>();

        this.siteService.getSite().pipe(
            takeUntil(this.destroy$),
        ).subscribe(site => this.site = site);

        this.userService.getUser().pipe(
            takeUntil(this.destroy$),
        ).subscribe(user => this.currentUserID = user.id);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    save(user: User): void {
        user.sites = [{siteID: this.site.siteID, siteName: this.site.siteName}];
        user.singleSites = [{siteID: this.site.siteID, siteName: this.site.siteName}];
        this.userService.createUser(user).pipe(
            takeUntil(this.destroy$),
        ).subscribe(user => {
            this.notifications.success("", "User created");

            this.analyticsService.track("User Created", {
                userID: user.id,
                siteID: this.site.siteID,
                createdBy: this.currentUserID,
            });

            this.router.navigate(['site', this.site.siteID, 'user-management']);
        }, (error) => {
            this.notifications.error("", "Error creating user");
        })
    }

}