// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-lpr/alert-hits/alert-hits.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".grid-icon {\r\n    display: block;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
