import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ShortRequest } from '../short-request';
import { Sort } from 'app/events/event.reducer';
import { Site } from 'app/site/site';
import { Router } from '@angular/router';
import { Lookup } from 'app/lookup';
import { EventService } from 'app/events/event.service';
import { getName } from 'app/users/user';
import { NotificationsService } from 'app/notifications/notifications.service';

@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.css']
})
export class RequestListComponent implements OnInit {
  @Input('requests') requests: ShortRequest[];
  @Input('loading') loading: boolean;
  @Input('siteID') siteID: number;
  @Output('sortChange') sortChangeEmitter: EventEmitter<string> = new EventEmitter<string>();
  
  public statuses: Lookup[] = [];
  public activity: {[eventID: number]: boolean};
  constructor(
    private router: Router, 
    private eventService: EventService, 
    private notificationsService: NotificationsService,
  ) { }

  ngOnInit() {
    this.eventService.getStatuses().subscribe(statuses => this.statuses = statuses.filter(status => status.name !== "Deleted"));
    this.eventService.getRequestsActivity(this.siteID).subscribe(act => this.activity = act);
  }
  setSort(field: string) {
    this.sortChangeEmitter.emit(field);
  }
  gotoEvent(eventID) {
    this.router.navigate(["site", this.siteID, "events", eventID]);
  }
  getStatusName(id: number): string {
    let name = "";
    this.statuses.forEach(status => {
        if(status.id === id) {
            name = status.name;
        }
    })
    return name;
  }
  getName(user) {
    return getName(user);
  }
  star(request: ShortRequest) {

    request.cStarred = true;
    this.eventService.starEvent(request.eventID, this.siteID).subscribe(event => {

    }, e => {
        request.cStarred = false;
        this.notificationsService.error("", "Error starring event");
    })
  }
  unstar(request: ShortRequest) {
    request.cStarred = false;
    this.eventService.unstarEvent(request.eventID, this.siteID).subscribe(event => {

    }, e => {
        request.cStarred = true;
        this.notificationsService.error("", "Error unstarring event");
    })
  }  
}
