import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { PermissionsService } from "app/permissions.service";

@Injectable({
    providedIn: 'root'
})
export class CVSGuard  {
    constructor(
        private permissionsService: PermissionsService, 
        private router: Router,
    ) {}
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let siteID = +route.params['siteID'];
        if(!this.permissionsService.site(siteID, 'canManageCVS')) {
            this.router.navigate(['site', siteID, 'dashboard']);
        }

        return true;
    }
}