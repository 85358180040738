// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 992px) {
  .hide-medium {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .hide-small {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .hide-xsmall {
    display: none;
  }
}
.tableFixHead {
  margin-top: 10px;
  margin-bottom: 30px;
  overflow-y: auto;
  padding: 0px;
  max-height: 400px;
}

.tableFixHead thead th {
  background: #28303d;
  color: white;
  padding: 10px;
  text-align: center;
  position: sticky;
  top: 0;
}

.tr-clickable {
  cursor: pointer;
}

table {
  border-collapse: separate;
  border-spacing: 0px;
  min-width: 325px;
  width: 100%;
}

td {
  padding: 10px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/lpr/lpr-table-styles.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACI;IACI,aAAA;EADN;AACF;AAIA;EACI;IACI,aAAA;EAFN;AACF;AAKA;EACI;IACI,aAAA;EAHN;AACF;AAMA;EACI,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AAJJ;;AAOA;EACI,mBCfU;EDgBV,YAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,MAAA;AAJJ;;AAOA;EACI,eAAA;AAJJ;;AAOA;EACI,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;AAJJ;;AAOA;EACI,aAAA;EACA,kBAAA;AAJJ","sourcesContent":["@import \"../../variables.scss\";\r\n\r\n@media only screen and (max-width: 992px) {\r\n    .hide-medium{\r\n        display: none;\r\n    }\r\n}\r\n\r\n@media only screen and (max-width: 768px) {\r\n    .hide-small{\r\n        display: none;\r\n    }\r\n}\r\n\r\n@media only screen and (max-width: 576px) {\r\n    .hide-xsmall{\r\n        display: none;\r\n    }\r\n}\r\n\r\n.tableFixHead {\r\n    margin-top: 10px;\r\n    margin-bottom: 30px;\r\n    overflow-y: auto;\r\n    padding: 0px;\r\n    max-height: 400px;\r\n}\r\n\r\n.tableFixHead thead th {\r\n    background: $selected-bg;\r\n    color: white;\r\n    padding: 10px;\r\n    text-align: center; \r\n    position: sticky;\r\n    top: 0;\r\n}\r\n\r\n.tr-clickable {\r\n    cursor: pointer;\r\n}\r\n\r\ntable {\r\n    border-collapse: separate;\r\n    border-spacing: 0px;\r\n    min-width: 325px;\r\n    width: 100%;\r\n}\r\n\r\ntd {\r\n    padding: 10px;\r\n    text-align: center;\r\n}","$base-theme:Default;\r\n$skin-name:blue;\r\n$accent: #13688c;\r\n$info: #0066cc;\r\n$success: #37b400;\r\n$warning: #ffb400;\r\n$error: #db4240;\r\n$text-color: #43aaa3;\r\n$bg-color: #ffffff;\r\n$base-text: #003f59;\r\n$base-bg: #daecf4;\r\n$hovered-text: #003f59;\r\n$hovered-bg: #7bd2f6;\r\n$selected-text: #ffffff;\r\n$selected-bg: #28303d;\r\n$series-a: #314652;\r\n$series-b: #0098ee;\r\n$series-c: #7bd2f6;\r\n$series-d: #ffb800;\r\n$series-e: #ff8517;\r\n$series-f: #e34a00;\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
