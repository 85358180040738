// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k-dialog {
  width: 50%;
}

p.bold {
  font-weight: bold;
}

div.fullwidth {
  width: 100%;
  border: 1px solid black;
}

div.threewidth {
  width: 30%;
  border: 1px solid black;
}

div.sevenwidth {
  width: 70%;
  border: 1px solid black;
}

.cropped-plate-container {
  width: 100%;
  margin-top: 3px;
}

.td-cropped-image {
  width: 25%;
}

.td-plate {
  width: 75%;
  background-color: lightgray;
}

.large-plate {
  text-align: center;
  font-size: 24px;
}

.capture-details {
  width: 100%;
  margin-top: 3px;
  font-size: 14px;
  background-color: lightgray;
}

table#details td {
  padding: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/lpr-image-modal/lpr-image-modal.component.scss"],"names":[],"mappings":"AAEA;EACI,UAAA;AADJ;;AAIA;EACI,iBAAA;AADJ;;AAIA;EACI,WAAA;EACA,uBAAA;AADJ;;AAIA;EACI,UAAA;EACA,uBAAA;AADJ;;AAIA;EACI,UAAA;EACA,uBAAA;AADJ;;AAIA;EACI,WAAA;EACA,eAAA;AADJ;;AAIA;EACI,UAAA;AADJ;;AAIA;EACI,UAAA;EACA,2BAAA;AADJ;;AAIA;EACI,kBAAA;EACA,eAAA;AADJ;;AAIA;EACI,WAAA;EACA,eAAA;EACA,eAAA;EACA,2BAAA;AADJ;;AAIA;EACI,YAAA;AADJ","sourcesContent":["@import \"../../../variables.scss\";\r\n\r\n.k-dialog {\r\n    width: 50%;\r\n}\r\n\r\np.bold {\r\n    font-weight: bold;\r\n}\r\n\r\ndiv.fullwidth {\r\n    width: 100%;\r\n    border: 1px solid black;\r\n}\r\n\r\ndiv.threewidth {\r\n    width: 30%;\r\n    border: 1px solid black;\r\n}\r\n\r\ndiv.sevenwidth {\r\n    width: 70%;\r\n    border: 1px solid black;\r\n}\r\n\r\n.cropped-plate-container {\r\n    width: 100%;\r\n    margin-top: 3px;\r\n}\r\n\r\n.td-cropped-image {\r\n    width: 25%;\r\n}\r\n\r\n.td-plate {\r\n    width: 75%;\r\n    background-color: lightgray\r\n}\r\n\r\n.large-plate {\r\n    text-align: center;\r\n    font-size: 24px; \r\n}\r\n\r\n.capture-details {\r\n    width: 100%;\r\n    margin-top: 3px;\r\n    font-size: 14px;\r\n    background-color: lightgray;\r\n}\r\n\r\ntable#details td {\r\n    padding: 3px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
