// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag {
    border-radius: 10px;
    background-color: lightblue;
    padding: 5px;
    margin-right: 2px;
}

.seen {
    background-color: #dbdbdb;
    color: #5f6368;
}

.btn {
    background-color: #fff;
    border: 1px solid #ccc;
}

.btn:hover {
    background-color: #f5f5f5ee;
    border: 1px solid #000;
} `, "",{"version":3,"sources":["webpack://./src/app/metrics/historical/request-list/request-list.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;IAC3B,sBAAsB;AAC1B","sourcesContent":[".tag {\r\n    border-radius: 10px;\r\n    background-color: lightblue;\r\n    padding: 5px;\r\n    margin-right: 2px;\r\n}\r\n\r\n.seen {\r\n    background-color: #dbdbdb;\r\n    color: #5f6368;\r\n}\r\n\r\n.btn {\r\n    background-color: #fff;\r\n    border: 1px solid #ccc;\r\n}\r\n\r\n.btn:hover {\r\n    background-color: #f5f5f5ee;\r\n    border: 1px solid #000;\r\n} "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
