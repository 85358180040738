import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-development-sandbox",
  templateUrl: "./development-sandbox.component.html",
  styleUrls: ["./development-sandbox.component.css"],
})
export class DevelopmentSandboxComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor() {}

  public ngOnInit() {}

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
