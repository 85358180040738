
import {combineLatest, throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { AccessKeyService } from './access-key.service';
import { ServiceHelpersService } from '../service-helpers.service';
import { User } from './user';
import { UserType } from './user-type';
import { Logger } from '../logger/logger.service';
import { config } from '../app.config';
import { PermissionsTemplate } from '../permissions-template';
import { SET_USER, SET_TEMP_USERS, USER_STATES, USER_LOADED, USER_NOT_LOADED, USER_LOADING, SET_PERMISSIONS_TEMPLATES, SET_USER_TYPES, SET_USER_HARD_TYPES } from './user.reducer';
import { NotificationsService} from '../notifications/notifications.service';
import { catchError, map, takeWhile } from 'rxjs/operators';
import { AuthFacade } from '../auth/auth.facade';
import { UserSurveyData } from './user-survey-data';
import { UserLogSummary, UserUpdateLogEntry } from './user-log';

export interface hasId {
  id: number;
}

@Injectable()
export class UserService {
  public headers = {};
  public loaded = false;
  public tempLoaded = false;
  public typesLoaded = false;
  public hardTypesLoaded = false;
  public permissionsTemplatesLoaded = false;
  public key;
  public alive: boolean = true;

  authState$: Observable<{ isAuthenticated: boolean; isLoading: boolean; sessionCreated }>;

  constructor(public http: HttpClient, public logger: Logger, public store: Store<AppState>, public accessKeyService: AccessKeyService, public serviceHelpersService: ServiceHelpersService, private notifications: NotificationsService, private authFacade: AuthFacade) {
    this.authState$ = combineLatest([
      authFacade.isAuthenticated$,
      authFacade.isLoading$,
      authFacade.sessionCreated$
    ]).pipe(
      map(([isAuthenticated, isLoading, sessionCreated]) => ({
        isAuthenticated,
        isLoading,
        sessionCreated
      }))
    );

    this.authState$.pipe(takeWhile(() => this.alive)).subscribe(state => {

      if (state.sessionCreated) {
        this.accessKeyService.getKey().subscribe(key => {
          this.key = key;
          this.headers = { "accessKey": key };
        });
        this.getUserLoaded().subscribe(loaded => {
          if (loaded === USER_STATES.NOT_LOADED) {
            this.store.dispatch({ type: USER_LOADING, payload: {} });
            this.fetchUser().subscribe();
          }
        });

      }

    });

  }
  
  public getUser(): Observable<User> {
    return this.store.select(s => s.user);
  }

  public getOtherUser(id): Observable<User> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<User>(config.apiUrl + 'user/' + id, {headers: headers}).pipe(catchError(this.serviceHelpersService.handleError), map(user => new User(user)));
  }

  public getTempUsers(): Observable<User[]> {
    if(!this.tempLoaded) {
      this.fetchTempUsers().subscribe();
    }
    return this.store.select(s => s.tempUsers);
  }

  public getUserLoaded(): Observable<string> {
    return this.store.select(s => s.userLoaded);
  }

  public getPermissionsTemplates(): Observable<PermissionsTemplate[]> {
    if(!this.permissionsTemplatesLoaded) {
      this.fetchPermissionsTemplates().subscribe();
    }
    return this.store.select(s => s.permissionsTemplates);
  }

  public getUsers(): Observable<User[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<User[]>(config.apiUrl + 'users', { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError), map(users => users.map(user => new User(user))));
  }

  public getDeletedUsers(): Observable<User[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<User[]>(config.apiUrl + 'users/deleted', { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError), map(users => users.map(user => new User(user))));
  }

  public getUsersBySite(id: number): Observable<User[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<User[]>(config.apiUrl + 'site/' + id + '/users', { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError), map(users => users.map(user => new User(user))));
  }

  public getWtsEmployees(): Observable<User[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<User[]>(config.apiUrl + 'users/wtsemployee', { headers: headers }).pipe(map(users => users.map(user => new User(user)), catchError(this.serviceHelpersService.handleError)));
  }

  public getTypes(): Observable<UserType[]> {
    if(!this.typesLoaded) {
      this.fetchTypes().subscribe(types => {
      });
    }
    return this.store.select(s => s.userTypes);
  }
  public getHardTypes(): Observable<UserType[]> {
    if(!this.hardTypesLoaded) {
      this.fetchHardTypes().subscribe(types => {
        
      });
    }
    return this.store.select(s => s.userHardTypes);
  }

  public fetchHardTypes(): Observable<UserType[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<UserType[]>(config.apiUrl + 'users/types/hard', { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError), map(hardTypes => {
      this.store.dispatch({type: SET_USER_HARD_TYPES, payload: hardTypes});
      this.hardTypesLoaded = true;
      return hardTypes;
    }))
  }
  public fetchTypes(): Observable<UserType[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<UserType[]>(config.apiUrl + 'users/types', { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError), map(types => {
      this.store.dispatch({type: SET_USER_TYPES, payload: types});
      this.typesLoaded = true;
      return types
    }));
  }

  public fetchUser(): Observable<User> {
    return this.http.get<User>(config.apiUrl + 'user/current').pipe(catchError(this.handleErrorUserData), map( user => {
      user = new User(user);
      user.initial = false;
      this.store.dispatch({type: SET_USER, payload: user});
      this.store.dispatch({type: USER_LOADED, payload: {}});
      return user;
    }));
  }

  public fetchUserForLoader(): Observable<HttpResponse<User>> {
    return this.http.get<User>(config.apiUrl + 'user/current', { observe: 'response'});
  }

  public fetchTempUsers(salesID?: number): Observable<User[]> {
    let params = new HttpParams();
    if(salesID) {
      params = params.set('salesRep', salesID + '');
    }
    let headers = new HttpHeaders(this.headers);
    return this.http.get<User[]>(config.apiUrl + 'users/temp', { headers: headers, params: params }).pipe(catchError(this.serviceHelpersService.handleError), map( users => {
      this.store.dispatch({type: SET_TEMP_USERS, payload: users});
      this.tempLoaded = true;
      return users.map(u => new User(u));
    }));
  }

  public fetchPermissionsTemplates(): Observable<PermissionsTemplate[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<PermissionsTemplate[]>(config.apiUrl + 'permissions/templates', { headers: headers }).pipe( catchError(this.serviceHelpersService.handleError), map( templates => {
      this.store.dispatch({type: SET_PERMISSIONS_TEMPLATES, payload: templates});
      this.permissionsTemplatesLoaded = true;
      return templates;
    }));
  }

  public updateUser(changes: any): Observable<User> {
    let body = JSON.stringify(changes);
    let headers = new HttpHeaders(Object.assign({ 'Content-Type': 'application/json'}, this.headers ));
    return this.http.post<User>(config.apiUrl + 'user/' + changes.id, body, { headers: headers }).pipe(catchError(error => {
      if(error.status === 409) {
        this.notifications.error("", "A user with that name already exists")
      }
      return observableThrowError(error)
    }), catchError(this.serviceHelpersService.handleError), map(u => new User(u)));
  }

  public updateCurrentUser(changes: any) {
    let body = JSON.stringify(changes);
    let headers = new HttpHeaders(Object.assign({ 'Content-Type': 'application/json' }, this.headers ));
    let res = this.http.post<User>(config.apiUrl + 'user/current', body, { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError))
    return res.pipe(map( user => {
      this.store.dispatch({type: SET_USER, payload: user});
      this.loaded = true;
    }))
  }

  public createUser(user: any): Observable<any> {
    let body = JSON.stringify(user);
    let headers = new HttpHeaders(Object.assign({ 'Content-Type': 'application/json'}, this.headers ));
    return this.http.put<any>(config.apiUrl + 'users', body, { headers: headers }).pipe(catchError(error => {
      if(error.status === 409) {
        this.notifications.error("", "A user with that name already exists")
      }
      return observableThrowError(error)
    }), catchError(this.serviceHelpersService.handleError));
  }
  public handleErrorUserData = (error: HttpErrorResponse) => {
    let errMsg = error.message || 'Server error';
    this.logger.error(errMsg);
    this.store.dispatch({type: USER_NOT_LOADED, payload: {}});
    // window.location.href = config.loginUrl;
    this.authFacade.logout();
    return observableThrowError(errMsg);    
  }
  public clientRemoveUser(userID: number, siteID: number, note: string): Observable<any> {
    let headers = new HttpHeaders(Object.assign({'Content-Type': 'application/json'}, this.headers ));
    return this.http.post<any>(config.apiUrl + 'site/' + siteID + '/user/' + userID, {note: note}, { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError))
  }

  public getUserSurveyData(): Observable<UserSurveyData> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<UserSurveyData>(config.apiUrl + 'survey/user/data', { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError));
  }

  public getUserLogSummariesBySite(siteID: number): Observable<UserLogSummary[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<any>(config.apiUrl + 'site/' + siteID + '/users/log/summaries', { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError));
  }

  public getUserUpdateLog(siteID: number, userID: number): Observable<UserUpdateLogEntry[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<any>(config.apiUrl + 'site/' + siteID + '/user/' + userID + '/log/updates', { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError));
  }
}
