import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Vehicle } from './vehicle';

@Component({
    selector: 'app-request-vehicle',
    templateUrl: './vehicle.component.html',
    styleUrls: ['./vehicle.component.css'],
})
export class VehicleComponent {
    @Output('change') change = new EventEmitter<Vehicle>();
    @Input('number') number;
    @Input('vehicle') vehicle: Vehicle;
    @Output('remove') remove = new EventEmitter();
    public states: string[] = ['AL', 'AK', 'AZ', 'AR', 'CA','CO', 'CT', 'DE', 'FL', 'GA', 'HI',
     'ID', 'IL', 'IN', 'IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV', 'NH',
     'NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY']
     setState(state:string) {
         this.vehicle.state = state;
         this.update();
     }
     update() {
         this.vehicle.year = Math.floor(this.vehicle.year);
         this.change.emit(this.vehicle);
     }
     removeFunc() {
         this.remove.emit();
     }
}