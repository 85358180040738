// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

app-camera-rotater {
    height: calc(100dvh - 50px);
    width: 100%;
    display: block;

}

app-site-walk-control {
    height: 40px;
    width: 100%;
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/camera/portfolio-site-walk/portfolio-site-walk.component.css"],"names":[],"mappings":";;AAEA;IACI,2BAA2B;IAC3B,WAAW;IACX,cAAc;;AAElB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,cAAc;AAClB","sourcesContent":["\r\n\r\napp-camera-rotater {\r\n    height: calc(100dvh - 50px);\r\n    width: 100%;\r\n    display: block;\r\n\r\n}\r\n\r\napp-site-walk-control {\r\n    height: 40px;\r\n    width: 100%;\r\n    display: block;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
