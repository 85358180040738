import { User } from '../../../users/user';
import { OtherOfficer } from './other-officer/other-officer';

export class ContactInfo {
    user: User;
    otherUsers: User[];
    otherEmails: string;
    policeInvolved: boolean;
    reportNumber: string;
    officers: User[];
    otherOfficers: OtherOfficer[];
    valid() {
        let res = this.otherEmailsValid()
        return {valid: res.valid, errors: {otherEmails: res.errors}};
    }
    otherEmailsValid() {
        let valid = true;
        let errors = [];
        if(this.otherEmails) {

            let emails = this.otherEmails.trim().split(',');
            emails.forEach(email => {
                email = email.trim();
                if(!this.validEmail(email) && email) {
                    valid = false;
                    errors.push(email + " is not a vaild email address.");
                }
                if(email.length > 45) {
                    valid = false;
                    errors.push(email + " is too long.")
                }
            })
        }
        if(this.otherOfficers && this.otherOfficers.length > 0 && this.policeInvolved) {
            this.otherOfficers.forEach(officer => {
                if(!officer.valid().valid) {
                    valid = false;
                }
            })
        }
        return {valid: valid, errors: errors};
    }
    otherOfficersValid() {

    }
    validEmail(email) {
        let expr = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        return expr.test(email);
    }

}

//TODO: maybe create master email list when the request gets submitted?