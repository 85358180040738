// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selector-container {
    width: 300px;
}

h3 {
    text-align: center;
}

.btn {
    background-color: #fff;
    border-color: #ccc;
}

.btn:hover {
    background-color: #f5f5f5ee;
    border: 1px solid #000;
} 
`, "",{"version":3,"sources":["webpack://./src/app/admin/site-management/site-management.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,sBAAsB;AAC1B","sourcesContent":[".selector-container {\r\n    width: 300px;\r\n}\r\n\r\nh3 {\r\n    text-align: center;\r\n}\r\n\r\n.btn {\r\n    background-color: #fff;\r\n    border-color: #ccc;\r\n}\r\n\r\n.btn:hover {\r\n    background-color: #f5f5f5ee;\r\n    border: 1px solid #000;\r\n} \r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
