import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, concatMap, withLatestFrom, catchError } from 'rxjs/operators';
import { Store, select } from "@ngrx/store";
import { AppState } from "app/app.state";
import { of } from 'rxjs';
import { SiteGroupsService } from '../admin-site-groups.service';
import * as siteGroupsActions from './actions';
import { selectSiteGroups, selectEditGroup, selectEditSelectedSites, selectEditName } from './selectors';
import { NotificationsService } from '../../../notifications/notifications.service';
import { SiteListService } from "app/sites/site-list.service";
import { Lookup } from "app/lookup";
import { SiteGroupFull } from "../site-group";

@Injectable()
export class siteGroupsEffects {

    loadSiteGroups$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(siteGroupsActions.loadSiteGroups),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectSiteGroups))),
                )
            }),
            switchMap(([action, siteGroups]) => {
                this.store.dispatch(siteGroupsActions.setSiteGroupsLoading({ loading: true }));
                return this.siteGroupsService.getSiteGroups().pipe(
                    map(data => {
                        return siteGroupsActions.setSiteGroups({ siteGroups: data });
                    }),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading site groups");
                        return of(siteGroupsActions.setSiteGroupsLoading({ loading: false }));
                    }),
                )
            })
        )
    })

    createSiteGroup$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(siteGroupsActions.createSiteGroup),
            switchMap((action) => {
                 return this.siteGroupsService.createSiteGroup(action.siteGroup).pipe(
                    map(group => {
                        this.notificationsService.success("", "Success creating site group")
                        return siteGroupsActions.appendSiteGroup({siteGroup: group});
                    }),
                    catchError(() => {
                        this.notificationsService.error("", "Error creating site group")
                        return of(siteGroupsActions.noAction());
                    }),
                )
            })
        )
    })

    clickEditSiteGroup$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(siteGroupsActions.clickEditSiteGroup),
            switchMap((action) => {
                return this.siteGroupsService.getSiteGroup(action.siteGroupID).pipe(
                    map(group => {
                        return siteGroupsActions.setEditGroup({editGroup: group});
                    }),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading site group")
                        return of(siteGroupsActions.noAction());
                    }),
                )
            })
        )
    })
                      
   
    getSiteGroupSelectedForDelete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(siteGroupsActions.getSiteGroupSelectedForDelete),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectSiteGroups))),
                )
            }),
            switchMap(([action, siteGroups]) => {
                return this.siteGroupsService.getSiteGroup(action.id).pipe(
                    map(data => {
                        this.store.dispatch(siteGroupsActions.setShowDeleteSiteGroupModal({show: true}));
                        return siteGroupsActions.setSiteGroupSelectedForDelete({ group: data });
                    }),
                    catchError(() => {
                        this.notificationsService.error("", "Error retrieving site group");
                        return of(siteGroupsActions.noAction());
                    }),
                )
            })
        )
    })

    loadEditSites$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(siteGroupsActions.loadEditSites),
            switchMap((action) => {
                return this.siteListService.getSiteList().pipe(
                    map(sites => {
                        if(sites && sites.map) {
                            return sites.map(s => {
                                let look = new Lookup();
                                look.id = s.siteID;
                                look.name = s.siteName;
                                return look;
                            });
                        }
                        return [];
                    }),
                    map(sites => siteGroupsActions.setEditSites({sites: sites})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading sites")
                        return of(siteGroupsActions.noAction());
                    })
                )
            })
        )
    })                  

    deleteSiteGroup$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(siteGroupsActions.deleteSiteGroup),
            switchMap((action) => {
                 return this.siteGroupsService.deleteSiteGroup(action.id).pipe(
                    map(() => {
                        this.store.dispatch(siteGroupsActions.setShowDeleteSiteGroupModal({show: false}));
                        this.store.dispatch(siteGroupsActions.setSiteGroupSelectedForDelete({ group: null }));
                        this.notificationsService.success("", "Success deleting site group")
                        return siteGroupsActions.removeDeletedSiteGroup({id: action.id});
                    }),
                    catchError(() => {
                        this.notificationsService.error("", "Error deleting site group")
                        return of(siteGroupsActions.noAction());
                    }),
                )
            })
        )
    })

    editSave$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(siteGroupsActions.editSave),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(selectEditGroup))),
                    withLatestFrom(this.store.pipe(select(selectEditSelectedSites))),
                    withLatestFrom(this.store.pipe(select(selectEditName))),
                )
            }),
            switchMap(([[[action, group], sites], name]) => {
                let g = new SiteGroupFull();
                g.id = group.id;
                g.name = name;
                g.sites = sites;
                g.users = group.users;
                return this.siteGroupsService.updateSiteGroup(g).pipe(
                    map(group => {
                        this.notificationsService.success("", "Site Group Updated")
                        return siteGroupsActions.editSuccess({siteGroup: group})
                    }), 
                    catchError(() => {
                        this.notificationsService.error("","Failed to update site group");
                        return of(siteGroupsActions.editFailed());
                    })
                );
            }),
        )
    })

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private siteGroupsService: SiteGroupsService,
        private siteListService: SiteListService,
        private notificationsService: NotificationsService,
    ) { }
}