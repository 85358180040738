import { ByosSite } from "../byosSite";
import { PortfolioExtendedStorageComponent } from "../portfolio-extended-storage/portfolio-extended-storage.component";
import { State as GridState } from '@progress/kendo-data-query';
import { Storage } from "../byosSite";
import { ByosSort } from "../byos-sort";
import { Lookup } from "app/lookup";

export const byosKey = "byos";

export interface State { 
    portfolioExtendedStorage: PortfolioExtendedStorageComponent,
    byosGridState: GridState,
    byosUnlicensedGridState: GridState,
    textFilter: string,
    enabledOptions: string[],
    selectedEnabledOption: string,
    appBarWidth: number,
    sites: ByosSite[],
    filteredSites: ByosSite[],
    byosSort: ByosSort,
    showConfigureStorage: boolean,
    showChangeStorage: boolean,
    siteIDBeingConfigured: number, 
    vendors: Lookup[];
    buckets: string[];
    accounts: string[];
    unassignStorage: any;
    unlicensedSiteSelections: number[];
    showUnassignStorageDialog: boolean;
    showChangeStorageConfirmDialog: boolean;
    showInquiryConfirmation: boolean
}

export const initialState: State = {
    portfolioExtendedStorage: null,

    textFilter: "",
    enabledOptions: ["All", "Storage Enabled", "Storage Disabled"],
    selectedEnabledOption: "All",
    appBarWidth: 0,
    byosSort: undefined,
    sites: [],
    filteredSites: [],
    byosGridState:{ sort: [], skip: 0, take: 50 },
    byosUnlicensedGridState: { sort: [], skip: 0, take: 50 },
    showConfigureStorage: false,
    showChangeStorage: false,
    siteIDBeingConfigured: null,  
    vendors: [],
    buckets: [],
    accounts: [],
    unassignStorage: null,
    unlicensedSiteSelections: [],
    showUnassignStorageDialog: false,
    showChangeStorageConfirmDialog: false,
    showInquiryConfirmation: false
}

