import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { AccessKeyService } from '../users/access-key.service'
import {BucketEnabled, ByosBucketRequest, ByosSite, ByosSiteBucket, ByosBucketCreate} from './byosSite';
import { Observable } from 'rxjs';
import { config } from '../app.config';
import { ServiceHelpersService } from "app/service-helpers.service";
import { catchError, map } from 'rxjs/operators';
import { Lookup } from 'app/lookup';

@Injectable(
    {
        providedIn: 'root',
    
    }
)
export class ExtendedStorageService {
    public headers = {};

    constructor(public http: HttpClient, public store: Store<AppState>, public accessKeyService: AccessKeyService, public serviceHelpers: ServiceHelpersService) {
        this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key }));
    }

    getBYOSites(): Observable<ByosSite[]> {
        let headers = new HttpHeaders(this.headers);
        
        return this.http.get<ByosSite[]>("/api/byos/sites", { headers: headers }).pipe(catchError(this.serviceHelpers.handleError));
    }

    updateBYOSSiteBucket(siteID: number, bucketID: number): Observable<ByosSiteBucket> {
        let headers = new HttpHeaders(this.headers);
        let body = { bucketID: bucketID };
        return this.http.put<ByosSiteBucket>("/api/byos/sites/" + siteID + "/bucket", body, { headers: headers })
        .pipe(catchError(this.serviceHelpers.handleError));
    }

    BYOSBucket(bucket: ByosBucketRequest): Observable<string[]> {
        let headers = new HttpHeaders(this.headers);
       
        return this.http.post<string[]>("/api/byos/bucket", bucket, { headers: headers })
        .pipe(catchError(this.serviceHelpers.handleError));       

    }

    getVendors(): Observable<Lookup[]> {
        let headers = new HttpHeaders(this.headers);
       
        return this.http.get<Lookup[]>("/api/byos/vendors", { headers: headers })
        .pipe(catchError(this.serviceHelpers.handleError));
    }

    getBuckets(accountInfo: ByosBucketRequest): Observable<string[]> {
        let headers = new HttpHeaders(this.headers);
        
        return this.http.post<string[]>("/api/byos/buckets", accountInfo,  { headers: headers })
        .pipe(catchError(this.serviceHelpers.handleError));
    }

    addStorageAccount(account: ByosBucketCreate, siteID: number): Observable<ByosSiteBucket> {
        let headers = new HttpHeaders(this.headers);

        
        return this.http.post<ByosSiteBucket>(`/api/byos/sites/${siteID}/bucket`, account,  { headers: headers })
        .pipe(catchError(this.serviceHelpers.handleError));
    }

    unassignStorage(siteID: number): Observable<any> {
        let headers = new HttpHeaders(this.headers);

        return this.http.put<any>(`/api/byos/sites/${siteID}/bucket/unassign`,    { headers: headers })
        .pipe(catchError(this.serviceHelpers.handleError));
    }

    submitInquiry(sites: Lookup[]): Observable<any> {
        let headers = new HttpHeaders(this.headers);
        
        return this.http.post<any>(`/api/byos/inquiry`, sites,  { headers: headers })
        .pipe(catchError(this.serviceHelpers.handleError));
    }
}