import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromMessagesState from './state';

export const selectMessages = createFeatureSelector<fromMessagesState.State>(fromMessagesState.messagesKey);

export const selectUserMessages = createSelector(
    selectMessages,
    (messages => messages.userMessages),
);

export const selectUserNewMessagesCount = createSelector(
    selectMessages,
    (messages => {
        return messages.userMessages.filter(m => m.seen === false).length;
    })
);     





