import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromCameraState from "./store/state";
import { cameraReducer } from './store/reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CameraEffects } from './store/effects';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCameraState.CameraKey, cameraReducer),
    EffectsModule.forFeature([CameraEffects]),
  ]
})
export class CameraModule { }
