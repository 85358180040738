import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ServiceHelpersService } from '../../service-helpers.service';
import { Observable } from 'rxjs';
import { AccessKeyService } from '../../users/access-key.service';
import { map, catchError } from 'rxjs/operators';
import { SiteGroup, SiteGroupFull } from './site-group';

@Injectable({
  providedIn: 'root'
})
export class SiteGroupsService {
  public headers = {};

  constructor(
    private http: HttpClient,
    private helper: ServiceHelpersService,
    private accessKeyService: AccessKeyService,    
  ) {
    this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key}));
   }

   getSiteGroups(): Observable<SiteGroup[]> {   
    let headers = new HttpHeaders(this.headers);
    return this.http.get<SiteGroup[]>(`/rest/admin/sitegroups`, { headers: headers }).pipe(catchError(this.helper.handleError), map(siteGroups => {
      if (siteGroups && siteGroups.map) {
        return siteGroups.map(group => new SiteGroup(group))
      }
      return siteGroups;
    }));
  }

  getSiteGroup(id: number): Observable<SiteGroupFull> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<SiteGroupFull>(`/rest/admin/sitegroup/${id}`, {headers: headers}).pipe(catchError(this.helper.handleError), map(group => {
      return new SiteGroupFull(group);
    }))
  }


  createSiteGroup(siteGroup: SiteGroup): Observable<SiteGroup> {
    let headers = new HttpHeaders(this.headers);
    return this.http.post<SiteGroup>(`/rest/admin/sitegroups`, siteGroup, { headers: headers }).pipe(catchError(this.helper.handleError));
  }

  updateSiteGroup(siteGroup: SiteGroupFull): Observable<SiteGroup> {
    let headers = new HttpHeaders(this.headers);
    return this.http.put<SiteGroup>(`/rest/admin/sitegroup/${siteGroup.id}`, siteGroup, {headers: headers}).pipe(catchError(this.helper.handleError), map(group => {
      return new SiteGroup(group);
    }))
  }

  deleteSiteGroup(id: number) {
    let headers = new HttpHeaders(this.headers);
    return this.http.delete(`/rest/admin/sitegroup/${id}`, {headers: headers}).pipe(catchError(this.helper.handleError));
  }

  getSiteGroupsForUser(id: number): Observable<SiteGroupFull[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<SiteGroupFull[]>(`/rest/admin/sitegroups/user/${id}`, {headers: headers}).pipe(catchError(this.helper.handleError), map(userSiteGroups => {
      if (userSiteGroups && userSiteGroups.map) {
        return userSiteGroups.map(group => new SiteGroupFull(group))
      }
      return userSiteGroups;
    }))
  }

}