
import {first, map, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../users/user.service';
import { PermissionsService } from '../permissions.service';


@Injectable()
export class MessageGuard  {
    constructor(public userService: UserService, public router: Router, public permissions: PermissionsService) {}
    canActivate () {
        return this.userService.getUser().pipe(filter(user => !user.initial),map(user => {
            if(!this.permissions.user('canMessage')) {
                this.router.navigate(['']);
            }
            return this.permissions.user('canMessage');
        }),first(),);
    }
}