import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { AddCamsReducer } from "./reducer";
import { AddCamsEffects } from "./effects";

import * as fromAddCamsState from "./state";


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAddCamsState.AddCamsKey, AddCamsReducer),
    EffectsModule.forFeature([AddCamsEffects])
  ],
  providers: [AddCamsEffects]
})
export class AddCamsStoreModule {}
