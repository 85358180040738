
import {take} from 'rxjs/operators';
import { CameraMarker } from '../camera/camera-marker';
import { SERVICE_REQUEST_TOGGLE_CAMERA } from '../requests/request-toggle-camera-action';

//TODO: Should probaly be refactored to inherit from RequestCameraMarker instead somehow, as its mostly the same code
export class ServiceRequestCameraMarker extends CameraMarker{
    clicked() {
        this.store.dispatch({type: SERVICE_REQUEST_TOGGLE_CAMERA, payload: this})
    }
    draw() {
        this.store.select(s => s.serviceRequest.cameras).pipe(take(1)).subscribe(cameras => {
            let selected = false;
            if(cameras) {
                cameras.forEach(camera => {
                    if(camera.id === this.id) {
                        selected = true
                    }
                })
            }
            if(!selected) {
                super.draw();
            } else {
                this.shapeObj.draw(this.getFill(), "green");
            }
        })
    }
}