import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CameraGridSize } from '../camera-grid-size';

@Component({
  selector: 'app-site-walk-control',
  templateUrl: './site-walk-control.component.html',
  styleUrls: ['./site-walk-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteWalkControlComponent {
  @Input() cameraGridSize: CameraGridSize = { small: true, medium: false, large: false };
  @Input() progress: number = 0;
  @Input() startCameraIndex: number = 0;
  @Input() endCameraIndex: number = 0;
  @Input() totalCameras: number = 0;
  @Input('status') portfolioSiteWalkStatus: "play" | "pause" | "fast" = "pause";
  @Input() theme: "light" | "dark" = "light";
  @Input() isIdle: boolean = false;
  @Input() loading: boolean = false;

  @Output() cameraGridSizeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickPlay: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickPause: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickFast: EventEmitter<any> = new EventEmitter<any>();
  @Output("next") nextEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("previous") previousEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("themeLight") themeLightEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("themeDark") themeDarkEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("closeNext") closeNextEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("closeCurrent") closeCurrentEmitter: EventEmitter<any> = new EventEmitter<any>();



  onCameraSizeButtonChange(event: string) {
    this.cameraGridSizeChange.emit(event);
  }
  onPlay() {
    this.clickPlay.emit();
  }
  onPause() {
    this.clickPause.emit();
  }
  onFast() {
    this.clickFast.emit();
  }
  onNext() {
    if (this.loading) {
      return;
    }
    this.nextEmitter.emit();
  }
  onPrevious() {
    if (this.loading) {
      return;
    }
    this.previousEmitter.emit();
  }
  onThemeLight() {
    this.themeLightEmitter.emit();
  }
  onThemeDark() {
    this.themeDarkEmitter.emit();
  }
  onCloseNext() {
    this.closeNextEmitter.emit();
  }
  onCloseCurrent() {
    this.closeCurrentEmitter.emit();
  }
}
