import { Layout } from '../layout';
import { GridSize } from '../gridsize';
import { Camera } from '../../camera/camera';
import { Server } from '../server';
import { CameraAuth } from 'app/camera/camera-auth';


export const CVSKey = "cvs"

export interface State {
    layouts: Layout[],
    cvsSiteID: number,
    selectedLayout: Layout,
    gridSizes: GridSize[],
    cameras: Camera[],
    showCVSLayouts: boolean,
    layoutHasChanges: boolean,
    showUnsavedChangesDialog: boolean,
    layoutForAfterConfirm: Layout,
    showDeleteLayoutDialog: boolean,
    showAutoGenerateDialog: boolean,
    standaloneSessions: number[],
}

export const initialState: State = {
    layouts: [],
    cvsSiteID: 0,
    selectedLayout: null,
    gridSizes: [],
    cameras: [],
    showCVSLayouts: false,
    layoutHasChanges: false,
    showUnsavedChangesDialog: false,
    layoutForAfterConfirm: null,
    showDeleteLayoutDialog: false,
    showAutoGenerateDialog: false,
    standaloneSessions: [],
}