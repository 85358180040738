import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SiteGroup, SiteGroupFull } from '../site-group';
import { Lookup } from 'app/lookup';



@Component({
  selector: 'admin-site-group-edit-form',
  templateUrl: './site-group-edit-form.component.html',
  styleUrls: ['./site-group-edit-form.component.css']
})
export class SiteGroupEditFormComponent implements OnInit {
  @Input('siteGroup') siteGroup: SiteGroupFull;
  @Input('selectedSites') selectedSites: Lookup[];
  @Input('unselectedSites') unselectedSites: Lookup[];
  @Input('name') name: string;
  @Input('siteFilter') siteFilter: string;
  @Input('saving') saving: boolean;
  @Input('dupName') dupName: boolean;
  
  @Output('selectSite') selectSiteEmitter = new EventEmitter<Lookup>();
  @Output('unselectSite') unselectSiteEmitter = new EventEmitter<Lookup>();
  @Output('save') saveEmitter = new EventEmitter<any>();
  @Output('nameChange') nameChangeEmitter = new EventEmitter<string>();
  @Output('filterChange') filterChangeEmitter = new EventEmitter<string>();

  public sitesNotification: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  selectSite(site: Lookup) {
    this.selectSiteEmitter.emit(site);
  }
  unselectSite(site: Lookup) {    
    if ((this.selectedSites.length - 1) < 1) {
      this.sitesNotification = true;
    } else {    
    this.unselectSiteEmitter.emit(site);
    }
  }
  save() {
    this.saveEmitter.emit();
  }
  nameChange(name: string) {
    this.nameChangeEmitter.emit(name);
  }
  filterChange(filter: string) {
    this.filterChangeEmitter.emit(filter);
  }
  closeSitesNotification() {
    this.sitesNotification = false;
  }
}
