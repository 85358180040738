import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { AppState } from '../../app.state';
import { NEW_REQUEST, REQUEST_SET_CONTACT_INFO_USER, RESET_REQUEST_STAGES, REQUEST_SET_SITEID } from '../requests.reducer';
import { UserService } from '../../users/user.service';
import { SiteService } from '../../site/site.service';

@Component({
    templateUrl: './new-request.component.html',
    styleUrls: ['./new-request.component.css'],
})
export class NewRequestComponent implements OnInit {
    constructor(public router: Router, public store: Store<AppState>, public userService: UserService, public siteService: SiteService) { }

    ngOnInit() {
        this.store.dispatch({ type: NEW_REQUEST });
        this.store.dispatch({ type: RESET_REQUEST_STAGES });

        this.userService.getUser().pipe(take(1)).subscribe(user =>
            this.store.dispatch({ type: REQUEST_SET_CONTACT_INFO_USER, payload: user })
        );

        this.siteService.getSite().pipe(take(1)).subscribe(site => {
            this.store.dispatch({ type: REQUEST_SET_SITEID, payload: site.siteID });
            this.router.navigate(["site", site.siteID, "request", "new-request", "when"]);
        });
    }
}
