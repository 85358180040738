
import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { SiteListService } from '../../sites/site-list.service';
import { AdminService } from '../admin.service';
import { Lookup } from '../../lookup';
import { UserSiteTypeLogin } from './user-site-type-login';
import { UserService } from 'app/users/user.service';
import { UserType } from 'app/users/user-type';

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.css'],
})
export class AdminDashboardComponent implements OnInit {
    public chartData: ChartData[] = [];
    public chartError: string = null;
    public eoMetrics: EOMetrics[] = [];
    public sortedEOMetrics: EOMetrics[] = [];
    public eoError: string = null;
    public eoMetricsSort: Sort = {field: null, desc: true };
    public sortedRequests: RequestMetrics[] = [];
    public requestsError: string = null;
    public requestMetrics: RequestMetrics[] = [];
    public requestsSort: Sort = {field: null, desc: true };
    public activityMetrics: ActivityMetrics[] = [];
    public sortedActivity: ActivityMetrics[] = [];
    public activityError: string = null;
    public activitySort: Sort = {field: null, desc: true};
    public recentUsers: RecentUser[] = [];
    public recentUsersError: string = null;
    public sitesNoLogins: SitesNoLogins[] = [];
    public sitesNoLoginsError: string = null;
    public siteUserLogins: UserSiteTypeLogin[] = [];
    public filteredSiteUserLogins: UserSiteTypeLogin[] = [];
    public sortedSiteUserLogins: UserSiteTypeLogin[] = [];
    public siteUserSort: Sort = {field: null, desc: true};
    public siteUserLoginsError: string = null;
    public months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    public start = new Date();
    public end = new Date();
    public salesmen: Observable<Lookup[]>;
    public selectedSalesmanID: number = null;
    public userTypes: Observable<UserType[]>;
    public selectedTypeID: number = 0;
    constructor(public router: Router, public siteListService: SiteListService, public adminService: AdminService, public userService: UserService) {}
    gotoSite(id: number) {
        this.router.navigate(['admin','site', id, 'metrics']);
    }
    gotoUser(id: number) {
        this.router.navigate(['admin', 'users', 'edit', id]);
    }
    separate(last: boolean) {
        return last ? "" : ",  "; // Two Spaces For Visual Affect.
    }
    toggleEO(field: string) {
        this.toggleSort(field, this.eoMetricsSort);
        this.sortedEOMetrics = this.sortMetrics(this.eoMetrics, this.eoMetricsSort);
    }
    toggleRequests(field: string) {
        this.toggleSort(field, this.requestsSort);
        this.sortedRequests = this.sortMetrics(this.requestMetrics, this.requestsSort);
    }
    toggleActivity(field: string) {
        this.toggleSort(field, this.activitySort);
        this.sortedActivity = this.sortMetrics(this.activityMetrics, this.activitySort);
    }
    toggleUserSite(field: string) {
        this.toggleSort(field, this.siteUserSort);
        this.sortedSiteUserLogins = this.sortMetrics(this.filteredSiteUserLogins, this.siteUserSort);
    }
    toggleSort(field: string, sort: Sort) {
        if(field !== sort.field) {
            sort.field = field;
            sort.desc = true;
            return;
        }
        if(sort.desc) {
            sort.desc = false;
        } else {
            sort.field = null;
        }
    }
    selectType(typeID: number) {
        console.log(typeID)
        this.selectedTypeID = typeID;
        if( typeID === 0 || typeID === undefined ) {
            this.filteredSiteUserLogins = this.siteUserLogins;
        } else {
            this.filteredSiteUserLogins = this.siteUserLogins.filter(l => l.typeID === typeID);
        }
        this.sortedSiteUserLogins = this.sortMetrics(this.filteredSiteUserLogins, this.siteUserSort);
    }
    sortMetrics(array: any[], sort: Sort): any[] {
        let resp = [...array];
        if(sort.field === null) {
            return resp;
        }
        resp.sort( (a, b) => {
            let first = a[sort.field];
            let second = b[sort.field];
            let r = 0;
            if(first && first.toLowerCase) {
                first = first.toLowerCase();
            }
            if(second && second.toLowerCase) {
                second = second.toLowerCase();
            }
            if(first > second) {
                r = 1;
            }
            if(first < second) {
                r = -1;
            }
            if(!sort.desc) {
                r = r * -1;
            }
            return r;
        })
        return resp;
    }
    ngOnInit() {
        this.start.setFullYear(this.start.getFullYear() - 1);
        this.salesmen = this.adminService.getSalesmen();
        this.userTypes = this.userService.getTypes().pipe(map(t => [{name: "All", id: 0, description: ""}, ...t]));
        this.loadData();

    }
    loadData() {
        this.sortedEOMetrics = null;
        this.eoError = null;
        this.adminService.getEOMetrics({whenStart: this.start, whenEnd: this.end, salesRep: this.selectedSalesmanID}).subscribe(metrics => {
            this.eoMetrics = metrics;
            this.sortedEOMetrics = [...metrics];
        })
        this.sortedRequests = null;
        this.requestsError = null;
        this.adminService.getSitesRequestsMetrics({whenStart: this.start, whenEnd: this.end, salesRep: this.selectedSalesmanID}).subscribe(metrics => {
            this.requestMetrics = metrics;
            this.sortedRequests = [...metrics];
        }, e => this.requestsError = "Error loading requests.")
        this.sortedActivity = null;
        this.activityError = null;
        this.adminService.getSitesLoginMetrics({whenStart: this.start, whenEnd: this.end, salesRep: this.selectedSalesmanID}).subscribe(metrics => {
            this.activityMetrics = metrics;
            this.sortedActivity = [...metrics];
        }, e => this.activityError = "Error loading site activity.")
        this.recentUsers = null;
        this.recentUsersError = null;
        this.adminService.getNewestUsersMetrics({salesRep: this.selectedSalesmanID}).subscribe(metrics => {
            this.recentUsers = this.sortMetrics(metrics, {field: 'userName', desc: true});
        }, e => this.recentUsersError = "Error loading recent users.")
        this.sitesNoLogins = null;
        this.sitesNoLoginsError = null;
        this.adminService.getSitesNoLoginMetrics({salesRep: this.selectedSalesmanID}).subscribe(metrics => {
            this.sitesNoLogins = this.sortMetrics(metrics, {field: 'displayName', desc: true});
        }, e => this.sitesNoLoginsError = "Error loading sites with no logins.")
        this.siteUserLogins = null;
        this.filteredSiteUserLogins = null;
        this.sortedSiteUserLogins = null;
        this.siteUserLoginsError = null;
        this.adminService.getUserSiteLoginsByType({whenStart: this.start, whenEnd: this.end, salesRep: this.selectedSalesmanID}).subscribe(metrics => {
            this.siteUserLogins = metrics;
            this.filteredSiteUserLogins = [...metrics];
            this.sortedSiteUserLogins = [...metrics];
        }, e => this.siteUserLoginsError = "Error loading site user logins.");
        this.chartData = null;
        this.chartError = null;
        this.adminService.getUserLoginMetrics({salesRep: this.selectedSalesmanID}).subscribe(data => {
            this.chartData = this.formatData(data);
        }, e => this.chartError = "Error loading login data.");
    }
    selectSales(id: number) {
        this.selectedSalesmanID = id;
        this.loadData();
    }
    unselectSales() {
        this.selectedSalesmanID = null;
        this.loadData();
    }
    //TODO: this should probably be moved into a service
    formatData(data) {
        let formatedData = [];
        let dataMap = {};
        let years = [];
        let months = this.months;
        data.forEach(datum => {
            if(dataMap[datum.year]) {
                dataMap[datum.year].series[datum.month-1] = {name: months[datum.month -1], value: datum.amount};
            } else {
                dataMap[datum.year] = {name: datum.year, series: []};
                dataMap[datum.year].series[datum.month-1] = {name: months[datum.month - 1], value: datum.amount};
                years.push(datum.year)
            }
        })
        years.forEach(year => {
            // dataMap[year].sort((a, b) => {
            //     return months.indexOf(a.name) - months.indexOf(b.name);
            // })
            for(let i = 0; i < months.length; i++) {
                if(!dataMap[year].series[i]) {
                    dataMap[year].series[i] = {name: months[i], value: 0};
                }
            }
            formatedData.push({name: year + "", series: dataMap[year].series})
        })
        console.log(formatedData)
        return formatedData;
    }
}

export interface ChartData {
    name: string, 
    series: {name: string, value: number}[]
}
export interface Sort {
    field: string,
    desc: boolean,
}

export interface EOMetrics {
    siteID: number, 
    siteName: string, 
    percentage: number
}

export interface RequestMetrics {
    siteID: number, 
    siteName: string, 
    eventAmount: number, 
    hoursRequested: number
}

export interface ActivityMetrics {
    siteID: number, 
    siteName: string, 
    loginAmount: number
}

export interface RecentUser {
    userName: string, 
    sites: {siteName: string}[]
}

export interface SitesNoLogins { 
    displayName?: string, 
    siteName: string
}