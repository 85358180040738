import { Injectable } from '@angular/core';
import { AdminService } from '../admin.service';
import * as AdminActions from './actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';


@Injectable()
export class AdminEffects {
    constructor(private actions$: Actions, private adminService: AdminService) {}

    fetchSites$ = createEffect(() => this.actions$.pipe(
        ofType(AdminActions.fetchSites),
        switchMap(() => this.adminService.fetchSites().pipe(
            map(sites => AdminActions.fetchSitesSuccess({sites})),
            catchError(() => of(AdminActions.fetchSitesFailure()))
        ))
    ));
}