// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3 {
    text-align: center;
    margin-top: 20px;
}

.selected {
    background-color: gainsboro;
}

.view-button {
    background-color: #fff;
    border-color: #ccc;
}

.view-button:hover {
    background-color: #f5f5f5ee;
    border: 1px solid #000;
} 

label {
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-events/admin-events.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["h3 {\r\n    text-align: center;\r\n    margin-top: 20px;\r\n}\r\n\r\n.selected {\r\n    background-color: gainsboro;\r\n}\r\n\r\n.view-button {\r\n    background-color: #fff;\r\n    border-color: #ccc;\r\n}\r\n\r\n.view-button:hover {\r\n    background-color: #f5f5f5ee;\r\n    border: 1px solid #000;\r\n} \r\n\r\nlabel {\r\n    font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
