export enum FeatureType {
  Motion = 1,
  AutoRequest = 2,
  FullRequest = 3,
  MobileApp = 4,
  RawRequest = 5,
  MobileWeb = 6,
  LPR = 7,
  CVS = 8,
}
