// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submit-button {
    font-size: 1.5em;
    background-color: #43aaa3;
    margin-right: 50px;
}
.reset-button {
    font-size: 1.5em;
    margin-right: 10px;
    background-color: #FF8517
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-permissions/admin-permissions.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB;AACJ","sourcesContent":[".submit-button {\r\n    font-size: 1.5em;\r\n    background-color: #43aaa3;\r\n    margin-right: 50px;\r\n}\r\n.reset-button {\r\n    font-size: 1.5em;\r\n    margin-right: 10px;\r\n    background-color: #FF8517\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
