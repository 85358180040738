
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import { START_REQUEST_CAMERAS, SET_REQUEST_CURRENT_CAMERAS } from '../../requests.reducer';

@Injectable()
export class CamerasGuard  {
    constructor(public store: Store<AppState>) {}
    canActivate() {
        this.store.dispatch({ type: START_REQUEST_CAMERAS });
        this.store.dispatch({ type: SET_REQUEST_CURRENT_CAMERAS });
        return true;
    }
}