import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { map, switchMap, withLatestFrom, concatMap, catchError } from 'rxjs/operators';
import { AppState } from "app/app.state";
import { Store, select } from "@ngrx/store";
import { of } from "rxjs";
import * as CameraActions from './actions';
import * as CameraSelectors from './selectors';
import { NotificationsService } from "app/notifications/notifications.service";
import { CameraService } from "../camera.service";
import { AnalyticsService } from 'app/analytics.service';
import { UserService } from '../../users/user.service';
import { Router } from "@angular/router";
import { HttpParams } from "@angular/common/http";


@Injectable()
export class CameraEffects {

    getPortfolioCameras$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CameraActions.getPortfolioCameras),
            switchMap((action) => {
                return this.cameraService.getPortfolioCameras().pipe(
                    map(data => CameraActions.getPortfolioCamerasSuccess({ cameras: data })),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading portfolio cameras")
                        return of(CameraActions.getPortfolioCamerasFailure());
                    }),
                )
            })
        )
    });

    getPortfolioCameraStreams$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CameraActions.portfolioLiveOn, CameraActions.getPortfolioCameraStreams),
            switchMap((action) => {
                return this.cameraService.getPortfolioCameraStreams().pipe(
                    map(data => CameraActions.setPortfolioCameraStreams({cameraStreams: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading camera streams")
                        return of(CameraActions.getPortfolioCameraStreamsError());
                    }),
                )
            })
        )
    });

    clickPortfolioLive$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CameraActions.clickPortfolioLive),
            concatLatestFrom(() => this.store.pipe(select(CameraSelectors.portfolioIsLive))),
            concatMap(([_, isLive]) => {
                if (isLive) {
                    return of(CameraActions.portfolioLiveOff());
                } else {
                    return of(CameraActions.portfolioLiveOn());
                }
            })
        )
    });
    clickCVS$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CameraActions.clickWalk),
            concatLatestFrom(() => this.store.pipe(select(CameraSelectors.cameraFilters))),
            switchMap(([action, filters]) => {
                console.log("Filters", filters);
                // let params = new HttpParams().appendAll({
                //     siteFilter: filters.siteFilter,
                //     intentFilter: filters.intentFilter,
                //     nameFilter: filters.nameFilter,
                //     sortOption: filters.selectedSortOption,
                //     cameraGridSize: filters.cameraGridSize.small ? 'small' : filters.cameraGridSize.medium ? 'medium' : 'large',
                //     // groupByOption: filters.selectedGroupByOption,
                //   });
                let tree = this.router.createUrlTree(['/portfolio/site-walk'], { queryParams: {
                    siteFilter: filters.siteFilter,
                    intentFilter: filters.intentFilter,
                    nameFilter: filters.nameFilter,
                    sortOption: filters.selectedSortOption,
                    cameraGridSize: filters.cameraGridSize.small ? 'small' : filters.cameraGridSize.medium ? 'medium' : 'large',
                    // groupByOption: filters.selectedGroupByOption,
                  } })
                console.log("Tree", tree);
                let url = this.router.serializeUrl(tree);
                console.log("URL", url);
                window.open(url, '_blank', 'noopener,noreferrer');
                return of(CameraActions.noAction());
            }),
        )
    });

    getCameraIntents$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CameraActions.getPortfolioCameras),
            switchMap((action) => {
                return this.cameraService.getCameraIntents().pipe(
                    map(data => CameraActions.getCameraIntentsSuccess({ intents: data })),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading camera intents")
                        return of(CameraActions.getCameraIntentsFailure());
                    }),
                )
            })
        )
    });
    

    freshpaintSelectCameraSize$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CameraActions.setCameraGridSize),
            concatLatestFrom(() => [
                this.store.select(CameraSelectors.cameraGridSize),
            ]),
            switchMap(([action, cameraGridSize]) => {
                let size = '';
                if (cameraGridSize.small) {
                    size = 'small';
                } else if (cameraGridSize.medium) {
                    size = 'medium';
                } else {
                    size = 'large';
                }
                this.analyticsService.track("User Selected Camera Grid Size", {
                    sizeSelected: size
                });
                return of(CameraActions.noAction()).pipe(
                    catchError(() => {
                        return of(CameraActions.noAction());
                    }),
                )
            })
        )
    });


    freshpaintCollapseButtonSelected$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CameraActions.toggleCameraFooterToggle),
            concatLatestFrom(() => [
                this.store.select(CameraSelectors.cameraFooterCollapse),
            ]),
            switchMap(([action, cameraFooterCollapse]) => {
                this.analyticsService.track("User Toggled Camera Footer", {
                    footerCollapsed: cameraFooterCollapse
                });
                return of(CameraActions.noAction()).pipe(
                    catchError(() => {
                        return of(CameraActions.noAction());
                    }),
                )
            })
        )
    })

constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private store: Store<AppState>,
    private cameraService: CameraService,
    private userService: UserService,
    private router: Router,
    private analyticsService: AnalyticsService
) { }
}