import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { style, animate, transition, trigger } from "@angular/animations";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";

import { User, getName } from "../../users/user";
import { WtsEvent } from "../event";
import { EventService } from "../event.service";
import { Site } from "../../site/site";
import { SiteService } from "../../site/site.service";
import { NotificationsService } from "../../notifications/notifications.service";
import * as moment from "moment";
import { Request } from "../request";
import { EventNote } from "../event-note";
import { ModalComponent } from "../../shared/modal/modal.component";
import { PermissionsService } from "../../permissions.service";
import { Store } from "@ngrx/store";
import { AppState } from "app/app.state";
import { CLEAR_MOUSEOVER_EVENT } from "../event.reducer";
import { MarkerService } from "app/markers/marker.service";
import { Map } from "../../map/map";
import { Subscription } from "rxjs";
import { Summary } from "../summary";
import { FeatureType } from "app/site-package/models";
import { takeWhile } from "rxjs/operators";
import { UserActivityService } from "app/user-activity/user-activity.service";
import { Actions } from "app/user-activity/user-activity";
import { Logger } from "app/logger/logger.service";

@Component({
  templateUrl: "./event-detail.component.html",
  styleUrls: ["./event-detail.component.css"],
  animations: [
    trigger("fadeInOut", [
      transition("void => *", [
        style({ /*opacity:0*/ transform: "scale(0)" }),
        animate(500, style({ /*opacity:1*/ transform: "scale(1)" })),
      ]),
      transition("* => void", [animate(500, style({ /*opacity:0*/ transform: "scale(0)" }))]),
    ]),
  ],
})
export class EventDetailComponent implements OnInit, OnDestroy {
  public routeSub: Subscription;
  public eventSub: Subscription;
  public event: WtsEvent;
  public statuses = [];
  public site: Site;
  public siteSub: Subscription;
  public adding: boolean;
  public newNote: string = "";
  public visible = {};
  public modalImageURL: string = "";
  public modalIndex: number = -1;
  public map: Map;
  public maps: Map[];
  public drawnOn: number[] = [];
  public cameras: string[] = [];
  public shareEvent: WtsEvent;
  public shareSummary: Summary;
  public shareShow: boolean = false;
  public camMarkerIDs: number[] = [];
  public features: FeatureType[] = [FeatureType.FullRequest];
  public isAutoRequest: boolean = false;
  public eventID: number;
  public invalidFileType: boolean = false;
  public vidTime: number | null = null;
  public summary: Summary[];
  private alive:boolean = true;
  private shared: {[link: string]: boolean} = {};
  private playedVideos: {[file: string]: boolean} = {};
  private loadedImages: {[img: string]:boolean} = {};
  private downloaded: {[file:string]: boolean} = {};
  @ViewChild("imageModal", { static: true }) imageModal: ModalComponent;

  @ViewChild("noteButton") noteButton: ElementRef;

  constructor(
    public eventService: EventService,
    public route: ActivatedRoute,
    public siteService: SiteService,
    public notificationsService: NotificationsService,
    public logger: Logger,
    private sanitizer: DomSanitizer,
    public permissionsService: PermissionsService,
    private store: Store<AppState>,
    private markerService: MarkerService,
    private userActivityService: UserActivityService,
  ) {}

  ngOnInit() {
    this.route.queryParamMap.pipe(takeWhile(() => this.alive)).subscribe(params => {
      if(params.get("video") !== null) {
        this.vidTime = +params.get("video");
      }
    })
    this.routeSub = this.route.params.subscribe((params) => {
      if (this.eventSub) {
        this.eventSub.unsubscribe();
      }
      
      this.eventSub = this.eventService.getEvent(+params["eventID"]).subscribe((event) => {
        this.event = event;
        this.isAutoRequest = event.requests[0].isAutoRequest;
        this.eventID = event.id;
        this.prepareMaps();
        this.setCameras();
        if (this.event.notes) {
          //this should probably be done in the event constructor;
          for (let i = 0; i < this.event.notes.length; i += 1) {
            this.event.notes[i] = new EventNote(this.event.notes[i]);
          }
          this.event.notes.sort((a, b) => b.time.getTime() - a.time.getTime());
        }
        if (this.event.summaries) {
          for (let i = 0; i < this.event.summaries.length; i += 1) {
            this.event.summaries[i].text = this.sanitizer.bypassSecurityTrustHtml(
              this.event.summaries[i].text as string,
            );
          }
        }
      });
    });

    this.eventService.getStatuses().subscribe((statuses) => (this.statuses = statuses));
    this.siteSub = this.siteService.getSite().subscribe((site) => {
      this.site = site;
      this.prepareMaps();
      this.setCameras();
    });

    this.logger.trackAction(4, this.site.siteID).subscribe();
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.eventSub) {
      this.eventSub.unsubscribe();
    }
    this.alive = false;
    this.userActivityService.endVideoTracking();
  }

  getUser(event: WtsEvent, author: number): User {
    let user = event.users.filter((user) => user.id === author)[0];
    return user; //not sure what to do if the user isn't found
  }

  getStatusName(id: number): string {
    let name = "";
    if (this.statuses) {
      this.statuses.forEach((status) => {
        if (status.id === id) {
          name = status.name;
        }
      });
    }
    return name;
  }

  getStatus(event: WtsEvent) {
    if (event && event.status && event.status.length) {
      return event.status[0].statusCode;
    }
    return 0;
  }

  createNote(text: string) {
    let note = { text: text };
    this.eventService.addNote(this.event.id, note).subscribe(
      (resp) => {
        this.event = resp;
        this.adding = false;
        this.newNote = "";
      },
      (err) => this.notificationsService.error("", "Error adding note"),
    );
  }

  getName(user) {
    return getName(user);
  }

  getSubmitted(request: Request) {
    return moment(request.requested).fromNow();
  }

  modalHidden() {
    this.modalImageURL = "";
    this.modalIndex = -1;
  }

  loadImage(url: string, i: number) {
    this.modalImageURL = url;
    this.modalIndex = i;
    this.imageModal.showModal();
  }

  onFileChanged(event) {
    let fileList = event.target.files;
    if (fileList.length > 0) {
      let file = fileList[0];
      let formData = new FormData();
      if ((file.type != "image/png") && (file.type != "image/jpeg")) {
        console.log("The file type is: ", file.type)
        this.invalidFileType = true;
      } else {
        formData.append("picture", file, file.name);
        this.eventService.addImage(this.event.id, formData).subscribe((res) => {
          this.eventService.getEvent(this.event.id).subscribe((res) => {
            this.event.images = res.images;
          });
        });
      }
    }
  }

  removeImage() {
    let nameArr = this.modalImageURL.split("/");
    let fileName = nameArr[nameArr.length - 1];
    this.eventService.removeImage(this.event.id, fileName).subscribe((res) => {
      console.log("Removing Image!");
      this.event["images"].splice(this.modalIndex, 1);
      this.imageModal.hideModal();
    });
  }

  prepareMaps() {
    let site = this.site;
    let event = this.event;
    this.store.dispatch({ type: CLEAR_MOUSEOVER_EVENT });
    if (site && event) {
      this.maps = [];
      this.drawnOn = [];
      if (site.maps) {
        site.maps.forEach((map) => {
          let newMap = new Map(map);
          if (map.markers) {
            newMap.markersLocal = this.markerService
              .createMarkers(map.markers, newMap)
              .filter((m) => m.type !== "event" && m.type !== "camera");
            if (event.requests && event.requests[0]) {
              let camMarkerIDs = [];
              if (event.requests[0].cameras) {
              camMarkerIDs = event.requests[0].cameras.map((c) => c.id);
              }
              newMap.markersLocal = [
                ...newMap.markersLocal,
                ...this.markerService.createDrawingMarkers(event.requests[0].mapPoints, newMap),
                ...newMap.markers
                  .filter((m) => m.type === "camera" && m.data)
                  .map((m) =>
                    camMarkerIDs.indexOf(m.id) === -1
                      ? this.markerService.createCameraMarker(m, newMap)
                      : this.markerService.createPurpleCameraMarker(m, newMap),
                  ),
              ];
            }
            let drawing = false;
            newMap.markersLocal.forEach((marker) => {
              if (marker.type === "drawing") {
                drawing = true;
              }
            });
            if (drawing) {
              this.drawnOn.push(map.id);
            }
          }
          this.maps.push(newMap);
        });
        this.map = this.maps[0];
      } else {
        //this is probably bad...
        this.map = new Map({});
      }
    }
  }

  setMap(event) {
    this.map = event;
  }

  getDisplayFromMarker(id) {
    let displayName;
    if (this.site && this.site.maps) {
      this.site.maps.forEach((map) => {
        if (map.markers) {
          map.markers
            .filter((marker) => marker.type === "camera")
            .forEach((marker) => {
              if (id === marker.id) {
                displayName = marker.displayName;
              }
            });
        }
      });
    }

    return displayName;
  }

  setCameras() {
    this.cameras = [];
    this.camMarkerIDs = [];
    if (this.event && this.event.requests && this.event.requests[0]) {
      let request = this.event.requests[0];
      if (request.cameras) {
        request.cameras.forEach((cam) => {
          this.cameras.push(this.getDisplayFromMarker(cam.id));
          this.camMarkerIDs.push(cam.id);
        });
      }
    }
    if (this.maps) {
      for (let map of this.maps) {
        if (map.markersLocal) {
          let found = false;
          map.markersLocal
            .map((m) => m.id)
            .forEach((mID) => {
              if (this.camMarkerIDs.indexOf(mID) !== -1) {
                found = true;
              }
            });
          if (found && this.drawnOn.indexOf(map.id) === -1) {
            this.drawnOn.push(map.id);
          }
        }
      }
    }
  }

  startNote() {
    this.adding = true;
    this.noteButton.nativeElement.scrollIntoView();
  }

  closeShare() {
    this.shareShow = false;
    this.shareEvent = null;
    this.shareSummary = null;
  }

  share(event: WtsEvent, summary: Summary) {
    this.shareEvent = event;
    this.shareSummary = summary;
    this.shareShow = true;
  }

  closeInvalidFileTypeModal() {
    this.invalidFileType = false;
  }

  playVideo(file: string) {
    if(this.playedVideos[file]) {
      return;
    }
    this.userActivityService.startVideoTracking({
      eventID: this.event.id,
      action: Actions.PlayVideo,
      siteID: this.event.site.siteID,
      meta: {video: file},
    })
    //this.playedVideos[file] = true;
  }
  stopVideo() {
    this.userActivityService.endVideoTracking();
  }
  imageLoaded(img: string) {
    if(this.loadedImages[img]) {
      return;
    }
    this.userActivityService.logActivity({
      eventID: this.event.id,
      action: Actions.ViewImage,
      siteID: this.event.site.siteID,
      meta: {img: img},
    });
    this.loadedImages[img] = true;
  }
  linkCopy(link: string) {
    if(this.shared[link]) {
      return;
    }
    this.userActivityService.logActivity({
      eventID: this.event.id,
      action: Actions.CopyLink,
      siteID: this.event.site.siteID,
      meta: {link: link},
    });
    this.shared[link] = true;
  }
  download(file: string) {
    if(this.downloaded[file]) {
      return
    }
    this.userActivityService.logActivity({
      eventID: this.event.id,
      action: Actions.Download,
      siteID: this.event.site.siteID,
      meta: {file: file},
    });
    this.downloaded[file] = true;
  }
}
