import { Component, Output, EventEmitter, Input } from "@angular/core";
import SitePackage from "../../../site-package/models/site-package";
import Feature from "../../../site-package/models/feature";

@Component({
  selector: "app-site-package-edit-list",
  templateUrl: "./site-package-edit-list.component.html",
  styleUrls: ["./site-package-edit-list.component.css"],
})
export class SitePackageEditListComponent {
  @Output() public editSitePackage: EventEmitter<SitePackage> = new EventEmitter<SitePackage>();

  @Output() public createSitePackage: EventEmitter<Event> = new EventEmitter<Event>();

  @Output() public deleteSitePackage: EventEmitter<number> = new EventEmitter<number>();

  @Input() public allFeatures: Feature[];

  constructor() {}

  public onEditSitePackage(sitePackage: SitePackage) {
    this.editSitePackage.emit(sitePackage);
  }

  public onCreateSitePackage() {
    this.createSitePackage.emit();
  }

  public onDeleteSitePackage(sitePackage: SitePackage) {
    this.deleteSitePackage.emit(sitePackage.id);
  }
}
