import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  NgZone,
  OnDestroy,
  ChangeDetectorRef
} from "@angular/core";
import { Camera } from "app/camera/camera";
import { StreamService } from "../stream.service";

declare var Hls;

@Component({
  selector: "app-hls-player",
  templateUrl: "./hls-player.component.html",
  styleUrls: ["./hls-player.component.css"]
})
export class HlsPlayerComponent implements OnInit, OnDestroy {
  @ViewChild("videoPlayer", { static: true }) videoPlayer: ElementRef;

  @Input("camera") set setCamera(value: Camera) {
    if (!this.camera || this.camera.id !== value.id) {
      this.camera = value;
      this.hlsURL = this.camera.stream.hls.url;
    }
  }

  public width: number = 1280;
  public height: number = 720;
  public ready: boolean = false;

  public loaderStateText: string = "Securely connecting to your community";

  private camera: Camera;
  private hlsURL: string;

  private hls: any;

  private error: {
    retryAttempts: number;
    retryDelay: number;
    retryDelayActive: boolean;
    maxRetryAttemptsBeforeConnReset: number;
    maxRetryAttempts: number;
    recoveredMediaError: boolean;
    lastFragBufferTime: number;
    bufferCheckTimerSet: boolean;
    bufferStalled: boolean;
    fps: number;
    lastErrorDetail: string;
  } = {
    retryAttempts: 0,
    retryDelay: 5000,
    retryDelayActive: false,
    maxRetryAttemptsBeforeConnReset: 3,
    maxRetryAttempts: 6,
    recoveredMediaError: false,
    lastFragBufferTime: null,
    bufferCheckTimerSet: false,
    bufferStalled: false,
    fps: 0,
    lastErrorDetail: "",
  }

  private alive: boolean = true;

  constructor(
    private ss: StreamService, 
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.establishStream();
  }

  establishStream() {
    if (!this.camera) {
      this.camera = new Camera();
    }
    this.ss.createHLSStream(this.camera.id).subscribe((response: any) => {
      setTimeout(() => {
        this.playHLS();
      }, 4000);
    });
  }

  playHLS() {
    if (Hls.isSupported()) {
      console.log("hls supported");
      this.hls = new Hls({
        debug: false,
        liveDurationInfinity: true,
        xhrSetup: this.xhrOverride
      });

      this.startVideoLifecycle();
    } else {
      console.log("hls not supported");
    }
  }

  startVideoLifecycle() {
    this.subscribeEvents();

    this.hls.loadSource(this.hlsURL);
    this.hls.attachMedia(this.videoPlayer.nativeElement);
  }

  subscribeEvents() {
    /* BUFFER */
    this.hls.on(Hls.Events.BUFFER_CREATED, this.bufferCreated.bind(this));
    this.hls.on(Hls.Events.BUFFER_RESET, this.bufferReset.bind(this));
    this.hls.on(Hls.Events.BUFFER_APPENDING, this.bufferAppending.bind(this));
    this.hls.on(Hls.Events.BUFFER_EOS, this.bufferEOS.bind(this));

    /* MANIFEST */
    this.hls.on(Hls.Events.MANIFEST_PARSED, this.manifestParsed.bind(this));
    this.hls.on(Hls.Events.MANIFEST_LOADING, this.manifestLoading.bind(this));
    this.hls.on(Hls.Events.MANIFEST_LOADED, this.manifestLoaded.bind(this));

    /* MEDIA */
    this.hls.on(Hls.Events.MEDIA_ATTACHED, this.mediaAttached.bind(this));
    this.hls.on(Hls.Events.MEDIA_DETACHED, this.mediaDetached.bind(this));

    /* ERROR */
    this.hls.on(Hls.Events.ERROR, this.handleErrors.bind(this));

    /* FRAG */
    this.hls.on(Hls.Events.FRAG_PARSED, this.fragParsed.bind(this));
    this.hls.on(Hls.Events.FRAG_PARSING_DATA, this.fragParsingData.bind(this));
    this.hls.on(Hls.Events.FRAG_BUFFERED, this.fragBuffered.bind(this));
  }

  handleErrors(event: Event, data: any) {
    let errorType = data.type;
    let errorDetails = data.details;
    let errorIsFatal = data.fatal;

    this.error.lastErrorDetail = errorDetails;

    switch(errorDetails) {
      case Hls.ErrorDetails.BUFFER_STALLED_ERROR:
        this.handleBufferStallError(data);
        break;
      case Hls.ErrorDetails.FRAG_PARSING_ERROR:
        this.handleFragParseError(data);
        break;
      case Hls.ErrorDetails.BUFFER_SEEK_OVER_HOLE:
        this.handleBufferSeekOverHole(data);
        break;
      default:
        switch (errorType) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            this.handleNetworkError(data);
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.log("unhandled media error");
            console.log(data);
            break;
          default:
            console.log("unhandled error");
            console.log(data);
            if (errorIsFatal) {
              console.log("fatal unhandled error, destroying");
              this.hls.destroy();
            }
            break;
        }
    }
  }

  recoverMediaError() {
    console.log("recovering media error");
    this.error.recoveredMediaError = true;
    this.hls.swapAudioCodec();
    this.hls.recoverMediaError();
  }

  handleNetworkError(data: any) {
    console.log("handling network error");

    setTimeout(() => {
      this.hls.loadSource(this.hlsURL);
      this.hls.startLoad();
    }, 1000);
  }

  handleBufferSeekOverHole(data: any) {
    console.log("buffer seeked over hole");
  }

  handleBufferStallError(data: any) {
    console.log("buffer stalled");
    this.error.bufferStalled = true;
    setTimeout(() => {
      if (this.error.bufferStalled) {
        this.retryStream();
      }
    }, 5000);
  }

  handleFragParseError(data: any) {
    console.log("frag parse error");
    if (data.reason == "no audio/video samples found") {
    }
  }

  mediaAttached(event: Event, data: any) {
    console.log("media attached");
  }

  mediaDetached(event: Event, data: any) {
    console.log("media detached");
  }

  bufferCreated(event: Event, data: any) {
    console.log("video buffer created");
  }

  bufferEOS(event: Event, data: any) {
    console.log("buffer EOS");
  }

  bufferReset(event: Event, data: any) {
    console.log("video buffer reset");
    if (this.error.recoveredMediaError) {
      this.error.recoveredMediaError = false;
      this.playVideo();
    }
  }

  bufferAppending(event: Event, data: any) {
    console.log("appending buffer");
  }

  startBufferStatusChecker() {
    setInterval(() => {
      console.log("checking status");
      let currTime = new Date().getTime();
      let secondDiff = (currTime - this.error.lastFragBufferTime) / 1000;
      console.log("last frag buffer was " + secondDiff + " seconds ago");
      if (secondDiff >= 7) {
        this.retryStream();
      }
    }, 5000);
  }

  retryStream() {
    if (!this.alive) {
      return;
    }

    if (this.error.retryDelayActive) {
      return;
    }

    if (this.error.retryAttempts >= this.error.maxRetryAttempts) {
      console.log("max failed attempts");
      this.showLoader("Unable to load stream");

      let error = "max retries: " + JSON.stringify(this.error);
      this.ss.sendError(this.camera.id, error, "HLS").subscribe((response) => {});
    }

    if (this.error.retryAttempts >= this.error.maxRetryAttemptsBeforeConnReset) {
      this.error.retryDelayActive = true;
      setTimeout(() => {
        this.error.retryDelayActive = false;
      }, this.error.retryDelay);

      this.showLoader("Re-establishing stream");

      this.recoverMediaError();

      this.error.retryAttempts++;
    } else {
      this.showLoader("Re-establishing connection");

      setTimeout(() => {
        this.establishStream();
      }, 2000);
    }
  }

  manifestParsed(event: Event, data: any) {
    console.log("manifest parsed");
    this.playVideo();
  }

  bufferAppended(event: Event, data: any) {
    console.log("buffer appended");
  }

  fragLoaded(event: Event, data: any) {
    console.log("frag loaded");
  }

  manifestLoading(event: Event, data: any) {
    console.log("manifest loading");
    this.showLoader("Initiating full resolution live stream");
  }

  manifestLoaded(event: Event, data: any) {
    console.log("manifest loaded");
  }

  fragParsed(event: Event, data: any) {
    console.log("frag parsed");
  }

  fragParsingData(event: Event, data: any) {
    console.log("frag parsing data");
    if (data.type === "video") {
      let fps = data.nb / (data.endPTS - data.startPTS);
      console.log("fps: " + fps);
      if (fps !== Infinity) {
        this.error.fps = fps;
      }
    }
  }

  fragBuffered(event: Event, data: any) {
    console.log("frag buffered");
    this.error.bufferStalled = false;
    if (!this.ready && this.error.fps > 0) {
      this.showLoader("Commencing live video");
      setTimeout(() => {
        if (!this.alive) {
          return;
        }

        this.hideLoader();
      }, 10000);
    }

    this.error.lastFragBufferTime = new Date().getTime();
    if (!this.error.bufferCheckTimerSet) {
      this.error.bufferCheckTimerSet = true;
      this.startBufferStatusChecker();
    }
    
    this.error.retryAttempts = 0;
  }

  playVideo() {
    console.log("playing video");
    this.videoPlayer.nativeElement.play();
  }

  showLoader(text: string) {
    this.videoPlayer.nativeElement.removeAttribute("controls");
    this.loaderStateText = text;
    this.ready = false;
    this.changeDetectorRef.detectChanges();
  }

  hideLoader() {
    this.videoPlayer.nativeElement.setAttribute("controls", "controls");
    this.ready = true;
    this.changeDetectorRef.detectChanges();
  }

  xhrOverride(xhr, url) {
    url += "?=" + Math.random() * 1000 + 1;
    xhr.timeout = 20000;
    xhr.open("GET", url, true);
  }

  ngOnDestroy() {
    this.alive = false;

    console.log("dispose hls");
    if(this.hls) {
      this.hls.destroy();
    }
  }
}
