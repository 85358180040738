import { User } from "app/users/user";

export class ShortRequest {
    eventID: number;
    description: string;
    statusCode: number;
    outcome: number;
    clientTitle: string;
    hasFiles: boolean;
    requester: User;
    requested: Date;
    hasActivity: boolean;
    category: string;
    tags: string[] = [];
    cStarred: boolean;
    starred: boolean;

    constructor(config?: any) {
        if(config) {
            Object.assign(this, config);
            if(config.requested) {
                this.requested = new Date(config.requested);
            }
        }

        if(!this.category) {
            this.category = "Not Assigned";
        }
    }
}