import { SitePermissions, DocumentPermissions } from "app/permissions";

export class Updates {
    updates: Update[];
}

export class Update {
    userID: number;
    userName: string;
    action: string;
    permission: { site: SitePermissions, document: DocumentPermissions };
    sitesExcluded?: number[] = [];
    sitesIncluded?: number[] = [];
}