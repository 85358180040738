import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { map, switchMap, catchError } from 'rxjs/operators';
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import * as ExtendedStorageActions from './actions';
import * as extendedStorageSelectors from './selectors';
import { NotificationsService } from "app/notifications/notifications.service";
import { ExtendedStorageService } from "../extended-storage.service";
import { AnalyticsService } from 'app/analytics.service';
import { AppState } from "app/app.state";
import { Storage } from "../byosSite";
import { Lookup } from "app/lookup";

@Injectable()
export class ExtendedStorageEffects {

    getBYOSites$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                ExtendedStorageActions.getBYOSites,
                ExtendedStorageActions.unassignStorageSuccess               
            ),
            switchMap(() => {
                return this.extendedStorageService.getBYOSites().pipe(
                    map(data => ExtendedStorageActions.getBYOSitesSuccess({ sites: data })),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading BYO sites")
                        return of(ExtendedStorageActions.getBYOSitesFailure());
                    }),
                )
            })
        )
    }
    );

    getBYOSitesAfterCreate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                ExtendedStorageActions.addStorageAccountSuccess
            ),
            concatLatestFrom(() => [
                this.store.select(extendedStorageSelectors.siteIDBeingConfigured),
            ]),
            switchMap(([action, siteIDBeingConfigured]) => {
                return this.extendedStorageService.getBYOSites().pipe(
                    map(data => {           
                        let updatedSite = data.find(site => site.id === siteIDBeingConfigured)
                        let newSiteStorage: Storage = {
                         property: updatedSite.displayName,
                            accountName: updatedSite.byosBucket.accountName,
                            provider: updatedSite.byosBucket.vendor.name,
                            description: updatedSite.byosBucket.description,
                            bucketID: updatedSite.bucketID,
                            bucketName: updatedSite.byosBucket.bucketName,
                            siteID: siteIDBeingConfigured
                        }
                        this.store.dispatch(ExtendedStorageActions.getBYOSitesSuccess({ sites: data }));
                        return ExtendedStorageActions.configureSiteStorage({ siteStorage: newSiteStorage });
                    }),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading BYO sites")
                        return of(ExtendedStorageActions.getBYOSitesFailure());
                    }),
                )
            })
        )
    }
    );
    
    updateBYOSSiteBucket$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                ExtendedStorageActions.updateBYOSSiteBucket
            ),
            concatLatestFrom(() => [
                this.store.select(extendedStorageSelectors.siteStorageBeingConfigured),
            ]),
            switchMap(([action, siteStorage]) => {
                return this.extendedStorageService.updateBYOSSiteBucket(siteStorage.siteID, action.account.id).pipe(
                    map(data => ExtendedStorageActions.updateBYOSSiteBucketSuccess({bucket: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error updating Site bucket")
                        return of(ExtendedStorageActions.updateBYOSSiteBucketFailure());
                    }),
                )
            })
        )
    }
);

    updateBYOSVendor$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                ExtendedStorageActions.getVendors
            ),
            concatLatestFrom(() => [
                this.store.select(extendedStorageSelectors.siteStorageBeingConfigured),
            ]),
            switchMap(([action]) => {
                return this.extendedStorageService.getVendors().pipe(
                    map(data => ExtendedStorageActions.getVendorsSuccess({vendors: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error getting vendors")
                        return of(ExtendedStorageActions.getVendorsFailure());
                    }),
                )
            })

        )
    }
);

    selectBuckets$ = createEffect(() => {
         
        return this.actions$.pipe(
            ofType(
                ExtendedStorageActions.getBuckets
            ),
            switchMap((action) => {
                return this.extendedStorageService.getBuckets(action.accountInfo).pipe(
                    map(data => ExtendedStorageActions.getBucketsSuccess({ buckets: data})),
                    catchError(() => {
                        this.notificationsService.error("", "Error getting buckets")
                        return of(ExtendedStorageActions.getBucketsFailure());
                    }),
                )
            })
        )
    }
);


    addStorageAccount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                ExtendedStorageActions.addStorageAccount
            ),
            concatLatestFrom(() => [
                this.store.select(extendedStorageSelectors.siteStorageBeingConfigured),
            ]),

            switchMap(([action, siteStorage] ) => {
                return this.extendedStorageService.addStorageAccount(action.account, siteStorage.siteID).pipe(
                    map(data => ExtendedStorageActions.addStorageAccountSuccess({account: data })),
                    catchError(() => {
                        this.notificationsService.error("", "Error adding storage account")
                        return of(ExtendedStorageActions.getBYOSitesFailure());
                    }),
                )
            })
        )
    }
);

    unassignStorage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                ExtendedStorageActions.unassignStorage
            ),
            concatLatestFrom(() => [
                this.store.select(extendedStorageSelectors.siteStorageBeingConfigured),
            ]),
            switchMap(([action, siteStorageBeingConfigured]) => {
                return this.extendedStorageService.unassignStorage(siteStorageBeingConfigured.siteID).pipe(
                    map(data => ExtendedStorageActions.unassignStorageSuccess()),
                    catchError(() => {
                        this.notificationsService.error("", "Error getting Unassign Storage")
                        return of(ExtendedStorageActions.unassignStorageFailure());
                    }),
                )
            })

        )
    }
    );

    submitInquiry$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                ExtendedStorageActions.submitInquiry
            ),
            concatLatestFrom(() => [
                this.store.select(extendedStorageSelectors.sitesUnlicensed),
                this.store.select(extendedStorageSelectors.unlicensedSiteSelections),
            ]),
            switchMap(([action, sites, selectedSitesIds]) => {
                let selectedSites: Lookup[] = [];
                sites.map(site => {
                    if (selectedSitesIds.includes(site.siteID)) {
                        selectedSites.push({ id: site.siteID, name: site.property });
                    }
                });                
                return this.extendedStorageService.submitInquiry(selectedSites).pipe(
                    map(() => {
                        this.notificationsService.success("", "Inquiry submitted successfully");                        
                        return ExtendedStorageActions.submitInquirySuccess({sites: selectedSites});
                    }),
                    catchError(() => {
                        this.notificationsService.error("", "Error submitting inquiry")
                        return of(ExtendedStorageActions.submitInquiryFailure());
                    }),
                )
            })
        )
    }
    );

    freshpaintByosInquiry$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExtendedStorageActions.submitInquirySuccess),
             switchMap((action) => {
                this.analyticsService.track("User Submitted Archival Storage Inquiry", {
                    sites: action.sites
                });
                return of(ExtendedStorageActions.noAction()).pipe(
                    catchError(() => {
                        return of(ExtendedStorageActions.noAction());
                    }),
                )
            })
        )
    })

    constructor(
        private actions$: Actions,
        private extendedStorageService: ExtendedStorageService,
        private notificationsService: NotificationsService,
        private store: Store<AppState>,
        private analyticsService: AnalyticsService

    ) { }

}