import "hammerjs";

import { AuthModule } from './auth/auth.module';
import { AuthInterceptor } from './auth/auth.interceptor';
import {  HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClientModule } from '@angular/common/http';

//import { MatSidenavModule } from "@angular/material/sidenav";
import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ModalModule } from "ngx-bootstrap/modal";

import { SharedModule } from "./shared/shared.module";

import { routing, appRoutingProviders } from "./app.routing";
import { getInitialState, appReducer } from "./app.state";

import { AdminModule } from './admin/admin.module';

import { DashboardGuard } from "./dashboard/dashboard-guard.service";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { PortfolioManagementComponent } from "./sidebar/portfolio-management/portfolio-management.component";

import { RequestsModule } from "./requests/requests.module";
import { AddressModule } from "./address/address.module";
import { SitePackageModule } from "./site-package";
import { AppComponent } from "./app.component";

import { AddressComponent } from "./address/address.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";

import { AppGuard } from "./app-guard.service";

import { Logger } from "./logger/logger.service";

import { ServiceHelpersService } from "./service-helpers.service";

import { MapModule } from "./map/map.module";
import { MarkerService } from "./markers/marker.service";
import { MarkerEventService } from "./markers/marker-event.service";

import { UserCreationComponent } from "./client-account-management/user-creation/user-creation.component";
import { UserFormComponent } from "./client-account-management/user-form/user-form.component";
import { ClientAccountManagementComponent } from "./client-account-management/client-account-management.component";
import { ClientAccountManagementGuard } from "./client-account-management/client-account-management-guard.service";
import { UserUpdateLogComponent } from "./client-account-management/user-update-log/user-update-log.component";
import { UserManagementGridComponent } from "./client-account-management/user-management-grid/user-management-grid.component";

import { NotificationsService } from "./notifications/notifications.service";

import { KeyboardService } from "./keyboard.service";

import { UserService } from "./users/user.service";
import { FirstLoginComponent } from "./first-login/first-login.component";
import { FirstLoginFormComponent } from "./first-login/first-login-form/first-login-form.component";
import { UserLoaderGuard } from "./users/user-loader-guard.service";
import { UserAgentService } from "./user-agent.service";
import { AccessKeyService } from "./users/access-key.service";
import { EditUserComponent } from "./edit-user/edit-user.component";

import { LightMapComponent } from "./light-map/light-map.component";
import { LightMapGuard } from "./light-map/light-map-guard.service";

import { MessageService } from "./messages/message.service";
import { MessagesComponent } from "./messages/messages.component";
import { MessagesTableComponent } from "./messages/messages-table/messages-table.component";
import { MessageGuard } from "./messages/message-guard.service";
import { MessageSiteGuard } from "./messages/message-site-guard.service";
import { NewMessagesComponent } from "./messages/new-messages/new-messages.component";
import { MessagesModule } from "./messages/messages.module";

import { PermissionsService } from "./permissions.service";

import { DocumentComponent } from "./document/document.component";
import { DocumentService } from "./document/document.service";
import { DocumentGuard } from "./document/document-guard.service";

import { EventComponent } from "./events/event.component";
import { EventService } from "./events/event.service";
import { EventGuard } from "./events/event-guard.service";
import { EventDetailComponent } from "./events/event-detail/event-detail.component";
import { NoteComponent } from "./events/event-detail/note/note.component";
import { StatusComponent } from "./events/event-detail/status/status.component";
import { SummaryComponent } from "./events/event-detail/summary/summary.component";
import { ShareModalComponent } from "./events/share-modal/share-modal.component";


import { RewindPlayerComponent } from "./camera/rewind/rewind-player/rewind-player.component";
import { RewindComponent } from "./camera/rewind/rewind.component";
import { CameraComponent } from "./camera/camera.component";
import { CameraSelectComponent } from "./camera/camera-select/camera-select.component";
import { CameraGuard } from "./camera/camera-guard.service";
import { CameraService } from "./camera/camera.service";
import { LiveViewComponent } from "./camera/live-view/live-view.component";
import { DayFilterPipe } from "./camera/rewind/day-filter.pipe";
import { UtcDayPipe } from "./camera/rewind/utc-day.pipe";
import { RewindGuard } from "./camera/rewind/rewind-guard.service";
import { LiveViewGuard } from "./camera/live-view/live-view-guard.service";
import { StreamService } from "./camera/live-view/stream.service";
import { HlsPlayerComponent } from "./camera/live-view/hls-player/hls-player.component";
import { VideoLoaderComponent } from "./camera/live-view/video-loader/video-loader.component";

import { MetricsService } from "./metrics/metrics.service";
import { MetricsGuard } from "./metrics/metrics-guard.service";
import { MetricsComponent } from "./metrics/metrics.component";
import { CountingComponent } from "./metrics/counting/counting.component";
import { EventsComponent } from "./metrics/events/events.component";
import { LoginsComponent } from "./metrics/logins/logins.component";
import { HistoricalComponent } from "./metrics/historical/historical.component";
import { RequestListComponent } from "./metrics/historical/request-list/request-list.component";
import { HistoricalDataComponent } from "./metrics/historical/historical-data/historical-data.component";

import { AddCamsModalComponent } from "./camera/rewind/add-cams/add-cams-modal/add-cams-modal.component";
import { AddCamsFormComponent } from "./camera/rewind/add-cams/add-cams-form/add-cams-form.component";
import { AddCamsStoreModule } from "./camera/rewind/add-cams/store/store.module";

import { DashboardRecentEventsComponent } from "./site/site-dashboard/dashboard-recent-events/dashboard-recent-events.component";
import { SiteDashboardComponent } from "./site/site-dashboard/site-dashboard.component";
import { PropertyMapComponent } from "./property-map/property-map.component";
import { SiteListService } from "./sites/site-list.service";
import { SiteService } from "./site/site.service";
import { DashboardEventDisplayComponent } from "./site/site-dashboard/dashboard-event-display/dashboard-event-display.component";
import { SitePackageService } from "./site-package/services/site-package.service";
import { FeatureService } from "./site-package/services/feature.service";
import { FeatureGuard } from "./site-package/feature.guard";
import { HasFeatureDirective } from "./site-package/feature.directive";
import { SiteLoaderGuard } from "./site/site-loader-guard.service";
import { SiteDisplayComponent } from "./dashboard/site-display/site-display.component";

import { httpInterceptorProviders } from "./http-interceptors";
import { MotionComponent } from "./motion/motion.component";
import { MotionSelectorComponent } from "./motion/motion-selector/motion-selector.component";
import { MotionModule } from "./motion/motion.module";
import { CloudPlayerModalComponent } from "./cloud/cloud-player/cloud-player-modal/cloud-player-modal.component";
import { CloudPlayerComponent } from "./cloud/cloud-player/cloud-player.component";
import { VideoImageCarouselComponent } from "./video-image-carousel/video-image-carousel.component";
import { AfterValueChangedDirective } from "./video-image-carousel/after-value-changed.directive";
import { CloudService } from "./cloud/services/cloud.service";
import { MotionDatetimeModalComponent } from "./motion/motion-datetime-modal/motion-datetime-modal.component";
import { MotionImageComponent } from './motion/motion-image/motion-image.component';
import { MotionFilesComponent } from './motion/motion-files/motion-files.component';
import { RawRequestComponent } from './raw-request/raw-request.component';
import { RawRequestModule } from "./raw-request/raw-request.module";
import { LprComponent } from "./lpr/lpr.component";
import { LPRModule } from "./lpr/lpr.module";
import { AdminLPRModule } from "./admin/admin-lpr/admin-lpr.module";
import { ManageAlertEntriesComponent } from "./lpr/manage-alert-entries/manage-alert-entries.component";
import { AlertEntryComponent } from "./lpr/alert-entry/alert-entry.component";
import { RecentHitsComponent } from "./lpr/recent-hits/recent-hits.component";
import { StringLimitPipe } from './string-limit.pipe';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { AlertDetailsComponent } from "./lpr/alert-details/alert-details.component";
import { ExpressRequestModule } from "./express-request/express-request.module";
import { AdminSiteGroupsModule } from "./admin/admin-site-groups/admin-site-groups.module";
import { SiteDisabledComponent } from './site-disabled/site-disabled.component';
import { CvsViewComponent } from './cvs/cvs-view/cvs-view.component';
import { CameraViewComponent } from './cvs/camera-view/camera-view.component';
import { CvsComponent } from './cvs/cvs.component';
import { CvsModule } from './cvs/cvs.module';
import { LayoutFormComponent } from './cvs/layout-form/layout-form.component';
import { CVSChangesGuard } from './cvs/cvs-changes-guard';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { SiteManagementModule } from "./admin/site-management/site-management.module";
import { ServiceRequestComponent } from "./service-request/service-request.component";
import { WebrtcPlayerComponent } from './camera/live-view/webrtc-player/webrtc-player.component';
import { MainComponent } from './main/main.component';
import { Hls2PlayerComponent } from './camera/live-view/hls2-player/hls2-player.component';
import { HybridPlayerComponent } from './camera/live-view/hybrid-player/hybrid-player.component';
import { NewLeadComponent } from './new-lead/new-lead.component';
import { NewLeadModule } from './new-lead/new-lead.module';
import { NewLeadFormComponent } from './new-lead/new-lead-form/new-lead-form.component';
import { CameraModule } from './camera/camera.module';
import { PortfolioCameraComponent } from './camera/portfolio-camera/portfolio-camera.component';
import { ScrollingModule } from "@angular/cdk/scrolling";
import { FeatureFlagService } from "./feature-flag/feature-flag.service";
import { PortfolioEventComponent } from "./events/portfolio-event/portfolio-event.component";
import { EventsModule } from "./events/events.module";
import { PortfolioEventGridComponent } from "./events/portfolio-event-grid/portfolio-event-grid.component";
import { PortfolioEventGraphComponent } from './events/portfolio-event-graph/portfolio-event-graph.component';
import { PortfolioEventDetailComponent } from "./events/portfolio-event-detail/portfolio-event-detail.component";
import { PortfolioEventTypeMetricsComponent } from './events/portfolio-event-type-metrics/portfolio-event-type-metrics.component';
import { CameraFilterComponent } from "./camera/camera-filter/camera-filter.component";
import { NavigationModule } from "@progress/kendo-angular-navigation";
import { MenuModule } from "@progress/kendo-angular-menu";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { EventFilterComponent } from './events/event-filter/event-filter.component';
import { ExtendedStorageComponent } from './extended-storage/extended-storage.component';
import { ExtendedStorageFilterComponent } from './extended-storage/extended-storage-filter/extended-storage-filter.component';
import { PortfolioExtendedStorageComponent } from './extended-storage/portfolio-extended-storage/portfolio-extended-storage.component';
import { ExtendedStorageModule } from "./extended-storage/extended-storage.module";
import { ExtendedStorageMgmtComponent} from './extended-storage/extended-storage-mgmt/extended-storage-mgmt.component';
import { ExtendedStorageAccountsComponent } from "./extended-storage/extended-storage-accounts/extended-storage-accounts.component";
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { AIModule } from "./ai/ai.module";
import { AIService } from "./ai/ai.service";
import { EventSynopsisComponent } from "./ai/event-synopsis/event-synopsis.component";
import { EventSynopsisModalComponent } from "./ai/event-synopsis-modal/event-synopsis-modal.component";
import { IotLprModule } from "./admin/iot-lpr/iot-lpr.module";
import { LayoutModule } from '@progress/kendo-angular-layout';
import { CameraRotaterComponent } from './camera/camera-rotater/camera-rotater.component';
import { CameraGridComponent } from './camera/camera-grid/camera-grid.component';
import { PortfolioSiteWalkComponent } from './camera/portfolio-site-walk/portfolio-site-walk.component';
import { SiteWalkControlComponent } from './camera/site-walk-control/site-walk-control.component';
import { ProgressBarModule } from "@progress/kendo-angular-progressbar";
import { VhHeightDirective } from './vh-height.directive';
import { IssueCollectorService } from "./issue-collector.service";

import { AppBarModule } from "@progress/kendo-angular-navigation";
import { NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ResizedDirective } from './angular-resize.directive';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { RouterModule } from '@angular/router';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IconsModule } from "@progress/kendo-angular-icons";

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SiteDashboardComponent,
    FirstLoginComponent,
    FirstLoginFormComponent,
    NotFoundComponent,
    NavbarComponent,
    SidebarComponent,
    PropertyMapComponent,
    UserCreationComponent,
    UserFormComponent,
    ClientAccountManagementComponent,
    UserUpdateLogComponent,
    UserManagementGridComponent,
    EventComponent,
    EventDetailComponent,
    NoteComponent,
    StatusComponent,
    CameraComponent,
    CameraSelectComponent,
    LiveViewComponent,
    DashboardEventDisplayComponent,
    PortfolioManagementComponent,
    RewindPlayerComponent,
    RewindComponent,
    DayFilterPipe,
    UtcDayPipe,
    LightMapComponent,
    SiteDisplayComponent,
    MessagesComponent,
    MessagesTableComponent,
    MetricsComponent,
    EditUserComponent,
    NewMessagesComponent,
    DashboardRecentEventsComponent,
    DocumentComponent,
    HlsPlayerComponent,
    VideoLoaderComponent,
    SummaryComponent,
    CountingComponent,
    EventsComponent,
    LoginsComponent,
    ShareModalComponent,
    HistoricalComponent,
    RequestListComponent,
    HistoricalDataComponent,
    MotionComponent,
    HasFeatureDirective,
    MotionSelectorComponent,
    CloudPlayerModalComponent,
    CloudPlayerComponent,
    VideoImageCarouselComponent,
    AfterValueChangedDirective,
    AddCamsFormComponent,
    AddCamsModalComponent,
    MotionDatetimeModalComponent,
    MotionImageComponent,
    MotionFilesComponent,
    RawRequestComponent,
    LprComponent,
    ManageAlertEntriesComponent,
    AlertEntryComponent,
    RecentHitsComponent,
    AlertDetailsComponent,
    StringLimitPipe,
    SiteDisabledComponent,
    CvsViewComponent,
    CameraViewComponent,
    CvsComponent,
    LayoutFormComponent,
    ServiceRequestComponent,
    WebrtcPlayerComponent,
    MainComponent,
    Hls2PlayerComponent,
    HybridPlayerComponent,
    NewLeadComponent,
    NewLeadFormComponent,
    PortfolioCameraComponent,
    PortfolioEventComponent,
    PortfolioEventGridComponent,
    PortfolioEventGraphComponent,
    PortfolioEventDetailComponent,
    PortfolioEventTypeMetricsComponent,
    CameraFilterComponent,
    EventFilterComponent,
    ExtendedStorageComponent,
    ExtendedStorageFilterComponent,
    PortfolioExtendedStorageComponent,
    ExtendedStorageMgmtComponent,
    ExtendedStorageAccountsComponent,
    EventSynopsisComponent,
    EventSynopsisModalComponent,
    CameraRotaterComponent,
    CameraGridComponent,
    PortfolioSiteWalkComponent,
    SiteWalkControlComponent,
    VhHeightDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AdminModule,
    RequestsModule,
    ButtonsModule,
    InputsModule,
    IconsModule,
    AppBarModule,
    LabelModule,
    routing,
    StoreModule.forRoot(appReducer, {
      initialState: getInitialState,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: true,
      }
    }),
    BsDatepickerModule.forRoot(),
    RouterModule.forRoot([]),
    SitePackageModule,
    EffectsModule.forRoot([]),
    AddCamsStoreModule,
    ModalModule.forRoot(),
    
    //MatSidenavModule,
    AddressModule,
    SharedModule,
    MapModule,
    MotionModule,
    RawRequestModule,
    LPRModule,
    AdminLPRModule,
    MessagesModule,
    TooltipModule,
    ExpressRequestModule,
    AdminSiteGroupsModule,
    CvsModule,
    SiteManagementModule,
    AuthModule,
    NewLeadModule,
    CameraModule,
    ScrollingModule,
    EventsModule,
    NavigationModule,
    MenuModule,
    IndicatorsModule,
    ExtendedStorageModule,
    AIModule,
    IotLprModule,
    LayoutModule,
    ProgressBarModule,
    NumericTextBoxModule,
    ResizedDirective,
    DateInputsModule
  ], 
  
  providers: [
    UserService,
    SiteService,
    SiteListService,
    AppGuard,
    DashboardGuard,
    appRoutingProviders,
    NotificationsService,
    MarkerService,
    ServiceHelpersService,
    EventService,
    EventGuard,
    SiteLoaderGuard,
    ClientAccountManagementGuard,
    UserLoaderGuard,
    CameraGuard,
    CameraService,
    UserAgentService,
    MarkerEventService,
    KeyboardService,
    LightMapGuard,
    RewindGuard,
    LiveViewGuard,
    AccessKeyService,
    MessageService,
    MessageGuard,
    PermissionsService,
    MetricsService,
    MetricsGuard,
    MessageSiteGuard,
    DocumentService,
    DocumentGuard,
    httpInterceptorProviders,
    StreamService,
    SitePackageService,
    FeatureService,
    FeatureGuard,
    FeatureFlagService,
    AIService,
    Logger,
    CloudService,
    CVSChangesGuard,
    IssueCollectorService,
    {
        provide: ErrorHandler,
        useClass: ApplicationinsightsAngularpluginErrorService,
    },
    { provide: Window, useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
],
    bootstrap: [AppComponent],
    exports: [AddressComponent, RouterModule],
     })
export class AppModule {}
