import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DocTypeService } from './doc-type.service';
import { map, combineLatest } from 'rxjs/operators';
import { DocType } from './doc-type';

@Component({
  selector: 'app-doc-type',
  templateUrl: './doc-type.component.html',
  styleUrls: ['./doc-type.component.css']
})
export class DocTypeComponent implements OnInit {
  public view: Observable<GridDataResult>;
  public gridState: BehaviorSubject<State> = new BehaviorSubject<State>({ sort: [] });
  public formGroup: UntypedFormGroup;

  private editedRowIndex: number;
  constructor(private docTypeService: DocTypeService) { }

  ngOnInit() {
    this.view = this.docTypeService.getTypesAdmin()
      .pipe(combineLatest(this.gridState, (types, state) => process(types, state)));
  }

  public onStateChange(state: State) {
    this.gridState.next(state);
  }

  public addHandler({sender}) {
    this.closeEditor(sender);
    this.formGroup = this.createForm();
    sender.addRow(this.formGroup);
  }

  public editHandler({sender, rowIndex, dataItem}) {
    this.closeEditor(sender);
    this.formGroup = this.createForm(dataItem);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({sender, rowIndex}) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({sender, rowIndex, formGroup, isNew}) {
    const docType: DocType = formGroup.value;
    if (isNew) {
      this.docTypeService.add(docType);
    } else {
      this.docTypeService.update(docType);
    }
    sender.closeRow(rowIndex);
  }

  public removeHandler({dataItem}) {
    this.docTypeService.remove(dataItem);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  private createForm(docType?: DocType): UntypedFormGroup {
    if (!docType) {
      docType = new DocType({id: 0, name: '', description: '', expireInDays: 30});
    }

    return new UntypedFormGroup({
      'id': new UntypedFormControl(docType.id),
      'name': new UntypedFormControl(docType.name, [Validators.required, Validators.minLength(1), Validators.maxLength(45)]),
      'description': new UntypedFormControl(docType.description),
      'expireInDays': new UntypedFormControl(docType.expireInDays, Validators.min(1)),
    })
  }
}
