import { ActionReducer, Action } from '@ngrx/store';
import { Marker } from '../markers/marker';
import { Request } from './request';

export const ADD_MOUSEOVER_EVENT = 'ADD_MOUSEOVER_EVENT';
export const REMOVE_MOUSEOVER_EVENT = 'REMOVE_MOUSEOVER_EVENT';
export const CLEAR_MOUSEOVER_EVENT = 'CLEAR_MOUSEOVER_EVENT';

export const SET_ALL_REQUESTS = 'SET_ALL_REQUESTS';
export const SET_REQUESTS_LOADING = 'SET_REQUESTS_LOADING';
export const SET_REQUESTS_LOADED = 'SET_REQUESTS_LOADED';

export const SET_EVENTS_FILTER = 'SET_EVENTS_FILTER';
export const SET_EVENTS_FILTER_STATUS = 'SET_EVENTS_FILTER_STATUS';
export const SET_EVENTS_FILTER_STATUS_OFF = 'SET_EVENTS_FILTER_STATUS_OFF';
export const SET_EVENTS_FILTER_TIME = 'SET_EVENTS_FILTER_TIME';
export const SET_EVENTS_FILTER_TIME_OFF = 'SET_EVENTS_FILTER_TIME_OFF';
export const SET_EVENTS_FILTER_ONLY_MINE = 'SET_EVENTS_FILTER_ONLY_MINE';
export const SET_EVENTS_FILTER_ONLY_MINE_OFF = 'SET_EVENTS_FILTER_ONLY_MINE_OFF';
export const SET_EVENTS_FILTER_SEARCH = 'SET_EVENTS_FILTER_SEARCH'

export const SET_EVENTS_SORT = 'SET_EVENTS_SORT';
export const SET_EVENTS_SORT_REVERSE = 'SET_EVENTS_SORT_REVERSE';
export const SET_EVENTS_SORT_REVERSE_TOGGLE = 'SET_EVENTS_SORT_REVERSE_TOGGLE';
export const SET_EVENTS_SORT_FIELD = 'SET_EVENTS_SORT_FIELD';



export const initialMouseOverEvent = [];

export const initialAllRequests = [];

export const initialRequestsLoading = false;

export const initialFilter = {byStatus: false, status: -1, byTime: false, days: -1, onlyMine: false, search: ""}

export const initialSort = {field: "submitted", reverse: true};

export function mouseOverEventReducer(state: Marker[] = [], action):Marker[] {
    switch(action.type) {
        case ADD_MOUSEOVER_EVENT:
            return [action.payload, ...state];
        case REMOVE_MOUSEOVER_EVENT:
            return state.filter(event => event.id !== action.payload.id);
        case CLEAR_MOUSEOVER_EVENT:
            return [];
        default:
            return state;
    }
}

export function allRequestsReducer(state: Request[] = initialAllRequests, action):Request[] {
    switch(action.type) {
        case SET_ALL_REQUESTS:
            return [...action.payload];
        default:
            return state;
    }
}

export function requestsLoadingReducer(state: boolean = initialRequestsLoading, action): boolean {
    switch(action.type) {
        case SET_REQUESTS_LOADING:
            return true;
        case SET_REQUESTS_LOADED:
            return false;
        default:
            return state;
    }
}

export function eventsFilterReducer(state: Filter = initialFilter, action): Filter {
    switch(action.type) {
        case SET_EVENTS_FILTER:
            return Object.assign(new Filter(), action.payload);
        case SET_EVENTS_FILTER_STATUS:
            return Object.assign(new Filter(), state, {byStatus: true, status: action.payload});
        case SET_EVENTS_FILTER_STATUS_OFF:
            return Object.assign(new Filter(), state, { byStatus: false, status: -1 });
        case SET_EVENTS_FILTER_TIME:
            return Object.assign(new Filter(), state, { byTime: true, days: action.payload });
        case SET_EVENTS_FILTER_TIME_OFF:
            return Object.assign(new Filter(), state, { byTime: false, days: -1 });
        case SET_EVENTS_FILTER_ONLY_MINE:
            return Object.assign(new Filter(), state, { onlyMine: true });
        case SET_EVENTS_FILTER_ONLY_MINE_OFF:
            return Object.assign(new Filter(), state, { onlyMine: false });
        case SET_EVENTS_FILTER_SEARCH:
            return Object.assign(new Filter(), state, { search: action.payload})
        default:
            return state;
    }
}

export function eventsSortReducer(state: Sort = initialSort, action): Sort {
    switch (action.type) {
        case SET_EVENTS_SORT:
            return Object.assign(new Sort(), action.payload);
        case SET_EVENTS_SORT_REVERSE:
            return Object.assign(new Sort(), state, {reverse: action.payload});
        case SET_EVENTS_SORT_REVERSE_TOGGLE:
            return Object.assign(new Sort(), state, {reverse: !state.reverse});
        case SET_EVENTS_SORT_FIELD:
            if(state.field === action.payload) {
                return Object.assign(new Sort(), state, {reverse: !state.reverse});
            } else {
                return Object.assign(new Sort(), state, {field: action.payload});
            }
        default:
            return state;
    }
}

export class Sort {
    public field: string;
    public reverse: boolean;
}

export class Filter {
    public byStatus: boolean;
    public status: number;
    public byTime: boolean;
    public days: number;
    public onlyMine: boolean;
    public search: string;
}