import { AdminSiteListItem } from "../adminSiteListItem";


export const adminKey = "admin";

export interface State {
    sites: AdminSiteListItem[],
    sitesLoaded: boolean,
    sitesLoading: boolean,
}

export const initialState: State = {
    sites: [],
    sitesLoaded: false,
    sitesLoading: false,
}