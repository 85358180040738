import { CameraMarker, CameraMarkerConfig } from '../../../camera/camera-marker'
import { REQUEST_TOGGLE_CAMERA } from '../../request-toggle-camera-action';
import { take } from 'rxjs/operators';



export class RequestCameraMarker extends CameraMarker{
//    constructor(marker: CameraMarker) {
        //super({id: marker.id, camera: marker.camera, shape: marker.shape.type, data: marker.shape.data, map: marker.shape.map, store: marker.store, displayName: marker.shape.displayName }) //TODO: fix this, needs to take a camera marker
//    }
    clicked() {
        this.store.dispatch({type: REQUEST_TOGGLE_CAMERA, payload: this})
        this.map.draw();
    }
    draw() {
        this.store.select(s => s.request.cameras.cameras).subscribe(cameras => {
            let selected = false;
            if(cameras) {
                cameras.forEach(camera => {
                    if(camera.id === this.id) {
                        selected = true;
                    }
                })
            }
            if(!selected) {
                super.draw();
            } else {
                this.shapeObj.draw(this.getFill(), "green");
            }
        }).unsubscribe();
    }
    getFillColor(): string {
        let color = super.getFillColor();
        this.store.select(s => s.request.cameras.cameras).pipe(take(1)).subscribe(cameras => {
            let selected = false;
            if(cameras) {
                cameras.forEach(camera => {
                    if(camera.id === this.id) {
                        selected = true;
                    }
                })
            }
            if(selected) {
                color = "purple"
            }
        })
        return color
    }
}