import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Lookup } from 'app/lookup';
import { UserService } from 'app/users/user.service';
import { SiteListService } from 'app/sites/site-list.service';
import { map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { selectSiteGroups, selectSiteGroupsGridData } from '../admin-site-groups/store/selectors';
import { loadSiteGroups } from '../admin-site-groups/store/actions';
import { AdminService } from '../admin.service';
import { loadManComps, userSearch, setUsersGridState, setSelectedUsersKeys, setSelectAllCheckboxState, setAction, setNewPermissions, setSiteClude, submit, setSites, reset, setSearchForm, setShowStatusModal } from './store/actions';
import {
  selectManComps,
  selectManCompsLoading,
  selectSearching,
  selectUsersGridView,
  selectUsersGridState,
  selectSearchResults,
  selectSelectedUsersKeys,
  selectSelectAllCheckboxState,
  selectSelectedUsers,
  selectSelectedUserSites,
  selectAction,
  selectNewPermissions,
  selectSiteClude,
  selectSites,
  selectSubmitting,
  selectTransactionID,
  selectShowStatusModal,
  selectSearchForm,
} from './store/selectors';
import { UserSearch } from '../admin-user-selector/user-search';
import { GridDataResult, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { UserInfo } from './user-info';
import { DocType } from '../admin-documents/doc-type/doc-type';
import { DocTypeService } from '../admin-documents/doc-type/doc-type.service';

const HARD_TYPE_EMPLOYEE = 1;

@Component({
  selector: 'admin-permissions',
  templateUrl: './admin-permissions.component.html',
  styleUrls: ['./admin-permissions.component.css']
})
export class AdminPermissionsComponent implements OnInit {

  public manComps$: Observable<Lookup[]>;
  public manCompsLoading$: Observable<boolean>;
  public siteGroups$: Observable<Lookup[]>;
  public sites$: Observable<Lookup[]>;
  public hardTypes$: Observable<Lookup[]>;
  public types$: Observable<Lookup[]>;
  public permTemplates$: Observable<Lookup[]>;
  public searching$: Observable<boolean>;
  public searchForm$: Observable<UserSearch>;

  public usersGridView$: Observable<GridDataResult>;
  public usersGridState$: Observable<State>;
  public users$: Observable<UserInfo[]>;
  public selectedUsersKeys$: Observable<number[]>;
  public selectAllCheckboxState$: Observable<SelectAllCheckboxState>;
  
  public selectedUsers$: Observable<UserInfo[]>;
  public selectedUserSites$: Observable<Lookup[]>;
  public docTypes$: Observable<DocType[]>;
  public action$: Observable<string>;
  public newPermissions$: Observable<{site: any, document: any}>;
  public siteClude$: Observable<"exclude" | "include">
  public selectedSites$: Observable<number[]>;
  public submitting$: Observable<boolean>;
  public transactionID$: Observable<number>;
  public showStatusModal$: Observable<boolean>;

  constructor(
    private userService: UserService,
    private siteService: SiteListService,
    private store: Store<AppState>,
    private docTypeService: DocTypeService,
  ) { }

  ngOnInit() {
    this.store.dispatch(loadSiteGroups());
    this.store.dispatch(loadManComps());
    this.hardTypes$ = this.userService.getHardTypes().pipe(map(types => types.filter(t => t.id !== HARD_TYPE_EMPLOYEE)));
    this.types$ = this.userService.getTypes();
    this.sites$ = this.siteService.getSiteList().pipe(map(sites => sites.map(site => {return {name: site.siteName, id: site.siteID}})));
    this.permTemplates$ = this.userService.getPermissionsTemplates();
    this.siteGroups$ = this.store.pipe(select(selectSiteGroupsGridData));
    this.manComps$ = this.store.select(selectManComps);
    this.manCompsLoading$ = this.store.select(selectManCompsLoading);
    this.searching$ = this.store.pipe(select(selectSearching));
    this.searchForm$ = this.store.pipe(select(selectSearchForm));

    this.usersGridState$ = this.store.pipe(select(selectUsersGridState));
    this.usersGridView$ = this.store.pipe(select(selectUsersGridView));
    this.users$ = this.store.pipe(select(selectSearchResults));
    this.selectedUsersKeys$ = this.store.pipe(select(selectSelectedUsersKeys));
    this.selectAllCheckboxState$ = this.store.pipe(select(selectSelectAllCheckboxState));

    this.selectedUsers$ = this.store.pipe(select(selectSelectedUsers));
    this.selectedUserSites$ = this.store.pipe(select(selectSelectedUserSites));
    this.docTypes$ = this.docTypeService.getTypesAdmin();
    this.action$ = this.store.pipe(select(selectAction));
    this.newPermissions$ = this.store.pipe(select(selectNewPermissions));
    this.siteClude$ = this.store.pipe(select(selectSiteClude));
    this.selectedSites$ = this.store.pipe(select(selectSites));
    this.submitting$ = this.store.pipe(select(selectSubmitting));
    this.transactionID$ = this.store.pipe(select(selectTransactionID));
    this.showStatusModal$ = this.store.pipe(select(selectShowStatusModal));

  }
  search(search: UserSearch) {
    this.store.dispatch(userSearch({search: search}));
  }

  onSearchFormChange(data: UserSearch) {
    this.store.dispatch(setSearchForm({searchForm: data}));
  }

  onSelectedUsersStateChange(state: State) {
    this.store.dispatch(setUsersGridState({gridState: state}));
  }

  setSelectedUsers(keys: number[]) {
    console.log(keys);
    this.store.dispatch(setSelectedUsersKeys({keys: keys}));
  }

  onSelectAllCheckboxChange(state: SelectAllCheckboxState) {
    this.store.dispatch(setSelectAllCheckboxState({checkbox: state}));
  }

  onActionChange(action: string) {
    this.store.dispatch(setAction({action: action}));
  }

  onNewPermissionsChange(perms: {site: any, document: any}) {
    this.store.dispatch(setNewPermissions({perms: perms}));
  }

  onSiteCludeChange(selected: "exclude" | "include") {
    this.store.dispatch(setSiteClude({siteClude: selected}));
  }
  onSitesChange(sites: number[]) {
    this.store.dispatch(setSites({sites: sites}));
  }
  submit() {
    this.store.dispatch(submit());
  }
  onCloseStatusModal() {
    this.store.dispatch(setShowStatusModal({value: false}))
  }
  resetAll() {
    this.store.dispatch(reset());
    this.store.dispatch(loadManComps());
  }
}
