// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mediaButton {
    width: 60px;
    height: 45px;
    background-color: gray;
    color: white;
    font-size: 0.8em;
}

.selected {
    background-color: #333;
}

.expire-message {
    width: 80%;
    margin: 10%;
}

.table-responsive {
    overflow-x: auto;
}

.ar-button {
    width: 125px;
    height: 50px;
    background-color: gray;
    color: white;
    font-size: 0.8em;
}

.arselected {
    background-color: #333;
}

.clickable {
    cursor: pointer;
}

.download-btn {
    background: none;
    border: none;
    outline: none;
    width: 32px;
}

.iscloud {
    background-color: #f8f9fa;
}

.speedButton {
    margin-right: 3px;
    padding: 3px;
}

.card {
    background-color: #f5f5f5;
}
`, "",{"version":3,"sources":["webpack://./src/app/events/event-detail/summary/summary.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".mediaButton {\r\n    width: 60px;\r\n    height: 45px;\r\n    background-color: gray;\r\n    color: white;\r\n    font-size: 0.8em;\r\n}\r\n\r\n.selected {\r\n    background-color: #333;\r\n}\r\n\r\n.expire-message {\r\n    width: 80%;\r\n    margin: 10%;\r\n}\r\n\r\n.table-responsive {\r\n    overflow-x: auto;\r\n}\r\n\r\n.ar-button {\r\n    width: 125px;\r\n    height: 50px;\r\n    background-color: gray;\r\n    color: white;\r\n    font-size: 0.8em;\r\n}\r\n\r\n.arselected {\r\n    background-color: #333;\r\n}\r\n\r\n.clickable {\r\n    cursor: pointer;\r\n}\r\n\r\n.download-btn {\r\n    background: none;\r\n    border: none;\r\n    outline: none;\r\n    width: 32px;\r\n}\r\n\r\n.iscloud {\r\n    background-color: #f8f9fa;\r\n}\r\n\r\n.speedButton {\r\n    margin-right: 3px;\r\n    padding: 3px;\r\n}\r\n\r\n.card {\r\n    background-color: #f5f5f5;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
