// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unassign-storage-message {
    max-width: 350px;
    text-align: justify;
}

.change-storage-message {
    max-width: 350px;
    text-align: justify;
}

.confirm-button {
    color: #fff;
    background: #A31621;
}

.continue-button {
    color: #fff;
    background: #97C152;
}`, "",{"version":3,"sources":["webpack://./src/app/extended-storage/extended-storage.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".unassign-storage-message {\r\n    max-width: 350px;\r\n    text-align: justify;\r\n}\r\n\r\n.change-storage-message {\r\n    max-width: 350px;\r\n    text-align: justify;\r\n}\r\n\r\n.confirm-button {\r\n    color: #fff;\r\n    background: #A31621;\r\n}\r\n\r\n.continue-button {\r\n    color: #fff;\r\n    background: #97C152;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
