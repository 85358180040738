import { Tag } from "./tag";

export class AlertEntry {
  id: number;
  plate: string;
  state: string;
  description: string;
  expirationDate: Date;
  isAdmin: boolean;
  events: number[];
  notificationEmails: string[];
  siteID: number;
  siteName: string;
  isRemoved: boolean;
  createdDate: Date;
  createdByID: number;
  createdName: string;
  tags: Tag[];
  hits: number;
  lastHitDate: Date;
  lastUpdatedByID: number;

  constructor(config?: any) {
    if (config) {
      Object.assign(this, config);
      if (config.createdDate) {
        this.createdDate = new Date(this.createdDate);
      }
      if (this.expirationDate) {
        this.expirationDate = new Date(this.expirationDate);
      }
      if(config.lastHitDate) {
        this.lastHitDate = new Date(config.lastHitDate);
      }
    }
  }
}