import { Injectable } from '@angular/core';
import { SiteService } from 'app/site/site.service';
import { take, map } from 'rxjs/operators';
import { Map } from 'app/map/map';
import { Observable } from 'rxjs';
import { PropertyMap } from 'app/property-map/property-map';
import { MarkerService } from 'app/markers/marker.service';
import { AddCamsMarker } from './add-cams-marker';

@Injectable({
  providedIn: 'root'
})
export class AddCamsService {

  constructor(
    private siteService: SiteService,
    private markerService: MarkerService,
  ) { }

  getMaps(): Observable<Map[]> {
    return this.siteService.getSite().pipe(
      take(1),
      map(site => {
        let maps: Map[] = [];
        if(site && site.maps) {
          site.maps.forEach(map => {
            let newMap = new PropertyMap(map);
            let markers = map.markers.filter(m => m.type === "camera");
            newMap.markersLocal = [];
            newMap.markersLocal = this.markerService.createAddCamsMarkers(markers, newMap)
            maps.push(newMap);
          })
        }
        return maps;
      })
    );
  }
  getMarker(cameraID: number, maps: Map[]): AddCamsMarker {
    let result: AddCamsMarker;

    maps.forEach(map => {
      if (map.markersLocal) {
        map.markersLocal.forEach(m => {
          let marker = m as AddCamsMarker;
          if (marker.type === "camera" && +marker.camera === cameraID) {
            result = marker;
          }
        })
      }
    });

    return result;
  }
}
