// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panelbar-wrapper {
    max-width: 600px;
    margin: 0 auto;
}

.panel-bar {
    font-size: 16px;
}

.clickable {
    cursor: pointer;
  }

.file-actions {
    margin-right:10px;
    float: right;
}  

.doc-list-item {
    padding: 5px;
}

.doc-action-btn {
    padding: 0px;
    background: none;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/document-list/document-list.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;EACjB;;AAEF;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".panelbar-wrapper {\r\n    max-width: 600px;\r\n    margin: 0 auto;\r\n}\r\n\r\n.panel-bar {\r\n    font-size: 16px;\r\n}\r\n\r\n.clickable {\r\n    cursor: pointer;\r\n  }\r\n\r\n.file-actions {\r\n    margin-right:10px;\r\n    float: right;\r\n}  \r\n\r\n.doc-list-item {\r\n    padding: 5px;\r\n}\r\n\r\n.doc-action-btn {\r\n    padding: 0px;\r\n    background: none;\r\n    border: none;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
