// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field {
    display: inline;
}
.officerButton {
    margin-top: 10px;
    margin-bottom: 5px;
}

.officerButton, .buttons{
    background-color: #fff;
    color: black;
}

.officerButton, .btn {
    border: 1px solid #ccc;
}

.officerButton:hover {
    background-color: #ccc;
}

.otherOfficer {
    margin-top: 10px;
   
}
.error {
    word-break: break-all;
}

h4 {
    margin-top: 11px;
    margin-bottom: 10px;
    font-weight: normal;
}

label {
    margin-right: 5px; 
    font-weight: bold;
}

.buttons {
    margin-bottom: 10px;  
}

.btn {
    border-radius: 4px;
}

`, "",{"version":3,"sources":["webpack://./src/app/requests/new-request/contact-info/contact-info.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;;AAEpB;AACA;IACI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".field {\r\n    display: inline;\r\n}\r\n.officerButton {\r\n    margin-top: 10px;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.officerButton, .buttons{\r\n    background-color: #fff;\r\n    color: black;\r\n}\r\n\r\n.officerButton, .btn {\r\n    border: 1px solid #ccc;\r\n}\r\n\r\n.officerButton:hover {\r\n    background-color: #ccc;\r\n}\r\n\r\n.otherOfficer {\r\n    margin-top: 10px;\r\n   \r\n}\r\n.error {\r\n    word-break: break-all;\r\n}\r\n\r\nh4 {\r\n    margin-top: 11px;\r\n    margin-bottom: 10px;\r\n    font-weight: normal;\r\n}\r\n\r\nlabel {\r\n    margin-right: 5px; \r\n    font-weight: bold;\r\n}\r\n\r\n.buttons {\r\n    margin-bottom: 10px;  \r\n}\r\n\r\n.btn {\r\n    border-radius: 4px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
