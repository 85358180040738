// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-synopsis-container .event-synopsis-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.event-synopsis-container .event-synopsis-loading-text {
    margin-top: 10px;
    font-size: 16px;
}

.event-synopsis-container .event-synopsis-content {
    font-size: 16px;
    padding: 10px;
    height: 100%;
}

.event-synopsis-container .event-synopsis-categories {
    margin-top: 15px;
}

.event-synopsis-container .event-synopsis-item {
    margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/ai/event-synopsis/event-synopsis.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".event-synopsis-container .event-synopsis-loader {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100%;\r\n    flex-direction: column;\r\n}\r\n\r\n.event-synopsis-container .event-synopsis-loading-text {\r\n    margin-top: 10px;\r\n    font-size: 16px;\r\n}\r\n\r\n.event-synopsis-container .event-synopsis-content {\r\n    font-size: 16px;\r\n    padding: 10px;\r\n    height: 100%;\r\n}\r\n\r\n.event-synopsis-container .event-synopsis-categories {\r\n    margin-top: 15px;\r\n}\r\n\r\n.event-synopsis-container .event-synopsis-item {\r\n    margin-bottom: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
