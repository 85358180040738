import { User } from './users/user';
import { UserType } from './users/user-type';
import { Request as EventRequest } from './events/request';
import {
    userReducer,
    initialUser,
    tempUsersReducer,
    initialTempUsers,
    initialUserLoaded,
    userLoadedReducer,
    initialPermissionsTemplates,
    permissionsTemplatesReducer,
    initialUserTypes,
    initialUserHardTypes,
    userTypeReducer,
    userHardTypeReducer, 
} from './users/user.reducer';
import { initialPropertyMap, PropertyMapState, propertyMapReducer } from './property-map/property-map.reducer';
import { sidebarOpenReducer, initialSidebarOpen, sidebarHiddenReducer, initialSidebarHidden } from './sidebar/sidebar.reducer';
import { SiteListItem } from './sites/site-list-item';
import { initialSiteList, siteListReducer } from './sites/site-list.reducer'
import { Site } from './site/site';
import { initialSite, siteReducer, initialSiteLoading, siteLoadingReducer } from './site/site.reducer';
import { PermissionsTemplate } from './permissions-template';
import {
    initialAccMgmtUsers,    
    accMgmtUsersReducer,
    initialAccMgmtDeletedUsers,
    accMgmtDeletedUsersReducer,
    initialAccMgmtFilter,
    initialAccMgmtSite,
    accMgmtFilterReducer,
    accMgmtSiteReducer
} from './admin/account-management/account-management.reducer';
import { initialMouseOverEvent, mouseOverEventReducer, initialFilter, initialSort, eventsSortReducer, eventsFilterReducer, initialAllRequests, allRequestsReducer, Sort, Filter, initialRequestsLoading, requestsLoadingReducer } from './events/event.reducer';
import { Marker } from './markers/marker';
import {
    initialRequestStages,
    requestStagesReducer,
    requestReducer,
    initialRequest,
    initialNewRequestInProgress,
    newRequestInProgressReducer,
    serviceRequestReducer,
    initialServiceRequest,
} from './requests/requests.reducer';
import { RequestStages } from './requests/new-request/request-stages';
import { Request } from './requests/request';
import { ServiceRequest } from './service-request/service-request';
import { AdminEventsState, initialAdminEventsState, adminEventsReducer } from './admin/admin-events/admin-events.reducer';
import {
    initialSelectedMetric,
    selectedMetricReducer,
    initialEventsDateRange,
    eventsDateRangeReducer,
    initialLoginsDateRange,
    loginsDateRangeReducer,
} from './metrics/metrics.reducer';
import { DateRange } from './metrics/date-range';
import { allSitesSearchReducer, initialAllSitesSearch } from './admin/admin-all-sites/all-sites.reducer'
import { AllSitesSearch } from './admin/admin-all-sites/all-sites-search'

export function getInitialState() {
    return {...initialState};
}

export const initialState: AppState = {
    user: initialUser,
    userLoaded: initialUserLoaded,
    tempUsers: initialTempUsers,
    siteList: initialSiteList,
    site: initialSite,
    siteLoading: initialSiteLoading,
    permissionsTemplates: initialPermissionsTemplates,
    userTypes: initialUserTypes,
    userHardTypes: initialUserHardTypes,
    sidebarOpen: initialSidebarOpen,
    sidebarHidden: initialSidebarHidden,
    accMgmtUsers: initialAccMgmtUsers,
    accMgmtDeletedUsers: initialAccMgmtDeletedUsers,
    accMgmtSite: initialAccMgmtSite,
    accMgmtFilter: initialAccMgmtFilter,
    mouseOverEvent: initialMouseOverEvent,
    requestStages: initialRequestStages,
    newRequestInProgress: initialNewRequestInProgress,
    request: initialRequest,
    requests: initialAllRequests,
    requestsLoading: initialRequestsLoading,
    eventsSort: initialSort,
    eventsFilter: initialFilter,
    serviceRequest: initialServiceRequest,
    propertyMap: initialPropertyMap,
    adminEvents: initialAdminEventsState,
    selectedMetric: initialSelectedMetric,
    eventsDateRange: initialEventsDateRange,
    loginsDateRange: initialLoginsDateRange,
    allSitesSearch: initialAllSitesSearch,
};

export class AppState {
    public user: User;
    public userLoaded: string;
    public tempUsers: User[];
    public siteList: SiteListItem[];
    public site: Site;
    public siteLoading: boolean;
    public permissionsTemplates: PermissionsTemplate[];
    public userTypes: UserType[];
    public userHardTypes: UserType[];
    public sidebarOpen: boolean;
    public sidebarHidden: boolean;
    public accMgmtUsers: User[];
    public accMgmtDeletedUsers: User[];
    public accMgmtSite: SiteListItem;
    public accMgmtFilter: string;
    public mouseOverEvent: Marker[];
    public requestStages: RequestStages;
    public newRequestInProgress: boolean;
    public request: Request;
    public requests: EventRequest[];
    public requestsLoading: boolean;
    public eventsSort: Sort;
    public eventsFilter: Filter;
    public serviceRequest: ServiceRequest;
    public propertyMap: PropertyMapState;
    public adminEvents: AdminEventsState;
    public selectedMetric: string;
    public eventsDateRange: DateRange;
    public loginsDateRange: DateRange;
    public allSitesSearch: AllSitesSearch;
}

export const appReducer = {
    user: userReducer,
    userLoaded: userLoadedReducer,
    tempUsers: tempUsersReducer,
    siteList: siteListReducer,
    site: siteReducer,
    siteLoading: siteLoadingReducer,
    permissionsTemplates: permissionsTemplatesReducer,
    userTypes: userTypeReducer,
    userHardTypes: userHardTypeReducer,
    sidebarOpen: sidebarOpenReducer,
    sidebarHidden: sidebarHiddenReducer,
    accMgmtUsers: accMgmtUsersReducer,
    accMgmtDeletedUsers: accMgmtDeletedUsersReducer,
    accMgmtSite: accMgmtSiteReducer,
    accMgmtFilter: accMgmtFilterReducer,
    mouseOverEvent: mouseOverEventReducer,
    requestStages: requestStagesReducer,
    newRequestInProgress: newRequestInProgressReducer,
    request: requestReducer,
    requests: allRequestsReducer,
    requestsLoading: requestsLoadingReducer,
    eventsSort: eventsSortReducer,
    eventsFilter: eventsFilterReducer,
    serviceRequest: serviceRequestReducer,
    propertyMap: propertyMapReducer,
    adminEvents: adminEventsReducer,
    selectedMetric: selectedMetricReducer,
    eventsDateRange: eventsDateRangeReducer,
    loginsDateRange: loginsDateRangeReducer,
    allSitesSearch: allSitesSearchReducer,
}
