import { MotionCameraMarker } from "../motion-selector/motion-camera-marker";
import { props, createAction } from "@ngrx/store";
import { MotionDateTime } from "../motion-datetime-modal/motion-datetime";
import { MotionRequest } from "../motion-request";
import { MotionData, MotionFile } from "../motion-data";
import { TimeObj } from "app/time-obj";
import { CloudVideo } from "app/cloud/cloud-video";
import { Map } from "app/map/map";


// Reset
export const resetMotion = createAction("[Motion] Reset");

export const setMotionSiteID = createAction("[Motion] Set Motion SiteID", props<{siteID: number}>());

export const loadingMotionForSite = createAction("[Motion] Loading Motion for Site", props<{siteID: number}>());

export const loadMotionForCameraID = createAction("[Motion] Load Motion For CameraID", props<{cameraID: number}>());

// Camera Selection
export const clickCamera = createAction("[Motion] Click Camera", props<{marker: MotionCameraMarker}>());

export const addCamera = createAction("[Motion] Add Camera", props<{marker: MotionCameraMarker}>());

export const removeCamera = createAction("[Motion] Remove Camera", props<{marker: MotionCameraMarker}>());

export const resetCameras = createAction("[Motion] Reset Cameras");

export const tooManyCamsClicked = createAction("[Motion] Too Many Cam Clicked");

export const maxCameraModalOpen = createAction("[Motion] Max Camera Modal Open");

export const maxCameraModalClose = createAction("[Motion] Max Camera Modal Close");


// Time Selection
export const setSelectedTime = createAction("[Motion] Set Selected Time", props<{time: MotionDateTime}>());

// Loading Motion
export const submitRequest = createAction("[Motion] Submit Request");

export const loadMotion = createAction("[Motion] Load Motion", props<{request: MotionRequest}>());

export const motionLoading = createAction("[Motion] Motion Loading");

export const motionLoaded = createAction("[Motion] Motion Loaded", props<{motionData: MotionData, request: MotionRequest}>());

export const motionFailed = createAction("[Motion] Motion Failed");

// Viewing Cloud for Motion
export const motionClick = createAction("[Motion] Motion Click", props<{file: MotionFile}>());

export const loadCloud = createAction("[Motion] Load Cloud", props<{cameraID: number, time: TimeObj}>());

export const cloudLoaded = createAction("[Motion] Cloud Loaded", props<{cloudFiles: CloudVideo[], cloudTime: TimeObj, cloudCamera: number}>());

export const cloudFailed = createAction("[Motion] Cloud Failed");

export const cloudModalClose = createAction("[Motion] Cloud Modal Close");

export const toggleMotionSelectorCollapse = createAction("[Motion] Toggle Motion Selector Collapse");


export const setCameraIDToName = createAction("[Motion] Set CameraID To Name", props<{map: {[id: number]:string}}>());

export const setCameraIDToDisplayName = createAction("[Motion] Set CameraID To DisplayName", props<{map: {[id: number]:string}}>());


// Selection Maps
export const setSelectionMaps = createAction("[Motion] Set SelectionMaps", props< {maps: Map[]}>());

export const selectSelectionMap = createAction("[Motion] Select Selection Map", props<{mapID: number}>());
