

export interface UserActivity {
    id?: number
    userID?: number;
    eventID?:number;
    siteID: number;
    action: Actions;
    meta?: any;
}

export enum Actions {
    SendInPopup = "Send in Popup",
    ViewImage = "View Image",
    PlayVideo = "Play Video",
    CopyLink = "Copy Link",
    Download = "Download",
}