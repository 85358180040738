import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EventService } from '../../../events/event.service';
import { WtsEvent } from '../../../events/event';
import { NotificationsService } from '../../../notifications/notifications.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent {

  @Input() set model(fileEventID: number) {
    this.active = fileEventID !== undefined;
    this.eventID = fileEventID;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();


  public active: boolean = false;
  public eventID: number;
  public files: any = [];
  public event: WtsEvent;
  public uploadedImages: string[] = [];

  constructor(public eventService: EventService, public notificationsService: NotificationsService) { }

  onCancel(e): void {
    e.preventDefault()
    this.closeDialog()
  }

  closeDialog(): void {
    this.active = false;
    this.cancel.emit();
  }

  filesChange(files) {
    if (files.length > 0) {
      let formData = new FormData();
      formData.append('picture', files[0], files.name);
      this.eventService.addImage(this.eventID, formData)
        .subscribe(res => {
          this.eventService.getEvent(this.eventID)
            .subscribe(res => {
              this.uploadedImages = res['images']
            });
        });
    }
  }

  filesInvalid(files) {
    if (files.length > 0) {
      this.notificationsService.error("", "Error uploading file")
    }
  }
}
