import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import { SiteService } from '../../../site/site.service';
import { PropertyMap } from '../../../property-map/property-map';
import { Map } from '../../../map/map';
import { Site } from '../../../site/site';
import { CLEAR_MOUSEOVER_EVENT, ADD_MOUSEOVER_EVENT, REMOVE_MOUSEOVER_EVENT } from '../../../events/event.reducer';
import { MarkerService } from '../../../markers/marker.service';
import { Cameras } from './cameras';
import { REQUEST_TOGGLE_CAMERA } from '../../request-toggle-camera-action';
import { Marker } from '../../../markers/marker';

@Component({
    selector: 'app-cameras',
    templateUrl: './cameras.component.html',
    styleUrls: ['./cameras.component.css'],
})
export class CamerasComponent implements OnInit, OnDestroy {
    public siteSub: any;
    public map: Map;
    public maps: Map[];
    public site: Site;
    public siteLoading;
    public cameras: Cameras;
    public camerasSub;
    public selected;
    public starMaps: number[];
    constructor(public siteService: SiteService, public store: Store<AppState>, public markerService: MarkerService, public router: Router, public route: ActivatedRoute) {}

    ngOnInit() {
        this.siteLoading = this.siteService.getSiteLoading();
        this.store.dispatch({type: CLEAR_MOUSEOVER_EVENT});
        this.siteSub = this.siteService.getSite().subscribe(site => {
            this.store.dispatch({type: CLEAR_MOUSEOVER_EVENT});
            if(site) {
                this.maps = [];
                if(site.maps) {
                    site.maps.forEach(map => {
                        let newMap = new PropertyMap(map);
                        if(map.markers) {
                            map.markersLocal = this.markerService.createRequestCameraMarkers(map.markers, newMap);
                        }
                        newMap.markersLocal = map.markersLocal
                        this.maps.push(newMap);
                    })
                    this.map = this.maps[0];
                } else {
                    this.map = new PropertyMap({})
                }
                this.site = site;
            }
        })
        this.camerasSub = this.store.select(s => s.request.cameras).subscribe(cameras => {
            this.cameras = cameras;
            this.selected = {};
            this.starMaps = [];
            let cameraMarkerIDs = cameras.cameras.map(c => c.id);
            for(let map of this.maps) {
                let found = false;
                for(let marker of map.markersLocal) {
                    if(cameraMarkerIDs.indexOf(marker.id) !== -1) {
                        found = true;
                        break;
                    }
                }
                if(found) {
                    this.starMaps.push(map.id);
                }
            }
            if(this.cameras.cameras) {
                this.cameras.cameras.forEach(camera => this.selected[camera.id] = true);
            }
        });
    }
    select(camera) {
        this.store.dispatch({type: REQUEST_TOGGLE_CAMERA, payload: camera});
        this.map.draw();
    }
    remove(camera) {
        this.store.dispatch({type: REQUEST_TOGGLE_CAMERA, payload: camera});
        this.store.dispatch({type: REMOVE_MOUSEOVER_EVENT, payload: camera});
        this.map.draw();
    }
    enter(camera) {
        if(camera.map.id === this.map.id) {
            this.store.dispatch({type: ADD_MOUSEOVER_EVENT, payload: camera });
            this.map.draw();        
        }
    }
    leave(camera) {
        if(camera.map.id === this.map.id) {
            this.store.dispatch({type: REMOVE_MOUSEOVER_EVENT, payload: camera });
            this.map.draw();
        }
    }
    ngOnDestroy() {
        this.siteSub.unsubscribe();
        this.camerasSub.unsubscribe();
    }
    cameraMarkers(markers:Marker[]): Marker[] {
        if (!markers) {
            return [];
        }
        let mark = markers.filter(marker => {
            return marker.type === "camera";
        })
        let nums = mark.filter(m => !isNaN(+m.displayName));
        let notNums = mark.filter(m => isNaN(+m.displayName));
        nums.sort((a, b) => {
            return +a.displayName - +b.displayName;
        })
        notNums.sort((a,b) => {
            if(a.displayName < b.displayName) {
                return -1
            }
            if(a.displayName > b.displayName) {
                return 1
            }
            return 0
        })
        return [...nums, ...notNums];
    }
    back() {
        this.router.navigate(['../', 'where'], {relativeTo: this.route});
    }
    next() {
        this.router.navigate(['../', 'contact-info'], {relativeTo: this.route});
    }
}