export class PeopleCamera {
    cameraID: number;
    cameraName: string;
    siteID: number;
    siteName: string;
    httpURL: string;
    port: number;
    constructor(config?: {
        cameraID: number,
        cameraName: string,
        siteID: number,
        siteName: string,
        httpURL: string,
        port: number,
    }) {
        if(config) {
            this.cameraID = config.cameraID;
            this.cameraName = config.cameraName;
            this.siteID = config.siteID;
            this.siteName = config.siteName;
            this.httpURL = config.httpURL;
            this.port = config.port;
        }
    }
}