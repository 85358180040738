import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Validators, UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { DocumentType } from "app/document/document-type";
import { DocumentFile } from "app/document/document-file";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "app-upload-modal",
  templateUrl: "./upload-modal.component.html",
  styleUrls: ["./upload-modal.component.css"]
})
export class UploadModalComponent implements OnInit, OnDestroy {
  @Input() types: DocumentType[];

  @Input() set model(uploadInfo: DocumentFile) {
    this.active = uploadInfo !== undefined;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();

  @Output() save: EventEmitter<any> = new EventEmitter();

  public uploadForm: UntypedFormGroup;
  public selectedType: DocumentType[];
  public active: boolean = false;
  public alive: boolean = true;
  private fb: UntypedFormBuilder

  constructor() {
    this.fb = new UntypedFormBuilder();
  }

  ngOnInit() {
    this.loadForm();
    this.uploadForm.get("type").valueChanges.pipe(takeWhile(() => this.alive))
    .subscribe(value => {
      if (value) {
      this.typeChange(value);
      }
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onSave(event: Event): void {
    event.preventDefault();
    this.save.emit(this.uploadForm.value);
    this.closeForm();
  }

  onCancel(event: Event): void {
    event.preventDefault();
    this.closeForm();
    this.cancel.emit();
  }

  closeForm(): void {
    this.uploadForm.reset();
    this.active = false;
  }

  typeChange(typeID: number) {
    this.selectedType = this.types.filter(f => f.id === typeID);
    console.log("SelectedType:", this.selectedType[0].expireInDays)
    let dateOffset = this.selectedType[0].expireInDays * (24 * 60 * 60 * 1000);
    let start = new Date();
    let end = new Date();
    end.setTime(end.getTime() + dateOffset);
    this.uploadForm.patchValue({
      start: start,
      end: end
    });
  }

  loadForm() {
    this.uploadForm = this.fb.group({
      type: ["", [Validators.required]],
      displayName: ["", [Validators.required]],
      start: ["", [Validators.required]],
      end: ["", [Validators.required]],
      message: ["", [Validators.required]]
    },{validator: this.dateLessThan('start', 'end')});
  }

  dateLessThan(start: string, end: string) {
    return (group: UntypedFormGroup): {[key: string]: any} => {
      let f = group.controls[start];
      let t = group.controls[end];
      if (f.value > t.value) {
        return {
          dates: "start date should be less than end date"
        };
      }
      return {};
    }
}



}
