
import {map} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import { WhereMap } from './where-map';
import { Map } from '../../../map/map';
import { SiteService } from '../../../site/site.service';
import { Site } from '../../../site/site';
import { MarkerService } from '../../../markers/marker.service';
import { CLEAR_MOUSEOVER_EVENT } from '../../../events/event.reducer';
import { Where } from './where';
import { REQUEST_SET_WHERE_ADDRESS, SET_REQUEST_WHERE } from '../../requests.reducer';
import { Vehicle } from './vehicle/vehicle';

@Component({
    selector: 'app-request-where',
    templateUrl: './where.component.html',
    styleUrls: ['./where.component.css'],
})
export class WhereComponent implements OnInit, OnDestroy {
    public maps: WhereMap[];
    public map: WhereMap;
    public site: Site;
    public siteSub: any;
    public siteLoading;
    public whereSub: any;
    public where: Where;
    public drawnOn;
    public selectedVehicle: number = 0;
    constructor(public store: Store<AppState>, public siteService: SiteService, public markerService: MarkerService, public router: Router, public route: ActivatedRoute){}
    ngOnInit() {
        this.siteLoading = this.siteService.getSiteLoading();
        this.siteSub = this.siteService.getSite().subscribe(site => {
            this.store.dispatch({type: CLEAR_MOUSEOVER_EVENT});
            if(site) {
                this.maps = [];
                if(site.maps) {
                    site.maps.forEach(map => {
                        let newMap = new WhereMap(map, this.store);
                        if(map.markers) {
                            map.markersLocal = this.markerService.createMarkers(map.markers, newMap)
                        } else {
                            map.markersLocal = [];
                        }
                        newMap.markersLocal = map.markersLocal;
                        this.maps.push(newMap);
                    })
                    this.map = this.maps[0];
                } else {
                    //this is probably bad...
                    this.map = new WhereMap({}, this.store);
                }
                this.site = site;
            }
        })
        this.whereSub = this.store.select(s => s.request.where).subscribe(where => this.where = where);
        this.drawnOn = this.store.select(s => s.request.where).pipe(map(where => {
            return where.mapData.map(drawing => drawing.mapID)
        }));
    }
    clearMap() {
        if(this.map) {
            this.map.clear()
            this.map.draw();
        }
    }
    clearAllMaps() {
        if(this.maps) {
            this.maps.forEach(map => {
                map.clear()
            })
        }
        this.map.draw()
    }
    addressChange(address) {
        this.store.dispatch({ type: REQUEST_SET_WHERE_ADDRESS, payload: address})
    }
    setMap(event) {
        this.map = event;
    }
    ngOnDestroy() {
        this.siteSub.unsubscribe();
        this.whereSub.unsubscribe();
    }
    next() {
        this.router.navigate(['../', 'cameras'],{relativeTo: this.route})
    }
    back() {
        this.router.navigate(['../', 'when'], {relativeTo: this.route})
    }
    addVehicle() {
        this.where.vehicles.push(new Vehicle());
        if(this.selectedVehicle < 0) {
            this.selectedVehicle = 0;
        }
    }
    toggleVehicles() {
        if(this.where.vehicleInvolved && this.where.vehicles.length === 0) {
            this.addVehicle();
        }
        this.update();
    }
    updateVehicle(vehicle, change) {
        vehicle = change;
        this.update();
    }
    update() {
        this.store.dispatch({ type: SET_REQUEST_WHERE, payload: this.where });
    }    
    deleteVehicle(index) {
        if(index > -1) {
            if(this.selectedVehicle >= index && this.selectedVehicle > 0) {
                this.selectedVehicle = index - 1;
            }
            this.where.vehicles.splice(index, 1);
            this.update();
        }
    }    
}