export class SortData {
    field: string;
    inverse: boolean;
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config);
        }
    }
}

// sort returns a new sorted array, doesn't work with nested fields and will not change order if one of the fields is empty or a different type
export function sort<T>(data: T[], s: SortData): T[] {
    return [...data].sort((a, b) => {
        let mult = 1;
        if(s.inverse) {
            mult = -1;
        }
        if(!a || !b || !a.hasOwnProperty(s.field) || !b.hasOwnProperty(s.field)) {
            return 0;
        }
        if((a[s.field] === null || typeof a[s.field] === 'undefined') && (b[s.field] === null || typeof a[s.field] === 'undefined')) {
            return 0;
        }
        if(a[s.field] === null || typeof a[s.field] === 'undefined') {
            return -1 * mult;
        }
        if(b[s.field] === null || typeof a[s.field] === 'undefined') {
            return 1 * mult;
        }
        if(typeof a[s.field] !== typeof b[s.field]) {
            return 0;
        }
        switch (typeof a[s.field]) {
        case 'string':
            return a[s.field].localeCompare(b[s.field]) * mult;
        case 'number':
            return (a[s.field] - b[s.field]) * mult;
        case 'object':
            if(a[s.field].getTime && b[s.field].getTime) {
                return (a[s.field].getTime() - b[s.field].getTime()) * mult;
            }
            return 0;
        default:
            return 0;
        }
    })
}