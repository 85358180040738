export class CountChanges {
    sevenDays: DayComparision;
    thirtyDays: DayComparision;
    constructor(config?: any) {
        if(config) {
            if(config.sevenDays) {
                this.sevenDays = new DayComparision(config.sevenDays)
            }
            if(config.thirtyDays) {
                this.thirtyDays = new DayComparision(config.thirtyDays)
            }
        }
    }
}

export class DayComparision {
    last: number;
    prev: number;
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config);
        }
    }
}