// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site {
    width: 100%;
}

.siteName {
    text-align: center;
}

h4 {
    font-size: 18px;
}

.disabled {
    color: red;
}

.package-name {
    text-align: center;
    font-weight: normal;
}

h5 {
    font-size: 14px;
}

.metrics {
    padding: 6px 0px;
    text-align: center;
    font-size: .8em;
    width: 100%;
}

.flex {
    display: flex;
    justify-content: space-around;
}

label {
    font-weight: bold;
}

.hours {
    display: block;
}

.hoursDays {
    display: block;
}

.days {
    display: block;
}

.map{
    margin-bottom: 10px;
}

.verifiedDate {
    display: block;
}

`, "",{"version":3,"sources":["webpack://./src/app/dashboard/site-display/site-display.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".site {\r\n    width: 100%;\r\n}\r\n\r\n.siteName {\r\n    text-align: center;\r\n}\r\n\r\nh4 {\r\n    font-size: 18px;\r\n}\r\n\r\n.disabled {\r\n    color: red;\r\n}\r\n\r\n.package-name {\r\n    text-align: center;\r\n    font-weight: normal;\r\n}\r\n\r\nh5 {\r\n    font-size: 14px;\r\n}\r\n\r\n.metrics {\r\n    padding: 6px 0px;\r\n    text-align: center;\r\n    font-size: .8em;\r\n    width: 100%;\r\n}\r\n\r\n.flex {\r\n    display: flex;\r\n    justify-content: space-around;\r\n}\r\n\r\nlabel {\r\n    font-weight: bold;\r\n}\r\n\r\n.hours {\r\n    display: block;\r\n}\r\n\r\n.hoursDays {\r\n    display: block;\r\n}\r\n\r\n.days {\r\n    display: block;\r\n}\r\n\r\n.map{\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.verifiedDate {\r\n    display: block;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
