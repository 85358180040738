import { Component, Input } from '@angular/core';


@Component({
    selector: 'app-map-tooltip',
    templateUrl: './map-tooltip.component.html',
    styleUrls: ['./map-tooltip.component.css'],
})

export class MapTooltipComponent {
    text = "";
    top = 0;
    left = 0;
    display = "none";
    public imgUrl = "";
}