import { createReducer, Action, on } from "@ngrx/store";
import { initialState, State, initialSelectedTime } from './state';
import * as RawActions from "./actions";
import { MotionDateTime } from "app/motion/motion-datetime-modal/motion-datetime";

const reducer = createReducer(
    initialState,
    on(RawActions.addCamera, (state: State, { marker}) => {
        if(state.selectedCameras.map(c => c.id).indexOf(marker.id) === -1) {
            return { 
                ...state, 
                selectedCameras: [...state.selectedCameras, marker],
            }
        } else {
            return state;
        }
    }),
    on(RawActions.removeCamera, (state: State, {marker}) => {
        return { ...state, selectedCameras: [...state.selectedCameras.filter(m => m.id !== marker.id)]};
    }),
    on(RawActions.resetCameras, (state: State) => {
        return { ...state, selectedCameras: [] };
    }),
    on(RawActions.hideTimeModal, (state: State) => {
        return { ...state, showTimeModal: false };
    }),
    on(RawActions.submitTimeChange, (state: State, { selectedTime }) => {
        return { ...state, showTimeModal: false, selectedTime: new MotionDateTime(selectedTime) }
    }),
    on(RawActions.clickChangeTimeButton, (state: State) => {
        return { ...state, showTimeModal: true };
    }),
    on(RawActions.setMaps, (state: State, {maps}) => {
        return { ...state, selectionMaps: maps };
    }),
    on(RawActions.maxCameraModalOpen, (state: State) => {
        return { ...state, showTooManyCams: true };
    }),
    on(RawActions.maxCameraModalClose, (state: State) => {
        return { ...state, showTooManyCams: false };
    }),
    on(RawActions.setDescription, (state: State, { description }) => {
        return { ...state, description: description };
    }),
    on(RawActions.showTimeUpdate, (state: State) => {
        return { ...state, showTimeUpdateModal: true };
    }),
    on(RawActions.hideTimeUpdate, (state: State) => {
        return { ...state, showTimeUpdateModal: false };
    }),
    on(RawActions.resetRaw, (state: State) => {
        return { ...state, selectedCameras: [], selectedTime: initialSelectedTime(), description: "" };
    }),
    on(RawActions.requestSubmited, (state: State) => {
        return { ...state, showTimeUpdateModal: false };
    }),
    on(RawActions.setRawSiteID, (state: State, { siteID }) => {
        return { ...state, rawSiteID: siteID };
    }),
)


export function rawRequestReducer(state: State | undefined, action: Action) {
    return reducer(state, action)
}