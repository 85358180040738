// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeSelect {
  margin-top: 15px;
}

.label {
  display: block;
  font-weight: bold;
  text-indent: 8px;
  color: #656565;
}

.label, h3 {
  text-align: center;
}

button {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/metrics/logins/logins.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".timeSelect {\r\n  margin-top: 15px;\r\n}\r\n\r\n.label {\r\n  display: block;\r\n  font-weight: bold;\r\n  text-indent: 8px;\r\n  color: #656565;\r\n}\r\n\r\n.label, h3 {\r\n  text-align: center;\r\n}\r\n\r\nbutton {\r\n  margin-left: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
