import { createReducer, on, Action } from '@ngrx/store';
import { initialState, State } from './state';
import * as AdminActions from './actions';


const reducer = createReducer(
    initialState,
    on(AdminActions.fetchSitesSuccess, (state: State, {sites}) => {
        return {
            ...state,
            sites: sites,
            sitesLoaded: true,
            sitesLoading: false,
        }
    }),
    on(AdminActions.fetchSitesFailure, (state: State) => {
        return {
            ...state,
            sites: [],
            sitesLoaded: false,
            sitesLoading: false,
        }
    }),
    on(AdminActions.fetchSites, (state: State) => {
        return {
            ...state,
            sitesLoading: true,
            sitesLoaded: false,
        }
    }),
);

export function adminReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
