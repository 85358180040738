import { MotionCameraMarker } from '../motion-selector/motion-camera-marker';
import { MotionDateTime } from '../motion-datetime-modal/motion-datetime';
import { TimeObj } from 'app/time-obj';
import { MotionData, MotionFile } from '../motion-data';
import { MotionRequest } from '../motion-request';
import { CloudVideo } from 'app/cloud/cloud-video';
import { Map } from 'app/map/map';

export const MotionKey = "motion";


export interface State {
    motionSiteID: number,
    selectedCameras: MotionCameraMarker[],
    selectedTime: MotionDateTime,
    motionData: MotionData,
    motionLoading: boolean,
    motionLoaded: boolean,
    motionFailed: boolean,
    lastRequest: MotionRequest,
    motionSelectorCollapsed: boolean,
    cameraIDToName: {[id: number]:string},
    cameraIDToDisplayName: {[id: number]:string},

    cloudTime: TimeObj,
    cloudVideos: CloudVideo[],
    cloudCamera: number,
    cloudModalVisible: boolean,
    selectionMaps: Map[],
    currentSelectionMapID: number,

    motionViewed: {[fileURL: string]: boolean},
    selectedMotionFile: MotionFile,
    maxCameraModalVisible: boolean,
}

export const initialState: State = {
    motionSiteID: 0,
    selectedCameras: [],
    selectedTime: initialSelectedTime(),
    motionData: null,
    motionLoading: false,
    motionLoaded: false,
    motionFailed: false,
    lastRequest: null,
    motionSelectorCollapsed: false,
    cameraIDToName: {},
    cameraIDToDisplayName: {},
    
    cloudTime: null,
    cloudVideos: [],
    cloudCamera: null,
    cloudModalVisible: false,
    selectionMaps: [],
    currentSelectionMapID: 0,

    motionViewed: {},
    selectedMotionFile: null,
    maxCameraModalVisible: false,
}

function initialSelectedTime(): MotionDateTime {
    let mot = new MotionDateTime();
    let startD = new Date();
    startD.setHours(startD.getHours() - 2, 0, 0, 0);
    let endD = new Date();
    endD.setHours(endD.getHours(), 0 , 0, 0);
    mot.motionStartDate = new TimeObj(startD);
    mot.motionEndDate = new TimeObj(endD);
    return mot;
}