import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ServiceHelpersService } from '../service-helpers.service';
import { Observable } from 'rxjs';
import { AccessKeyService } from '../users/access-key.service';
import { map, catchError } from 'rxjs/operators';
import { Layout } from './layout';
import { GridSize } from './gridsize';
import { CVSStatus } from './cvs-status';

@Injectable({
  providedIn: 'root'
})
export class CvsService {
  public headers = {};
  constructor(
    private http: HttpClient,
    private helper: ServiceHelpersService,
    private accessKeyService: AccessKeyService,
  ) {
    this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key}));
   }

   getLayouts(siteID: number): Observable<Layout[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<Layout[]>(`/rest/site/${siteID}/cvs/layouts`, { headers: headers }).pipe(catchError(this.helper.handleError), map(layouts => {
      if (layouts && layouts.map) {
        return layouts.map(e => new Layout(e))
      }
      return layouts;
    }));
  }

  getLayout(siteID: number, layoutID: number, withCameras: boolean): Observable<Layout> {
    let params = new HttpParams();
    if(withCameras) {
      params = params.set('withCameras', 'true');
    }
    let headers = new HttpHeaders(this.headers);
    return this.http.get<Layout>(`/rest/site/${siteID}/cvs/layout/${layoutID}`, {headers: headers, params: params }).pipe(catchError(this.helper.handleError))
  }

  getGridSizes(siteID: number): Observable<GridSize[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<GridSize[]>(`/rest/site/${siteID}/cvs/gridsizes`, { headers: headers }).pipe(catchError(this.helper.handleError), map(gridSizes => {
      if (gridSizes && gridSizes.map) {
        return gridSizes.map(e => new GridSize(e))
      }
      return gridSizes;
    }));
  }

  addLayout(layout: Layout): Observable<Layout> {
    let headers = new HttpHeaders(this.headers);
    return this.http.post<Layout>(`/rest/site/${layout.siteID}/cvs/layout`, layout, { headers: headers }).pipe(catchError(this.helper.handleError), map(l => new Layout(l)));
  }

  updateLayout(layout: Layout): Observable<Layout> {
    let headers = new HttpHeaders(this.headers);
    return this.http.put<Layout>(`/rest/site/${layout.siteID}/cvs/layout`, layout, { headers: headers }).pipe(catchError(this.helper.handleError), map(l => new Layout(l)));
  }

  deleteLayout(layout: Layout) {
    let headers = new HttpHeaders(this.headers);
    return this.http.delete(`/rest/site/${layout.siteID}/cvs/layout/${layout.id}`, { headers: headers }).pipe(catchError(this.helper.handleError));
  }

  generateStandaloneSession(siteID: number, layoutID: number): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders(this.headers);
    return this.http.post<any>(`/rest/site/${siteID}/cvs/standalonesession`, { headers: headers, observe: 'response' });
  }

  getSiteCVSStatus(siteID: number): Observable<CVSStatus> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<CVSStatus>(`/rest/site/${siteID}/cvs/status`, {headers: headers }).pipe(catchError(this.helper.handleError))
  }
  generateCVS(siteID: number, gridSizeID: number): Observable<Layout[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.post<Layout[]>(`/rest/site/${siteID}/cvs/generate/${gridSizeID}`, {}, {headers: headers}).pipe(catchError(this.helper.handleError), map(layouts => {
      if(layouts && layouts.map) {
        return layouts.map(l => new Layout(l));
      } else {
        return layouts;
      }
    }))
  }
}
