import { AlertEntry } from '../manage-alert-entries/alert-entry';
import { SortData } from "../sort-data";
import { Tag } from '../manage-alert-entries/tag';
import { AlertHit } from '../recent-hits/alert-hit';

export const LPRKey = "lpr"

export interface State {
    selectedTopTab: string,
    selectedBottomTab: string,
    lprSiteID: number,

    // Alert Entries
    alertEntries: AlertEntry[],
    alertEntriesSort: SortData,
    alertEntriesSiteID: number,
    showRemoveAlertEntryModal: boolean,
    alertEntryToRemove: AlertEntry,

    // Recent Hits
    entriesWithRecentHitsInfo: AlertEntry[],
    recentHitsLoading: boolean,
    recentHitsSort: SortData,

    // Save/Edit Alert Entry
    states: string[],
    tags: Tag[],
    editEntry: AlertEntry,
    success: boolean,
    saveDisabled: boolean,

    // Alert Details
    alertDetailsInfo: AlertHit[],
    alertDetailsLoading: boolean,
    alertDetailsSort: SortData,
    alertDetailsActiveAlerts: AlertEntry[],
    alertDetailsSelectedEntry: AlertEntry,

    // Hit Modal
    showViewHitModal: boolean,
    viewHitModalHit: AlertHit,
}

export const initialState: State = {
    selectedTopTab: "recentAlerts",
    selectedBottomTab: "manageAlerts",
    lprSiteID: 0,

    // Alert Entries
    alertEntries: [],
    alertEntriesSort: { field: 'createdDate', inverse: true },
    alertEntriesSiteID: 0,
    showRemoveAlertEntryModal: false,
    alertEntryToRemove: null,

  
    entriesWithRecentHitsInfo: [],
    recentHitsLoading: false,
    recentHitsSort: { field: '', inverse: false },

    // Save/Edit Alert Entry
    states: ['AL', 'AK', 'AZ', 'AR', 'CA','CO', 'CT', 'DE', 'FL', 'GA', 'HI',
    'ID', 'IL', 'IN', 'IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV', 'NH',
    'NJ','NM','NY','NC','ND','OH','OK','OR','PA','PR','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'],
    tags: [],
    editEntry: null,
    success: false,
    saveDisabled: false,

    // Alert Details
    alertDetailsInfo: [],
    alertDetailsLoading: false,
    alertDetailsSort: { field: '', inverse: false },
    alertDetailsActiveAlerts: [],
    alertDetailsSelectedEntry: null,

    // Hit Modal
    showViewHitModal: false,
    viewHitModalHit: null,
}