import { TimeObj } from "app/time-obj";
import { MotionDateTime } from "app/motion/motion-datetime-modal/motion-datetime";
import { RawRequestCameraMarker } from "../raw-request-camera-marker";
import { Map } from "app/map/map";


export const RawKey = "rawRequest"

export interface State {
    selectedTime: MotionDateTime,
    selectedCameras: RawRequestCameraMarker[],
    showTimeModal: boolean,
    selectionMaps: Map[],
    currentSelectionMapID: number,
    showTooManyCams: boolean,
    description: string,
    showTimeUpdateModal: boolean,
    rawSiteID: number,
}

export const initialState: State = {
    selectedTime: initialSelectedTime(),
    selectedCameras: [],
    showTimeModal: false,
    selectionMaps: [],
    currentSelectionMapID: 0,
    showTooManyCams: false,
    description: "",
    showTimeUpdateModal: false,
    rawSiteID: 0,
}

export function initialSelectedTime(): MotionDateTime {
    let mot = new MotionDateTime();
    let startD = new Date();
    startD.setHours(startD.getHours() - 2, 0, 0, 0);
    let endD = new Date();
    endD.setHours(endD.getHours(), 0 , 0, 0);
    mot.motionStartDate = new TimeObj(startD);
    mot.motionEndDate = new TimeObj(endD);
    return mot;
}