import { Lookup } from "app/lookup";
import { UserInfo } from "./user-info";

export class SiteGroup {
    id: number;
    name: string;
    createdDate: Date;

    constructor(config?: any) {
      if (config) {
        Object.assign(this, config);
        if (config.createdDate) {
          this.createdDate = new Date(this.createdDate);
        }
      }
    }
  }

  export class SiteGroupFull extends SiteGroup {
    sites: Lookup[] = [];
    users: UserInfo[] = [];
    constructor(config?: any) {
      super(config);
      if(config) {
        if(config.users) {
          this.users = config.users.map(u => new UserInfo(u));
        }
        if(config.sites) {
          this.sites = config.sites
        }
      }
    }
  }