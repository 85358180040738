import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceHelpersService } from './service-helpers.service';
import { catchError } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { User } from './users/user';
import { UserSurveyData } from './users/user-survey-data';
import { config } from './app.config';

@Injectable({
  providedIn: 'root'
})

export class SurvicateService {

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    public http: HttpClient,
    public serviceHelpersService: ServiceHelpersService,
    private window: Window
  ) { }

  user: User;
  data: UserSurveyData;

  public loadSurvicate(user: User, data: UserSurveyData): void {

    this.user = user;
    this.data = data;

    let w = this.window as any;

    let setAttributes = this.setAttributes.bind(this);
    let addListeners = this.addListeners.bind(this);
    let showSurvey = this.showSurvey.bind(this);

    w.addEventListener("SurvicateReady", setAttributes);
    w.addEventListener("SurvicateReady", addListeners);
    w.addEventListener("SurvicateReady", showSurvey);

    var s = this._document.createElement('script');
    s.src = 'https://survey.survicate.com/workspaces/35ed9b7512e2b471d005811aa5a2f97d/web_surveys.js';
    s.async = true;
    var e = this._document.getElementsByTagName('script')[0];
    e.parentNode.insertBefore(s, e);
  }

  public surveyInteraction(surveyID: string, action: string): void {
    let body = JSON.stringify({ surveyID: surveyID, action: action });
    this.postSurveyInteraction(body).subscribe(() => {
      return;
    }, error => {
      console.log("survicate error: ", error);
    });
  }

  public setAttributes() {

    let user = this.user;
    let w = this.window as any;
    w._sva.setVisitorTraits({
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      hard_type: user.typeHard.name,
      hard_type_id: user.typeHard.id,
      friendly_type: user.typeFriendly.name,
      friendly_type_id: user.typeFriendly.id,
      user_id: user.id,
      sites_count: user.sites.length,
      sites_dns_count: user.sites.filter(site => site.isDisabled === true).length,
    });

  }

  public addListeners() {

    let w = this.window as any;

    let surveyInteraction = this.surveyInteraction.bind(this);

    w._sva.addEventListener('question_answered', function (surveyID, questionID, anser) {
      surveyInteraction(surveyID, "question_answered");
    });

    w._sva.addEventListener('survey_completed', function (surveyID) {
      surveyInteraction(surveyID, "survey_completed");
    });

    w._sva.addEventListener('survey_closed', function (surveyID) {
      surveyInteraction(surveyID, "survey_closed");
    });

  }

  public showSurvey() {

    var surveyID = this.data.surveyID;
    let w = this.window as any;

    if (surveyID !== "" && w._sva) {

      var options = {
        forceDisplay: true,
        displayMethod: 'delayed',
        displayOptions: {
          delay: 1
        }
      }
      w._sva.showSurvey(surveyID, options)
    }
  }

  postSurveyInteraction(body: string) {
    let headers = new HttpHeaders(Object.assign({ 'Content-Type': 'application/json' }, {}));
    return this.http.post<void>(config.apiUrl + 'survey', body, { headers: headers }).pipe(catchError(this.serviceHelpersService.handleError))
  }

}