import { createAction, props } from "@ngrx/store";
import { Lookup } from "app/lookup";
import { UserInfo } from "../user-info";
import { UserSearch } from "app/admin/admin-user-selector/user-search";
import { State } from '@progress/kendo-data-query';
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { PermissionTemplate } from '../../admin-permission-templates/permission-template';
import { SitePermissions } from 'app/permissions';


export const loadManComps = createAction("[Admin Perms] Load ManComps");

export const manCompsLoaded = createAction("[Admin Perms] ManComps Loaded", props<{manComps: Lookup[]}>());

export const loadManCompsFailed = createAction("[Admin Perm] Failed to load Man comps");

export const loadSiteGroups = createAction("[Admin Perms] Load Site Groups");

export const siteGroupsLoaded = createAction("[Admin Perms] Site Groups Loaded", props<{siteGroups: Lookup[]}>());

export const loadSites = createAction("[Admin Perms] Load Sites");

export const sitesLoaded = createAction("[Admin Perms] Sites Loaded", props<{sites: Lookup[]}>());

export const loadHardTypes = createAction("[Admin Perms] Load Hard Types");

export const hardTypesLoaded = createAction("[Admin Perms] Hard Types Loaded", props<{hardTypes: Lookup[]}>());

export const loadTypes = createAction("[Admin Perms] Load Types");

export const typesLoaded = createAction("[Admin Perms] Types Loaded", props<{types: Lookup[]}>());

export const loadPermTemplates = createAction("[Admin Perms] Load Permission Templates");

export const permTemplatesLoaded = createAction("[Admin Perms] Permission Templates loaded", props<{permTemplates: Lookup[]}>());

export const userSearch = createAction("[Admin Perms] User Seach", props<{search: UserSearch}>());

export const userSearchSuccess = createAction("[Admin Perms] User Search Success", props<{users: UserInfo[]}>());

export const userSearchFailed = createAction("[Admin Perms] User Search Failed");

export const setUsersGridState = createAction("[Admin Perms] Set Users Grid State", props<{gridState: State}>());

export const setSelectedUsersKeys = createAction("[Admin Perms] Set Selected Users Keys", props<{keys: number[]}>());

export const setSelectAllCheckboxState = createAction("[Admin Perms] Set Select All Checkbox State", props<{checkbox: SelectAllCheckboxState}>());

export const setAction = createAction("[Admin Perms] Set Action", props<{action: string}>());

export const setNewPermissions = createAction("[Admin Perms] Set New Permissions", props<{perms: {site: any, document: any}}>());

export const setSiteClude = createAction("[Admin Perms] set siteClude", props<{siteClude: "exclude" | "include"}>());

export const setSites = createAction("[Admin Perms] Set Sites", props<{sites: number[]}>());

export const submit = createAction("[Admin Perms] Submit");

export const submitSuccess = createAction("[Admin Perms] Submit Success", props<{transactionID: number}>());

export const submitFail = createAction("[Admin Perms] Submit Failed");

export const setShowStatusModal = createAction("[Admin Perms] Show Status Modal", props<{value: boolean}>());

export const reset = createAction("[Admin Perms] Reset");

export const setSearchForm = createAction("[Admin Perms] Set Search From", props<{searchForm: UserSearch}>());

// Admin Permission Templates

export const noAction = createAction("[Admin Perms] Admin No Action");

export const loadPermissionTemplates = createAction("[Admin Perms] Admin Load Permission Templates");

export const permissionTemplatesLoaded = createAction("[Admin Perms] Admin Permission Templates Loaded", props<{templates: PermissionTemplate[]}>());

export const loadPermissionTemplatesFailed = createAction("[Admin Perm] Admin Failed to load Permission Templates");

export const setPermissionTemplatesGridState = createAction("[Admin Perm] Admin Set Permission Templates Grid State", props<{gridState: State}>());

export const checkTemplateForUsers = createAction("[Admin Perm] Admin Check Template for Users", props<{template: PermissionTemplate}>());

export const setShowDeletePermissionTemplateModal = createAction("[Admin Perm] Admin Set Show Delete Permission Template Modal", props<{show: boolean}>())

export const setTemplateHasUsers = createAction("[Admin Perm] Admin Set Template Has Users", props<{hasUsers: boolean}>());

export const deletePermissionTemplate = createAction("[Admin Perm] Admin Delete Template", props<{id: number}>());

export const cancelDeletePermissionTemplate = createAction("[Admin Perm] Admin Cancel Delete Template");

export const setPermissionTemplateSelectedForDelete = createAction("[Admin Perm] Admin Set Permission Template Selected For Delete", props<{template: PermissionTemplate}>());

export const removeDeletedPermissionTemplate = createAction("[Admin Perm] Admin Remove Deleted Permission Template from  Permission Templates List", props<{id: number}>());

export const setShowPermissionTemplateForm = createAction("[Admin Perm] Admin Set Show Permission Template Form", props<{show: boolean}>());

export const setPermissionTemplateFormMode = createAction("[Admin Perm] Admin Set Permission Template Form Mode", props<{mode: string}>());

export const setPermissionTemplateSelectedForEdit = createAction("[Admin Perm] Admin Set Permission Template Selected for Edit", props<{template: PermissionTemplate}>());

export const setTemplateName = createAction("[Admin Perm] Admin Set Template Name", props<{name: string}>());

export const setTemplateDescription = createAction("[Admin Perm] Admin Set Template Description", props<{desc: string}>());

export const setTemplatePermissions = createAction("[Admin Perm] Admin Set Template Permissions", props<{permissions: SitePermissions}>());

export const createPermissionTemplate = createAction("[Admin Perms] Admin Create Permission Template");

export const updatePermissionTemplate = createAction("[Admin Perms] Admin Update Permission Template");

export const addCreatedPermissionTemplate = createAction("[Admin Perm] Admin Add Created Permission Template to Permission Templates List", props<{template: PermissionTemplate}>());

export const replaceUpdatedPermissionTemplate = createAction("[Admin Perm] Admin Replace Updated Permission Template in Permission Templates List", props<{template: PermissionTemplate}>());

export const setTemplateSaving = createAction("[Admin Perm] Admin Set Template Saving", props<{saving: boolean}>());