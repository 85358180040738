import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NewRequestComponent } from './new-request/new-request.component';
import { WhenComponent } from './new-request/when/when.component';
import { WhatComponent } from './new-request/what/what.component';
import { WhereComponent } from './new-request/where/where.component';
import { CamerasComponent } from './new-request/cameras/cameras.component';
import { ContactInfoComponent } from './new-request/contact-info/contact-info.component';
import { ConfirmationComponent } from './new-request/confirmation/confirmation.component';

import { AppGuard } from '../app-guard.service';
import { WhenGuard } from './new-request/when/when-guard.service';
import { WhatGuard } from './new-request/what/what-guard.service';
import { WhereGuard } from './new-request/where/where-guard.service';
import { CamerasGuard } from './new-request/cameras/cameras-guard.service';
import { ContactInfoGuard } from './new-request/contact-info/contact-info-guard.service';
import { ConfirmationGuard } from './new-request/confirmation/confirmation-guard.service';
import { RequestAuthGuard } from './request-auth-guard.service';
import { SiteLoaderGuard } from '../site/site-loader-guard.service';
import { UserLoaderGuard } from '../users/user-loader-guard.service';

const requestsRoutes: Routes = [
    {
        path: 'site/:siteID/request/new-request',
        component: NewRequestComponent,
        canActivate: [SiteLoaderGuard],
        canActivateChild: [ AppGuard, UserLoaderGuard, RequestAuthGuard ],
        children: [
            { path: 'when', component: WhenComponent, canActivate: [ WhenGuard ] },
            { path: 'what', component: WhatComponent, canActivate: [ WhatGuard ] },
            { path: 'where', component: WhereComponent, canActivate: [ WhereGuard] },
            { path: 'cameras', component: CamerasComponent, canActivate: [ CamerasGuard ]},
            { path: 'contact-info', component: ContactInfoComponent, canActivate: [ ContactInfoGuard ]},
            { path: 'confirmation', component: ConfirmationComponent, canActivate: [ ConfirmationGuard]},
        ]
    }
]
export const requestsRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(requestsRoutes);