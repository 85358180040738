// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  auth: {
    audience: "https://ngauth0.watchtower-security.io",
    scope: "openid profile email",
    domain: "login.watchtower-security.io",
    clientId: 'WqD0o6A0qxj09va3hlHBih5kD4H8kyrs',
  },
  statsig: {
    sdkKey: "client-BpPCCPxq3RQEtxNMQ4dBixojJPUztrFY67ruTQLBqNR",
  },
  amplitude: {
    deploymentKey: "client-UVnX5Yef1wKGXcuBQwuBn1kU3DidcLAq",
  },
  featureFlagProvider: "amplitude",
  insights: "3d421e13-3250-4a05-84da-78c63482f54d",
  issueCollectorServiceUrl: "https://watchtower-security.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3ddrgv/b/7/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=01b7cb74",
};
