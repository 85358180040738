import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import SitePackage from "../models/site-package";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { config } from "../../app.config";
import { ServiceHelpersService } from "app/service-helpers.service";

@Injectable()
export class SitePackageService {
  constructor(private http: HttpClient, private serviceHelpersService: ServiceHelpersService) {}

  public fetch(): Observable<SitePackage[]> {
    const url = `${config.apiUrl}site/packages`;
    return this.http.get<SitePackage[]>(url).pipe(catchError(this.serviceHelpersService.handleError));
  }

  public getByID(id: number): Observable<SitePackage> {
    const url = `${config.apiUrl}site/package/${id}`;
    return this.http.get<SitePackage>(url).pipe(catchError(this.serviceHelpersService.handleError));
  }

  public update(sitePackage: SitePackage): Observable<SitePackage> {
    const url = `${config.apiUrl}admin/site/package`;
    return this.http.put<SitePackage>(url, sitePackage).pipe(catchError(this.serviceHelpersService.handleError));
  }

  public create(sitePackage: SitePackage): Observable<SitePackage> {
    const url = `${config.apiUrl}admin/site/package`;
    return this.http.post<SitePackage>(url, sitePackage).pipe(catchError(this.serviceHelpersService.handleError));
  }

  public delete(id: number): Observable<number> {
    const url = `${config.apiUrl}admin/site/package/${id}`;
    return this.http.delete<number>(url).pipe(catchError(this.serviceHelpersService.handleError));
  }
}
