import * as fromMotionState from './state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MotionCameraMarker } from '../motion-selector/motion-camera-marker';

export const selectMotion = createFeatureSelector<fromMotionState.State>(fromMotionState.MotionKey);

export const selectedCameras = createSelector(
    selectMotion,
    (motion => [...motion.selectedCameras]),
)

export const isCameraSelected = (markerID: number) => {
    return createSelector(
        selectedCameras,
        (cams: MotionCameraMarker[]) => {
            return cams.map(marker => marker.id).indexOf(markerID) !== -1
        },
    )
}

export const numCamsSelected = createSelector(
    selectedCameras,
    (cams: MotionCameraMarker[]) => {
        return cams.length
    }
)

export const mapsWithCamsSelected = createSelector(
    selectedCameras,
    (cams: MotionCameraMarker[]) => cams.map(c => c.map.id),
)

export const selectedTime = createSelector(
    selectMotion,
    (motion => motion.selectedTime),
)

export const selectedHours = createSelector(
    selectedTime,
    (time => {
        if(!time.motionStartDate || !time.motionEndDate) {
            return 0;
        }
        return time.motionStartDate.hoursDifference(time.motionEndDate);
    })
)

export const selectedStartTimeAsDate = createSelector(
    selectedTime,
    (time => {
        return time.motionStartDate.toDate();
    })
)

export const selectedEndTimeAsDate = createSelector(
    selectedTime,
    (time => {
        return time.motionEndDate.toDate();
    })
)

export const motionFiles = createSelector(
    selectMotion,
    (motion => {
        if(motion.motionData && motion.motionData.files) {
            return motion.motionData.files;
        } else {
            return [];
        }
    })
)

export const motionLoading = createSelector(
    selectMotion,
    (motion => {
        return motion.motionLoading;
    })
)

export const motionLoaded = createSelector(
    selectMotion,
    (motion => {
        return motion.motionLoaded;
    })
)

export const motionFailed = createSelector(
    selectMotion,
    (motion => {
        return motion.motionFailed;
    })
)

export const cloudFiles = createSelector(
    selectMotion,
    (motion => {
        if(motion.cloudVideos) {
            return motion.cloudVideos;
        } else {
            return [];
        }
    })
)
export const cloudTime = createSelector(
    selectMotion,
    (motion => {
        return motion.cloudTime;
    }),
)

export const cloudCamera = createSelector(
    selectMotion,
    (motion => {
        return motion.cloudCamera;
    }),
)

export const cloudModalVisible = createSelector(
    selectMotion,
    (motion => motion.cloudModalVisible),
)

export const sameRequest = createSelector(
    selectMotion,
    (motion => {
        if(!motion.lastRequest) {
            return false;
        }
        let selectedCamIDs = motion.selectedCameras.map(cam => cam.camera);
        if(!sameNumberArrays(selectedCamIDs, motion.lastRequest.cameraIDs)) {
            return false;
        }
        if(!(motion.selectedTime.motionStartDate.equals(motion.lastRequest.start) && motion.selectedTime.motionEndDate.equals(motion.lastRequest.end))) {
            return false;
        }
        return true;
    })
)

export const selectorCollapsed = createSelector(
    selectMotion,
    (motion => {
        return motion.motionSelectorCollapsed;
    })
)

export const selectCameraIDToName = createSelector(
    selectMotion,
    (motion => {
        return motion.cameraIDToName;
    })
)

export const selectCameraIDToDisplayName = createSelector(
    selectMotion,
    (motion => {
        return motion.cameraIDToDisplayName;
    })
)

export const motionSiteID = createSelector(
    selectMotion,
    (motion => {
        return motion.motionSiteID;
    }),
)

export const selectionMaps = createSelector(
    selectMotion,
    (motion => {
        return motion.selectionMaps;
    }),
)

export const currentSelectionMapID = createSelector(
    selectMotion,
    (motion => {
        return motion.currentSelectionMapID;
    }),
)

export const motionViewed = createSelector(
    selectMotion,
    (motion => {
        return motion.motionViewed;
    }),
)

export const selectedMotionFile = createSelector(
    selectMotion,
    (motion => {
        return motion.selectedMotionFile;
    }),
)

export const  maxCameraModalVisible = createSelector(
    selectMotion,
    (motion => {
        return motion.maxCameraModalVisible;
    }),
)

export const markerForCameraID = (cameraID: number) => {
    return createSelector(
        selectionMaps,
        (maps => {
            for(let map of maps) {
                for(let marker of map.markersLocal.filter(m => m.type === "camera")) {
                    let m = marker as MotionCameraMarker;
                    if(m.camera === cameraID) {
                        
                        return m;
                    }
                }
            }
            return null;
        })
    )
}

// sameNumberArrays compares two arrays of numbers to see if they contain the same things
function sameNumberArrays(array1: number[], array2: number[]): boolean {
    if(array1.length !== array2.length) {
        return false;
    }
    let s1 = [...array1].sort();
    let s2 = [...array2].sort();
    for(let i = 0; i < s1.length; i++) {
        if(s1[i] !== s2[i]) {
            return false;
        }
    }
    return true;
}