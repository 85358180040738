// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k-appbar .k-appbar-separator {
    margin: 0 8px;
}

.k-appbar-separator {
    height: 24px;
}
.k-appbar-section > * {
    margin: 0px 6px 0px 6px;
}

`, "",{"version":3,"sources":["webpack://./src/app/events/event-filter/event-filter.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".k-appbar .k-appbar-separator {\r\n    margin: 0 8px;\r\n}\r\n\r\n.k-appbar-separator {\r\n    height: 24px;\r\n}\r\n.k-appbar-section > * {\r\n    margin: 0px 6px 0px 6px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
