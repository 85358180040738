
import {take} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ADD_MOUSEOVER_EVENT, REMOVE_MOUSEOVER_EVENT } from '../events/event.reducer'
import { AppState } from '../app.state';
import { MarkerShape, Label, MarkerDrawing } from './marker-shape';
import { Map } from '../map/map';
import { MarkerCircle, MarkerCircleConfig, MarkerPoly, MarkerPolyConfig, MarkerRectangle, MarkerRectConfig, MarkerHeatDot } from './marker-shape';
import { MarkerEventService } from './marker-event.service'
/*export interface Marker {
    draw();
    click(x,y);
    mouseOver(x,y);
    id: number;
    type: string;
    x: number;
    y: number;
    reset();
}
*/

export class Marker {
    public id: number;
    public type: string;
    public shape: string;
    public shapeObj: MarkerShape;
    public store: Store<AppState>;
    public on: boolean;
    public eventService: MarkerEventService;
    public meta: any
    get x() {
        return this.shapeObj.x;
    }
    get y() {
        return this.shapeObj.y;
    }
    get bX() {
        return this.shapeObj.bX;
    }
    get bY() {
        return this.shapeObj.bY;
    }
    get data() {
        return this.shapeObj.data;
    }
    get map() {
        return this.shapeObj.map;
    }
    get displayName() {
        return this.shapeObj.displayName;
    }
    constructor(config: MarkerConfig) {
        this.id = config.id;
        this.type = "marker";
        this.store = config.store;
        this.on = false;
        this.shape = config.shape;
        this.eventService = config.eventService;
        if(config.meta && typeof config.meta === 'string') {
            this.meta = JSON.parse(config.meta);
        }
        switch(config.shape.trim()) {
            case 'circle':              
                this.shapeObj = new MarkerCircle({map: config.map, displayName: config.displayName, data: config.data, label: config.label})
                break;
            case 'poly':
                this.shapeObj = new MarkerPoly({map: config.map, displayName: config.displayName, data: config.data, withPoint: config.type === "camera", label: config.label})
                break;
            case 'rect':
                this.shapeObj = new MarkerRectangle({map: config.map, displayName: config.displayName, data: config.data, label: config.label});
                break;
            case 'point':
                this.shapeObj = new MarkerHeatDot({ map: config.map, displayName: config.displayName, data: config.data, label: config.label })
                break;
            case 'drawing':
                this.shapeObj = new MarkerDrawing({map: config.map, displayName: config.displayName, data: config.data, label: config.label});
                break;
            default:
                console.log("unrecognized shape: ",config.shape, config);
                break;
        }
    }


    draw() {
        this.shapeObj.draw(this.getFill(), this.getStroke());
    }
    click(x:number, y:number) {
        if(this.isCurrentMouseOver()) {
            this.clicked();
        }
    }
    isCurrentMouseOver(): boolean {
        let on = false;
        this.store.select(s => s.mouseOverEvent).pipe(take(1)).subscribe(markers => on = markers[0] ? markers[0].id === this.id : false);
        return on;        
    }

    clicked() {
        console.log("Clicked: Marker")
    }
    mouseOver(x:number, y:number) {
        if(this.shapeObj.inMarker({x,y})) {
            this.isOn();
        } else {
            this.isOff();
        }
    }
    isOn() {
        if(!this.on) {
            this.on = true;
            this.enter();
        }
    }
    isOff() {
        if(this.on) {
            this.on = false;
            this.leave();
        }
    }
    enter() {
        this.store.dispatch({type: ADD_MOUSEOVER_EVENT, payload: this });
        this.shapeObj.map.draw();

    }
    leave() {
        this.store.dispatch({type: REMOVE_MOUSEOVER_EVENT, payload: this });
        this.shapeObj.map.draw();
    }

    reset() {
        this.shapeObj.reset();
    }
    //fill and stroke default to red because these should be overridden
    getFill() {
        return "red";
    }
    getStroke() {
        return "red";
    }
}

export class MarkerConfig {
    id: number;
    shape: string;
    data: any;
    map: Map;
    store: Store<AppState>;
    displayName: string;
    eventService: MarkerEventService;
    meta?: any;
    type: string;
    label: Label;
}