import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as IotLprActions from "./actions";

export const iotLprReducer = createReducer(
    initialState,
    on(IotLprActions.searchIotLpr, (state, { searchParams }) => ({
        ...state,
        loading: true,
        error: null
    })),
    on(IotLprActions.searchIotLprSuccess, (state, { results, totalCount }) => ({
        ...state,
        results,
        totalCount,
        loading: false
    })),
    on(IotLprActions.searchIotLprFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false
    })),
    on(IotLprActions.loadImageSuccess, (state, { id, imageUrl }) => ({
        ...state,
        results: state.results.map(result =>
            result.id === id ? { ...result, imageUrl } : result
        )
    })),
    on(IotLprActions.fetchSiteTimezonesSuccess, (state, { timezones }) => ({
        ...state,
        siteTimezones: timezones.reduce((acc, tz) => ({ ...acc, [tz.siteID]: tz }), {})
    })),
    on(IotLprActions.fetchSitesSuccess, (state, { sites }) => ({
        ...state,
        sites: sites
    })),
    on(IotLprActions.setSelectedSites, (state, { sites }) => ({
        ...state,
        selectedSites: sites
    }))

);
