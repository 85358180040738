import { Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { Logger } from './logger/logger.service';
import { AuthFacade } from './auth/auth.facade';

@Injectable()
export class AppGuard  implements OnDestroy {

    private isLoggedIn: boolean;
    private alive = true;
 
    constructor(public logger: Logger, private authFacade: AuthFacade) {
        this.authFacade.sessionCreated$
        .pipe(takeWhile(() => this.alive))
        .subscribe((sessionCreated) => {
            this.isLoggedIn = sessionCreated;
        });
    }

    ngOnDestroy() {
        this.alive = false;
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (this.isLoggedIn) {
            this.logger.saveUrl(state.url);
        }
        return true;
    }
} 