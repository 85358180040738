import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AccessKeyService } from "../users/access-key.service";
import { ServiceHelpersService } from "../service-helpers.service";
import { config } from "../app.config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DocumentFile } from "./document-file";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class DocumentService {
  public headers = {};

  constructor(
    public http: HttpClient,
    public keyService: AccessKeyService,
    public helperService: ServiceHelpersService
  ) {
    this.keyService
      .getKey()
      .subscribe(
        key => (this.headers = Object.assign(this.headers, { accessKey: key }))
      );
  }

  fetchForSite(siteID: number): Observable<DocumentFile[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http
      .get<DocumentFile[]>(config.apiUrl + "site/" + siteID + "/documents", {
        headers: headers
      })
      .pipe(
        catchError(this.helperService.handleError),
        map(docs => {
          if (docs && docs.map) {
            return docs.map(d => new DocumentFile(d));
          }

          return docs;
        })
      );
  }

  fetchForSiteAdmin(siteID: number): Observable<DocumentFile[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http
      .get<DocumentFile[]>(config.apiUrl + "admin/site/" + siteID + "/documents", {
        headers: headers
      })
      .pipe(
        catchError(this.helperService.handleError),
        map(docs => {
          if (docs && docs.map) {
            return docs.map(d => new DocumentFile(d));
          }

          return docs;
        })
      );
  }

  upload(siteID: number, typeID: number, file: FormData, name?: string) {
    let headers = new HttpHeaders(this.headers);
    let query = "?type=blob&filename=" + name;
    return this.http
      .post(config.apiUrl + "site/" + siteID + "/document/type/" + typeID, file, {
        headers: headers
      })
      .pipe(catchError(this.helperService.handleError));
  }

  update(siteID: number, typeID: number, name: string, file: FormData) {
    let headers = new HttpHeaders(this.headers);
    return this.http.put(
      config.apiUrl +
        "site/" +
        siteID +
        "/document/type/" +
        typeID +
        "/filename/" +
        name,
        file,
      { headers: headers }
    )
    .pipe(catchError(this.helperService.handleError));
  }

  remove(siteID: number, typeID: number, name: string) {
    let headers = new HttpHeaders(this.headers);
    return this.http
      .delete(
        config.apiUrl +
          "site/" +
          siteID +
          "/document/type/" +
          typeID +
          "/filename/" +
          name,
        { headers: headers }
      )
      .pipe(catchError(this.helperService.handleError));
  }
}
