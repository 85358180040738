import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from './state';
import * as siteGroupActions from './actions';
import { SiteGroupFull } from "../site-group";

const reducer = createReducer(
    initialState,

    on(siteGroupActions.setSiteGroups, (state: State, {siteGroups}) => {
        return {
            ...state,
            siteGroups: siteGroups,
            siteGroupsLoading: false,
        }
    }),
    on(siteGroupActions.setSiteGroupsLoading, (state: State, {loading}) => {
        return {
            ...state,
            siteGroupsLoading: loading,
        }
    }),
    on(siteGroupActions.setSiteGroupsGridState, (state: State, {gridState}) => {
        return {
            ...state,
            siteGroupsGridState: gridState,
        }
    }),
    on(siteGroupActions.setShowCreateSiteGroupModal, (state: State, {show}) => {
        return {
            ...state,
            showCreateSiteGroupModal: show,
        }
    }),    
    on(siteGroupActions.appendSiteGroup, (state: State, {siteGroup}) => {
        return {
            ...state,
            siteGroups: [ ...state.siteGroups, siteGroup],
        }
    }),

    // Edit Site Group
    on(siteGroupActions.setEditGroup, (state: State, {editGroup}) => {
        console.log(editGroup)
        return {
            ...state,
            editGroup: editGroup,
            editSelectedSites: [...editGroup.sites],
            editName: editGroup.name,
        }
    }),
    on(siteGroupActions.setEditGroupLoading, (state: State, {editGroupLoading}) => {
        return {
            ...state,
            editGroupLoading: editGroupLoading,
        }
    }),
    on(siteGroupActions.addEditSelectedSite, (state: State, {site}) => {
        return {
            ...state,
            editSelectedSites: [...state.editSelectedSites, site],
        }
    }),
    on(siteGroupActions.removeEditSelectedSite, (state: State, {site}) => {
        return {
            ...state,
            editSelectedSites: state.editSelectedSites.filter(s => s.id !== site.id),
        }
    }),
    on(siteGroupActions.setEditSelectedSites, (state: State, {sites}) => {
        return {
            ...state,
            editSelectedSites: [...sites],
        }
    }),
    on(siteGroupActions.setEditSaving, (state: State, {editSaving}) => {
        return {
            ...state,
            editSaving: editSaving,
        }
    }),
    on(siteGroupActions.setEditSites, (state: State, {sites}) => {
        return {
            ...state,
            editSites: sites,
        }
    }),
    on(siteGroupActions.setEditName, (state: State, {name}) => {
        return {
            ...state,
            editName: name,
        }
    }),
    on(siteGroupActions.editSave, (state: State) => {
        return {
            ...state,
            editSaving: true,
        }
    }),
    on(siteGroupActions.editSuccess, (state: State, {siteGroup}) => {
        return {
            ...state,
            editSaving: false,
            editGroup: null,
            editName: "",
            editSiteFilter: "",
            editSelectedSites: [],
            siteGroups: state.siteGroups.map(s => s.id === siteGroup.id ? siteGroup : s),
        }
    }),
    on(siteGroupActions.setEditSiteFilter, (state: State, {filter}) => {
        return {
            ...state,
            editSiteFilter: filter,
        }
    }),
    on(siteGroupActions.editFailed, (state: State) => {
        return {
            ...state,
            editSaving: false,
        }
    }),
    on(siteGroupActions.setShowDeleteSiteGroupModal, (state: State, {show}) => {
        return {
            ...state,
            showDeleteSiteGroupModal: show,
        }
    }),
    on(siteGroupActions.setSiteGroupSelectedForDelete, (state: State, {group}) => {
        return {
            ...state,
            siteGroupSelectedForDelete: group,
        }
    }),
    on(siteGroupActions.removeDeletedSiteGroup, (state: State, {id}) => {
        return {
            ...state,
            siteGroups: state.siteGroups.filter(group => group.id !== id),
        }
    }),
)

export function siteGroupsReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}

