import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SiteListService } from '../../sites/site-list.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-admin-all-sites',
    templateUrl: './admin-all-sites.component.html',
    styleUrls: ['./admin-all-sites.component.css']
})
export class AdminAllSitesComponent {
    sites;
    constructor(public router: Router, public siteListService: SiteListService){}
    gotoSite(id: number) {
        this.router.navigate(['site', id, 'dashboard']);
    }
    ngOnInit() {
        this.siteListService.getSiteList().pipe(map(sites => sites.filter(s => {
            return s.status === "active"
        }))).subscribe(sites => this.sites = sites);
    }
}