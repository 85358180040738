import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ResizedEvent } from '../../../angular-resize.directive';
import { menuIcon, filterIcon, SVGIcon, minHeightIcon, searchIcon } from "@progress/kendo-svg-icons";



@Component({
  selector: 'app-admin-filter',
  templateUrl: './admin-filter.component.html',
  styleUrls: ['./admin-filter.component.css']
})

export class AdminFilterComponent {

  @Input() enabledOptions: string[];

  @Input('selectedEnabledOption') set setSelectedEnabledOptions(value: string) {
    if (value) {
      this.selectedEnabledOption = value;
    }
  }

  @Input('textFilter') set setTextFilter(value: string) {
    this.textFilter = value;
  }

  @Input('appBarWidth') set setAppBarWidth(value: number) {
    this.appBarWidth = value;
  }

  @Output() clearFilters: EventEmitter<any> = new EventEmitter<any>();

  @Output() appBarWidthChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() textFilterChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() enabledOptionChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() backFillButtonClick: EventEmitter<any> = new EventEmitter<any>();

 
  public appBarWidth: number = 0;
  public collapseWidth: number = 700;
  public selectedEnabledOption: string = "";
  public textFilter: string = '';

  public menuSVG: SVGIcon = menuIcon;
  public filterSVG: SVGIcon = filterIcon;
  public minHeightSVG: SVGIcon = minHeightIcon;
  public searchSVG: SVGIcon = searchIcon;


  constructor() { }

  ngOnInit(): void { }

  onFiltersResized(event: ResizedEvent) {
    this.appBarWidth = Math.round(event.newRect.width);
  }
   
  onBackFillButtonClick() {
    this.backFillButtonClick.emit();
  }

  onTextFilterChange() {
    this.textFilterChange.emit(this.textFilter.trim());
  }

  onClearFilters() {
    this.clearFilters.emit();
  }

  onEnabledOptionChange() {
    this.enabledOptionChange.emit(this.selectedEnabledOption);
  }

}
