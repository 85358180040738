import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { FeatureFlagService } from './feature-flag.service';

export const FeatureFlagGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> => {
  const featureFlagService = inject(FeatureFlagService);
  const featureName = route.data["featureName"];

  if (featureName) {
    return featureFlagService.initialized$.pipe(
      filter(initialized => initialized),
      take(1),
      map(() => {
        const isEnabled = featureFlagService.isFeatureEnabled(featureName);
        return isEnabled ? true : inject(Router).parseUrl("/dashboard");
      }),
    );
  }

  return true;
};
