import { EventSearch } from "./event-search";
import { EventListItem } from "./event-list-item";
import { supportsPassiveEventListeners } from "@angular/cdk/platform";


export interface AdminEventsState {
    eventsLoading: boolean;
    currentSearch: EventSearch;
    previousSearch: EventSearch;
    events: EventListItem[];
    unsortedEvents: EventListItem[];
    sort: { column: string, reverse: boolean };
}

export const initialAdminEventsState: AdminEventsState = {
    eventsLoading: false,
    currentSearch: { 
        outcome: "All",  
        search: "",
        isStarred: false,
        siteID: 0,
        managementCompanyID: 0,
    },
    previousSearch: { 
        outcome: "All",  
        search: "",
        isStarred: false,
        siteID: 0,
        managementCompanyID: 0,
    },
    events: [],
    unsortedEvents: [],
    sort: { column: "", reverse: false },
}

export const SET_ADMIN_EVENTS_CURRENT_SEARCH = 'SET_ADMIN_EVENTS_CURRENT_SEARCH';
export const SET_ADMIN_EVENTS_LOADING = 'SET_ADMIN_EVENTS_LOADING';
export const SET_ADMIN_EVENTS_RESULTS = 'SET_ADMIN_EVENTS_RESULTS';

export const SET_ADMIN_EVENTS_OUTCOME = 'SET_ADMIN_EVENTS_OUTCOME';
export const SET_ADMIN_EVENTS_MANAGEMENT_COMPANY = 'SET_ADMIN_EVENTS_MANAGEMENT_COMPANY';
export const SET_ADMIN_EVENTS_SITE = 'SET_ADMIN_EVENTS_SITE';
export const SET_ADMIN_EVENTS_SEARCH = 'SET_ADMIN_EVENTS_SEARCH';
export const SET_ADMIN_EVENTS_STARRED = 'SET_ADMIN_EVENTS_STARRED';
export const CLEAR_ADMIN_EVENTS = 'CLEAR_ADMIN_EVENTS';
export const TOGGLE_ADMIN_EVENTS_SORT = 'TOGGLE_ADMIN_EVENTS_SORT';


export function adminEventsReducer(state: AdminEventsState = initialAdminEventsState, action): AdminEventsState {
    switch(action.type) {
        case TOGGLE_ADMIN_EVENTS_SORT:
            let newSort: {column: string, reverse: boolean};
            if(state.sort.column !== action.payload) {
                newSort = { column: action.payload, reverse: false };
            } else {
                if(!state.sort.reverse) {
                    newSort = {column: action.payload, reverse: true};
                } else {
                    newSort = {column: "", reverse: false };
                }
            }
            return Object.assign({}, state, { events: sortEvents(state.unsortedEvents, newSort), sort: newSort});
        case SET_ADMIN_EVENTS_CURRENT_SEARCH:
            return Object.assign({}, state, { currentSearch: new EventSearch(action.payload) });
        case SET_ADMIN_EVENTS_LOADING:
            return Object.assign({}, state, { eventsLoading: action.payload });
        case SET_ADMIN_EVENTS_RESULTS:
            return Object.assign({}, state, { eventsLoading: false, previousSearch: new EventSearch(state.currentSearch), unsortedEvents: action.payload, events: sortEvents(action.payload, state.sort) });
        case SET_ADMIN_EVENTS_OUTCOME:
            return Object.assign({}, state, { currentSearch: new EventSearch(Object.assign({}, state.currentSearch, { outcome: action.payload })) });
        case SET_ADMIN_EVENTS_MANAGEMENT_COMPANY:
            return Object.assign({}, state, { currentSearch: new EventSearch(Object.assign({}, state.currentSearch, { managementCompanyID: action.payload })) });
        case SET_ADMIN_EVENTS_SITE:
            return Object.assign({}, state, { currentSearch: new EventSearch(Object.assign({}, state.currentSearch, { siteID: action.payload })) });
        case SET_ADMIN_EVENTS_SEARCH:
            return Object.assign({}, state, { currentSearch: new EventSearch(Object.assign({}, state.currentSearch, { search: action.payload })) });
        case SET_ADMIN_EVENTS_STARRED:
            return Object.assign({}, state, { currentSearch: new EventSearch(Object.assign({}, state.currentSearch, { isStarred: action.payload })) });
        case CLEAR_ADMIN_EVENTS:
            return Object.assign({}, state, {events: []});
        default:
            return state;
    }
}


function sortEvents(events: EventListItem[], sort: {column: string, reverse: boolean}): EventListItem[] {
    if(!sort.column) {
        return [...events]
    }
    return [...events].sort(sortByFieldFunc(sort.column, sort.reverse));
}

function sortByFieldFunc(field: string, reverse: boolean) {
    if(!field) {
        return () => 0
    }
    let r = 1;
    if(reverse) {
        r = -1;
    }
    return (a, b) => {
        if(a[field] > b[field]) {
            return -1 * r;
        }
        if(a[field] < b[field]) {
            return 1 * r;
        }
        return 0;
    }
}