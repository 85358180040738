import { CameraView } from './camera-view';

export class Layout {
    id: number;
    siteID: number;
    name: string;    
    gridSizeID: number;
    cameraViews: CameraView[];
    showCameraName: boolean;

    constructor(config?: any) {
        if (config) {
            Object.assign(this, config);
        }
    }
}