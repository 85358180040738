export class PackageFeature {
  id: number;
  name: string;
  featureID: number;
  packageID: number;
}

export default interface SitePackage {
  id: number;
  name: string;
  features: PackageFeature[];
}

export function generateMockSitePackage(): SitePackage {
  return {
    id: 1,
    name: "name",
    features: generateMockPackageFeatures(),
  };
}

export function generateMockPackageFeatures(): PackageFeature[] {
  return [
    {
      id: 1,
      name: "name",
      featureID: 1,
      packageID: 1,
    },
  ];
}
