import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringLimit'
})
export class StringLimitPipe implements PipeTransform {

  transform(value: string, limit?: number): string {
    if(!limit && limit !== 0) {
      limit = 20;
    }
    if(limit < 4) {
      limit = 4;
    }
    if(value.length > limit) {
      return value.slice(0, limit - 3) + "..."
    } else {
      return value;
    }
  }

}
