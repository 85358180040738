import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { User } from '../../users/user';
import { UserType } from '../../users/user-type';
import { UserService } from '../../users/user.service';
import { emailValidator } from '../../validators/validators';

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.css'],
})
export class UserFormComponent {
    @Input('user') set setUser(value: User) {
        this.user = value;
        this.buildForm();
    }
    @Output('save') save:EventEmitter<User> = new EventEmitter<User>();
    userForm: UntypedFormGroup;
    user: User = new User();
    types: {} = {};
    typeNames: string[] = [];
    public titles: string[] = ['', 'Mr.', 'Ms.', 'Mrs.', 'Sgt.', 'Det.', 'Ofc.', 'Capt.', 'Insp.'];
    //public mask: (string | RegExp)[] = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public mask = "";
    
    constructor(public fb: UntypedFormBuilder, public userService: UserService) {}
    ngOnInit(): void {
        this.userService.getTypes().subscribe(types => {
            this.types = types.reduce((prev, next) => {
                prev[next.name] = next
                return prev;
            }, {});
            this.typeNames = types.map(type => type.name);
        });
        this.buildForm();

        // this.userForm.get("email").valueChanges.subscribe(email => {
        //     this.userForm.get('userName').setValue(email);
        // })

    }
    
    buildForm(): void {
        this.userForm = this.fb.group({
            'userName': [this.user.userName],
            'firstName': [this.user.firstName,[Validators.maxLength(45)]],
            'lastName': [this.user.lastName,[Validators.maxLength(45)]],
            'title': [this.user.title],
            'email': [this.user.email, [Validators.required, Validators.minLength(2), Validators.maxLength(45), emailValidator()]],
            'phoneNumber': [this.user.phoneNumber],
            'typeFriendly': [this.user.typeFriendly ? this.user.typeFriendly.name : ''],//this is only the string in the form so make sure to convert back to the object before submiting

        });
        this.userForm.valueChanges.subscribe(data => this.onvalueChanged(data));
        this.onvalueChanged();
    }

    onvalueChanged(data?: any) {

        if (!this.userForm) {
            return;
        }

        const form = this.userForm;
        for(const field in this.formErrors) {
            this.formErrors[field] = '';
            const control = form.get(field);
            if(control && control.dirty && !control.valid) {
                const messages = this.validationMessages[field];
                for (const key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }
    }

    saveUser() { 
        if(this.userForm.valid) {            
            let user = new User(this.userForm.value);
            user.typeFriendly = this.types[this.userForm.value.typeFriendly];
            this.save.emit(user);
        }
    }

    formErrors = {
        'email': '',
        'firstName': '',
        'lastName': '',
    }

    validationMessages = {
        'email': {
            'required': 'Email is required.',
            'email': 'Email is not valid.',
            'minlength': 'Email must be at least 2 characters long.',
            'maxlength': 'Email cannot be more than 45 characters long.',
        },
        'firstName': {
             'maxlength': 'Firstname cannot be more than 45 characters long.',
        },
        'lastName': {
            'maxlength': 'Lastname cannot be more than 45 characters long.',
       }
    }

}