import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { AppState } from "app/app.state";
import { Observable } from "rxjs";
import * as actions from '../store/actions';
import * as selectors from '../store/selectors';

@Component({
    selector: "app-event-synopsis-modal",
    templateUrl: "./event-synopsis-modal.component.html",
    styleUrls: ["./event-synopsis-modal.component.css"],
})
export class EventSynopsisModalComponent implements OnInit {
    constructor(
        private store: Store<AppState>,
    ) { }

    public showEventSynopsisModal$: Observable<boolean>;
    public eventSynopsisDetailLevel: { text: string, value: string };
    public eventSynopsisDescriptionSelector: { text: string, value: string };
    public eventSynopsisDetailLevels = [
        { text: "Low-Level", value: "low" },
        { text: "Medium-Level", value: "medium" },
        { text: "High-Level", value: "high" },
    ];
    public eventSynopsisDescriptionSelectors = [
        { text: "Summary", value: "summary" },
        { text: "Description", value: "description" },
        { text: "Both", value: "both" },
    ];

    ngOnInit(): void {
        this.showEventSynopsisModal$ = this.store.pipe(select(selectors.showEventSynopsisModal));

        this.eventSynopsisDetailLevel = this.eventSynopsisDetailLevels[2];
        this.eventSynopsisDescriptionSelector = this.eventSynopsisDescriptionSelectors[2];
    }

    onGenerateEventSynopsis(detailLevel: string, descriptionSelector: string, categoryIDs: number[] = []): void {
        this.store.dispatch(actions.generateEventSynopsis({
            detailLevel: detailLevel,
            descriptionSelector: descriptionSelector,
            categoryIDs: categoryIDs,
        }));
    }

    onGenerateEventSynopsisStream(detailLevel: string, descriptionSelector: string, categoryIDs: number[] = []): void {
        this.store.dispatch(actions.generateEventSynopsisStream({
            detailLevel: detailLevel,
            descriptionSelector: descriptionSelector,
            categoryIDs: categoryIDs,
        }));
    }

    onCloseEventSynopsisModal(): void {
        this.store.dispatch(actions.hideEventSynopsisModal());
    }
}
