export class SiteListItem {
    siteName: string;
    siteID: number;
    managementCompanyID?: number;
    managementCompanyName?: string;
    status?: string;
    packageID?: number;
    packageName?: string;
    allowLEAccess?: boolean;
    isDisabled?: boolean;
    constructor(siteName: string, siteID: number, managementCompanyID?: number, managementCompanyName?: string, status?: string, packageID?: number, packageName?: string, allowLEAccess?: boolean, isDisabled?: boolean) {  
        this.siteName = siteName;
        this.siteID = siteID;
        this.managementCompanyID = managementCompanyID;
        this.managementCompanyName = managementCompanyName;
        this.status = status;
        this.packageID = packageID;
        this.packageName = packageName;
        this.allowLEAccess = allowLEAccess;
        this.isDisabled = isDisabled;        
    }
}