// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-msg {
    margin-top: 10px;
    color: red;
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-extended-storage/backfill-form/backfill-form.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,eAAe;AACnB","sourcesContent":[".calendar-container {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.error-msg {\r\n    margin-top: 10px;\r\n    color: red;\r\n    font-size: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
