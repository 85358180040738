import { SiteTimezone } from "../../../site/site-timezone";
import { IotLprResult, IotLprSearchParams } from "../iot-lpr";
import { AdminSiteListItem } from '../../adminSiteListItem';

export const iotLprKey = "iotLpr";

export interface State {
    results: IotLprResult[];
    totalCount: number;
    loading: boolean;
    error: string | null;
    siteTimezones: { [siteID: number]: SiteTimezone };
    sites: AdminSiteListItem[];
    selectedSites: AdminSiteListItem[];
}

export const initialState: State = {
    results: [],
    totalCount: 0,
    loading: false,
    error: null,
    siteTimezones: {},
    sites: [],
    selectedSites: []
}
