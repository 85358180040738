import { CameraMarker } from "app/camera/camera-marker";
import { clickCamera } from "./store/actions";
import { select } from "@ngrx/store";
import { isCameraSelected, primaryCamera } from "./store/selector";
import { take } from "rxjs/operators";


export class AddCamsMarker extends CameraMarker {
    clicked() {
        if(!this.isPrimary()) {
            this.store.dispatch(clickCamera({marker: this}));
            this.map.draw();
        }
    }
    draw() {
        this.store.pipe(select(isCameraSelected(this.id)), take(1)).subscribe(selected => {
            if(!this.isPrimary()) {
                if(!selected) {
                    super.draw();
                    return;
                } else {
                    this.shapeObj.draw("#b19cd9", "green");
                }    
            } else {
                this.shapeObj.draw("purple", "green");
            }        
        }).unsubscribe();
    }
    isPrimary(): boolean {
        let res = false;
        this.store.pipe(select(primaryCamera), take(1)).subscribe(primary => {
            if(primary && primary.id === this.id) {
                res = true;
            }
        })
        return res;
    }
}