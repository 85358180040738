import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, ElementRef } from "@angular/core";
import { CloudVideo } from "app/cloud/cloud-video";
import { dateFromTimeObj } from "app/time-obj";
import { CloudPlayerComponent } from "../cloud-player.component";
import { Request } from "app/requests/request";
import { Camera } from "app/camera/camera";
import { MotionFile } from "app/motion/motion-data";

export interface CloudPlayerModalData {
  videos: CloudVideo[];
  cameraID: number;
  time: Date;
};

export interface RequestData {
  time: Date;
  player: ElementRef;
}

@Component({
  selector: "app-cloud-player-modal",
  templateUrl: "./cloud-player-modal.component.html",
  styleUrls: ["./cloud-player-modal.component.scss"],
})
export class CloudPlayerModalComponent implements OnInit {
  @Input("data") set setData(value: CloudPlayerModalData) {
    this.data = value;
    this.selectedVideoIndex = 0;   
    this.currentVideo = value.videos[this.selectedVideoIndex];
    this.videoCount = value.videos.length;
    this.videoCountEmitter.emit(this.videoCount);
    this.seekSelectedTime(value.time);
  };
  
  @Input() videoImages: MotionFile[];
  @Input() hasRequestFeature: boolean;
  @Input() hasRequestPermission: boolean;
  @Input() hasCanCreateRawRequestsPermission: boolean;
  @Input() camIDToDisplayName: { [id: number]: string };
  @Input() motionViewed: { [imgURL: string]: boolean };
  @Input() selectedMotionFile: MotionFile;
  
  
  @Output() close = new EventEmitter<Event>();
  @Output() motionFileClick = new EventEmitter<MotionFile>();
  @Output('request') requestEmitter = new EventEmitter<RequestData>();
  @Output('videoCount') videoCountEmitter = new EventEmitter<number>();
  @Output() rawRequest = new EventEmitter<boolean>();

  @ViewChild("player") player: CloudPlayerComponent;

  public videoCount: number;
  public selectedVideoIndex: number = 0;
  public currentVideo: CloudVideo;
  public cameraSelectionIDsText: string;
  public videoStartTime: number = 0;
  public requestModalOpened: boolean;
  public addCamerasModalOpened: boolean;
  public isAutoRequest: boolean = true;
  public data: CloudPlayerModalData;
  public paused: boolean = false;
  
  private currentVideoTime: number;

  constructor() {}

  public ngOnInit() { }

  public onClose() {
    this.close.emit();
  }

  public onVideoChanged(index: number) {
    this.selectedVideoIndex = index; 
    this.currentVideo = this.data.videos[this.selectedVideoIndex];
    this.paused=false;
  }

  public onTimeUpdated(time: number) {
    this.currentVideoTime = time;
  }

  public onVideoDone() {
    let nextIndex = this.selectedVideoIndex + 1;
    if (nextIndex > this.videoCount - 1) {
      nextIndex = 0;
    }
    this.videoStartTime = 0;
    this.selectedVideoIndex = nextIndex;
     this.onVideoChanged(this.selectedVideoIndex);
  }

  public onPlayClicked() {
    this.paused=false;
  }

  public onRequestModalClose() {
    this.requestModalOpened = false;
  }

  public onAddCameras() {
    this.addCamerasModalOpened = true;
  }

  public onMotionFileClick(file: MotionFile) {
    this.motionFileClick.emit(file);
  }

  private getCurrentVideoStart(): number {
    if (this.currentVideo.time === undefined) {
      return 0;
    }

    return dateFromTimeObj(this.currentVideo.time).getTime();
  }
  
  private seekSelectedTime(time: Date) {
    this.data.videos.forEach((video, index) => {
      const videoTime = dateFromTimeObj(video.time).getTime();
      if (videoTime < time.getTime()) {
        if (this.selectedVideoIndex !== index) {
          this.selectedVideoIndex = index;
          this.onVideoChanged(this.selectedVideoIndex);
        }

        this.videoStartTime = (time.getTime() - videoTime) / 1000;
        return;
      }
    });
  }
  
  public newRequest() {
    this.paused=true;
    const currentVideoDate = this.getCurrentVideoStart();
    const start = new Date(currentVideoDate);

    let requestTime = start;
    requestTime.setSeconds(requestTime.getSeconds() + this.currentVideoTime);
    this.requestEmitter.emit({time: requestTime, player: this.player.cloudPlayer});
  }

  public requestRaw() {
        this.rawRequest.emit(true);
     }
}
