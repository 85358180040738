
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { AccessKeyService } from '../../users/access-key.service';
import { ServiceHelpersService } from '../../service-helpers.service';
import { config } from '../../app.config';
import { Logger } from '../../logger/logger.service';

@Injectable()
export class TempUserEmailService {
    public headers = {};
    constructor(public http: HttpClient, public accessKeyService: AccessKeyService, public helpers: ServiceHelpersService, public logger: Logger) {
        this.accessKeyService.getKey().subscribe(key => {
            this.headers = {"accessKey": key};
        })
    }
    sendEmail(id) {
        let headers = new HttpHeaders(this.headers);
        let body = "";
        return this.http.post<any>(config.apiUrl + 'user/' + id + '/email/access', body, {headers: headers}).pipe(catchError(this.helpers.handleError),)
    }
}