import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from "./state";
import * as MotionActions from './actions';
import { MotionData, MotionFile } from "../motion-data";
import { MotionDateTime } from "../motion-datetime-modal/motion-datetime";
import { TimeObj } from "app/time-obj";
import { MotionRequest } from "../motion-request";


const reducer = createReducer(
    initialState,
    on(MotionActions.addCamera, (state: State, { marker}) => {
        if(state.selectedCameras.map(c => c.id).indexOf(marker.id) === -1) {
            return { 
                ...state, 
                selectedCameras: [...state.selectedCameras, marker],
            }
        } else {
            return state;
        }
    }),
    on(MotionActions.removeCamera, (state: State, {marker}) => {
        return { ...state, selectedCameras: [...state.selectedCameras.filter(m => m.id !== marker.id)]};
    }),
    on(MotionActions.resetCameras, (state: State) => {
        return { ...state, selectedCameras: [] };
    }),
    on(MotionActions.motionLoading, (state: State) => {
        return { ...state, motionLoading: true, motionLoaded: false, motionFailed: false, motionData: null };
    }),
    on(MotionActions.motionLoaded, (state: State, {motionData, request}) => {
        return { ...state, motionData: new MotionData(motionData), motionLoaded: true, motionLoading: false, motionFailed: false, lastRequest: new MotionRequest(request.start, request.end, [...request.cameraIDs])};
    }),
    on(MotionActions.motionFailed, (state: State) => {
        return { ...state, motionLoaded: false, motionLoading: false, motionFailed: true, motionData: null };
    }),
    on(MotionActions.setSelectedTime, (state: State, {time}) => {
        return { ...state, selectedTime: Object.assign( new MotionDateTime(), time )};
    }),
    on(MotionActions.cloudLoaded, (state: State, {cloudFiles, cloudTime, cloudCamera}) => {
        cloudFiles = cloudFiles ? cloudFiles : []
        return { ...state, cloudTime: Object.assign( new TimeObj(), cloudTime), cloudVideos: [...cloudFiles], cloudCamera: cloudCamera, cloudModalVisible: true};
    }),
    on(MotionActions.cloudModalClose, (state: State) => {
        return { ...state, cloudModalVisible: false };
    }),
    on(MotionActions.toggleMotionSelectorCollapse, (state: State) => {
        return { ...state, motionSelectorCollapsed: !state.motionSelectorCollapsed };
    }),
    on(MotionActions.setCameraIDToName, (state: State, {map}) => {
        return { ...state, cameraIDToName: Object.assign({}, map)};
    }),
    on(MotionActions.setCameraIDToDisplayName, (state: State, {map}) => {
        return { ...state, cameraIDToDisplayName: Object.assign({}, map)};
    }),
    on(MotionActions.resetMotion, (state: State) => {
        return initialState;
    }),
    on(MotionActions.setMotionSiteID, (state: State, {siteID}) => {
        return { ...state, motionSiteID: siteID };
    }),
    on(MotionActions.setSelectionMaps, (state: State, {maps}) => {
        console.log(maps)
        return { ...state, selectionMaps: [...maps]};
    }),
    on(MotionActions.selectSelectionMap, (state: State, {mapID}) => {
        return { ...state, currentSelectionMapID: mapID };
    }),
    on(MotionActions.motionClick, (state: State, {file}) => {
        let viewed = {...state.motionViewed};
        viewed[file.imageURL] = true;
        return { ...state, motionViewed: viewed, selectedMotionFile: new MotionFile(file) };
    }),
    on(MotionActions.maxCameraModalOpen, (state: State) => {
       return { ...state, maxCameraModalVisible: true};
    }),
    on(MotionActions.maxCameraModalClose, (state: State) => {
        return { ...state, maxCameraModalVisible: false};
     })

    )


export function motionReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}