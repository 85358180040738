// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iot-lpr-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 85px);
    margin-top: 5px;
}

kendo-appbar {
    flex: 0 0 auto;
}

kendo-grid {
    flex: 1 1 auto;
    overflow: hidden;
}

.search-input {
    width: 100%;
    min-width: 30vw;
}

.lp-image {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    cursor: pointer;
}

.lp-image-loader {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lp-image-tooltip {
    max-width: 375px;
    max-height: 275px;
    object-fit: contain;
}

.full-size-image {
    width: 100%;
    height: 100%;
    background-color: black;
    object-fit: contain;
}

::ng-deep .k-grid .k-dropdown {
    width: 6em !important;
}

.restoreButton {
    margin: 5px 5px 5px 0;
    padding: 5px 5px 5px 5px;
    background-color: #000;
    color: white;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.align-right {
    cursor: pointer;
    margin-left: auto;
  }`, "",{"version":3,"sources":["webpack://./src/app/admin/iot-lpr/iot-lpr.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,wBAAwB;IACxB,sBAAsB;IACtB,YAAY;IACZ,qCAAqC;AACzC;;AAEA;IACI,eAAe;IACf,iBAAiB;EACnB","sourcesContent":[".iot-lpr-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: calc(100vh - 85px);\r\n    margin-top: 5px;\r\n}\r\n\r\nkendo-appbar {\r\n    flex: 0 0 auto;\r\n}\r\n\r\nkendo-grid {\r\n    flex: 1 1 auto;\r\n    overflow: hidden;\r\n}\r\n\r\n.search-input {\r\n    width: 100%;\r\n    min-width: 30vw;\r\n}\r\n\r\n.lp-image {\r\n    width: 100%;\r\n    max-height: 100%;\r\n    object-fit: contain;\r\n    cursor: pointer;\r\n}\r\n\r\n.lp-image-loader {\r\n    width: 100%;\r\n    height: 50px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.lp-image-tooltip {\r\n    max-width: 375px;\r\n    max-height: 275px;\r\n    object-fit: contain;\r\n}\r\n\r\n.full-size-image {\r\n    width: 100%;\r\n    height: 100%;\r\n    background-color: black;\r\n    object-fit: contain;\r\n}\r\n\r\n::ng-deep .k-grid .k-dropdown {\r\n    width: 6em !important;\r\n}\r\n\r\n.restoreButton {\r\n    margin: 5px 5px 5px 0;\r\n    padding: 5px 5px 5px 5px;\r\n    background-color: #000;\r\n    color: white;\r\n    border: 1px solid rgba(0, 0, 0, 0.08);\r\n}\r\n\r\n.align-right {\r\n    cursor: pointer;\r\n    margin-left: auto;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
