

export class OtherOfficer {
    public name: string;
    public email: string;
    public phoneNumber: string;
    valid() {
        let valid = true;
        let errors = {email: [], name: [], phoneNumber: []}
        if(this.email && !this.validEmail(this.email)) {
            valid = false;
            errors.email.push(this.email + " is not a valid email address.");
        }
        return {valid: valid, errors: errors};
    }

    validEmail(email) {
        let expr = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        return expr.test(email);
    }

}