import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SiteListItem } from '../../sites/site-list-item';
import { User } from '../../users/user';
import { UserType } from '../../users/user-type';
import { PermissionsTemplate } from '../../permissions-template';
import { Permissions } from '../../permissions';
import { SiteGroup, SiteGroupFull } from "../admin-site-groups/site-group";

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.css'],
})
export class AccountFormComponent implements OnInit {
  public formUser:any = {};
  //public mask: (string | RegExp)[] = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public titles: string[] = ['', 'Mr.', 'Ms.', 'Mrs.', 'Sgt.', 'Det.', 'Ofc.', 'Capt.', 'Insp.']; //TODO: add more titles later?
  public types: UserType[];
  public hardTypes: UserType[];
  public siteList: {text: string, id: number, allowLEAccess: boolean}[];
  public sites: {text: string, id: number, allowLEAccess: boolean}[];
  public singleSites: {text: string, id: number}[];
  public siteGroups: {text: string, id: number}[];
  public siteGroupSites: {siteName: string, siteID: number}[] = [];
  public siteGroupFullMap = new Map();
  public mergedSites: {siteName: string, siteID: number}[];
  public success: string = "";
  public error: string = "";
  public user: User;
  public templates: PermissionsTemplate[];
  public templateMap: {[id: number]: PermissionsTemplate};
  public existingPermissions:Permissions = new Permissions();
  public existingSites;
  public existingSiteGroups;
  public typeSub: any;
  public admin: boolean;
  public userSites: SiteListItem[];
  public mask = "";

  constructor() {}

  @Input('user2') set existingUser(value: User) {

    if (typeof value !== 'undefined') {

      this.formUser = Object.assign(this.formUser, value);
 
      if (this.formUser.singleSites) {
        console.log(this.formUser.singleSites)
        this.existingSites = this.formUser.singleSites.map(site => { return { text: site.siteName, id: site.siteID, allowLEAccess: site.allowLEAccess } });
      }

      if (this.formUser.siteGroups) {
        this.existingSiteGroups = this.formUser.siteGroups.map(group => { return { text: group.name, id: group.id } });
      }

      this.mergedSites = value.sites;

      this.existingPermissions = value.permissions ? value.permissions : new Permissions();

      if (!this.existingPermissions.admin) {
        this.existingPermissions.admin = {};
      }

      this.admin = this.existingPermissions.admin.isAdmin;
      this.onTypeChange(this.formUser.typeFriendly);
      this.onHardTypeChange(this.formUser.typeHard);

    }

  }

  @Input('user2-site-groups') set userSiteGroups(value: SiteGroupFull[])  {
    if (value && value.length > 0) {
      for (let siteGroup of value) {
        if (!this.siteGroupFullMap.has(siteGroup.id)) {  
          this.siteGroupFullMap.set(siteGroup.id, siteGroup);  
        }
        this.updateSiteGroupSites();
      }
    }
  }

  @Input('current-user') set cUser(value: User ) {
    this.user = value;
  }

  @Input('type-list') set tList(value: UserType[]) {
    this.types = value;
    if(this.formUser) {
      this.onTypeChange(this.formUser.typeFriendly);
    }
  }

  @Input('hard-type-list') set htList(value: UserType[]) {
    this.hardTypes = value;
    if(this.formUser) {
      this.onHardTypeChange(this.formUser.typeHard);
      if(!this.formUser.typeHard && this.hardTypes.length > 0) {
        this.formUser.typeHard = this.hardTypes[0]
      }
    }
  }

  @Input('template-list') set templList(value: PermissionsTemplate[]) {
    this.templates = value;
    if(value && value.forEach) {
      this.templateMap = {};
      value.forEach(templ => {
        this.templateMap[templ.id] = templ;
      })
    }
  }

  @Input('site-list') set sList(value: SiteListItem[]) {
    this.siteList = value.map(site => {return {text: site.siteName, id: site.siteID, allowLEAccess: site.allowLEAccess}});
  }

  @Input('site-groups-list') set siteGroupsList(value: SiteGroup[]) {
    if (value) {
    this.siteGroups = value.map(group => {return {text: group.name, id: group.id}});
    }
  }

  @Input('site-group-full') set siteGroupFull(value: SiteGroupFull) {
     if (value) {
      if (!this.siteGroupFullMap.has(value.id)) {  
        this.siteGroupFullMap.set(value.id, value);  
      }
      this.updateSiteGroupSites();
    }
  }

  @Output('save') save = new EventEmitter();

  @Output('siteGroupAdded') siteGroupAdded = new EventEmitter();
  
  public ngOnInit() {}

  public isSelectedSite(site) {
    if(!this.formUser || !this.formUser.sites) {
      return false;
    }
    return this.formUser.singleSites.reduce((prev, next) => next.siteID === site.siteID || prev, false)
  }

  public validPhoneNumber(num) {
    return num && !num.match(/_/);
  }

  public validEmail(email) {
    let expr = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return expr.test(email);
  }

  public validData(data) {
    
    if(!data.email || !this.validEmail(data.email) || data.email === '') {
      return false;
    }

    if((!data.sites || data.sites.length < 1) && !(data.typeHard && data.typeHard.id === 1)) {
      return false;
    }

    if((!data.typeHard)) {
      return false;
    }

    if((!data.permissionsTemplateID)) {
      return false;
    }

    return true;
  }

  public setSites(sites) {
    this.formUser.singleSites = [...sites.map(site => new SiteListItem(site.text, site.id, null, null, null, null, null, site.allowLEAccess, null))];
    this.updateSiteGroupSites();
  
  }

  public setSiteGroups(siteGroups) {
    if (siteGroups) {
      this.formUser.siteGroups = [...siteGroups.map(group => new SiteGroup({ name: group.text, id: group.id }))];

      for (let group of siteGroups) {
        if (!this.siteGroupFullMap.has(group.id)) {
          this.siteGroupAdded.emit(group.id);
        }
      }
    }

    this.updateSiteGroupSites();
  }

  public updateSiteGroupSites() {
    if (!this.formUser.siteGroups || this.formUser.siteGroups.length < 1 ) {
      this.siteGroupSites = [];
      this.formUser.siteGroups = [];
    }

    this.siteGroupSites = [];

    for (let group of this.formUser.siteGroups) {
      if (this.siteGroupFullMap.get(group.id)) {
        let siteGroupFull = this.siteGroupFullMap.get(group.id);
        let groupSites = siteGroupFull.sites;
        for (let site of groupSites) {
            this.siteGroupSites.push({siteName: site.name, siteID: site.id})
        }
      }
    }
     
    this.mergeSites();
  }

  mergeSites() {
    this.mergedSites = [];
    let siteIsMerged = [];
    if (this.formUser.singleSites && this.formUser.singleSites.length > 0) {
      for (let site of this.formUser.singleSites) {
        this.mergedSites.push(site);
        siteIsMerged.push(site.siteID);
      }
    }

    if (this.formUser.siteGroups && this.siteGroupSites.length > 0) {
      for (let site of this.siteGroupSites) {
        if (siteIsMerged.indexOf(site.siteID) === -1) {
          this.mergedSites.push(site);
          siteIsMerged.push(site.siteID);
        }
      }
    }

    this.mergedSites.sort((a, b) => (a.siteName > b.siteName) ? 1 : -1);
    this.formUser.sites = this.mergedSites;
  }

  public onSubmit() {
    if(!this.validData(this.formUser)) {
      return;
    }

    try {
      if(!this.formUser.permissions.admin.isAdmin) {
        this.formUser.permissions.admin = {isAdmin: false};
      }
      this.formUser.permissions.admin.isAdmin = this.admin;
    } catch (error) {
      console.log(error);
    }

    this.save.emit(this.formUser);
  }

  public permUpdate(event) {
      this.formUser.permissions = event;
  }

  public onTypeChange(event) {
    if(this.types && this.formUser && event) {
      let res = this.types.reduce((prev, next) => {
        return next.id === event.id ? next : prev;
      },null)
      if(res) {
        this.formUser.typeFriendly = res;
      }
    }
  }
  
  public onHardTypeChange(event) {
    if(this.hardTypes && this.formUser && event) {
      let res = this.hardTypes.reduce((prev,next) => {
        return next.id === event.id ? next : prev;
      }, null)
      if (res) {
        this.formUser.typeHard = res;
      }
    }
  }
}
