export class TransactionStatus {
    completed: boolean;
    successes: number;
    errors: number;
    fullErrors: TransactionError[];

    constructor(config?: any) {
        if(config) {
            Object.assign(this, config)
        }
    }
}

export class TransactionError {
    userID: number;
    userName: string;
    error: string;

    constructor(config?: any) {
        if(config) {
            Object.assign(this, config)
        }
    }
}