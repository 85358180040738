
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild, Input, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { takeWhile } from "rxjs/operators";
import { Marker } from '../markers/marker';
import { Map } from './map';
import { AppState } from '../app.state';
import { MapTooltipComponent } from './map-tooltip.component';
import { CameraService } from '../camera/camera.service'; //This is to get the display names for the tooltip
import { SiteService } from 'app/site/site.service';
import { CameraLite } from 'app/camera/camera-lite';
declare var h337: any;
const TOOLTIP_WIDTH = 158;
const TOOLTIP_HEIGHT = 122;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
    @ViewChild('canvas', {static: true}) canvas;
    @ViewChild('heatmap', {static: true}) heatmap;
    @ViewChild('tooltip', {static: true}) tooltip: MapTooltipComponent;
    @Input('map') set SetMap(value: Map) {
      if(value){
      if(!this.hmap) {
        this.hmap = h337.create({container: this.heatmap.nativeElement, maxOpacity: 1, minOpacity: 0, height: 720, width: 1280 });
        if(this.heatmap.nativeElement.children.length > 0) {
          this.heatmap.nativeElement.style.position = "absolute";
            let hcanvas = this.heatmap.nativeElement.children[0];
            hcanvas.attributes['height'].nodeValue = 720;
            hcanvas.attributes['width'].nodeValue = 1280;
            this.heatmap.nativeElement.children[0].className = this.heatmap.nativeElement.children[0].className + " img-fluid"
        }
        this.hmap.setData({ max: 10, min: 0, data: [{x: 0, y: 0, value: 0}]})
      }
      this.map = value;
      this.map.canvas = this.canvas.nativeElement;
      this.map.heatContainer = this.heatmap;
      this.map.tooltip = this.tooltip;
      this.map.renderer = this.renderer;
      this.map.hmap = this.hmap;
      this.draw();
      }
    };
    public map = new Map({});
    public hmap;
    public selectSub;
    public mouseOverMarkers: Observable<Marker[]>;
    private alive = true;
    public siteID: number;
    public siteCamerasMap: {[id: number]: CameraLite} = {};
  constructor(public renderer: Renderer2, public store: Store<AppState>, private cameraService: CameraService,private siteService: SiteService) { }
  mouseClick(event) {
    if(this.map) {
      this.map.mouseClick(event);
    }
  }
  mouseDown(event) {
    if(this.map) {
      this.map.mouseDown(event);
    }
  }
  mouseUp(event) {
    if(this.map) {
      this.map.mouseUp(event);
    }
  }
  mouseLeave(event) {
    if(this.map) {
      this.map.mouseLeave(event);
    }
  }
  ngOnInit() {

    this.siteService.getSite().pipe(takeWhile(() => this.alive)).subscribe(site => {
      if(site) {
        this.siteID = site.siteID;
        this.cameraService.getCamerasLite(this.siteID).subscribe(cameras => {
          cameras.forEach(camera => {
            this.siteCamerasMap[camera.id] = camera;
          })
        })
      }
    });

    if (!this.siteID) {
      console.log("siteID not found for map");
      this.siteID = 5;
    }

    if(!this.hmap) {
    this.hmap = h337.create({container: this.heatmap.nativeElement, maxOpacity: 1, minOpacity: 0, height: 100, width: 1000 });
        if(this.heatmap.nativeElement.children.length > 0) {

            let hcanvas = this.heatmap.nativeElement.children[0];
            hcanvas.attributes['height'].nodeValue = 100;
            hcanvas.attributes['width'].nodeValue = 1000;
            this.heatmap.nativeElement.children[0].className = this.heatmap.nativeElement.children[0].className + " img-fluid"

        }
    this.hmap.setData({ max: 10, min: 0, data: [{x: 0, y: 0, value: 0}]})
    if(this.map) {
      this.map.hmap = this.hmap;
    }
    }
    this.selectSub = this.store.select(s => s.mouseOverEvent).pipe(map(events => events.filter(marker => marker.type === 'camera'))).subscribe(events => {
      if(events.length > 0) {

        let xmod = ( this.map.canvas.clientWidth  / this.map.canvas.width )
        let ymod = ( this.map.canvas.clientHeight / this.map.canvas.height )
        
        let marker: any = events[0]

        if (marker.camera in this.siteCamerasMap) {
        this.tooltip.text = this.siteCamerasMap[marker.camera].displayName;
        this.tooltip.imgUrl = "/rest/site/" + this.siteCamerasMap[marker.camera].siteID + "/source/1/camera/" + marker.camera + "/file/" + this.siteCamerasMap[marker.camera].dayReferenceFilename;
        } else {
          this.tooltip.text = "";
          this.tooltip.imgUrl = "";
        }

        let x = marker.x * xmod + 15 - TOOLTIP_WIDTH;//width of tooltip
        let y = marker.y * ymod - 15 - TOOLTIP_HEIGHT;//height of tooltip
        if(marker.x < 640) {
          x = marker.x * xmod;
        }
        if(marker.y - TOOLTIP_HEIGHT < 0) {
          y = marker.bY * ymod + 15;
          if(marker.bX < 640) {
            x = marker.bX * xmod;
          }
        }
        
        this.tooltip.top = y;
        this.tooltip.left = x;
        this.tooltip.display = "block";
      } else {
        this.tooltip.display = "none";
      }
      
    })
    this.mouseOverMarkers = this.store.select(s => s.mouseOverEvent);
  }
  ngOnDestroy() {
    this.selectSub.unsubscribe();
    this.alive = false;
  }
  mouseMove(event) {
    if(this.map) {
      this.map.mouseMove(event);
    }
  }
  draw() {
    this.map.draw();
  }
}
