
import {first, map, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../users/user.service';

@Injectable()
export class DashboardGuard  {
    constructor(public userService: UserService, public router: Router){}
    canActivate() {
        return this.userService.getUser().pipe(filter(user => !user.initial),map(user => {
            if(user.sites && user.sites.length >= 1) {
                return true;
            }
            if( !user.sites || user.sites.length === 0) {
                this.router.navigate(['no-sites-found']);
                return false;
            }
        }),first(),);
    }
}