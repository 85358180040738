import { Camera } from "app/camera/camera";

export class CameraView {
    id: number;
    cameraID: number;
    cameraName: string;
    cvsLayoutID: number;
    gridPosition: number;
    camera?: Camera

    constructor(config?: any) {
        if (config) {
            Object.assign(this, config);
        }
    }
}
