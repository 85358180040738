import { Component, Output, EventEmitter } from '@angular/core';
import { AuthFacade } from '../auth/auth.facade';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent {

    constructor(public authFacade: AuthFacade) {}

    @Output('side') side = new EventEmitter();
    menu() {
        this.side.emit();
    }
    logout(): void {
        this.authFacade.logout();
    }
}