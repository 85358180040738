import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridDataResult, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { UserInfo } from 'app/admin/admin-permissions/user-info';

@Component({
  selector: 'admin-users-selected-grid',
  templateUrl: './admin-users-selected-grid.component.html',
  styleUrls: ['./admin-users-selected-grid.component.css']
})
export class AdminUsersSelectedGridComponent implements OnInit {
  public selectedUsersKeys: number[] = [];
  public users: UserInfo[];
  public usersGridView: GridDataResult;
  public usersGridState: State;
  public selectedUsers: UserInfo[];
  public dataExists: boolean = false;
  public selectAllState: SelectAllCheckboxState;
  constructor() { }

  @Input('usersView') set setUsersView(value: GridDataResult) {
    if (value.data.length < 1) {
      this.dataExists = false;
    } else {
      this.usersGridView = value;
      this.dataExists = true;
    }
  }

  @Input('users') set setUsers(value: UserInfo[]) {
      if (value) {
      this.users = value;
    }
  }

  @Input('selectedUsersKeys') set setSelectedUsersKeys(value: number[]) {
    if (value) {
      this.selectedUsersKeys = value;
    }
  }

  @Input('selectAllCheckboxState') set setSelectAllCheckboxState(value: SelectAllCheckboxState) {
    this.selectAllState = value;
  }

  @Input('gridState') set setGridState(value: State) {
    this.usersGridState = value;
  }

  @Output('selectedUsers') usersSelected = new EventEmitter;

  @Output('dataStateChange') stateChange = new EventEmitter;

  @Output('selectAllCheckboxChange') checkBoxChange = new EventEmitter;

  ngOnInit() {
  }

  onDataStateChange(state) {
    this.stateChange.emit(state);
  }

  onSelectedKeysChange(keys) {
    const len = this.selectedUsersKeys.length;

        if (len === 0) {
            this.selectAllState = 'unchecked';
        } else if (len > 0 && len < this.users.length) {
            this.selectAllState = 'indeterminate';
        } else {
            this.selectAllState = 'checked';
        }
    this.usersSelected.emit(this.selectedUsersKeys);

  }

  onSelectAllChange(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.selectedUsersKeys = this.users.map((user) => user.id);
      this.selectAllState = 'checked';
    } else {
      this.selectedUsersKeys = [];
      this.selectAllState = 'unchecked';
    }

    this.checkBoxChange.emit(this.selectAllState);

    // this.usersSelected.emit(this.selectedUsersKeys);

  }

}
