import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpressRequestFormComponent } from './express-request-form/express-request-form.component';
import { ExpressRequestModalComponent } from './express-request-modal/express-request-modal.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    ExpressRequestFormComponent,
    ExpressRequestModalComponent,
  ],
  imports: [
    CommonModule,
    DialogModule,
    FormsModule,
  ],
  exports: [
    ExpressRequestFormComponent,
    ExpressRequestModalComponent,
  ]
})
export class ExpressRequestModule { }
