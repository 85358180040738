export class Address {
    streetAddress: string;
    aptNumber: string;
    city: string;
    state: string;
    zipCode: string;
    constructor(options?:any) {
        this.streetAddress = "";
        this.aptNumber = "";
        this.city = "";
        this.state = "";
        this.zipCode = "";
        Object.assign(this, options);
    }
}