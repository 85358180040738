// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPanel {
    min-width: 360px;
    background-color: #f2f2f2;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/app/requests/new-request/new-request.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,gBAAgB;AACpB","sourcesContent":[".mainPanel {\r\n    min-width: 360px;\r\n    background-color: #f2f2f2;\r\n    font-weight: 400;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
