export class TimeObj {
    year: number;
    month: number; //javascript month january = 0
    day: number;
    hours: number;
    minutes: number;
    seconds: number;
    constructor(time?: Date) {
        if (time) {
            time = new Date(time);
            var temp = {
                year: time.getFullYear(),
                month: time.getMonth(),
                day: time.getDate(),
                hours: time.getHours(),
                minutes: time.getMinutes(),
                seconds: time.getSeconds()
            };
            return Object.assign(this, temp);
        }
    }
    toDate(): Date {
        return dateFromTimeObj(this);
    }    
    hoursDifference(other: TimeObj): number {
        if(!other) {
            return 0;
        }
        let start = this.toDate().getTime();
        let end = other.toDate().getTime();
        const HOUR = 1000 * 60 * 60;
        return Math.abs(Math.floor( (start - end) / HOUR))
    }
    equals(other: TimeObj): boolean {
        return this.year === other.year && this.month === other.month && this.day === other.day && this.hours === other.hours && this.minutes === other.minutes && this.seconds === other.seconds;
    }
}

export function dateFromTimeObj(t: TimeObj): Date {
    if (!t) {
        return null;
    }    
    return new Date(t.year, t.month, t.day, t.hours, t.minutes, t.seconds);
}
