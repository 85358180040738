import { Directive, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appVhHeight]'
})
export class VhHeightDirective {

  constructor(private renderer: Renderer2) {
    this.setVh();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setVh();
  }

  private setVh() {
    const vh = window.innerHeight * 0.01;
    this.renderer.setStyle(document.documentElement, '--vh', `${vh}px`);
  }
}
