

export class DocType {
    id: number;
    name: string;
    description: string;
    expireInDays: number;
    constructor(config?: DocType) {
        if(config) {
            Object.assign(this, config);
        }
    }
}