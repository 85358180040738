import { Component, OnInit } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import * as siteGroupsActions from './store/actions';
import {
  selectSiteGroupsGridState,
  selectSiteGroupsGridView,
  selectSiteGroupsLoading,
  selectShowCreateSiteGroupModal,
  selectSiteGroupsGridData,
  selectEditGroup,
  selectEditSelectedSites,
  selectEditUnselectedSites,
  selectEditName,
  selectEditSiteFilter,
  selectEditSaving,
  selectEditDuplicateName,
  selectShowDeleteSiteGroupModal,
  selectSiteGroupSelectedForDelete,  
} from './store/selectors';
import { SiteGroup, SiteGroupFull } from './site-group';
import { Lookup } from 'app/lookup';

@Component({
  selector: 'admin-site-groups',
  templateUrl: './admin-site-groups.component.html',
  styleUrls: ['./admin-site-groups.component.css']
})
export class AdminSiteGroupsComponent implements OnInit {
  public siteGroupsGridState$: Observable<State>;
  public siteGroupsLoading$: Observable<boolean>;
  public siteGroupsView$: Observable<GridDataResult>;
  public showCreateSiteGroupModal$: Observable<boolean>;
  public currentSiteGroups$: Observable<SiteGroup[]>;
  public showDeleteSiteGroupModal$: Observable<boolean>;
  public siteGroupSelectedForDelete$: Observable<SiteGroupFull>;
  

  public editGroup$: Observable<SiteGroupFull>;
  public editSelectedSites$: Observable<Lookup[]>;
  public editUnselectedSites$: Observable<Lookup[]>;
  public editName$: Observable<string>;
  public editSiteFilter$: Observable<string>;
  public editSaving$: Observable<boolean>;
  public editDupName$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.store.dispatch(siteGroupsActions.loadSiteGroups());
    this.store.dispatch(siteGroupsActions.loadEditSites());

    this.siteGroupsGridState$ = this.store.pipe(select(selectSiteGroupsGridState));
    this.siteGroupsLoading$ = this.store.pipe(select(selectSiteGroupsLoading));
    this.siteGroupsView$ = this.store.pipe(select(selectSiteGroupsGridView));
    this.showCreateSiteGroupModal$ = this.store.pipe(select(selectShowCreateSiteGroupModal));
    this.currentSiteGroups$ = this.store.pipe(select(selectSiteGroupsGridData));
    this.editGroup$ = this.store.pipe(select(selectEditGroup));
    this.editSelectedSites$ = this.store.pipe(select(selectEditSelectedSites));
    this.editUnselectedSites$ = this.store.pipe(select(selectEditUnselectedSites));
    this.editName$ = this.store.pipe(select(selectEditName));
    // this.editName$.subscribe(name => console.log(name));
    this.editSiteFilter$ = this.store.pipe(select(selectEditSiteFilter));
    this.editSaving$ = this.store.pipe(select(selectEditSaving));
    this.editDupName$ = this.store.pipe(select(selectEditDuplicateName));
    this.showDeleteSiteGroupModal$ = this.store.pipe(select(selectShowDeleteSiteGroupModal));
    this.siteGroupSelectedForDelete$ = this.store.pipe(select(selectSiteGroupSelectedForDelete));
  }

  onSiteGroupsStateChange(state: State) {
    this.store.dispatch(siteGroupsActions.setSiteGroupsGridState({ gridState: state }));
  }

  createSiteGroup() {
    this.store.dispatch(siteGroupsActions.setShowCreateSiteGroupModal({show: true}));
  }

  cancelCreateSiteGroup() {
    this.store.dispatch(siteGroupsActions.setShowCreateSiteGroupModal({show: false}));
  }

  saveNewSiteGroup(group: SiteGroup) {
    this.store.dispatch(siteGroupsActions.setShowCreateSiteGroupModal({show: false}));
    this.store.dispatch(siteGroupsActions.createSiteGroup({siteGroup: group}))
  }

  editSiteGroup(group: SiteGroup) {
    this.store.dispatch(siteGroupsActions.clickEditSiteGroup({siteGroupID: group.id}));
  }

  deleteSiteGroup(group: SiteGroup) {
    this.store.dispatch(siteGroupsActions.getSiteGroupSelectedForDelete({id: group.id}));
  }

  deleteSelectedSiteGroup(group: SiteGroupFull) {
    this.store.dispatch(siteGroupsActions.deleteSiteGroup({id: group.id}))
  }

  cancelDeleteSiteGroup() {
    this.store.dispatch(siteGroupsActions.setShowDeleteSiteGroupModal({show: false}));
  }

  // Edit Form
  editSelectSite(site: Lookup) {
    this.store.dispatch(siteGroupsActions.addEditSelectedSite({site: site}));
  }
  editUnselectSite(site: Lookup) {
    this.store.dispatch(siteGroupsActions.removeEditSelectedSite({site: site}));
  }
  editSave() {
    this.store.dispatch(siteGroupsActions.editSave());
  }
  editNameChange(name: string) {
    this.store.dispatch(siteGroupsActions.setEditName({name: name}));
  }
  editSiteFilterChange(filter: string) {
    this.store.dispatch(siteGroupsActions.setEditSiteFilter({filter: filter}));
  }
}
