import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HistoricalData } from '../historical-data';
import { SeriesClickEvent } from '@progress/kendo-angular-charts';


@Component({
  selector: 'app-historical-data',
  templateUrl: './historical-data.component.html',
  styleUrls: ['./historical-data.component.css']
})
export class HistoricalDataComponent implements OnInit {
  @Input('data') data: HistoricalData;
  @Input('child') child: boolean;
  @Input('loading') loading: boolean;
  @Output('remove') removeEmitter: EventEmitter<HistoricalData> = new EventEmitter<HistoricalData>();

  constructor() {}

  ngOnInit() {}

  clickPie(event: SeriesClickEvent) {
    event.dataItem.selected = true;
    this.data.selectCategory(event.category);
  }

  remove() {
    this.removeEmitter.emit(this.data);
  }
}
