// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzone {
  min-height: 200px;
  min-width: 200px;
  display: table;
  background-color: #eee;
  border: dotted 1px #aaa;
}

.text-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.centered {
  font-family: sans-serif;
  font-size: 1.3em;
  font-weight: bold;
  text-align:center;
}

.clickable {
  cursor: pointer;
}

.file-list-container {
  margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/document/document.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".dropzone {\r\n  min-height: 200px;\r\n  min-width: 200px;\r\n  display: table;\r\n  background-color: #eee;\r\n  border: dotted 1px #aaa;\r\n}\r\n\r\n.text-wrapper {\r\n  display: table-cell;\r\n  vertical-align: middle;\r\n}\r\n\r\n.centered {\r\n  font-family: sans-serif;\r\n  font-size: 1.3em;\r\n  font-weight: bold;\r\n  text-align:center;\r\n}\r\n\r\n.clickable {\r\n  cursor: pointer;\r\n}\r\n\r\n.file-list-container {\r\n  margin-top: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
