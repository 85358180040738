// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message {
    color: red;
    margin: 5px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-site-groups/site-group-create/site-group-create.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".error-message {\r\n    color: red;\r\n    margin: 5px;\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
