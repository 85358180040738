// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td {
    padding-right: 10px;
}

.section {
    flex: 1;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    min-width: 250px;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
}

h3 {
    margin-top: 10px;
}

.wide {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-permissions/admin-bulk-perm-form/admin-bulk-perm-form.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,OAAO;IACP,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf","sourcesContent":["td {\r\n    padding-right: 10px;\r\n}\r\n\r\n.section {\r\n    flex: 1;\r\n    border-color: black;\r\n    border-style: solid;\r\n    border-width: 1px;\r\n    border-radius: 5px;\r\n    padding: 10px;\r\n    margin: 10px;\r\n    min-width: 250px;\r\n}\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n}\r\n\r\nh3 {\r\n    margin-top: 10px;\r\n}\r\n\r\n.wide {\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
