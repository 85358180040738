// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollUp {  
  position: absolute;
  top: 80vh;
  right: 30px;
}

.material-icons-round.scroll-up {
  cursor: pointer;
  color: #43aaa3;
  font-size: 48px;
}

.cam-vp {
  height:calc(100vh - 80px);
  position:relative;
}

.loading-indicator {
  position: absolute;
  font-size: 48px;
  top: calc(50% - 80px);
  left: 50%;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/app/camera/portfolio-camera/portfolio-camera.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,eAAe;EACf,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,qBAAqB;EACrB,SAAS;EACT,gCAAgC;AAClC","sourcesContent":[".scrollUp {  \r\n  position: absolute;\r\n  top: 80vh;\r\n  right: 30px;\r\n}\r\n\r\n.material-icons-round.scroll-up {\r\n  cursor: pointer;\r\n  color: #43aaa3;\r\n  font-size: 48px;\r\n}\r\n\r\n.cam-vp {\r\n  height:calc(100vh - 80px);\r\n  position:relative;\r\n}\r\n\r\n.loading-indicator {\r\n  position: absolute;\r\n  font-size: 48px;\r\n  top: calc(50% - 80px);\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
