import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SiteGroup, SiteGroupFull } from '../site-group';

@Component({
  selector: 'app-site-group-delete',
  templateUrl: './site-group-delete.component.html',
  styleUrls: ['./site-group-delete.component.css']
})
export class SiteGroupDeleteComponent implements OnInit {
  @Input("active") set activeSetter(value: boolean) {
    this.active = value;
  }

  @Input("siteGroup") set siteGroupSetter(value: SiteGroupFull) {
    if (this.active && value.users && value.users.length > 0) {
      this.groupHasUsers = true;
     
    } else {
      this.groupHasUsers = false;
    }
      this.siteGroup = value;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();

  @Output() save: EventEmitter<SiteGroup> = new EventEmitter();

  public active: boolean = false;
  public disableSubmitButton: boolean = true;
  public siteGroup: SiteGroupFull;
  public groupHasUsers: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  onCancel(event: Event): void {
    this.closeModal();
  }

  closeModal(): void {
    this.cancel.emit();
  }

  onSubmit() {
    this.save.emit(this.siteGroup);
  }

}
