import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ComponentCanDeactivate } from './cvs-changes-guard';
import { Observable } from 'rxjs';
import { map, takeWhile, take } from "rxjs/operators";
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/app.state';
import {
  selectLayouts,
  selectCVSSiteID,
  selectSelectedLayout,
  selectGridSizes,
  selectCameras,
  selectCVSDupName,
  selectLayoutHasChanges,
  selectShowUnsavedChangesDialog,
  selectShowDeleteLayoutDialog,
  selectCameraLayoutsMap,
  selectShowAutoGenerateDialog,
  selectNumberOfLayouts
} from './store/selectors';
import * as CVSActions from './store/actions';
import { Layout } from './layout';
import { GridSize } from './gridsize';
import { Camera } from '../camera/camera';

@Component({
  selector: 'cvs',
  templateUrl: './cvs.component.html',
  styleUrls: ['./cvs.component.css']
})
export class CvsComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.layoutHasChanges) {
      return false;
    }
    return true;
  }

  
  private alive: boolean = true;
  public siteID$: Observable<number>;
  public layouts$: Observable<Layout[]>;
  public numberOfLayouts$: Observable<number>;
  public selectedLayout$: Observable<Layout>;
  public cvsSiteID$: Observable<number>;
  public gridSizes$: Observable<GridSize[]>;
  public newLayout: Layout = {id: null, siteID: null, name: "New Layout", gridSizeID: 3, cameraViews: [], showCameraName: false};
  public cameras$: Observable<Camera[]>;
  public cvsDupName$: Observable<boolean>;
  public layoutHasChanges$: Observable<boolean>;
  public layoutHasChanges: boolean;
  public showUnsavedChangesDialog$: Observable<boolean>;
  public showDeleteLayoutDialog$: Observable<boolean>;
  public showAutoGenerateDialog$: Observable<boolean>;
  public cameraLayoutsMap$: Observable<any>;
  public generateSizeID: number = 3;
    
  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.siteID$ = this.store.select(s => s.site).pipe(map(site => site.siteID));
    this.siteID$.pipe(takeWhile(() => this.alive)).subscribe(siteID => {
      this.store.pipe(select(selectCVSSiteID), take(1)).subscribe(cvsSiteID => {
        if(siteID !== cvsSiteID) {
          this.store.dispatch(CVSActions.resetCVS());
          this.store.dispatch(CVSActions.setCVSSiteID({siteID: siteID}));
          this.store.dispatch(CVSActions.loadLayouts({siteID: siteID}));
          this.store.dispatch(CVSActions.loadGridSizes({siteID: siteID}));
          this.store.dispatch(CVSActions.loadCameras({siteID: siteID}));
        }
      })
    })

    this.layouts$ = this.store.pipe(select(selectLayouts));
    this.numberOfLayouts$ = this.store.pipe(select(selectNumberOfLayouts));
    this.selectedLayout$ = this.store.pipe(select(selectSelectedLayout));
    this.gridSizes$ = this.store.pipe(select(selectGridSizes));
    this.cvsSiteID$ = this.store.pipe(select(selectCVSSiteID));
    this.cameras$ = this.store.pipe(select(selectCameras));
    this.cvsDupName$ = this.store.pipe(select(selectCVSDupName));
    this.layoutHasChanges$ = this.store.pipe(select(selectLayoutHasChanges));
    this.showUnsavedChangesDialog$ = this.store.pipe(select(selectShowUnsavedChangesDialog));
    this.showDeleteLayoutDialog$ = this.store.pipe(select(selectShowDeleteLayoutDialog));
    this.showAutoGenerateDialog$ = this.store.pipe(select(selectShowAutoGenerateDialog));
    this.cameraLayoutsMap$ = this.store.pipe(select(selectCameraLayoutsMap));

    this.layoutHasChanges$.pipe(takeWhile(() => this.alive)).subscribe(hasChanges => { 
      this.layoutHasChanges = hasChanges;
    });
 
  }

  ngOnDestroy() {
    this.alive = false;
  }

  selectLayout(layout: Layout) {
    this.store.dispatch(CVSActions.setSelectedLayout({layout: layout}));
  }

  addLayout() {
    this.saveLayout(this.newLayout);
  }

  saveLayout(layout: Layout) {
    if (layout.id == null) {
    this.store.dispatch(CVSActions.addLayout({layout: layout}));
    } else {
    this.store.dispatch(CVSActions.updateLayout({layout: layout}));
    }
  }

  deleteLayout(layout: Layout) {
    this.store.dispatch(CVSActions.setShowDeleteLayoutDialog({show: true}));
  

  }

  updateLayoutChanges(layout: Layout) {
    this.store.dispatch(CVSActions.setLayoutHasChanges({changes: true}));
    this.store.dispatch(CVSActions.storeLayoutChanges({layout: layout}));
  }

  confirmUnsavedChanges() {
    this.store.dispatch(CVSActions.confirmUnsavedChanges());
  }

  closeUnsavedChangesDialog() {
    this.store.dispatch(CVSActions.setShowUnsavedChangesDialog({show: false}));
  }

  confirmDeleteLayout() {
    this.store.dispatch(CVSActions.deleteLayout());
  }

  closeDeleteLayoutDialog() {
    this.store.dispatch(CVSActions.setShowDeleteLayoutDialog({show: false}));
  }

  submitGenerate() {
    let siteID;
    this.siteID$.pipe(take(1)).subscribe(id => siteID = id);
    this.store.dispatch(CVSActions.generateLayouts({siteID: siteID, gridSizeID: this.generateSizeID}))
  }
  closeAutoGenerateDialog() {
    this.store.dispatch(CVSActions.setShowAutoGenerateDialog({show: false}));
  }
  openAutoGenerateDialog() {
    this.store.dispatch(CVSActions.setShowAutoGenerateDialog({show: true}));
  }
}
