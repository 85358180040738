
import { map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';

import { WhereMap } from '../where/where-map';
import { Request } from '../../request';
import { AppState } from '../../../app.state';
import { SiteService } from '../../../site/site.service';
import { MarkerService } from '../../../markers/marker.service';
import { Site } from '../../../site/site';
import { CLEAR_MOUSEOVER_EVENT } from '../../../events/event.reducer';
import { NotificationsService } from '../../../notifications/notifications.service';
import { RequestService } from '../../request.service';
import { ModalComponent } from '../../../shared/modal/modal.component';
import { TimeObj } from "../../../time-obj";
import { REQUEST_SET_CONFIDENTIAL, REQUEST_SUBMITTED, RESET_REQUEST_SUBMIT } from '../../requests.reducer'
import { PermissionsService } from '../../../permissions.service';
import { Logger } from '../../../logger/logger.service';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.css'],
})
export class ConfirmationComponent implements OnInit, OnDestroy {
    public request: Request;
    public requestSub;
    public siteLoading;
    public siteSub;
    public site: Site;
    public maps: WhereMap[];
    public map: WhereMap;
    public drawnOn;
    public sending = false;
    public fileEventID: number;

    @ViewChild("emergencyModal", { static: true }) emergencyModal: ModalComponent

    constructor(public store: Store<AppState>, public siteService: SiteService, public markerService: MarkerService, public router: Router, public notificationsService: NotificationsService, public requestService: RequestService, public route: ActivatedRoute, public logger: Logger, private permissionsService: PermissionsService) { }
    ngOnInit() {
        this.requestSub = this.store.select(s => s.request).subscribe(request => {
            if(request === null) {
                this.router.navigate(['site', this.site.siteID, 'events']);
                return;    
            }
            this.request = request;
        });
        this.siteLoading = this.siteService.getSiteLoading();
        this.siteSub = this.siteService.getSite().subscribe(site => {
            this.store.dispatch({ type: CLEAR_MOUSEOVER_EVENT });
            if (site) {
                this.maps = [];
                if (site.maps) {
                    site.maps.forEach(map => {
                        let newMap = new WhereMap(map, this.store);
                        newMap.canDraw = false;
                        if (map.markers) {
                            let cameraMarkerIDs = [];
                            if(this.request && this.request.cameras) {
                                cameraMarkerIDs = this.request.cameras.cameras.map(c => c.id);
                            }
                            map.markersLocal = [
                                ...this.markerService.createMarkers(map.markers.filter(m => m.type !== "camera"), newMap),
                                ...map.markers.filter(m => m.type === "camera").map(m => cameraMarkerIDs.indexOf(m.id) === -1 ? this.markerService.createCameraMarker(m, newMap) : this.markerService.createPurpleCameraMarker(m, newMap)),
                            ]
                        }
                        newMap.markersLocal = map.markersLocal;
                        this.maps.push(newMap);
                    })
                    this.map = this.maps[0];
                } else {
                    //this is probably bad...
                    this.map = new WhereMap({}, this.store);
                }
                this.site = site;
            }
        })
        this.drawnOn = combineLatest(this.store.select(s => s.request.where), this.store.select(s => s.request.cameras)).pipe(map(([where, cameras]) => {
            return [
                ...where.mapData.map(drawing => drawing.mapID),
                ...cameras.cameras.map(m => m.map.id),
            ]
        }));
    }
    ngOnDestroy() {
        this.requestSub.unsubscribe();
        this.siteSub.unsubscribe();
    }
    setMap(event) {
        this.map = event;
    }
    navigateWhere() {
        this.router.navigate(["../", "where"], { relativeTo: this.route });
    }
    navigateCameras() {
        this.router.navigate(['../', 'cameras'], { relativeTo: this.route });
    }
    navigateWhen() {
        this.router.navigate(['../', 'when'], { relativeTo: this.route });
    }
    // navigateWhat() {
    //     this.router.navigate(['../', 'what'], {relativeTo: this.route});
    // }
    navigateContactInfo() {
        this.router.navigate(['../', 'contact-info'], { relativeTo: this.route });
    }
    totalHours() {
        if (!this.request || !this.request.when.start || !this.request.when.end || !this.request.cameras.cameras) {
            return 0;
        }
        let hours = (this.request.when.end.getTime() - this.request.when.start.getTime()) / 1000 / 60 / 60;
        return hours * this.request.cameras.cameras.length;
    }
    back() {
        this.navigateContactInfo();
    }
    toggleConfidential() {
        this.store.dispatch({ type: REQUEST_SET_CONFIDENTIAL, payload: !this.request.confidential });
    }
    submit() {
        this.sendRequest();
    }
    modalHidden() {
        this.router.navigate(['site', this.site.siteID, 'events']);
    }

    fileUploadComplete() {
        this.fileEventID = undefined;
        if (this.emergency(this.request)) {
            console.log("Emergency Detected")
            this.emergencyModal.showModal();
        } else {
            this.router.navigate(['site', this.site.siteID, 'events']);
        }
    }

    sendRequest() {
        this.sending = true;
        this.store.dispatch({type: REQUEST_SUBMITTED});
        let request: any = Object.assign({}, this.request);
        request.when = {};
        request.when.start = new TimeObj(this.request.when.start);
        request.when.end = new TimeObj(this.request.when.end);
        request.when.evidenceDiscoveredBy = this.request.when.evidenceDiscoveredBy;
        this.requestService.sendRequest(request).subscribe(resp => {
            this.notificationsService.success("", "Request Submited");
            this.fileEventID = resp.eventID;
            this.sending = false;
            
            this.logger.trackAction(7, this.site.siteID).subscribe();
        }, (error) => {
            this.store.dispatch({type: RESET_REQUEST_SUBMIT});
            this.notificationsService.error("", "Error submitting request")
            this.sending = false;
        });
    }

    hideModal() {
        this.emergencyModal.hideModal();
    }

    emergency(request: Request): boolean {
        let time = new Date();
        let diff = time.getTime() - request.when.end.getTime();
        let length = request.when.end.getTime() - request.when.start.getTime();
        const HOUR = 1000 * 60 * 60;
        // if ((time.getHours() <= 7 || time.getHours() >= 19) && diff >= 0 && diff <= 6 * HOUR) {
        //     return true;
        // }
        if (length <= (6 * HOUR) && diff <= (24 * HOUR)) {
            return true;
        }
        return false;
    }

    isAdmin() {
        return this.permissionsService.admin("isAdmin");
    }
}
