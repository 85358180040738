import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';import { concatLatestFrom } from '@ngrx/operators';

import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { Store } from "@ngrx/store";
import * as AIActions from "./actions";
import * as eventSelectors from "../../events/store/selectors";
import { AppState } from "../../app.state";
import { NotificationsService } from "../../notifications/notifications.service";
import { AIService } from "../ai.service";

@Injectable()
export class AIEffects {
    generateEventSynopsis$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AIActions.generateEventSynopsis),
            concatLatestFrom(() => [
                this.store.select(eventSelectors.eventItems),
            ]),
            switchMap(([action, events]) => {
                const eventIDs = events.map(e => e.id);
                return this.aiService.generateEventSynopsis(eventIDs, action.detailLevel, action.descriptionSelector, action.categoryIDs).pipe(
                    map((eventSynopsis) => AIActions.generateEventSynopsisSuccess({ eventSynopsis })),
                    catchError(() => {
                        this.notificationsService.error("", "Error generating AI synopsis")
                        return of(AIActions.generateEventSynopsisFailure());
                    }),
                )
            })
        )
    });

    generateEventSynopsisStream$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AIActions.generateEventSynopsisStream),
            concatLatestFrom(() => [
                this.store.select(eventSelectors.eventItems),
            ]),
            switchMap(([action, events]) => {
                const eventIDs = events.map(e => e.id);
                return this.aiService.generateEventSynopsisStream(eventIDs, action.detailLevel, action.descriptionSelector, action.categoryIDs).pipe(
                    map((response) => AIActions.generateEventSynopsisStreamSuccess({ requestId: response.requestId })),
                    catchError(() => {
                        this.notificationsService.error("", "Error generating AI synopsis");
                        return of(AIActions.generateEventSynopsisStreamFailure());
                    })
                )
            })
        )
    });

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private notificationsService: NotificationsService,
        private aiService: AIService,
    ) { }
}
