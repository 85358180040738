import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as AuthActions from './store/auth.actions';
import * as AuthSelectors from './store/auth.selectors';

@Injectable()
export class AuthFacade {
  isAuthenticated$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  sessionCreated$: Observable<boolean>;

  constructor(private store: Store) {
    this.isAuthenticated$ = this.store.select(AuthSelectors.isAuthenticated);
    this.isLoading$ = this.store.select(AuthSelectors.isLoading);
    this.sessionCreated$ = this.store.select(AuthSelectors.sessionCreated); 
  }

  login(): void {
    this.store.dispatch(AuthActions.login());
  }

  logout(): void {
    this.store.dispatch(AuthActions.logout());
  }

  checkTokenExpiration(): void {
    this.store.dispatch(AuthActions.checkTokenExpiration());
  }

  refreshToken(): void {
    this.store.dispatch(AuthActions.refreshToken());
  }
}