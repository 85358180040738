// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-fluid {
    padding: 5px;
}

.center {
    text-align: center;
}
.x {
    cursor: pointer;
}

.clickable {
    cursor: pointer;
}

.selectedCamera {
    display: inline-block;
    margin: 5px;
    padding: 5px;
    background-color: lightgray;
    border-color: lightgray;
    border-style: solid;
    border-radius: 10px;
}

.buttons {
    background-color: #fff;
    color: black;
    margin-bottom: 10px;  
}

.btn {
    border: 1px solid #ccc;
    border-radius: 4px;
}

.btn:disabled {
    cursor: not-allowed;
    pointer-events: all;
}

h4 {
    font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/requests/new-request/cameras/cameras.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".container-fluid {\r\n    padding: 5px;\r\n}\r\n\r\n.center {\r\n    text-align: center;\r\n}\r\n.x {\r\n    cursor: pointer;\r\n}\r\n\r\n.clickable {\r\n    cursor: pointer;\r\n}\r\n\r\n.selectedCamera {\r\n    display: inline-block;\r\n    margin: 5px;\r\n    padding: 5px;\r\n    background-color: lightgray;\r\n    border-color: lightgray;\r\n    border-style: solid;\r\n    border-radius: 10px;\r\n}\r\n\r\n.buttons {\r\n    background-color: #fff;\r\n    color: black;\r\n    margin-bottom: 10px;  \r\n}\r\n\r\n.btn {\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n}\r\n\r\n.btn:disabled {\r\n    cursor: not-allowed;\r\n    pointer-events: all;\r\n}\r\n\r\nh4 {\r\n    font-weight: normal;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
