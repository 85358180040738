import { ActionReducer, Action } from '@ngrx/store';
import { User } from './user';
import { UserType } from './user-type';
import { Permissions } from '../permissions';
import { PermissionsTemplate } from '../permissions-template';
import { UserSoftType } from 'app/constants';



export const SET_USER = 'SET_USER';
export const SET_TEMP_USERS = 'SET_TEMP_USERS';
export const UPDATE_TEMP_USER = 'UPDATE_TEMP_USER';

export const SET_PERMISSIONS_TEMPLATES = 'SET_PERMISSIONS_TEMPLATES';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const USER_NOT_LOADED = 'USER_NOT_LOADED';

export const USER_STATES = {LOADING: "loading", LOADED: "loaded", NOT_LOADED: "not loaded"};
export const SET_USER_TYPES = 'SET_USER_TYPES';
export const SET_USER_HARD_TYPES = 'SET_USER_HARD_TYPES';


export const initialUser = {
    initial: true,
    isTempUser: false,
    accessKey: "",
    email: "",
    userName: "",
    permissions: {admin: {},user: {}},
    sites: [],
    siteGroups: [],
    singleSites: [],
    title: "",
    phoneNumber: "",
    ext: "",
    cellNumber: "",
    firstName: "",
    lastName: "",
    contactPreference: "",
    isWtsEmployee: false,
    id: 0,
    active: false,
    typeFriendly: { id: 0, name: "", description: ""},
    typeHard: {id: 0, name: "", description: ""},
    getName: getName,
    siteName: '',
    permissionsTemplateID: 0,
    oldUsername: "",
}

export function getName() {
    return ""
}

export const initialUserTypes: UserType[] = [];

export const initialUserHardTypes: UserType[] = [];

export const initialTempUsers: User[] = [];

export const initialPermissionsTemplates: PermissionsTemplate[] = [];

export const initialUserLoaded: string = USER_STATES.NOT_LOADED;


export function userReducer(state: User, action) {
    switch (action.type) {
        case SET_USER:
            let res = Object.assign(new User(), action.payload)
            return res;
        default:
            return state;
    }    
}

// export const userReducer: ActionReducer<User> = (state: User, action: Action) => {
//     switch (action.type) {
//         case SET_USER:
//             let res = Object.assign(new User(), action.payload)
//             return res;
//         default:
//             return state;
//     }
// };

export function tempUsersReducer(state: User[] = [], action) {
    switch (action.type) {
        case SET_TEMP_USERS:
            let res = [...action.payload];
            return res;
        case UPDATE_TEMP_USER:
            return [...state].map((user) => {
                if(user.id === action.payload.id) {
                    return Object.assign(new User(), user, action.payload)
                }
                return user;
            });
        default:
            return state;
    }    
}

// export const tempUsersReducer: ActionReducer<User[]> = (state: User[] = [], action: Action) => {
//     switch (action.type) {
//         case SET_TEMP_USERS:
//             let res = [...action.payload];
//             return res;
//         case UPDATE_TEMP_USER:
//             return [...state].map((user) => {
//                 if(user.id === action.payload.id) {
//                     return Object.assign({}, user, action.payload)
//                 }
//                 return user;
//             });
//         default:
//             return state;
//     }
// }

export function userLoadedReducer(state: string = initialUserLoaded, action: Action) {
    switch (action.type) {
        case USER_LOADED:
            return USER_STATES.LOADED;
        case USER_LOADING:
            return USER_STATES.LOADING;
        case USER_NOT_LOADED:
            return USER_STATES.NOT_LOADED;
        default:
            return state;
    }    
}

// export const userLoadedReducer: ActionReducer<string> = (state: string = initialUserLoaded, action: Action) =>{
//     switch (action.type) {
//         case USER_LOADED:
//             return USER_STATES.LOADED;
//         case USER_LOADING:
//             return USER_STATES.LOADING;
//         case USER_NOT_LOADED:
//             return USER_STATES.NOT_LOADED;
//         default:
//             return state;
//     }
// }

export function permissionsTemplatesReducer(state: PermissionsTemplate[] = initialPermissionsTemplates, action) {
    switch (action.type) {
        case SET_PERMISSIONS_TEMPLATES:
            return [...action.payload];
        default:
            return state;
    }    
}

// export const permissionsTemplatesReducer: ActionReducer<PermissionsTemplate[]> = (state: PermissionsTemplate[] = initialPermissionsTemplates, action: Action) => {
//     switch (action.type) {
//         case SET_PERMISSIONS_TEMPLATES:
//             return [...action.payload];
//         default:
//             return state;
//     }
// }

export function userTypeReducer(state: UserType[] = initialUserTypes, action) {
    switch (action.type) {
        case SET_USER_TYPES:
            [...action.payload].sort((a,b) => {
                if(a.id === UserSoftType.Other) {
                    return 1;
                }
                if(b.id === UserSoftType.Other) {
                    return -1;
                }
                return 0;
            })
            return [...action.payload];
        default:
            return state;
    }    
}

// export const userTypeReducer: ActionReducer<UserType[]> = (state: UserType[] = initialUserTypes, action: Action) => {
//     switch (action.type) {
//         case SET_USER_TYPES:
//             return [...action.payload];
//         default:
//             return state;
//     }
// }

export function userHardTypeReducer(state: UserType[] = initialUserHardTypes, action) {
    switch (action.type) {
        case SET_USER_HARD_TYPES:
            return [...action.payload];
        default:
            return state;
    }    
}

// export const userHardTypeReducer: ActionReducer<UserType[]> = (state: UserType[] = initialUserHardTypes, action: Action) => {
//     switch (action.type) {
//         case SET_USER_HARD_TYPES:
//             return [...action.payload];
//         default:
//             return state;
//     }
// }