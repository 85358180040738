// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .modal-header > .modal-title {
    width: 100%;
}

.message-modal-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/messages/messages.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB","sourcesContent":["::ng-deep .modal-header > .modal-title {\r\n    width: 100%;\r\n}\r\n\r\n.message-modal-title {\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
