import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-video-loader",
  templateUrl: "./video-loader.component.html",
  styleUrls: ["./video-loader.component.css"]
})
export class VideoLoaderComponent implements OnInit {
  @Input() stateText: string;
  @Input() ready: boolean;
  @Input() stretch: boolean;
  @Input() imgSrc: string;
  @Input() error: boolean = false;

  constructor() {}

  ngOnInit() {}
}
