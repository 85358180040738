// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-checkbox-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox-header .k-checkbox-label {
  margin-left: 5px;
}

.truncate-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bucketEnabled {
  color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action_btn {
  margin-right: 5px;
}

.bucketEnabled-1 {
  color: #fff; 
  padding: 10px; 
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-extended-storage/admin-table/admin-table.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".custom-checkbox-header {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.custom-checkbox-header .k-checkbox-label {\r\n  margin-left: 5px;\r\n}\r\n\r\n.truncate-text {\r\n  overflow: hidden;\r\n  white-space: nowrap;\r\n  text-overflow: ellipsis;\r\n}\r\n\r\n.bucketEnabled {\r\n  color: #fff;\r\n  padding: 10px;\r\n  border-radius: 10px;\r\n}\r\n\r\n.button-container {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.action_btn {\r\n  margin-right: 5px;\r\n}\r\n\r\n.bucketEnabled-1 {\r\n  color: #fff; \r\n  padding: 10px; \r\n  border-radius: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
