import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Site } from '../../site';
import { Request } from '../../../events/request';
import { getName } from '../../../users/user';

@Component({
  selector: 'app-dashboard-recent-events',
  templateUrl: './dashboard-recent-events.component.html',
  styleUrls: ['./dashboard-recent-events.component.css']
})
export class DashboardRecentEventsComponent implements OnInit {
  @Input('statuses') statuses = [];
  @Input('site') site: Site;
  @Input('requests') requests: Request[];

  constructor(private router: Router) { }

  ngOnInit() {
  }
  gotoEvent(eventID) {
    this.router.navigate(["site", this.site.siteID, "events", eventID]);
  }
  getStatusName(id: number): string {
    let name = "";
    this.statuses.forEach(status => {
        if(status.id === id) {
            name = status.name;
        }
    })
    return name;
  }
  getName(user) {
    return getName(user);
  }
}
