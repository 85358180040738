import { Directive, OnInit, OnDestroy, Input, ElementRef, ViewContainerRef, TemplateRef } from "@angular/core";
import { SiteService } from "app/site/site.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Site } from "app/site/site";
import { FeatureService } from "./services";

@Directive({
  selector: "[hasFeatures]",
})
export class HasFeatureDirective implements OnInit, OnDestroy {
  private currentSite: Site;
  private featureIDs: number[];
  private operator: string = "AND";
  private destroy$ = new Subject<void>();
  private isHidden: boolean = true;

  @Input()
  public set hasFeatures(features: number[]) {
    this.featureIDs = features;
    this.updateView();
  }

  @Input()
  public set hasFeaturesOp(operator: string) {
    this.operator = operator;
    this.updateView();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private siteService: SiteService,
    private featureService: FeatureService,
  ) {}

  public ngOnInit() {
    this.siteService
      .getSite()
      .pipe(takeUntil(this.destroy$))
      .subscribe((site) => {
        this.currentSite = site;
        this.updateView();
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateView() {
    if (this.checkFeatures()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkFeatures() {
    let hasPermission = false;

    if (this.currentSite && this.currentSite.package) {
      for (const checkFeatureID of this.featureIDs) {
        const featureFound = this.featureService.checkFeatureInPackage(checkFeatureID, this.currentSite.package);
        if (featureFound) {
          hasPermission = true;

          if (this.operator === "NOT") {
            hasPermission = false;
            break;
          }
          else if (this.operator === "OR") {
            break;
          }
        } else {
          hasPermission = false;
          
          if (this.operator === "AND") {
            break;
          }
        }
      }
    }

    return hasPermission;
  }
}
