import { Map, MapData } from '../map/map';
import { Marker } from '../markers/marker';

export class PropertyMap extends Map {
    constructor(mapData: MapData) {
        super(mapData);
    }
    drawMarker(marker: Marker){
        if(marker.type === 'camera' || marker.type === 'map') {
            marker.draw()
        }
    }
    mouseMove(event) {
        let xy = this.getXY(event);
        if(this.markersLocal) {
            this.markersLocal.filter(marker => marker.type === "camera" || marker.type === 'map').forEach(marker => {
                marker.mouseOver(xy.x, xy.y);
            })
        }
    }
    mouseClick(event) {
        let xy = this.getXY(event);        
        if(this.markersLocal) {
            this.markersLocal.filter(marker => marker.type === "camera" || marker.type === 'map').forEach(marker => {
                marker.click(xy.x, xy.y);
            })
        }
    }
}