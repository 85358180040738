import { CameraMarker, CameraMarkerConfig } from "app/camera/camera-marker";


export class PurpleCameraMarker extends CameraMarker {
    constructor(config: CameraMarkerConfig) {
        super(config);
    }
    getFillColor() {
        return "purple"
    }
}