import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from './document.service';
import { PermissionsService } from '../permissions.service';
import { config } from '../app.config';
import { DocTypeService } from 'app/admin/admin-documents/doc-type/doc-type.service';
import { DocumentFile } from './document-file';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit, OnDestroy {
  public siteID: number;
  public docs: any = [];
  public types: any = [];

  private _destroyed$ = new Subject();

  constructor(
    public docService: DocumentService,
    public docTypeService: DocTypeService,
    public route: ActivatedRoute,
    public permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.route.params
    .pipe(takeUntil(this._destroyed$))
    .subscribe(params => {
      this.siteID = +params['siteID'];
      this.getDocTypes();
      this.getFiles();
    });
  }

  getFiles() {
    this.docService.fetchForSite(this.siteID)
    .pipe(takeUntil(this._destroyed$))
    .subscribe(res => {
      this.docs = res
    });
  }

  getDocTypes() {
    this.docTypeService.getTypes(this.siteID)
    .pipe(takeUntil(this._destroyed$))
    .subscribe(res => {
      this.types = res
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }
}
