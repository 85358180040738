import { State as GridState } from '@progress/kendo-data-query';
import { AlertEntry } from '../../../lpr/manage-alert-entries/alert-entry';
import { AlertHit } from '../../../lpr/recent-hits/alert-hit';

export const adminLPRKey = "adminLPR"

export interface State {
    // Alert Entries Tab
    alertEntries: AlertEntry[],
    alertEntriesLoading: boolean,
    alertEntriesGridState: GridState,
    selectedAlertEntry: number[],
    alertEntryHits: AlertHit[],
    alertEntryHitsLoading: boolean,
    alertEntryHitsGridState: GridState,
    showLprImageModal: boolean,
    showRequestModal: boolean,    
    showAddCamsModal: boolean,

    //Alert Hits Tab
    selectedEntriesHitsTab: string,
    alertHitsGridState: GridState,
    alertHits: AlertHit[],
    alertHitsLoading: boolean,
}

export const initialState: State = {
    // Alert Entries Tab
    alertEntries: [],
    alertEntriesLoading: false,
    alertEntriesGridState: {},
    selectedAlertEntry: [],
    alertEntryHits: [],
    alertEntryHitsLoading: false,
    alertEntryHitsGridState: {},
    showLprImageModal: false,
    showRequestModal: false,
    showAddCamsModal: false,

    //Alert Hits Tab
    selectedEntriesHitsTab: "alertEntries",
    alertHitsGridState: {skip: 0, take: 50},
    alertHitsLoading: false,
    alertHits: [],
}