
import {filter, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Permissions } from './permissions';
import { UserService } from './users/user.service';

@Injectable()
export class PermissionsService {
    // !NOTE: Possible timing issues if the user isn't loaded yet
    constructor(public userService: UserService) {}

    admin(field: string, permissions?: Permissions): boolean {
        if (!permissions) {
            this.userService.getUser().pipe(filter(user => !user.initial), take(1)).subscribe(user => permissions = user.permissions);
        }

        return permissions && permissions.admin && permissions.admin[field];
    }

    adminAnyDocuments(permissions?: Permissions): boolean {
        if (!permissions) {
            this.userService.getUser().pipe(filter(user => !user.initial), take(1)).subscribe(user => permissions = user.permissions);
        }

        if (permissions && permissions.admin && permissions.admin.canManageDocTypes) {
            return true;
        }

        return permissions && permissions.admin && permissions.admin.document && Object.keys(permissions.admin.document).filter(key => permissions.admin.document[key]).length > 0
    }

    site(siteID: number, field: string, permissions?: Permissions, ): boolean {
        if (!permissions) {
            this.userService.getUser().pipe(filter(user => !user.initial), take(1)).subscribe(user => permissions = user.permissions);
        }

        if (permissions && permissions.admin && permissions.admin.allSites) {
            return true;
        }

        return permissions && permissions[siteID] && permissions[siteID][field];
    }

    document(siteID: number, type: number, permissions?: Permissions): boolean {
        if (!permissions) {
            this.userService.getUser().pipe(filter(user => !user.initial), take(1)).subscribe(user => permissions = user.permissions);
        }

        if(this.documentAdmin(type, permissions)) {
            return true;
        }

        return permissions && permissions.document && permissions.document[siteID] && permissions.document[siteID][type];
    }

    documentAdmin(type: number, permissions?: Permissions): boolean {
        if (!permissions) {
            this.userService.getUser().pipe(filter(user => !user.initial), take(1)).subscribe(user => permissions = user.permissions);
        }

        if (permissions && permissions.admin && permissions.admin.canManageDocTypes) {
            return true;
        }

        return permissions && permissions.admin && permissions.admin.document[type];
    }

    anyDocumentForSite(siteID: number, permissions?: Permissions): boolean {
        if (!permissions) {
            this.userService.getUser().pipe(filter(user => !user.initial), take(1)).subscribe(user => permissions = user.permissions);
        }

        if(this.adminAnyDocuments(permissions)) {
            return true;
        }
        
        return permissions && permissions.document && permissions.document[siteID] && Object.keys(permissions.document[siteID]).filter(key => permissions.document[siteID][key]).length > 0
    }

    user(field: string, permissions?: Permissions): boolean {
        if (!permissions) {
            this.userService.getUser().pipe(filter(user => !user.initial), take(1)).subscribe(user => permissions = user.permissions);
        }

        return permissions && permissions.user && permissions.user[field];
    }
}