import { CameraMarker } from '../camera/camera-marker';

export class ServiceRequest {
    userID: number;
    cameras: CameraMarker[];
    description: string;
    siteID: number;
    constructor(config?: {userID?: number, cameras?: CameraMarker[], description?: string}) {
        this.cameras = [];
        if(config) {
            Object.assign(this, config);
        }
    }
}

