import { Camera } from '../camera';
import { CameraLite } from '../camera-lite';
import { Lookup } from '../../lookup';
import { CameraGridSize } from "../camera-grid-size";


export const CameraKey = "camera";

export interface State {
    portfolioCameras: CameraLite[],
    portfolioCameraStreams: {[cameraID: number]: Camera},
    streamsLoaded: boolean,
    portfolioIsLive: boolean,
    cameraIntents: Lookup[],
    siteFilter: number[],
    intentFilter: number[],
    nameFilter: string,
    sortOptions: string[],
    selectedSortOption: string,
    groupByOptions: string[],
    selectedGroupByOption: string,
    cameraFooterCollapse: boolean,
    cameraGridSize: CameraGridSize  
    viewportWidth: number,
    cameraHeight: number,
    appBarWidth: number,
    portfolioSiteWalkStatus: "play" | "pause" | "fast",
    lastPlayStatus: "play" | "fast",
    theme: "light" | "dark",
    loadingCameras: boolean,
}

export const initialState: State = {
    portfolioCameras: [],
    portfolioCameraStreams: {},
    streamsLoaded: false,
    portfolioIsLive: false,
    cameraIntents: [],
    siteFilter: [],
    intentFilter: [],
    nameFilter: "",
    sortOptions: ["Site (Asc)", "Site (Desc)", "Camera # (Asc)", "Camera # (Desc)"],
    selectedSortOption: "",
    groupByOptions: ["Site", "Intent", "Camera"],
    selectedGroupByOption: "",
    cameraFooterCollapse: false,
    cameraGridSize: { small: true, medium: false, large: false },
    appBarWidth: 0,
    viewportWidth: 0,
    cameraHeight: 0,
    portfolioSiteWalkStatus: "pause",
    lastPlayStatus: "play",
    theme: "light",
    loadingCameras: false,
}