import { Injectable } from "@angular/core";

declare var window: any;

@Injectable()
export class UserAgentService {
  public isMobile;

  constructor() {
    var isMobile = {
      Android: function() {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function() {
        return (
          isMobile.Android() ||
          isMobile.BlackBerry() ||
          isMobile.iOS() ||
          isMobile.Opera() ||
          isMobile.Windows()
        );
      }
    };

    this.isMobile = isMobile;
  }

  internetExplorer() {
    //internet explorer detection copied from old portal could probably be improved
    return (
      (navigator.appName === "Microsoft Internet Explorer" &&
        navigator.platform !== "MacPPC" &&
        navigator.platform !== "Mac68k") ||
      (!window.ActiveXObject && "ActiveXObject" in window)
    );
  }

  safari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  mobile() {
    return this.isMobile.any();
  }
}
