import { map } from "rxjs/operators";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";

import { Site } from "../../../site/site";
import { SiteService } from "../../../site/site.service";
import { UserService } from "../../../users/user.service";
import { User } from "../../../users/user";
import { NotificationsService } from "../../../notifications/notifications.service";
import { SiteListService } from "../../../sites/site-list.service";
import { Lookup } from "../../../lookup";
import { AdminService } from "../../admin.service";
import SitePackage from "../../../site-package/models/site-package";
import * as fromSitePackages from "../../../site-package/reducers";
import { SitePackageViewActions } from "app/site-package/actions";
import { PermissionsService } from "app/permissions.service";

@Component({
  selector: "app-site-setup",
  templateUrl: "./site-setup.component.html",
  styleUrls: ["./site-setup.component.scss"],
})
export class SiteSetupComponent implements OnInit, OnDestroy {
  public site: Site = new Site();
  public users: User[];
  public paramSub;
  public sales: User[];
  public sitePackages$: Observable<SitePackage[]>;
  public hqAdminList: { text: string; id: number }[];
  public managementCompanies: Lookup[] = [];
  public hasManageVitals: boolean;

  private originalFileRetention: number;
  public showConfirm: boolean = false;
  public messages: string[] = [];
  private originalIsDisabled: boolean = false;

  constructor(
    private _siteService: SiteService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _notificationsService: NotificationsService,
    private _siteListService: SiteListService,
    private _adminService: AdminService,
    private _sitePackageStore$: Store<fromSitePackages.State>,
    private _permissionService: PermissionsService,
  ) {}

  updateSite(site: Site) {
    this.site = site;
  }

  ngOnInit() {
    this.paramSub = this._route.params.subscribe((params) => {
      this._siteService.getOtherSite(+params["id"]).subscribe((site) => {        
        this.originalFileRetention = site.autoRequestFileRetentionDays;
        this._siteService.getSiteCloudStorage(site.hqAdminID).subscribe((cloudStorage) => {
          site.cloudStorage = cloudStorage.minCloudStorage;
          this.site = site;
          this.originalIsDisabled = this.site.isDisabled;
        });
      });
      this._userService.getUsersBySite(+params["id"]).subscribe((users) => {
        this.users = users;
      });
    });

    this._userService.getWtsEmployees().subscribe((users) => {
      this.sales = users;
    });

    this._siteListService
      .getHqAdminList()
      .pipe(
        map((list) =>
          list.map((item) => {
            return { text: item.name, id: item.id };
          })
        )
      )
      .subscribe((list) => (this.hqAdminList = list));

    this._adminService.getManagementCompanies().subscribe((mgmt) => {
      this.managementCompanies = mgmt;
    });

    this.sitePackages$ = this._sitePackageStore$.pipe(select(fromSitePackages.selectAllSitePackages));

    this._sitePackageStore$.dispatch(SitePackageViewActions.loadSitePackages());

    this.hasManageVitals = this._permissionService.admin("canManageVitals");
  }

  ngOnDestroy() {
    this.paramSub.unsubscribe();
  }

  save() {
    if (this.site.daysRewind > this.site.cloudStorage) {
      this._notificationsService.error("", "Rewind Days cannot be greater than cloud storage: " + this.site.cloudStorage.toString());
      return;
    }
    if (this.site.autoRequestFileRetentionDays < 7) {
      this._notificationsService.error("", "Auto Request File Retention Days cannot be less than 7");
      return;
    }
    if (this.site.rawRequestHours < 4) {
      this._notificationsService.error("", "Raw Request Max Hours cannot be less than 4");
    }
    if(this.site.autoRequestFileRetentionDays < this.originalFileRetention) {
      this.openConfirm();
    } else {
      this.doSave();
    }
  
  }
  doSave() {
    this._siteService.updateSite(this.site).subscribe(
      (resp) => {
        this._notificationsService.success("", "Site Updated");
        if (this.originalIsDisabled && !this.site.isDisabled && this.site.isByos && this.site.bucketID) {          
          this.byosDnsBackfill(this.site.siteID);
          return;          
        }
        this._router.navigate(["admin", "sites"]);
      },
      (site) => this._notificationsService.error("", "Error Updating Site")
    );
  }

  byosDnsBackfill(siteID: number) { 
    this._siteService.byosDnsBackfill(siteID).subscribe(
      (resp) => {        
        this._notificationsService.success("", "BYOS Backfill Request Sent");
        this._router.navigate(["admin", "sites"]);
      },
      (err) => this._notificationsService.error("", "Error Sending BYOS DNS Backfill Request")
    );
  }

  cancel() {
    this.showConfirm = false;
    this.messages = [];
  }
  confirm() {
    this.cancel();
    this.doSave();
  }
  openConfirm() {
    this.showConfirm = true;
    this.messages = ["You have lowered the file retention for this site.  This could result in the loss of video that has been previously downloaded"];
  }
}
