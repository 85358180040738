import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, catchError } from 'rxjs/operators';
import { AppState } from "app/app.state";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import * as adminExtendedStorageActions from './actions';
import * as adminExtendedStorageSelectors from './selectors';
import { NotificationsService } from "app/notifications/notifications.service";
import { AdminExtendedStorageService } from "../admin-extended-storage.service";

@Injectable()
export class AdminExtendedStorageEffects {

    getByosSites$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                adminExtendedStorageActions.getByosSites,                            
            ),
            switchMap(() => {
                return this.adminExtendedStorageService.getByosSites().pipe(
                    map(sites => adminExtendedStorageActions.getByosSitesSuccess({ sites: sites })),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading BYO sites")
                        return of(adminExtendedStorageActions.getByosSitesFailure());
                    }),
                )
            })
        )
    });

    submitBackfillRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                adminExtendedStorageActions.submitBackfillRequest,
            ),
            switchMap((action) => {
                return this.adminExtendedStorageService.backfillSite(action.request).pipe(
                    map(job => {
                        this.notificationsService.success("", "Backfill request submitted");
                        return adminExtendedStorageActions.submitBackfillRequestSuccess();
                    }),
                    catchError(() => {
                        this.notificationsService.error("", "Error submitting backfill request");                        
                        return of(adminExtendedStorageActions.submitBackfillRequestFailure());
                    }),
                )
            })
        )
    });


    constructor(
        private actions$: Actions,
        private adminExtendedStorageService: AdminExtendedStorageService,
        private notificationsService: NotificationsService,
        private store: Store<AppState>

    ) { }

}

