export class Permissions {
  public admin: AdminPermissions;
  public user?: UserPermissions;
  public document?: SiteDocumentPermissions;
  constructor(options?: any) {
    this.user = {};
    this.admin = {};
    booleanUserFields.forEach(field => {
      this.user[field.name] = field.default;
    });

    // !NOTE: assigning any user fields will overwrite all of them
    if (options) {
      Object.assign(this, options);
    }
    if(!this.admin.document) {
    this.admin.document = {};
    }
  }
}

export interface DocumentPermissions {
  id?: boolean;
}

export interface AdminDocumentPermissions {
  siteID?: DocumentPermissions;
}

export interface SiteDocumentPermissions extends DocumentPermissions {}

export interface SitePermissions {
  canManageUsers?: boolean;
  canCreateTickets?: boolean;
  canCreateRawRequests?: boolean;
  canViewTickets?: boolean;
  canManageLPR?: boolean;
  canViewLPR?: boolean;
  rewind?: boolean;
  live?: boolean;
  cameras?: number[];
  metrics?: boolean;
  carCounting?: boolean;
  peopleCounting?: boolean;
  document?: boolean;
  serviceRequest?: boolean;
  canManageCVS?: boolean;
  canViewCVS?: boolean;  
  canBYOS?: boolean;
}

export interface AdminPermissions {
  canCreateUser?: boolean;
  canEditTempUsers?: boolean;
  canManageUser?: boolean;
  canManageSites?: boolean;
  canMessage?: boolean;
  isAdmin?: boolean;
  allSites?: boolean;
  document?: AdminDocumentPermissions;
  canManageDocTypes?: boolean;
  canManageVitals?: boolean;
  bulkPermissions?: boolean;
  canManageSiteGroups?: boolean;
  canManageSitePackages?: boolean;
}

export interface UserPermissions {
  canMessage?: boolean;
}
export const booleanUserFields = [
  { name: "canMessage", displayName: "Messages", default: true }
];

export const booleanSiteFields = [
  { name: "canManageUsers", displayName: "Manage Users" },
  { name: "canCreateTickets", displayName: "Create Tickets" },
  { name: "canCreateRawRequests", displayName: "Create Raw Requests" },
  { name: "canViewTickets", displayName: "View Tickets" },
  { name: "rewind", displayName: "Rewind" },
  { name: "live", displayName: "Live" },
  { name: "metrics", displayName: "Metrics" },
  { name: "carCounting", displayName: "Car Counting" },
  { name: "peopleCounting", displayName: "People Counting" },
  { name: "canManageLPR", displayName: "LPR Manage" },
  { name: "canViewLPR", displayName: "LPR View"},
  { name: "serviceRequest", displayName: "Service Request"},
  { name: "canManageCVS", displayName: "CVS Manage" },
  { name: "canViewCVS", displayName: "CVS View"},
  { name: "canBYOS", displayName: "Archival Storage Manage" }
];
