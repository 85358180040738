// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-modal-button {
    font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-site-package/site-package-create-modal/site-package-create-modal.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB","sourcesContent":[".create-modal-button {\r\n    font-size: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
