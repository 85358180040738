export class CameraAuth {
    cameraID: number;
    username: string;
    password: string;

    constructor(config?: any) {
        if (config) {
            Object.assign(this, config);
        }
    }
}
