import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from "./state";
import * as CameraActions from './actions';
import { CameraGridSize } from "../camera-grid-size";

const reducer = createReducer(
    initialState,
    on(CameraActions.getPortfolioCameras, (state: State) => {
        return {
            ...state,
            loadingCameras: true,
        }
    }),
    on(CameraActions.getPortfolioCamerasSuccess, (state: State, {cameras}) => {
        return {
            ...state,
            portfolioCameras: [...cameras],
            loadingCameras: false,
        }
    }),
    on(CameraActions.getPortfolioCamerasFailure, (state: State) => {
        return {
            ...state,
            loadingCameras: false,
        }
    }),
    on(CameraActions.setPortfolioCameraStreams, (state: State, {cameraStreams}) => {
        return {
            ...state,
            portfolioCameraStreams: cameraStreams.reduce((acc, camera) => {
                acc[camera.id] = camera;
                return acc;
            }, {}),
            streamsLoaded: true,
        }
    }),
    on(CameraActions.portfolioLiveOn, (state: State) => {
        return {
            ...state,
            portfolioIsLive: true,
        }
    }),
    on(CameraActions.portfolioLiveOff, (state: State) => {
        return {
            ...state,
            portfolioIsLive: false,
            portfolioCameraStreams: {},
            streamsLoaded: false,
        }
    }),
    on(CameraActions.getCameraIntentsSuccess, (state: State, {intents}) => {
        return {
            ...state,
            cameraIntents: intents,
        }
    }),
    on(CameraActions.setSiteFilter, (state: State, {filter}) => {
        return {
            ...state,
            siteFilter: [...filter],
        }
    }),
    on(CameraActions.setIntentFilter, (state: State, {filter}) => {
        return {
            ...state,
            intentFilter: [...filter],
        }
    }),
    on(CameraActions.setNameFilter, (state: State, {filter}) => {
        return {
            ...state,
            nameFilter: filter,
        }
    }),
    on(CameraActions.clearFilters, (state: State) => {
        return {
            ...state,
            siteFilter: [],
            intentFilter: [],
            nameFilter: "",
        }
    }),
    on(CameraActions.setSelectedSortOption, (state: State, {option}) => {
        return {
            ...state,
            selectedSortOption: option,
        }
    }),
    on(CameraActions.setSelectedGroupByOption, (state: State, {option}) => {
        return {
            ...state,
            selectedGroupByOption: option,
        }
    }),
    on(CameraActions.toggleCameraFooterToggle, (state: State) => {
        let collapse = {...state}.cameraFooterCollapse;
        return {
            ...state,
            cameraFooterCollapse: !collapse,
        }
    }),

    on(CameraActions.setCameraGridSize, (state: State, {gridSize}) => {        
        let cameraGridSize: CameraGridSize = { small: false, medium: false, large: false};
        switch (gridSize) {
            case "SET_SMALL":
                cameraGridSize.small = true;
                break;    
            case "SET_MEDIUM":
                cameraGridSize.medium = true;
                break;
            case "SET_LARGE":
                cameraGridSize.large = true;
                break;                   
        }
        console.log(cameraGridSize);
        return {
            ...state,
            cameraGridSize: cameraGridSize,
        }
    }),

    on(CameraActions.setViewportWidth, (state: State, {width}) => {
        return {
            ...state,
            viewportWidth: width,
        }
    }),

    on(CameraActions.setCameraHeight, (state: State, {height}) => {
        return {
            ...state,
            cameraHeight: height,
        }
    }),

    on(CameraActions.setAppBarWidth, (state: State, {width}) => {
        return {
            ...state,
            appBarWidth: width,
        }
    }),
    on(CameraActions.setFilters, (state: State, {filters}) => {
        return {
            ...state,
            siteFilter: filters.siteFilter,
            intentFilter: filters.intentFilter,
            nameFilter: filters.nameFilter,
            selectedSortOption: filters.selectedSortOption,
            selectedGroupByOption: filters.selectedGroupByOption,
            cameraGridSize: {
                small: filters.cameraGridSize.toLowerCase() === 'small',
                medium: filters.cameraGridSize.toLowerCase() === 'medium',
                large: filters.cameraGridSize.toLowerCase() === 'large',
            },
        }
    }),
    on(CameraActions.clickSiteWalkPlay, (state: State) => {
        let status: "play" | "pause" | "fast" = "play";
        return {
            ...state,
            portfolioSiteWalkStatus: status,
            lastPlayStatus: status,
        }
    }),
    on(CameraActions.clickSiteWalkPause, (state: State) => {
        let status: "play" | "pause" | "fast" = "pause";
        return {
            ...state,
            portfolioSiteWalkStatus: status,
        }
    }),
    on(CameraActions.clickSiteWalkFast, (state: State) => {
        let status: "play" | "pause" | "fast" = "fast";
        return {
            ...state,
            portfolioSiteWalkStatus: status,
            lastPlayStatus: status,
        }
    }),
    on(CameraActions.siteWalkTogglePause, (state: State) => {
        let status: "play" | "pause" | "fast" = state.portfolioSiteWalkStatus === "pause" ? state.lastPlayStatus : "pause";
        return {
            ...state,
            portfolioSiteWalkStatus: status,
        }
    }),
    on(CameraActions.themeLight, (state: State) => {
        let theme: "light" | "dark" = "light";
        return {
            ...state,
            theme: theme,
        }
    }),
    on(CameraActions.themeDark, (state: State) => {
        let theme: "light" | "dark" = "dark";
        return {
            ...state,
            theme: theme,
        }
    }),
)
export function cameraReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}