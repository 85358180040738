import { createReducer, on } from "@ngrx/store";
import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import Feature from "../models/feature";
import { FeatureAPIActions } from "../actions";

export const featureFeatureKey = "features";

export interface State extends EntityState<Feature> {
  error: Error;
}

export const featureAdapter: EntityAdapter<Feature> = createEntityAdapter<Feature>({
  selectId: (feature: Feature) => feature.id,
  sortComparer: false,
});

export const initialState: State = featureAdapter.getInitialState({
  error: null,
});

export const reducer = createReducer(
  initialState,
  on(FeatureAPIActions.loadFeaturesSuccess, (state, { features }) =>
    featureAdapter.addMany(features, {
      ...state,
      error: null,
    }),
  ),
  on(FeatureAPIActions.loadFeaturesFailure, (state, { errorMessage }) => ({
    ...state,
    error: {
      name: "failed to load features",
      message: errorMessage,
    },
  })),
);

export const getError = (state: State) => state.error;
