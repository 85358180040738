import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Message } from '../message';

@Component({
    selector: 'app-messages-table',
    templateUrl: './messages-table.component.html',
    styleUrls: ['./messages-table.component.css']
})
export class MessagesTableComponent {
    @Input('messages') set messageSetter(value: Message[]){
        this.inputMessages = value;
        this.messages = this.doFilter(this.filter, value);
    };
    @Output('sort') sortEmitter = new EventEmitter<string>(); 
    @Output('selected') selected = new EventEmitter<Message>();
    messages: Message[];
    inputMessages: Message[];
    filter: string = "";
    select(message) {
        console.log(message)
        this.selected.emit(message);
    }
    sort(sort) {
        this.sortEmitter.emit(sort);
    }
    doFilter(filter: string, messages: Message[]): Message[] {
        if(!filter) {
            return messages;
        }
        return messages.filter(message => {
            let reg = RegExp(filter);
            return reg.test(message.text) || reg.test(message.subject) || reg.test(message.site ? message.site.displayName: "");
        })
    }
    changeFilter(filter) {
        this.filter = filter;
        this.messages = this.doFilter(filter, this.inputMessages);
    }
}