import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { LabelModule } from "@progress/kendo-angular-label";
import { GridModule, ExcelModule } from "@progress/kendo-angular-grid";
import { DialogModule, WindowModule } from "@progress/kendo-angular-dialog";
import { ChartsModule } from "@progress/kendo-angular-charts";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { NotificationModule } from "@progress/kendo-angular-notification";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { TypographyModule } from '@progress/kendo-angular-typography';
import { IconsModule } from "@progress/kendo-angular-icons";
import { UploadsModule } from "@progress/kendo-angular-upload";
import { ModalComponent } from "./modal/modal.component";
import { DocumentListComponent } from "./document-list/document-list.component";
import { UploadDirective } from "./upload.directive";
import { TimePickerComponent } from "../time-picker/time-picker.component";
import { ConfirmModalComponent } from "../confirm-modal/confirm-modal.component";
import { LprImageModalComponent } from "./lpr-image-modal/lpr-image-modal.component";
import { PasswordValidatorComponent } from './password-validator/password-validator.component';

import { NumericTextBoxModule } from "@progress/kendo-angular-inputs";


@NgModule({ declarations: [
        ModalComponent,
        DocumentListComponent,
        TimePickerComponent,
        UploadDirective,
        ConfirmModalComponent,
        LprImageModalComponent,
        PasswordValidatorComponent,
    ],
    exports: [
        CommonModule,
        ModalModule,
        FormsModule,
        LayoutModule,
        InputsModule,
        LabelModule,
        ButtonsModule,
        DateInputsModule,
        GridModule,
        DialogModule,
        WindowModule,
        ChartsModule,
        DropDownsModule,
        FormsModule,
        ReactiveFormsModule,
        NotificationModule,
        TooltipModule,
        ModalComponent,
        DocumentListComponent,
        TimePickerComponent,
        UploadDirective,
        ExcelModule,
        ConfirmModalComponent,
        LprImageModalComponent,
        PasswordValidatorComponent,
        UploadsModule,
    ], imports: [CommonModule,
        ModalModule,
        FormsModule,
        LayoutModule,
        InputsModule,
        LabelModule,
        ButtonsModule,
        DateInputsModule,
        GridModule,
        DialogModule,
        WindowModule,
        ChartsModule,
        DropDownsModule,
        FormsModule,
        ReactiveFormsModule,
        NotificationModule,
        TooltipModule,
        ExcelModule,
        TypographyModule,
        IconsModule,
        UploadsModule,
        NumericTextBoxModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class SharedModule {}
