
import {take, filter} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { AppState } from '../app.state';
import { Map } from '../map/map';
import { PropertyMap } from './property-map';
import { MapComponent } from '../map/map.component';
import { MarkerService } from '../markers/marker.service';
import { SiteService } from '../site/site.service';
import { CLEAR_MOUSEOVER_EVENT } from '../events/event.reducer';
import { Site } from '../site/site';
import { MarkerEventService } from '../markers/marker-event.service';
import { PermissionsService } from '../permissions.service';
import { MapContainerComponent } from '../map/map-container.component';
import { SET_MAP } from './property-map.reducer';

@Component({
    selector: 'app-property-map',
    templateUrl: './property-map.component.html',
    styleUrls: ['./property-map.component.css'],
})
export class PropertyMapComponent implements OnInit, OnDestroy{
    public siteSub: any;
    public map: Map;
    public maps: Map[];
    public site: Site;
    public siteLoading;
    public routeSubscription;
    public eventSub;
    public selectedMapID;
    @ViewChild('mapContainer', {static: true}) mapContainer: MapContainerComponent;
    constructor(public markerService: MarkerService, public siteService: SiteService, public store: Store<AppState>, public activatedRoute: ActivatedRoute, public markerEventService: MarkerEventService, public router: Router, public permissions: PermissionsService) {}
    ngOnInit() {
        
        this.siteLoading = this.siteService.getSiteLoading();
        
        this.store.dispatch({type: CLEAR_MOUSEOVER_EVENT});
        this.siteSub = this.siteService.getSite().subscribe(site => {
            this.store.dispatch({type: CLEAR_MOUSEOVER_EVENT});
            if(site) {
                this.maps = [];
                if(site.maps) {
                    site.maps.forEach(map => {
                        let newMap = new PropertyMap(map);
                        if(map.markers) {
                            newMap.markersLocal = this.markerService.createMarkers(map.markers, newMap);
                        } else {
                            newMap.markersLocal = [];
                        }
                        this.maps.push(newMap);
                    })
                    this.map = this.maps[0];
                } else {
                    this.map = new PropertyMap({})
                }
                this.site = site;
            }
        })
        this.eventSub = this.markerEventService.getEvents().pipe(filter(event => event.type === 'camera')).subscribe(event => {
            if(this.permissions.site(this.site.siteID, 'live')) {
                this.router.navigate(['site', this.site.siteID,'cameras', event.payload,'live-view']);
            } else if(this.permissions.site(this.site.siteID, 'rewind')) {
                this.router.navigate(['site', this.site.siteID, 'cameras', event.payload, 'rewind']);
            }
            
        })
        this.store.select(s => s.propertyMap.selectedMapID).pipe(take(1)).subscribe(mapID => {
            console.log("Map Loaded: ", mapID)
            this.selectedMapID = mapID;
        })

    }
    ngAfterViewInit() {
        // this.siteSub = this.siteService.getSite().subscribe(site => {
        //     this.store.dispatch({type: CLEAR_MOUSEOVER_EVENT});
        //     if(site) {
        //         this.maps = [];
        //         if(site.maps) {
        //             site.maps.forEach(map => {
        //                 let newMap = new PropertyMap(map);
        //                 if(map.markers) {
        //                     map.markersLocal = this.markerService.createMarkers(map.markers, newMap);
        //                 }
        //                 newMap.markersLocal = map.markersLocal;
        //                 this.maps.push(newMap);
        //             })
        //             this.map = this.maps[0];
        //             console.log(this.maps)
        //             this.maps = [...this.maps];
        //         } else {
        //             this.map = new PropertyMap({})
        //         }
        //         this.site = site;
        //     }
        // })

    }
    mapChange(map: Map) {
        console.log("Map Change: ", map)
        this.store.dispatch({type: SET_MAP, payload: map.id})
    }
    ngOnDestroy() {
        if(this.siteSub) {
            this.siteSub.unsubscribe();
        }
        if(this.eventSub) {
            this.eventSub.unsubscribe();
        }
    }
}