import {first, map, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../users/user.service';
import { PermissionsService } from '../../permissions.service';
import { Observable } from "rxjs";

@Injectable()
export class AdminSiteGroupsGuard  {
    constructor(public userService: UserService, public router: Router, public permissions: PermissionsService) {}

    public canActivate(): Observable<boolean> {
        return this.userService.getUser().pipe(
          filter((user) => !user.initial),
          map((user) => this.permissions.admin("canManageSiteGroups")),
          first(),
        );
      }
    }