import { Component, EventEmitter, Output, Input } from "@angular/core";
import SitePackage from "../../../site-package/models/site-package";
import Feature from "../../../site-package/models/feature";

@Component({
  selector: "app-site-package-edit-modal",
  templateUrl: "./site-package-edit-modal.component.html",
  styleUrls: ["./site-package-edit-modal.component.css"],
})
export class SitePackageEditModalComponent {
  @Output()
  public close: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public save: EventEmitter<SitePackage> = new EventEmitter<SitePackage>();

  @Input()
  public sitePackage: SitePackage;

  @Input()
  public allFeatures: Feature[];

  private editedSitePackage: SitePackage;

  constructor() {}

  public onClose() {
    this.close.emit();
  }

  public onSave() {
    this.save.emit(this.editedSitePackage);
  }

  public onFormValueChange(sitePackage: SitePackage) {
    this.editedSitePackage = sitePackage;
  }
}
