import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromCVSState from './state';
import { Layout } from '../layout';

export const selectCVS = createFeatureSelector<fromCVSState.State>(fromCVSState.CVSKey);

export const selectLayouts = createSelector(
    selectCVS,
    (cvs => cvs.layouts),
);

export const selectNumberOfLayouts = createSelector(
    selectCVS,
    (cvs => cvs.layouts ? cvs.layouts.length : 0),
)

export const selectCVSSiteID = createSelector(
    selectCVS,
    (cvs => cvs.cvsSiteID),
);

export const selectSelectedLayout = createSelector(
    selectCVS,
    (cvs => cvs.selectedLayout),
);

export const selectGridSizes = createSelector(
    selectCVS,
    (cvs => cvs.gridSizes),
);

export const selectCameras = createSelector(
    selectCVS,
    (cvs => cvs.cameras),
);

export const selectCVSDupName = createSelector(
    selectCVS,
    (cvs => {
        if (!cvs.selectedLayout) {
            return false;
        }
        for (let layout of cvs.layouts) {
            if (layout.id !== cvs.selectedLayout.id && cvs.selectedLayout.name.toLowerCase().trim() === layout.name.toLowerCase().trim()) {
                return true;
            }
        }
        return false;
    })
);


export const selectShowCVSLayouts = createSelector(
    selectCVS,
    (cvs => cvs.showCVSLayouts),
);

export const selectLayoutHasChanges = createSelector(
    selectCVS,
    (cvs => cvs.layoutHasChanges),
);

export const selectShowUnsavedChangesDialog = createSelector(
    selectCVS,
    (cvs => cvs.showUnsavedChangesDialog),
);

export const selectShowDeleteLayoutDialog = createSelector(
    selectCVS,
    (cvs => cvs.showDeleteLayoutDialog),
);

export const selectShowAutoGenerateDialog = createSelector(
    selectCVS,
    (cvs => cvs.showAutoGenerateDialog),
);

export const selectCameraLayoutsMap = createSelector(
    selectCVS,
    (cvs => {
        let cameraLayoutsMap: {} = {};
        cvs.layouts.forEach(layout => {
            let id = layout.id
            let name = layout.name
            if (layout.cameraViews) {
                layout.cameraViews.forEach(camera => {
                    if (camera.cameraID in cameraLayoutsMap) {
                        let layouts: string[] = cameraLayoutsMap[camera.cameraID];
                        if (!layouts.includes(name)) {
                            layouts.push(name);
                        }
                        cameraLayoutsMap[camera.cameraID] = layouts;
                    } else {
                        let layouts = [name];
                        cameraLayoutsMap[camera.cameraID] = layouts;
                    }
                })
            }
        })
        return cameraLayoutsMap;
    }),
);

export const selectStandaloneSessions = createSelector(
    selectCVS,
    (cvs => cvs.standaloneSessions),
);