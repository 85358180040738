import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from './state';
import * as LPRActions from './actions';
import { AlertHit } from "../recent-hits/alert-hit";
import { SortData } from "../sort-data";

const reducer = createReducer(
    initialState,
    on(LPRActions.setTopTab, (state: State, {tab}) => {
        return {
            ...state,
            selectedTopTab: tab,
        }
    }),

    on(LPRActions.setBottomTab, (state: State, {tab}) => {
        return {
            ...state,
            selectedBottomTab: tab,
        }
    }),

    on(LPRActions.setLPRSiteID, (state: State, {siteID}) => {
        return {
            ...state,
            lprSiteID: siteID,
        }
    }),

    on(LPRActions.resetLPR, (state: State) => {
        return {
            ...state,
            ...initialState,
        }
    }),

    // Manage Alert Entries

    on(LPRActions.setAlertEntriesSiteID, (state: State, {siteID}) => {
        return {
            ...state,
            alertEntriesSiteID: siteID,
        }
    }),

    on(LPRActions.setAlertEntries, (state: State, {alertEntries}) => {
        return {
            ...state,
            alertEntries: alertEntries,
        }
    }),

    on(LPRActions.appendAlertEntry, (state: State, {entry}) => {
        return {
            ...state,
            alertEntries: [ ...state.alertEntries, entry],
        }
    }),
    on(LPRActions.replaceAlertEntry, (state: State, {entry}) => {
        return {
            ...state,
            alertEntries: state.alertEntries.map(e => e.id === entry.id ? entry : e),
        }
    }),

    on(LPRActions.setShowRemoveAlertEntryModal, (state: State, {show}) => {
        return {
            ...state,
            showRemoveAlertEntryModal: show,
        }
    }),

    on(LPRActions.setAlertEntryToRemove, (state: State, {entry}) => {
        return {
            ...state,
            alertEntryToRemove: entry,
        }
    }),

    // Save/Edit Alert Entry
    on(LPRActions.setTags, (state: State, {tags}) => {
        return {
            ...state,
            tags: tags,
        }
    }),
          
    on(LPRActions.manageAlertEntriesClickColumn, (state: State, {field}) => {
        let newSort = { field: '', inverse: false }
        if(field !== state.alertEntriesSort.field) {
            newSort.field = field;
            return {
                ...state,
                alertEntriesSort: newSort,
            }
        }
        if(field === state.alertEntriesSort.field && !state.alertEntriesSort.inverse) {
            newSort.inverse = true;
            newSort.field = field;
            return {
                ...state,
                alertEntriesSort: newSort,
            }
        }
        return {
            ...state,
            alertEntriesSort: newSort,
        }
    }),

    on(LPRActions.setEditEntry, (state: State, {entry}) => {
        return {
            ...state,
            editEntry: entry,
        }
    }),

    on(LPRActions.setSuccess, (state: State, {success}) => {
        return {
            ...state,
            success: success,
        }
    }),

    on(LPRActions.setSaveDisabled, (state: State, {saveDisabled}) => {
        return {
            ...state,
            saveDisabled: saveDisabled,
        }
    }),

    // Recent Hits
    on(LPRActions.recentHitsSetHits, (state: State, {hits}) => {
        return {
            ...state,
            entriesWithRecentHitsInfo: hits,
        }
    }),
    on(LPRActions.recentHitsClickColumn, (state: State, {field}) => {
        let newSort = { field: '', inverse: false }
        if(field !== state.recentHitsSort.field) {
            newSort.field = field;
            return {
                ...state,
                recentHitsSort: newSort,
            }
        }
        if(field === state.recentHitsSort.field && !state.recentHitsSort.inverse) {
            newSort.inverse = true;
            newSort.field = field;
            return {
                ...state,
                recentHitsSort: newSort,
            }
        }
        return {
            ...state,
            recentHitsSort: newSort,
        }
    }),

    // Alert Details
    on(LPRActions.alertDetailsSetHits, (state: State, {hits}) => {
        return {
            ...state,
            alertDetailsInfo: hits,
        }
    }),
    on(LPRActions.alertDetailsClickColumn, (state: State, {field}) => {
        let newSort = { field: '', inverse: false }
        if(field !== state.alertDetailsSort.field) {
            newSort.field = field;
            return {
                ...state,
                alertDetailsSort: newSort,
            }
        }
        if(field === state.alertDetailsSort.field && !state.alertDetailsSort.inverse) {
            newSort.inverse = true;
            newSort.field = field;
            return {
                ...state,
                alertDetailsSort: newSort,
            }
        }
        return {
            ...state,
            alertDetailsSort: newSort,
        }
    }),
    on(LPRActions.alertDetailsSetSelectedAlert, (state: State, {entry}) => {
        return {
            ...state,
            alertDetailsSelectedEntry: entry,
        }
    }),
    on(LPRActions.alertDetailsReset, (state: State) => {
        return {
            ...state,
            alertDetailsInfo: [],
            alertDetailsLoading: false,
            alertDetailsSort: { field: '', inverse: false },
            alertDetailsActiveAlerts: [],
            alertDetailsSelectedEntry: null,                    
        }
    }),

    on(LPRActions.showViewHitModal, (state: State, {show}) =>{
        return {
            ...state,
            showViewHitModal: show,
        }
    }),
    on(LPRActions.setViewHit, (state: State, {hit}) => {
        return {
            ...state,
            viewHitModalHit: new AlertHit(hit),
        }
    }),
)

export function lprReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}