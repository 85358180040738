import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { config } from '../app.config';
import { AccessKeyService } from '../users/access-key.service'
import { ServiceHelpersService } from '../service-helpers.service';
import { Request } from './request';
import { WtsEvent } from './event';
import { catchError, map } from 'rxjs/operators';
import { Lookup } from 'app/lookup';
import { PortfolioEvent } from './portfolio-event';
import { EventSort } from './event-sort';
import { EventTypeMetric } from './event-type-metric';
import { EventDayHourMetric } from './event-day-hour-metric';

@Injectable()
export class EventService {
    public headers = {};
    constructor(public http: HttpClient, public store: Store<AppState>, public accessKeyService: AccessKeyService, public serviceHelpers: ServiceHelpersService) {
        this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key}));
    }
    getEvents(siteID: number): Observable<WtsEvent[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<WtsEvent[]>(config.apiUrl + "site/" + siteID + "/events", {headers: headers}).pipe(catchError(this.serviceHelpers.handleError), map(events => {
            if(events && events.map) {
                return events.map(e => new WtsEvent(e));
            }
            return events;
        }));
    }
    getPortfolioEvents(
        page: number,
        pageSize: number,
        eventSort: EventSort,
        siteFilter: number[],
        startDate: Date,
        endDate: Date,
        cfTagCategories: number[],
        statusFilter: number[],
        textFilter: string,
        selectedBubble: EventDayHourMetric): Observable<PortfolioEvent> {   
        let headers = new HttpHeaders(this.headers);
        let params = new HttpParams()        
        .set('page', page)
        .set('pageSize', pageSize)
        if(typeof eventSort !== 'undefined' && typeof eventSort.dir !== 'undefined') {
            params = params.set('sortBy', eventSort.field)
            .set('sortOrder', eventSort.dir);
        }
        if(siteFilter.length > 0) {
            params = params.set('sites', siteFilter.join(","));
        }
        if(startDate) {
            params = params.set('startDate', startDate.toISOString());
        }
        if(endDate) {
            params = params.set('endDate', endDate.toISOString());
        }
        if(cfTagCategories.length > 0) {
            params = params.set('cfTagCategories', cfTagCategories.join(","));
        }
        if(statusFilter.length > 0) {
            params = params.set('statusCode', statusFilter.join(","));
        }
        if(textFilter !== "" ) {
            params = params.set('searchText', textFilter);
        }
        if(selectedBubble) {
            params = params.set('day', selectedBubble.y);
            params = params.set('hour', selectedBubble.x);
        }


        return this.http.get<PortfolioEvent>("/api/events", {headers: headers, params: params}).pipe(catchError(this.serviceHelpers.handleError));

    }
    getEventsLight(siteID: number): Observable<WtsEvent[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<WtsEvent[]>(config.apiUrl + "site/" + siteID + "/events/light", {headers: headers}).pipe(catchError(this.serviceHelpers.handleError), map(events => {
            if(events && events.map) {
                return events.map(e => new WtsEvent(e));
            }
            return events;
        }));
    }
    getRequests(siteID: number): Observable<Request[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Request[]>(config.apiUrl + "site/" + siteID + "/requests", {headers: headers}).pipe(catchError(this.serviceHelpers.handleError), map(requests => {
            return requests.map(request => new Request(request));
        }));
    }
    getRequestsActivity(siteID: number): Observable<{[eventID: number]:boolean}> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<{[eventID: number]: boolean}>(`${config.apiUrl}site/${siteID}/requests/activity`, {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getEvent(eventID: number): Observable<WtsEvent> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<WtsEvent>(config.apiUrl + "event/" + eventID, {headers: headers}).pipe(catchError(this.serviceHelpers.handleError), map(event => new WtsEvent(event)));

    }
    getEventForSite(eventID: number, siteID: number): Observable<WtsEvent> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<WtsEvent>(config.apiUrl + "site/" + siteID + "/event/" + eventID, {headers: headers}).pipe(catchError(this.serviceHelpers.handleError), map(event => new WtsEvent(event)));
    }
    getStatuses(): Observable<Lookup[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Lookup[]>(config.apiUrl + "event/statuses", {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }
    addNote(eventID: number, note: {text: string}): Observable<WtsEvent> {
        let headers = new HttpHeaders(Object.assign({}, this.headers, { "Content-Type": 'application/json'}));
        let body = JSON.stringify(note);
        return this.http.post<WtsEvent>(config.apiUrl + "event/" + eventID + "/notes", body, {headers: headers}).pipe(catchError(this.serviceHelpers.handleError), map(event => new WtsEvent(event)));
    }
    getRecentRequests(siteID: number): Observable<Request[]>  {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Request[]>(config.apiUrl + "site/" + siteID + "/requests/last10", {headers: headers}).pipe(catchError(this.serviceHelpers.handleError), map(requests => {
            if(requests && requests.map) {
                return requests.map(r => new Request(r));
            }
            return requests;
        }));
    }
    addImage(eventID: number, file: FormData) {
        let headers = new HttpHeaders(this.headers);
        return this.http.post(config.apiUrl + "event/" + eventID + "/images", file, {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }
    removeImage(eventID: number, name: string) {
      let headers = new HttpHeaders(this.headers);
      return this.http.delete(config.apiUrl + "event/" + eventID + "/image/" + name, {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }
    starEvent(eventID: number, siteID: number) {
        let headers = new HttpHeaders(this.headers);
        return this.http.put<WtsEvent>(config.apiUrl +"site/" + siteID + "/event/" + eventID + "/star", {}).pipe(catchError(this.serviceHelpers.handleError), map(e => new WtsEvent(e)));
    }
    unstarEvent(eventID: number, siteID: number) {
        let headers = new HttpHeaders(this.headers);
        return this.http.put<WtsEvent>(config.apiUrl + "site/" + siteID + "/event/" + eventID + "/unstar", {}).pipe(catchError(this.serviceHelpers.handleError), map(e => new WtsEvent(e)));
    }
    createAutoZipFile(eventID: number): Observable<any> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<any>(config.uploadUrl + "rest/autorequest/createzip/event/" + eventID, {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getSitesForFilter(): Observable<Lookup[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Lookup[]>("api/sites", {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getCfTagCategories(): Observable<Lookup[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Lookup[]>("api/events/cftagcategories", {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getEventStatusCodes(): Observable<Lookup[]> {
        let headers = new HttpHeaders(this.headers);
        return this.http.get<Lookup[]>("api/events/statuscodes", {headers: headers}).pipe(catchError(this.serviceHelpers.handleError));
    }
    getEventTypeMetrics(
        siteFilter: number[],
        startDate: Date,
        endDate: Date,
        cfTagCategories: number[],
        statusFilter: number[],
        textFilter: string): Observable<EventTypeMetric> {   
        let headers = new HttpHeaders(this.headers);
        let params = new HttpParams()
        if(siteFilter.length > 0) {
            params = params.set('sites', siteFilter.join(","));
        }
        if(startDate) {
            params = params.set('startDate', startDate.toISOString());
        }
        if(endDate) {
            params = params.set('endDate', endDate.toISOString());
        }
        if(cfTagCategories.length > 0) {
            params = params.set('cfTagCategories', cfTagCategories.join(","));
        }
        if(statusFilter.length > 0) {
            params = params.set('statusCode', statusFilter.join(","));
        }
        if(textFilter !== "" ) {
            params = params.set('searchText', textFilter);
        }
        return this.http.get<EventTypeMetric>("/api/events/cfTagCategories/metrics", {headers: headers, params: params}).pipe(catchError(this.serviceHelpers.handleError));
    }

    getEventDayHourMetrics(
        siteFilter: number[],
        startDate: Date,
        endDate: Date,
        cfTagCategories: number[],
        statusFilter: number[],
        textFilter: string): Observable<EventDayHourMetric[]> {   
        let headers = new HttpHeaders(this.headers);
        let params = new HttpParams()
        if(siteFilter.length > 0) {
            params = params.set('sites', siteFilter.join(","));
        }
        if(startDate) {
            params = params.set('startDate', startDate.toISOString());
        }
        if(endDate) {
            params = params.set('endDate', endDate.toISOString());
        }
        if(cfTagCategories.length > 0) {
            params = params.set('cfTagCategories', cfTagCategories.join(","));
        }
        if(statusFilter.length > 0) {
            params = params.set('statusCode', statusFilter.join(","));
        }
        if(textFilter !== "" ) {
            params = params.set('searchText', textFilter);
        }
        return this.http.get<EventDayHourMetric[]>("/api/events/eventTimesByDayHour", {headers: headers, params: params}).pipe(catchError(this.serviceHelpers.handleError));
    } 
}