import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, concatMap, withLatestFrom, catchError } from 'rxjs/operators';
import { Store, select } from "@ngrx/store";
import { AppState } from "app/app.state";
import { of } from 'rxjs';
import { SiteListService } from '../../../sites/site-list.service';
import * as siteManagementActions from './actions';
import { NotificationsService } from '../../../notifications/notifications.service';


@Injectable()
export class siteManagementEffects {

    loadSiteList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(siteManagementActions.loadSiteList),
            switchMap(() => {
                return this.siteListService.getManageSitesList().pipe(
                    map(data => {
                        return siteManagementActions.setSiteList({ sites: data });
                    }),
                    catchError(() => {
                        this.notificationsService.error("", "Error loading site list");
                        return of(siteManagementActions.noAction());
                    }),
                )
            })
        )
    })


    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private siteListService: SiteListService,
        private notificationsService: NotificationsService,
    ) { }

}