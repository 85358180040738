import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../users/user';
import { process, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-account-undelete',
  templateUrl: './account-undelete.component.html',
  styleUrls: ['./account-undelete.component.css']
})
export class AccountUndeleteComponent implements OnInit {
  public deletedUsers: User[] = [];
  public state: State = {
    skip: 0,
    take: 50,
  }
  public gridData: GridDataResult = process(this.deletedUsers, this.state)

  constructor() { }

  @Input('deletedUsers') set deletedUsersInput(value: User[]) {
    if (value && value.length > 0) {
      this.deletedUsers = value;
      this.updateGrid();
    }
  }

  @Output('undelete') undeleteUserEvent = new EventEmitter<User>();

  public ngOnInit() { }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.updateGrid();
  }

  updateGrid() {
    this.gridData = process(this.deletedUsers, this.state)
  }

  undeleteUser(user: User) {
    this.undeleteUserEvent.emit(user);    
  }

}
