import { Component, Input } from '@angular/core';

import { EventData } from './event-data';

@Component({
    selector: "app-dashboard-event-display",
    templateUrl: "./dashboard-event-display.component.html",
    styleUrls: ["./dashboard-event-display.component.css"]
})
export class DashboardEventDisplayComponent {
    @Input("data") data: EventData;

}