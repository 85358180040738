import { ExtendedStorageComponent } from "../extended-storage.component";
import { props, createAction} from "@ngrx/store";
import {State} from '@progress/kendo-data-query';
import { ByosSiteBucket, ByosBucketRequest, ByosSite, Storage, ByosBucketCreate  } from "../byosSite";
import { Lookup } from "app/lookup";
import { User } from "app/users/user";

export const noAction = createAction("[Extended Storage] No Action");

export const setSelectedEnabledOption = createAction("[Extended Storage] Set Selected Enabled Option", props<{option: string}>());

export const clearFilters = createAction("[Extended Storage] Clear Filters");

export const getBYOSites =   createAction("[ Extended Storage] Get Byos Sites");
    
export const getBYOSitesFailure = createAction("[Extended Storage] Get BYOS Sites Failure");

export const getBYOSitesSuccess = createAction("[Extended Storage] Get BYOS Sites Success", props<{sites: ByosSite[]}>());

export const setBYOSGridState = createAction("[Extended Storage] Set BYOS Grid State", props<{gridState: State}>());

export const setBYOSUnlicensedGridState = createAction("[Extended Storage] Set BYOS Unlicensed Grid State", props<{gridState: State}>());

export const configureSiteStorage = createAction("[Extended Storage] Configure Site Storage", props<{siteStorage: Storage}>());

export const setShowConfigureStorage = createAction("[Extended Storage] Set Show Configure Storage", props<{show: boolean}>());

export const setTextFilter = createAction("[Extended Storage] Set Text Filter", props<{textFilter: string}>());

export const searchByos = createAction("[Extended Storage] Search Storage");

export const changeStorage = createAction("[Extended Storage] Change Storage", props<{siteStorage: Storage}>());

export const showChangeStorage = createAction("[Extended Storage] Show Change Storage",  props<{show: boolean}>());

export const loadAccountss = createAction('[Extended Storage] Load Accounts');

export const existingStorageAccountChange = createAction('[Extended Storage] Existing Storage Account Change', props<{ account: Lookup }>());

export const updateBYOSSiteBucket = createAction('[Extended Storage] Update BYOS Site Bucket', props<{ account: Lookup }>());

export const updateBYOSSiteBucketSuccess = createAction("[Extended Storage] Update BYOS Site Bucket Success", props<{bucket: ByosSiteBucket}>());

export const updateBYOSSiteBucketFailure = createAction("[Extended Storage] Update BYOS Site Bucket Failure");

export const  getVendors = createAction("[Extended Storage] Get Vendors");

export const getVendorsSuccess = createAction("[Extended Storage] Get Vendors Success", props<{vendors: Lookup[]}>());

export const getVendorsFailure = createAction("[Extended Storage] Get Vendors Failure");

export const getBuckets = createAction("[Extended Storage] Get Buckets", props<{accountInfo: ByosBucketRequest}>());

export const getBucketsSuccess = createAction("[Extended Storage] Get Buckets Success", props<{buckets: string[]}>());

export const getBucketsFailure = createAction("[Extended Storage] Get Buckets Failure");

export const addStorageAccount = createAction("[Extended Storage] Add Buckets", props<{account:  ByosBucketCreate}>());

export const addStorageAccountSuccess = createAction("[Extended Storage] Add Buckets Success", props<{account: ByosSiteBucket}>());

export const addStorageAccountFailure = createAction("[Extended Storage] Add Buckets Failure");

export const unassignStorage = createAction("[Extended Storage] Unassign Storage");
 
export const unassignStorageSuccess = createAction("[Extended Storage] Unassign Storage Success");

export const unassignStorageFailure = createAction("[Extended Storage] Unassign Storage Failure");

export const getExtendedStorageForm = createAction("[Extended Storage] Get Extended Storage Form", props<{siteID: number}>());

export const setUnlicensedSiteSelections = createAction("[Extended Storage] Set Unlicensed Site Selections", props<{siteSelections: number[]}>()); 

export const setShowUnassignStorageDialog = createAction("[Extended Storage] Set Show Unassign Storage Dialog", props<{show: boolean}>());

export const setShowChangeStorageConfirmDialog = createAction("[Extended Storage] Set Show Change Storage Confirm Dialog", props<{show: boolean}>());

export const submitInquiry = createAction("[Extended Storage] Submit Inquiry");

export const submitInquirySuccess = createAction("[Extended Storage] Submit Inquiry Success", props<{sites: Lookup[]}>());

export const submitInquiryFailure = createAction("[Extended Storage] Submit Inquiry Failure");

export const showInquiryConfirmation = createAction("[Extended Storage] Show Inquiry Confirmation", props<{show: boolean}>());