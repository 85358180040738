import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NotificationsService } from "app/notifications/notifications.service";

import * as AddCamsActions from "./actions";
import { AddCamsReducer } from "./reducer";
import { switchMap, map, concatMap, withLatestFrom, flatMap } from "rxjs/operators";
import { of } from "rxjs";
import * as fromAddCamsState from "./state"

import { Store, select } from "@ngrx/store";
import { AppState } from "app/app.state";
import { selectedAdditionalCameras, isCameraSelected, numCamsSelected, cameraData } from "./selector";
import { CameraService } from "app/camera/camera.service";

@Injectable()
export class AddCamsEffects {
    clickCamera$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AddCamsActions.clickCamera), 
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(isCameraSelected(action.marker.id)))),
                    withLatestFrom(this.store.pipe(select(numCamsSelected))),
                    withLatestFrom(this.store.select(s => s.site).pipe(map(s => s.autoRequestAdditionalCameras)))
                )
            }),
            map(([[[action, isSelected], numCamsSelected], numAddCams]) => {
                this.store.dispatch(AddCamsActions.loadCamera({ cameraID:+action.marker.camera}));
                if(isSelected) {
                    return AddCamsActions.removeCamera({marker: action.marker});
                }
                if(numCamsSelected < (numAddCams)) {
                    return AddCamsActions.addCamera({marker: action.marker});
                }
                return AddCamsActions.maxCameraModalOpen();
                // this.notificationsService.error("",`You have already selected the max of ${numAddCams} additional cameras.`);
                // return AddCamsActions.tooManyCamsClicked();
            })
        )
    })
    loadCamera$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AddCamsActions.loadCamera),
            concatMap(action => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(select(cameraData))),
                    withLatestFrom(this.store.select(s => s.site)),
                )
            }),
            flatMap(([[action, camData], site]) => {
                if(camData && camData[action.cameraID]) {
                    return of(AddCamsActions.cameraAlreadyLoaded());
                } else {
                    return this.cameraService.getCamera(action.cameraID, site.siteID).pipe(map(cam => AddCamsActions.addCameraData({camera:cam})))
                }
            })
        )
    })

    constructor(
        private actions$: Actions, 
        private notificationsService: NotificationsService,
        private store: Store<AppState>,
        private cameraService: CameraService,
    ) {}
}