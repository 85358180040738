export function convertFromUTCDate(date: Date): Date {
    let newDate = new Date();

    newDate.setFullYear(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    newDate.setHours(0,0,0,0);
    return newDate;
}

export function convertToUTCDate(date: Date): Date {
    let newDate = new Date();

    newDate.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    newDate.setUTCHours(0,0,0,0);
    return newDate;
}