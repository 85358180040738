import { Component, OnInit, EventEmitter, OnDestroy, Input, Output } from '@angular/core';
import { PasswordRequirements } from './password-requirements';


@Component({
  selector: 'app-password-validator',
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.css']
})
export class PasswordValidatorComponent implements OnInit {
  @Output("password") passwordEmitter: EventEmitter<string> = new EventEmitter();
  @Output("validation") validationEmitter: EventEmitter<boolean> = new EventEmitter();


  public newPassword: string = "";
  public confirmPassword: string = "";

  public passwordStrengthOk: boolean = false;
  public passwordRequirements: PasswordRequirements = { lengthOk: false, hasLowerCase: false, hasUpperCase: false, hasNumber: false, hasSpecial: false };

  constructor() { }

  ngOnInit() { }


  onNewPasswordChange(pwd: string) {
    this.resetPasswordRequirements();

    if (pwd.length >= 8) {
      this.passwordRequirements.lengthOk = true;
    }

    if (/[a-z]/.test(pwd)) {
      this.passwordRequirements.hasLowerCase = true;
    }

    if (/[A-Z]/.test(pwd)) {
      this.passwordRequirements.hasUpperCase = true;
    }

    if (/[0-9]/.test(pwd)) {
      this.passwordRequirements.hasNumber = true;
    }

    if (/[!@#$%^&*]/.test(pwd)) {
      this.passwordRequirements.hasSpecial = true;
    }

    let count: number = 0;
    let checkArray = [this.passwordRequirements.hasLowerCase, this.passwordRequirements.hasUpperCase, this.passwordRequirements.hasNumber, this.passwordRequirements.hasSpecial];

    checkArray.forEach(element => {
      if (element) {
        count++;
      }
    });

    if (count >= 3) {
      this.passwordStrengthOk = true;
    } else {
      this.passwordStrengthOk = false;
    }

    this.passwordEmitter.emit(this.newPassword);

    if (this.passwordRequirements.lengthOk && this.passwordStrengthOk && this.newPassword === this.confirmPassword) {
      this.validationEmitter.emit(true);
    } else {
      this.validationEmitter.emit(false);
    }


  }

  onConfirmPasswordChange(pwd: string) {
    this.onNewPasswordChange(this.newPassword); 
  }

  resetPasswordRequirements() {
    this.passwordRequirements = { lengthOk: false, hasLowerCase: false, hasUpperCase: false, hasNumber: false, hasSpecial: false };
  }

}
