import { State as GridState } from '@progress/kendo-data-query';
import { SiteGroup, SiteGroupFull } from '../site-group';
import { Lookup } from 'app/lookup';

export const siteGroupsKey = "siteGroups"

export interface State {
    siteGroups: SiteGroup[],
    siteGroupsLoading: boolean,
    siteGroupsGridState: GridState,
    showCreateSiteGroupModal: boolean,

    // Edit Site Group
    editGroupLoading: boolean,
    editGroup: SiteGroupFull,
    editSelectedSites: Lookup[],
    editSaving: boolean,
    editSites: Lookup[],
    editName: string,
    editSiteFilter: string,

    // Delete Site Group
    showDeleteSiteGroupModal: boolean,
    siteGroupSelectedForDelete: SiteGroupFull,
}

export const initialState: State = {
     siteGroups: [],
     siteGroupsLoading: false,
     siteGroupsGridState: {},
     showCreateSiteGroupModal: false,

     // Edit Site Group
     editGroupLoading: false,
     editGroup: null,
     editSelectedSites: [],
     editSaving: false,
     editSites: [],
     editName: "",
     editSiteFilter: "",

     // Delete Site Group
     showDeleteSiteGroupModal: false,
     siteGroupSelectedForDelete: null,
}

