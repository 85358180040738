import { Option } from './option';

export class Camera {
    public id: number;
    public hqadminSiteID: number;
    public siteID: number;
    public siteName: string;

    public stream: Stream;
    public imgUrl: string;
    public options: Option[];
    public displayName: string;
    public cameraNumber: number;
    public dayReferenceFilename: string;
}

export class Stream {
    hls: HLS;
    webRTC: WebRTC;
    webRTCCloud: WebRTC;
    username: string;
    password: string;
}

export class HLS {
    url: string;
}

export class WebRTC {
    url: string;
}