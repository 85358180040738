import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { AppState } from "app/app.state";
import { Store } from "@ngrx/store";
import { Router } from '@angular/router';
import { ResizedEvent } from '../../angular-resize.directive';
import { Storage } from '../byosSite';
import { PermissionsService } from 'app/permissions.service';
import { LicenseCount } from '../license-count';

@Component({
  selector: 'app-portfolio-extended-storage',
  templateUrl: './portfolio-extended-storage.component.html',
  styleUrls: ['./portfolio-extended-storage.component.css']
})
export class PortfolioExtendedStorageComponent implements OnInit {

  @Input('byosGridView') set setEvents(value: GridDataResult) {
    if (value) {
      this.byosGridView = value;
    }
  }

  @Input('gridState') set setGridState(value: State) {
    if (value) {
      this.gridState = value;
    }
  }

  @Input('byosUnlicensedGridView') set setUnlicensedEvents(value: GridDataResult) {
    if (value) { 
      this.byosUnlicensedGridView = value;
    }
  }

  @Input('unlicensedGridState') set setUnlicensedGridState(value: State) {
    if (value) {
      this.unlicensedGridState = value;
    }
  }

  @Input('unlicensedSiteSelections') set setUnlicensedSiteSelections(value: number[]) {
    if (value) {
      this.unlicensedSiteSelections = value;
    }
  }

  @Input('licenseCount') set setLicenseCount(value: LicenseCount) {
    if (value) {
      this.licenseCount = value;
    }
  }

  @Input('showInquiryConfirmation') set setShowInquiryConfirm(value: boolean) {    
    this.showInquiryConfirm = value; 
  }

  @Input('inquirySelectedSites') set setInquirySelectedSites(value: string[]) {
    if (value) {
      this.inquirySelectedSites = value;
    }    
  }



  @Output() gridStateChange: EventEmitter<State> = new EventEmitter<State>();
  @Output() unlicensedGridStateChange: EventEmitter<State> = new EventEmitter<State>();
  @Output() configureStorage: EventEmitter<Storage> = new EventEmitter<Storage>();
  @Output() changeStorage: EventEmitter<any> = new EventEmitter<any>();
  @Output() unlicensedSiteSelectionsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() inquirySubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() showInquiryConfirmation: EventEmitter<boolean> = new EventEmitter<boolean>();
 
  public byosGridView: GridDataResult = { data: [], total: 0 };
  public gridState: State = { skip: 0, take: 10 };
  public byosUnlicensedGridView: GridDataResult = { data: [], total: 0 };
  public unlicensedGridState: State = { skip: 0, take: 10 };
  public siteStorage: Storage;;
  public showForm: boolean = false;
  public unlicensedSiteSelections: number[] = [];
  public licenseCount: LicenseCount = { sitesWithLicense: 0, sitesWithoutLicense: 0 };
  public showInquiryConfirm: boolean = false;
  public inquirySelectedSites: string[] = [];
  
  gridWidth: number = 0;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    public permissions: PermissionsService
  ) { }

  ngOnInit(): void { }

  public onByosGridStateChange(state: DataStateChangeEvent) {
    this.gridStateChange.emit(state);
  }

  public onSelectedKeysChange(keys: number[]) {
    this.unlicensedSiteSelectionsChange.emit(keys);
  }

  public onByosUnlicensedGridStateChange(state: DataStateChangeEvent) {
    this.unlicensedGridStateChange.emit(state);
  }

  public onGridResized(event: ResizedEvent) {
    this.gridWidth = Math.round(event.newRect.width);
  }

  public onConfigureStorage(dataItem: Storage) {
    console.log('permission check', this.permissions.site(dataItem.siteID, 'canBYOS'));
    if(dataItem.accountName === undefined) {
     this.changeStorage.emit(dataItem);
    }
    else {
      this.configureStorage.emit(dataItem);
    }  
  }

  public onShowInquiryConfirmation() {
    this.showInquiryConfirmation.emit(true);
  }

  public closeInquiryConfirmation() {
    this.showInquiryConfirmation.emit(false);
  }  

  public onSubmitInquiry() {
   this.inquirySubmitted.emit();   
  }

}
