import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable()
export class NotificationsService {
    constructor(private notificationService: NotificationService) {}

    success(title: string, body: string): void {
        this.notificationService.show({
            content: body,
            hideAfter: 3000,
            position: { horizontal: 'center', vertical: "top" },
            animation: { type: 'fade', duration: 400 },
            type: { style: 'success', icon: true},
        })
        console.log("Success", body);
    }
    error(title: string, body: string): void {
        this.notificationService.show({
            content: body,
            hideAfter: 3000,
            position: { horizontal: 'center', vertical: "top" },
            animation: { type: 'fade', duration: 400 },
            type: {style: 'error', icon: true},
        })
    }
}