import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Lookup } from 'app/lookup';
import { UserSearch } from './user-search';


@Component({
  selector: 'admin-user-selector',
  templateUrl: './admin-user-selector.component.html',
  styleUrls: ['./admin-user-selector.component.css']
})
export class AdminUserSelectorComponent implements OnInit, OnDestroy {
  @Input() manComps: Lookup[];
  @Input() manCompsLoading: boolean;
  @Input() siteGroups: Lookup[];
  @Input() sites: Lookup[];
  @Input() hardTypes: Lookup[];
  @Input() types: Lookup[];
  @Input() permTemplates: Lookup[];
  @Input() searching: boolean;
  @Input("searchForm") set setSearchForm(value: UserSearch) {
    console.log(value)
    this.searchForm = {...value};
  };
  @Output('search') searchEmitter = new EventEmitter<any>();
  @Output('onFormUpdate') onFormUpdateEmitter = new EventEmitter<UserSearch>();

  public alive = true;
  public searchForm: UserSearch;

  constructor() { 
  }

  ngOnInit() {    
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onFormChange(field: string, value: any) {
    this.searchForm[field] = value;
    this.onFormUpdateEmitter.emit(this.searchForm);
  }
  search() {
    this.searchEmitter.emit(this.searchForm);
  }
}
