import { Message } from '../message';

export const messagesKey = "messages"

export interface State {
    userMessages: Message[],

}

export const initialState: State = {
    userMessages: [],
}