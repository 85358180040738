import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, combineLatest, Subject } from 'rxjs';
import { AppInsightsService } from './app-insights.service';
import { AuthFacade } from './auth/auth.facade';
import { IssueCollectorService } from './issue-collector.service';
import { FeatureFlagService } from './feature-flag/feature-flag.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  authState$: Observable<{ isAuthenticated: boolean; isLoading: boolean; sessionCreated: boolean; }>;

  private destroy$ = new Subject<void>();

  constructor(
    private insights: AppInsightsService,
    private authFacade: AuthFacade,
    private router: Router,
    private issueCollectorService: IssueCollectorService,
    private featureFlagService: FeatureFlagService
  ) {

    this.authFacade.login();
    this.authState$ = combineLatest([ 
      authFacade.isAuthenticated$,
      authFacade.isLoading$,
      authFacade.sessionCreated$
    ]).pipe(
      map(([isAuthenticated, isLoading, sessionCreated]) => ({
        isAuthenticated,
        isLoading,
        sessionCreated
      }))
    );
 
    // this.insights.setupInsights();
  }

  ngOnInit() {
    this.featureFlagService.isFeatureEnabled$('issue-collector-service').pipe(takeUntil(this.destroy$)).subscribe((isEnabled) => {
      if (isEnabled) {
        this.issueCollectorService.loadIssueCollector();
      }
    });
    
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
}


}
