
import {map, catchError, filter, first, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import { AppState } from '../app.state';
import { UserService } from './user.service';
import { User } from './user'
import { SET_USER, USER_LOADED, USER_STATES } from './user.reducer';
import { AuthFacade } from '../auth/auth.facade';

@Injectable()
export class UserLoaderGuard  {
    constructor(public userService: UserService, public router: Router, public store: Store<AppState>, private authFacade: AuthFacade) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.loadUser()        
    }
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.getUserLoaded().pipe(filter(loaded => loaded === USER_STATES.LOADED ),take(1),map(loaded => true),);
    }
    loadUser() {
        return this.userService.fetchUserForLoader().pipe(map(resp => {
            if(resp.status < 200 || resp.status >= 300) {
                throw new Error('Bad response status: ' + resp.status);
            }
            let user = new User(resp.body);
            this.store.dispatch({type: SET_USER, payload: user});
            this.store.dispatch({type: USER_LOADED });
            return true;
        }),catchError( (error: any) =>{
            this.authFacade.logout();
            return new Observable<boolean>(observer => {
                observer.next(false);
                observer.complete();
            });
        }),first(),)

    }
}