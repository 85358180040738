import { Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BehaviorSubject, Observable, Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeatureFlagService } from '../../feature-flag/feature-flag.service';

@Component({
    selector: "app-portfolio-management",
    templateUrl: "./portfolio-management.component.html",
    styleUrls: ["./portfolio-management.component.css"],
    animations: [
        trigger('stretchIn', [
            state('in', style({height: '*'})),
            transition('* => void', [
                style({height: "*"}),
                animate(250, style({height:0}))
            ]),
            transition('void => *', [
                style({height: 0}),
                animate(250, style({height:"*"}))
            ])
        ])
    ]
})
export class PortfolioManagementComponent implements OnInit, OnDestroy {
    @Input("user") user;
    showSites: boolean;
    showPortfolioManagement: boolean = true;
    showEvents$: Observable<boolean> = new BehaviorSubject<boolean>(false);
    showCameras$: Observable<boolean> = new BehaviorSubject<boolean>(false);
    showByos$: Observable<boolean> = new BehaviorSubject<boolean>(false);

    private destroy$ = new Subject<void>();

    constructor(private featureFlagService: FeatureFlagService) {}

    ngOnInit(): void {
        this.showEvents$ = this.featureFlagService.isFeatureEnabled$('multi-site-events').pipe(takeUntil(this.destroy$));
        this.showCameras$ = this.featureFlagService.isFeatureEnabled$('multi-site-cameras').pipe(takeUntil(this.destroy$));
        this.showByos$ = this.featureFlagService.isFeatureEnabled$('byos').pipe(takeUntil(this.destroy$));
    }   

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
 
    togglePortfolioManagement(): void {
        this.showPortfolioManagement = !this.showPortfolioManagement;
    }
}
