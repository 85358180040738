// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stateContainer {
    width: 50px;
}
.panel.panel-default {
    padding-bottom: 20px;
}
.vehicleNumber {
    display: inline;
}
.panel.panel-default {
    padding-bottom: 20px;
    padding-top: 20px
}

label {
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/requests/new-request/where/vehicle/vehicle.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,oBAAoB;AACxB;AACA;IACI,eAAe;AACnB;AACA;IACI,oBAAoB;IACpB;AACJ;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".stateContainer {\r\n    width: 50px;\r\n}\r\n.panel.panel-default {\r\n    padding-bottom: 20px;\r\n}\r\n.vehicleNumber {\r\n    display: inline;\r\n}\r\n.panel.panel-default {\r\n    padding-bottom: 20px;\r\n    padding-top: 20px\r\n}\r\n\r\nlabel {\r\n    font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
