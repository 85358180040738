export class ByosBackfillJob {
    public id: number;
    public siteID: number;
    public fileCount: string;
    public createdDate: Date;
    public createdByID: number;
    public canceledDate: Date;
    public canceledByID: number;
    public isCompleted: boolean;
    public completedDate: Date;

    constructor(options?: any) {
        if (options) {
            Object.assign(this, options);
            if (this.createdDate) {
                this.createdDate = new Date(this.createdDate);
            }
            if (this.canceledDate) {
                this.canceledDate = new Date(this.canceledDate);
            }
            if (this.completedDate) {
                this.completedDate = new Date(this.completedDate);
            }
        }
    }

}