
import {take, map, takeUntil} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, combineLatest, Subject } from 'rxjs';


import { Video } from '../video';
import { CameraService } from '../camera.service';
import { RewindPlayerComponent } from './rewind-player/rewind-player.component';
import { RewindRequest } from './rewind-request';
import { UserService } from '../../users/user.service';
import { User } from '../../users/user';
import { NotificationsService } from '../../notifications/notifications.service';
import { SiteService } from '../../site/site.service';
import { Site } from '../../site/site';
import { Logger } from '../../logger/logger.service';
import { PermissionsService } from '../../permissions.service';
import { NEW_REQUEST, REQUEST_SET_CONTACT_INFO_USER, RESET_REQUEST_STAGES, REQUEST_SET_SITEID } from '../../requests/requests.reducer';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../app.state';

import { TimeObj } from '../../time-obj';
import { Cameras } from 'app/requests/new-request/cameras/cameras';
import { Camera } from '../camera';
import { Map } from 'app/map/map';
import { AddCamsService } from './add-cams/add-cams.service';
import * as AddCamsActions from './add-cams/store/actions';
import * as AddCamsSelectors from './add-cams/store/selector';
import { PropertyMap } from 'app/property-map/property-map';
import { AddCamsMarker } from './add-cams/add-cams-marker';
import { setPrimaryCamera, resetCameras, loadCamera } from './add-cams/store/actions';
import { primaryCamera, cameraData, selectedCameras } from './add-cams/store/selector';
import { FeatureType } from 'app/site-package/models';
import { FeatureService } from 'app/site-package/services';
import { RequestType } from "app/requests/request-type";
import { convertFromUTCDate } from 'app/shared/utc-converter';


@Component({
    selector: "app-rewind",
    templateUrl: "./rewind.component.html",
    styleUrls: ["./rewind.component.css"],
})
export class RewindComponent implements OnInit{
    @ViewChild("videoPlayer", {static: true}) videoPlayer: RewindPlayerComponent;

    selectedVideo: Video;
    selectedDay: Date = new Date();
    days: Date[] = [];
    videos: Video[] = [];
    cameraID: number;
    siteID: number;
    user: User;
    request: RewindRequest = new RewindRequest();
    showRequest: boolean = false;
    site: Site;
    camera: Camera;
    player: RewindPlayerComponent;
    noRewind = '';
    public cameras$: Observable<Camera[]>;
 
    public showAddCams: boolean;
    public addCamsMaps$: Observable<Map[]>;
    public primaryCamMapID$: Observable<number>;
    public mapsWithSelectedCamerasIDs$: Observable<number[]>;
    public FeatureType: FeatureType;
    public isAutoRequest: boolean;
    public requestModalOpened: boolean;
    public submitInProgress: boolean = false;
    public hasLivePermission: boolean;
    public hasCanCreateTicketsPermission: boolean;
    public hasCanCreateRawRequestsPermission: boolean;
    public hasRawRequestFeature: boolean;
    public hasFullRequestFeature: boolean;
    public maxCameraModalVisible$: Observable<boolean>;
    public rewindHours: number;

    private videosSub: Subscription;
    private destroy$ = new Subject<void>();

    constructor(
        private cameraService: CameraService, 
        private route: ActivatedRoute, 
        private userService: UserService, 
        private notificationsService: NotificationsService, 
        private siteService: SiteService, 
        private logger: Logger, 
        private router: Router, 
        private store: Store<AppState>,
        private addCamsService: AddCamsService,
        private featureService: FeatureService,
        private permissionsService: PermissionsService,
    ) {}

    public ngOnInit() {
        this.userService.getUser().pipe(takeUntil(this.destroy$)).subscribe(user => this.user = user);
        this.siteService.getSite().pipe(takeUntil(this.destroy$)).subscribe(site => {
            this.site = site;
             this.hasLivePermission = this.permissionsService.site(this.site.siteID, "live");
            this.hasCanCreateTicketsPermission = this.permissionsService.site(this.site.siteID, "canCreateTickets");
            this.hasCanCreateRawRequestsPermission = this.permissionsService.site(this.site.siteID, "canCreateRawRequests");
        });        

        this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
            this.cameraID = +params["cameraID"];
            this.siteID = +params["siteID"];
            if(this.videosSub) {
                this.videosSub.unsubscribe();
            }

            this.cameraService.getCamera(this.cameraID, this.siteID).pipe(takeUntil(this.destroy$)).subscribe(camera => {
                this.camera = camera;
            });

            this.videosSub = this.cameraService.getVideos(this.cameraID, this.siteID, this.site.daysRewind).pipe(takeUntil(this.destroy$)).subscribe(videos => {
                this.videos = videos;
                this.days = [];
                videos.forEach(video => {
                    let day = new Date(video.startTime);
                    day.setUTCHours(0,0,0,0);
                    let found = false;
                    this.days.forEach(exDay => {
                        if(exDay.getTime() === day.getTime()) {
                            found = true;
                        }
                    });

                    if(!found) {
                        this.days.push(day);
                    }
                });

                if(this.videos.length > 0) {
                    this.selectVideo(this.videos[0]);
                    this.selectDay(this.days[0]);
                    this.noRewind = "";
                } else {
                    this.noRewind = "No rewind was found for the selected camera.";
                }
            });

            this.addCamsMaps$ = this.addCamsService.getMaps();
            this.store.dispatch(setPrimaryCamera({marker: this.getMarker(this.cameraID)}));
            this.store.dispatch(resetCameras());
            this.store.dispatch(loadCamera({cameraID: this.cameraID}));
            this.cameras$ = this.store.pipe(select(AddCamsSelectors.selectCamerasWithData));
            
            this.primaryCamMapID$ = combineLatest(
                this.store.pipe(select(primaryCamera)),
                this.addCamsMaps$,
            ).pipe(map(([pCam, maps]) => {
                for(let map of maps) {
                    let markers = map.markersLocal.map(r => r.id);
                    if(markers.indexOf(pCam.id) !== -1) {
                        return map.id;
                    }
                }

                return 0;
            }));

            this.mapsWithSelectedCamerasIDs$ = combineLatest(
                this.store.pipe(select(selectedCameras)),
                this.addCamsMaps$,
            ).pipe(map(([cams, maps]) => {
                let foundMapIDs = [];
                for (let map of maps) {
                    for (let cam of cams) {
                        if(map.markersLocal) {
                            let markerIDs = map.markersLocal.map(r => r.id)
                            if(markerIDs.indexOf(cam.id) !== -1) {
                                foundMapIDs.push(map.id);
                            }
                        }
                    }
                }
                return foundMapIDs;
            }));
        });

        this.isAutoRequest = this.featureService.checkFeatureInPackage(FeatureType.AutoRequest);
        this.maxCameraModalVisible$ = this.store.pipe(select(AddCamsSelectors.maxCameraModalVisible));
        this.hasRawRequestFeature = this.featureService.checkFeatureInPackage(FeatureType.RawRequest);
        this.hasFullRequestFeature = this.featureService.checkFeatureInPackage(FeatureType.FullRequest);
        this.rewindHours = this.site.daysRewind*24;
    }

    public ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onCloseRequestModal() {
        this.requestModalOpened = false;
    }

    newRequest() {
        //setup request
        this.store.dispatch({type: NEW_REQUEST});
        this.store.dispatch({type: RESET_REQUEST_STAGES });
        this.userService.getUser().pipe(take(1)).subscribe(user => 
            this.store.dispatch({type: REQUEST_SET_CONTACT_INFO_USER, payload: user})
        );

        this.siteService.getSite().pipe(take(1)).subscribe(site => {
            this.store.dispatch({ type: REQUEST_SET_SITEID, payload: site.siteID });
            this.router.navigate(["site", site.siteID,"request", "new-request", "when"]);
        });
    }

    selectDay(date: Date) {
        this.selectedDay = date;
    }

    selectVideo(video:Video) {
        this.selectedVideo = video;
        this.logger.sendRewindInfo({cameraID: this.cameraID, startTime: video.startTime}, this.siteID).pipe(takeUntil(this.destroy$)).subscribe();
        this.logger.trackAction(5, this.siteID).subscribe();
        this.videoPlayer.load();
        this.videoPlayer.play();
    }

    weekdayString(num: number): string {
        if(num > 6 || num < 0) {
            return "";
        }

        let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        return days[num];
    }

    rewindDate(day: Date): Date {
        return convertFromUTCDate(day);
    }

    startRequest(time) {
        let newRequest = new RewindRequest();
        
        let utc = new Date(this.selectedVideo.startTime.getTime());
        let local = new Date(this.selectedVideo.startTime.getTime());
        local.setHours(utc.getUTCHours(), utc.getUTCMinutes(), utc.getUTCSeconds(), utc.getUTCMilliseconds());
        local.setFullYear(utc.getUTCFullYear(), utc.getUTCMonth(), utc.getUTCDate() );
        let newTime = local;
        newTime.setSeconds(newTime.getSeconds() + time);
        newRequest.when.start = newTime;
        newRequest.when.end = newTime;
        newRequest.siteID = this.siteID;
        let markers;
        this.store.pipe(select(selectedCameras), take(1)).subscribe(s => {
            markers = s;
        });

        newRequest.cameras = new Cameras(markers);
        newRequest.contactInfo.user = this.user;
        this.request = newRequest
        this.player = this.videoPlayer;
        this.requestModalOpened = true;
    }

    submit(req) {
        this.submitInProgress = true;
        let request = Object.assign(new RewindRequest(), req);
        request.isAutoRequest = this.isAutoRequest;
        request.when = {};
        request.when.start = new TimeObj(req.when.start);
        request.when.end = new TimeObj(req.when.end);
        let markers;
        this.store.pipe(select(selectedCameras), take(1)).subscribe(s => {
            markers = s;
        });

        request.cameras = new Cameras(markers);        
        let img
        if(request.image) {
            img = request.image;
        }

        request.cameras = new Cameras(markers);

        let primaryCameraID: number;
        this.store.pipe(select(primaryCamera), take(1)).subscribe(primaryCamera => {
            primaryCameraID = primaryCamera.camera;
        });

        request.primaryCameraID = primaryCameraID;

        delete request.image;

        request.requestType = RequestType.Rewind;

        this.cameraService.expressRequest(request).pipe(takeUntil(this.destroy$)).subscribe(resp =>{
            if(img) {
                this.cameraService.uploadImage(resp.eventID, img).pipe(takeUntil(this.destroy$)).subscribe(resp => {
                    this.notificationsService.success("", "Request Submitted");
                    this.submitInProgress = false;
                    this.requestModalOpened = false;
                    this.router.navigate(['site', this.siteID, 'events']);    
                }, err => {
                    this.submitInProgress = false;
                    this.notificationsService.error("", "Error uploading image");
                    console.error(err);
                })    
            } else {
                this.notificationsService.success("", "Request Submitted");
                this.submitInProgress = false;
                this.requestModalOpened = false;
                this.router.navigate(['site', this.siteID, 'events']);    
            }
        }, err => {
            this.submitInProgress = false;
            this.notificationsService.error("", "Error Submitting Request")
        })
    }

    getMarker(cameraID: number):AddCamsMarker {
        let result: AddCamsMarker;
        let maps: PropertyMap[];
        this.addCamsMaps$.pipe(take(1)).subscribe(m => maps = m);
        maps.forEach(map => {
            if(map.markersLocal) {
                map.markersLocal.forEach(m => {
                    let marker = m as AddCamsMarker;
                    if(marker.type=== "camera" && +marker.camera === cameraID) {
                        result = marker;
                    }
                })
            }
        });
        return result;
    }

    done() {
        let i = 0;
        for(i = 0; i < this.videos.length; i+=1) {
            if(this.videos[i] === this.selectedVideo) {
                break;
            }
        }

        if(i+1 < this.videos.length) {
            this.selectVideo(this.videos[i+1]);
        }
    }

    onAddCams() {
        this.showAddCams = true;
    }

    onAddCamsSave() {
        this.showAddCams = false;
    }

    onAddCamsCancel() {
        this.showAddCams = false;
        this.store.dispatch(resetCameras());
    }

    maxCameraModalClose() {
        this.store.dispatch(AddCamsActions.maxCameraModalClose())
       }
    rawRequest() {
        this.router.navigate(["site", this.siteID, "raw-request"], {queryParams: {cameraID: this.cameraID}});
    }
}