import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { Camera } from 'app/camera/camera';
import { CameraService } from 'app/camera/camera.service';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Hls2PlayerComponent } from '../hls2-player/hls2-player.component';
import { WebrtcPlayerComponent } from '../webrtc-player/webrtc-player.component';
import { FeatureFlagService } from 'app/feature-flag/feature-flag.service';

@Component({
  selector: 'app-hybrid-player',
  templateUrl: './hybrid-player.component.html',
  styleUrls: ['./hybrid-player.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HybridPlayerComponent {
  @Input('camera') camera: Camera;
  @Input('isCloud') isCloud: boolean;
  @Input('showLogo') showLogo: boolean = true;
  @Input('stretch') stretch: boolean = false;
  @Input('hasHls') hasHls: boolean = true;
  @ViewChild('webrtc') webRTC: WebrtcPlayerComponent;
  @ViewChild('hls') hls: Hls2PlayerComponent;
  showWebrtc: boolean = false;
  showHls2: boolean = true;
  hlsReady: boolean = false;
  webrtcReady: boolean = false;
  hlsError: boolean = false;
  webrtcError: boolean = false;
  public loaderStateText: string = "Loading"
  public ready: boolean = false;
  private alive: boolean = true;
  public hlsFeature: boolean = false;
  public error: boolean = false;
  public auth: { username: string, password: string } = { username: '', password: '' };
  constructor(
    private cameraService: CameraService,
    private featureFlagService: FeatureFlagService,
  ) { }
  ngOnInit() {
    interval(600000).pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.cameraService.getCameraStreamAuth(this.camera.id, this.camera.siteID).subscribe((auth) => {
        this.auth = auth;
      });
    });
    this.featureFlagService.isFeatureEnabled$('hybrid-player-hls').pipe(takeWhile(() => this.alive)).subscribe((enabled) => {
      this.hlsFeature = enabled;
    });
  }
  ngOnDestroy() {
    this.alive = false;
  }
  onWebrtcReady(ready: boolean) {
    this.webrtcReady = ready;
    if (ready) {
      this.ready = true;
      this.showHls2 = false;
      this.showWebrtc = true;
      this.error = false;
    } else {
      this.showHls2 = true;
      this.showWebrtc = false;
    }
  }
  onWebrtcError(error: boolean) {
    this.webrtcError = error;
    this.checkError();
    console.log('webrtcError', error);
  }
  onHls2Ready(ready: boolean) {
    this.hlsReady = ready;
    if (ready) {
      this.ready = true;
      this.error = false;
    }
  }
  onHlsError(error: boolean) {
    this.hlsError = error;
    console.log('hls2Error', error);
    this.checkError();
  }
  checkError() {
    if ((this.webrtcError && this.hlsError) || (this.webrtcError && !this.hasHls)) {
      this.ready = false;
      this.error = true;
      this.loaderStateText = "Error connecting to your camera. Please try again later."
    }
  }
  play() {
    if(this.webRTC) {
      this.webRTC.play();
    }
    if(this.hls) {
      this.hls.play();
    }
  }
  pause() {
    if(this.webRTC) {
      this.webRTC.pause();
    }
    if(this.hls) {
      this.hls.pause();
    }
  }
  reset() {
    this.hlsReady = false;
    this.webrtcReady = false;
    this.hlsError = false;
    this.webrtcError = false;
    this.ready = false;
    this.showWebrtc = false;
    this.showHls2 = true;
    if(this.webRTC) {
      this.webRTC.reset();
    }
    if(this.hls) {
      this.hls.reset();
    }
  }
  clear() {
    if(this.webRTC) {
      this.webRTC.clear();
    }
    if(this.hls) {
      this.hls.clear();
    }
  }
  setup() {
    this.hlsReady = false;
    this.webrtcReady = false;
    this.hlsError = false;
    this.webrtcError = false;
    this.ready = false;
    this.showWebrtc = false;
    this.showHls2 = true;    
    if(this.webRTC) {
      this.webRTC.setup();
    }
    if(this.hls) {
      this.hls.setup();
    }
  }
}
