import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import SitePackage, { PackageFeature } from "../../../site-package/models/site-package";
import Feature from "../../../site-package/models/feature";

@Component({
  selector: "app-site-package-create-form",
  templateUrl: "./site-package-create-form.component.html",
  styleUrls: ["./site-package-create-form.component.css"],
})
export class SitePackageCreateFormComponent implements OnInit, OnDestroy {
  @Output() public formValueChange: EventEmitter<SitePackage> = new EventEmitter<SitePackage>();

  @Input() public allFeatures: Feature[] = [];

  public sitePackageCreateForm: UntypedFormGroup;
  public availableFeatures: Feature[] = new Array<Feature>();
  public assignedFeatures: Feature[] = new Array<Feature>();

  private destroy$ = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder) {}

  public ngOnInit() {
    this.loadForm();
    this.sitePackageCreateForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((sitePackage: SitePackage) => {
      if (!sitePackage.features) {
        sitePackage.features = new Array();
      }

      this.formValueChange.emit({
        ...sitePackage,
        name: sitePackage.name,
        features: [...sitePackage.features],
      });
    });

    this.updateFeatureAssignment();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public unassignFeature(featureToUnassign: Feature) {
    const indexOfFeatureToRemove = this.assignedFeatures.findIndex((feature) => {
      return featureToUnassign.id == feature.id;
    });

    this.assignedFeatures.splice(indexOfFeatureToRemove, 1);
    this.availableFeatures.push(featureToUnassign);
    this.patchFormFeatures();
  }

  public assignFeature(featureToAssign: Feature) {
    const indexOfFeatureToRemove = this.availableFeatures.findIndex((feature) => {
      return featureToAssign.id == feature.id;
    });

    this.availableFeatures.splice(indexOfFeatureToRemove, 1);
    this.assignedFeatures.push(featureToAssign);
    this.patchFormFeatures();
  }

  private loadForm() {
    this.sitePackageCreateForm = this.formBuilder.group({
      name: ["", Validators.required],
      features: [],
    });
  }

  private updateFeatureAssignment() {
    this.availableFeatures = [...this.allFeatures];

    this.patchFormFeatures();
  }

  private patchFormFeatures() {
    this.sitePackageCreateForm.patchValue({
      features: [...this.mapFeatures(this.assignedFeatures)],
    });
  }

  private mapFeatures(features: Feature[]): PackageFeature[] {
    return features.map((feature) => {
      return <PackageFeature>{
        name: feature.name,
        featureID: feature.id,
      };
    });
  }
}
