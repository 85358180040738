
import {filter} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { Map } from './map';
import { MarkerEventService } from '../markers/marker-event.service';

@Component({
    selector: 'app-map-container',
    templateUrl: './map-container.component.html',
    styleUrls: ['./map-container.component.css'],
})
export class MapContainerComponent {
    @Input('maps') set setMaps(value) {
        this.maps = value;
        if(value) {
            //this.selected = value[0];
            this.selectByMapID(this.selectMapID);
        }
    };
    @Input('selectMapID') set setSelectMapID(value: number) {
        this.selectMapID = value;
        this.selectByMapID(value);
    }
    @Input('starMaps') set SetStarMaps(value: number[]) {
        this.starMaps = value;
    };

    @Output('map') map = new EventEmitter();
    @Output('mapClick') mapClicked = new EventEmitter
    public maps: Map[];
    public selected: Map = new Map({});
    public starMaps: number[];
    public eventSub;
    public selectMapID: number;
    constructor(public markerEventService: MarkerEventService) {}

    ngOnInit() {
        this.eventSub = this.markerEventService.getEvents().pipe(filter(event => event.type === 'map')).subscribe(event => this.selectByMapID(event.payload));
    }
    ngOnDestroy() {
        if(this.eventSub) {
            this.eventSub.unsubscribe();
        }
    }
    select(map) {
        this.selected = map;
        this.map.emit(map);
    }
    selectByMapID(id: number) {
        let found = this.maps.filter(map => map.id === id);
        if(found.length < 1) {
            if(this.maps && this.maps.length > 0) {
                this.select(this.maps[0]);
            }
        } else {
            this.select(found[0])
        }
    }
    mapClick(event) {
        event.preventDefault();
        this.mapClicked.emit(event)
    }

}