// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layer {
    /* float: left; */
    height: 100%;
    width: 100%;
}

/* .map {
    height: auto;
    width: 100%;
} */

.clickable {
    cursor: pointer;
}

.img-fluid { 
    display: block;
}

/* .tooltip {
    z-index: 100;
} */

img, canvas {
-webkit-user-select: none;
-o-user-select: none;
user-select: none;
}

`, "",{"version":3,"sources":["webpack://./src/app/map/map.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,WAAW;AACf;;AAEA;;;GAGG;;AAEH;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;;GAEG;;AAEH;AACA,yBAAyB;AAGzB,oBAAoB;AACpB,iBAAiB;AACjB","sourcesContent":[".layer {\r\n    /* float: left; */\r\n    height: 100%;\r\n    width: 100%;\r\n}\r\n\r\n/* .map {\r\n    height: auto;\r\n    width: 100%;\r\n} */\r\n\r\n.clickable {\r\n    cursor: pointer;\r\n}\r\n\r\n.img-fluid { \r\n    display: block;\r\n}\r\n\r\n/* .tooltip {\r\n    z-index: 100;\r\n} */\r\n\r\nimg, canvas {\r\n-webkit-user-select: none;\r\n-khtml-user-select: none;\r\n-moz-user-select: none;\r\n-o-user-select: none;\r\nuser-select: none;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
