import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SiteListService } from "../../sites/site-list.service";
import { DocumentFile } from "../../document/document-file";
import { DocumentService } from "../../document/document.service";
import { Lookup } from "../../lookup";
import { TimeObj } from "../../time-obj";
import { NotificationsService } from "../../../app/notifications/notifications.service";
import { Site } from "app/site/site";
import { DocTypeService } from "./doc-type/doc-type.service";
import { PermissionsService } from "app/permissions.service";
import { convertFromUTCDate } from "app/shared/utc-converter";

@Component({
  selector: "app-admin-documents",
  templateUrl: "./admin-documents.component.html",
  styleUrls: ["./admin-documents.component.css"]
})
export class AdminDocumentsComponent implements OnInit {
  public sites: Observable<{ text: string; id: number }[]>;
  public site: Site;
  public siteID: number;
  public siteName: string;
  public newSites = [];
  public docList: DocumentFile[] = [];
  public doc: DocumentFile;
  public docInfo: DocumentFile;
  public siteDocTypes: Lookup[] = [];
  public docTypes: Lookup[] = [];
  public showExpired: boolean = false;
  public missingExtension: boolean = false;
  public selectedTab: "docs" | "docTypes" = "docs";
  public documentMap = {};
  public typeCountMap = {};
  public uploadInfo: any;
  public invalidFiles: any = [];

  public files: any = [];
  public types: any = [];

  public fileExtensions = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'bmp', 'BMP', 'pdf', 'PDF'];
  public fileExtensionsString = this.fileExtensionsToString(this.fileExtensions);
  public fileExtensionsMessage = "You can only upload files of type: jpg, png, bmp, or pdf."

  constructor(
    private siteListService: SiteListService,
    private docService: DocumentService,
    private docTypeService: DocTypeService,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit() {
    this.sites = this.siteListService.getSiteList().pipe(
      map(sites =>
        sites.map(site => {
          return {
            text: site.siteName,
            id: site.siteID,
            managementCompanyName: site.managementCompanyName
          };
        })
      )
    );
  }

  getDocs(siteID: number) {
    this.docService.fetchForSiteAdmin(siteID).subscribe(res => {
      this.docList = res.map(o => Object.assign(o, {startDisplayDate: convertFromUTCDate(o.startDisplayDate), endDisplayDate: convertFromUTCDate(o.endDisplayDate)}))
      this.getDocTypes();
    });
  }

  getDocTypes() {
    this.docTypeService.getTypesAdmin().subscribe(res => {      
      this.docTypes = res;
      this.types = res.sort((a, b) => (a.name > b.name ? 1 : -1));
    });
  }

  selectSite(site) {
    if (site) {
      this.site = site;
      this.siteID = site.id;
      this.siteName = site.text;
      this.getDocs(this.siteID);
    }
  }

  clearSite() {
    this.siteName = "";
    this.siteID = undefined;
  }

  saveHandler(settings) {
    let startObj = new TimeObj(settings.start);
    let endObj = new TimeObj(settings.end);
    let start =
      this.zeroPad(startObj.year) +
      "-" +
      this.zeroPad(startObj.month + 1) +
      "-" +
      this.zeroPad(startObj.day);
    let end =
      this.zeroPad(endObj.year) +
      "-" +
      this.zeroPad(endObj.month + 1) +
      "-" +
      this.zeroPad(endObj.day);

    let currentTime = new Date();
    let saveTimeObj = new TimeObj(currentTime);
    let timeStr =
      this.zeroPad(saveTimeObj.year) +
      this.zeroPad(saveTimeObj.month + 1) +
      this.zeroPad(saveTimeObj.day) +
      this.zeroPad(saveTimeObj.hours) +
      this.zeroPad(saveTimeObj.minutes) +
      this.zeroPad(saveTimeObj.seconds);

    if (this.invalidFiles.length > 0) {
      this.notificationsService.error("", "Error uploading file");
      return;
    }
    let file = this.files[0].name.split(".");

    let fileExt = file[1];
    let fileName =
      settings.type.toString() +
      "-" +
      this.siteID.toString() +
      "-" +
      timeStr +
      "." +
      fileExt;

    let typeName = this.docTypes.find(x => x.id === settings.type).name
    let formData: FormData = new FormData();
    formData.append("file", this.files[0], fileName);
    formData.append("type", settings.type);
    formData.append("typeName", typeName);
    formData.append("displayName", settings.displayName);
    formData.append("start", start);
    formData.append("end", end);
    formData.append("message", settings.message);

    this.docService
      .upload(this.siteID, settings.type, formData)
      .subscribe(res => {
        this.notificationsService.success("", "File uploaded");
        this.getDocs(this.siteID);
      }, (error) => {
        this.notificationsService.error("", "Error uploading file")
      });

    this.uploadInfo = undefined;
  }

  zeroPad(n: number) {
    if (n > 9) {
      return n.toString();
    } else {
      return "0" + n.toString();
    }
  }

  cancelHandler() {
    this.uploadInfo = undefined;
  }

  editCancelHandler() {
    this.docInfo = undefined;
  }

  editSaveHandler(settings) {
    let startObj = new TimeObj(settings.start);
    let endObj = new TimeObj(settings.end);

    let start =
      this.zeroPad(startObj.year) +
      "-" +
      this.zeroPad(startObj.month + 1) +
      "-" +
      this.zeroPad(startObj.day);

    let end =
      this.zeroPad(endObj.year) +
      "-" +
      this.zeroPad(endObj.month + 1) +
      "-" +
      this.zeroPad(endObj.day);

    let formData: FormData = new FormData();
    formData.append("displayName", settings.displayName);
    formData.append("start", start);
    formData.append("end", end);

    this.docService
      .update(this.siteID, this.docInfo.typeID, this.docInfo.name, formData)
      .subscribe(res => {
        this.getDocs(this.siteID);
      });

    this.docInfo = undefined;
  }

  onFileExplorerChange(event) {
    let fileList = event.target.files;
    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        let ext = fileList[i].name.split('.')[fileList[i].name.split('.').length - 1]
        if (this.fileExtensions.lastIndexOf(ext) === -1) {
          this.notificationsService.error("", this.fileExtensionsMessage);
          return;
        }
      }
      this.filesChange(fileList);
    }
  }

  filesChange(files) {
    this.files = [];
    this.files.push.apply(this.files, files);
    this.uploadInfo = {
      site: this.siteID
    };
  }

  filesInvalid(files) {
    if(files.length > 0) {
      this.files = [];
      this.uploadInfo = undefined;
      this.notificationsService.error("", this.fileExtensionsMessage);
    }
  }

  docDeleted(file: DocumentFile) {
    this.getDocs(this.siteID);
  }

  docEdit(file: DocumentFile) {
    this.docInfo = file;
  }
  fileExtensionsToString(fileExtensions: string[]): string {
    return fileExtensions.map(s => s.toLowerCase().trim()).map(s => "." + s).filter((value, index, self) => self.indexOf(value) === index).join(", ");
  }
}
