import { ManagementCompany } from "../management-company";
import { LeadSource } from "../lead-source";
import { InternalEmail } from "../internal-email";
import { RelatedSite } from "../related-site";

export const NewLeadKey = "newLead";

export interface State {
    showNewLeadForm: boolean,
    managementCompanies: ManagementCompany[],
    leadSources: LeadSource[],
    internalEmails: InternalEmail[],
    relatedSites: RelatedSite[],
    submitInProgress: boolean,
}

export const initialState: State = {
    showNewLeadForm: false,
    managementCompanies: [],
    leadSources: [],
    internalEmails: [],
    relatedSites: [],
    submitInProgress: false,
}