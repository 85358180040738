// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-player {
    max-width: 100%;
    display: block;
    pointer-events: auto;
    background-color: black;
    max-height: calc(90vh - 100px);
}

.video-player::after {
    padding-top: 56.25%;
    display: block;
    content: "";
}
`, "",{"version":3,"sources":["webpack://./src/app/camera/live-view/hls-player/hls-player.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,oBAAoB;IACpB,uBAAuB;IACvB,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,WAAW;AACf","sourcesContent":[".video-player {\r\n    max-width: 100%;\r\n    display: block;\r\n    pointer-events: auto;\r\n    background-color: black;\r\n    max-height: calc(90vh - 100px);\r\n}\r\n\r\n.video-player::after {\r\n    padding-top: 56.25%;\r\n    display: block;\r\n    content: \"\";\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
