
import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {filter, map, first} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../users/user.service';
import { SiteService } from '../site/site.service';
import { PermissionsService } from '../permissions.service';

@Injectable()
export class DocumentGuard  {

    constructor(
        public userService: UserService, 
        public router: Router, 
        public permissions: PermissionsService, 
        public siteService: SiteService
    ) {}

    canActivate() {
        return observableCombineLatest(this.siteService.getSite().pipe(filter(site => site !== null)), this.userService.getUser().pipe(filter(user => !user.initial))).pipe(map(args => {
            let site = args[0];
            let user = args[1];
            if(!this.permissions.anyDocumentForSite(site.siteID, user.permissions)) {
                this.router.navigate(['dashboard'])
                return false;
            }

            return true;
        }), first())
    }
}
