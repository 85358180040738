import { props, createAction } from "@ngrx/store";
import { PortfolioEvent } from "../portfolio-event";
import { State } from '@progress/kendo-data-query';
import { Lookup } from "../../lookup";
import { EventTypeMetric } from "../event-type-metric";
import { EventDayHourMetric } from "../event-day-hour-metric";



export const noAction = createAction("[Event] No Action");

export const getPortfolioEvents = createAction("[Event] Get Portfolio Events");

export const getPortfolioEventsSuccess = createAction("[Event] Get Portfolio Events Success", props<{events: PortfolioEvent}>());

export const getPortfolioEventsFailure = createAction("[Event] Get Portfolio Events Failure");

export const setEventGridState = createAction("[Event] Set Event Grid State", props<{gridState: State}>());

export const getSiteList = createAction("[Event] Get Site List");

export const getCfTagCategories = createAction("[Event] Get CF Tag Categories");

export const getStatusCodes = createAction("[Event] Get Status Codes");

export const getSiteListSuccess = createAction("[Event] Get Site List Success", props<{sites: Lookup[]}>());

export const getCfTagCategoriesSuccess = createAction("[Event] Get CF Tag Categories Success", props<{categories: Lookup[]}>());

export const getStatusCodesSuccess = createAction("[Event] Get Status Codes Success", props<{statusCodes: Lookup[]}>());

export const getSiteListFailure = createAction("[Event] Get Site List Failure");

export const getCfTagCategoriesFailure = createAction("[Event] Get CF Tag Categories Failure");

export const getStatusCodesFailure = createAction("[Event] Get Status Codes Failure");

export const setSiteFilter = createAction("[Event] Set Site Filter", props<{siteFilter: Lookup[]}>());

export const setCfTagCategoryFilter = createAction("[Event] Set CF Tag Category Filter", props<{cfTagCategoryFilter: Lookup[]}>());

export const setStartDateFilter = createAction("[Event] Set Start Date Filter", props<{startDate: Date}>());

export const setEndDateFilter = createAction("[Event] Set End Date Filter", props<{endDate: Date}>());

export const setStatusFilter = createAction("[Event] Set Status Filter", props<{statusFilter: Lookup[]}>());

export const setTextFilter = createAction("[Event] Set Text Filter", props<{textFilter: string}>());

export const clearFilters = createAction("[Event] Clear Filters");

export const searchEvents = createAction("[Event] Search Events");

export const getEventTypeMetrics = createAction("[Event] Get Event Type Metrics");

export const getEventTypeMetricsSuccess = createAction("[Event] Get Event Type Metrics Success", props<{metrics: EventTypeMetric}>());

export const getEventTypeMetricsFailure = createAction("[Event] Get Event Type Metrics Failure");

export const toggleEventMetrics = createAction("[Event] Show Event Metrics");

export const getEventTimeDayHourMetrics = createAction("[Event] Get Event Time Day Hour");

export const getEventTimeDayHourSuccess = createAction("[Event] Get Event Time Day Hour Success", props<{metrics: EventDayHourMetric[]}>());

export const getEventTimeDayHourFailure = createAction("[Event] Get Event Time Day Hour Failure");

export const setSelectedBubble = createAction("[Event] Set Selected Bubble", props<{bubble: EventDayHourMetric}>());