import { Component, OnInit, OnDestroy } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { Observable } from 'rxjs';
import * as adminLPRActions from '../store/actions';
import {
  selectAlertHitsLoading,
  selectAlertHitsGridState,
  selectAlertHitsGridView,
  selectShowLprImageModal,
} from '../store/selectors';
import { AlertHit } from '../../../lpr/recent-hits/alert-hit';

@Component({
  selector: 'app-alert-hits',
  templateUrl: './alert-hits.component.html',
  styleUrls: ['./alert-hits.component.css']
})
export class AlertHitsComponent implements OnInit, OnDestroy {
  public alertHitsGridState$: Observable<State>;
  public alertHitsLoading$: Observable<boolean>;
  public alertHitsView$: Observable<GridDataResult>;
  public showLprImageModal$: Observable<boolean>;

  public isAdmin: boolean = true;
  public alertHit: AlertHit;
  
  constructor(private store: Store<AppState>) { }

  ngOnInit() {

    this.store.dispatch(adminLPRActions.loadAlertHits());
    this.alertHitsGridState$ = this.store.pipe(select(selectAlertHitsGridState));
    this.alertHitsLoading$ = this.store.pipe(select(selectAlertHitsLoading));
    this.alertHitsView$ = this.store.pipe(select(selectAlertHitsGridView));
    this.showLprImageModal$ = this.store.pipe(select(selectShowLprImageModal));
  }

  ngOnDestroy() {
    this.store.dispatch(adminLPRActions.setShowLprImageModal({show: false}));
  }

  onAlertHitsStateChange(state: DataStateChangeEvent) {
    this.store.dispatch(adminLPRActions.setAlertHitsGridState({ gridState: state }));
  }

  showLprImageModal(hit: AlertHit) {
    this.store.dispatch(adminLPRActions.setShowLprImageModal({show: true}));
    this.alertHit = hit;
  }

  hideLprImageModal() {
    this.store.dispatch(adminLPRActions.setShowLprImageModal({show: false}));
  }

}
