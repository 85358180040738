import { Lookup } from "app/lookup";
import { UserInfo } from "../user-info";
import { State as GridState } from '@progress/kendo-data-query';
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { TransactionStatus } from "../admin-perm-status-modal/transaction-status";
import { UserSearch } from "app/admin/admin-user-selector/user-search";
import { PermissionTemplate } from "app/admin/admin-permission-templates/permission-template";
import { SitePermissions } from 'app/permissions';


export const adminPermissionsKey = "adminPermissions"

export interface State {
    manComps: Lookup[],
    manCompsLoading: boolean,
    searchForm: UserSearch,
    searching: boolean,
    users: UserInfo[],
    usersGridState: GridState,
    selectedUsersKeys: number[],
    selectAllCheckboxState: SelectAllCheckboxState,
    action: string,
    newPermissions: {site: any, document: any},
    siteClude: "exclude" | "include",
    selectedSites: number[],
    submitting: boolean,
    transactionID: number,
    showStatusModal: boolean,
    
    // Permission Templates
    permissionTemplates: PermissionTemplate[],
    permissionTemplatesLoading: boolean,
    permissionTemplatesGridState: GridState,
    showDeletePermissionTemplateModal: boolean,
    templateHasUsers: boolean,
    permissionTemplateSelectedForDelete: PermissionTemplate,
    showPermissionTemplateForm: boolean,
    permissionTemplateFormMode: string,
    permissionTemplateSelectedForEdit: PermissionTemplate,
    templateName: string,
    templateNameDup: boolean,
    templateDescription: string,
    templatePermissions: SitePermissions,
    templateSaving: boolean,
}

export const initialState: State = {
    manComps: [],
    manCompsLoading: false,
    searchForm: {
        manComp: [],
        siteGroup: [],
        site: [],
        hardType: null,
        type: [],
        permissionTemplate: [],
        email: '',
    },
    searching: false,
    users: [],
    usersGridState: {skip: 0, take: 50},
    selectedUsersKeys: [],
    selectAllCheckboxState: "unchecked",
    action: "set",
    newPermissions: {site: {}, document: {}},
    siteClude: "exclude",
    selectedSites: [],
    submitting: false,
    transactionID: 0,
    showStatusModal: false,

    // Permission Templates
    permissionTemplates: [],
    permissionTemplatesLoading: false,
    permissionTemplatesGridState: {},
    showDeletePermissionTemplateModal: false,
    templateHasUsers: false,
    permissionTemplateSelectedForDelete: null,
    showPermissionTemplateForm: false,
    permissionTemplateFormMode: '',
    permissionTemplateSelectedForEdit: {id: 0, name: "", description: "", internal: false, admin: false, permissions: {}},
    templateName: "",
    templateNameDup: false,
    templateDescription: "",
    templatePermissions: {},
    templateSaving: false,
}
