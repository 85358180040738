import { NgModule } from "@angular/core";
import * as fromLPRState from './store/state';
import { lprReducer } from "./store/reducers";
import { LPREffects } from "./store/effects";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromLPRState.LPRKey, lprReducer),
        EffectsModule.forFeature([LPREffects]),
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [
        LPREffects,
    ],
})

export class LPRModule { }