import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State } from "./state";
import * as EventActions from './actions';
import { EventSort } from "../event-sort";


const reducer = createReducer(
    initialState,
    on(EventActions.getPortfolioEvents, (state: State) => {
        return {
            ...state,
            eventGridLoading: true,
 
        }
    }),

    on(EventActions.getPortfolioEventsSuccess, (state: State, {events}) => { 
        return {
            ...state,
            portfolioEvents: events,
            eventItems: [...events.items],
            eventGridLoading: false,
        }
    }),

    on(EventActions.getPortfolioEventsFailure, (state: State) => {
        return {
            ...state,
            eventGridLoading: false,
        }
    }),

    on(EventActions.setEventGridState, (state: State, {gridState}) => {
        let eventSort: EventSort = {field: "", dir: ""};
        if (gridState.sort.length > 0){
            eventSort = { dir: gridState.sort[0].dir, field: gridState.sort[0].field };
        }

        return {
            ...state,
            eventGridState: gridState,
            eventSort: eventSort,
        }
    }),
    on(EventActions.getSiteListSuccess, (state: State, {sites}) => {
        return {
            ...state,
            siteList: sites,
        }
    }),
    on(EventActions.getCfTagCategoriesSuccess, (state: State, {categories}) => {
        return {
            ...state,
            cfTagCategories: categories,
        }
    }),
    on(EventActions.setSiteFilter, (state: State, {siteFilter}) => {
        return {
            ...state,
            siteFilter: siteFilter,
        }
    }),
    on(EventActions.setCfTagCategoryFilter, (state: State, {cfTagCategoryFilter}) => {
        return {
            ...state,
            cfTagCategoryFilter: cfTagCategoryFilter,
        }
    }),
    on(EventActions.setStartDateFilter, (state: State, {startDate}) => {
        return {
            ...state,
            startDateFilter: startDate,
        }
    }),
    on(EventActions.setEndDateFilter, (state: State, {endDate}) => {
        return {
            ...state,
            endDateFilter: endDate,
        }
    }),
    on(EventActions.getStatusCodesSuccess, (state: State, {statusCodes}) => {
        statusCodes = statusCodes.filter(s => s.name !== "Deleted");
        return {
            ...state,
            statusCodes: statusCodes,
        }
    }),
    on(EventActions.setStatusFilter, (state: State, {statusFilter}) => {
        return {
            ...state,
            statusFilter: statusFilter,
        }
    }),
    on(EventActions.clearFilters, (state: State) => {
        return {
            ...state,
            siteFilter: [],
            cfTagCategoryFilter: [],
            startDateFilter: null,
            endDateFilter: null,
            statusFilter: [],
            textFilter: "",
            selectedBubble: null,
        }
    }),
    on(EventActions.setTextFilter, (state: State, {textFilter}) => {
        return {
            ...state,
            textFilter: textFilter,
        }
    }),
    on(EventActions.searchEvents, (state: State) => {
        let gridState = {...state.eventGridState};
        gridState.skip = 0;
        return {
            ...state,
            eventGridState: gridState,
        }
    }),
    on(EventActions.getEventTypeMetrics, (state: State) => {
        return {
            ...state,
            eventTypeMetricsLoading: true,
        }
    }),
    on(EventActions.getEventTypeMetricsSuccess, (state: State, {metrics}) => {
        return {
            ...state,
            eventTypeMetrics: metrics,
            eventTypeMetricsLoading: false,
        }
    }),
    on(EventActions.getEventTypeMetricsFailure, (state: State) => {
        return {
            ...state,
            eventTypeMetricsLoading: false,
        }
    }),
    on(EventActions.toggleEventMetrics, (state: State) => {
        return {
            ...state,
            showEventMetrics: !state.showEventMetrics,
        }
    }),
    on(EventActions.getEventTimeDayHourMetrics, (state: State) => {
        return {
            ...state,
            eventTimeDayHourLoading: true,
        }
    }),
    on(EventActions.getEventTimeDayHourSuccess, (state: State, {metrics}) => {
        return {
            ...state,
            eventTimeDayHour: metrics,
            eventTimeDayHourLoading: false,
        }
    }),
    on(EventActions.setSelectedBubble, (state: State, { bubble }) => {
        let gs = {...state.eventGridState};
            gs.skip = 0;
            gs.take = 50;
        return {
            ...state,
            eventGridState: gs,
            portfolioEvents: null,
            eventItems: [],
            selectedBubble: bubble,
        }
    }),
    on(
        EventActions.getPortfolioEvents,
        EventActions.searchEvents,
        EventActions.clearFilters,
        EventActions.setSiteFilter,
        EventActions.setCfTagCategoryFilter,
        EventActions.setStartDateFilter, 
        EventActions.setEndDateFilter,
        EventActions.setStatusFilter,
        EventActions.setTextFilter, (state: State) => {
            let gs = {...state.eventGridState};
            gs.skip = 0;
            gs.take = 50;  
        return {
            ...state,
            eventGridState: gs,
            portfolioEvents: null,
            eventItems: [],
            selectedBubble: null,            
        }
    }),
    
);

export function eventReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}