import { TimeObj } from "../../time-obj";

export class MotionDateTime {
    motionStartDate: TimeObj;
    motionEndDate: TimeObj;
    get hours(): number {
        if(!(this.motionStartDate && this.motionEndDate)) {
            return 0;
        }
        return this.motionStartDate.hoursDifference(this.motionEndDate);
    }
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config);
        }
    }
}