import { NgModule } from '@angular/core';
import * as fromCVSState from './store/state';
import { cvsReducer } from "./store/reducers";
import { CVSEffects } from "./store/effects";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCVSState.CVSKey, cvsReducer),
    EffectsModule.forFeature([CVSEffects]),
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    CVSEffects,
  ],
})
export class CvsModule { }
