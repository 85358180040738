import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SortData } from '../sort-data';
import { AlertEntry } from '../../lpr/manage-alert-entries/alert-entry';

@Component({
  selector: 'app-recent-hits',
  templateUrl: './recent-hits.component.html',
  styleUrls: ['./recent-hits.component.scss','../lpr-table-styles.scss']
})
export class RecentHitsComponent implements OnInit {
  @Input('hits') set setHits(value: AlertEntry[]) {
    this.entriesWithRecentHits = value.filter(entry => entry.hits !== 0);
    console.log("hits: ", this.entriesWithRecentHits)
  }
  @Input("sort") sort: SortData;

  @Output("columnClick") columnClickEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output("recentAlertClick") selectedAlertEmitter: EventEmitter<AlertEntry> = new EventEmitter<AlertEntry>();

  public entriesWithRecentHits: AlertEntry[];
  
  constructor() { }

  ngOnInit() {
  }
  onColumnClick(field: string): void {
    this.columnClickEmitter.emit(field);
  }
  onClickRecentAlert(entry: AlertEntry): void {
    console.log("In onClickRecentAlert: ", entry)
    this.selectedAlertEmitter.emit(entry);
  }

}
