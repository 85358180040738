import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Observable,Subject } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { MessageService } from "./message.service";
import { Message } from "./message";
import { ModalComponent } from "../shared/modal/modal.component";
import { UserService } from "app/users/user.service";
import {selectUserMessages} from "./store/selectors";
import * as messagesActions from "./store/actions";

@Component({
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.css"],
})
export class MessagesComponent implements OnInit, OnDestroy {
  public messages: Message[];
  public sortType: string = "new";
  public message: Message = new Message(null);
  public showMessage: boolean = false;
  public userMessages$: Observable<Message[]>;
  public alive : boolean = true;

  @ViewChild("modal", { static: true }) modal: ModalComponent;

  private reversed: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    public messageService: MessageService,
    public userService: UserService,
    private store: Store<AppState>, 
    ) {}

  public ngOnInit() {
    this.userMessages$ = this.store.pipe(select(selectUserMessages));
      this.userMessages$.pipe(takeWhile(() => this.alive)).subscribe(userMessages => {
      this.messages = this.sortMessages(userMessages, this.sortType, this.reversed)
    });
  
  }

  public ngOnDestroy() {
     this.alive = false;
  }

  public sortChange(newSort: string) {
    if (newSort === this.sortType) {
      this.reversed = !this.reversed;
    }

    this.sortType = newSort;
    this.messages = this.sortMessages(this.messages, this.sortType, this.reversed);
  }

  public show(message: Message) {
    this.messageService.markAsRead(message.id).subscribe((m) => {
      this.userService.fetchUser().subscribe();
    });

    message.seen = true;
    this.message = message;
    this.store.dispatch(messagesActions.updateUserMessage({userMessage: this.message}));
    this.showMessage = true;

        this.modal.showModal();
  }

  private sortMessages(messages: Message[], type: string, reverse: boolean): Message[] {
    let mult = 1;
    if (reverse) {
      mult = -1;
    }

    switch (type) {
      case "new":
        return messages.sort((a, b) => {
          let res = 1;
          if (b.seen && !a.seen) {
            res = -1;
          }
          if ((!b.seen && !a.seen) || (b.seen && a.seen)) {
            res = 0;
          }
          return res * mult;
        });
      case "subject":
        return messages.sort((a, b) => {
          if (a.subject.toLocaleLowerCase() > b.subject.toLocaleLowerCase()) {
            return 1 * mult;
          }
          if (b.subject.toLocaleLowerCase() > a.subject.toLocaleLowerCase()) {
            return -1 * mult;
          }
          return 0;
        });
      case "site":
        return messages.sort((a, b) => {
          if (a.site && b.site) {
            if (a.site.displayName.toLocaleLowerCase() > b.site.displayName.toLocaleLowerCase()) {
              return 1 * mult;
            }
            if (a.site.displayName.toLocaleLowerCase() < b.site.displayName.toLocaleLowerCase()) {
              return -1 * mult;
            }
            return 0;
          }
          if (a.site) {
            return -1 * mult;
          }
          if (b.site) {
            return 1 * mult;
          }
          return 0;
        });
      case "sent":
        return messages.sort((a, b) => {
          return (a.createdDate.getTime() - b.createdDate.getTime()) * mult;
        });
      default:
        return messages;
    }
  }
}
