import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PermissionTemplate } from '../permission-template';

@Component({
  selector: 'app-permission-template-delete',
  templateUrl: './permission-template-delete.component.html',
  styleUrls: ['./permission-template-delete.component.css']
})
export class PermissionTemplateDeleteComponent implements OnInit {

  @Input("active") set activeSetter(value: boolean) {
    this.active = value;
  }

  @Input("permissionTemplate") set permissionTemplateSetter(value: PermissionTemplate) {
      this.permissionTemplate = value;
  }

  @Input("hasUsers") set hasUsersSetter(value: boolean) {
    this.templateHasUsers = value;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();

  @Output() save: EventEmitter<PermissionTemplate> = new EventEmitter();

  public active: boolean = false;
  public disableSubmitButton: boolean = true;
  public permissionTemplate: PermissionTemplate;
  public templateHasUsers: boolean = false;


  constructor() { }

  ngOnInit() {
  }

  onCancel(event: Event): void {
    this.closeModal();
  }

  closeModal(): void {
    this.cancel.emit();
  }

  onSubmit() {
    this.save.emit(this.permissionTemplate);
  }

}
