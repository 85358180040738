import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromSiteManagementState from './store/state';
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { siteManagementReducer } from "./store/reducers";
import { siteManagementEffects } from "./store/effects"


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSiteManagementState.adminSiteManagementKey, siteManagementReducer),
    EffectsModule.forFeature([siteManagementEffects]),
  ]
})
export class SiteManagementModule { }
