import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationsService } from '../notifications/notifications.service';
import { UserService } from '../users/user.service';
import { User } from '../users/user';
import { AnalyticsService } from 'app/analytics.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit, OnDestroy {
  public error: string = ""
  public user: Observable<User>;

  private destroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private notifications: NotificationsService,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    this.user = this.userService.getUser();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onUpdate(user: User) {
    this.userService.updateCurrentUser(user).pipe(
      takeUntil(this.destroy$),
    ).subscribe(()=>{
      this.error = "";
      this.notifications.success("", "Information Updated");

      this.analyticsService.track("User Updated", {
        userID: user.id,
        updatedBy: user.id,
      });
    }, error => this.error = error);
  }
}
