import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import * as fromAIState from "./store/state";
import { aiReducer } from "./store/reducers";
import { AIEffects } from "./store/effects";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromAIState.aiKey, aiReducer),
        EffectsModule.forFeature([AIEffects]),
    ]
})
export class AIModule { }
