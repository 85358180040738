import * as fromCameraState from './state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CameraGridSize } from "../camera-grid-size";
import { GridSize } from 'app/cvs/gridsize';
import { filter } from 'rxjs/operators';


export const selectCamera = createFeatureSelector<fromCameraState.State>(fromCameraState.CameraKey);

export const portfolioCameras = createSelector(
    selectCamera,
    (camera => camera.portfolioCameras),
)

export const cameraStreams = createSelector(
    selectCamera,
    (camera => camera.portfolioCameraStreams),
)

export const portfolioIsLive = createSelector(
    selectCamera,
    (camera => camera.portfolioIsLive),
)

export const portfolioCameraStreams = createSelector(
    selectCamera,
    (camera => camera.portfolioCameraStreams),
)

export const streamsLoaded = createSelector( // possibly derive from portfolioCameraStreams instead
    selectCamera,
    (camera => camera.streamsLoaded),
)

export const siteFilter = createSelector(
    selectCamera,
    (camera => camera.siteFilter),
)

export const intentFilter = createSelector(
    selectCamera,
    (camera => camera.intentFilter),
)

export const nameFilter = createSelector(
    selectCamera,
    (camera => camera.nameFilter),
)

export const selectedSortOption = createSelector(
    selectCamera,
    (camera => camera.selectedSortOption),
)

export const selectedGroupByOption = createSelector(
    selectCamera,
    (camera => camera.selectedGroupByOption),
)

export const cameraFooterCollapse = createSelector(
    selectCamera,
    (camera => camera.cameraFooterCollapse)
)

export const appBarWidth = createSelector(
    selectCamera,
    (camera => camera.appBarWidth),
)

export const cameraGridSize = createSelector(
    selectCamera, 
    appBarWidth,
    ((camera, barWidth) =>  { 
        let cameraGridSize = camera.cameraGridSize;
        if(!cameraGridSize.large)
            {
                return cameraGridSize;
            }
        if(barWidth < 960){
            cameraGridSize = { small: false, medium: true, large: false };
            return cameraGridSize;
        }
        else {
            return camera.cameraGridSize;
        }
    })
)
export const allCameraSizes = createSelector(
    selectCamera,
    (camera => camera.cameraGridSize),
)

export const cameraSitesFiltered = createSelector(
    portfolioCameras,
    siteFilter,
    ((cameras, filter) => {

        if (filter.length === 0) {
            return cameras;
        }

        let filteredCameras = [...cameras];
              

        filteredCameras = filteredCameras.filter(cam => {
            return filter.some(f => {
                return f === cam.siteID;
            });
        });

        return filteredCameras;
    })
)

export const cameraIntentsFiltered = createSelector(
    cameraSitesFiltered,
    intentFilter,
    ((cameras, filter) => {

        if (filter.length === 0) {
            return cameras;
        }

        let filteredCameras = [...cameras];

        filteredCameras = filteredCameras.filter(cam => {
            return filter.some(f => {
                return cam.intents.includes(f);
            });
        });

        return filteredCameras;
    })
)

export const cameraNameFiltered = createSelector(
    cameraIntentsFiltered,
    nameFilter,
    ((cameras, filter) => {

        if (filter.length === 0) {
            return cameras;
        }

        let filteredCameras = [...cameras];

        if (filter !== '') {
            filteredCameras = filteredCameras.filter(cam => {
                return cam.displayName.toLowerCase().includes(filter.toLowerCase());
            });
        }

        return filteredCameras;
    })
)

export const camerasSorted = createSelector(
    cameraNameFiltered,
    selectedSortOption,
    ((cameras, sortOption) => {

        let sortedCameras = [...cameras];

        switch (sortOption) {
            case "Site (Asc)":
                sortedCameras = sortedCameras.sort((a, b) => {
                    return a.siteName.localeCompare(b.siteName);
                });
                break;
            case "Site (Desc)":
                sortedCameras = sortedCameras.sort((a, b) => {
                    return b.siteName.localeCompare(a.siteName);
                });
                break;
            case "Camera # (Asc)":
                 sortedCameras = sortedCameras.sort((a, b) => a.siteName.localeCompare(b.siteName) || a.cameraNumber - b.cameraNumber);
                break;
            case "Camera # (Desc)":
                sortedCameras = sortedCameras.sort((a, b) => a.siteName.localeCompare(b.siteName) || b.cameraNumber - a.cameraNumber);
                break;
            default:
                break;                
        }

        return sortedCameras;

    })
)

export const filteredPortfolioCameras = createSelector(
    camerasSorted, 
    (camera => camera),
)

export const cameraIntents = createSelector(
    selectCamera,
    (camera => camera.cameraIntents),
)

export const sortOptions = createSelector(
    selectCamera,
    (camera => camera.sortOptions),
)

export const groupByOptions = createSelector(
    selectCamera,
    (camera => camera.groupByOptions),
)

export const viewportWidth = createSelector(
    selectCamera,
    (camera => camera.viewportWidth),
)

export const cameraFilters = createSelector(
    siteFilter, intentFilter, nameFilter, selectedSortOption, selectedGroupByOption, cameraGridSize,
    ((siteFilter, intentFilter, nameFilter, selectedSortOption, selectedGroupByOption, cameraGridSize) => {
        return {
            siteFilter,
            intentFilter,
            nameFilter,
            selectedSortOption,
            selectedGroupByOption,
            cameraGridSize
        }
    }),
)

export const camerasPerRow = createSelector(
    viewportWidth, cameraGridSize, 
    ((width, cameraGridSize) => {

        if( cameraGridSize.small ) {
        if (width < 576) {
            return 2;
        } else if (width < 768) {
            return 3;
        } else if (width < 992) {
            return 4;
        } else {
            return 6;
        }

    }
    else if( cameraGridSize.medium ) {
        
        if (width < 768 ) {
            return 1;
        } else if (width < 992) {
            return 2;
        } else {
            return 3;
        }
    }
    else  {
        if (width < 992) {
            return 1;
        }
        else {
            return 2;
        }
    }
    }),
)

export const cameraHeight = createSelector(
    cameraGridSize, cameraFooterCollapse,
    (( gridSize, camfooterCollapse ) => 
        { 
            let cameraHeight = 0;
            if( gridSize.small ) {
                cameraHeight = 205;
            }
            else if( gridSize.medium ) {
                cameraHeight = 305;
            }
            else {
                cameraHeight = 455;
            }

            if (camfooterCollapse) {
                cameraHeight -= 33;
            }

            return cameraHeight;
        }),

)

export const formattedPortfolioCameras = createSelector(
    filteredPortfolioCameras,
    camerasPerRow,
    (cameras, perRow) => {
        let formattedCameras = [];
        let row = [];
        let count = 0;

        cameras.forEach(cam => {
            row.push(cam);
            count++;
            if (count === perRow) {
                formattedCameras.push(row);
                row = [];
                count = 0;
            }
        });

        if (row.length > 0) {
            formattedCameras.push(row);
        }

        return formattedCameras;
    }
)

export const scrollUpThreshold = createSelector(
    cameraGridSize,
    (gridSize => {   
        if(gridSize.small){
            return 5;
        }
        else if(gridSize.medium){
            return 4;
        }
        else{
            return 3;
        }
    })
)

export const cvsStreams = createSelector(
    filteredPortfolioCameras,
    cameraStreams,
    ((cams, streams) => {
        return cams.map(cam => streams[cam.id])
    }),
)

export const portfolioSiteWalkStatus = createSelector(
    selectCamera,
    (camera => camera.portfolioSiteWalkStatus),
)

export const theme = createSelector(
    selectCamera,
    (camera => camera.theme),
)
export const loadingCameras = createSelector(
    selectCamera,
    portfolioCameras,
    ((camera,portFolioCameras) => {
        if (portFolioCameras.length > 0) {
            return false;
        }        
        return camera.loadingCameras;        
    })
)