import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromSiteGroupsState from './state';
import { process } from '@progress/kendo-data-query';

export const selectSiteGroups = createFeatureSelector<fromSiteGroupsState.State>(fromSiteGroupsState.siteGroupsKey);

export const selectSiteGroupsGridState = createSelector(
    selectSiteGroups,
    (siteGroups => siteGroups.siteGroupsGridState),
);

export const selectSiteGroupsLoading = createSelector(
    selectSiteGroups,
    (siteGroups => siteGroups.siteGroupsLoading),
);

export const selectSiteGroupsGridData = createSelector(
    selectSiteGroups,
    (siteGroups => siteGroups.siteGroups),
);

export const selectSiteGroupsGridView = createSelector(
    selectSiteGroupsGridData,
    selectSiteGroupsGridState,
    ((data, state) => process(data, state)),
);

export const selectShowCreateSiteGroupModal = createSelector(
    selectSiteGroups,
    (siteGroups => siteGroups.showCreateSiteGroupModal),
);

export const selectEditGroup = createSelector(
    selectSiteGroups,
    (siteGroups => siteGroups.editGroup),
);

export const selectEditSelectedSites = createSelector(
    selectSiteGroups,
    (siteGroups => siteGroups.editSelectedSites),
);

export const selectEditSites = createSelector(
    selectSiteGroups,
    (siteGroups => siteGroups.editSites),
);


export const selectEditSiteFilter = createSelector(
    selectSiteGroups,
    (siteGroups => {
        return siteGroups.editSiteFilter;
    })
);

export const selectEditUnselectedSites = createSelector(
    selectEditSites,
    selectEditSelectedSites,
    selectEditSiteFilter,
    ((sites, selectedSites, filter) => {
        return sites.filter(site => selectedSites.map(s => s.id).indexOf(site.id) === -1).filter(site => site.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
    })
);

export const selectEditName = createSelector(
    selectSiteGroups,
    (siteGroups => siteGroups.editName),
);

export const selectEditSaving = createSelector(
    selectSiteGroups,
    (siteGroups => siteGroups.editSaving),
);

export const selectEditDuplicateName = createSelector(
    selectSiteGroups,
    (siteGroups => {
        if(!siteGroups.editGroup) {
            return false;
        }
        for(let group of siteGroups.siteGroups) {
            if(group.id !== siteGroups.editGroup.id && siteGroups.editName.toLowerCase().trim() === group.name.toLowerCase().trim()) {
                return true;
            }
        }
        return false;
    })
)
export const selectShowDeleteSiteGroupModal = createSelector(
    selectSiteGroups,
    (siteGroups => siteGroups.showDeleteSiteGroupModal),
);

export const selectSiteGroupSelectedForDelete = createSelector(
    selectSiteGroups,
    (siteGroups => siteGroups.siteGroupSelectedForDelete),
);

