import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetricsService } from '../metrics.service';
import { SiteService } from '../../site/site.service';
import { ChartComponent } from '@progress/kendo-angular-charts';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { SET_LOGINS_DATE_RANGE } from '../metrics.reducer';
import { Observable } from 'rxjs';
import { DateRange } from '../date-range';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-logins',
  templateUrl: './logins.component.html',
  styleUrls: ['./logins.component.css']
})
export class LoginsComponent implements OnInit {
 
  public siteID: number;
  public loginsMajorUnit: number = 1;
  public lineData: any;
  public lineDataKeys: any;
  public hoursRequested: any = 0;
  public totalLogins: any = 0;
  public numberOfRequests: any = 0;
  public businessDaysRequest: any = 0;
  public loginInfoClientData: any = [];
  public loginInfoPoliceData: any = [];
  public dateRange: DateRange = {start: null, end: null};
  public loginsDateRange: Observable<DateRange>;

  constructor(
    public metricsService: MetricsService,
    public route: ActivatedRoute,
    private siteService: SiteService,
    public store: Store<AppState>
  ) { }

  ngOnInit() {
  
    this.route.params.subscribe(params => {
      this.siteID = +params['siteID'];
      })
  
      this.loginsDateRange = this.store.select(s => {
        return s.loginsDateRange;
      })
  
      this.loginsDateRange.pipe(take(1)).subscribe(res => {
        this.dateRange = res;
      });

      if (this.dateRange.start && this.dateRange.end) {
        this.getTouchesMetrics();
     } else {
      let dateOffset = (24*60*60*1000) * 365;
      let startDate = new Date();
      startDate.setTime(startDate.getTime()- dateOffset); 
      this.dateRange.end = new Date();
      this.dateRange.start = startDate;
      this.store.dispatch({ type: SET_LOGINS_DATE_RANGE, payload: this.dateRange });
      this.getTouchesMetrics();
      }

    }

  getTouchesMetrics() {
  
    this.metricsService.getLoginsOverTime(this.siteID)
    .subscribe(res => {
      this.loginsMajorUnit = 1
      let temp: any = res
      this.lineData = {}
      temp.forEach(e => {
        if (this.lineData[e.year]) {
          this.lineData[e.year][e.month - 1] += e.amount
        } else {
          this.lineData[e.year] = [0,0,0,0,0,0,0,0,0,0,0,0]
          this.lineData[e.year][e.month - 1] += e.amount
        }
      })
      //console.log(this.lineData)
      this.lineDataKeys = Object.keys(this.lineData)

      this.lineDataKeys.forEach(k => {
        this.lineData[k].forEach(m => {
          if (m > 7) {
            this.loginsMajorUnit = null
          }
        })
      })
    });

    this.metricsService.getLoginInfo(this.siteID, this.dateRange.start, this.dateRange.end)
    .subscribe(res => {
      let temp: any = res;
      this.hoursRequested = 0;
      this.totalLogins = 0;
      this.numberOfRequests = 0;
      this.businessDaysRequest = 0;
      this.loginInfoClientData = [];
      this.loginInfoPoliceData = [];

      temp.forEach(e => {
        e.name = e.firstName + ' ' + e.lastName
        //console.log(this.hoursRequested)
        this.hoursRequested += e.hoursRequested
        this.totalLogins += e.logins
        this.numberOfRequests += e.requests

        if (e.typeHard === 2) {
          this.loginInfoPoliceData.push(e)
        }
        if (e.typeHard === 3) {
          this.loginInfoClientData.push(e)
        }
      })

    });

  }
  
  startDateChange(start) {
    this.dateRange.start = start;
    if (this.dateRange.start != null && this.dateRange.end != null) {
      this.store.dispatch({ type: SET_LOGINS_DATE_RANGE, payload: this.dateRange })
    }
  }

  endDateChange(end) {
    this.dateRange.end = end;
    if (this.dateRange.start != null && this.dateRange.end != null) {
      this.store.dispatch({ type: SET_LOGINS_DATE_RANGE, payload: this.dateRange })
    }
  }


}
