import { Component } from '@angular/core';

@Component({
  selector: 'app-portfolio-event-detail',
  templateUrl: './portfolio-event-detail.component.html',
  styleUrls: ['./portfolio-event-detail.component.css']
})
export class PortfolioEventDetailComponent {

}
