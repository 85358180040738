import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpContextToken } from "@angular/common/http";

export const BYPASS_NOCACHE = new HttpContextToken(() => false);

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (req.context.get(BYPASS_NOCACHE)) {
            return next.handle(req);
        }

        let headers = req.headers.set("If-Modified-Since", "Mon, 26 Jul 1997 05:00:00 GMT");
        headers = headers.set("Cache-Control", "no-cache");
        headers = headers.set("Pragma", "no-cache");
        const cReq = req.clone({
            headers: headers,
        });
        return next.handle(cReq);
    }
}