// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k-appbar .k-appbar-separator {
    margin: 0 8px;
    padding-bottom: 8px;
}

.k-appbar-separator {
    height: 24px;
}

.size-option .k-button:first-child {
    font-size: 11px;
    background-color: #E4E7EB;
    border-radius: 5px 0 0 5px;
    box-shadow: none;
}

.size-option .k-button {
    background-color: white;
    color: black;
    border-style: none;
}

.k-appbar-section > * {
    margin: 0px 6px 0px 6px;
}

.size-option .k-button:hover,
.size-option .k-button:focus,
.size-option .k-button .k-state-selected,
.size-option .k-selected
{
    background-color: #66b2b2;
    color: white;
    border: none;
}

.k-button-group > .k-button + .k-button {
    margin-inline-start: 1px;
}

`, "",{"version":3,"sources":["webpack://./src/app/camera/camera-filter/camera-filter.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;;;;;IAKI,yBAAyB;IACzB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".k-appbar .k-appbar-separator {\r\n    margin: 0 8px;\r\n    padding-bottom: 8px;\r\n}\r\n\r\n.k-appbar-separator {\r\n    height: 24px;\r\n}\r\n\r\n.size-option .k-button:first-child {\r\n    font-size: 11px;\r\n    background-color: #E4E7EB;\r\n    border-radius: 5px 0 0 5px;\r\n    box-shadow: none;\r\n}\r\n\r\n.size-option .k-button {\r\n    background-color: white;\r\n    color: black;\r\n    border-style: none;\r\n}\r\n\r\n.k-appbar-section > * {\r\n    margin: 0px 6px 0px 6px;\r\n}\r\n\r\n.size-option .k-button:hover,\r\n.size-option .k-button:focus,\r\n.size-option .k-button .k-state-selected,\r\n.size-option .k-selected\r\n{\r\n    background-color: #66b2b2;\r\n    color: white;\r\n    border: none;\r\n}\r\n\r\n.k-button-group > .k-button + .k-button {\r\n    margin-inline-start: 1px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
