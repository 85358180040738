export default class Feature {
  id: number;
  name: string;
}

export function generateMockFeature(): Feature {
  return {
    id: 1,
    name: "name",
  };
}
