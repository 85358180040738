import { Lookup } from "app/lookup";


export class UserInfo {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    hardType: Lookup;
    softType: Lookup;
    sites: Lookup[];
    siteGroups: Lookup[];
    permissionTemplate: Lookup;
    lastBulkModifiedDate: Date;
    createdDate: Date;
    constructor(config?: any) {
        if(config) {
            Object.assign(this, config)
            if(config.lastBulkModifiedDate) {
                let checkDate = new Date(config.lastBulkModifiedDate) 
                if (checkDate.getFullYear() < 2000) {
                    this.lastBulkModifiedDate = null;
                } else {
                this.lastBulkModifiedDate = new Date(config.lastBulkModifiedDate);
                }
            }
            if(config.createdDate) {
                this.createdDate = new Date(config.createdDate);
            }
        }
    }
}