import { createAction, props } from "@ngrx/store";
import { State } from '@progress/kendo-data-query';
import { AlertEntry } from '../../../lpr/manage-alert-entries/alert-entry';
import { AlertHit } from "../../../lpr/recent-hits/alert-hit";

export const noAction = createAction("[AdminLPR] No Action");

export const setSelectedEntriesHitsTab = createAction("[AdminLPR] Set Selected Entries Hits Tab ", props<{tab: string}>());

// Alert Entries Tab
export const loadAlertEntries = createAction("[AdminLPR] Load Alert Entries");

export const setAlertEntries = createAction("[AdminLPR] Set Alert Entries", props<{alertEntries: AlertEntry[]}>());

export const setAlertEntriesLoading = createAction("[AdminLPR] Set Alert Entries Loading", props<{loading: boolean}>());

export const setAlertEntriesGridState = createAction("[AdminLPR] Set Alert Entries Grid State", props<{gridState: State}>());

export const setSelectedAlertEntry = createAction("[AdminLPR] Set Selected Alert Entry", props<{entry: number[]}>());

export const loadAlertEntryHits = createAction("[AdminLPR] Load Alert Entry Hits");

export const setAlertEntryHits = createAction("[AdminLPR] Set Alert Entry Hits", props<{alertEntryHits: AlertHit[]}>());

export const setAlertEntryHitsLoading = createAction("[AdminLPR] Set Alert Entry Hits Loading", props<{loading: boolean}>());

export const setAlertEntryHitsGridState = createAction("[AdminLPR] Set Alert Entry Hits Grid State", props<{gridState: State}>());

export const setShowLprImageModal = createAction("[AdminLPR] Set Show Lpr Image Modal", props<{show: boolean}>());

// Alert Hits Tab
export const loadAlertHits = createAction("[AdminLPR] Load Alert Hits");

export const setAlertHits = createAction("[AdminLPR] Set Alert Hits", props<{alertHits: AlertHit[]}>());

export const setAlertHitsLoading = createAction("[AdminLPR] Set Alert Hits Loading", props<{loading: boolean}>());

export const setAlertHitsGridState = createAction("[AdminLPR] Set Alert Hits Grid State", props<{gridState: State}>());


export const setShowRequestModal = createAction("[AdminLPR] Set Show Request Modal", props<{show: boolean}>());

export const setShowAddCamsModal = createAction("[AdminLPR] Set Show Add Cams Modal", props<{show: boolean}>());
