import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from './auth.reducer';
import { of, EMPTY, interval } from 'rxjs';
import { catchError, map, mergeMap, tap, filter, withLatestFrom, take, switchMap } from 'rxjs/operators';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { AuthService } from '../auth.service';
import * as AuthActions from './auth.actions';
import { AuthFacade } from '../auth.facade';

@Injectable()
export class AuthEffects {
  listenForAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap(() =>
        this.authService.authenticated$().pipe(
          take(1),
          map((authResult) => { 
            if (authResult) {              
              this.store.dispatch(AuthActions.createSession({ authResult }));
              return AuthActions.loginSuccess({ authResult });
            } else { 
              return AuthActions.loginFailure({ error: null });
            }
          }),
          catchError((error) => of(AuthActions.loginFailure({ error })))
        )
      )
    );
  });

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {
          this.authService.logout();
        })
      );
    },
    { dispatch: false }
  );

  checkTokenExpiration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.checkTokenExpiration),
      withLatestFrom(
        this.authFacade.isAuthenticated$,
        this.authService.authenticated$()
      ),
      filter(([_, isAuthenticated]) => isAuthenticated),
      mergeMap(() => {
        if (this.authService.isTokenExpired()) {
          return of(AuthActions.refreshToken());
        }

        return EMPTY;
      })
    );
  });

  checkTokenExpirationOnNavigate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map(() => AuthActions.checkTokenExpiration())
    );
  });

  // checkTokenExpirationPeriodically$ = createEffect(() =>
  //   interval(60000).pipe(map(() => AuthActions.checkTokenExpiration()))
  // );
  
  refreshTokenInterval$ = createEffect(() =>
    interval(900000).pipe(map(() => AuthActions.refreshToken()))
  );


  refreshToken$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.refreshToken),
        withLatestFrom(this.authFacade.isAuthenticated$),
        filter(([_, isAuthenticated]) => isAuthenticated),
        tap(() => {
          this.authService.refreshAuth();
        })
      );
    },
    { dispatch: false }
  );

  updateAuthCookie$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.updateAuthCookie),
      withLatestFrom(this.authFacade.sessionCreated$),
      filter(([_, sessionCreated]) => sessionCreated),
      mergeMap(([action]) => {
        let accessToken = action.accessToken;
        return this.authService.updateAuthCookie(accessToken).pipe(
          map((user) => {
            return AuthActions.noAction(); 
          }),
          catchError(error => {
            this.store.dispatch(AuthActions.logout()); 
            return of(AuthActions.updateAuthCookieFailure({ error }))
          }))
      })
    );
  });

  createSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.createSession),
      switchMap((action) => {
        let accessToken = action.authResult.accessToken;
        return this.authService.createSession(accessToken).pipe(
          take(1), 
          map((user) => {
            return AuthActions.sessionCreated(); 
          }),
          catchError(error => {
            this.store.dispatch(AuthActions.logout()); 
            return of(AuthActions.createSessionFailure({ error }))
          }))
      })
    );
  });

  sessionNavigate$ = createEffect(() =>
        this.actions$.pipe(
        ofType(AuthActions.sessionCreated),
        tap(() => {
            let routeAtLogin = localStorage.getItem('wts_auth0_signin_route');
            localStorage.removeItem('wts_auth0_signin_route');
            this.router.navigate([routeAtLogin]); 
        })
    ), { dispatch: false }
);

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private authFacade: AuthFacade,
    private store: Store<State>,
    private router: Router
  ) {}
}
