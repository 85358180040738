import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Camera } from '../camera';
import { interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { CameraGridComponent } from '../camera-grid/camera-grid.component';

const updateInterval = 100;

@Component({
  selector: 'app-camera-rotater',
  templateUrl: './camera-rotater.component.html',
  styleUrls: ['./camera-rotater.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraRotaterComponent implements OnInit, OnDestroy {
  @Input("size") set setSize(size: {rows: number, columns: number}) {
    this.rows = size.rows;
    this.columns = size.columns;
    this.setupStartEnd();
    this.setupCameras();
  }
  @Input('cameras') set setCameras(cameras: Camera[]) {
    this.allCameras = cameras;
    this.setupStartEnd();
    this.setupCameras();
  }
  @Input('status') set setStatus(status: "play" | "pause" | "fast") {
    this.status = status;
    if ((status === "play" || status === "fast") && !this.rotater) {
      this.play();
    }
    if (status === "pause") {
      this.pause();
    }
  }
  @Input() public delay = 40000;
  @Output("start") startEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output("end") endEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output("progress") progressEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output("loading") loadingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('grid0') grid0: CameraGridComponent | null = null;
  @ViewChild('grid1') grid1: CameraGridComponent | null = null;

  public allCameras: Camera[] = [];
  public currentCameras: (Camera | null)[] = [];
  public cameras: Camera[][] = [[], []];
  public activeSet = 0;
  public currentIndex = 0;
  private alive = true;
  private previousIndex = 0;
  private status: "play" | "pause" | "fast" = "play";
  public rows = 3;
  public columns = 3;
  public currentProgress = 0;
  private isLoading = false;
   
  private rotater: Subscription | null = null;
  constructor() { }
  ngOnInit(): void {
  }
  ngOnDestroy(): void { 
    this.alive = false;
  }
  rotateCameras() {
    let length = this.columns * this.rows;
    if(this.allCameras.length <= length) {
      return;
    }
    if (this.isLoading) {
      return;
    }
    this.loading(true);
    let start = this.previousIndex;
    this.previousIndex = this.currentIndex;
    this.activeSet = this.activeSet === 0 ? 1 : 0;
    let nextSet = this.activeSet === 0 ? 1 : 0;
    if(nextSet === 0) {
      this.grid0?.clear();
    } else {
      this.grid1?.clear();
    }
    this.cameras[nextSet] = [];
    for(let i = 0; i < length; i++) {
      this.cameras[nextSet].push(this.allCameras[(this.currentIndex + i) % this.allCameras.length]);
    }
    this.currentIndex = (this.currentIndex + length) % this.allCameras.length;
    let end = this.previousIndex - 1;
    if (end < 0) {
      end = this.allCameras.length;
    }

    this.startEmitter.emit(start);
    this.endEmitter.emit(end);
    this.loading(false);
  }
  closeNext() {
    if (this.activeSet === 0) {
      this.grid1?.clear();
    } else {
      this.grid0?.clear();
    }
  }
  closeCurrent() {
    if (this.activeSet === 0) {
      this.grid0?.clear();
    } else {
      this.grid1?.clear();
    }
  }
  reverseCameras() {
    let length = this.columns * this.rows;
    if(this.allCameras.length <= length) {
      return;
    }
    this.loading(true);
    let nextSet = this.activeSet === 0 ? 1 : 0;
    this.cameras[nextSet] = [];
    let tempIndex = this.previousIndex - length * 2;
    if (tempIndex < 0) {
      tempIndex = this.allCameras.length + tempIndex;
    }
    for(let i = 0; i < length; i++) {
      this.cameras[nextSet].push(this.allCameras[(tempIndex + i) % this.allCameras.length]);
    }
    this.currentIndex = this.previousIndex;
    this.previousIndex = (tempIndex + length) % this.allCameras.length;
    let start = tempIndex <= this.allCameras.length ? tempIndex : 0;
    let end = this.previousIndex === 0 ? this.allCameras.length - 1 : this.previousIndex - 1;
    this.activeSet = nextSet;
    this.startEmitter.emit(start);
    this.endEmitter.emit(end);
    nextSet = this.activeSet === 0 ? 1 : 0;
    if(nextSet === 0) {
      this.grid0?.clear();
    } else {
      this.grid1?.clear();
    }
    this.cameras[nextSet] = [];
    this.cameras[nextSet] = this.nextCameras(tempIndex, length);
    // if(nextSet === 0) {
    //   this.grid0?.setup();
    // }
    // if(nextSet === 1) {
    //   this.grid1?.setup();
    // }
    this.loading(false);
  }
  nextCameras(index: number, length: number): Camera[] {
    let cameras = [];

    for(let i = 0; i < length; i++) {
      cameras.push(this.allCameras[(index + length + i) % this.allCameras.length]);
    }



    return cameras;
  }
  setupStartEnd() {
    let length = this.columns * this.rows;
    let start = 0;
    let end = this.allCameras.length > length ? length - 1 : this.allCameras.length - 1;
    this.startEmitter.emit(start);
    this.endEmitter.emit(end);
  }
  setupCameras() {
    if(!this.allCameras || !this.rows || !this.columns) {
      return;
    }
    this.loading(true);
    let length = this.columns * this.rows;
    let start = 0;
    let end = this.allCameras.length > length ? length - 1 : this.allCameras.length - 1;
    this.startEmitter.emit(start);
    this.endEmitter.emit(end);
    this.currentIndex = 0;
    this.activeSet = 0;
    this.cameras[0] = [];
    this.cameras[1] = [];
    if (this.allCameras.length <= length) {
      this.cameras[0] = this.fillNulls(this.allCameras, length);
      this.cameras[1] = [];
      this.loading(false);
      return;
    }
    this.cameras[0] = this.allCameras.slice(0, length);
    if(this.allCameras.length > length * 2) {
      this.cameras[1] = this.allCameras.slice(length, length * 2);
      this.currentIndex = length * 2;
      this.previousIndex = length;
      this.loading(false);
      return;
    }
    this.cameras[1] = this.allCameras.slice(length, this.allCameras.length);
    for(let i = 0; i < (length * 2) - this.allCameras.length; i++) {
      this.cameras[1].push(this.allCameras[i]);
    }
    this.currentIndex = this.allCameras.length % (length * 2);
    this.previousIndex = this.allCameras.length > length ? length : this.allCameras.length;
    this.loading(false);
  }
  fillNulls(cameras: Camera[], length: number) {
    let newList = [...cameras];
    for (let i = 0; i < length - cameras.length; i++) {
      newList.push(null);
    }
    return newList;
  }
  next() {
    this.pause();
    this.rotateCameras();
    if (this.status !== "pause") {
      this.play();
    }
  }
  back() {
    this.pause();
    this.reverseCameras();
    if (this.status !== "pause") {
      this.play();
    }
  }
  play() {
    if (this.rotater) {
      this.rotater.unsubscribe();
    }
    this.rotater = interval(updateInterval).pipe(takeWhile(() => this.alive)).subscribe((i) => {
      if(i === 0) {
        return;
      }
      this.currentProgress++;
      let delay = this.delay;
      if (this.status === "fast") {
        delay = this.delay / 2;
      }
      let unit = delay / updateInterval;
      let prog = (this.currentProgress % unit) / unit * 100;
      if (this.currentProgress > unit) {
        this.rotateCameras();
        this.currentProgress = 0;
      }
      this.progressEmitter.emit(prog);
    });
  }
  pause() {
    if (this.rotater) {
      this.rotater.unsubscribe();
      this.rotater = null;
    }
  }
  loading(l: boolean) {
    this.isLoading = l;
    this.loadingEmitter.emit(l);
  }
}
