
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {take} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AppState } from './app.state';
import { Logger } from './logger/logger.service';
import { User } from './users/user';
import { config } from './app.config';
import { SET_USER, USER_LOADED } from './users/user.reducer';
import { NotificationsService } from './notifications/notifications.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthFacade } from './auth/auth.facade';


@Injectable()
export class ServiceHelpersService {
    constructor(
        public logger: Logger,
        public store: Store<AppState>,
        public http: HttpClient,
        public router: Router,
        public notifications: NotificationsService,
        public authFacade: AuthFacade
        ) {}
    // public extractData = (res: Response) => {

    //     if (res.status < 200 || res.status >= 300) {
    //       throw new Error('Bad response status: ' + res.status);
    //     }
    //     let body = res.json();
    //     return body || {};
    // }
    public handleError = (error: any) => {
        let currentUser: User;
        this.store.select(s => s.user).pipe(take(1)).subscribe(user => currentUser = user);
        switch(error.status) {
            case 401: //Unauthorized - no session need to relog
                // window.location.href = this.router.url + "?expired=true";
                this.authFacade.logout();
                break;
            case 403: //Forbidden - getting a resource they shouldn't log an error
                this.logger.error("Forbidden: " + error.url + " UserID: " + currentUser.id);
                window.location.href = "/logout"
//                this.notifications.error("", "You are not permitted to access " + error.url);
                break;
            case 418: //Tea Pot - access keys don't match need to reload user data 
                console.log("test");
                this.http.get<User>(config.apiUrl + 'user/current').subscribe(resp => {
                    let user = new User(resp);
                    if( user.id !== currentUser.id) {
                        window.location.href = "/logout?url=" +  this.router.url + "&expired=true";
                    }
                    user.initial = false;
                    this.store.dispatch({type: SET_USER, payload: user});
                    this.store.dispatch({type: USER_LOADED });
                    
                }, e => window.location.href = "/logout")
        }
        let errMsg = error.error || 'Server error';
        this.logger.error(errMsg);
        return observableThrowError(errMsg);
    }
}
