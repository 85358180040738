import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Logger } from '../logger/logger.service';
import { Marker } from './marker';
import { EventMarker } from '../events/event-marker';
import { CameraMarker } from '../camera/camera-marker';
import { RequestCameraMarker } from '../requests/new-request/cameras/request-camera-marker';
import { MarkerEventService } from './marker-event.service';
import { NodeMarker } from '../node-marker/node-marker';
import { ServiceRequestCameraMarker } from '../service-request/service-request-camera-marker';
import { DrawingMarker } from './drawing-marker';
import { MarkerData } from './marker-data';
import { Map } from 'app/map/map';
import { PurpleCameraMarker } from 'app/events/event-detail/purple-camera-marker';
import { AddCamsMarker } from 'app/camera/rewind/add-cams/add-cams-marker';
import { MotionCameraMarker } from 'app/motion/motion-selector/motion-camera-marker';
import { RawRequestCameraMarker } from 'app/raw-request/raw-request-camera-marker';

@Injectable()
export class MarkerService {
    public headers = {};
    constructor(public logger: Logger, public store: Store<AppState>, public markerEventService: MarkerEventService) {
    }
    public createMarkers(markerData, map) {
        let markers: Marker[] = [];
        markerData.forEach(marker => {
            if(marker.data) {
                switch(marker.type) {
                    case 'heatdot':
                        markers.push(this.createEventMarker(marker, map));
                        break;
                    case 'camera':
                        markers.push(this.createCameraMarker(marker, map));
                        break;
                    case 'map':
                        markers.push(this.createMapMarker(marker, map));
                        break;
                    default:
                        this.logger.error("Invalid Event Type: " + marker.type);
                        break;
                }    
            }
        })
        return markers;
    }
    public createCameraMarker(marker: MarkerData, map: Map) {
        let data = JSON.parse(marker.data);
        let label = null;
        if(marker.label) {
            label = JSON.parse(marker.label);
        }

        return new CameraMarker({
            id: marker.id, 
            shape: marker.shape, 
            data: data, 
            camera: marker.resourceIDToLoad, 
            map: map, 
            store: this.store, 
            displayName: marker.displayName, 
            eventService: this.markerEventService, 
            meta: marker.meta, 
            type: marker.type, 
            label: label,
        })
    }

    public createPurpleCameraMarker(marker: MarkerData, map: Map) {
        let data = JSON.parse(marker.data);
        let label = null;
        if(marker.label) {
            label = JSON.parse(marker.label);
        }

        return new PurpleCameraMarker({
            id: marker.id, 
            shape: marker.shape, 
            data: data, 
            camera: marker.resourceIDToLoad, 
            map: map, 
            store: this.store, 
            displayName: marker.displayName, 
            eventService: this.markerEventService, 
            meta: marker.meta, 
            type: marker.type, 
            label: label,
        })
    }

    public createEventMarker(marker: MarkerData, map: Map) {
        let data = JSON.parse(marker.data);
        let label = null;
        if(marker.label) {
            label = JSON.parse(marker.label);
        }

        return new EventMarker({
            x: data.x, 
            y: data.y, 
            value: 10, 
            store: this.store, 
            event: marker.resourceIDToLoad, 
            map: map, id: marker.id, 
            displayName: marker.resourceIDToLoad + "", 
            shape: marker.shape, 
            data: data, 
            eventService: this.markerEventService, 
            type: marker.type, 
            label: label,
        });
    }

    public createMapMarker(marker: MarkerData, map: Map) {
        let data = JSON.parse(marker.data);
        let label = null;
        if(marker.label) {
            label = JSON.parse(marker.label);
        }                        

        return new NodeMarker({
            id: marker.id, 
            shape: marker.shape, 
            data: data, 
            node: marker.resourceIDToLoad, 
            map: map, store: this.store, 
            displayName: marker.displayName, 
            eventService: this.markerEventService, 
            type: marker.type, 
            label: label,
        })
    }

    public createRequestCameraMarkers(markerData: MarkerData[], map) {
        let markers: Marker[] = [];
        let cameraData = markerData.filter(marker => marker.type === 'camera');
        cameraData.forEach(marker => {
            if(marker.data) {
                let data = JSON.parse(marker.data)
                let label = null;
                if(marker.label) {
                    label = JSON.parse(marker.label);
                }
                markers.push(new RequestCameraMarker({id: marker.id, shape: marker.shape, camera: marker.resourceIDToLoad, map: map, store: this.store, displayName: marker.displayName, eventService: this.markerEventService, data: data, meta: marker.meta, type: marker.type, label: label}));
            }
        })
        let nodeData = markerData.filter(marker => marker.type === 'map');
        nodeData.forEach(marker => {
            let data = JSON.parse(marker.data);
            let label = null;
            if(marker.label) {
                label = JSON.parse(marker.label);
            }
            markers.push(new NodeMarker({id: marker.id, shape: marker.shape, data: data, node: marker.resourceIDToLoad, map: map, store: this.store, displayName: marker.displayName, eventService: this.markerEventService, type: marker.type, label: label}));
        })
        return markers;
    }
    public createServiceRequestCameraMarkers(markerData: MarkerData[], map) {
        let markers: Marker[] = [];
        let cameraData = markerData.filter(marker => marker.type === 'camera');
        cameraData.forEach(marker => {
            if(marker.data) {
                let data = JSON.parse(marker.data)
                let label = null;
                if(marker.label) {
                    label = JSON.parse(marker.label);
                }
                markers.push(new ServiceRequestCameraMarker({id: marker.id, shape: marker.shape, camera: marker.resourceIDToLoad, map: map, store: this.store, displayName: marker.displayName, eventService: this.markerEventService, data: data, meta: marker.meta, type: marker.type, label: label}));
            }
        })
        return markers;
    }
    public createDrawingMarkers(mapData, map): Marker[] {
        if(!mapData || !map) {
            return [];
        }
        let data = mapData.filter(datum => datum.mapID === map.id);
        let markers = []
        data.forEach(datum => {
            markers.push(new DrawingMarker({id: -1, shape: "drawing", displayName: "", data: datum, map: map, store: this.store, eventService: this.markerEventService, type: "drawing", label: null}));
        })
        return markers;
    }
    public createAddCamsMarkers(markers, map) {
        let newMarkers = []
        for (let marker of markers) {
            if (!marker.data) {
                continue;
            }
            let data = JSON.parse(marker.data);
            let label = null;
            if (marker.label) {
                label = JSON.parse(marker.label);
            }
            newMarkers.push(new AddCamsMarker({ id: marker.id, shape: marker.shape, camera: marker.resourceIDToLoad, map: map, store: this.store, displayName: marker.displayName, eventService: this.markerEventService, data: data, meta: marker.meta, type: marker.type, label: label }));
        }
        return newMarkers;
    }
    public createMotionCameraMarkers(markers, map: Map) {
        let newMarkers = []
        for (let marker of markers) {
            if (!marker.data) {
                continue;
            }
            let data = JSON.parse(marker.data);
            let label = null;
            if (marker.label) {
                label = JSON.parse(marker.label);
            }
            newMarkers.push(new MotionCameraMarker({ id: marker.id, shape: marker.shape, camera: marker.resourceIDToLoad, map: map, store: this.store, displayName: marker.displayName, eventService: this.markerEventService, data: data, meta: marker.meta, type: marker.type, label: label }));
        }
        return newMarkers;
    }
    public createRawRequestCameraMarkers(markers, map: Map) {
        let newMarkers = []
        for (let marker of markers) {
            if (!marker.data) {
                continue;
            }
            let data = JSON.parse(marker.data);
            let label = null;
            if (marker.label) {
                label = JSON.parse(marker.label);
            }
            newMarkers.push(new RawRequestCameraMarker({ id: marker.id, shape: marker.shape, camera: marker.resourceIDToLoad, map: map, store: this.store, displayName: marker.displayName, eventService: this.markerEventService, data: data, meta: marker.meta, type: marker.type, label: label }));
        }
        return newMarkers;
    }
}