import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {

  constructor(
    private router: Router,
    private http: HttpClient,
  ) { }
  
  setupInsights() {
    let key = environment.insights;
    var angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({ config: {
      instrumentationKey: key,
      extensions: [angularPlugin],
      extensionConfig: {
        [angularPlugin.identifier]: { router: this.router }
      },
      distributedTracingMode: DistributedTracingModes.W3C,
      enableCorsCorrelation: true,
      correlationHeaderDomains: ["myportal.watchtower-security.io", "wtscloudapi-dev.azurewebsites.net", "cloudapi.watchtower-security.com", "myportal.watchtower-security.com", "staging.watchtower-security.com", "localhost"],
      correlationHeaderExcludedDomains: ["login.watchtower-security.io", "login.watchtower-security.com"],
    }});
    let telinit = (envelope: ITelemetryItem) => {
      // code to exclude certain urls from being tracked
      // const telemetryItem = envelope.baseData;
      // if(telemetryItem && telemetryItem.url) {
      //   const excludedUrls = [
      //     /mtx[0-9]+\.watchtower-security\.com/,
      //   ]
      //   if(excludedUrls.some(url => telemetryItem.url.match(url))) {
      //     return false;
      //   }
      // }
      if(envelope && envelope.tags) {
        envelope.tags["ai.cloud.role"]="sapphirefront"
      }
    }

    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer(telinit);              
}
getInsightsKey(): Observable<string> {
  return this.http.get<{name: string, value: string}>(`/rest/config/appInsights`).pipe(map(r => r.value));
}

}
