import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromMotionState from "./store/state";
import { motionReducer } from './store/reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MotionEffects } from './store/effects';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ExpressRequestModule } from '../express-request/express-request.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromMotionState.MotionKey, motionReducer),
    EffectsModule.forFeature([MotionEffects]),
    LayoutModule,
    ExpressRequestModule,
    ],
  providers: [
    MotionEffects,
  ],
})
export class MotionModule { }
