import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { AppState } from '../../../app.state';
import { Vehicle } from '../where/vehicle/vehicle';
import { What } from './what';
import { SET_REQUEST_WHAT } from '../../requests.reducer';




@Component({
    selector: 'app-request-what',
    templateUrl: './what.component.html',
    styleUrls: ['./what.component.css'],
})
export class WhatComponent implements OnInit, OnDestroy {
    public what = new What();
    public whatSub;
    constructor(public store: Store<AppState>, public router: Router, public route: ActivatedRoute) {}
    ngOnInit() {
        this.what.vehicles = [];
        // this.whatSub = this.store.select(s => s.request.what).subscribe(what => {
        //     this.what = what;
        //     if(!this.what.vehicles) {
        //         this.what.vehicles = [];
        //     }
        // });
    }
    ngOnDestroy() {
        this.whatSub.unsubscribe();
    }
    addVehicle() {
        this.what.vehicles.push(new Vehicle());
    }
    deleteVehicle(index) {
        if(index > -1) {
            this.what.vehicles.splice(index, 1);
            this.update();
        }
    }
    toggleVehicles() {
        if(this.what.vehicleInvolved && this.what.vehicles.length === 0) {
            this.addVehicle();
        }
        this.update();
    }
    updateVehicle(vehicle, change) {
        vehicle = change;
        this.update();
    }
    update() {
        this.store.dispatch({ type: SET_REQUEST_WHAT, payload: this.what });
    }
    next() {
        this.router.navigate(['../', 'where'], {relativeTo: this.route});
    }
    back() {
        this.router.navigate(['../', 'when'],{relativeTo: this.route});
    }
}
