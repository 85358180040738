
import { filter, map, takeUntil } from 'rxjs/operators';
import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { SiteService } from '../site.service';
import { Marker } from '../../markers/marker';
import { Map } from '../../map/map';
import { AppState } from '../../app.state';
import { MarkerService } from '../../markers/marker.service';
import { CLEAR_MOUSEOVER_EVENT } from '../../events/event.reducer';
import { Site } from '../site';
import { NotificationsService } from '../../notifications/notifications.service';
import { EventMap } from '../../event-map/event-map';
import { MarkerEventService } from '../../markers/marker-event.service';
import { EventService } from '../../events/event.service';
import { WtsEvent } from '../../events/event';
import { EventData } from './dashboard-event-display/event-data';
import { Request } from '../../events/request';
import { PermissionsService } from '../../permissions.service';
import { FeatureService } from 'app/site-package/services';
import { FeatureType } from 'app/site-package/models';
import { resetMotion } from 'app/motion/store/actions';
import { Logger } from 'app/logger/logger.service';
import { FeatureFlagService } from 'app/feature-flag/feature-flag.service';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../users/user';
import { UserService } from '../../users/user.service';


@Component({
    selector: 'app-site-dashboard',
    templateUrl: './site-dashboard.component.html',
    styleUrls: ['./site-dashboard.component.css']
})
export class SiteDashboardComponent {
    @ViewChild('mapComponent', { static: true }) mapComponent;
    public user: User;
    public siteLoading;
    public site: Site;
    public map: Map;
    public maps: Map[];
    public verifiedTime: Date;
    public events: Observable<Marker[]>;
    public markers = [];
    public eventData: WtsEvent[] = [];
    public metrics;
    public recentRequests: Request[] = [];
    public statuses = [];
    public hasFullService: boolean;
    public clientRepPhone: string;
    public showExtendedMetrics: boolean = false;
    public showByos$: Observable<boolean> = new BehaviorSubject<boolean>(false);

    private destroy$ = new Subject<void>();

    constructor(
        public logger: Logger,
        public activatedRoute: ActivatedRoute,
        public siteService: SiteService,
        public store: Store<AppState>,
        public markersService: MarkerService,
        public notificationsService: NotificationsService,
        public markerEventService: MarkerEventService,
        public router: Router,
        public eventService: EventService,
        private permissionsService: PermissionsService,
        private featureService: FeatureService,
        private featureFlagService: FeatureFlagService,
        public userService: UserService
    ) { }

    public ngOnInit() {
        this.userService.getUser().pipe(takeUntil(this.destroy$)).subscribe(user => this.user = user);
        this.siteLoading = this.siteService.getSiteLoading();

        this.store.dispatch({ type: CLEAR_MOUSEOVER_EVENT });
        this.events = this.store.select(s => s.mouseOverEvent).pipe(map(events => events.filter(marker => marker.type === 'event'), takeUntil(this.destroy$)));

        this.siteService.getSite().pipe(takeUntil(this.destroy$)).subscribe(site => {
            this.showExtendedMetrics = false;
            this.store.dispatch({ type: CLEAR_MOUSEOVER_EVENT });
            if (site) {
                this.hasFullService = this.featureService.checkFeatureInPackage(FeatureType.FullRequest, site.package);
                if (this.hasFullService) {
                    this.store.dispatch(resetMotion());
                }
                this.maps = [];
                if (site.maps) {
                    site.maps.forEach(map => {
                        let newMap = new EventMap(map);
                        if (map.markers && this.hasFullService) {
                            newMap.markersLocal = this.markersService.createMarkers(map.markers, newMap);
                        } else {
                            newMap.markersLocal = [];
                        }

                        this.maps.push(newMap);
                    });

                    this.map = this.maps[0]
                } else {
                    this.map = new EventMap({})
                }

                this.site = site;

                if (this.site.salesContact && this.site.salesContact.phoneNumber) {
                    let formattedClientRepPhone = this.site.salesContact.phoneNumber;
                    this.clientRepPhone = "1" + formattedClientRepPhone.replace(/\D/g, '');
                }    

                this.eventService.getEventsLight(this.site.siteID).pipe(takeUntil(this.destroy$)).subscribe(events => {
                    this.eventData = events;
                });

                this.siteService.getMetrics(site.siteID).subscribe(metrics => {
                    let extendedMetricsDate = new Date();
                    extendedMetricsDate.setMonth(extendedMetricsDate.getMonth()-12);
                    let firstRequestDate = new Date(metrics.firstRequestDate);
                    if (firstRequestDate.getTime() < extendedMetricsDate.getTime()) {
                        this.showExtendedMetrics = true;
                    }
                    this.metrics = metrics
                });
                this.eventService.getRecentRequests(site.siteID).pipe(takeUntil(this.destroy$)).subscribe(requests => this.recentRequests = requests);

                this.logger.trackAction(2, this.site.siteID).subscribe();
            }
        });

        this.eventService.getStatuses().subscribe(statuses => this.statuses = statuses.filter(status => status.name !== "Deleted"));
        this.markerEventService.getEvents().pipe(filter(event => event.type === 'event'), takeUntil(this.destroy$)).subscribe(event => {
            if (this.permissionsService.site(this.site.siteID, 'canViewTickets')) {
                this.router.navigate(['site', this.site.siteID, "events", event.payload]);
            }
        });

        this.verifiedTime = new Date();
        this.verifiedTime.setMinutes(Math.floor(this.verifiedTime.getMinutes() / 15) * 15)
        this.showByos$ = this.featureFlagService.isFeatureEnabled$('byos').pipe(takeUntil(this.destroy$));
    }

    public ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public getEventData(marker): EventData {
        let ed = new EventData();
        let evList = this.eventData.filter(event => event.id === marker.event)
        if (evList.length < 1) {
            return undefined;
        }

        let ev: any = evList[0];
        ed.description = ev.description;
        ed.displayName = ev.displayName;
        ed.number = ev.id;
        ed.submitted = ev.eventTime;

        return ed;
    }

    gotoExtendedStorage() {
        this.router.navigate(['portfolio', 'extended-storage']);    
      }

}
