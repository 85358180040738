import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SummaryFile, Summary } from '../summary';
import { WtsEvent } from '../event';
import { UserService } from 'app/users/user.service';
import { take } from 'rxjs/operators';
import { User } from 'app/users/user';
import { ShareFilesService } from './share-files.service';
import { FileShare } from './file-share';
import { NotificationsService } from 'app/notifications/notifications.service';
import { UserActivityService } from 'app/user-activity/user-activity.service';
import { Actions } from 'app/user-activity/user-activity';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.css']
})
export class ShareModalComponent implements OnInit {
  @Input('event') event: WtsEvent;
  @Input('summary') summary: Summary;
  @Input('show') show: boolean;


  @Output('close') closeEmitter = new EventEmitter<any>();

  public message: string;
  public emailString: string;
  public checked: {[id: number]: boolean} = {};
  public files: SummaryFile[] = [];
  public emails: string[] = [];
  public valid: boolean = false;
  public emailsValid: boolean = false;
  public emailErrors: string[] = [];
  public user: User;
  public includeSummary: boolean = false;
  constructor(
    private userService: UserService,
    private shareService: ShareFilesService,
    private notificaitonsService: NotificationsService,
    private userActivityService: UserActivityService,
  ) { }

  ngOnInit() {
    this.userService.getUser().pipe(take(1)).subscribe(u => {
      this.message = u.getName() + " has shared these files from a completed Forensic Video Review with you.";
      this.user = u;
    })
  }
  link(file: SummaryFile) {
    return '/rest/file/uploaded/' +file.url + '/' + file.fileName
  }
  emailsChanged() {
    let valid = true;
    let errors = [];
    let emails = [];
    if(this.emailString) {
        emails = this.emailString.trim().split(',');
        for(let i = 0; i < emails.length; i++) {
          emails[i] = emails[i].trim();
          if(!this.validEmail(emails[i]) && emails[i]) {
            valid = false;
            errors.push(emails[i] + " is not a vaild email address.");
          }
          if(emails[i].length > 45) {
            valid = false;
            errors.push(emails[i] + " is too long.")
          }
        }
        this.emails = emails;
    } else {
      this.emails = [];
    }
    this.emailsValid = valid;
    this.emailErrors = errors;
    this.validate();
  }
  close() {
    this.closeEmitter.emit();
  }
  checkChange(file: SummaryFile, index: number) {
    console.log("Checking")
    this.checked[index] = !this.checked[index];
    this.files = this.summary.uploadedFiles.filter((f,i) => this.checked[i]);
    this.validate();
  }
  send() {

    let summaryText: string = "";
    if (this.includeSummary && this.summary && this.summary.text) {
      let t = this.summary.text as {changingThisBreaksApplicationSecurity: string}
      if (t.changingThisBreaksApplicationSecurity) {
        summaryText = t.changingThisBreaksApplicationSecurity;
      } else if(typeof this.summary.text === "string"){
        summaryText = this.summary?.text;
      }
    }

    let userName = this.user.getName() ? this.user.getName() : this.user.userName;
    let info = new FileShare({
      message: this.message,
      eventID: this.event.id,
      siteName: this.event.site.displayName,
      files: this.files,
      userName: userName,
      includeEmails: this.emails,
      includeSummary: this.includeSummary,
      summaryText: summaryText,
    });
    this.shareService.sendShareFileEmail(info, this.event.site.siteID).subscribe(() => {
      this.notificaitonsService.success("","Email Sent");
      this.userActivityService.logActivity({
        eventID: this.event.id,
        siteID: this.event.site.siteID,
        action: Actions.SendInPopup,
        meta: info,
      })
      this.close();
    }, e => {
      this.notificaitonsService.error("", "Error sending email");
    });
  }
  validate() {
    this.valid = this.emails.length > 0 && this.files.length > 0 && this.emailsValid;
  }
  validEmail(email) {
    let expr = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    return expr.test(email);
  }
}
