export class Vehicle {
    make: string;
    model: string;
    color: string;
    year: number;
    licensePlate: string;
    state: string;
}

export function isBlankVehicle(v: Vehicle): boolean{
    return !v.make && !v.model && !v.color && !v.year && !v.licensePlate && !v.state
}