import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Camera } from '../camera';
import { Permissions } from 'app/permissions';
import { FeatureType } from 'app/site-package/models';
import { CameraLite } from '../camera-lite';
import { CameraService } from '../camera.service';
import { HybridPlayerComponent } from '../live-view/hybrid-player/hybrid-player.component';
import { AnalyticsService } from 'app/analytics.service';
import { CameraGridSize } from '../camera-grid-size';

@Component({
    selector: 'app-camera-select',
    templateUrl: './camera-select.component.html',
    styleUrls: ['./camera-select.component.css'],
})
export class CameraSelectComponent {

    @Input('camera') set setCamera(value: CameraLite) {
        if (value) {
            this.camera = value;
            this.imageURL = "/rest/site/" + value.siteID + "/source/1/camera/" + value.id + "/file/" + value.dayReferenceFilename;
        }
    }
    @Input('cameraStream') set setCameraStream(value: Camera | null | undefined) {
        
        if (!value) {
            this.cameraStream = value;
            return;
        }
        this.cameraService.getCameraStreamAuth(value.id, value.siteID).subscribe((auth) => {
            this.cameraStream = value;
            if(this.cameraStream) {
                this.cameraStream.stream.username = auth.username;
                this.cameraStream.stream.password = auth.password;
                this.hasAuth = true;
            }
        });
            
    }
    @Input() isLive: boolean = false;

    @Input('cameraFooterCollapse') set setCameraFooterCollapse(value: boolean) {
            this.cameraFooterCollapse = value;
    }

    @Input() rewindPermissions: boolean;

    @Input('cameraGridSize') set setCameraGridSize(value: CameraGridSize) {
        if (value) {
            this.cameraGridSize = value;
        }
    }
    @Input('stretch') stretch: boolean = false;
    
    @Output() selected: EventEmitter<Event> = new EventEmitter<Event>();

    @Output() rewind: EventEmitter<Event> = new EventEmitter<Event>();

    @Output() motion: EventEmitter<Event> = new EventEmitter<Event>();

    @ViewChild('VideoPlayer', {static: false}) videoPlayer: HybridPlayerComponent;
    @ViewChild('VideoPlayerContainer') videoPlayerContainer: ElementRef<HTMLDivElement>;

    public camera: CameraLite;
    public cameraStream: Camera | null | undefined;
    public imageURL: string = "";
    public hasAuth: boolean = false;
    private intersecObs: IntersectionObserver | null | undefined;
    public cameraFooterCollapse: boolean = false;
    public cameraGridSize: CameraGridSize = {small: true, medium: false, large: false};

    constructor(
        public cameraService: CameraService,
        private analyticsService: AnalyticsService,
    ) { }
    ngAfterViewInit() {
        let options = {
            threshold: .1,
        }      
        this.intersecObs = new IntersectionObserver((e: IntersectionObserverEntry[]) => {
            e.forEach((entry: IntersectionObserverEntry) => {
              if(!this.videoPlayer) {
                return;
              }
              if (entry.isIntersecting) {
                this.videoPlayer.play();
              } else {
                this.videoPlayer.pause();
              }
            });
          }, options);
        if(this.videoPlayer) {
            this.intersecObs.observe(this.videoPlayerContainer.nativeElement);
        }
    }

    ngOnDestroy() {
        this.cameraStream = null;
    }
    public features: FeatureType[] = [
        FeatureType.Motion,
    ]

    public onClick() {
        this.selected.emit();
    }

    public onRewind(camera: CameraLite) {
        this.analyticsService.track("User Selected Rewind", {
            cameraID: camera.id,
            cameraName: camera.displayName,
            siteID: camera.siteID,
            siteName: camera.siteName,
          });
        this.rewind.emit();
    }

    public onMotion(camera: CameraLite) {
        this.analyticsService.track("User Selected Motion", { 
            cameraID: camera.id,
            cameraName: camera.displayName,
            siteID: camera.siteID,
            siteName: camera.siteName,
          }); 
        this.motion.emit();
    }
    reset() {
        if(this.videoPlayer) {          
            this.videoPlayer.reset();
        }
    }
    clear() {
        if(this.videoPlayer) {
            this.videoPlayer.clear();
        }
    }
    setup() {
        if(this.videoPlayer) {
            this.videoPlayer.setup();
        }
    }
    
}