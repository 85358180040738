import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { ADD_MOUSEOVER_EVENT, REMOVE_MOUSEOVER_EVENT, CLEAR_MOUSEOVER_EVENT } from './event.reducer';
import { MarkerShape, MarkerShapeConfig, MarkerHeatDot } from '../markers/marker-shape';
import { Marker, MarkerConfig } from '../markers/marker';
import { MarkerEventService } from '../markers/marker-event.service'
import { Map } from '../map/map';

export class EventMarker extends Marker {
    public value;
    public hmap;
    public _map;
    public on;
    public added = false;
    public store: Store<AppState>;
    public id: number;
    public shape: string;
    public shapeObj: MarkerShape;
    public event: number;
    public type = 'event';
    constructor(config: EventMarkerConfig) {
        super(config)
        this.event = config.event;
    }

    get map() {
        return this._map;
    }

    clicked() {
        this.eventService.sendEvent({marker: this, payload: this.event, type: "event"})
        console.log("Clicked: " + this.x + ", " + this.y +" Value: " + this.shapeObj.data.value);
    }
    enter() {
                this.store.dispatch({type: ADD_MOUSEOVER_EVENT, payload: this });
    }
    leave() {
        this.store.dispatch({type: REMOVE_MOUSEOVER_EVENT, payload: this });
    }
}
export interface EventMarkerConfig extends MarkerConfig {
    x: number;
    y: number;
    value: number; 
    map: Map;
    event: number;
}
