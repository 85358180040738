// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    max-width: 300px;
}
.err {
    color: red;
}
.fileView {
    max-width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/app/events/share-modal/share-modal.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,UAAU;AACd;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".logo {\r\n    max-width: 300px;\r\n}\r\n.err {\r\n    color: red;\r\n}\r\n.fileView {\r\n    max-width: 100px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
