import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DocumentService } from "../../document/document.service";
import { config } from "../../app.config";
import { PermissionsService } from "../../permissions.service";
import { DocumentType } from "app/document/document-type";
import { DocumentFile } from "app/document/document-file";
import { eyeIcon, SVGIcon, pencilIcon, trashIcon } from "@progress/kendo-svg-icons";

@Component({
  selector: "app-document-list",
  templateUrl: "./document-list.component.html",
  styleUrls: ["./document-list.component.css"]
})
export class DocumentListComponent implements OnInit {
  @Input("siteID") set setSiteID(value: number) {
    this.showExpired = false;
    this.siteID = value;
  }

  @Input("docTypes") set setDocTypes(value: DocumentType[]) {
    console.log(value);
    this.docTypes = value;
    this.docTypesForSite();
  }
  
  @Input() admin: boolean;

  @Input("docList") set setFileList(value: DocumentFile[]) {
    if (value.length > 0) {
      this.hasDocuments = true;
    }

    this.docListWithExpired = value;
    this.docList = this.docListWithExpired.filter(
      (f: DocumentFile) => new Date(f.endDisplayDate) > this.currentDate
    );

    this.docTypesForSite();
  }

  @Output() docDeleted = new EventEmitter<DocumentFile>();

  @Output() docEdit = new EventEmitter<DocumentFile>();

  public docTypes: DocumentType[] = [];
  public siteID: number;
  public siteDocTypes: DocumentType[] = [];
  public documentMap = {};
  public typeCountMap = {};
  public showExpired: boolean = false;
  public docList: DocumentFile[] = [];
  public docListWithExpired: DocumentFile[] = [];
  public hasDocuments: boolean = false;
  public currentDate = new Date();
  public hasDocAdmin: boolean;
  public expanded: boolean;

  public eyeSVG:  SVGIcon = eyeIcon;
  public pencilSVG: SVGIcon = pencilIcon;
  public trashSVG: SVGIcon = trashIcon;

  constructor(
    private docService: DocumentService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.hasDocAdmin = this.permissionsService.admin("document");

    this.docTypesForSite();
  }

  docTypesForSite() {
    this.siteDocTypes = this.docTypes
    this.siteDocTypes.sort((a, b) => (a.name > b.name ? 1 : -1));

    for (let i = 0; i < this.siteDocTypes.length; i++) {
      let categoryItems = this.getDocsByType(this.siteDocTypes[i].id);
      if (categoryItems.length > 0) {
        this.documentMap[this.siteDocTypes[i].id] = categoryItems;
        this.typeCountMap[this.siteDocTypes[i].id] = categoryItems.length;
      } else {
        this.documentMap[this.siteDocTypes[i].id] = [];
        this.typeCountMap[this.siteDocTypes[i].id] = 0;
      }
    }
  }

  getDocsByType(typeID: number) {
    let filteredFiles = this.docList.filter(item => {
      return item.typeID == typeID;
    });

    return filteredFiles;
  }

  download(file: DocumentFile) {
    let url = config.uploadUrl + "rest/file/site/" + file.siteID + "/document/" + file.uuid + "/" + file.name;
    window.open(url, "_blank");
  }

  removeDoc(file: DocumentFile) {
    this.docService
      .remove(file.siteID, file.typeID, file.name)
      .subscribe(res => {
        this.docDeleted.emit(file);
      });
  }

  editDoc(file: DocumentFile, event: Event) {
    event.stopPropagation();
    this.docEdit.emit(file);
  }

  toggleShowExpired() {
    this.showExpired = this.showExpired ? false : true;

    if (this.showExpired === true) {
      this.docList = this.docListWithExpired;
      this.docTypesForSite();
    } else {
      this.docList = this.docListWithExpired.filter(
        f => new Date(f.endDisplayDate) > this.currentDate
      );

      this.docTypesForSite();
    }
  }
}
