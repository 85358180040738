// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-assignment {
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: stretch;
  border: 1px solid black;
  min-height: 300px;
  height: 100%;
  gap: 10px;
}

.separator {
  padding-top: 5px;
}

.feature-section-label {
  padding: 5px;
  font-size: 14px;
  display: block;
  padding: 5px;
  font-weight: bold;
}

.features-available {
  order: 1;
  flex: 1 1 100%;
  border-right: 1px solid black;
  padding: 5px;
}

.features-assigned {
  order: 2;
  flex: 1 1 100%;
  padding: 5px;
}

.feature-chip {
  border-radius: 16px;
  padding: 7px 12px;
  display: inline-flex;
  height: 1px;
  min-height: 32px;
  align-items: center;
  background-color: #8edae6;
  font-size: 12px;
  margin: 2px;
}

.edit-form-textbox {
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-site-package/site-package-edit-form/site-package-edit-form.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,qBAAqB;EACrB,oBAAoB;EACpB,uBAAuB;EACvB,iBAAiB;EACjB,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,cAAc;EACd,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,QAAQ;EACR,cAAc;EACd,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,QAAQ;EACR,cAAc;EACd,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;EACpB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".feature-assignment {\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  flex-flow: row nowrap;\r\n  align-items: stretch;\r\n  border: 1px solid black;\r\n  min-height: 300px;\r\n  height: 100%;\r\n  gap: 10px;\r\n}\r\n\r\n.separator {\r\n  padding-top: 5px;\r\n}\r\n\r\n.feature-section-label {\r\n  padding: 5px;\r\n  font-size: 14px;\r\n  display: block;\r\n  padding: 5px;\r\n  font-weight: bold;\r\n}\r\n\r\n.features-available {\r\n  order: 1;\r\n  flex: 1 1 100%;\r\n  border-right: 1px solid black;\r\n  padding: 5px;\r\n}\r\n\r\n.features-assigned {\r\n  order: 2;\r\n  flex: 1 1 100%;\r\n  padding: 5px;\r\n}\r\n\r\n.feature-chip {\r\n  border-radius: 16px;\r\n  padding: 7px 12px;\r\n  display: inline-flex;\r\n  height: 1px;\r\n  min-height: 32px;\r\n  align-items: center;\r\n  background-color: #8edae6;\r\n  font-size: 12px;\r\n  margin: 2px;\r\n}\r\n\r\n.edit-form-textbox {\r\n  font-size: 14px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
