import { ChangeDetectionStrategy, Component, Input, ViewChildren } from '@angular/core';
import { TileLayoutGap, TileLayoutReorderEvent, TileLayoutResizeEvent } from '@progress/kendo-angular-layout';
import { Camera } from '../camera';
import { CameraSelectComponent } from '../camera-select/camera-select.component';

@Component({
  selector: 'app-camera-grid',
  templateUrl: './camera-grid.component.html',
  styleUrls: ['./camera-grid.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraGridComponent {
  @Input() public columns: number = 3;
  @Input() public rows: number = 3;
  @Input('cameras') public set camerasSetter(value: Camera[]) {
    this.cameras = value.map(camera => {
      let cam = new Camera();
      if(camera) {
        Object.assign(cam, camera);
      } else {
        cam = camera
      }
      return cam;
    });
  }
  @ViewChildren('camera') cameraElements: CameraSelectComponent[] = [];
  public gap: TileLayoutGap = {
    rows: 0,
    columns: 0
  };

  public cameras: Camera[] = [];

  public onReorder(e: TileLayoutReorderEvent): void {};

  public onResize(e: TileLayoutResizeEvent): void {};

  public cameraSelect(camera: Camera): void {

  };
  public reset(): void {
    this.cameraElements.forEach(camera => {
      camera.reset()
    });
  }
  public clear(): void {
    this.cameraElements.forEach(camera => {
      camera.clear()
    });
  }
  public setup(): void {
    this.cameraElements.forEach(camera => {
      camera.setup()
    });
  }
}
