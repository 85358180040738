import { createAction, props } from "@ngrx/store";
import SitePackage from "../models/site-package";

export const loadSitePackages = createAction("[SitePackage/View] Load Site Packages");

export const updateSitePackage = createAction(
  "[SitePackage/View] Update Site Package",
  props<{ sitePackage: SitePackage }>(),
);

export const createSitePackage = createAction(
  "[SitePackage/View] Create Site Package",
  props<{ sitePackage: SitePackage }>(),
);

export const deleteSitePackage = createAction("[SitePackage/View] Delete Site Package", props<{ id: number }>());
