import { Component, OnInit, ViewChild, Input, OnDestroy, ElementRef, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { Camera } from 'app/camera/camera';
import { WHEPClient } from './whepclient';


@Component({
  selector: 'app-webrtc-player',
  templateUrl: './webrtc-player.component.html',
  styleUrls: ['./webrtc-player.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebrtcPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;
  @ViewChild('videoContainer') videoContainer: ElementRef<HTMLDivElement>;
  @ViewChild('logo') logo: ElementRef<HTMLImageElement>;
  @Input('camera') set setCamera(value: Camera) {
    if (!this.camera || this.camera.id !== value.id) {
      this.camera = value;
      this.webRTCURL = this.isCloud ? this.camera.stream.webRTCCloud.url : this.camera.stream.webRTC.url;
    }
  };
  @Input('isCloud') set setIsCloud(value: boolean) {
    if (this.isCloud !== value && this.camera) {
      this.webRTCURL = value ? this.camera.stream.webRTCCloud.url : this.camera.stream.webRTC.url;
    }
    this.isCloud = value;
  };
  @Input('showLogo') showLogo: boolean = true;
  @Input('stretch') stretch: boolean = false;
  @Input('streamAuth') set setStreamAuth(value: { username: string, password: string }) {
    if (value) {
      this.whepClient.updateUsernamePassword(value.username, value.password);
    }
  };
  @Output('ready') readyEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('error') errorEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  videoElement: HTMLVideoElement;
  webRTCURL: string = "";

  public width: number = 1280;
  public height: number = 720;
  public ready: boolean = false;
  public top: string = "0px";
  public right: string = "0px";

  private camera: Camera;
  private isCloud: boolean = false;
  private whepClient: WHEPClient;
  public alive: boolean = true;
  private resizeObserver: ResizeObserver;

  public  hideLogo = true;

  constructor() {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.webRTCSetup();
    this.resizeObserver = new ResizeObserver(() => { 
      this.resize();
    });
    this.resizeObserver.observe(this.videoContainer.nativeElement);
  }

  ngOnDestroy() {
    this.webRTCTearDown();
    // console.log("Destroying webrtc player:", this.camera.displayName);
    this.alive = false;
    if(this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  webRTCSetup() {
    // console.log("Setting up webrtc player:", this.camera.displayName);
    if (this.video && this.video.nativeElement) {
      this.videoElement = this.video.nativeElement;
      this.videoElement.muted = true;
    }



    this.whepClient = new WHEPClient(this.webRTCURL, this.videoElement, this.camera.stream.username, this.camera.stream.password, (err) => {
      this.errorEmitter.emit(true);
      this.ready = false;
      this.readyEmitter.emit(this.ready);
    });

  }

  webRTCTearDown() {
    if (this.video && this.video.nativeElement) {
     this.video.nativeElement.srcObject = null;
     this.video.nativeElement.src = "";
    }
    if (this.whepClient) {
      this.whepClient.close();
      this.whepClient = null;
    }
  }

  onVideoPlay() {
    this.ready = true;

    this.hideLogo = false;
    
    this.readyEmitter.emit(this.ready);
  }

  goFullScreen() {
    this.videoContainer.nativeElement.requestFullscreen();
  }
  resize() {
    this.top = "0px";
    this.right = "0px";
    if (this.stretch) {
      return;
    }
    let vwidth = this.videoElement.videoWidth;
    let vheight = this.videoElement.videoHeight;
    let cwidth = this.videoElement.clientWidth;
    let cheight = this.videoElement.clientHeight;
    let vidRatio = this.videoElement.videoWidth / this.videoElement.videoHeight;
    let dispRatio = this.videoElement.clientWidth / this.videoElement.clientHeight;
    if(vidRatio > dispRatio) {
      this.top = (cheight - (cwidth / vwidth * vheight)) / 2 + "px";
    } else {
      this.right = (cwidth - (cheight / vheight * vwidth)) / 2 + "px";
    }
  }
  play() {
    this.videoElement.play();
  }
  pause() {
    this.videoElement.pause();
  }
  reset() {
    this.webRTCTearDown();
    this.webRTCSetup();
  }
  clear() {
    this.webRTCTearDown();
  }
  setup() {
    this.webRTCSetup();
  }
}
