import { ActionReducer, Action } from '@ngrx/store';

export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const HIDE_SIDEBAR = "HIDE_SIDEBAR";
export const SHOW_SIDEBAR = "SHOW_SIDEBAR";

export const initialSidebarOpen = true;

export const initialSidebarHidden = false;

// export const sidebarOpenReducer: ActionReducer<boolean> = (state: boolean = initialSidebarOpen, action: Action) => {
//     switch(action.type) {
//         case OPEN_SIDEBAR:
//             return true;
//         case CLOSE_SIDEBAR:
//             return false;
//         case TOGGLE_SIDEBAR:
//             return !state;
//         default:
//             return state;
//     }
// }

export function sidebarOpenReducer(state: boolean = initialSidebarOpen, action: Action): boolean {
    switch(action.type) {
        case OPEN_SIDEBAR:
            return true;
        case CLOSE_SIDEBAR:
            return false;
        case TOGGLE_SIDEBAR:
            return !state;
        default:
            return state;
    }    
}

export function sidebarHiddenReducer(state: boolean = initialSidebarHidden, action: Action): boolean {
    switch(action.type) {
        case HIDE_SIDEBAR:
            return true;
        case SHOW_SIDEBAR:
            return false;
        default:
            return state;
    }    
}