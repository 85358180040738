import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { Store, select } from "@ngrx/store";
import { AppState } from "app/app.state";
import * as actions from '../store/actions';
import * as selectors from '../store/selectors';
import * as aiActions from '../../ai/store/actions';
import { Router } from '@angular/router';
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { AnalyticsService } from 'app/analytics.service';
import { Lookup } from '../../lookup';
import { debounceTime, takeWhile } from 'rxjs/operators';
import { EventTypeMetric } from '../event-type-metric';
import { EventDayHourMetric } from '../event-day-hour-metric';

@Component({
  selector: 'app-portfolio-event',
  templateUrl: './portfolio-event.component.html',
  styleUrls: ['./portfolio-event.component.css']
})
export class PortfolioEventComponent implements OnInit {
  public eventGridState$: Observable<State>;
  public eventGridView$: Observable<GridDataResult>;
  public eventGridLoading$: Observable<boolean>;
  public siteList$: Observable<Lookup[]>;
  public cfTagCategories$: Observable<Lookup[]>; 
  public statusCodes$: Observable<Lookup[]>; 
  public siteFilter$: Observable<Lookup[]>;
  public cfTagCategoryFilter$: Observable<Lookup[]>;
  public startDateFilter$: Observable<Date>;
  public endDateFilter$: Observable<Date>;
  public statusFilter$: Observable<Lookup[]>;
  public textFilter$: Observable<string>;
  public textFilterChangeDebounced$: Subject<string> = new Subject<string>();
  public alive: boolean = true;

  public eventTypeMetrics$: Observable<EventTypeMetric>;
  public eventTypeMetricsLoading$: Observable<boolean>;
  public showEventMetrics$: Observable<boolean>;
  public eventTimeDayHour$: Observable<EventDayHourMetric[]>;
  public eventTimeDayHourLoading$: Observable<boolean>;
  public selectedBubble$: Observable<EventDayHourMetric>;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(actions.getPortfolioEvents());
    this.store.dispatch(actions.getSiteList());
    this.store.dispatch(actions.getCfTagCategories());
    this.store.dispatch(actions.getStatusCodes());
    this.store.dispatch(actions.getEventTypeMetrics());
    this.store.dispatch(actions.getEventTimeDayHourMetrics());
    this.eventGridState$ = this.store.pipe(select(selectors.eventGridState));
    this.eventGridView$ = this.store.pipe(select(selectors.eventGridView));
    this.eventGridLoading$ = this.store.pipe(select(selectors.eventGridLoading));
    this.siteList$ = this.store.pipe(select(selectors.siteList));
    this.cfTagCategories$ = this.store.pipe(select(selectors.cfTagCategories));
    this.siteFilter$ = this.store.pipe(select(selectors.siteFilter));
    this.cfTagCategoryFilter$ = this.store.pipe(select(selectors.cfTagCategoryFilter));
    this.startDateFilter$ = this.store.pipe(select(selectors.startDateFilter));
    this.endDateFilter$ = this.store.pipe(select(selectors.endDateFilter));
    this.statusCodes$ = this.store.pipe(select(selectors.statusCodes));
    this.statusFilter$ = this.store.pipe(select(selectors.statusFilter));
    this.textFilter$ = this.store.pipe(select(selectors.textFilter));
    this.eventTypeMetrics$ = this.store.pipe(select(selectors.eventTypeMetrics));
    this.eventTypeMetricsLoading$ = this.store.pipe(select(selectors.eventTypeMetricsLoading));
    this.showEventMetrics$ = this.store.pipe(select(selectors.showEventMetrics));
    this.eventTimeDayHour$ = this.store.pipe(select(selectors.eventTimeDayHour));
    this.eventTimeDayHourLoading$ = this.store.pipe(select(selectors.eventTimeDayHourLoading));
    this.selectedBubble$ = this.store.pipe(select(selectors.selectedBubble));
    this.textFilterChangeDebounced$.pipe(
      takeWhile(() => this.alive),
      debounceTime(500)
    ).subscribe((textFilter) => {
      this.store.dispatch(actions.setTextFilter({ textFilter: textFilter }));
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  onGridStateChange(gridState: State) {
    this.store.dispatch(actions.setEventGridState({ gridState: gridState }));
  }

  onSiteFilterChange(filter: Lookup[]) {
    this.store.dispatch(actions.setSiteFilter({ siteFilter: filter }));
  }

  onCfTagCategoryFilterChange(filter: Lookup[]) {
    this.store.dispatch(actions.setCfTagCategoryFilter({ cfTagCategoryFilter: filter }));
  }

  onStartDateFilterChange(filter: Date) {
    this.store.dispatch(actions.setStartDateFilter({ startDate: filter }));
  }

  onEndDateFilterChange(filter: Date) {
    this.store.dispatch(actions.setEndDateFilter({ endDate: filter }));
  }

  onStatusFilterChange(filter: Lookup[]) { 
    this.store.dispatch(actions.setStatusFilter({ statusFilter: filter }));
  }

  onTextFilterChange(filter: string) {
    this.textFilterChangeDebounced$.next(filter);
  }

  onClearFilters() {
    this.store.dispatch(actions.clearFilters());
  }


  onGenerateEventSynopsis(detailLevel: string, descriptionSelector: string, categoryIDs: number[] = []): void {
    this.store.dispatch(aiActions.generateEventSynopsis({
      detailLevel: detailLevel,
      descriptionSelector: descriptionSelector,
      categoryIDs: categoryIDs,
    }));
  }

  onGenerateEventSynopsisStream(detailLevel: string, descriptionSelector: string, categoryIDs: number[] = []): void {
    this.store.dispatch(aiActions.generateEventSynopsisStream({
      detailLevel: detailLevel,
      descriptionSelector: descriptionSelector,
      categoryIDs: categoryIDs,
    }));
  }

  onToggleEventMetrics() {
    this.store.dispatch(actions.toggleEventMetrics());
  }

  onBubbleSelected(e: EventDayHourMetric) {
    this.store.dispatch(actions.setSelectedBubble({ bubble: e }));
  }


}