import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "app/app.state";
import { PermissionsService } from "app/permissions.service";
import { take } from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class LPRGuard  {
    constructor(
        private store: Store<AppState>, 
        private permissionsService: PermissionsService, 
        private router: Router,
    ) {}
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let siteID = +route.params['siteID'];
        if(!this.permissionsService.site(siteID, 'canManageLPR') && !this.permissionsService.site(siteID, 'canViewLPR')) {
            this.router.navigate(['site', siteID, 'dashboard']);
        }
        this.store.select(s => s.site).pipe(take(1)).subscribe(site => {
            if(site.lprCams < 1) {
                this.router.navigate(['site', siteID, 'dashboard']);
            }
        })
        return true;
    }
}