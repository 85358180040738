import { ActionReducer, Action } from '@ngrx/store';
import { Request } from './request';

import { When } from './new-request/when/when';
import { What } from './new-request/what/what';
import { Where } from './new-request/where/where';
import { Cameras } from './new-request/cameras/cameras';
import { RequestCameraMarker } from './new-request/cameras/request-camera-marker';
import { ContactInfo } from './new-request/contact-info/contact-info';
import { Confirmation } from './new-request/confirmation/confirmation';
import { User } from '../users/user';

import { MapDrawing } from './new-request/where/map-drawing';

import { RequestStages } from './new-request/request-stages';

import { ServiceRequest } from '../service-request/service-request';
import { ServiceRequestCameraMarker } from '../service-request/service-request-camera-marker';

import { REQUEST_TOGGLE_CAMERA, SERVICE_REQUEST_TOGGLE_CAMERA } from './request-toggle-camera-action';
import { CameraMarker } from 'app/camera/camera-marker';

export const NEW_REQUEST_IN_PROGRESS = "NEW_REQUEST_IN_PROGRESS";
export const NEW_REQUEST_NOT_IN_PROGRESS = "NEW_REQUEST_NOT_IN_PROGRESS";

export const NEW_REQUEST = 'NEW_REQUEST';

export const SET_REQUEST_WHEN = 'SET_REQUEST_WHEN';
export const SET_REQUEST_WHAT = 'SET_REQUEST_WHAT';
export const SET_REQUEST_WHERE = 'SET_REQUEST_WHERE';
export const SET_REQUEST_CAMERAS = 'SET_REQUEST_CAMERAS';
export const SET_REQUEST_CONTACT_INFO = 'SET_REQUEST_CONTACT_INFO';
export const SET_REQUEST_CONFIRMATION = 'SET_REQUEST_CONFIRMATION';

export const REQUEST_ADD_WHERE_POINT = 'REQUEST_ADD_WHERE_POINT';
export const REQUEST_CLEAR_MAP = 'REQUEST_CLEAR_MAP';
export const REQUEST_SET_WHERE_ADDRESS = 'REQUEST_SET_WHERE_ADDRESS';

export const REQUEST_RESET_CAMERAS = 'REQUEST_RESET_CAMERAS';

export const REQUEST_SET_CONTACT_INFO_USER = 'REQUEST_SET_CONTACT_INFO_USER';
export const REQUEST_SET_SITEID = 'REQUEST_SET_SITEID';
export const REQUEST_SET_CONFIDENTIAL = 'REQUEST_SET_CONFIDENDTIAL';
export const REQUEST_SUBMITTED = 'REQUEST_SUBMITTED';
export const RESET_REQUEST_SUBMIT = "RESET_REQUEST_SUBMIT";

//set whether a stage has been started or not
export const START_REQUEST_WHEN = 'START_REQUEST_WHEN';
export const START_REQUEST_WHAT = 'START_REQUEST_WHAT';
export const START_REQUEST_WHERE = 'START_REQUEST_WHERE';
export const START_REQUEST_CAMERAS = 'START_REQUEST_CAMERA';
export const START_REQUEST_CONTACT_INFO = 'START_REQUEST_CONTACT_INFO';
export const START_REQUEST_CONFIRMATION = 'START_REQUEST_CONFIRMATION';
export const RESET_REQUEST_STAGES = 'RESET_REQUEST_STAGES';
//set the current stage you're looking at for the new request
export const SET_REQUEST_CURRENT_WHEN = 'SET_REQUEST_CURRENT_WHEN';
export const SET_REQUEST_CURRENT_WHAT = 'SET_REQUEST_CURRENT_WHAT';
export const SET_REQUEST_CURRENT_WHERE = 'SET_REQUEST_CURRENT_WHERE';
export const SET_REQUEST_CURRENT_CAMERAS = 'SET_REQUEST_CURRENT_CAMERAS';
export const SET_REQUEST_CURRENT_CONTACT_INFO = 'SET_REQUEST_CURRENT_CONTACT_INFO';
export const SET_REQUEST_CURRENT_CONFIRMATION ='SET_REQUEST_CURRENT_CONFIRMATION';



export const REQUEST_STATES = { WHEN: 'when', WHAT: 'what', WHERE: 'where', CAMERAS: 'cameras', CONTACT_INFO: 'contact-info', CONFIRMATION: 'confirmation' }

export const SERVICE_REQUEST_SET = 'SERVICE_REQUEST_SET';
export const SERVICE_REQUEST_CLEAR = 'SERVICE_REQUEST_CLEAR';
export const SERVICE_REQUEST_SET_DESCRIPTION = 'SERVICE_REQUEST_SET_DESCRIPTION';
export const SERVICE_REQUEST_SET_SITEID = 'SERVICE_REQUEST_SET_SITEID';


export const initialNewRequestInProgress = false;

//export const initialRequest = new Request();
export const initialRequest = null;

//export const initialRequestStages = new RequestStages();
export const initialRequestStages = {
    current: "",
    when: {displayName: "When", started: false, route: "when"},
    what: {displayName: "What", started: false, route: "what"},
    where: {displayName: "Where", started: false, route: "where"},
    cameras: { dipslayName: "Cameras", started: false, route: "cameras" },
    contactInfo: { displayName: "Contact Info", started: false, route: "contact-info"},
    confirmation: { displayName: "Confirmation", started: false, route: "confirmation"},

};

//export const initialServiceRequest = new ServiceRequest();
export const initialServiceRequest = {userID: -1, cameras: [], description: "", siteID: 0};

export function newRequestInProgressReducer(state: boolean = initialNewRequestInProgress, action: Action): boolean {
    switch(action.type) {
        case NEW_REQUEST_IN_PROGRESS:
            return true;
        case NEW_REQUEST_NOT_IN_PROGRESS:
            return false;
        default:
            return state;
    }
}

export function requestReducer(state: Request, action): Request {
    switch(action.type) {
        case NEW_REQUEST:
            return new Request();
        case SET_REQUEST_WHEN:
            return Object.assign(new Request(), state, {when: Object.assign(new When(), action.payload)});
        case SET_REQUEST_WHAT:
            return Object.assign(new Request(), state, {what: Object.assign(new What(), action.payload)});
        case SET_REQUEST_WHERE:
            return Object.assign(new Request(), state, {where: Object.assign(new Where(), action.payload)});
        case SET_REQUEST_CAMERAS:
            return Object.assign(new Request(), state, {where: new Cameras(action.payload)});
        case SET_REQUEST_CONTACT_INFO:
            return Object.assign(new Request(), state, {contactInfo: Object.assign(new ContactInfo(), action.payload)});
        case SET_REQUEST_CONFIRMATION:
            return Object.assign(new Request(), state, {confirmation: Object.assign(new Confirmation(), action.payload)});
        case REQUEST_ADD_WHERE_POINT:
            {
                let matches = state.where.mapData.filter(drawing => drawing.mapID === action.payload.mapID)
                return Object.assign(new Request(), state, {
                    where: Object.assign(new Where(), state.where, {
                        mapData: [...state.where.mapData.filter(drawing => drawing.mapID !== action.payload.mapID), Object.assign(new MapDrawing(), matches.length > 0 ? matches[0]: {mapID: action.payload.mapID}, {points: [...(matches.length > 0? matches[0].points: []), action.payload.point]})]
                    })
                });
            }
        case REQUEST_CLEAR_MAP:
            {
                return Object.assign(new Request(), state, {where: Object.assign(new Where(),state.where, {mapData: [...state.where.mapData.filter(drawing => drawing.mapID !== action.payload)]})})
            }
        case REQUEST_SET_WHERE_ADDRESS:
            return Object.assign(new Request(), state, {where: Object.assign(new Where(),state.where, {address: action.payload})});
        case REQUEST_TOGGLE_CAMERA:
            return Object.assign(new Request(), state, {cameras: new Cameras(toggleThingInList(state.cameras.cameras, action.payload, RequestCameraMarker) as CameraMarker[])});
            // let cameraFound = false;
            // if(state.cameras) {
            //     state.cameras.cameras.forEach(camera => {
            //         if(camera.id === action.payload.id) {
            //             cameraFound = true;
            //         }
            //     })
            // }
            // if(cameraFound) {
            //     return Object.assign(new Request(), state, {cameras: Object.assign(new Cameras(), state.cameras, {cameras: [...state.cameras.cameras.filter(camera => camera.id !== action.payload.id)]})})     
            // } else {
            //     return Object.assign(new Request(), state, {cameras: Object.assign(new Cameras(), state.cameras, { cameras: [...state.cameras.cameras, new RequestCameraMarker(action.payload)] } )})
            // }
        case REQUEST_SET_CONTACT_INFO_USER:
            return Object.assign(new Request(), state, {contactInfo: Object.assign(new ContactInfo(), state.contactInfo, { user: Object.assign(new User(), action.payload)})});
        case REQUEST_SET_SITEID:
            return Object.assign(new Request(), state, {siteID: action.payload});
        case REQUEST_SET_CONFIDENTIAL:
            return Object.assign(new Request(), state, {confidential: action.payload});
        case REQUEST_SUBMITTED:
            return Object.assign(new Request(), state, {submitted: true});
        case RESET_REQUEST_SUBMIT:
            return Object.assign(new Request(), state, {submitted: false});
        default:
            return state; 
    }
}

export function requestStagesReducer(state: RequestStages, action: Action): RequestStages {
    switch(action.type) {
        case START_REQUEST_WHEN:
            {
                let res = Object.assign(new RequestStages(), state);
                res.when.started = true;
                return res;
            }
        // case START_REQUEST_WHAT:
        //     {
        //         let res = Object.assign(new RequestStages(), state);
        //         res.what.started = true;
        //         return res;
        //     }
        case START_REQUEST_WHERE:
            {
                let res = Object.assign(new RequestStages(), state);
                res.where.started = true;
                return res;
            }
        case START_REQUEST_CAMERAS:
            {
                let res = Object.assign(new RequestStages(), state);
                res.cameras.started = true;
                return res;
            }
        case START_REQUEST_CONTACT_INFO:
            {
                let res = Object.assign(new RequestStages(), state);
                res.contactInfo.started = true;
                return res;
            }
        case START_REQUEST_CONFIRMATION:
            {
                let res = Object.assign(new RequestStages(), state);
                res.confirmation.started = true;
                return res;
            }
        case RESET_REQUEST_STAGES:
            return new RequestStages();
        case SET_REQUEST_CURRENT_WHEN:
            {
                let res = Object.assign(new RequestStages(), state);
                res.current = REQUEST_STATES.WHEN;
                return res;
            }
        case SET_REQUEST_CURRENT_WHAT:
            {
                let res = Object.assign(new RequestStages(), state);
                res.current = REQUEST_STATES.WHAT;
                return res;
            }
        case SET_REQUEST_CURRENT_WHERE:
            {
                let res = Object.assign(new RequestStages(), state);
                res.current = REQUEST_STATES.WHERE;
                return res;
            }
        case SET_REQUEST_CURRENT_CAMERAS:
            {
                let res = Object.assign(new RequestStages(), state);
                res.current = REQUEST_STATES.CAMERAS;
                return res;
            }
        case SET_REQUEST_CURRENT_CONTACT_INFO:
            {
                let res = Object.assign(new RequestStages(), state);
                res.current = REQUEST_STATES.CONTACT_INFO;
                return res;
            }
        case SET_REQUEST_CURRENT_CONFIRMATION:
            {
                let res = Object.assign(new RequestStages, state);
                res.current = REQUEST_STATES.CONFIRMATION;
                return res;
            }
        default:
            return state;
    }
}
export function serviceRequestReducer(state: ServiceRequest, action): ServiceRequest {
    switch(action.type) {
        case SERVICE_REQUEST_CLEAR:
            {
                return new ServiceRequest();
            }
        case SERVICE_REQUEST_SET:
            {
                return new ServiceRequest(action.payload);
            }
        case SERVICE_REQUEST_SET_DESCRIPTION:
            {
                return new ServiceRequest(Object.assign(state, {description: action.payload}))
            }
        case SERVICE_REQUEST_TOGGLE_CAMERA:
            {
                return new ServiceRequest(Object.assign({}, state, {cameras: toggleThingInList(state.cameras, action.payload, ServiceRequestCameraMarker)}))
            }
        case SERVICE_REQUEST_SET_SITEID:
            {
                return new ServiceRequest(Object.assign({}, state, {siteID: action.payload}));
            }
        default:
            return state;
    }
}


function toggleThingInList(list: {id: number}[], thing: {id: number}, Constructor: any): {id: number}[] {
    let thingFound = false;
    if(list) {
        list.forEach(thingInList => {
            if(thingInList.id === thing.id) {
                thingFound = true;
            }
        })
        if(thingFound) {
            return list.filter(listThing => listThing.id !== thing.id)
        } else {
            return [...list, new Constructor(thing)]
        }
    }
    return [new Constructor(thing)];
} 