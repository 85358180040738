import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ServiceHelpersService } from '../service-helpers.service';
import { Observable } from 'rxjs';
import { AccessKeyService } from '../users/access-key.service';
import { map, catchError } from 'rxjs/operators';
import { AlertEntry } from './manage-alert-entries/alert-entry';
import { Tag } from './manage-alert-entries/tag';
import { AlertHit } from './recent-hits/alert-hit';

@Injectable({
  providedIn: 'root'
})
export class LprService {
  public headers = {};
  constructor(
    private http: HttpClient,
    private helper: ServiceHelpersService,
    private accessKeyService: AccessKeyService,
    ) {
      this.accessKeyService.getKey().subscribe(key => this.headers = Object.assign(this.headers, { "accessKey": key}));
     }

  getAlertEntries(siteID: number): Observable<AlertEntry[]> {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<AlertEntry[]>(`/rest/site/${siteID}/lpr/alertentries`, { headers: headers }).pipe(catchError(this.helper.handleError), map(alertEntries => {
      if (alertEntries && alertEntries.map) {
        return alertEntries.map(e => new AlertEntry(e))
      }
      return alertEntries;
    }));
  }

  removeAlertEntry(entryID: number, siteID: number) {
    let headers = new HttpHeaders(this.headers);
    return this.http.delete(`/rest/site/${siteID}/lpr/alertentry/${entryID}`, { headers: headers }).pipe(catchError(this.helper.handleError));
  }

  // Add/Edit Alert Entry

  saveAlertEntry(alertEntry: AlertEntry): Observable<AlertEntry> {
    let headers = new HttpHeaders(this.headers);
    return this.http.post<AlertEntry>(`/rest/site/${alertEntry.siteID}/lpr/alertentry`, alertEntry, { headers: headers }).pipe(catchError(this.helper.handleError), map(e => new AlertEntry(e)));
  }

  updateAlertEntry(alertEntry: AlertEntry): Observable<AlertEntry> {
    let headers = new HttpHeaders(this.headers);
    return this.http.put<AlertEntry>(`/rest/site/${alertEntry.siteID}/lpr/alertentry/${alertEntry.id}`, alertEntry, { headers: headers }).pipe(catchError(this.helper.handleError), map(e => new AlertEntry(e)));
  }

  getTags() {
    let headers = new HttpHeaders(this.headers);
    return this.http.get<Tag[]>(`/rest/lpr/gettags`, { headers: headers }).pipe(catchError(this.helper.handleError), map(tags => {
      if (tags && tags.map) {
        return tags.map(e => new Tag(e))
      }
      return tags;
    }))
  }

  // Alert Details

  loadRecentHitsForAlert(alertID: number, siteID: number): Observable<AlertHit[]> {
    return this.http.get<AlertHit[]>(`/rest/site/${siteID}/lpr/alertentry/${alertID}/hits`).pipe(
      map(hits => {
        if(hits && hits.map) {
          return hits.map(h => new AlertHit(h));
        }
        return hits;
      })
    )
  }
}