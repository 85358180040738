import { Vehicle } from '../where/vehicle/vehicle';

export class What {
    description: string;
    vehicleInvolved: boolean;
    vehicles: Vehicle[];  
    valid(){
        let valid = true;
        let errors = {};
        return {valid: valid, errors: errors};
    }
}
