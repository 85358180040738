import { createAction, props } from "@ngrx/store";
import SitePackage from "../models/site-package";

export const loadSitePackagesSuccess = createAction(
  "[SitePackage/API] Load Site Packages Success",
  props<{ sitePackages: SitePackage[] }>(),
);

export const loadSitePackagesFailure = createAction(
  "[SitePackage/API] Load Site Packages Failure",
  props<{ errorMessage: string }>(),
);

export const updateSitePackageSuccess = createAction(
  "[SitePackage/API] Update Site Package Success",
  props<{ sitePackage: SitePackage }>(),
);

export const updateSitePackageFailure = createAction(
  "[SitePackage/API] Update Site Package Failure",
  props<{ errorMessage: string }>(),
);

export const createSitePackageSuccess = createAction(
  "[SitePackage/API] Create Site Package Success",
  props<{ sitePackage: SitePackage }>(),
);

export const createSitePackageFailure = createAction(
  "[SitePackage/API] Create Site Package Failure",
  props<{ errorMessage: string }>(),
);

export const deleteSitePackageSuccess = createAction(
  "[SitePackage/API] Delete Site Package Success",
  props<{ id: number }>(),
);

export const deleteSitePackageFailure = createAction(
  "[SitePackage/API] Delete Site Package Failure",
  props<{ errorMessage: string }>(),
);
