
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserService } from "app/users/user.service";
import { filter, map, first } from "rxjs/operators";
import { PermissionsService } from "app/permissions.service";

@Injectable()
export class DevelopmentSandboxGuard  {
  constructor(private userService: UserService, private permissionsService: PermissionsService) {}

  public canActivate(): Observable<boolean> {
    return this.userService.getUser().pipe(
      filter((user) => !user.initial),
      map((user) => this.permissionsService.admin("canAccessDevSandbox")),
      first(),
    );
  }
}
